// @flow

import React from 'react';

import Svg from 'components/svg';

const FailedDelivery = Svg((
  <>
    <path
      d="M19.0001 7.74841V0.998413H10.0001H1.00012V7.74841V14.4984H10.0001"
      stroke="#D13D47"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M17.5985 10.9001L13.5985 14.9001"
      stroke="#D13D47"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M13.5985 10.9001L17.5985 14.9001"
      stroke="#D13D47"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M1.00012 2.69952L10.0001 9.44952L19.0001 2.69952"
      stroke="#D13D47"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 20 20', fill: 'none' });

FailedDelivery.displayName = 'FailedDelivery';

export default FailedDelivery;
