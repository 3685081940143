import type { Reducer } from 'redux';

import { createReducer, formatActionType } from 'normalized-redux/reducers';
import { Entity, Pipe } from 'normalized-redux/entity-normalizer/types';
import { FormattedActionType } from 'normalized-redux/reducers/format-action-type';

type FetchState< E extends Entity> = {
  id?: E['id'],
  params?: Record<string, any>,
  loading?: boolean,
  error?: any,
  success?: boolean,
};

type LocalState<E extends Entity> = {
  [key in E['id']]?: FetchState<E>;
}

export const initialFetchState: FetchState<Entity> = {
  id: undefined,
  params: undefined,
  loading: false,
  error: undefined,
  success: false,
};

export const initialState: LocalState<Entity> = {};

type Action<T extends string = string> = {
  type: T,
};

type FetchStartAction<E extends Entity, T extends string > = Action<T> & {
  id: E['id'],
  params?: FetchState<Entity>['params'],
  pipe?: Pipe,
};

type FetchSuccessAction<E extends Entity, T extends string> = Action<T> & {
  id: E['id'],
};

type FetchFailAction<E extends Entity, T extends string> = Action<T> & {
  id: E['id'],
  error: any,
};

type CreateFetchStartActionArgs<E extends Entity> = {
  id: E['id'],
  params?: FetchState<E>['params'],
  pipe?: Pipe,
};

// actions
export const createFetchStartAction = <
  E extends Entity,
  T extends string
>(type: T) => ({ id, params, pipe }: CreateFetchStartActionArgs<E>): FetchStartAction<E, T> => ({
    type,
    id,
    params,
    pipe,
  });

type CreateFetchSuccessActionArgs<E extends Entity> = {
  id: E['id'],
};

export const createFetchSuccessAction = <
  E extends Entity,
  T extends string
>(type: T) => ({ id }: CreateFetchSuccessActionArgs<E>): FetchSuccessAction<E, T> => ({
    type,
    id,
  });

type CreateFetchFailActionArgs<E extends Entity> = {
  id: E['id'],
  error: any,
};

export const createFetchFailAction = <
  E extends Entity,
  T extends string
>(type: T) => ({ id, error }: CreateFetchFailActionArgs<E>): FetchFailAction<E, T> => ({
    type,
    id,
    error,
  });

// handlers
export const fetchStartHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: FetchStartAction<E, T>): LocalState<E> => ({
    ...state,
    [action.id]: {
      id: action.id,
      params: action.params,
      loading: true,
      success: false,
      error: undefined,
    },
  });

export const fetchSuccessHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: FetchSuccessAction<E, T>): LocalState<E> => ({
    ...state,
    [action.id]: {
      ...initialFetchState,
      ...state[action.id],
      loading: false,
      success: true,
      error: undefined,
    },
  });

export const fetchFailHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: FetchFailAction<E, T>): LocalState<E> => ({
    ...state,
    [action.id]: {
      ...initialFetchState,
      ...state[action.id],
      error: action.error,
      loading: false,
      success: false,
    },
  });

// selectors
type GetFetchSelectorArgs<E extends Entity> = {
  id: E['id'],
};
export const getFetchSelector = <
  E extends Entity
>(state: LocalState<E>, { id }: GetFetchSelectorArgs<E>): FetchState<E> => {
  if (!state[id]) {
    return initialFetchState as FetchState<E>;
  }

  return state[id] as FetchState<E>;
};

type FormattedFetchStartActionType<Key extends string> = FormattedActionType<Key, 'FETCH_START'>;
type FormattedFetchSuccessActionType<Key extends string> = FormattedActionType<Key, 'FETCH_SUCCESS'>;
type FormattedFetchFailActionType<Key extends string> = FormattedActionType<Key, 'FETCH_FAIL'>;

export type FetchReducer<E extends Entity, Key extends string> = {
  reducer: Reducer<LocalState<E>, any>
  actions: {
    fetchStart: (
      args: CreateFetchStartActionArgs<E>
    ) => FetchStartAction<E, FormattedFetchStartActionType<Key>>,
    fetchSuccess: (
      args: CreateFetchSuccessActionArgs<E>
    ) => FetchSuccessAction<E, FormattedFetchSuccessActionType<Key>>,
    fetchFail: (
      args: CreateFetchFailActionArgs<E>
    ) => FetchFailAction<E, FormattedFetchFailActionType<Key>>,
    types: {
      fetchStart: FormattedFetchStartActionType<Key>,
      fetchSuccess: FormattedFetchSuccessActionType<Key>,
      fetchFail: FormattedFetchFailActionType<Key>,
    },
  },
  selectors: {
    getFetchSelector: (state: LocalState<E>, { id }: GetFetchSelectorArgs<E>) => FetchState<E>,
  },
};

// initializer
const createFetchReducer = <
  E extends Entity,
  Key extends string
  >({ key }: { key: Key }): FetchReducer<E, Key> => {
  const FETCH_START = formatActionType({ key, type: 'FETCH_START' });
  const FETCH_SUCCESS = formatActionType({ key, type: 'FETCH_SUCCESS' });
  const FETCH_FAIL = formatActionType({ key, type: 'FETCH_FAIL' });

  return ({
    reducer: createReducer(initialState as LocalState<E>, {
      [FETCH_START]: fetchStartHandler,
      [FETCH_SUCCESS]: fetchSuccessHandler,
      [FETCH_FAIL]: fetchFailHandler,
    }),
    actions: {
      fetchStart: createFetchStartAction(FETCH_START),
      fetchSuccess: createFetchSuccessAction(FETCH_SUCCESS),
      fetchFail: createFetchFailAction(FETCH_FAIL),
      types: {
        fetchStart: FETCH_START,
        fetchSuccess: FETCH_SUCCESS,
        fetchFail: FETCH_FAIL,
      },
    },
    selectors: {
      getFetchSelector,
    },
  });
};

export default createFetchReducer;
