// @flow

import React from 'react';

import Svg from 'components/svg';

const CreateContract = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M0.349976 0.350098H13.2257L13.4161 0.540478L14.0211 1.145C14.3872 1.51058 14.8802 2.00264 15.3881 2.50863C16.4102 3.52693 17.4756 4.58458 17.7051 4.79948L17.9109 4.99211V8.34575H16.6109V5.55037C16.1856 5.13582 15.3022 4.25812 14.4706 3.4296C13.9622 2.9231 13.4688 2.43064 13.1025 2.06484L12.6874 1.6501H1.64998V19.4805H8.34563V20.7805H0.349976V20.1305H0.999976C0.349976 20.1305 0.349976 20.1307 0.349976 20.1305V0.350098ZM3.21953 6.08922H15.0413V7.38922H3.21953V6.08922ZM3.21953 10.3936H10.2587V11.6936H3.21953V10.3936ZM12.743 12.7432C13.9412 11.545 15.5663 10.8718 17.2608 10.8718C18.9553 10.8718 20.5804 11.545 21.7786 12.7432C22.9768 13.9414 23.65 15.5665 23.65 17.261C23.65 18.9555 22.9768 20.5806 21.7786 21.7788C20.5804 22.977 18.9553 23.6501 17.2608 23.6501C15.5663 23.6501 13.9412 22.977 12.743 21.7788C11.5448 20.5806 10.8717 18.9555 10.8717 17.261C10.8717 15.5665 11.5448 13.9414 12.743 12.7432ZM17.2608 12.1718C15.9111 12.1718 14.6167 12.708 13.6623 13.6624C12.7079 14.6168 12.1717 15.9112 12.1717 17.261C12.1717 18.6107 12.7079 19.9051 13.6623 20.8595C14.6167 21.8139 15.9111 22.3501 17.2608 22.3501C18.6106 22.3501 19.905 21.8139 20.8594 20.8595C21.8138 19.9051 22.35 18.6107 22.35 17.261C22.35 15.9112 21.8138 14.6168 20.8594 13.6624C19.905 12.708 18.6106 12.1718 17.2608 12.1718ZM17.9108 13.7414V16.611H20.7804V17.911H17.9108V20.7805H16.6108V17.911H13.7413V16.611H16.6108V13.7414H17.9108ZM3.21953 14.6979H8.34562V15.9979H3.21953V14.6979Z"
  />
), { viewBox: '0 0 24 24', fill: 'none' });

CreateContract.displayName = 'CreateContract';

export default CreateContract;
