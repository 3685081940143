import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { isForwardRef } from 'react-is';
import {
  cloneElement,
  forwardRef,
  useCallback,
  HTMLAttributes,
  ReactElement,
} from 'react';

import { TriggerProps } from './types';

const isPrimitiveChildren = (children: ReactElement): boolean => ['string', 'number', 'boolean'].includes(typeof children);

function TooltipTrigger({ children, className }: TriggerProps) {
  const renderTrigger = useCallback(() => {
    if (!children) return null;

    if (isPrimitiveChildren(children)) {
      // For primitive children, wrap them with TooltipPrimitive.Trigger (no span needed)
      return <TooltipPrimitive.Trigger>{children}</TooltipPrimitive.Trigger>;
    }

    const applyCustomAndDisabledStyles = (child: ReactElement) => {
      // disabled button/element will not trigger and show tooltip content
      // Refer: https://www.radix-ui.com/docs/primitives/components/tooltip#displaying-a-tooltip-from-a-disabled-button
      if (child?.props.disabled) {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <span role="presentation" tabIndex={0}>
            {cloneElement(child, {
              style: {
                ...child.props.style,
                pointerEvents: 'none',
              },
              className: clsx(child.props.className, className),
            })}
          </span>
        );
      }
      return cloneElement(child, {
        className: clsx(child.props.className, className),
      });
    };

    const wrapForwardRef = (child: ReactElement): ReactElement => {
      if (typeof child.type === 'string' || isForwardRef(child)) {
        return (
          <TooltipPrimitive.Trigger asChild>
            {cloneElement(child, { className: clsx(child.props.className, className) })}
          </TooltipPrimitive.Trigger>
        );
      }

      const TriggerableChildren = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
        (props, ref) => cloneElement(child, {
          ...props,
          triggerRef: ref,
          className: clsx(props.className, className),
        }),
      );

      TriggerableChildren.displayName = 'TriggerableChildren';
      return (
        <TooltipPrimitive.Trigger asChild>
          <TriggerableChildren />
        </TooltipPrimitive.Trigger>
      );
    };

    return wrapForwardRef(applyCustomAndDisabledStyles(children));
  }, [children, className]);

  return renderTrigger();
}

export default TooltipTrigger;
