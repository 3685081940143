import { ChatEmpty } from 'components/icons/chat-empty';
import { CommentsActiveEmpty } from 'components/icons/comments-active-empty';
import { CommentsAllResolvedEmpty } from 'components/icons/comments-all-resolved-empty';
import { CommentsResolvedEmpty } from 'components/icons/comments-resolved-empty';
import { TemplateEmpty } from 'components/icons/template-empty';
import Message from 'components/message';
import toast from 'components/toasts';

import style from './constants.scss';

export const ALL_PARTICIPANTS = 0;
export const MAX_MESSAGE_LENGTH = 5000;
export const MAX_VISIBLE_MESSAGES = 6;
export const MY_COMPANY = 1;

export const WRITTEN_MESSAGE = 0;
export const INVITATION_MESSAGE = 1;
export const INLINE_COMMENT_MESSAGE = 3;
export const SUGGESTION_MESSAGE = 4;

const COMMENTS_SUPPORT_ARTICLE_URL = 'https://support.oneflow.com/support/solutions/articles/77000553290-inline-comments-beta';
const SUGGESTIONS_SUPPORT_ARTICLE_URL = 'https://support.oneflow.com/en/support/solutions/articles/77000568756-suggestions-beta';

export const COMMENT_STATES = {
  COMMENT_ACTIVE: 'active',
  COMMENT_ALL: 'all',
  COMMENT_RESOLVED: 'resolved',
} as const;

export const MESSAGE_SCOPE = {
  PUBLIC_ALL: 'public_all',
  THREAD_ALL: 'thread_all',
};

export const MESSAGE_TYPES = {
  CHAT: 'chat',
  COMMENTS: 'comments',
} as const;

export const ICON_TYPES = {
  CHAT_ICON: 'chat',
  COMMENTS_ACTIVE_ICON: 'comments-active',
  COMMENTS_ALL_RESOLVED_ICON: 'comments-all-resolved',
  COMMENTS_RESOLVED_ICON: 'comments-resolved',
  TEMPLATE_CHAT_ICON: 'chat-template',
  TEMPLATE_COMMENTS_ICON: 'comments-template',
};

export const ICONS = {
  [ICON_TYPES.CHAT_ICON]: (
    <ChatEmpty
      height="169px"
      style={{
        marginBottom: '5px',
        marginTop: '-3px',
      }}
      width="244px"
    />
  ),
  [ICON_TYPES.COMMENTS_ACTIVE_ICON]: (
    <CommentsActiveEmpty
      height="170px"
      style={{
        marginBottom: '15px',
        marginRight: '15px',
        marginTop: '-100px',
      }}
      width="183px"
    />
  ),
  [ICON_TYPES.COMMENTS_ALL_RESOLVED_ICON]: (
    <CommentsAllResolvedEmpty
      height="233px"
      style={{
        marginBottom: '4px',
        marginTop: '-100px',
      }}
      width="215px"
    />
  ),
  [ICON_TYPES.COMMENTS_RESOLVED_ICON]: (
    <CommentsResolvedEmpty
      height="200px"
      style={{
        marginBottom: '17px',
        marginTop: '-49px',
      }}
      width="210px"
    />
  ),
  [ICON_TYPES.TEMPLATE_CHAT_ICON]: (
    <TemplateEmpty
      height="170px"
      style={{
        marginBottom: '11px',
        marginTop: '-62px',
      }}
      width="164px"
    />
  ),
  [ICON_TYPES.TEMPLATE_COMMENTS_ICON]: (
    <TemplateEmpty
      height="170px"
      style={{
        marginBottom: '11px',
        marginTop: '-86px',
      }}
      width="164px"
    />
  ),
};

export const EMPTY_STATE_MESSAGE_TYPES = {
  ACTIVE_EMPTY: 'active-empty',
  CHAT_EMPTY: 'chat-empty',
  CHAT_TEMPLATE: 'chat-template',
  COMMENTS_EMPTY: 'comments-empty',
  COMMENTS_TEMPLATE: 'comments-template',
  RESOLVED_ALL: 'resolved-all',
  RESOLVED_EMPTY: 'resolved-empty',
};

const EMPTY_STATE_HEADLINES = {
  [EMPTY_STATE_MESSAGE_TYPES.ACTIVE_EMPTY]: (
    <Message
      id="Start collaborating"
      comment="Text showing inside the comments tab when there are no active inline comments."
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.CHAT_EMPTY]: (
    <Message
      id="Start a conversation"
      comment="Text showing when the chat tab is empty."
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.CHAT_TEMPLATE]: (
    <Message
      id="Create a document to start a conversation"
      comment="Message in sidebar tabs when the contract is a template."
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.COMMENTS_EMPTY]: (
    <Message
      id="Start collaborating"
      comment="Text appears when the tab for comments and suggestions is empty."
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.COMMENTS_TEMPLATE]: (
    <Message
      id="Create a document to start collaborating"
      comment="Message in sidebar tabs when the contract is a template."
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_ALL]: (
    <Message
      id="All resolved!"
      comment="Text showing inside the comments tab when all the inline comments have been resolved and the tab is now empty."
    />
  ),
};

export const EMPTY_STATE_HEADLINES_WITH_SUGGESTIONS = {
  ...EMPTY_STATE_HEADLINES,
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_EMPTY]: (
    <Message
      id="No resolved comments or suggestions yet"
      comment="Text showing inside the comments tab when there are no resolved inline comments."
    />
  ),
};

export const EMPTY_STATE_HEADLINES_WITHOUT_SUGGESTIONS = {
  ...EMPTY_STATE_HEADLINES,
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_EMPTY]: (
    <Message
      id="No resolved comments yet"
      comment="Text showing inside the comments tab when there are no resolved inline comments."
    />
  ),
};

const EMPTY_STATE_MESSAGES = {
  [EMPTY_STATE_MESSAGE_TYPES.CHAT_EMPTY]: (
    <Message
      id="Ask a question or share an update instantly"
      comment="Text showing when the chat tab is empty."
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.CHAT_TEMPLATE]: (
    <Message
      id="You can post messages only in documents"
      comment="Text showing when the contract is a template."
    />
  ),
};

export const EMPTY_STATE_MESSAGES_WITH_SUGGESTIONS = {
  ...EMPTY_STATE_MESSAGES,
  [EMPTY_STATE_MESSAGE_TYPES.COMMENTS_EMPTY]: (
    <Message
      id="Select text in the document to add a {comment} or {suggest} edits"
      comment="Text showing when there are no active annotations."
      values={{
        comment: (
          <a
            className={style.Link}
            href={COMMENTS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="comment"
              comment="Link to the comments support article."
            />
          </a>
        ),
        suggest: (
          <a
            className={style.Link}
            href={SUGGESTIONS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="suggest"
              comment="Link to the suggestions support article."
            />
          </a>
        ),
      }}
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.COMMENTS_TEMPLATE]: (
    <Message
      id="You can add {comments} and {suggest} edits only in documents"
      comment="Text showing when the contract is a template."
      values={{
        comments: (
          <a
            className={style.Link}
            href={COMMENTS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="comments"
              comment="Link to the comments support article."
            />
          </a>
        ),
        suggest: (
          <a
            className={style.Link}
            href={SUGGESTIONS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="suggest"
              comment="Link to the suggestions support article."
            />
          </a>
        ),
      }}
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_ALL]: (
    <Message
      id="Select text in the document to add a {comment} or {suggest} edits if needed"
      comment="Text showing when there are no active annotations."
      values={{
        comment: (
          <a
            className={style.Link}
            href={COMMENTS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="comment"
              comment="Link to the comments support article."
            />
          </a>
        ),
        suggest: (
          <a
            className={style.Link}
            href={SUGGESTIONS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="suggest"
              comment="Link to the suggestions support article."
            />
          </a>
        ),
      }}
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_EMPTY]: (
    <Message
      id="Resolved items will appear here once addressed"
      comment="Text showing when there are no active annotations."
    />
  ),
};

export const EMPTY_STATE_MESSAGES_WITHOUT_SUGGESTIONS = {
  ...EMPTY_STATE_MESSAGES,
  [EMPTY_STATE_MESSAGE_TYPES.COMMENTS_EMPTY]: (
    <Message
      id="Select text in the document to add a {comment}"
      comment="Text showing when there are no active annotations."
      values={{
        comment: (
          <a
            className={style.Link}
            href={COMMENTS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="comment"
              comment="Link to the comments support article."
            />
          </a>
        ),
      }}
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.COMMENTS_TEMPLATE]: (
    <Message
      id="You can add {comments} only in documents"
      comment="Text showing when the contract is a template."
      values={{
        comments: (
          <a
            className={style.Link}
            href={COMMENTS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="comments"
              comment="Link to the comments support article."
            />
          </a>
        ),
      }}
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_ALL]: (
    <Message
      id="Select text in the document to add a {comment} if needed"
      comment="Text showing when there are no active annotations."
      values={{
        comment: (
          <a
            className={style.Link}
            href={COMMENTS_SUPPORT_ARTICLE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Message
              id="comment"
              comment="Link to the comments support article."
            />
          </a>
        ),
      }}
    />
  ),
  [EMPTY_STATE_MESSAGE_TYPES.RESOLVED_EMPTY]: (
    <Message
      id="Resolved items will appear here once addressed"
      comment="Text showing when there are no active annotations."
    />
  ),
};

export const SAVE_ANNOTATION_IN_TEXT_BOX_ERROR_TITLE = (
  <Message
    id="Couldn't perform this action"
    comment="Title for the warning message when the user can't post a comment."
  />
);

export const SAVE_COMMENT_IN_TEXT_BOX_ERROR = (
  <Message
    id="Oops, we couldn't add your comment. Please try again. If the problem persists, contact support."
    comment="Error showing when saving the comment failed."
  />
);

export const SAVE_SUGGESTION_IN_TEXT_BOX_ERROR = (
  <Message
    id="Oops, we couldn’t add your suggestion. Please try again. If the problem persists, contact support."
    comment="Error showing when saving the suggestion failed."
  />
);

export const ANNOTATION_ACTION_IN_TEXT_BOX_ERROR = (
  <>
    <Message
      id="Sorry, we couldn't perform this action now, please try again."
      comment="Part of an error message."
    />
    {' '}
    <Message
      id="If the problem persists, please contact us at"
      comment="Part of an error message."
    />
    {' '}
    <a href="mailto:support@oneflow.com">
      support@oneflow.com
    </a>
    .
  </>
);

export const contractVersionNotUpToDateToast = () => toast.error({
  id: 'post-message',
  title: (
    <Message
      id="The contract version is not up to date"
      comment="Title for the warning message when the user can't post a message."
    />
  ),
  description: (
    <Message
      id="To perform this action, please make sure to refresh the page to get the latest version."
      comment="Description for the warning message when the user can't post a message."
    />
  ),
});
