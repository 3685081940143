import Svg from 'components/svg';

const CommentsActiveEmpty = Svg((
  <>
    <path
      d="M150.192 124.14L149.005 122.953L147.061 124.897L148.249 126.084L150.192 124.14Z"
      fill="#99B0B7"
    />
    <path
      d="M145.432 128.902L144.245 127.715L142.302 129.658L143.489 130.846L145.432 128.902Z"
      fill="#99B0B7"
    />
    <path
      d="M150.194 129.656L148.249 127.711L147.063 128.897L149.008 130.842L150.194 129.656Z"
      fill="#99B0B7"
    />
    <path
      d="M145.432 124.89L143.487 122.945L142.3 124.132L144.245 126.077L145.432 124.89Z"
      fill="#99B0B7"
    />
    <path
      d="M171.242 89.1879L168.493 89.1875L168.494 90.8658L171.243 90.8667L171.242 89.1879Z"
      fill="#99B0B7"
    />
    <path
      d="M164.499 89.1806L161.749 89.1797L161.75 90.858L164.499 90.8589V89.1806Z"
      fill="#99B0B7"
    />
    <path
      d="M167.333 92.0318L165.655 92.0312L165.656 94.7806L167.334 94.7811L167.333 92.0318Z"
      fill="#99B0B7"
    />
    <path
      d="M167.337 85.2895L165.658 85.2891L165.659 88.0383L167.338 88.0388L167.337 85.2895Z"
      fill="#99B0B7"
    />
    <path
      d="M26.9466 18.4519L25.7525 17.2578L24.5591 18.4512L25.7532 19.6453L26.9466 18.4519Z"
      fill="#99B0B7"
    />
    <path
      d="M26.938 12.948L25.7439 11.7539L24.5505 12.9473L25.7446 14.1414L26.938 12.948Z"
      fill="#99B0B7"
    />
    <path
      d="M32.4512 18.4636L31.2571 17.2695L30.0637 18.4629L31.2578 19.657L32.4512 18.4636Z"
      fill="#99B0B7"
    />
    <path
      d="M32.4498 12.9519L31.2557 11.7578L30.0623 12.9512L31.2564 14.1453L32.4498 12.9519Z"
      fill="#99B0B7"
    />
    <path
      d="M7.42574 47.6333L5.73755 47.6328L5.73807 49.321L7.42627 49.3215L7.42574 47.6333Z"
      fill="#99B0B7"
    />
    <path
      d="M3.52048 43.7547L1.83228 43.7539L1.8325 45.4421L3.5207 45.4429L3.52048 43.7547Z"
      fill="#99B0B7"
    />
    <path
      d="M11.3247 43.7664L9.63647 43.7656L9.63669 45.4538L11.3249 45.4546L11.3247 43.7664Z"
      fill="#99B0B7"
    />
    <path
      d="M7.41525 39.8641L5.72705 39.8633L5.72727 41.5515L7.41546 41.5523L7.41525 39.8641Z"
      fill="#99B0B7"
    />
    <path
      d="M5.14406 47.2253L3.94997 46.0312L2.75659 47.2247L3.95068 48.4188L5.14406 47.2253Z"
      fill="#99B0B7"
    />
    <path
      d="M5.12891 41.7293L3.93483 40.5352L2.74146 41.7285L3.93556 42.9226L5.12891 41.7293Z"
      fill="#99B0B7"
    />
    <path
      d="M10.6421 47.241L9.44801 46.0469L8.25464 47.2402L9.44871 48.4344L10.6421 47.241Z"
      fill="#99B0B7"
    />
    <path
      d="M10.6404 41.7293L9.4463 40.5352L8.25293 41.7285L9.44705 42.9226L10.6404 41.7293Z"
      fill="#99B0B7"
    />
    <path
      d="M176.722 97.7309L175.044 97.7305L175.045 100.445L173.127 98.528L171.941 99.7145L173.858 101.632L171.143 101.631L171.144 103.309L173.859 103.31L171.943 105.226L173.13 106.413L175.046 104.497L175.047 107.212L176.725 107.213L176.724 104.498L178.642 106.415L179.828 105.229L177.911 103.311L180.626 103.312L180.625 101.634L177.91 101.633L179.826 99.7171L178.639 98.5297L176.723 100.446L176.722 97.7309ZM176.71 100.473L177.876 101.64L177.877 103.291L176.71 104.457L175.06 104.456L173.893 103.29V101.639L175.059 100.473H176.71Z"
      fill="#99B0B7"
    />
    <g filter="url(#filter0_d_9351_22294)">
      <path
        d="M152.544 19.6797H47.0205C45.094 19.6797 43.5322 21.2415 43.5322 23.1681V147.005C43.5322 148.932 45.094 150.494 47.0205 150.494H152.544C154.47 150.494 156.032 148.932 156.032 147.005V23.1681C156.032 21.2415 154.47 19.6797 152.544 19.6797Z"
        fill="white"
      />
      <path d="M111.192 47.8164H52.3254V53.9212H111.192V47.8164Z" fill="#FFF3D8" />
      <path d="M147.348 47.8164H114.208V53.9212H147.348V47.8164Z" fill="#D6DFE2" />
      <path d="M147.275 36.4648H52.217V42.5695H147.275V36.4648Z" fill="#D6DFE2" />
      <path d="M147.275 58.7031H52.217V64.8078H147.275V58.7031Z" fill="#D6DFE2" />
      <path d="M147.275 75.2734H52.217V81.3781H147.275V75.2734Z" fill="#D6DFE2" />
      <path d="M96.8021 84.8633H52.3254V90.9679H96.8021V84.8633Z" fill="#D6DFE2" />
      <path d="M147.348 84.8633H99.8184V90.9679H147.348V84.8633Z" fill="#D6DFE2" />
      <path d="M85.4288 111.035H52.2893V117.14H85.4288V111.035Z" fill="#F7DCD7" />
      <path d="M147.348 111.035H88.4812V117.14H147.348V111.035Z" fill="#B6E0CC" />
      <path d="M147.275 101.434H52.217V107.538H147.275V101.434Z" fill="#D6DFE2" />
      <path d="M147.311 127.605H52.2532V133.71H147.311V127.605Z" fill="#D6DFE2" />
    </g>
    <g filter="url(#filter1_d_9351_22294)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.047 137.722V113.652L153.569 131.115H142.925L142.697 131.301L136.047 137.722Z"
        fill="white"
      />
      <path
        d="M141.899 129.478L141.895 129.482L141.891 129.486L137.775 133.446V117.637L149.364 129.227L142.284 129.215H142.203L142.141 129.269L141.899 129.478Z"
        fill="#E0A8CC"
        stroke="#E0A8CC"
      />
    </g>
    <g filter="url(#filter2_d_9351_22294)">
      <path
        d="M156.167 143.25C156.167 138.795 152.555 135.184 148.1 135.184C143.645 135.184 140.033 138.795 140.033 143.25C140.033 147.706 143.645 151.317 148.1 151.317C152.555 151.317 156.167 147.706 156.167 143.25Z"
        fill="white"
      />
      <path
        d="M156.603 143.249C156.603 138.553 152.796 134.746 148.1 134.746C143.404 134.746 139.597 138.553 139.597 143.249C139.597 147.945 143.404 151.752 148.1 151.752C152.796 151.752 156.603 147.945 156.603 143.249Z"
        stroke="#E0A8CC"
        strokeWidth="1.3"
      />
      <path
        d="M146 141.061C146 141.608 146.217 142.132 146.603 142.518C146.99 142.905 147.514 143.122 148.061 143.122C148.608 143.122 149.132 142.905 149.518 142.518C149.905 142.132 150.122 141.608 150.122 141.061C150.122 140.514 149.905 139.99 149.518 139.603C149.132 139.217 148.608 139 148.061 139C147.514 139 146.99 139.217 146.603 139.603C146.217 139.99 146 140.514 146 141.061Z"
        stroke="#E0A8CC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.727 147.76C144.727 146.865 145.083 146.007 145.715 145.375C146.348 144.742 147.205 144.387 148.1 144.387C148.994 144.387 149.852 144.742 150.485 145.375C151.117 146.007 151.473 146.865 151.473 147.76"
        stroke="#E0A8CC"
        strokeWidth="1.3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter3_d_9351_22294)">
      <mask
        id="mask0_9351_22294"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="39"
        y="46"
        width="20"
        height="27"
      >
        <path d="M39.6802 46.0625H58.8663V72.2253H39.6802V46.0625Z" fill="white" />
      </mask>
      <g mask="url(#mask0_9351_22294)">
        <g filter="url(#filter4_d_9351_22294)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.0566 70.8393V46.7695L40.5337 64.2323H51.178L51.4065 64.4185L58.0566 70.8393Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5457 50.2305V67.0795L52.061 62.7631L51.8194 62.5538L44.2122 62.5664L56.5457 50.2305Z"
            fill="#8EC4E3"
          />
        </g>
      </g>
      <path
        d="M53.6337 76.8037C53.6337 72.1077 49.8268 68.3008 45.1308 68.3008C40.4348 68.3008 36.6279 72.1077 36.6279 76.8037C36.6279 81.4997 40.4348 85.3066 45.1308 85.3066C49.8268 85.3066 53.6337 81.4997 53.6337 76.8037Z"
        fill="white"
      />
      <path
        d="M53.6337 76.8037C53.6337 72.1077 49.8268 68.3008 45.1308 68.3008C40.4348 68.3008 36.6279 72.1077 36.6279 76.8037C36.6279 81.4997 40.4348 85.3066 45.1308 85.3066C49.8268 85.3066 53.6337 81.4997 53.6337 76.8037Z"
        stroke="#8EC4E3"
        strokeWidth="1.3"
      />
      <path
        d="M43.0698 74.4635C43.0698 75.0099 43.2869 75.5345 43.6735 75.9208C44.0599 76.3071 44.584 76.5243 45.1308 76.5243C45.6776 76.5243 46.2017 76.3071 46.5881 75.9208C46.9749 75.5345 47.1916 75.0099 47.1916 74.4635C47.1916 73.9167 46.9749 73.3926 46.5881 73.0063C46.2017 72.6195 45.6776 72.4023 45.1308 72.4023C44.584 72.4023 44.0599 72.6195 43.6735 73.0063C43.2869 73.3926 43.0698 73.9167 43.0698 74.4635Z"
        stroke="#8EC4E3"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.7583 81.2049C41.7583 80.3105 42.1136 79.4524 42.746 78.8201C43.3785 78.1874 44.2365 77.832 45.1308 77.832C46.0251 77.832 46.8833 78.1874 47.5156 78.8201C48.1483 79.4524 48.5032 80.3105 48.5032 81.2049"
        stroke="#8EC4E3"
        strokeWidth="1.3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_9351_22294"
        x="24.2322"
        y="0.379688"
        width="151.1"
        height="169.412"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.65" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22294" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22294"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_9351_22294"
        x="134.247"
        y="112.852"
        width="21.122"
        height="27.6703"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.9" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22294" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22294"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_9351_22294"
        x="134.947"
        y="134.098"
        width="26.3059"
        height="26.3047"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22294" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22294"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_9351_22294"
        x="31.978"
        y="46.7695"
        width="30.0786"
        height="47.1875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22294" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22294"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_9351_22294"
        x="38.7337"
        y="45.9695"
        width="21.1229"
        height="27.6703"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.9" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22294" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22294"
          result="shape"
        />
      </filter>
    </defs>
  </>
), {
  fill: 'none',
  viewBox: '0 0 183 170',
});

CommentsActiveEmpty.displayName = 'CommentsActiveEmpty';

export { CommentsActiveEmpty };
