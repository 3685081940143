// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import clsx from 'clsx';
import type { FormRenderProps } from 'react-final-form';

import { isValidEmail } from 'forms/validators';
import { ApiError } from 'components/api-error';
import Button from 'components/button';
import Header from 'components/block-header';

import style from './public-form.module.scss';

type Props = {
  buttonClass?: string,
  children: (formProps: FormRenderProps) => React.Node,
  customMessage?: {},
  errorCode?: number,
  footer?: null | ((formProps?: FormRenderProps) => React.Node),
  formRef: Object,
  headerClassName?: string,
  hideButton?: boolean,
  initialValues?: {},
  isLoading?: boolean,
  isSignup?: boolean,
  isSignupWithToken?: boolean,
  hasCaptchaToken?: boolean,
  onSubmit: (values: any) => void | null | Promise<any>,
  optionalParagraph?: React.Node,
  subheader?: React.Node,
  submitText: React.Node,
  title: React.Node,
};

class PublicForm extends React.PureComponent<Props> {
  static defaultProps = {
    subheader: null,
    optionalParagraph: null,
    errorCode: undefined,
    customMessage: undefined,
    initialValues: undefined,
    footer: null,
    isSignupWithToken: false,
  };

  renderSubmit(formProps: FormRenderProps) {
    const {
      buttonClass,
      hideButton,
      isLoading,
      isSignup,
      isSignupWithToken,
      submitText,
      hasCaptchaToken,
    } = this.props;

    if (hideButton) {
      return null;
    }

    let isDisabled = formProps.submitting || isLoading;

    if (isSignup) {
      isDisabled = formProps.submitting
        || !formProps.values.email
        || !isValidEmail(formProps.values.email)
        || !hasCaptchaToken;
    }

    if (isSignupWithToken) {
      const hasErrors = Object.keys(formProps.errors).length > 0;
      const needsTerms = isSignupWithToken && !!!formProps.values.hasAgreedToTerms; // eslint-disable-line
      isDisabled = formProps.submitting || needsTerms || !!!formProps.errors || hasErrors;// eslint-disable-line
    }

    const buttonClasses = clsx(style.SubmitButton, buttonClass);

    return (
      <Button
        data-testid="submit-button"
        customClass={buttonClasses}
        type="submit"
        disabled={isDisabled}
        color="yellow"
      >
        {submitText}
      </Button>
    );
  }

  renderFooter(formProps: FormRenderProps) {
    const { footer } = this.props;

    if (!footer) {
      return null;
    }

    return footer(formProps);
  }

  renderForm = (formProps: FormRenderProps) => {
    const { formRef } = this.props;

    return (
      <form autoComplete="off" onSubmit={formProps.handleSubmit} ref={formRef}>
        <div className={style.FormContent}>
          {this.props.children(formProps)}
          {this.renderSubmit(formProps)}
          {this.renderFooter(formProps)}
        </div>
      </form>
    );
  };

  render() {
    const {
      customMessage,
      errorCode,
      footer,
      headerClassName,
      initialValues,
      onSubmit,
      optionalParagraph,
      subheader,
      title,
      ...finalFormProps
    } = this.props;

    return (
      <>
        <Header
          title={title}
          headerClassName={headerClassName}
          subheader={subheader}
          optionalParagraph={optionalParagraph}
        />
        <ApiError errorCode={errorCode} customMessage={customMessage} />
        <Form
          {...finalFormProps}
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={this.renderForm}
        />
      </>
    );
  }
}

export default PublicForm;
