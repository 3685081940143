// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19999');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M0.5 2.5H17.5V15.5H0.5V2.5Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M0.5 4.06616L8.15654 10.1836C8.39247 10.3721 8.69126 10.4756 9 10.4756C9.30874 10.4756 9.60753 10.3721 9.84346 10.1836L17.5 4.06616"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const EnvelopeThin = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

EnvelopeThin.displayName = 'EnvelopeThin';

export default EnvelopeThin;
