// @flow

import React from 'react';

import Svg from 'components/svg';

const GrantGroupAccessIcon = Svg((
  <path
    d="M11.8512 5.20117C11.6423 4.83968 11.3503 4.53321 10.9993 4.3072C10.6482 4.0812 10.2484 3.94218 9.83285 3.90168C9.41733 3.86118 8.99815 3.92038 8.61009 4.07435C8.22203 4.22833 7.8763 4.47264 7.60159 4.787M13.6109 10.1109C13.6109 10.8031 13.4056 11.4798 13.021 12.0554C12.6365 12.631 12.0898 13.0796 11.4503 13.3445C10.8107 13.6094 10.107 13.6787 9.42808 13.5436C8.74915 13.4086 8.12551 13.0753 7.63602 12.5858C7.14654 12.0963 6.8132 11.4726 6.67815 10.7937C6.5431 10.1148 6.61241 9.41104 6.87732 8.7715C7.14222 8.13196 7.59083 7.58533 8.1664 7.20075C8.74197 6.81617 9.41866 6.61089 10.1109 6.61089M13.222 7.77756L10.1279 10.9554C10.0885 10.9959 10.0418 11.028 9.99034 11.0499C9.9389 11.0718 9.88375 11.0831 9.82807 11.0831C9.77238 11.0831 9.71723 11.0718 9.66579 11.0499C9.61435 11.028 9.56762 10.9959 9.52827 10.9554L8.55534 9.95676M0.388672 10.1109C0.389112 9.46783 0.549714 8.8349 0.856117 8.26869C1.16252 7.70249 1.60517 7.22065 2.1445 6.86627C2.68383 6.51188 3.30301 6.296 3.9467 6.23791C4.59039 6.17983 5.23851 6.28136 5.83312 6.53342M8.04492 1.91992C8.04492 2.32603 8.20625 2.71551 8.49341 3.00268C8.78058 3.28984 9.17006 3.45117 9.57617 3.45117C9.98228 3.45117 10.3718 3.28984 10.6589 3.00268C10.9461 2.71551 11.1074 2.32603 11.1074 1.91992C11.1074 1.51381 10.9461 1.12433 10.6589 0.837165C10.3718 0.55 9.98228 0.388672 9.57617 0.388672C9.17006 0.388672 8.78058 0.55 8.49341 0.837165C8.20625 1.12433 8.04492 1.51381 8.04492 1.91992ZM1.94423 2.72201C1.94423 3.34084 2.19006 3.93434 2.62764 4.37192C3.06523 4.80951 3.65872 5.05534 4.27756 5.05534C4.8964 5.05534 5.48989 4.80951 5.92748 4.37192C6.36506 3.93434 6.61089 3.34084 6.61089 2.72201C6.61089 2.10317 6.36506 1.50967 5.92748 1.07209C5.48989 0.634504 4.8964 0.388672 4.27756 0.388672C3.65872 0.388672 3.06523 0.634504 2.62764 1.07209C2.19006 1.50967 1.94423 2.10317 1.94423 2.72201Z"
    stroke="currentColor"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), { fill: 'none', viewBox: '0 0 14 14' });

GrantGroupAccessIcon.displayName = 'GrantGroupAccessIcon';

export default GrantGroupAccessIcon;
