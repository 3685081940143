import React from 'react';

import Svg from 'components/svg';

const Participants = Svg((
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59995 14.3334C6.0617 14.3334 4.58644 14.9445 3.49873 16.0322C2.41102 17.1199 1.79995 18.5951 1.79995 20.1334V20.9334H0.199951V20.1334C0.199951 18.1708 0.979592 16.2886 2.36736 14.9008C3.75513 13.513 5.63735 12.7334 7.59995 12.7334C9.56255 12.7334 11.4448 13.513 12.8325 14.9008C14.2203 16.2886 15 18.1708 15 20.1334V20.9334H13.4V20.1334C13.4 18.5951 12.7889 17.1199 11.7012 16.0322C10.6135 14.9445 9.13821 14.3334 7.59995 14.3334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18225 4.61572C5.08868 3.7093 6.31806 3.20007 7.59994 3.20007C8.88181 3.20007 10.1112 3.7093 11.0176 4.61572C11.924 5.52215 12.4333 6.75153 12.4333 8.03341C12.4333 9.31529 11.924 10.5447 11.0176 11.4511C10.1112 12.3575 8.88181 12.8667 7.59994 12.8667C6.31806 12.8667 5.08868 12.3575 4.18225 11.4511C3.27583 10.5447 2.7666 9.31529 2.7666 8.03341C2.7666 6.75153 3.27583 5.52215 4.18225 4.61572ZM7.59994 4.80007C6.7424 4.80007 5.91999 5.14073 5.31362 5.74709C4.70726 6.35346 4.3666 7.17587 4.3666 8.03341C4.3666 8.89094 4.70726 9.71335 5.31362 10.3197C5.91999 10.9261 6.7424 11.2667 7.59994 11.2667C8.45747 11.2667 9.27988 10.9261 9.88625 10.3197C10.4926 9.71335 10.8333 8.89094 10.8333 8.03341C10.8333 7.17587 10.4926 6.35346 9.88625 5.74709C9.27988 5.14073 8.45747 4.80007 7.59994 4.80007Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9422 5.19225C17.4306 4.91428 16.8535 4.7796 16.2717 4.80244C15.6899 4.82528 15.1251 5.0048 14.6369 5.32203L13.966 5.7579L13.0943 4.41625L13.7651 3.98037C14.4949 3.50616 15.3393 3.23782 16.2089 3.20367C17.0786 3.16953 17.9414 3.37085 18.7061 3.78638C19.4709 4.20191 20.1092 4.81623 20.5538 5.56448C20.9984 6.31273 21.2326 7.16713 21.2319 8.03748C21.2311 8.90783 20.9954 9.76183 20.5495 10.5093C20.1036 11.2568 19.4642 11.87 18.6988 12.2842C17.9333 12.6984 17.0702 12.8982 16.2006 12.8626C15.3309 12.8269 14.4871 12.5571 13.7581 12.0816L13.088 11.6446L13.9621 10.3044L14.6322 10.7415C15.1198 11.0596 15.6844 11.2401 16.2661 11.2639C16.8478 11.2878 17.4252 11.1541 17.9373 10.877C18.4494 10.5999 18.8771 10.1897 19.1754 9.68965C19.4737 9.18962 19.6314 8.61833 19.6319 8.03609C19.6324 7.45386 19.4757 6.88229 19.1783 6.38174C18.8809 5.88119 18.4538 5.47023 17.9422 5.19225Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6906 14.4802C16.8416 14.2856 15.9596 14.2845 15.1101 14.4769L14.3298 14.6537L13.9763 13.0932L14.7565 12.9165C15.8402 12.671 16.9651 12.6724 18.0481 12.9207C19.1311 13.1689 20.1444 13.6577 21.0129 14.3507C21.8814 15.0437 22.5828 15.9232 23.0653 16.924C23.5477 17.9249 23.7988 19.0215 23.8 20.1326L23.8008 20.9326L22.2008 20.9342L22.2 20.1342C22.1991 19.2632 22.0022 18.4035 21.624 17.6188C21.2457 16.8341 20.6958 16.1446 20.0149 15.6013C19.3341 15.058 18.5397 14.6748 17.6906 14.4802Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

export default Participants;
