// @flow

import React from 'react';

import Svg from 'components/svg';

const Redo = Svg((
  <path d="M9.25554 8.15622H16.6515L13.7089 5.54553L15.1492 4L21 9.18797L15.1492 14.378L13.7089 12.8325L16.6301 10.2448H9.25554C8.39148 10.3045 7.58274 10.6817 6.99226 11.3002C6.40178 11.9187 6.07347 12.7327 6.07347 13.5781C6.07347 14.4235 6.40178 15.2375 6.99226 15.856C7.58274 16.4746 8.39148 16.8517 9.25554 16.9114H12.0376V19H9.25554C7.83546 18.923 6.49949 18.3183 5.52207 17.3099C4.54465 16.3015 4 14.9662 4 13.5781C4 12.19 4.54465 10.8547 5.52207 9.84632C6.49949 8.83797 7.83546 8.23319 9.25554 8.15622Z" fill="currentColor" />
));

Redo.displayName = 'Redo';

export default Redo;
