// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

export const getSecurityCodeText = (mfaChannelData: string) => {
  const headerText = (
    <Message
      id="Security code"
      comment="Message header for the text displayed after requesting a new security code"
    />
  );
  const bodyText = (
    <Message
      id="Your security code has been sent to {mfaChannel}."
      comment="Message displayed after successfully requesting a new security code"
      values={{
        mfaChannel: <strong>{mfaChannelData}</strong>,
      }}
    />
  );

  return {
    headerText,
    bodyText,
    hasInfoStyle: true,
  };
};

export default { };
