// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import Field from 'components/field';
import TextArea from 'components/text-area';

type Props = {
  message: MessageTranslator,
  required?: boolean,
};

export const MessageTemplateBody = ({ message, required }: Props) => {
  let label = message({ id: 'Message', comment: 'The field label in add message template page' });
  if (!required) {
    label = message({ id: 'Message (optional)', comment: 'The field label in add message template page which is optional' });
  }

  return (
    <Field
      name="body"
      label={label}
      placeholder={message({
        id: 'Enter message',
        comment: 'A placeholder in add message template page',
      })}
      component={TextArea}
      maxLength={4500}
      resize="vertical"
      minRows={5}
      maxRows={10}
      required={required}
    />
  );
};

MessageTemplateBody.defaultProps = {
  required: false,
};

export default localize<Props>(MessageTemplateBody);
