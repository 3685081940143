import Svg from 'components/svg';

const UseTemplate = Svg((
  <path
    d="M11 8.58333V14.4167M8.08333 11.5H13.9167M12 5.5V5L8.5 1.5H2C1.73478 1.5 1.48043 1.60536 1.29289 1.79289C1.10536 1.98043 1 2.23478 1 2.5V13.5C1 13.7652 1.10536 14.0196 1.29289 14.2071C1.48043 14.3946 1.73478 14.5 2 14.5H7"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
), { viewBox: '0 0 16 16', fill: 'none' });

UseTemplate.displayName = 'UseTemplate';

export default UseTemplate;
