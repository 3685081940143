import { isNumber, truncate } from 'lodash';

import type {
  PriceColumn,
  Sums,

} from 'data-validators/entity-schemas/document-box/product-table';

const PRICE_COL_TRUNCATE_LENGTH = 20;
const PRODUCT_COL_TRUNCATE_LENGTH = 40;

type Columns = {
  column: PriceColumn;
  columns: PriceColumn[];
}

const getFormattedPriceColumns = ({ column, columns }: Columns) => {
  const priceColumnLabel = column.label;
  const productColumnLabel = columns.find((c) => c.key === 'name')?.label || '';
  const truncatedPriceColumnLabel = truncate(priceColumnLabel, {
    length: PRICE_COL_TRUNCATE_LENGTH,
  });
  const truncatedProductColumnLabel = truncate(productColumnLabel, {
    length: PRODUCT_COL_TRUNCATE_LENGTH,
  });

  return {
    value: column.uuid,
    productLabel: truncatedProductColumnLabel,
    priceLabel: truncatedPriceColumnLabel,
  };
};

type Args = Columns & {
  boxId?: number;
  isBoxVisible?: boolean;
  sums: Sums;
};

export const getPriceColumns = ({
  boxId,
  columns,
  isBoxVisible,
  sums,
}: Args) => (
  columns
    .filter((column) => column.key.includes('price'))
    .map((column) => (
      {
        visible: isBoxVisible,
        column: {
          ...column,
          boxId,
          price: sums?.[column.key as keyof Sums],
          price_discounted: isNumber(sums[`${column.key}_discounted` as keyof Sums])
            ? sums[`${column.key}_discounted` as keyof Sums]
            : sums[column.key as keyof Sums],
        },
        formatted: getFormattedPriceColumns({ column, columns }),
      }
    ))
);
