// @flow
import { camelizeKeys } from 'utils/camelizer';

import {
  getMultiple,
  post,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

type AddContractLink = ({
  agreementId: number,
  targetAgreementId: number,
  linkType: string,
  targetExternalUrl?: string,
  targetExternalUrlTitle?: string,
}) => Promise<any>;

export const addContractLink: AddContractLink = ({
  agreementId,
  targetAgreementId,
  linkType,
  targetExternalUrl,
  targetExternalUrlTitle,
}) => (
  post({
    url: `/agreements/${agreementId}/links/`,
    body: {
      target_agreement_id: targetAgreementId,
      link_type: linkType,
      target_external_url: targetExternalUrl,
      target_external_url_title: targetExternalUrlTitle,
    },
  })
    .then(extractResponseBodyAsJSON)
);

type RemoveContractLink = ({
  agreementId: number,
  id: number,
}) => Promise<{}>;

export const removeContractLink: RemoveContractLink = ({ agreementId, id }) => (
  remove({
    url: `/agreements/${agreementId}/links/${id}`,
  })
    .then(extractResponseBodyAsJSON)
);

type FetchContractLinks = ({
  id: number,
}) => Promise<any>;

export const fetchContractLinks: FetchContractLinks = ({ id }) => (
  getMultiple({
    url: `/agreements/${id}/links/`,
    pagination: { offset: 0, limit: 100 },
  })
    .then((response) => response.json())
    .then((response) => camelizeKeys(response))
);
