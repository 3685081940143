// @flow

import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  put,
  post,
  remove,
  extractResponseBodyAsJSON,
  hasAnyEntity,
} from 'oneflow-client/core';
import type { SortParams } from 'oneflow-client/core';

import { accountSchema } from 'oneflow-client/workspaces';

export const permissionCategorySchema = new schema.Entity(
  'categories',
  {},
  {
    idAttribute: 'category',
  },
);

export const permissionSchema = new schema.Entity('permissions');

export const roleSchema = new schema.Entity('roles', {
  account: accountSchema,
  permissions: [permissionSchema],
});

export type NormalizedRoles = {
  entities: {
    roles: {
      [number]: Role,
    },
    permissions: {
      [number]: Permission,
    },
  },
  result: number | Array<number>,
  count: number,
}

type RoleNormalizer = (any) => NormalizedRoles;

export const normalizeRoles: RoleNormalizer = (roles) => ({
  ...normalize(roles.collection, [roleSchema]),
  count: roles.count,
});

export const normalizeRole: RoleNormalizer = (role) => ({
  ...normalize(role, roleSchema),
  count: 1,
});

type GetRoles = ({
  params?: {},
  pagination?: {},
  sorting?: SortParams,
  scope?: string,
  sort?: Array<string>,
}) => Promise<NormalizedRoles>;

export const getRoles: GetRoles = ({
  params,
  pagination,
  sorting = {
    attr: 'name',
    direction: 'asc',
  },
  sort,
}) => {
  if (sort) {
    return (
      getMultiple({
        url: '/roles/',
        pagination,
        params,
        sort,
      })
        .then(extractResponseBodyAsJSON)
        .then(normalizeRoles)
    );
  }

  return (
    getMultiple({
      url: '/roles/',
      pagination,
      params,
      sorting,
    })
      .then(extractResponseBodyAsJSON)
      .then(normalizeRoles)
  );
};

type RolePermission = {
  id: number,
  enable: boolean,
};

type UpdateRole = ({
  id: number,
  name: string,
  permissions: Array<RolePermission>,
}) => Promise<NormalizedRoles>;

export const updateRole: UpdateRole = ({ id, name, permissions }) => (
  put({
    url: `/roles/${id}`,
    body: {
      name,
      permissions,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeRole)
);

type PermissionCategory = ({
  permissions: Array<RolePermission>,
  categoryName: string,
  categoryLabel: string,
}) => Promise<NormalizedRoles>;

export type NormalizedPermissions = {
  entities: {
    permissions: {
      categories: Array<PermissionCategory>,
    },
  },
  count: number,
  result: Array<number>,
};

type CreateRole = ({
  accountId: number,
  name: string,
  scope: string,
}) => Promise<NormalizedRoles>;

export const createRole: CreateRole = ({ accountId, name, scope }) => (
  post({
    url: '/roles/',
    body: {
      account_id: accountId,
      name,
      scope,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeRole)
);

type RemoveRole = ({
  id: number,
}) => Promise<NormalizedRoles>;

export const removeRole: RemoveRole = ({ id }) => (
  remove({
    url: `/roles/${id}`,
  })
    .then(extractResponseBodyAsJSON)
);

export const hasAnyRole = hasAnyEntity({ url: '/roles/' });

type PermissionsNormalizer = (any) => NormalizedPermissions;

export const normalizePermissions: PermissionsNormalizer = (permissions) => ({
  ...normalize(permissions.collection, [permissionCategorySchema]),
  count: permissions.count,
});

type GetPermissions = ({
  pagination?: {},
  params?: {},
}) => Promise<NormalizedPermissions>;

export const getPermissions: GetPermissions = ({ params } = {}) => (
  getMultiple({ url: '/roles/permission/categories/', params })
    .then(extractResponseBodyAsJSON)
    .then(normalizePermissions)
);
