const ss = window.sessionStorage;

const SS_PREFIX = 'oneflow:';

const getItem = (keyName) => (
  ss.getItem(`${SS_PREFIX}${keyName}`)
);

const getInt = (keyName) => {
  const value = ss.getItem(`${SS_PREFIX}${keyName}`);
  if (value === null) {
    return value;
  }

  return parseInt(value, 10);
};

const getString = getItem;

const setItem = (keyName, keyValue) => (
  ss.setItem(`${SS_PREFIX}${keyName}`, keyValue)
);

const removeItem = (keyName) => (
  ss.removeItem(`${SS_PREFIX}${keyName}`)
);

const clear = () => (
  ss.clear()
);

export default {
  getItem,
  getInt,
  getString,
  setItem,
  removeItem,
  clear,
};
