/* eslint-disable react/prop-types */

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { localize } from '@oneflowab/pomes';
import get from 'lodash/get';

import { getSignup, postCompleteSignup, postInviteSignup } from 'oneflow-client/signup';
import PublicForm from 'hocs/public-form';
import { getErrorMessages } from 'components/api-error';

import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getLanguageOption } from 'components/languages';
import SignupFlow from 'components/signup-flow';
import Wrapper from 'components/public-wrapper';

import SignupInvite from 'routes/public/signup/signup-invite';
import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeSetUserId } from 'client-analytics/amplitude';

const navigateToRoot = () => new Promise(() => {
  window.location.replace('/');
});

export const isSubmitDisabled = (isInvite) => (formProps) => {
  const {
    hasSubmitErrors,
    dirtySinceLastSubmit,
    validating,
    submitting,
    pristine,
    invalid,
    values,
    hasValidationErrors,
  } = formProps;

  if (!isInvite && (!values || !values.hasAgreedToTerms)) {
    return true;
  }

  if (hasSubmitErrors && dirtySinceLastSubmit && !hasValidationErrors) {
    return false;
  }

  return !!(validating || submitting || pristine || invalid);
};

export const SignupWithTokenComponent = ({
  message, token = '', onInvalidToken,
}) => {
  const [emailFromToken, setEmailFromToken] = useState(undefined);
  const [inviteResponse, setInviteResponse] = useState(undefined);
  const [errorCode, setErrorCode] = useState(undefined);
  const [customMessage, setCustomMessage] = useState(undefined);

  const defaultLanguage = useSelector((state) => (
    getLanguageOption(getCurrentLanguageSelector(state))
  ));

  const onInitSuccess = useCallback((response) => {
    const isInvited = response.state === 2;
    const userId = response.id;
    amplitudeSetUserId(userId); // set user id before the identify call
    const identifyObj = new amplitude.Identify();
    amplitude.identify(identifyObj);

    setEmailFromToken(response.email);
    setInviteResponse(isInvited ? response : undefined);
  }, [setEmailFromToken, setInviteResponse]);

  const onInitFailure = useCallback((error) => {
    let reason = 'invalidToken';
    const errorNumber = get(error, 'body.api_error_code');
    if (errorNumber === 1001) {
      reason = 'userIsAlreadyRegistered';
    }

    onInvalidToken(reason);
  }, [onInvalidToken]);

  const onInit = useCallback(() => {
    getSignup({
      token: token || '',
    })
      .then(onInitSuccess)
      .catch(onInitFailure);
  }, [token, onInitSuccess, onInitFailure]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  const storeCredentials = (password) => () => {
    if (!window.PasswordCredential) {
      return Promise.resolve();
    }
    const credentials = new window.PasswordCredential({ id: emailFromToken, password });

    return navigator.credentials.store(credentials);
  };

  const onSignupFailure = (error) => {
    const errorNumber = get(error, 'body.api_error_code');
    const isBadRequest = error?.body?.status_code === 400;

    if (!errorNumber && isBadRequest) {
      const apiErrors = getErrorMessages();
      setCustomMessage(apiErrors.createAccountBadRequest);
      return;
    }
    setErrorCode(errorNumber);
  };

  const onInviteSuccess = () => (
    navigateToRoot()
  );

  const onInvite = (values) => postInviteSignup({
    token: token || '',
    password: values.password,
  }).then(storeCredentials(values.password))
    .then(onInviteSuccess)
    .catch(onSignupFailure);

  const onSignupSuccess = () => {
    navigateToRoot();
  };

  const getArrayValues = (arr) => {
    if (!arr) {
      return [];
    }

    if (!Array.isArray(arr)) {
      return [arr.value];
    }

    return arr.map(({ value }) => value);
  };

  const onSignup = (values) => postCompleteSignup({
    token: token || '',
    password: values.password,
    name: values.name,
    companyName: values.companyName,
    country: values.country.value,
    language: values.language.value || defaultLanguage.value,
    companySize: values.companySize?.value,
    tools: values.tools && getArrayValues(values.tools),
    team: values.team?.value,
    usage: values.usage && getArrayValues(values.usage),
    jobLevel: values.jobLevel?.value,
  }).then(storeCredentials(values.password))
    .then(onSignupSuccess)
    .catch(onSignupFailure);

  const shouldRenderSomeForm = () => emailFromToken !== undefined;

  const renderInviteFields = () => {
    const languageCode = inviteResponse ? inviteResponse.language : undefined;

    return <SignupInvite languageCode={languageCode} />;
  };

  const renderCompleteFields = () => (<SignupFlow />);

  if (!shouldRenderSomeForm()) {
    return null;
  }

  const header = message({
    id: 'Complete registration for',
    comment: 'Complete signup page header. Used before an email row (and sometimes a company name) like "Complete registration for (email) at (company)".',
  });

  if (inviteResponse) {
    const invitingCompanyText = message({
      id: 'at {companyName}',
      values: {
        companyName: <strong>{inviteResponse.account.name}</strong>,
      },
      comment: 'Invite page subheader. Used after the header and email and should fit as the end of "Complete registration for (email) at (companyName)".',
    });

    return (
      <Wrapper>
        <PublicForm
          title={header}
          subheader={emailFromToken}
          optionalParagraph={invitingCompanyText}
          errorCode={errorCode}
          onSubmit={onInvite}
          submitText={message({
            id: 'Sign up',
            comment: 'Submit button text for the invite page.',
          })}
          isSubmitDisabled={isSubmitDisabled(true)}
          initialValues={{ language: defaultLanguage?.value }}
        >
          {renderInviteFields}
        </PublicForm>
      </Wrapper>
    );
  }

  return (
    <Wrapper noBackgroundColor>
      <PublicForm
        title={header}
        subheader={emailFromToken}
        errorCode={errorCode}
        onSubmit={onSignup}
        submitText={message({
          id: 'Create your free account',
          comment: 'Button text for completing the sign up flow.',
        })}
        isSignupWithToken
        isSubmitDisabled={isSubmitDisabled(false)}
        initialValues={{ language: defaultLanguage?.value }}
        keepDirtyOnReinitialize
        hideButton
        customMessage={customMessage}
      >
        {renderCompleteFields}
      </PublicForm>
    </Wrapper>
  );
};

export default localize(SignupWithTokenComponent);
