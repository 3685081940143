// @flow

import React from 'react';

import Svg from 'components/svg';

const Failed = Svg((
  <>
    <circle cx="35" cy="35" r="35" fill="currentColor" />
    <path d="M22.4157 18.7269L35.5 31.8107L48.5304 18.7807C48.7588 18.5377 49.0339 18.3432 49.3392 18.2091C49.6446 18.0749 49.9738 18.0038 50.3073 18C51.0213 18 51.7061 18.2836 52.211 18.7885C52.7159 19.2934 52.9996 19.9781 52.9996 20.6921C53.0058 21.0222 52.9446 21.3501 52.8196 21.6556C52.6946 21.9612 52.5085 22.2379 52.2727 22.469L39.1076 35.4989L52.2727 48.6635C52.7164 49.0976 52.9766 49.6854 52.9996 50.3057C52.9996 51.0197 52.7159 51.7045 52.211 52.2094C51.7061 52.7142 51.0213 52.9979 50.3073 52.9979C49.9642 53.0121 49.6219 52.9548 49.3021 52.8297C48.9823 52.7046 48.692 52.5143 48.4497 52.271L35.5 39.1872L22.4426 52.2441C22.2152 52.479 21.9434 52.6666 21.643 52.796C21.3427 52.9254 21.0197 52.994 20.6927 52.9979C19.9787 52.9979 19.2939 52.7142 18.789 52.2094C18.2841 51.7045 18.0004 51.0197 18.0004 50.3057C17.9942 49.9757 18.0554 49.6478 18.1804 49.3423C18.3054 49.0367 18.4915 48.7599 18.7273 48.5289L31.8924 35.4989L18.7273 22.3344C18.2836 21.9003 18.0234 21.3124 18.0004 20.6921C18.0004 19.9781 18.2841 19.2934 18.789 18.7885C19.2939 18.2836 19.9787 18 20.6927 18C21.3388 18.0081 21.958 18.2692 22.4157 18.7269Z" fill="white" />

  </>
), { viewBox: '0 0 70 70' });

Failed.displayName = 'Failed';

export default Failed;
