// @flow

import React from 'react';

import Svg from 'components/svg';

const CalendarAlert2 = Svg((
  <>
    <path
      d="M4.49988 0.5V3.06522V5.5M13.4999 0.5V3V5.5M8.99995 6.5L8.99988 11.5M0.499896 3C2.4999 3 6.24063 3 7.99988 3H12.9999C14.9999 3 15.9999 3 17.4999 3C17.4999 5 17.5 14.6175 17.5 17.5C14.9999 17.5 3.84566 17.5 0.499878 17.5C0.499943 14.6175 0.499878 5 0.499896 3Z"
      stroke="currentColor"
      strokeLinecap="square"
      fill="none"
    />
    <path
      d="M8.49988 14C8.49988 14.1326 8.55256 14.2598 8.64632 14.3536C8.74009 14.4473 8.86727 14.5 8.99988 14.5C9.13249 14.5 9.25966 14.4473 9.35343 14.3536C9.4472 14.2598 9.49988 14.1326 9.49988 14C9.49988 13.8674 9.4472 13.7402 9.35343 13.6464C9.25966 13.5527 9.13249 13.5 8.99988 13.5C8.86727 13.5 8.74009 13.5527 8.64632 13.6464C8.55256 13.7402 8.49988 13.8674 8.49988 14Z"
      stroke="currentColor"
      strokeLinecap="square"
    />
  </>
), { viewBox: '0 0 18 18' });

CalendarAlert2.displayName = 'CalendarAlert2';

export default CalendarAlert2;
