const ls = window.localStorage;

const LS_PREFIX = 'oneflow:';

export const getItem = (keyName: string): string | null => ls.getItem(`${LS_PREFIX}${keyName}`);

const getInt = (keyName: string): number | null => {
  const value = ls.getItem(`${LS_PREFIX}${keyName}`);
  if (value === null) return null;
  return parseInt(value, 10);
};

const getString = getItem;

export const setItem = (
  keyName: string,
  keyValue: boolean | string,
): void => {
  ls.setItem(`${LS_PREFIX}${keyName}`, String(keyValue));
};

export const removeItem = (keyName: string): void => {
  ls.removeItem(`${LS_PREFIX}${keyName}`);
};

type SetItemInContractListArgs = {
  visible: boolean;
  workspaceId: number | null | undefined;
  param: string;
};

export const setItemInContractList = ({
  visible,
  workspaceId,
  param,
}: SetItemInContractListArgs): void => {
  if (typeof workspaceId !== 'number') return;

  setItem(
    `contract_list_workspace_${workspaceId}_filter_${param}_visible_state`,
    visible,
  );
};

type GetItemInContractListArgs = {
  visible: boolean;
  workspaceId: number | null | undefined;
  param: string;
};

export const getItemInContractList = ({
  visible,
  workspaceId,
  param,
}: GetItemInContractListArgs): boolean => {
  if (typeof workspaceId !== 'number') {
    return visible;
  }

  const localStorageItem = getItem(
    `contract_list_workspace_${workspaceId}_filter_${param}_visible_state`,
  );

  if (localStorageItem !== null) {
    return JSON.parse(localStorageItem);
  }

  return visible;
};

const clear = (): void => {
  ls.clear();
};

const localStorageUtil = {
  getItem,
  getInt,
  getString,
  setItem,
  removeItem,
  clear,
  setItemInContractList,
  getItemInContractList,
};

export default localStorageUtil;
