import moment from 'moment';

function isValid(value) {
  let ssn = value;

  if (!ssn) {
    return false;
  }

  if (ssn.length !== 6 || Number.isNaN(Number(ssn))) {
    return false;
  }

  const firstDigit = Number(ssn[0]);
  const thirdDigit = Number(ssn[2]);

  const isFHNumber = firstDigit === 8 || firstDigit === 9;
  const isDNumber = firstDigit > 3 && firstDigit <= 7;
  const isHNumber = thirdDigit === 4 || thirdDigit === 5;

  if (isFHNumber || isHNumber) {
    return false;
  }

  // https://www.skatteetaten.no/en/person/foreign/norwegian-identification-number/d-number/
  if (isDNumber) {
    ssn = `${firstDigit - 4}${ssn.slice(1)}`;
  }

  ssn = `19${ssn.slice(4, 6)}-${ssn.slice(2, 4)}-${ssn.slice(0, 2)}`;

  return moment(ssn).isValid();
}

export default { isValid };
