const reorderOptions = (options, selectedValues, getOptionValue) => {
  if (!Array.isArray(options)) {
    return [];
  }

  if (!selectedValues || (Array.isArray(selectedValues) && selectedValues.length === 0)) {
    return options;
  }

  const selected = Array.isArray(selectedValues) ? selectedValues : [selectedValues];

  const selectedOptions = selected.map((selectedValue) => {
    if (typeof selectedValue === 'string' || typeof selectedValue === 'number') {
      const matchedOption = options.find((option) => getOptionValue(option) === selectedValue);
      return matchedOption || { value: selectedValue, label: selectedValue };
    }
    return selectedValue;
  });

  const remainingOptions = options.filter((option) => !selectedOptions.some(
    (selectedOption) => getOptionValue(option) === getOptionValue(selectedOption),
  ));

  const reorderedOptions = [...selectedOptions, ...remainingOptions];

  return reorderedOptions;
};
export default reorderOptions;
