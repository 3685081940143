import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const omitUndefinedValues = (obj) => {
  if (isArray(obj)) {
    return obj.map(omitUndefinedValues);
  }

  if (isObject(obj)) {
    const keys = Object.keys(obj);
    const newObject = {};
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const value = obj[key];

      if (value !== undefined) {
        newObject[key] = omitUndefinedValues(value);
      }
    }
    return newObject;
  }

  return obj;
};

export default omitUndefinedValues;
