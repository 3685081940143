import Svg from 'components/svg';

const InfoFilled = Svg((
  <>
    <rect width="24" height="24" rx="12" fill="currentColor" />
    <path d="M12 16V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="white" />
  </>

), { viewBox: '0 0 24 24', fill: 'none' });

InfoFilled.displayName = 'InfoFilled';

export default InfoFilled;
