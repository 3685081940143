type Options = {
  match: 'all' | 'any';
};

type Acl = {
  [permission: string]: 'allow' | 'deny';
};

const checkAcl = <T extends Acl | null | undefined>(acl: T, check: keyof NonNullable<T> | (keyof NonNullable<T>)[], options: Options = { match: 'all' }): boolean => {
  if (!acl) {
    return false;
  }

  const checkArray = Array.isArray(check) ? check : [check];

  if (options.match === 'any') {
    return checkArray.some((permission) => (acl as NonNullable<T>)[permission] === 'allow');
  }

  return checkArray.every((permission) => (acl as NonNullable<T>)[permission] === 'allow');
};

export default checkAcl;
