import Svg from 'components/svg';

const TemplateEmpty = Svg((
  <>
    <mask
      id="mask0_9351_22256"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="163"
      height="170"
    >
      <path d="M162.895 0H0V170H162.895V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_9351_22256)">
      <path
        d="M147.346 45.5977L146.274 46.6709L148.03 48.4277L149.104 47.3544L147.346 45.5977Z"
        fill="#99B0B7"
      />
      <path
        d="M151.649 49.8984L150.576 50.9717L152.332 52.7285L153.406 51.6552L151.649 49.8984Z"
        fill="#99B0B7"
      />
      <path
        d="M152.328 45.5977L150.57 47.3555L151.642 48.428L153.4 46.6702L152.328 45.5977Z"
        fill="#99B0B7"
      />
      <path
        d="M148.024 49.8984L146.265 51.6564L147.338 52.7288L149.096 50.971L148.024 49.8984Z"
        fill="#99B0B7"
      />
      <path
        d="M16.1311 88.7969L16.1304 90.323L17.6563 90.3226L17.6571 88.7969H16.1311Z"
        fill="#99B0B7"
      />
      <path
        d="M12.6089 92.3251L12.6084 93.8507L14.1344 93.8502L14.1349 92.3242L12.6089 92.3251Z"
        fill="#99B0B7"
      />
      <path
        d="M12.6206 85.2738L12.6199 86.7999L14.1458 86.7995L14.1466 85.2734L12.6206 85.2738Z"
        fill="#99B0B7"
      />
      <path d="M9.09181 88.8047L9.09106 90.3308H10.6171L10.6178 88.8047H9.09181Z" fill="#99B0B7" />
      <path
        d="M8.90698 70.1958L8.90649 71.7218L10.4325 71.7213L10.4329 70.1953L8.90698 70.1958Z"
        fill="#99B0B7"
      />
      <path
        d="M5.39963 73.7191L5.39893 75.2452L6.9249 75.2448L6.92564 73.7188L5.39963 73.7191Z"
        fill="#99B0B7"
      />
      <path
        d="M5.41213 66.6641L5.41138 68.1901L6.93739 68.1896L6.93809 66.6641H5.41213Z"
        fill="#99B0B7"
      />
      <path
        d="M1.88329 70.1997L1.88257 71.7253H3.40857L3.40929 70.1992L1.88329 70.1997Z"
        fill="#99B0B7"
      />
      <path
        d="M8.53932 72.2578L7.45996 73.337L8.53867 74.4157L9.61803 73.3365L8.53932 72.2578Z"
        fill="#99B0B7"
      />
      <path
        d="M3.56937 72.2656L2.48999 73.3452L3.56871 74.4239L4.6481 73.3443L3.56937 72.2656Z"
        fill="#99B0B7"
      />
      <path
        d="M8.55301 67.2852L7.47363 68.3647L8.55235 69.4434L9.63171 68.3639L8.55301 67.2852Z"
        fill="#99B0B7"
      />
      <path
        d="M3.57082 67.2852L2.49146 68.3647L3.57016 69.4434L4.64953 68.3639L3.57082 67.2852Z"
        fill="#99B0B7"
      />
      <path
        d="M152.555 33.0714V34.5882L155.008 34.5874L153.275 36.3206L154.348 37.3932L156.082 35.66L156.08 38.114L157.597 38.1136L157.598 35.6596L159.33 37.3916L160.403 36.3185L158.671 34.5863L161.125 34.5856L161.126 33.0688L158.671 33.0696L160.405 31.3364L159.332 30.2639L157.599 31.997L157.6 29.543L156.083 29.5434V31.9974L154.35 30.2654L153.278 31.3385L155.009 33.0706L152.555 33.0714ZM155.033 33.0829L156.088 32.0283L157.581 32.0278L158.635 33.0819L158.634 34.5741L157.58 35.6287L156.087 35.6292L155.033 34.5751V33.0829Z"
        fill="#99B0B7"
      />
      <path
        d="M144.166 148.079V149.595H146.619L144.886 151.328L145.96 152.4L147.692 150.667L147.691 153.121H149.209V150.667L150.942 152.399L152.015 151.326L150.282 149.594L152.737 149.593V148.077H150.283L152.016 146.344L150.943 145.272L149.21 147.004L149.211 144.551H147.695L147.694 147.004L145.962 145.273L144.889 146.346L146.62 148.079H144.166ZM146.645 148.09L147.699 147.036L149.192 147.035L150.246 148.089V149.582L149.191 150.637H147.698L146.644 149.583L146.645 148.09Z"
        fill="#99B0B7"
      />
      <g filter="url(#filter0_d_9351_22256)">
        <path
          d="M131.882 20.6758H25.85C23.9143 20.6758 22.345 22.2451 22.345 24.181V148.614C22.345 150.55 23.9143 152.119 25.85 152.119H131.882C133.817 152.119 135.386 150.55 135.386 148.614V24.181C135.386 22.2451 133.817 20.6758 131.882 20.6758Z"
          fill="white"
        />
        <path d="M95.077 53.5508H31.1079V57.398H95.077V53.5508Z" fill="#D6DFE2" />
        <path d="M126.623 35.3125H31.1079V39.1598H126.623V35.3125Z" fill="#D6DFE2" />
        <path d="M126.623 44.4297H31.1079V48.277H126.623V44.4297Z" fill="#D6DFE2" />
        <path d="M126.623 70.6055H31.1079V74.4528H126.623V70.6055Z" fill="#D6DFE2" />
        <path d="M126.623 79.7344H31.1079V83.5817H126.623V79.7344Z" fill="#D6DFE2" />
        <path d="M126.623 88.8516H31.1079V92.6989H126.623V88.8516Z" fill="#D6DFE2" />
        <path d="M126.623 115.035H31.1079V118.882H126.623V115.035Z" fill="#D6DFE2" />
        <path d="M126.623 105.906H31.1079V109.753H126.623V105.906Z" fill="#D6DFE2" />
        <path d="M126.623 132.09H31.1079V135.936H126.623V132.09Z" fill="#D6DFE2" />
      </g>
      <g filter="url(#filter1_d_9351_22256)">
        <path
          d="M130.533 47.7327C140.4 47.7327 148.397 39.7337 148.397 29.8663C148.397 19.999 140.4 12 130.533 12C120.666 12 112.667 19.999 112.667 29.8663C112.667 39.7337 120.666 47.7327 130.533 47.7327Z"
          fill="#FFF3D8"
        />
        <path d="M130.937 23V37.301" stroke="#99B0B7" strokeWidth="1.5" />
        <path d="M137.969 29.8438H123.668" stroke="#99B0B7" strokeWidth="1.5" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_9351_22256"
        x="3.04497"
        y="1.37578"
        width="151.641"
        height="170.041"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.65" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22256" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22256"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_9351_22256"
        x="104.667"
        y="12"
        width="43.7307"
        height="43.7344"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22256" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22256"
          result="shape"
        />
      </filter>
    </defs>
  </>
), {
  fill: 'none',
  viewBox: '0 0 164 170',
});

TemplateEmpty.displayName = 'TemplateEmpty';

export { TemplateEmpty };
