// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('a');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <mask id="b" fill="white">
          <rect width="11.6422" height="11.6422" rx="1.6436" />
        </mask>
        <rect
          width="11.6422"
          height="11.6422"
          stroke="currentColor"
          strokeWidth="3.72549"
          mask="url(#b)"
          rx="1.6436"
        />
        <mask id="c" fill="white">
          <rect width="11.6422" height="11.6422" x="16.2988" y="16.2993" rx="1.6436" />
        </mask>
        <rect
          width="11.6422"
          height="11.6422"
          x="16.2988"
          y="16.2993"
          stroke="currentColor"
          strokeWidth="3.72549"
          mask="url(#c)"
          rx="1.6436"
        />
        <mask id="d" fill="white">
          <rect width="11.6422" height="11.6422" y="16.2993" rx="1.6436" />
        </mask>
        <rect
          width="11.6422"
          height="11.6422"
          y="16.2993"
          stroke="currentColor"
          strokeWidth="3.72549"
          mask="url(#d)"
          rx="1.6436"
        />
        <mask id="e" fill="white">
          <rect width="11.6422" height="11.6422" x="16.436" rx="1.6436" />
        </mask>
        <rect
          width="11.6422"
          height="11.6422"
          x="16.436"
          stroke="currentColor"
          strokeWidth="3.72549"
          mask="url(#e)"
          rx="1.6436"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="currentColor" d="M0 0h27.9412v27.9412H0z" />
        </clipPath>
      </defs>
    </>
  );
};

const DashboardIcon = Svg(
  Fragment, { viewBox: '0 0 28 28', fill: 'none' },
);

DashboardIcon.displayName = 'DashboardIcon';

export default DashboardIcon;
