import createQueriesReducer from './queries';

export default createQueriesReducer;

// const queries = createQueriesReducer({ entity: 'agreements' });

// export const QUERY_AGREEMENTS = queries.actions.types.queryStart;

// const reducer = queries.reducer;

// const queryAgreements = queries.actions.queryStart;
// const queryAgreementsSuccess = queries.actions.querySuccess;
// const queryAgreementsFail = queries.action.queryFail;

// const getQuerySelector = (state, name) => (
//  queries.getQuerySelector(state.agreements.queries, name);
// );
