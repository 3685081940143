// @flow

import React, { useState } from 'react';

import type { Match, Location } from 'react-router';

import Wrapper from 'components/public-wrapper';
import PublicAppTitle from 'components/app-title';

import ResetPasswordStart from 'routes/public/reset-password/reset-password-start';
import ResetPasswordWithToken from 'routes/public/reset-password/reset-password-with-token';

type Props = {
  location?: Location,
  computedMatch?: Match,
};

const ResetPasswordPage = ({ location, computedMatch }: Props) => {
  const [hasInvalidToken, setHasInvalidToken] = useState(false);

  const onInvalidToken = () => {
    setHasInvalidToken(true);
    window.history.pushState({}, null, '/recover-account');
  };

  const renderPage = () => {
    if (hasInvalidToken || !computedMatch || !computedMatch.params.token) {
      return <ResetPasswordStart initialEmail={location.initialEmail} />;
    }

    return (
      <ResetPasswordWithToken
        token={computedMatch.params.token}
        onInvalidToken={onInvalidToken}
      />
    );
  };

  return (
    <Wrapper hasSignupLink>
      <PublicAppTitle title="Reset password" />
      {renderPage()}
    </Wrapper>
  );
};

export default ResetPasswordPage;
