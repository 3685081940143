// @flow

import React from 'react';

import Svg from 'components/svg';

const HorizontalLine = Svg((
  <>
    <path d="M9 3H15V5H9V3Z" fill="currentColor" />
    <path d="M9 15H15V17H9V15Z" fill="currentColor" />
    <path d="M2 11H22V13H2V11Z" fill="currentColor" />
    <path d="M10 7H14V9H10V7Z" fill="currentColor" />
    <path d="M10 19H14V21H10V19Z" fill="currentColor" />
  </>
), { viewBox: '0 0 24 24' });

HorizontalLine.displayName = 'HorizontalLine';

export default HorizontalLine;
