// @flow

import React from 'react';

import Svg from 'components/svg';

const PersonFrameIcon = Svg((
  <>
    <path
      d="M5.99925 6.76974C7.06133 6.76974 7.92232 5.90875 7.92232 4.84666C7.92232 3.78457 7.06133 2.92358 5.99925 2.92358C4.93716 2.92358 4.07617 3.78457 4.07617 4.84666C4.07617 5.90875 4.93716 6.76974 5.99925 6.76974Z"
      stroke="currentColor"
      strokeWidth="1.12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.23047 11.0005C2.40702 10.131 2.87872 9.34932 3.56565 8.78786C4.25259 8.22639 5.1125 7.91968 5.9997 7.91968C6.8869 7.91968 7.74681 8.22639 8.43374 8.78786C9.12068 9.34932 9.59238 10.131 9.76893 11.0005"
      stroke="currentColor"
      strokeWidth="1.12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2308 0.999756H1.76923C1.3444 0.999756 1 1.34415 1 1.76899V10.2305C1 10.6554 1.3444 10.9998 1.76923 10.9998H10.2308C10.6556 10.9998 11 10.6554 11 10.2305V1.76899C11 1.34415 10.6556 0.999756 10.2308 0.999756Z"
      stroke="currentColor"
      strokeWidth="1.12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 12 12', fill: 'none' });

PersonFrameIcon.displayName = 'PersonFrameIcon';

export default PersonFrameIcon;
