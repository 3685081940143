// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import map from 'lodash/map';

import { sortedParticipantRoles } from 'utils/participant/roles';

import Tooltip from 'components/tooltip';
import {
  availableParticipantRoles,
  internalApproverParticipantRole,
  externalApproverParticipantRole,
} from './constants';

export const participantRolesWithApprover = (
  message: MessageTranslator,
  hasAccessToInternalApproval: boolean,
  hasAccessToPendingStateApprover?: boolean,
) => {
  let roles = availableParticipantRoles(message);

  if (hasAccessToInternalApproval) {
    roles = {
      ...roles,
      ...internalApproverParticipantRole(message),
    };
  }

  if (hasAccessToPendingStateApprover) {
    roles = {
      ...roles,
      ...externalApproverParticipantRole(message),
    };
  }

  return roles;
};

export const participantRoles = (
  message: MessageTranslator,
  hasAccessToPendingStateApprover?: boolean,
) => {
  let roles = availableParticipantRoles(message);

  if (hasAccessToPendingStateApprover) {
    roles = {
      ...roles,
      ...externalApproverParticipantRole(message),
    };
  }

  return roles;
};

export const getParticipantRole = (
  participantRole?: ParticipantRoleType,
  message: MessageTranslator,
) => (
  participantRoles(message)[participantRole]
);

export const getParticipantRolesAsOptions = (
  message: MessageTranslator,
  hasAccessToPendingStateApprover?: boolean,
) => {
  const roles = map(participantRoles(message, hasAccessToPendingStateApprover), (item) => ({
    label: item.label,
    value: item.value,
    name: item.name,
  }));

  return sortedParticipantRoles(roles);
};

export const getParticipantRolesWithApproverAsOptions = (
  message: MessageTranslator,
  hasAccessToInternalApproval: boolean,
  hasAccessToPendingStateApprover?: boolean,
) => {
  const roles = map(participantRolesWithApprover(
    message,
    hasAccessToInternalApproval,
    hasAccessToPendingStateApprover,
  ),
  (item) => ({
    label: item.label,
    value: item.value,
    name: item.name,
  }));

  return sortedParticipantRoles(roles);
};

type Props = {
  participantRole?: ParticipantRoleType,
  withTooltip?: boolean,
  message: MessageTranslator,
};

export class ParticipantRoleComponent extends React.PureComponent<Props> {
  static defaultProps = {
    participantRole: undefined,
    withTooltip: false,
  };

  renderParticipantRoleWithTooltip() {
    const {
      participantRole,
      message,
    } = this.props;

    const role = getParticipantRole(
      participantRole,
      message,
    );

    return (
      <Tooltip
        message={role?.description}
        side="top"
      >
        <span>{role?.label}</span>
      </Tooltip>
    );
  }

  render() {
    const {
      participantRole,
      message,
      withTooltip,
    } = this.props;

    const role = getParticipantRole(
      participantRole,
      message,
    );

    if (!role) {
      return null;
    }

    if (withTooltip) {
      return this.renderParticipantRoleWithTooltip();
    }

    return role.label;
  }
}

export default localize<Props>(ParticipantRoleComponent);
