// @flow

import React from 'react';

import Svg from 'components/svg';

const Send = Svg((
  <g>
    <path
      d="M16.8699 1.00549L4.59005 11.2423M7.48033 14.1326L10.2858 16.9342C10.4599 17.1084 10.6757 17.2351 10.9126 17.3024C11.1495 17.3698 11.3997 17.3754 11.6394 17.3189C11.8792 17.2623 12.1004 17.1455 12.2823 16.9793C12.4641 16.8132 12.6005 16.6034 12.6785 16.3698L17.2826 2.54449C17.3676 2.29 17.3801 2.01685 17.3185 1.75569C17.257 1.49453 17.1239 1.25569 16.9341 1.06596C16.7444 0.876234 16.5056 0.743127 16.2444 0.681573C15.9833 0.62002 15.7101 0.632456 15.4556 0.717486L1.63033 5.32549C1.39744 5.40375 1.18834 5.54009 1.0228 5.72164C0.857252 5.90319 0.740728 6.12395 0.684232 6.36305C0.627737 6.60216 0.633135 6.85173 0.699919 7.08817C0.766702 7.32461 0.892664 7.54012 1.0659 7.71434L4.59005 11.2423L4.47048 15.6973L7.48033 14.1326Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </g>
), { viewBox: '0 0 18 18', fill: 'none' });

Send.displayName = 'Send';

export default Send;
