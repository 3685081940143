// @flow

import React from 'react';

import Svg from 'components/svg';

const Italic = Svg((
  <path fillRule="evenodd" clipRule="evenodd" d="M14 5H16.9998L17 7H13.6584L11.9506 17H15V19H11.609H9H6V17H9.34157L11.0494 7H8V5H11.391H14Z" fill="currentColor" />
));

Italic.displayName = 'Italic';

export default Italic;
