import { get, post, extractResponseBodyAsJSON } from 'oneflow-client/core';

export const postSignup = ({
  email,
  startTrial,
  queryValues,
  captchaResponseToken,
}) => {
/* eslint-disable camelcase */
  const {
    gclid,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  } = queryValues;

  return post({
    url: '/positions/signup',
    body: {
      email,
      start_trial: startTrial,
      gclid,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
      captcha_response_token: captchaResponseToken,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }).then(extractResponseBodyAsJSON);
};

export const postSignupGuestConversion = ({
  agreementId,
  participantId,
  password,
  language,
  guestToken,
}) => post({
  url: `/agreements/${agreementId}/participants/${participantId}/convert`,
  body: {
    password,
    language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
}, {
  token: guestToken,
}).then(extractResponseBodyAsJSON);

export const getSignup = ({ token }) => (
  get({
    url: '/positions/signup_step2',
    params: {
      private_token: token,
    },
  }).then(extractResponseBodyAsJSON)
);

export const postCompleteSignup = ({
  token,
  name,
  password,
  companyName,
  companySize,
  country,
  language,
  tools,
  team,
  usage,
  jobLevel,
}) => post({
  url: '/positions/signup_step2',
  body: {
    company_country: country,
    company_name: companyName,
    fullname: name,
    language,
    password,
    private_token: token,
    company_size: companySize,
    tools_used: tools,
    team,
    intended_use: usage,
    job_level: jobLevel,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
}).then(extractResponseBodyAsJSON);

export const postInviteSignup = ({
  token,
  password,
}) => (
  post({
    url: '/positions/signup_step2',
    body: {
      password,
      private_token: token,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }).then(extractResponseBodyAsJSON)
);
