// @flow

import React from 'react';

import Svg from 'components/svg';

const Workspace = Svg(
  (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.20092 15.2437C6.79996 16.8278 5.365 18 3.65625 18C1.63696 18 0 16.363 0 14.3438C0 12.6149 1.19998 11.1662 2.8125 10.7853V7.21466C1.19998 6.83376 0 5.38514 0 3.65625C0 1.63696 1.63696 0 3.65625 0C5.36496 0 6.7999 1.17213 7.20089 2.7562H10.7991C11.2001 1.17213 12.635 0 14.3438 0C16.363 0 18 1.63696 18 3.65625C18 5.34469 16.8555 6.76582 15.3 7.18618V10.8138C16.8555 11.2342 18 12.6553 18 14.3438C18 16.363 16.363 18 14.3438 18C12.635 18 11.2 16.8278 10.7991 15.2437H7.20092ZM6.0125 3.65625C6.0125 4.95757 4.95757 6.0125 3.65625 6.0125C2.35493 6.0125 1.3 4.95757 1.3 3.65625C1.3 2.35493 2.35493 1.3 3.65625 1.3C4.95757 1.3 6.0125 2.35493 6.0125 3.65625ZM4.6125 7.18618C5.88192 6.84313 6.8776 5.8336 7.20092 4.5562H10.7991C11.1319 5.8711 12.1771 6.90218 13.5 7.21466V10.7853C12.1772 11.0978 11.132 12.1288 10.7991 13.4437H7.20089C6.87754 12.1663 5.88188 11.1569 4.6125 10.8138V7.18618ZM6.0125 14.3438C6.0125 15.6451 4.95757 16.7 3.65625 16.7C2.35493 16.7 1.3 15.6451 1.3 14.3438C1.3 13.0424 2.35493 11.9875 3.65625 11.9875C4.95757 11.9875 6.0125 13.0424 6.0125 14.3438ZM14.3438 6.0125C15.6451 6.0125 16.7 4.95757 16.7 3.65625C16.7 2.35493 15.6451 1.3 14.3438 1.3C13.0424 1.3 11.9875 2.35493 11.9875 3.65625C11.9875 4.95757 13.0424 6.0125 14.3438 6.0125ZM16.7 14.3438C16.7 15.6451 15.6451 16.7 14.3438 16.7C13.0424 16.7 11.9875 15.6451 11.9875 14.3438C11.9875 13.0424 13.0424 11.9875 14.3438 11.9875C15.6451 11.9875 16.7 13.0424 16.7 14.3438Z"
      fill="currentColor"
    />

  ), { viewBox: '0 0 18 18', fill: 'none' },
);

Workspace.displayName = 'Workspace';

export default Workspace;
