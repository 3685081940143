// @flow

import React from 'react';

import Svg from 'components/svg';

const ShowTableBorders = Svg((
  <path fillRule="evenodd" clipRule="evenodd" d="M11 6H6.00195V11H11V6ZM13 6V11H18.002V6H13ZM11 13H6.00195V18H11V13ZM13 18V13H18.002V18H13ZM6.00195 4H4.00195V6V18V20H6.00195H18.002H20.002V18V6V4H18.002H6.00195Z" fill="currentColor" />
), { viewBox: '0 0 24 24', fill: 'none' });

ShowTableBorders.displayName = 'ShowTableBorders';

export default ShowTableBorders;
