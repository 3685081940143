import Svg from 'components/svg';
import { uniqueId } from 'lodash';

const Fragment = () => {
  const id = uniqueId('clip0_15800_47241');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M26.4282 29.0721C33.7262 29.0721 39.6424 23.1559 39.6424 15.8578C39.6424 8.5598 33.7262 2.64355 26.4282 2.64355C19.1301 2.64355 13.2139 8.5598 13.2139 15.8578C13.2139 23.1559 19.1301 29.0721 26.4282 29.0721Z" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.3569 66.0781H2.64258V63.2127C2.68466 59.1839 3.74717 55.2313 5.73086 51.7246C7.71459 48.2178 10.5548 45.2709 13.9861 43.1593C17.4175 41.0477 21.3281 39.8402 25.3525 39.6497C29.3771 39.4592 33.3842 40.2918 36.9997 42.0698" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M58.2 52.8V63H54.8V52.8H58.2Z" fill="currentColor" />
        <path d="M54.6969 50.3531C54.2187 49.8749 53.95 49.2263 53.95 48.55C53.95 47.8737 54.2187 47.2251 54.6969 46.7469C55.1751 46.2687 55.8237 46 56.5 46C57.1763 46 57.8249 46.2687 58.3031 46.7469C58.7813 47.2251 59.05 47.8737 59.05 48.55C59.05 49.2263 58.7813 49.8749 58.3031 50.3531C57.8249 50.8313 57.1763 51.1 56.5 51.1C55.8237 51.1 55.1751 50.8313 54.6969 50.3531Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M44.4792 42.4792C47.6673 39.2911 51.9913 37.5 56.5 37.5C61.0087 37.5 65.3327 39.2911 68.5208 42.4792C71.7089 45.6673 73.5 49.9913 73.5 54.5C73.5 59.0087 71.7089 63.3327 68.5208 66.5208C65.3327 69.7089 61.0087 71.5 56.5 71.5C51.9913 71.5 47.6673 69.7089 44.4792 66.5208C41.2911 63.3327 39.5 59.0087 39.5 54.5C39.5 49.9913 41.2911 45.6673 44.4792 42.4792ZM56.5 40.9C52.8931 40.9 49.4338 42.3329 46.8833 44.8833C44.3329 47.4338 42.9 50.8931 42.9 54.5C42.9 58.1069 44.3329 61.5662 46.8833 64.1167C49.4338 66.6671 52.8931 68.1 56.5 68.1C60.1069 68.1 63.5662 66.6671 66.1167 64.1167C68.6671 61.5662 70.1 58.1069 70.1 54.5C70.1 50.8931 68.6671 47.4338 66.1167 44.8833C63.5662 42.3329 60.1069 40.9 56.5 40.9Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="74" height="74" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const SingleParticipant = Svg(Fragment, { viewBox: '0 0 74 74', fill: 'none' });

SingleParticipant.displayName = 'SingleParticipant';

export default SingleParticipant;
