// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { hasAnyTag } from 'oneflow-client/tags';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  tagId?: number,
  autoFocus?: boolean,
};

export const uniqueTagName = (tagId: number) => ({ params }: { params: any }) => (
  hasAnyTag({ params, entityId: tagId })
);

export const TagName = ({ message, tagId, autoFocus }: Props) => (
  <Field
    name="name"
    label={message({
      id: 'Tag name',
      comment: 'Label for the name field for a tag.',
    })}
    placeholder={message({
      id: 'Enter tag name',
      comment: 'Name placeholder for a tag.',
    })}
    autoFocus={autoFocus}
    component={TextField}
    maxLength={30}
    responsive
    unique={{
      text: message({
        id: 'A tag with this name already exists. Try another one.',
        comment: 'Form validation error message.',
      }),
      param: 'name',
      request: uniqueTagName(tagId),
    }}
    required
    autoComplete="off"
  />
);

TagName.defaultProps = {
  tagId: undefined,
  autoFocus: undefined,
};

export default localize<Props>(TagName);
