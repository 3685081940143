// @flow

import React from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import CircularSpinner from 'components/icons/circular-spinner';
import Button from 'components/button';
import Field from 'components/field';
import TextField from 'components/text-field';

import style from './two-factor-authentication.module.scss';

export type Props = {|
  requestMfaState: RpcState,
  onAuthenticationTokenRequest: () => void,
  message: MessageTranslator,
|};

type State = {|
  countdown: number,
|};

export const RETRY_TIME = 30;

export class TwoFactorAuthenticationComponent extends React.Component<Props, State> {
  state: State = {
    countdown: 0,
  };

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    this.clearCountdown();
  }

  countdown = () => {
    const { countdown } = this.state;

    if (countdown === 0) {
      return;
    }

    this.setState((prevState: State) => ({
      countdown: prevState.countdown - 1,
    }));

    this.countdownTimeout = setTimeout(this.countdown, 1000);
  };

  startCountdown = () => {
    this.clearCountdown();

    this.setState({
      countdown: RETRY_TIME,
    });

    this.countdownTimeout = setTimeout(this.countdown, 1000);
  };

  clearCountdown = () => {
    if (this.countdownTimeout) {
      clearTimeout(this.countdownTimeout);
      this.countdownTimeout = undefined;
    }
  };

  handleAuthenticationTokenRequest = () => {
    const { onAuthenticationTokenRequest } = this.props;

    onAuthenticationTokenRequest();
    this.startCountdown();
  };

  countdownTimeout: ?TimeoutID;

  renderSecurityCodeField() {
    const { message } = this.props;
    const codeMessage = message({
      id: 'Security code',
      comment: 'Security code label and placeholder on the two-step authentication page.',
    });

    return (
      <div className={style.MfaCode}>
        <Field
          autoFocus
          hideErrorsUntilTouched
          name="securityCode"
          component={TextField}
          label={codeMessage}
          placeholder={codeMessage}
          maxLength={20}
          responsive
          required
          hideRequired
          pattern={{
            regexp: /^[a-zA-Z0-9]+$/,
          }}
        />
      </div>
    );
  }

  render() {
    const { countdown } = this.state;
    const { requestMfaState } = this.props;

    return (
      <>
        {this.renderSecurityCodeField()}
        <div className={style.Resend}>
          <Message
            id="Didn't receive a security code?"
            comment="Information about requesting a new multi-step authentication token"
          />
          <Button
            data-testid="resend-button"
            outline
            icon={requestMfaState.loading ? <CircularSpinner height="12px" /> : undefined}
            customClass={style.ResendButton}
            disabled={Boolean(countdown) || requestMfaState.loading}
            onClick={this.handleAuthenticationTokenRequest}
          >
            <Message
              id="Resend"
              comment="Label for the button to request a new security code"
            />
          </Button>
        </div>
        <Message
          id="You can request a new one in {count} seconds."
          values={{
            count: RETRY_TIME,
          }}
          comment="Information about requesting a new multi-step authentication token"
        />
      </>
    );
  }
}

export default localize<Props>(TwoFactorAuthenticationComponent);
