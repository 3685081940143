// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_697_18427');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.85 2.85001H9.15V4.15001H7.15V19.85H9.15V21.15H3.85V19.85H5.85V4.15001H3.85V2.85001ZM8.85 9.15001V7.85001L24.15 7.85V16.15H8.85V14.85H22.85V9.15L8.85 9.15001ZM-0.150055 7.85004L4.15 7.85001V9.15001L1.14994 9.15001L1.14994 14.85H4.15V16.15L-0.150063 16.15L-0.150055 7.85004Z"
          fill="#013A4C"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const EmptyFormBox = Svg(Fragment,
  {
    fill: 'none',
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
  });

EmptyFormBox.displayName = 'EmptyFormBox';

export default EmptyFormBox;
