// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import * as validators from 'forms/validators';
import type { UniqueParams } from 'forms/validators/unique';

import Field from 'components/field';
import PhoneNumber from 'components/phone-number';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
  hideLabel?: boolean,
  unique?: UniqueParams,
  autoComplete?: string,
  hideErrorsUntilTouched?: boolean,
  required?: boolean,
  hideRequired?: boolean,
  initialValue?: string,
  defaultCountry?: string,
  preferredCountries?: Array<string>,
  disabled?: boolean,
  validateFields?: Array<string>,
  validate?: Array<any>,
  displayErrorEarly?: boolean,
  setCountryCode?: () => void,
  setCountry?: () => void,
  setInputFieldCurrentValue?: () => void,
  onCountryChangeState?: string,
};

export const PhoneField = ({
  message,
  autoFocus,
  hideLabel,
  unique,
  autoComplete,
  hideErrorsUntilTouched,
  required = true,
  hideRequired = false,
  initialValue,
  defaultCountry,
  preferredCountries,
  disabled,
  validateFields,
  validate,
  displayErrorEarly = false,
  setCountryCode,
  setCountry,
  setInputFieldCurrentValue,
  onCountryChangeState,
}: Props) => {
  const fieldLabel = message({
    id: 'Phone number',
    comment: 'Label for a phone number input field.',
  });

  const phoneValidations = (() => {
    if (required) {
      return validators.composeValidators(
        validators.requiredPhoneNumber({
          message,
          field: fieldLabel,
        }),
        validate,
      );
    }

    return validate;
  })();

  return (
    <Field
      key={required}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      hideErrorsUntilTouched={hideErrorsUntilTouched}
      name="phoneNumber"
      component={PhoneNumber}
      label={fieldLabel}
      placeholder={message({
        id: 'Enter phone number',
        comment: 'Placeholder for a phone number input field.',
      })}
      maxLength={70}
      hideLabel={hideLabel}
      unique={unique}
      responsive
      required={required}
      hideRequired={hideRequired}
      initialValue={initialValue}
      defaultCountry={defaultCountry}
      preferredCountries={preferredCountries}
      disabled={disabled}
      validateFields={validateFields}
      validate={phoneValidations}
      displayErrorEarly={displayErrorEarly}
      setCountryCode={setCountryCode}
      setCountry={setCountry}
      setInputFieldCurrentValue={setInputFieldCurrentValue}
      onCountryChangeState={onCountryChangeState}
    />
  );
};

export default localize<Props>(PhoneField);
