import { Validator } from './index';

interface RoleValidatorParams {
  customErrorMessage: string;
}

type RoleValidator = (params: RoleValidatorParams) => Validator;

const role: RoleValidator = ({ customErrorMessage }) => (value: string) => {
  if (!value && !customErrorMessage) {
    return undefined;
  }

  return customErrorMessage;
};

export default role;
