import Svg from 'components/svg';

export const Book = Svg((
  <>
    <g clipPath="url(#clip0_524_10415)">
      <path d="M12.3333 3.86334C13.3327 3.70202 14.343 3.61871 15.3553 3.61417C15.4424 3.61364 15.5267 3.64552 15.5917 3.70362C15.6528 3.76456 15.6873 3.84728 15.6875 3.93362V13.9961C15.6859 14.0803 15.6517 14.1606 15.5921 14.2202C15.5326 14.2797 15.4523 14.3139 15.3681 14.3156C10.0717 14.3922 8.5 16.0725 8.5 16.0725M8.5 16.0725V5.35834C8.5 5.35834 6.93472 3.68445 1.64472 3.61417C1.55756 3.61364 1.47331 3.64552 1.40833 3.70362C1.34718 3.76456 1.31271 3.84728 1.3125 3.93362V13.9961C1.31414 14.0803 1.34832 14.1606 1.40787 14.2202C1.46743 14.2797 1.54773 14.3139 1.63194 14.3156C6.92833 14.3922 8.5 16.0725 8.5 16.0725Z" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 15.7595C8.5998 14.9302 8.96772 14.1562 9.54778 13.5553C10.2892 12.7532 11.2634 12.2035 12.3333 11.9836V2.01056C12.3335 1.9588 12.32 1.9079 12.2942 1.86303C12.2684 1.81815 12.2312 1.78089 12.1864 1.75501C12.1399 1.72678 12.088 1.70876 12.034 1.70215C11.98 1.69554 11.9252 1.70049 11.8733 1.71667C10.9777 1.97293 10.172 2.47541 9.54778 3.16695C8.97765 3.76966 8.61115 4.53617 8.5 5.35834" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_524_10415">
        <rect width="16" height="16" fill="white" transform="translate(0.5 0.88501)" />
      </clipPath>
    </defs>
  </>
), {
  viewBox: '0 0 17 17',
  fill: 'none',
});

Book.displayName = 'Book';
