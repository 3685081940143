// @flow

import React, { createRef } from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import get from 'lodash/get';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import type { FormRenderProps } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import PublicForm from 'hocs/public-form';
import { postSignup } from 'oneflow-client/signup';
import { getErrorMessages, getErrorMessage } from 'components/api-error';
import LoginLink from 'components/login-link';
import { EmailField } from 'components/fields';
import ArrowLeft from 'components/icons/arrow-left';

import SignupStartConfirmation from './signup-start-confirmation';

import style from './signup-start.module.scss';

type Props = {
  initialEmail?: string,
  queryValues: { [any]: string },
  invalidTokenReason: string,
  message: MessageTranslator,
};

type State = {
  signupEmail?: string,
  errorCode?: number,
};

export const isSubmitDisabled = (formProps: FormRenderProps) => {
  const {
    hasSubmitErrors,
    dirtySinceLastSubmit,
    validating,
    submitting,
    pristine,
    invalid,
    hasValidationErrors,
  } = formProps;

  if (hasSubmitErrors && dirtySinceLastSubmit && !hasValidationErrors) {
    return false;
  }

  return !!(validating || submitting || pristine || invalid);
};

export class SignupStartComponent extends React.Component<Props, State> {
  static defaultProps = {
    initialEmail: '',
  };

  state = {
    signupEmail: undefined,
    errorCode: undefined,
    captchaToken: undefined,
  }

  recaptchaRef: { current: null | ReCAPTCHA } = createRef();

  componentDidMount() {
    const pageViewEvent = 'Pageview';
    const pageViewEventProp = {
      'page url': '/signup',
      'page title': document.title,
      'page path': '/signup',
      domain: window.location.hostname,
    };

    amplitudeLogEvent(pageViewEvent, pageViewEventProp);
  }

  onCaptchaChange = (token) => {
    this.setState({
      captchaToken: token,
    });
  };

  resetCaptcha = () => {
    const { current } = this.recaptchaRef;

    this.setState({
      captchaToken: undefined,
    });

    if (!current) {
      return;
    }

    current.reset();
  };

  getErrorTexts() {
    const { invalidTokenReason } = this.props;
    const { errorCode } = this.state;
    const apiErrors = getErrorMessages();

    if (!errorCode && invalidTokenReason) {
      return apiErrors[invalidTokenReason];
    }

    return getErrorMessage(errorCode);
  }

  onSuccess = (response: Object) => {
    this.setState({
      signupEmail: response.email,
    });
  }

  onFailure = (error: Object) => {
    this.resetCaptcha();
    const errorCode = get(error, 'body.api_error_code');
    this.setState({ errorCode });
  }

  onSubmit = (values: Object) => {
    const { queryValues } = this.props;

    return postSignup({
      email: values.email,
      startTrial: true,
      queryValues,
      captchaResponseToken: this.state.captchaToken,
    })
      .then(this.onSuccess)
      .catch(this.onFailure);
  }

  shouldRenderConfirmation() {
    return this.state.signupEmail !== undefined;
  }

  renderFooter = (props: Props) => {
    const { queryValues } = this.props;

    if (queryValues.origin === 'website') {
      return (
        <div className={style.FooterLinks}>
          <a
            href="https://oneflow.com/"
            rel="noopener noreferrer"
          >
            <ArrowLeft className={style.Icon} />
            <Message
              id="Back to website"
              comment="Link text for going back from our app to our webiste."
            />
          </a>
        </div>
      );
    }

    return (
      <div className={style.FooterLinks}>
        {LoginLink(props)}
      </div>
    );
  };

  render() {
    const { initialEmail, message } = this.props;
    const { signupEmail, captchaToken } = this.state;
    const hasCaptchaToken = Boolean(captchaToken);

    if (this.shouldRenderConfirmation()) {
      return <SignupStartConfirmation signupEmail={signupEmail} />;
    }

    return (
      <PublicForm
        title={(
          <Message
            id="Try Oneflow for Free!"
            comment="Signup page header for trials"
          />
        )}
        customMessage={this.getErrorTexts()}
        onSubmit={this.onSubmit}
        submitText={message({
          id: 'Sign up',
          comment: 'Submit button text for the signup page.',
        })}
        hasCaptchaToken={hasCaptchaToken}
        isSubmitDisabled={isSubmitDisabled}
        initialValues={{ email: initialEmail }}
        footer={this.renderFooter}
        buttonClass="gtm-signup-start"
        isSignup
      >
        {() => (
          <>
            <p>
              <Message
                id="Access the extensive features available in our premium package for 14 days. When your trial is over, the basic features of Oneflow, such as e-signing and uploading PDFs, will be free to you forever."
                comment="Signup page body text. Used for explaining that you will be able to use the full application after signing up."
              />
            </p>
            <p>
              <Message
                id="You can always upgrade your account to continue experiencing the magic of truly digital contracts 💫"
                comment="Signup page body text. Used for explaining that you will be able to use the full application after signing up."
              />
            </p>
            <EmailField
              hideRequired
              autoFocus
              hideErrorsUntilTouched
              autoComplete="off"
            />
            <ReCAPTCHA
              className={style.CaptchaField}
              ref={this.recaptchaRef}
              sitekey={process.env.RECAPTCHA_SITE_KEY}
              onChange={this.onCaptchaChange}
            />
          </>
        )}
      </PublicForm>
    );
  }
}

export default localize<Props>(SignupStartComponent);
