import type { Reducer } from 'redux';

import { createReducer, formatActionType } from 'normalized-redux/reducers';
import type { Entity, Pipe } from 'normalized-redux/entity-normalizer/types';
import { FormattedActionType } from 'normalized-redux/reducers/format-action-type';

const DEFAULT_LIMIT = 20;
const DEFAULT_QUERY = 'default';

type Action<T extends string = string> = {
  type: T;
}

type QueryState<E extends Entity> = {
  params?: Record<string, any>,
  pagination: {
    limit: number,
    offset: number,
  },
  sort?: string[],
  count: number,
  result: E['id'][],
  status: 'pristine' | 'loading' | 'success' | 'error' | 'dirty',
  loading: boolean,
  error?: any,
};

type LocalState<E extends Entity> = {
  [name: string]: QueryState<E>;
};

const initialQueryState: LocalState<Entity>[string] = {
  params: {},
  pagination: {
    offset: 0,
    limit: DEFAULT_LIMIT,
  },
  sort: undefined,
  count: 0,
  result: [],
  status: 'pristine',
  loading: false,
  error: undefined,
};

export const initialState: LocalState<Entity> = {
  default: initialQueryState,
};

type QueryStartActionArgs = {
  name?: string,
  params?: Record<string, any>,
  pagination?: {
    limit?: number,
    offset?: number,
  },
  sort?: string[],
  pipe?: Pipe,
};
type QueryStartAction<T extends string> = Action<T> & {
  query: {
    name: string,
    params?: Record<string, any>,
    pagination: {
      limit: number,
      offset: number,
    },
    sort?: string[],
  }
  pipe?: Pipe,
};

// actions
export const createQueryStartAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
  params = {},
  pagination: {
    limit = DEFAULT_LIMIT,
    offset = 0,
  } = {},
  sort,
  pipe = undefined,
}: QueryStartActionArgs = {}): QueryStartAction<T> => ({
  type,
  query: {
    name,
    params,
    pagination: {
      limit,
      offset,
    },
    sort,
  },
  pipe,
});

type QuerySuccessActionArgs = {
  name?: string,
  result: any[],
  count: number,
  pagination: {
    limit: number,
    offset: number,
  },
  sort?: string[],
};
type QuerySuccessAction<T extends string> = Action<T> & {
  query: {
    name: string,
    result: any[],
    count: number,
    pagination: {
      limit: number,
      offset: number,
    },
    sort?: string[],
  }
};

export const createQuerySuccessAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
  result,
  count,
  pagination,
  sort,
}: QuerySuccessActionArgs): QuerySuccessAction<T> => ({
  type,
  query: {
    name,
    result,
    count,
    pagination,
    sort,
  },
});

type QueryFailActionArgs = {
  name?: string,
  error: any,
};
type QueryFailAction<T extends string> = Action<T> & {
  query: {
    name: string;
    error: any;
  }
}

export const createQueryFailAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
  error,
}: QueryFailActionArgs): QueryFailAction<T> => ({
  type,
  query: {
    name,
    error,
  },
});

type QueryResetActionArgs = {
  name?: string,
};
type QueryResetAction<T extends string> = Action<T> & {
  query: {
    name: string;
  }
};

export const createQueryResetAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
}: QueryResetActionArgs): QueryResetAction<T> => ({
  type,
  query: {
    name,
  },
});

type QueryReloadActionArgs = QueryResetActionArgs;
type QueryReloadAction<T extends string> = QueryResetAction<T>;

export const createQueryReloadAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
}: QueryReloadActionArgs): QueryReloadAction<T> => ({
  type,
  query: {
    name,
  },
});

type QueryLoadMoreActionArgs = {
  name?: string,
  additionalResults: number,
};

type QueryLoadMoreAction<T extends string> = Action<T> & {
  query: {
    name: string,
    additionalResults: number,
  }
};
export const createQueryLoadMoreAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
  additionalResults,
}: QueryLoadMoreActionArgs): QueryLoadMoreAction<T> => ({
  type,
  query: {
    name,
    additionalResults,
  },
});

type QuerySetParamsActionArgs = {
  name?: string,
  params: Record<string, any>,
};
type QuerySetParamsAction<T extends string> = Action<T> & {
  query: {
    name: string,
    params: Record<string, any>,
  }
};

export const createQuerySetParamsAction = <T extends string>(type: T) => ({
  name = DEFAULT_QUERY,
  params,
}: QuerySetParamsActionArgs): QuerySetParamsAction<T> => ({
  type,
  query: {
    name,
    params,
  },
});

// handlers
export const queryStartHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: QueryStartAction<T>): LocalState<E> => ({
    ...state,
    [action.query.name]: {
      ...initialQueryState,
      // save the previous query state to avoid "flickering" in the UI
      // (since clearing 'result' and 'count' will result in the table,
      // list, etc to be clear of all items before we get a response)
      ...state[action.query.name],
      sort: action.query.sort,
      pagination: action.query.pagination,
      params: action.query.params,
      status: 'loading',
      loading: true,
      error: undefined,
    },
  });

export const querySuccessHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: QuerySuccessAction<T>): LocalState<E> => ({
    ...state,
    [action.query.name]: {
      ...initialQueryState,
      ...state[action.query.name],
      pagination: {
        ...state[action.query.name].pagination,
        ...action.query.pagination,
      },
      count: action.query.count,
      result: action.query.result,
      status: 'success',
      loading: false,
      error: undefined,
    },
  });

export const queryFailHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: QueryFailAction<T>): LocalState<E> => ({
    ...state,
    [action.query.name]: {
      ...initialQueryState,
      ...state[action.query.name],
      status: 'error',
      loading: false,
      error: action.query.error,
    },
  });

export const queryResetHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: QueryResetAction<T>): LocalState<E> => ({
    ...state,
    [action.query.name]: {
      ...initialQueryState,
    },
  });

export const queryLoadMoreHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: QueryLoadMoreAction<T>): LocalState<E> => ({
    ...state,
    [action.query.name]: {
      ...initialQueryState,
      // save the previous query state to avoid "flickering" in the UI
      // (since clearing 'result' and 'count' will result in the table,
      // list, etc to be clear of all items before we get a response)
      ...state[action.query.name],
      // pagination: {
      //   limit: state[action.query.name].pagination.limit + action.query.additionalResults,
      //   offset: action.query.pagination.offset,
      // },
      status: 'loading',
      loading: true,
      error: undefined,
    },
  });

export const querySetParamsHandler = <
  E extends Entity,
  T extends string,
>(state: LocalState<E>, action: QuerySetParamsAction<T>): LocalState<E> => ({
    ...state,
    [action.query.name]: {
      ...initialQueryState,
      ...state[action.query.name],
      params: action.query.params,
      status: 'dirty',
    },
  });

// selectors
export const getQuerySelector = <
  E extends Entity
>(state: LocalState<E>, { name = DEFAULT_QUERY } = {}) => {
  if (!state[name]) {
    return initialQueryState as QueryState<E>;
  }

  return state[name] as QueryState<E>;
};

type WithKey<Key extends string> = {
  key: Key,
  actionPrefix?: never,
};

type WithActionPrefix<Key extends string> = {
  key?: never,
  actionPrefix: Key,
};

type FormattedQueryStartActionType<Key extends string> = FormattedActionType<Key, 'QUERY_START'>;
type FormattedQuerySuccessActionType<Key extends string> = FormattedActionType<Key, 'QUERY_SUCCESS'>;
type FormattedQueryFailActionType<Key extends string> = FormattedActionType<Key, 'QUERY_FAIL'>;
type FormattedQueryResetActionType<Key extends string> = FormattedActionType<Key, 'QUERY_RESET'>;
type FormattedQueryReloadActionType<Key extends string> = FormattedActionType<Key, 'QUERY_RELOAD'>;
type FormattedQueryLoadMoreActionType<Key extends string> = FormattedActionType<Key, 'QUERY_LOAD_MORE'>;
type FormattedQuerySetParamsActionType<Key extends string> = FormattedActionType<Key, 'QUERY_SET_PARAMS'>;

export type QueriesReducer<E extends Entity, Key extends string> = {
  reducer: Reducer<LocalState<E>, any>,
  actions: {
    queryStart: (args: QueryStartActionArgs) => QueryStartAction<
      FormattedQueryStartActionType<Key>
    >,
    querySuccess: (args: QuerySuccessActionArgs) => QuerySuccessAction<
      FormattedQuerySuccessActionType<Key>
    >,
    queryFail: (args: QueryFailActionArgs) => QueryFailAction<FormattedQueryFailActionType<Key>>,
    queryReset: (args: QueryResetActionArgs) => QueryResetAction<
      FormattedQueryResetActionType<Key>
    >,
    queryReload: (args: QueryReloadActionArgs) => QueryReloadAction<
      FormattedQueryReloadActionType<Key>
    >,
    queryLoadMore: (args: QueryLoadMoreActionArgs) => QueryLoadMoreAction<
      FormattedQueryLoadMoreActionType<Key>
    >,
    querySetParams: (args: QuerySetParamsActionArgs) => QuerySetParamsAction<
      FormattedQuerySetParamsActionType<Key>
    >,
    types: {
      queryStart: FormattedQueryStartActionType<Key>,
      querySuccess: FormattedQuerySuccessActionType<Key>,
      queryFail: FormattedQueryFailActionType<Key>,
      queryReset: FormattedQueryResetActionType<Key>,
      queryReload: FormattedQueryReloadActionType<Key>,
      queryLoadMore: FormattedQueryLoadMoreActionType<Key>,
      querySetParams: FormattedQuerySetParamsActionType<Key>,
    },
  },
  selectors: {
    getQuerySelector: (state: LocalState<E>, args: { name?: string }) => QueryState<E>,
  },
};

// initializer
const createQueriesReducer = <
  E extends Entity,
  Key extends string,
>({ key, actionPrefix }: WithKey<Key> | WithActionPrefix<Key>): QueriesReducer<E, Key> => {
  const actionKey = (actionPrefix || key) as Key;

  const QUERY_START = formatActionType({ key: actionKey, type: 'QUERY_START' });
  const QUERY_SUCCESS = formatActionType({ key: actionKey, type: 'QUERY_SUCCESS' });
  const QUERY_FAIL = formatActionType({ key: actionKey, type: 'QUERY_FAIL' });
  const QUERY_RESET = formatActionType({ key: actionKey, type: 'QUERY_RESET' });
  const QUERY_RELOAD = formatActionType({ key: actionKey, type: 'QUERY_RELOAD' });
  const QUERY_LOAD_MORE = formatActionType({ key: actionKey, type: 'QUERY_LOAD_MORE' });
  const QUERY_SET_PARAMS = formatActionType({ key: actionKey, type: 'QUERY_SET_PARAMS' });

  return ({
    reducer: createReducer(initialState as LocalState<E>, {
      [QUERY_START]: queryStartHandler,
      [QUERY_SUCCESS]: querySuccessHandler,
      [QUERY_FAIL]: queryFailHandler,
      [QUERY_RESET]: queryResetHandler,
      [QUERY_LOAD_MORE]: queryLoadMoreHandler,
      [QUERY_SET_PARAMS]: querySetParamsHandler,
    }),
    actions: {
      queryStart: createQueryStartAction(QUERY_START),
      querySuccess: createQuerySuccessAction(QUERY_SUCCESS),
      queryFail: createQueryFailAction(QUERY_FAIL),
      queryReset: createQueryResetAction(QUERY_RESET),
      queryReload: createQueryReloadAction(QUERY_RELOAD),
      queryLoadMore: createQueryLoadMoreAction(QUERY_LOAD_MORE),
      querySetParams: createQuerySetParamsAction(QUERY_SET_PARAMS),
      types: {
        queryStart: QUERY_START,
        querySuccess: QUERY_SUCCESS,
        queryFail: QUERY_FAIL,
        queryReset: QUERY_RESET,
        queryReload: QUERY_RELOAD,
        queryLoadMore: QUERY_LOAD_MORE,
        querySetParams: QUERY_SET_PARAMS,
      },
    },
    selectors: {
      getQuerySelector,
    },
  });
};

export default createQueriesReducer;
