// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import type { Validator } from './index';

type RequiredPhoneNumber = ({
  text?: string,
  message?: MessageTranslator,
  field?: string,
}) => Validator;

type GetTranslatedMessage = ({
  text?: string,
  message?: MessageTranslator,
  values: {
    field?: string,
  },
}) => string
export const getTranslatedMessage: GetTranslatedMessage = ({ text, message, values }) => {
  if (text) {
    return text;
  }

  if (!message || !isFunction(message)) {
    throw new Error('message is mandatory for creating a required validation');
  }

  if (!values.field) {
    throw new Error('field is mandatory for creating a required validation');
  }

  return message({
    id: '{field} is required',
    pluralId: '{field} are required',
    pluralCondition: 'pluralCount',
    values: {
      field: values.field,
      pluralCount: 1,
    },
    comment: 'The validation message for required fields.',
  });
};

const requiredPhoneNumber: RequiredPhoneNumber = ({ text, message, field }) => {
  if (!message && !text && !field) {
    throw new Error('text or (message and field) is mandatory for creating a required validation');
  }

  return (fullValue) => {
    const values = { field };
    let value = fullValue;

    if (fullValue && fullValue.startsWith('+')) {
      value = value.replace('+', '');
    }

    const validationText = getTranslatedMessage({ text, message, values });

    if (isString(value)) {
      return value.trim() ? undefined : validationText;
    }

    return value ? undefined : validationText;
  };
};

export default requiredPhoneNumber;
