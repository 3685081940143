import {
  TYPE_IS_SIGNATORY,
  TYPE_IS_INFLUENCER,
  TYPE_IS_ORGANIZER,
  TYPE_IS_VIEWER,
  TYPE_IS_INTERNAL_APPROVER,
  TYPE_IS_EXTERNAL_APPROVER,
} from 'agreement/participant/constants';

export const availableParticipantRoles = (message) => ({
  [TYPE_IS_SIGNATORY]: {
    label: message({
      id: 'Signatory',
      comment: 'The role of the participant',
    }),
    value: TYPE_IS_SIGNATORY,
    name: 'signatory',
    description: message({
      id: 'Signatory: views, edits, and signs',
      comment: 'Tooltip help text explaining the meaning behind signatory participant role. The text is used in the tooltip of the signatory role field.',
    }),
  },
  [TYPE_IS_INFLUENCER]: {
    label: message({
      id: 'Influencer',
      comment: 'The role of the participant',
    }),
    value: TYPE_IS_INFLUENCER,
    name: 'influencer',
    description: message({
      id: 'Influencer: views and edits',
      comment: 'Tooltip help text explaining the meaning behind influencer participant role. The text is used in the tooltip of the influencer role field.',
    }),
  },
  [TYPE_IS_ORGANIZER]: {
    label: message({
      id: 'Organizer',
      comment: 'The role of the participant',
    }),
    value: TYPE_IS_ORGANIZER,
    name: 'organizer',
    description: message({
      id: 'Organizer: views and edits, invisible to counterparties',
      comment: 'Tooltip help text explaining the meaning behind organizer participant role. The text is used in the tooltip of the organizer role field.',
    }),
  },
  [TYPE_IS_VIEWER]: {
    label: message({
      id: 'Viewer',
      comment: 'The role of the participant',
    }),
    value: TYPE_IS_VIEWER,
    name: 'viewer',
    description: message({
      id: 'Viewer: views and comments',
      comment: 'Tooltip help text explaining the meaning behind viewer participant role. The text is used in the tooltip of the viewer role field.',
    }),
  },
});

export const internalApproverParticipantRole = (message) => ({
  [TYPE_IS_INTERNAL_APPROVER]: {
    label: message({
      id: 'Internal approver',
      comment: 'The role of the participant',
    }),
    value: TYPE_IS_INTERNAL_APPROVER,
    name: 'internalApprover',
    description: message({
      id: 'Internal approver: views, edits and approves, invisible to counterparties',
      comment: 'Tooltip help text explaining the meaning behind internal approver participant role. The text is used in the tooltip of the internal approver role field',
    }),
  },
});

export const externalApproverParticipantRole = (message) => ({
  [TYPE_IS_EXTERNAL_APPROVER]: {
    label: message({
      id: 'Approver',
      comment: 'The role of the participant',
    }),
    value: TYPE_IS_EXTERNAL_APPROVER,
    name: 'externalApprover',
    description: message({
      id: 'Approver: views, edits, and approves',
      comment: 'Tooltip help text explaining the meaning behind external approver participant role. The text is used in the tooltip of the external approver role field',
    }),
  },
});
