// @flow

import {
  get,
  post,
  postMultipart,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

import {
  normalizeBinding,
} from 'oneflow-client/schema';

const ping = ({ positionId } = {}) => {
  if (!positionId) {
    return get({ url: '/ping' });
  }

  return get({ url: `/ping?position_id=${positionId}` }).then(
    (response) => {
      const { headers } = response;
      const server = headers.get('X-Flow-Server-Time');
      const expires = headers.get('X-Flow-Session-Expires-At');
      return {
        server,
        expires,
      };
    },
  );
};

const refresh = () => (
  get({ url: '/ping?refresh=1' }).then(
    (response) => {
      const { headers } = response;
      const server = headers.get('X-Flow-Server-Time');
      const expires = headers.get('X-Flow-Session-Expires-At');
      return {
        server,
        expires,
      };
    },
  )
);

const createBindingByResource = ({
  resourceType,
  resourceId,
  actorType,
  actorId,
  roleId,
}) => (
  post({
    url: `/${resourceType}s/${resourceId}/${actorType}s/${actorId}/bindings/`,
    body: { role_id: roleId },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeBinding)
);

const removeBindingByResource = ({
  resourceType,
  resourceId,
  actorType,
  actorId,
  roleId,
}) => (
  remove({
    url: `/${resourceType}s/${resourceId}/${actorType}s/${actorId}/bindings/`,
    body: { role_id: roleId },
  })
    .then(extractResponseBodyAsJSON)
);

// file
const uploadAsset = ({ file, type }) => {
  const formData = new FormData();
  formData.append('asset_type', type);
  formData.append('file', file, file.name);

  return (
    postMultipart({
      url: '/assets/',
      body: formData,
    })
      .then(extractResponseBodyAsJSON)
  );
};

type UploadDocumentParams = {
  file: File,
  type: string,
  contractId: number,
  guestToken?: string,
};

// contract document
const uploadDocument = ({
  file,
  type,
  contractId,
  guestToken,
}: UploadDocumentParams) => {
  const formData = new FormData();
  formData.append('asset_type', type);
  formData.append('qqfile', file, file.name);
  formData.append('allow_async', 'true');

  const options = {};
  if (guestToken) {
    options.token = guestToken;
  }

  return (
    postMultipart({
      url: `/agreements/${contractId}/assets/?_source=form`,
      body: formData,
    }, options)
      .then(extractResponseBodyAsJSON)
  );
};

// contract image
const uploadContractImage = ({ file, contractId }: { file: File, contractId: number }) => {
  const formData = new FormData();
  formData.append('upload', file, file.name);

  return (
    postMultipart({
      url: `/agreements/${contractId}/images/?_source=form`,
      body: formData,
    })
      .then(extractResponseBodyAsJSON)
  );
};

// Exports
const client = {
  ping,
  refresh,
  createBindingByResource,
  removeBindingByResource,
  uploadAsset,
  uploadDocument,
  uploadContractImage,
};

export default client;
