import React from 'react';
import Message from 'components/message';

export const OPTIONS_JOB_LEVEL = [
  {
    label: <Message
      id="Executive"
      comment="Executive dropdown option"
    />,
    value: 'executive',
    key: 'executive',
  },
  {
    label: <Message
      id="Director"
      comment="Director dropdown option"
    />,
    value: 'director',
    key: 'director',
  },
  {
    label: <Message
      id="Manager"
      comment="Manager dropdown option"
    />,
    value: 'manager',
    key: 'manager',
  },
  {
    label: <Message
      id="Individual contributor"
      comment="Individual contributor dropdown option"
    />,
    value: 'individual contributor',
    key: 'individual contributor',
  },
];

export const OPTIONS_TEAM = [
  {
    label: <Message
      id="Sales"
      comment="Sales dropdown option"
    />,
    value: 'sales',
    key: 'sales',
  },
  {
    label: <Message
      id="Marketing"
      comment="Marketing dropdown option"
    />,
    value: 'marketing',
    key: 'marketing',
  },
  {
    label: <Message
      id="HR"
      comment="HR dropdown option"
    />,
    value: 'hr',
    key: 'hr',
  },
  {
    label: <Message
      id="Legal"
      comment="Legal dropdown option"
    />,
    value: 'legal',
    key: 'legal',
  },
  {
    label: <Message
      id="Procurement"
      comment="Procurement dropdown option"
    />,
    value: 'procurement',
    key: 'procurement',
  },
  {
    label: <Message
      id="Finance"
      comment="Finance dropdown option"
    />,
    value: 'finance',
    key: 'finance',
  },
  {
    label: <Message
      id="IT"
      comment="IT dropdown option"
    />,
    value: 'it',
    key: 'it',
  },
  {
    label: <Message
      id="Other"
      comment="Other dropdown option"
    />,
    value: 'other',
    key: 'other',
  },
];

export const OPTIONS_USAGE = [
  {
    label: <Message
      id="Electronic signatures"
      comment="Electronic signatures dropdown option"
    />,
    value: 'electronic signatures',
    key: 'electronic signatures',
  },
  {
    label: <Message
      id="Upload and sign PDF"
      comment="Upload and sign PDF dropdown option"
    />,
    value: 'upload and sign pdf',
    key: 'upload and sign pdf',
  },
  {
    label: <Message
      id="Quotes"
      comment="Quotes dropdown option"
    />,
    value: 'quotes',
    key: 'quotes',
  },
  {
    label: <Message
      id="Proposals"
      comment="Proposals dropdown option"
    />,
    value: 'proposals',
    key: 'proposals',
  },
  {
    label: <Message
      id="Annual reports"
      comment="Annual reports dropdown option"
    />,
    value: 'annual reports',
    key: 'annual reports',
  },
  {
    label: <Message
      id="Standard operating procedures"
      comment="SOP dropdown option"
    />,
    value: 'sop',
    key: 'sop',
  },
  {
    label: <Message
      id="API"
      comment="API dropdown option"
    />,
    value: 'api',
    key: 'api',
  },
  {
    label: <Message
      id="Contract management"
      comment="Contract management dropdown option"
    />,
    value: 'contract management',
    key: 'contract management',
  },
  {
    label: <Message
      id="Contract automation"
      comment="Contract automation dropdown option"
    />,
    value: 'contract automation',
    key: 'contract automation',
  },
  {
    label: <Message
      id="Other"
      comment="Other dropdown option"
    />,
    value: 'other',
    key: 'other',
  },
];

export const OPTIONS_COMPANY_SIZE = [
  {
    label: <Message
      id="Solo"
      comment="Solo dropdown option"
    />,
    value: '1',
    key: 'solo',
  },
  {
    label: '2-10',
    value: '2-10',
    key: '2-10',
  },
  {
    label: '11-50',
    value: '11-50',
    key: '11-50',
  },
  {
    label: '51-200',
    value: '51-200',
    key: '51-200',
  },
  {
    label: '201-500',
    value: '201-500',
    key: '201-500',
  },
  {
    label: '501-1000',
    value: '501-1000',
    key: '501-1000',
  },
  {
    label: '1001+',
    value: '1001+',
    key: '1001+',
  },
];

export const OPTIONS_TOOLS = [
  {
    label: <Message
      id="We don't use any of these systems or products"
      comment="Option in the tools field on the signup company details page"
    />,
    value: 'do not use any',
    key: 'do not use any',
  },
  {
    label: 'Adocka',
    value: 'adocka',
    key: 'adocka',
  },
  {
    label: 'Dynamics',
    value: 'dynamics',
    key: 'dynamics',
  },
  {
    label: 'HubSpot',
    value: 'hubspot',
    key: 'hubspot',
  },
  {
    label: 'Intelliplan',
    value: 'intelliplan',
    key: 'intelliplan',
  },
  {
    label: 'Pipedrive',
    value: 'pipedrive',
    key: 'pipedrive',
  },
  {
    label: 'Salesforce',
    value: 'salesforce',
    key: 'salesforce',
  },
  {
    label: 'SuperOffice',
    value: 'superoffice',
    key: 'superoffice',
  },
  {
    label: 'Teamtailor',
    value: 'teamtailor',
    key: 'teamtailor',
  },
  {
    label: 'Upsales',
    value: 'upsales',
    key: 'upsales',
  },
  {
    label: <Message
      id="Other"
      comment="Other dropdown option"
    />,
    value: 'other',
    key: 'other',
  },
];
