/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import {
  isEmpty,
  isEqual,
} from 'lodash';
import { produce, setAutoFreeze } from 'immer';

import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type { PristineContract } from 'app/reducers/current-contract';

setAutoFreeze(true);

export const produceUpdatedBoxOrder = produce((draftState, action) => {
  draftState.boxOrder = action.boxOrder;
  draftState.pristineState.boxOrder = false;
  draftState.isPristine = false;
});

export const produceUpdatedDataField = produce((draftState,
  {
    dataField,
    pristineContract,
  }: { dataField: DataField, pristineContract: PristineContract }) => {
  const { data } = draftState;

  if (isEmpty(data)) {
    return;
  }

  const oldData = draftState.data[dataField.id];
  const pristineDataField = pristineContract?.data[dataField.id];

  if (!oldData) {
    return;
  }

  const updatedDataField = {
    ...oldData,
    ...dataField,
  };

  const { externalKey, value } = dataField.value;
  const isDataFieldPristine = isEqual(updatedDataField, pristineDataField);

  draftState.pristineState.dataFieldsMap[dataField.id] = isDataFieldPristine;
  draftState.data[dataField.id] = updatedDataField;
  draftState.dataFieldExternalKeyValueMap[externalKey as string] = value;
});
