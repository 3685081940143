// @flow

import React from 'react';

import Svg from 'components/svg';

const AddressBook = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.08498 1.39232C1.77641 0.722725 2.70987 0.350098 3.67899 0.350098L19.4739 0.350147V19.3457L17.5609 19.3457V22.3501L19.4739 22.35L19.4739 23.65L3.0413 23.6501C2.22419 23.6501 1.45707 23.4463 0.884594 22.9556C0.296802 22.4517 0 21.7191 0 20.8479V3.93343C0 2.97622 0.392845 2.0626 1.08498 1.39232ZM1.3 18.3977V3.93343C1.3 3.33471 1.5454 2.75612 1.98936 2.32618C2.43402 1.89556 3.04143 1.6501 3.67899 1.6501L18.1739 1.65013V18.0457L3.0413 18.0457C2.43019 18.0457 1.81922 18.1315 1.3 18.3977ZM16.2609 19.3457L3.0413 19.3457C2.38252 19.3457 1.96264 19.4704 1.71366 19.6696C1.49468 19.8448 1.3 20.1659 1.3 20.8479C1.3 21.4115 1.48146 21.755 1.73062 21.9685C1.99511 22.1952 2.42364 22.3501 3.0413 22.3501L16.2609 22.3501V19.3457ZM6.93238 5.68501C7.5528 5.08418 8.38996 4.75019 9.25866 4.75019C10.1274 4.75019 10.9645 5.08418 11.5849 5.68501C12.2061 6.28653 12.559 7.10689 12.559 7.96685C12.559 8.82682 12.2061 9.64718 11.5849 10.2487C11.3396 10.4862 11.0605 10.6821 10.7588 10.8321C11.5674 11.0693 12.3128 11.4971 12.9236 12.0886C13.8998 13.034 14.4522 14.3206 14.4522 15.6668V16.3168H13.1522V15.6668C13.1522 14.6791 12.7472 13.7275 12.0192 13.0225C11.2905 12.3168 10.2979 11.9168 9.2587 11.9168C8.21947 11.9168 7.22684 12.3168 6.49816 13.0225C5.77018 13.7275 5.36522 14.6791 5.36522 15.6668V16.3168H4.06522V15.6668C4.06522 14.3206 4.61763 13.034 5.59378 12.0886C6.2046 11.4971 6.95 11.0693 7.7585 10.8321C7.45688 10.6821 7.17769 10.4863 6.93238 10.2487C6.31125 9.64718 5.95829 8.82682 5.95829 7.96685C5.95829 7.10689 6.31125 6.28653 6.93238 5.68501ZM9.25866 6.05019C8.72152 6.05019 8.21041 6.25702 7.83676 6.61888C7.46381 6.98005 7.2583 7.46538 7.2583 7.96685C7.2583 8.46833 7.46381 8.95366 7.83676 9.31483C8.21041 9.67669 8.72152 9.88352 9.25866 9.88352C9.7958 9.88352 10.3069 9.67669 10.6806 9.31483C11.0535 8.95366 11.259 8.46833 11.259 7.96685C11.259 7.46538 11.0535 6.98005 10.6806 6.61888C10.3069 6.25702 9.7958 6.05019 9.25866 6.05019Z"
    fill="currentColor"
  />
), { viewBox: '0 0 20 24', fill: 'none' });

AddressBook.displayName = 'AddressBook';

export default AddressBook;
