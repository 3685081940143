// @flow

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import accounts from 'reducers/entities/accounts';
import positions from 'reducers/entities/positions';
import workspacesReducer, { isAValidWorkspaceId } from 'reducers/entities/workspaces';

import { checkAcl } from 'components/acl';

import { getCurrentWorkspaceSelector } from 'reducers/app';

export const REQUEST_ME_START = 'session/REQUEST_ME_START' as const;
export const REQUEST_ME_SUCCESS = 'session/REQUEST_ME_SUCCESS' as const;
export const SET_GUEST_TOKEN = 'session/SET_GUEST_TOKEN' as const;
export const LOGOUT = 'session/LOGOUT' as const;
export const PING = 'session/PING' as const;
export const REFRESH = 'session/REFRESH' as const;
export const SET_REFRESH_SUCCESS = 'session/REFRESH_SUCCESS' as const;
export const SET_SESSION_EXPIRE = 'app/SET_SESSION_EXPIRE' as const;
export const SET_HAS_USER = 'currentContract/SET_HAS_USER' as const;

type ExpireInfo = {
  server: string,
  expires: string,
}

type State = {
  me: { id: Oneflow.Position['id'] } | undefined;
  loading: boolean;
  success: boolean | undefined;
  guestToken: string | null;
  hasUser: boolean | undefined;
  expire?: ExpireInfo;
};

const initialState: State = {
  me: undefined,
  loading: false,
  success: undefined,
  guestToken: null,
  hasUser: undefined,
};

// actions
export const requestMe = () => ({
  type: REQUEST_ME_START,
});

export const requestMeSuccess = (data: { id: Oneflow.Position['id'] }) => ({
  type: REQUEST_ME_SUCCESS,
  data,
});

export const setGuestToken = (token: string | null) => ({
  type: SET_GUEST_TOKEN,
  token,
});

export const logout = (postLogoutPath: string) => ({
  type: LOGOUT,
  postLogoutPath,
});

export const setHasUser = (hasUser: boolean) => ({
  type: SET_HAS_USER,
  hasUser,
});

export const setSessionExpire = (expireInfo: ExpireInfo) => ({
  type: SET_SESSION_EXPIRE,
  expireInfo,
});

export const setRefreshSuccess = (success: boolean) => ({
  type: SET_REFRESH_SUCCESS,
  success,
});

export const ping = ({ positionId }: { positionId: Oneflow.Position['id'] }) => ({
  type: PING,
  positionId,
});

export const refresh = () => ({
  type: REFRESH,
});

type Action =
  | ReturnType<typeof requestMe>
  | ReturnType<typeof requestMeSuccess>
  | ReturnType<typeof setGuestToken>
  | ReturnType<typeof logout>
  | ReturnType<typeof setHasUser>
  | ReturnType<typeof setSessionExpire>
  | ReturnType<typeof setRefreshSuccess>
  | ReturnType<typeof ping>
  | ReturnType<typeof refresh>;

const session = (state = initialState, action: Action) => {
  switch (action.type) {
    case REQUEST_ME_START:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ME_SUCCESS:
      return {
        ...state,
        me: action.data,
        loading: false,
      };
    case SET_GUEST_TOKEN:
      return {
        ...state,
        guestToken: action.token,
        loading: false,
      };
    case SET_SESSION_EXPIRE:
      return {
        ...state,
        expire: action.expireInfo,
      };
    case SET_REFRESH_SUCCESS:
      return {
        ...state,
        success: action.success,
      };
    case SET_HAS_USER:
      return {
        ...state,
        hasUser: action.hasUser,
      };
    default:
      return state;
  }
};

export default session;

const emptyObject = Object.freeze({});

type RootState = {
  session: typeof initialState;
};

// selectors
export const getLoadingStateSelector = (state: RootState) => state.session.loading;

export const getRefreshStateSelector = (state: RootState) => state.session.success;

export const getPositionFromSessionSelector = (
  state: RootState,
): Oneflow.Position | typeof emptyObject => {
  if (!state.session.me) {
    return emptyObject;
  }
  const myPosition = state.session.me;

  return positions.getPositionSelector(state, { id: myPosition.id });
};

export const getMyUserRole = (state: RootState) => {
  if (!state.session.me) {
    return undefined;
  }

  // since we do early return in the current function if state.session.me is undefined,
  // at this point,  we can safely assume that getPositionFromSessionSelector
  // will return a Oneflow.Position
  return (getPositionFromSessionSelector(state) as Oneflow.Position).userRole;
};

export const getGhostFromSessionSelector = (state: RootState) => {
  if (!state.session.me) {
    return undefined;
  }

  return (getPositionFromSessionSelector(state) as Oneflow.Position).ghost;
};

export const getAccountFromSessionSelector = (state: RootState) => {
  if (!state.session.me) {
    return emptyObject;
  }

  // since we do early return in the current function if state.session.me is undefined,
  // at this point,  we can safely assume that getPositionFromSessionSelector
  // will return a Oneflow.Position
  const { account } = getPositionFromSessionSelector(state) as Oneflow.Position;

  return accounts.getAccountSelector(state, { id: account });
};

export const getDefaultSignatureFromSessionSelector = (state: RootState) => {
  const position = getPositionFromSessionSelector(state);

  if (isEmpty(position)) {
    return '';
  }

  const account = getAccountFromSessionSelector(state);
  const currentWorkspace = getCurrentWorkspaceSelector(state as any);

  let signaturebrandingName = account.name;

  if (!isEmpty(currentWorkspace) && currentWorkspace.brandingName) {
    signaturebrandingName = currentWorkspace.brandingName;
  }

  return compact([
    position.fullname,
    position.title,
    position.phoneNumber,
    signaturebrandingName,
  ]).join('\n');
};

export const getSignatureFromSessionSelector = (state: RootState) => {
  const position = getPositionFromSessionSelector(state);

  if (isEmpty(position)) {
    return '';
  }

  if (position.signature) {
    return position.signature;
  }

  return getDefaultSignatureFromSessionSelector(state);
};

export const getLanguageFromMeSelector = (state: RootState) => {
  if (!state.session.me) {
    return undefined;
  }

  const position = getPositionFromSessionSelector(state);

  return position.language;
};

export const getFeatureSelector = (state: RootState, { feature }) => {
  const { features } = getAccountFromSessionSelector(state);

  return get(features, feature, { enabled: 0 });
};

export const isFeatureEnabledSelector = (state: RootState, { feature }) => {
  const featureObject = getFeatureSelector(state, { feature });

  return featureObject.enabled === 1;
};

const workspaceModules = [
  'collection:module:dashboard:use',
  'collection:module:agreement:use',
  'collection:module:template:use',
  'collection:module:addressbook:use',
];

export const hasAnyWorkspaceModuleAccess = (workspace: Workspace) => (
  checkAcl(workspace.acl, workspaceModules, { match: 'any' })
);

export const getAllMyWorkspacesFromSessionSelector = (state: RootState) => {
  const myUsersWorkspaces = getPositionFromSessionSelector(state).collections || [];

  return (
    workspacesReducer.getWorkspacesSelector(
      state,
      {
        ids: myUsersWorkspaces,
      },
    )
      .filter(hasAnyWorkspaceModuleAccess)
      .sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
  );
};

export const getAllWorkspacesFromSessionSelector = (state: RootState) => {
  const workspace = getCurrentWorkspaceSelector(state);
  const myUsersWorkspaces = getPositionFromSessionSelector(state).collections || [];

  return (
    workspacesReducer.getWorkspacesSelector(
      state,
      {
        ids: [
          0,
          ...myUsersWorkspaces,
        ],
      },
    )
      .filter(hasAnyWorkspaceModuleAccess)
      .sort((a, b) => {
        // shared with me should always be last
        if (a.id === 0) {
          return 1;
        }
        if (b.id === 0) {
          return -1;
        }

        if (a.id === workspace?.id) {
          return -1;
        }
        if (b.id === workspace?.id) {
          return 1;
        }

        return a.name && b.name && a.name.localeCompare(b.name);
      })
  );
};

export const getHasUserSelector = (state: RootState) => Boolean(state.session.hasUser);

type IsWorkspaceAccessible = (state: RootState, workspace: Oneflow.Workspace) => boolean;
export const isWorkspaceAccessible: IsWorkspaceAccessible = (
  state: RootState,
  workspace: Oneflow.Workspace,
) => {
  const workspaceIds = getAllWorkspacesFromSessionSelector(state)
    .map((item) => item.id);

  return isAValidWorkspaceId(workspace.id) && workspaceIds.includes(workspace.id);
};

export const getExpireFromSessionSelector = (state: RootState) => {
  if (!state.session.expire) {
    return undefined;
  }

  return state.session.expire;
};
