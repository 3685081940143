// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
  disabled?: boolean,
};

export const DataFieldName = ({ message, autoFocus, disabled }: Props) => (
  <Field
    name="name"
    label={message({
      id: 'Name',
      comment: 'Label for the name field for a data field.',
    })}
    placeholder={message({
      id: 'Enter name',
      comment: 'Name placeholder for a data field.',
    })}
    autoFocus={autoFocus}
    component={TextField}
    maxLength={100}
    disabled={disabled}
    required
    autoComplete="off"
  />
);

DataFieldName.defaultProps = {
  autoFocus: undefined,
  disabled: undefined,
};

export default localize<Props>(DataFieldName);
