import 'normalize.css';

import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import I18n from '@oneflowab/pomes';
import { ConnectedRouter } from 'connected-react-router';

import PublicApp from 'routes/public';
import PublicAppTitle from 'components/app-title';
import { ErrorHandler } from 'components/legacy/error';
import FaviconOneflow from 'components/favicon';
import history from 'store/history';
import store from 'public/store';
import translations from 'translations';

const helmetContext = {};

const Public = () => (
  <Provider store={store}>
    <I18n translations={translations} initialized>
      <ErrorHandler>
        <HelmetProvider context={helmetContext}>
          <FaviconOneflow />
          <PublicAppTitle />
          <ConnectedRouter history={history}>
            <PublicApp />
          </ConnectedRouter>
        </HelmetProvider>
      </ErrorHandler>
    </I18n>
  </Provider>
);

export default Public;
