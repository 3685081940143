// @flow

import React from 'react';

import Svg from 'components/svg';

const Replace = Svg((
  <>
    <path
      d="M11.8125 12.5C11.6958 12.5 11.5856 12.448 11.5033 12.3533C11.333 12.1587 11.333 11.8413 11.5033 11.646L12.5067 10.4993H6.125C5.8835 10.4993 5.6875 10.2753 5.6875 9.99933C5.6875 9.72333 5.8835 9.49933 6.125 9.49933H12.5061L11.5027 8.35267C11.4205 8.25933 11.375 8.13333 11.375 8C11.375 7.86667 11.4205 7.74067 11.5033 7.64667C11.5862 7.55267 11.6958 7.5 11.8125 7.5C11.9292 7.5 12.0394 7.552 12.1217 7.64667L13.8717 9.64667C13.9125 9.69333 13.9446 9.748 13.9668 9.81C13.9685 9.81533 13.9708 9.82133 13.9726 9.82733C13.9907 9.88133 14 9.94067 14 10C14 10.058 13.9907 10.116 13.9732 10.172C13.972 10.176 13.9708 10.1793 13.9697 10.1833C13.9452 10.2527 13.9131 10.3073 13.8722 10.354L12.1223 12.354C12.0394 12.448 11.9292 12.5 11.8125 12.5Z"
      fill="currentColor"
    />
    <path
      d="M2.1875 8.5C2.07083 8.5 1.96058 8.448 1.87833 8.35333L0.128333 6.35333C0.0875 6.30667 0.0554167 6.252 0.03325 6.19C0.0309167 6.18533 0.0291667 6.17933 0.0274167 6.17333C0.00933333 6.116 0 6.05867 0 6C0 5.94067 0.00933333 5.88267 0.028 5.826C0.0291667 5.82133 0.0309167 5.81667 0.0320833 5.812C0.0554167 5.74733 0.0875 5.69267 0.128917 5.646L1.87833 3.64667C1.96117 3.552 2.07083 3.5 2.1875 3.5C2.30417 3.5 2.41442 3.552 2.49667 3.64667C2.5795 3.74067 2.625 3.86667 2.625 4C2.625 4.13333 2.5795 4.25933 2.49667 4.35333L1.49333 5.5H7.875C8.1165 5.5 8.3125 5.724 8.3125 6C8.3125 6.276 8.1165 6.5 7.875 6.5H1.49392L2.49725 7.64667C2.5795 7.74067 2.625 7.86667 2.625 8C2.625 8.13333 2.5795 8.25933 2.49667 8.354C2.41442 8.448 2.30417 8.5 2.1875 8.5Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 14 16' });

Replace.displayName = 'Replace';

export default Replace;
