// @flow

import * as React from 'react';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import differenceBy from 'lodash/differenceBy';
import flatten from 'lodash/flatten';
import type { FieldRenderProps } from 'react-final-form';
import clsx from 'clsx';
import NewCheck from 'components/icons/new-check';
import ChevronDownIcon from 'components/icons/chevron-down';
import {
  ClearIndicator,
  MultiValueRemove,
  NoOptionsMessage,
  Input,
  Option,
} from 'components/select-field/components';

import reorderOptions from './helpers';

import 'styles/external/react-select.scss';
import style from './select-field.module.scss';

export type Props = FieldRenderProps & {
  additionalItemsPerLoad: number,
  ariaLabel?: string,
  boldLabel?: boolean,
  customStyles?: Object,
  disabled?: boolean,
  displayErrorEarly?: boolean,
  errorCustomClass?: string,
  hasIcon?: boolean,
  hideErrorElement?: boolean,
    // Useful when we need to access the value directly in form submit
  includeNativeSelect: Boolean,
  inheritFontStyles?: boolean;
  label?: string | React.Node,
  labelKey?: string,
  loadMoreItems?: Function,
  menuPlacement?: String,
  minOptionsCount: number,
  multi: boolean,
  noDropDownIndicator?: boolean,
  noScroll?: boolean,
  onChange?: Function,
  onInputChange: Function,
  options: Array<any>,
  placeholder?: string,
  small?: boolean,
  usePortal?: boolean;
  valueKey?: string,
  required?: boolean,
  // To determine the total amount of options that can be loaded when the select is in
  // infinite scroll mode (when loadMoreItems prop is provided)
  totalOptionsCount?: number,
};

type State = {
  inputValue: any,
  menuDirection?: 'top' | 'bottom',
}

class SelectField extends React.PureComponent<Props, State> {
  static defaultProps = {
    totalOptionsCount: 0,
    additionalItemsPerLoad: 5,
    customStyles: undefined,
    disabled: false,
    displayErrorEarly: undefined,
    hasIcon: false,
    hideErrorElement: false,
    label: '',
    loadMoreItems: () => undefined,
    menuPlacement: 'auto',
    minOptionsCount: 6,
    multi: false,
    noScroll: false,
    onChange: undefined,
    onInputChange: undefined,
    options: [],
    placeholder: '',
    small: undefined,
  };

  state = {
    inputValue: undefined,
  }

  onInputChange = (inputValue: any) => {
    if (!this.props.onInputChange) {
      return inputValue;
    }

    if (inputValue === '' && !this.state.inputValue) {
      return undefined;
    }

    if (inputValue !== this.state.inputValue && inputValue.length > 0) {
      return this.setState({
        inputValue,
      }, () => this.props.onInputChange(inputValue));
    }

    this.setState({ inputValue: undefined });
    return this.props.onInputChange(undefined);
  };

  allOptionsLoaded = () => {
    const { options, totalOptionsCount } = this.props;

    if (!totalOptionsCount) {
      return false;
    }

    return options.length === totalOptionsCount;
  };

  onChange = (selectedValues: any) => {
    const { multi, loadMoreItems, valueKey } = this.props;

    if (selectedValues && selectedValues.disabled) {
      return;
    }

    const remainingOptions = differenceBy(this.props.options, selectedValues, valueKey || 'value');

    if (
      remainingOptions.length < this.props.minOptionsCount
      && loadMoreItems
      && multi
      && !this.allOptionsLoaded()
    ) {
      loadMoreItems(this.props.minOptionsCount - remainingOptions.length);
    }

    this.props.input.onChange(selectedValues);

    if (this.props.onChange) {
      this.props.onChange(selectedValues);
    }
  };

  handleOnChangeWithFixedOptions = (selectedValues: any, actionMeta: any) => {
    const { multi } = this.props;

    if (actionMeta.action === 'clear') {
      const values = multi ? actionMeta.removedValues.filter((value) => value.isFixed) : null;
      this.onChange(values);
      return;
    }

    if (!multi) {
      this.onChange(selectedValues);
      return;
    }

    // isFixed is property defined in the individual option, most likely derived
    // From business logic
    if (['remove-value', 'pop-value'].includes(actionMeta.action) && actionMeta?.removedValue?.isFixed) {
      return;
    }

    this.onChange(selectedValues);
  }

  loadMoreItems = () => {
    const { loadMoreItems } = this.props;
    if (loadMoreItems && !this.allOptionsLoaded()) {
      loadMoreItems(this.props.additionalItemsPerLoad);
    }
  };

  getError = () => {
    const { meta = {} } = this.props;
    const error = meta.error || meta.submitError;

    if (!this.displayError() || !this.hasError() || !error) {
      return '';
    }

    return (
      <span>{error}</span>
    );
  };

  getSelected = (isSelected) => {
    if (!isSelected) {
      return null;
    }

    return <NewCheck />;
  };

  getOptionBackgroundColor = (color, isDisabled, isFocused, isSelected) => {
    if (isDisabled) {
      return null;
    }

    if (isSelected) {
      return color;
    }

    if (isFocused) {
      return '#e6ebed';
    }

    return null;
  };

  getOptionLabel = (option: {}) => {
    const { ...selectProps } = this.props;
    return option[get(selectProps, 'labelKey', 'label')];
  };

  getOptionValue = (option: {}) => {
    const { ...selectProps } = this.props;
    return option[get(selectProps, 'valueKey', 'value')];
  };

  getOptionStyles = (styles, {
    data,
    isDisabled,
    isFocused,
    isSelected,
  }) => ({
    ...styles,
    backgroundColor: this.getOptionBackgroundColor(
      data.color,
      isDisabled,
      isFocused,
      isSelected,
    ),
  });

  getControlStyles = (styles, { menuIsOpen }) => {
    const isTop = this.state.menuDirection === 'top';

    return ({
      ...styles,
      borderColor: this.displayError() && this.hasError() ? '#d13d47' : '#d6dfe2',
      borderBottomLeftRadius: menuIsOpen && !isTop ? 0 : 6,
      borderBottomRightRadius: menuIsOpen && !isTop ? 0 : 6,
      borderTopLeftRadius: menuIsOpen && isTop ? 0 : 6,
      borderTopRightRadius: menuIsOpen && isTop ? 0 : 6,
      paddingLeft: '2px',
    });
  };

  getMenuStyles = (styles, { placement }) => {
    const { zIndex } = this.props;
    const isTop = placement === 'top';

    setTimeout(() => this.setState({ menuDirection: placement }), 0);

    let menuStyles = {
      ...styles,
      borderBottomLeftRadius: isTop ? 0 : 6,
      borderBottomRightRadius: isTop ? 0 : 6,
      borderTopLeftRadius: isTop ? 6 : 0,
      borderTopRightRadius: isTop ? 6 : 0,
      marginBottom: isTop ? -1 : 8,
    };

    if (zIndex) {
      menuStyles = {
        ...menuStyles,
        zIndex,
      };
    }

    return menuStyles;
  };

  getMenuListStyles = (styles) => {
    const isTop = this.state.menuDirection === 'top';

    return ({
      ...styles,
      borderBottomLeftRadius: isTop ? 0 : 6,
      borderBottomRightRadius: isTop ? 0 : 6,
      borderTopLeftRadius: isTop ? 6 : 0,
      borderTopRightRadius: isTop ? 6 : 0,
    });
  };

  getMenuPortalStyles = () => ({
    menuPortal: ({ left, top, ...provided }) => ({
      ...provided,
    }),
  });

  getDropdownIndicatorsContainerStyles = (styles) => {
    const { disabled } = this.props;
    return ({
      ...styles,
      cursor: disabled ? 'not-allowed !important' : 'pointer',
    });
  };

  getStyles = () => {
    const { customStyles, usePortal } = this.props;

    if (customStyles) {
      return ({
        control: this.getControlStyles,
        menu: this.getMenuStyles,
        menuList: this.getMenuListStyles,
        option: this.getOptionStyles,
        indicatorsContainer: this.getDropdownIndicatorsContainerStyles,
        ...customStyles,
      });
    }

    if (usePortal) {
      return {
        control: this.getControlStyles,
        menu: this.getMenuStyles,
        menuList: this.getMenuListStyles,
        menuPortal: this.getMenuPortalStyles,
        option: this.getOptionStyles,
        indicatorsContainer: this.getDropdownIndicatorsContainerStyles,
      };
    }

    return {
      control: this.getControlStyles,
      menu: this.getMenuStyles,
      menuList: this.getMenuListStyles,
      option: this.getOptionStyles,
      indicatorsContainer: this.getDropdownIndicatorsContainerStyles,
    };
  };

  displayError() {
    const { meta = {}, displayErrorEarly } = this.props;

    return displayErrorEarly || meta.dirty || meta.touched;
  }

  hasError() {
    const { meta = {} } = this.props;
    const hasSubmitError = meta.submitError && !meta.dirtySinceLastSubmit;

    return !!meta.error || hasSubmitError;
  }

  renderError() {
    const { hideErrorElement, errorCustomClass } = this.props;

    if (hideErrorElement) {
      return null;
    }

    const error = this.getError();

    return (
      <span className={clsx(style.ErrorMessage, errorCustomClass)}>
        {error}
      </span>
    );
  }

  getAriaLabel() {
    const { ariaLabel, ...selectProps } = this.props;
    const placeholder = selectProps.placeholder ? selectProps.placeholder : undefined;
    return ariaLabel ?? placeholder;
  }

  renderNativeSelect() {
    if (!this.props.includeNativeSelect) {
      return null;
    }

    const {
      hasIcon,
      input,
      label,
      options,
      noScroll,
      small,
      includeNativeSelect,
      ariaLabel,
      ...selectProps
    } = this.props;

    let nativeSelectValue = this.getOptionValue(input.value);

    if (selectProps.multi) {
      nativeSelectValue = input.value?.map((item) => this.getOptionValue(item));
    }

    return (
      <select
        hidden
        style={{ display: 'none' }}
        multiple={selectProps.multi}
        name={input.name}
        aria-label={input.name}
        defaultValue={nativeSelectValue}
        key={nativeSelectValue} // Re-render everytime inputValue changes
      >
        {options.map((option) => {
          const optionValue = this.getOptionValue(option);
          const optionLabel = this.getOptionLabel(option);
          return (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          );
        })}
      </select>
    );
  }

  renderLabel() {
    const { label, boldLabel } = this.props;
    const labelClasses = clsx(style.Label, {
      [style.BoldLabel]: boldLabel,
    });

    if (!label) {
      return null;
    }

    return (
      <span className={labelClasses}>
        {label}
      </span>
    );
  }

  render() {
    const {
      async,
      cacheOptions,
      components,
      defaultOptions,
      disabled,
      hasIcon,
      inheritFontStyles,
      input,
      label,
      loadOptions,
      menuPlacement = 'auto',
      menuPosition = 'fixed',
      noDropDownIndicator = false,
      noScroll,
      options,
      small,
      ariaLabel,
      required,
      ...selectProps
    } = this.props;

    let defaultValue = null;

    const DropdownIndicatorIcon = () => {
      if (noDropDownIndicator) {
        return null;
      }
      return <ChevronDownIcon width="10px" height="10px" />;
    };

    const selectFieldClasses = clsx(style.SelectField, {
      [style.InheritFontStyles]: inheritFontStyles,
    });

    const selectedValues = input.value;

    // Make sure the selected option is first in the list
    const orderedOptions = reorderOptions(options, selectedValues, this.getOptionValue);

    if (input && get(input, 'value') !== undefined) {
      const flatOptions = flatten(options.map((option) => {
        if (option.options) {
          return option.options;
        }
        return option;
      }));

      let inputValue = input.value;
      if (!selectProps.multi && isObject(input.value)) {
        inputValue = this.getOptionValue(input.value);
      }

      const selectedOption = flatOptions
        .find((option) => this.getOptionValue(option) === inputValue);

      if (selectProps.multi) {
        defaultValue = input.value;
      }

      if (selectedOption) {
        defaultValue = selectedOption;
      }
    }

    if (async) {
      return (
        <label className={selectFieldClasses} htmlFor={`select-${input.name}`}>
          {this.renderLabel()}
          {/*  For direct access in onSubmit handler of native form */}
          {this.renderNativeSelect()}
          <AsyncSelect
            cacheOptions={cacheOptions}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            {...selectProps}
            id={`select-${input.name}`}
            className="ReactSelect"
            classNamePrefix="ReactSelect"
            value={input.value}
            onChange={this.handleOnChangeWithFixedOptions}
            onInputChange={this.onInputChange}
            options={orderedOptions}
            onMenuScrollToBottom={this.loadMoreItems}
            isClearable={selectProps.clearable}
            isDisabled={disabled}
            isMulti={selectProps.multi}
            isSearchable={selectProps.searchable}
            getOptionLabel={this.getOptionLabel}
            getOptionValue={this.getOptionValue}
            styles={this.getStyles()}
            components={{
              DropdownIndicator: get(components, 'DropdownIndicator', DropdownIndicatorIcon),
              IndicatorSeparator: null,
              ClearIndicator,
              MultiValueRemove,
              Option: get(components, 'Option', Option),
              ...(get(components, 'Input') && { Input: get(components, 'Input') }),
              NoOptionsMessage,
              ...components,
            }}
            maxMenuHeight={200}
            menuPlacement={menuPlacement}
            menuPosition={menuPosition}
            menuShouldBlockScroll
            aria-label={this.getAriaLabel()}
            required={required}
          />
        </label>
      );
    }

    return (
      <label className={selectFieldClasses} htmlFor={`select-${input.name}`}>
        {this.renderLabel()}
        {/*  For direct access in onSubmit handler of native form */}
        {this.renderNativeSelect()}
        <Select
          {...selectProps}
          id={`select-${input.name}`}
          className="ReactSelect"
          classNamePrefix="ReactSelect"
          value={defaultValue}
          onChange={this.handleOnChangeWithFixedOptions}
          onInputChange={this.onInputChange}
          options={orderedOptions}
          onMenuScrollToBottom={this.loadMoreItems}
          isClearable={selectProps.clearable}
          isDisabled={disabled}
          isMulti={selectProps.multi}
          isSearchable={selectProps.searchable}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          styles={this.getStyles()}
          components={{
            DropdownIndicator: get(components, 'DropdownIndicator', DropdownIndicatorIcon),
            IndicatorSeparator: null,
            ClearIndicator,
            MultiValueRemove,
            Option: get(components, 'Option', Option),
            ...(get(components, 'Input') && { Input: get(components, 'Input') }),
            NoOptionsMessage,
            Input,
            ...components,
          }}
          maxMenuHeight={200}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
          menuShouldBlockScroll
          aria-label={this.getAriaLabel()}
          required={required}
        />
        {this.renderError()}
      </label>
    );
  }
}

export default SelectField;
