// @flow

import React from 'react';

import Svg from 'components/svg';

const ReplaceNew = Svg((
  <>
    <mask id="path-1-inside-1_1560_292" fill="white">
      <rect x="1.99658" y="0.5" width="13.5294" height="11.2043" rx="0.76109" />
    </mask>
    <rect x="1.99658" y="0.5" width="13.5294" height="11.2043" rx="0.76109" stroke="currentColor" fill="none" strokeWidth="3" mask="url(#path-1-inside-1_1560_292)" />
    <mask id="path-2-inside-2_1560_292" fill="white">
      <rect x="12.4673" y="13.2957" width="13.5294" height="11.2043" rx="0.76109" />
    </mask>
    <rect x="12.4673" y="13.2957" width="13.5294" height="11.2043" rx="0.76109" fill="currentColor" stroke="currentColor" strokeWidth="2" mask="url(#path-2-inside-2_1560_292)" />
    <path d="M10.9965 22.7475C11.4107 22.7475 11.7465 22.4117 11.7465 21.9975C11.7465 21.5833 11.4107 21.2475 10.9965 21.2475V22.7475ZM6.52691 12.9682C6.23402 12.6753 5.75915 12.6753 5.46625 12.9682L0.693281 17.7411C0.400388 18.034 0.400388 18.5089 0.693281 18.8018C0.986175 19.0947 1.46105 19.0947 1.75394 18.8018L5.99658 14.5592L10.2392 18.8018C10.5321 19.0947 11.007 19.0947 11.2999 18.8018C11.5928 18.5089 11.5928 18.034 11.2999 17.7411L6.52691 12.9682ZM10.9965 21.2475H6.27941V22.7475H10.9965V21.2475ZM6.74658 21.7147V13.4985H5.24658V21.7147H6.74658ZM6.27941 21.2475C6.53742 21.2475 6.74658 21.4567 6.74658 21.7147H5.24658C5.24658 22.2851 5.70899 22.7475 6.27941 22.7475V21.2475Z" fill="currentColor" />
    <path d="M16.9966 1.74976C16.5824 1.74976 16.2466 2.08555 16.2466 2.49976C16.2466 2.91398 16.5824 3.24976 16.9966 3.24976V1.74976ZM21.4663 11.5291C21.7591 11.822 22.234 11.822 22.5269 11.5291L27.2999 6.75614C27.5928 6.46325 27.5928 5.98837 27.2999 5.69548C27.007 5.40259 26.5321 5.40259 26.2392 5.69548L21.9966 9.93812L17.7539 5.69548C17.461 5.40259 16.9862 5.40259 16.6933 5.69548C16.4004 5.98837 16.4004 6.46325 16.6933 6.75614L21.4663 11.5291ZM16.9966 3.24976H21.7138V1.74976H16.9966V3.24976ZM21.2466 2.78259V10.9988H22.7466V2.78259H21.2466ZM21.7138 3.24976C21.4557 3.24976 21.2466 3.0406 21.2466 2.78259H22.7466C22.7466 2.21218 22.2842 1.74976 21.7138 1.74976V3.24976Z" fill="currentColor" />
  </>
), { viewBox: '0 0 26 25' });

ReplaceNew.displayName = 'ReplaceNew';

export default ReplaceNew;
