import { uniqueId } from 'lodash';
import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_14_1274');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M50.1696 83.0525L56.8263 41.0593C54.2039 41.0593 49.7157 41.0593 49.7157 41.0593C46.3874 41.0593 42.1009 39.1918 40.8402 35.7596C40.4368 34.5988 39.4786 30.6114 44.9754 26.725C46.9421 25.3623 48.2029 23.8481 48.455 22.6872C48.7072 21.4759 48.4046 20.416 47.5473 19.6084C46.337 18.4475 43.9668 17.7914 40.9411 17.7914C35.8477 17.7914 32.2672 20.7188 31.9142 22.8386C31.6621 24.4033 32.8724 25.6651 33.9314 26.4727C37.1084 28.8449 37.8649 32.277 35.8981 35.5073C33.881 38.8385 29.4936 41.0088 24.8037 41.0593C24.8037 41.0593 20.1642 41.0593 17.5419 41.0593C16.9367 45.1476 7.05258 107.835 6.29614 112.781H45.5301C45.8831 110.611 47.6986 98.7495 50.1696 83.0525Z" fill="#193E4F" />
        <path d="M4.17809 127.317H20.265C27.1234 127.317 28.7876 130.8 28.2833 133.979C27.8798 136.554 26.1148 138.472 23.0891 139.733C26.9217 141.197 28.4346 143.468 27.8798 147.052C27.1738 151.544 23.2908 154.875 18.1974 154.875H-0.108398L4.17809 127.317ZM14.8187 138.724C17.9453 138.724 19.4077 137.058 19.7103 135.09C20.0129 132.97 19.0547 131.506 15.9281 131.506H13.1545L12.0451 138.724H14.8187ZM13.1041 150.585C16.3315 150.585 18.1974 149.273 18.6513 146.598C19.0043 144.276 17.6931 142.913 14.5665 142.913H11.4399L10.2296 150.635H13.1041V150.585Z" fill="#193E4F" />
        <path d="M50.4218 155.077C46.2362 155.38 44.219 154.926 43.2104 153.109C40.9915 154.471 38.5205 155.178 35.8982 155.178C31.1578 155.178 29.4937 152.705 29.9475 149.979C30.1493 148.667 30.9057 147.405 32.116 146.345C34.7383 144.074 41.1933 143.771 43.7147 142.055C43.9164 140.137 43.16 139.431 40.7898 139.431C38.0162 139.431 35.6965 140.339 31.7126 143.065L32.6707 136.806C36.0999 134.333 39.4282 133.172 43.2609 133.172C48.1525 133.172 52.4894 135.191 51.6826 140.541L50.7244 146.598C50.3714 148.717 50.4723 149.374 52.8424 149.424L50.4218 155.077ZM43.16 145.538C40.9411 146.951 36.8059 146.699 36.3521 149.626C36.1503 150.989 37.0076 151.998 38.3692 151.998C39.6804 151.998 41.2941 151.443 42.6053 150.534C42.5044 150.03 42.5549 149.525 42.7061 148.566L43.16 145.538Z" fill="#193E4F" />
        <path d="M58.2384 133.525H66.6096L66.1558 136.301C68.8285 134.03 70.8457 133.172 73.468 133.172C78.1579 133.172 80.3264 136.049 79.5699 140.743L77.4015 154.825H69.0302L70.8457 143.165C71.1987 141.046 70.5431 140.036 68.9294 140.036C67.6182 140.036 66.4079 140.743 65.248 142.307L63.3317 154.774H54.9604L58.2384 133.525Z" fill="#193E4F" />
        <path d="M86.1256 127.317H94.4969L92.3788 140.844L100.397 133.525H110.735L100.448 142.61L108.718 154.825H98.1782L91.8241 144.932H91.7233L90.2104 154.774H81.8391L86.1256 127.317Z" fill="#193E4F" />
        <path d="M115.728 127.317H125.36L121.124 154.825H111.492L115.728 127.317Z" fill="#193E4F" />
        <path d="M130.05 127.317H143.817C154.457 127.317 157.534 135.039 156.525 141.449C155.567 147.708 150.625 154.825 141.295 154.825H125.763L130.05 127.317ZM138.976 148.263C143.666 148.263 146.237 145.941 146.994 141.046C147.549 137.412 146.439 133.828 141.245 133.828H138.673L136.454 148.263H138.976Z" fill="#193E4F" />
        <path d="M102.919 4.36566H62.8274L57.4819 38.3338H64.2899C68.0217 38.3338 71.5517 36.6177 73.0646 34.1445C73.5689 33.337 73.7706 32.6303 73.7706 31.9742C73.7706 30.561 72.8124 29.501 71.8543 28.7944C69.232 26.826 68.6772 24.7566 68.6772 23.2929C68.6772 22.9901 68.6772 22.7377 68.7277 22.4853C69.2824 18.9018 74.1236 15.0154 80.5281 15.0154C84.3607 15.0154 87.2856 15.9239 89.0507 17.5895C90.614 19.0532 91.2191 21.1226 90.7653 23.2929C90.2105 25.867 87.6387 27.9869 86.1762 29.0468C82.2931 31.7723 82.7974 34.1445 83.0496 34.8511C83.8564 36.971 86.9326 38.3338 89.3028 38.3338H99.6912C99.6912 38.3338 99.6912 38.3338 99.6912 38.3842C113.811 38.4852 121.376 44.9962 119.006 60.138C116.787 74.2198 105.995 80.2765 93.1354 80.3775L88.0421 112.882H95.556C127.276 112.882 153.197 92.4909 158.29 60.2894C164.594 20.315 139.228 4.36566 102.919 4.36566Z" fill="#193E4F" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="160" height="160" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const BankIDLogo = Svg(Fragment, { viewBox: '0 0 160 160', fill: 'none' });

BankIDLogo.displayName = 'BankIDLogo';

export default BankIDLogo;
