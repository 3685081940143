import { put, call, select } from 'redux-saga/effects';
import { matchPath } from 'react-router';
import get from 'lodash/get';

import client from 'oneflow-client';

import { setLoading, setError } from 'reducers/app';
import { setLanguage } from 'reducers/i18n';
import { getLocationSelector } from 'reducers/router';

import { loadClientFeatureFlags } from 'sagas/client-feature-flags';
import { setLanguageFromCookie } from 'sagas/bootup';
import { registerIsoCountryLanguages } from 'sagas/i18n';
import { tryToRedirectToPath, redirectToPath } from 'public/sagas/login';

import { getRecommendedLanguage } from 'utils/navigator';

export function* setLanguageFromNavigator() {
  const language = yield call(getRecommendedLanguage);

  yield put(setLanguage(language));
}

export function* isAuthenticated() {
  try {
    yield call(client.ping);

    return true;
  } catch (error) {
    if (get(error, 'response.status') !== 401) {
      throw error;
    }

    return false;
  }
}

export function* checkIfAuthenticated() {
  if (yield call(isAuthenticated)) {
    const location = yield select(getLocationSelector);
    const isLogin = matchPath(location.pathname, { path: '/login' });

    if (isLogin) {
      yield call(tryToRedirectToPath);

      return;
    }

    const isEmailChange = matchPath(location.pathname, { path: '/change-email/:token(.+)' });

    if (!isEmailChange) {
      yield call(redirectToPath, '/');
    }
  }
}

export default function* bootup() {
  try {
    yield put(setLoading(true));

    yield call(registerIsoCountryLanguages);

    yield call(setLanguageFromNavigator);
    yield call(setLanguageFromCookie);
    yield call(checkIfAuthenticated);
    yield call(loadClientFeatureFlags);
  } catch (error) {
    yield put(setError(true, error));
  } finally {
    yield put(setLoading(false));
  }
}
