// @flow

import * as React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { getCountryListAsOptions } from 'components/countries';

import Field from 'components/field';
import SelectField from 'components/select-field';

type Props = {
  message: MessageTranslator,
  languageCode: EnabledLanguages,
  initialOption?: Option,
  onChange?: (Option) => void,
  clearable?: boolean,
  required?: boolean,
};

export const CountryField = ({
  message,
  languageCode,
  initialOption,
  onChange,
  clearable,
  required,
}: Props) => (
  <Field
    name="country"
    label={message({
      id: 'Country',
      comment: 'Country field label',
    })}
    placeholder={message({
      id: 'Select country',
      comment: 'Country field placeholder',
    })}
    component={SelectField}
    options={getCountryListAsOptions(languageCode)}
    clearable={clearable}
    searchable
    required={required}
    initialValue={initialOption?.value}
    onChange={onChange}
  />
);

export default localize<Props>(CountryField);
