// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837:19934');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.1501 -0.149902V0.850098H6.8501V-0.149902H8.1501V0.850098H10.1501L10.1501 10.1501H-0.149716L-0.149773 9.50015C-0.149851 8.60649 -0.149891 6.5345 -0.149881 4.6501L-0.149902 3.3501C-0.149888 2.59541 -0.149826 1.94005 -0.14979 1.50004L-0.149737 0.850098H1.8501V-0.149902H3.1501ZM1.15017 2.1501C1.15016 2.50054 1.15014 2.9083 1.15013 3.3501H8.8501V2.1501H1.15017ZM8.8501 4.6501H1.15012C1.15011 6.16562 1.15014 7.80248 1.15019 8.8501H8.8501L8.8501 4.6501ZM2.8501 6.8501H7.1501V8.1501H2.8501V6.8501Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const CalendarMinus = Svg(Fragment, { viewBox: '0 0 10 10', fill: 'none' });

CalendarMinus.displayName = 'CalendarMinus';

export default CalendarMinus;
