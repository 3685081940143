// @flow

import React from 'react';

import Svg from 'components/svg';

const Home = Svg((
  <path d="M0.632598 6.66086L0.293458 7.02826L1.02826 7.70654L1.3674 7.33914L0.632598 6.66086ZM7 0.5L7.3674 0.16086C7.27275 0.0583189 7.13955 0 7 0C6.86045 0 6.72725 0.0583189 6.6326 0.16086L7 0.5ZM12.6326 7.33914L12.9717 7.70654L13.7065 7.02826L13.3674 6.66086L12.6326 7.33914ZM3.5 8.5V8H2.5V8.5H3.5ZM3 13.5H2.5V14H3V13.5ZM11 13.5V14H11.5V13.5H11ZM11.5 8.5V8H10.5V8.5H11.5ZM1.3674 7.33914L7.3674 0.83914L6.6326 0.16086L0.632598 6.66086L1.3674 7.33914ZM2.5 8.5V13.5H3.5V8.5H2.5ZM3 14H11V13H3V14ZM11.5 13.5V11H10.5V13.5H11.5ZM11.5 11V8.5H10.5V11H11.5ZM6.6326 0.83914L12.6326 7.33914L13.3674 6.66086L7.3674 0.16086L6.6326 0.83914Z" />
), { viewBox: '0 0 14 14' });

Home.displayName = 'Home';

export default Home;
