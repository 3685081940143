// @flow

import React from 'react';

import Svg from 'components/svg';

const NoContacts = Svg((
  <>
    <path
      d="M12 23.5H3.25C2.65326 23.5 2.08097 23.2629 1.65901 22.841C1.23705 22.419 1 21.8467 1 21.25M1 21.25V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H18.25C18.4489 1 18.6397 1.07902 18.7803 1.21967C18.921 1.36032 19 1.55109 19 1.75V11.75M1 21.25C1 20.6533 1.23705 20.081 1.65901 19.659C2.08097 19.2371 2.65326 19 3.25 19H10.5"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 15.75C5.5 14.5565 5.97411 13.4119 6.81802 12.568C7.66193 11.7241 8.80653 11.25 10 11.25C11.1935 11.25 12.3381 11.7241 13.182 12.568M7.375 7.875C7.375 8.57119 7.65156 9.23887 8.14384 9.73116C8.63613 10.2234 9.30381 10.5 10 10.5C10.6962 10.5 11.3639 10.2234 11.8562 9.73116C12.3484 9.23887 12.625 8.57119 12.625 7.875C12.625 7.17881 12.3484 6.51113 11.8562 6.01884C11.3639 5.52656 10.6962 5.25 10 5.25C9.30381 5.25 8.63613 5.52656 8.14384 6.01884C7.65156 6.51113 7.375 7.17881 7.375 7.875Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M21 15.25L15 21.25M15 15.25L21 21.25"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 22 25', fill: 'none' });

NoContacts.displayName = 'NoContacts';

export default NoContacts;
