// @flow

import React from 'react';

import Svg from 'components/svg';

const Search = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M7.54967 0.350404C8.50386 0.341645 9.45043 0.520903 10.3353 0.877943C11.2202 1.23498 12.0262 1.76281 12.7071 2.4313C13.388 3.09979 13.9306 3.89584 14.3038 4.774C15.0576 6.54754 15.076 8.54787 14.3549 10.3349C14.0686 11.0445 13.675 11.6973 13.1935 12.2738L17.9192 17.0002L16.9999 17.9193L12.2742 13.193C11.7316 13.6461 11.1211 14.0217 10.4586 14.3032C8.68496 15.057 6.68454 15.0753 4.89739 14.3542C3.11023 13.6332 1.68272 12.2317 0.928909 10.4582C0.555659 9.58002 0.359041 8.63694 0.350282 7.68279C0.341523 6.72864 0.520794 5.78211 0.87786 4.89724C1.23493 4.01237 1.76279 3.20649 2.43132 2.52562C3.09985 1.84475 3.89594 1.30221 4.77415 0.928989C5.65235 0.555765 6.59548 0.359162 7.54967 0.350404ZM11.8934 11.7757L11.7759 11.8932C11.2524 12.4022 10.6351 12.8157 9.95011 13.1068C8.49379 13.7257 6.85124 13.7407 5.38381 13.1487C3.91637 12.5566 2.74426 11.4059 2.12532 9.94967C1.81886 9.22862 1.65742 8.45428 1.65023 7.67086C1.64304 6.88743 1.79023 6.11025 2.08341 5.38371C2.37659 4.65716 2.81001 3.99547 3.35893 3.43641C3.90785 2.87735 4.56152 2.43188 5.28261 2.12543C6.00371 1.81897 6.77811 1.65754 7.5616 1.65035C8.34509 1.64316 9.12232 1.79035 9.84892 2.08351C10.5755 2.37668 11.2373 2.81008 11.7963 3.35897C12.3554 3.90785 12.8009 4.56148 13.1074 5.28252C13.7263 6.73873 13.7414 8.38115 13.1493 9.84848C12.8568 10.5733 12.428 11.2261 11.8934 11.7757Z"
  />
), { viewBox: '0 0 18 18', fill: 'none' });

Search.displayName = 'Search';

export default Search;
