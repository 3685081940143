// @flow

import React from 'react';

import Svg from 'components/svg';

const ArrowLeft = Svg((
  <path
    d="M15.5 9H8.75H2M2 9L6.77809 4.25M2 9L6.77809 13.75"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), { viewBox: '0 0 18 18', fill: 'none' });

ArrowLeft.displayName = 'ArrowLeft';

export default ArrowLeft;
