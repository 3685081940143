// @flow

import React from 'react';

import Svg from 'components/svg';

const Money = Svg((
  <>
    <path
      d="M4.69226 5.62021C4.69226 6.10952 5.1299 6.57878 5.90889 6.92478C6.68789 7.27077 7.74444 7.46515 8.84611 7.46515C9.94778 7.46515 11.0043 7.27077 11.7833 6.92478C12.5623 6.57878 13 6.10952 13 5.62021C13 5.1309 12.5623 4.66163 11.7833 4.31564C11.0043 3.96965 9.94778 3.77527 8.84611 3.77527C7.74444 3.77527 6.68789 3.96965 5.90889 4.31564C5.1299 4.66163 4.69226 5.1309 4.69226 5.62021V5.62021Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4.69226 5.62024V11.1551C4.69226 12.1744 6.55226 13 8.84611 13C11.14 13 13 12.1744 13 11.1551V5.62024"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 8.38765C13 9.40698 11.14 10.2326 8.84615 10.2326C6.55231 10.2326 4.69231 9.40698 4.69231 8.38765M8.75385 1.93037C7.67488 1.26596 6.4196 0.944308 5.15385 1.0079C2.86 1.0079 1 1.83812 1 2.85284C1 3.39894 1.53446 3.88969 2.38462 4.22824"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M2.16264 10.3204L2.72007 10.5424L3.16402 9.42755L2.60659 9.20557L2.16264 10.3204ZM1.6 2.85278C1.6 2.52141 1.33137 2.25278 1 2.25278C0.668629 2.25278 0.4 2.52141 0.4 2.85278H1.6ZM2.60659 9.20557C2.22738 9.05457 1.95741 8.88404 1.79179 8.72298C1.6282 8.5639 1.6 8.45035 1.6 8.3876H0.4C0.4 8.87095 0.639031 9.27583 0.955208 9.58329C1.26936 9.88877 1.6917 10.1329 2.16264 10.3204L2.60659 9.20557ZM1.6 8.3876V2.85278H0.4V8.3876H1.6Z"
      fill="currentColor"
    />
    <path
      d="M2.38462 6.99655C1.53446 6.65778 1 6.1667 1 5.62024"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

Money.displayName = 'Money';

export default Money;
