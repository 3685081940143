// @flow

import React from 'react';

import Svg from 'components/svg';

const EditPermissions = Svg((
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1104 0.55755C4.44124 0.42051 4.79584 0.349976 5.15394 0.349976C5.51205 0.349976 5.86665 0.42051 6.19749 0.55755C6.52834 0.694591 6.82895 0.895455 7.08217 1.14867C7.33539 1.40189 7.53625 1.7025 7.67329 2.03335C7.81033 2.3642 7.88087 2.71879 7.88087 3.0769C7.88087 3.435 7.81033 3.7896 7.67329 4.12045C7.53625 4.45129 7.33539 4.75191 7.08217 5.00512C6.82895 5.25834 6.52834 5.45921 6.19749 5.59625C5.86665 5.73329 5.51205 5.80382 5.15394 5.80382C4.79584 5.80382 4.44124 5.73329 4.1104 5.59625C3.77955 5.45921 3.47894 5.25834 3.22572 5.00512C2.9725 4.75191 2.77164 4.45129 2.6346 4.12045C2.49756 3.7896 2.42702 3.435 2.42702 3.0769C2.42702 2.71879 2.49756 2.3642 2.6346 2.03335C2.77164 1.7025 2.9725 1.40189 3.22572 1.14867C3.47894 0.895455 3.77955 0.694591 4.1104 0.55755ZM5.15394 1.64998C4.96656 1.64998 4.78101 1.68688 4.60788 1.75859C4.43476 1.8303 4.27746 1.93541 4.14496 2.06791C4.01245 2.20041 3.90735 2.35772 3.83564 2.53084C3.76393 2.70396 3.72702 2.88951 3.72702 3.0769C3.72702 3.26428 3.76393 3.44984 3.83564 3.62296C3.90735 3.79608 4.01245 3.95338 4.14496 4.08589C4.27746 4.21839 4.43476 4.32349 4.60788 4.3952C4.78101 4.46691 4.96656 4.50382 5.15394 4.50382C5.34133 4.50382 5.52688 4.46691 5.7 4.3952C5.87313 4.32349 6.03043 4.21839 6.16293 4.08589C6.29543 3.95338 6.40054 3.79608 6.47225 3.62296C6.54396 3.44984 6.58087 3.26429 6.58087 3.0769C6.58087 2.88951 6.54396 2.70396 6.47225 2.53084C6.40054 2.35772 6.29543 2.20041 6.16293 2.06791C6.03043 1.93541 5.87313 1.8303 5.7 1.75859C5.52688 1.68688 5.34133 1.64998 5.15394 1.64998ZM5.5584 7.29869C4.27892 7.29869 3.81975 7.39654 3.31162 7.71064C2.80348 8.02475 2.38409 8.46355 2.09328 8.98538C1.80248 9.5072 1.64992 10.0947 1.6501 10.6921V11.4269H4.41933V12.7269H0.350098L0.350098 10.6925C0.349886 9.87352 0.559046 9.06791 0.957713 8.35254C1.35641 7.63711 1.93141 7.0355 2.62807 6.60485C3.32474 6.17421 4.5 6 5.5 6L5.5584 7.29869Z"
      fill="currentColor"
    />
    <rect x="6" y="9" width="7" height="5" fill="currentColor" />
    <path
      d="M10.5658 6.82806L11.7129 6.16576C11.2942 5.47256 10.4603 5 9.5 5C8.11928 5 7 5.97683 7 7.18182V9H8.25V7.43635C8.25 6.79372 8.80964 6.27273 9.5 6.27273C9.95083 6.27273 10.3459 6.49491 10.5658 6.82806Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

EditPermissions.displayName = 'EditPermissions';

export default EditPermissions;
