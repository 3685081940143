import {
  flatten,
  isEmpty,
} from 'lodash';

import { Box } from 'data-validators/entity-schemas/document-box';
import moment from 'moment';

import { getPendingStateParticipants } from 'agreement/pending-state-flow';
import * as participantConstants from 'agreement/participant/constants';
import hasAnyUpdatePermission from 'agreement/participant/has-any-update-permission';
import hasDeclined from 'agreement/participant/has-declined';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';
import hasSigned from 'agreement/participant/has-signed';
import hasSignMethodMarkedAsSigned from 'agreement/participant/has-sign-method-marked-as-signed';
import isDisabled from 'agreement/participant/is-disabled';
import isEnabled from 'agreement/party/is-enabled';
import isMessageRecipient from 'agreement/participant/is-message-recipient';
import isMessageRecipientHasNotSigned from 'agreement/participant/is-message-recipient-has-not-signed';
import isOurselves from 'agreement/party/is-ourselves';
import isSignatory from 'agreement/participant/is-signatory';
import isUndecided from 'agreement/participant/is-undecided';

import {
  isDraft,
  isPending,
} from './states';

const emptyObject = Object.freeze({});

type BoxData = {
  id?: number;
  _id?: number;
}

export const isAgreementOwner = (
  account: Oneflow.Account | Readonly<Record<string, never>>,
  agreement: Oneflow.Agreement,
) => (
  !isEmpty(account) && !isEmpty(agreement) && account.id === agreement.account?.id
);

export const getAgreementEnabledParties = (agreement: Oneflow.Agreement) => (
  agreement.parties?.filter(isEnabled)
);

export const getAgreementCounterparties = (agreement: Oneflow.Agreement) => {
  if (isEmpty(agreement)) {
    return [];
  }
  return agreement.parties?.filter((party) => isEnabled(party) && !isOurselves(party)) || [];
};

export const getAgreementMyParty = (agreement: Oneflow.Agreement) => {
  if (isEmpty(agreement)) {
    return undefined;
  }
  return agreement.parties?.find((party) => isEnabled(party) && isOurselves(party));
};

export const getAgreementOwnerParticipants = (agreement: Oneflow.Agreement) => {
  const party = getAgreementMyParty(agreement);

  if (!party || !party.participants.length) {
    return [];
  }

  return party.participants.filter(
    (participant) => !isDisabled(participant),
  );
};

export const getAgreementOwnerParticipantsWithoutSelf = (agreement: Oneflow.Agreement) => {
  const party = getAgreementMyParty(agreement);

  if (!party || !party.participants.length) {
    return [];
  }

  return party.participants.filter((participant) => !participant.self && !isDisabled(participant));
};

export const getCounterpartyParticipants = (agreement: Oneflow.Agreement) => {
  if (isEmpty(agreement)) {
    return [];
  }

  return flatten(
    getAgreementCounterparties(agreement)
      .map((party) => party.participants),
  );
};

export const getAgreementMyParticipant = (agreement: Oneflow.Agreement) => (
  getAgreementOwnerParticipants(agreement).find(
    (participant) => participant.self,
  )
);

export const getParticipants = (agreement: Oneflow.Agreement): Oneflow.Participant[] => {
  if (isEmpty(agreement)) {
    return [];
  }
  return flatten(agreement.parties?.map((party) => party.participants!));
};

export const getEnabledParticipants = (agreement: Oneflow.Agreement) => (
  getParticipants(agreement)
    .filter((participant) => !isDisabled(participant))
);

export const getAgreementSignatories = (agreement: Oneflow.Agreement) => (
  getEnabledParticipants(agreement)
    .filter(isSignatory)
);

export const getAgreementSignatoriesWithoutSelf = (agreement: Oneflow.Agreement) => (
  getAgreementSignatories(agreement)
    .filter((participant) => !participant.self)
);

export const getSignedParticipants = (agreement: Oneflow.Agreement) => (
  getAgreementSignatories(agreement)
    .filter(hasSigned)
);

export const getUsedDeliveryChannels = (agreement: Oneflow.Agreement) => (
  getCounterpartyParticipants(agreement).map((participant) => participant.deliveryChannel)
);

export const getUsedMfas = (agreement: Oneflow.Agreement) => (
  getCounterpartyParticipants(agreement)
    .map((participant) => (participant.mfaChannel === null ? 'none' : participant.mfaChannel))
);

export const getUsedSignMethods = (agreement: Oneflow.Agreement) => (
  getCounterpartyParticipants(agreement).map((participant) => participant.signMethod)
);

export const getDecliningParticipant = (agreement: Oneflow.Agreement) => (
  getParticipants(agreement)
    .find(hasDeclined)
);

export const getParticipantById = (agreement: Oneflow.Agreement, participantId: Oneflow.Participant['id']) => (
  getParticipants(agreement)
    .find((participant) => participant.id === participantId)
);

export const getMarkedAsSignedParticipant = (agreement: Oneflow.Agreement) => (
  getParticipants(agreement)
    .find(hasSignMethodMarkedAsSigned)
);

export const getMarkedAsDeclinedParticipant = (agreement: Oneflow.Agreement) => (
  getParticipants(agreement)
    .find((participant) => hasDeclined(participant)
      && isAgreementOwner({ id: participant.account?.id }, agreement))
);

export const isSignOrderEnabled = (
  agreement: Oneflow.Agreement,
) => Boolean(agreement.config?.signOrder);

export const getAgreementPossibleDeliveryChannels = (agreement: Oneflow.Agreement) => (
  agreement.availableOptions?.deliveryChannels
);

export const canBeSigned = (agreement: Oneflow.Agreement) => {
  const signatories = getAgreementSignatories(agreement);

  return Boolean(signatories.length);
};

export const isMyParticipantTurnToSign = (
  agreement: Oneflow.Agreement,
  agreementParticipant: Oneflow.Participant,
) => {
  if (!agreementParticipant) {
    return false;
  }

  if (!agreement.config?.signOrder) {
    return true;
  }

  const entries = getPendingStateParticipants(agreement);
  const currentBlock = agreement.pendingStateFlow?.runningBlock?.config.blockIndex || 1;

  const currentBlockEntries = entries.filter((participant) => participant.block === currentBlock);
  return !!currentBlockEntries.find(
    (blockParticipant) => blockParticipant.participantId === agreementParticipant.id,
  );
};

export const getAgreementUpdaters = (agreement: Oneflow.Agreement) => (
  getEnabledParticipants(agreement)
    .filter(hasAnyUpdatePermission)
);

// participant could be undefined in case that agreement is a template and there are no participants
export const getMyParticipantWhenUpdater = (
  agreement: Oneflow.Agreement,
) => {
  const updaters = getAgreementUpdaters(agreement);

  return updaters.find((participant) => participant.self);
};

export const getCurrentAgreementUpdater = (
  agreement: Oneflow.Agreement,
): Oneflow.Participant | Record<string, never> | undefined => {
  if (isEmpty(agreement)) {
    return {};
  }

  return getMyParticipantWhenUpdater(agreement);
};

export const getMyParticipantWhenSignatory = (
  agreement: Oneflow.Agreement,
) => {
  const signatories = getAgreementSignatories(agreement);

  return signatories.find((participant) => participant.self);
};

export const getUndecidedSignatories = (agreement: Oneflow.Agreement) => (
  getAgreementSignatories(agreement).filter(isUndecided)
);

export const isPartiallySigned = (agreement: Oneflow.Agreement) => (
  Boolean(getSignedParticipants(agreement).length)
  && Boolean(getUndecidedSignatories(agreement).length));

export const getCounterpartyNames = (agreement: Oneflow.Agreement) => (
  getAgreementCounterparties(agreement)
    .map((counterparty) => counterparty.name)
);

export const getParticipantParty = (
  agreement: Oneflow.Agreement,
  partyId: number,
): Oneflow.Party | Record<string, never> => {
  if (isEmpty(agreement)) {
    return emptyObject;
  }
  return agreement.parties?.find((party) => party.id === partyId) || emptyObject;
};

// TODO: replace any with AppState when redux state is typed
export const getGuestToken = (state: any) => (
  state.session.guestToken
);

export const isSharedFromAnotherWorkspace = (
  agreement: Oneflow.Agreement,
  currentWorkspaceId: number,
) => (
  Boolean(agreement?.isShared && agreement?.collection?.id !== currentWorkspaceId)
);

export const getMyPartyParticipants = (agreement: Oneflow.Agreement, party: Oneflow.Party) => (
  getEnabledParticipants(agreement).filter(() => (
    getParticipantParty(agreement, party.id)?.self === 1
  ))
);

export const usesSameDeviceSome = (
  agreement: Oneflow.Agreement,
  accountFromSession: Oneflow.Account,
) => {
  if (isEmpty(agreement) || isEmpty(accountFromSession)) {
    return false;
  }
  const party = getAgreementMyParty(agreement);
  const participants = isAgreementOwner(accountFromSession, agreement)
    ? getCounterpartyParticipants(agreement)
    : getMyPartyParticipants(agreement, party);

  return (
    participants.length
    && participants.some(hasDeliveryChannelSameDevice)
  );
};

export const usesSameDeviceAll = (agreement: Oneflow.Agreement) => {
  if (isEmpty(agreement)) {
    return false;
  }

  const ownerSideParticipants = getAgreementOwnerParticipants(agreement);

  if (ownerSideParticipants.length > 1) {
    return false;
  }

  const counterpartyParticipants = getCounterpartyParticipants(agreement);

  return (
    counterpartyParticipants.length > 0
    && counterpartyParticipants.every(hasDeliveryChannelSameDevice)
  );
};

export const getParticipantsSameDevice = (agreement: Oneflow.Agreement) => {
  if (isEmpty(agreement)) {
    return [];
  }

  const counterpartyParticipants = getCounterpartyParticipants(agreement);

  return counterpartyParticipants.filter(hasDeliveryChannelSameDevice).filter(
    (participant) => participant.state !== participantConstants.STATE_IS_DISABLED,
  );
};

export const getOtherEnabledParticipants = (agreement: Oneflow.Agreement) => (
  getEnabledParticipants(agreement)
    .filter((participant) => !participant.self)
);

export const isMessageParticipantsVisible = (
  agreement: Oneflow.Agreement,
) => {
  const myParticipant = getMyParticipantWhenUpdater(agreement);
  const otherEnabledParticipants = getOtherEnabledParticipants(agreement);

  return (
    Boolean(otherEnabledParticipants.length)
    && Boolean(myParticipant)
    && isPending(agreement)
    && canBeSigned(agreement)
  );
};

export const getMessageCategoryWithParticipants = (agreement: Oneflow.Agreement) => ({
  unsignedParticipants: getOtherEnabledParticipants(agreement)
    .filter(isMessageRecipientHasNotSigned).filter(isSignatory),
  signatories: getAgreementSignatoriesWithoutSelf(agreement).filter(isMessageRecipient),
  allColleagues: getAgreementOwnerParticipantsWithoutSelf(agreement)
    .filter(isMessageRecipient),
  allCounterparties: getCounterpartyParticipants(agreement).filter(isMessageRecipient),
  allParticipants: getOtherEnabledParticipants(agreement),
  custom: getOtherEnabledParticipants(agreement),
});

export const getMessageRecipientsForEnabledBlocks = (
  agreement: Oneflow.Agreement,
  messageParticipants: Oneflow.Participant[],
) => {
  if (!agreement.config?.signOrder) {
    return messageParticipants;
  }
  const entries = getPendingStateParticipants(agreement);
  const currentBlock = agreement.pendingStateFlow?.runningBlock?.config.blockIndex || 1;
  const blockParticipants = entries
    .filter((participant) => participant.block <= currentBlock);
  return messageParticipants.filter(
    (messageParticipant) => blockParticipants.find(
      (blockParticipant) => blockParticipant.participantId === messageParticipant.id,
    ),
  );
};

export const getMessageRecipients = (
  agreement: Oneflow.Agreement,
  category: keyof ReturnType<typeof getMessageCategoryWithParticipants>,
) => {
  const messageParticipants = getMessageCategoryWithParticipants(agreement)[category];

  if (agreement.config?.signOrder) {
    return getMessageRecipientsForEnabledBlocks(agreement, messageParticipants);
  }

  return messageParticipants;
};

export const isNewUpdate = (
  agreement: Oneflow.Agreement,
  entity: Oneflow.Message | Oneflow.AgreementEvent,
) => {
  const currentParticipant = getAgreementMyParticipant(agreement);
  if (!currentParticipant || !entity?.ownerParticipant || isDraft(agreement)) {
    return false;
  }

  const { ownerParticipant } = entity;
  const created = moment(entity.created);
  const currentParticipantId = currentParticipant && currentParticipant.id;
  const lastVisit = moment(currentParticipant.lastVisit);
  const isAfterLastVisit = !lastVisit.isValid() || created.isAfter(lastVisit);

  return isAfterLastVisit && currentParticipantId !== ownerParticipant.id;
};

export const isSoleSignatory = (agreement: Oneflow.Agreement) => {
  const agreementSignatories = getAgreementSignatories(agreement);

  return agreementSignatories.length === 1
    && agreementSignatories[0] === getAgreementMyParticipant(agreement);
};

export const isNewBox = (box: Box) => box._id && !box.id;

export const isNewBoxData = (data: BoxData) => data._id && !data.id;
