// @flow

import React from 'react';

import Svg from 'components/svg';

const NoInternetIcon = Svg((
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2293 16.4654C12.6498 12.2882 14.1836 8.29605 16.6723 4.89506C17.978 6.67943 19.0209 8.62653 19.778 10.6777L21.4602 9.03993C20.7908 7.41805 19.9545 5.86234 18.9613 4.39827C20.8508 4.7166 22.6064 5.42226 24.1413 6.42952L25.7124 4.89987C23.158 3.11688 20.0391 2.06921 16.6723 2.06921C8.01671 2.06921 1 8.99331 1 17.5346C1 20.7824 2.01454 23.7964 3.74794 26.2848L5.31906 24.7551C4.14155 22.9559 3.39128 20.8589 3.20986 18.6038H10.0524C10.0951 19.0665 10.1504 19.5272 10.218 19.9855L13.8334 16.4654H12.2293ZM10.0524 16.4654H3.20986C3.70105 10.3596 8.36249 5.4126 14.3832 4.39827C11.9441 7.99374 10.4515 12.142 10.0524 16.4654Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9407 16.4654L18.7443 18.6038H21.1153C20.6947 22.781 19.161 26.7732 16.6723 30.1742C15.2493 28.2296 14.1386 26.0918 13.3696 23.8367L11.6696 25.4918C12.3717 27.3079 13.2808 29.0459 14.3832 30.6709C12.3064 30.321 10.3912 29.5032 8.75325 28.3313L7.19236 29.851C9.82469 31.827 13.1097 33 16.6723 33C25.3278 33 32.3445 26.0759 32.3445 17.5346C32.3445 14.0937 31.2058 10.9153 29.2799 8.34624L27.719 9.86595C29.0726 11.7612 29.938 14.0204 30.1347 16.4654H23.2921C23.2293 15.7848 23.1393 15.1086 23.0229 14.4381L21.0992 16.311C21.1047 16.3625 21.1101 16.4139 21.1153 16.4654H20.9407ZM23.2921 18.6038C22.893 22.9273 21.4004 27.0755 18.9613 30.6709C24.982 29.6566 29.6435 24.7096 30.1347 18.6038H23.2921Z"
      fill="currentColor"
    />
    <path
      d="M1.92926 32.3631C1.73271 32.557 1.41613 32.5549 1.22217 32.3584C1.02821 32.1618 1.03031 31.8452 1.22687 31.6513L32.6488 0.644117C32.8454 0.450157 33.1619 0.452259 33.3559 0.648815C33.5499 0.845369 33.5478 1.16195 33.3512 1.35591L1.92926 32.3631Z"
      fill="#D9D9D9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
), {
  viewBox: '0 0 34 34',
  fill: 'none',
});

NoInternetIcon.displayName = 'NoInternetIcon';

export default NoInternetIcon;
