// @flow

import React from 'react';

import Svg from 'components/svg';

const AddColumnAfter = Svg((
  <>
    <rect x="10" y="19" width="4" height="14" transform="rotate(-180 10 19)" stroke="currentColor" strokeWidth="2" />
    <rect x="15" y="15" width="6" height="2" transform="rotate(-90 15 15)" fill="currentColor" />
    <rect x="19" y="13" width="6" height="2" transform="rotate(-180 19 13)" fill="currentColor" />
  </>
), {
  viewBox: '0 0 24 24',
  fill: 'none',
});

AddColumnAfter.displayName = 'AddColumnAfter';

export default AddColumnAfter;
