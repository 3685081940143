import { Helmet } from 'react-helmet-async';
import { MessageTranslator, localize } from '@oneflowab/pomes';

import getAppTitle from 'shared/get-app-title';
import { isEnvironment } from 'utils/environment';
import { useSelector } from 'react-redux';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { getLocationSelector } from 'reducers/router';
import { readWorkspaceIdFromLocation } from 'hocs/with-current-workspace';

type Props = {
  title?: string,
  message: MessageTranslator
};

const AppTitle = ({
  title = '',
  message,
}: Props) => {
  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);
  const location = useSelector(getLocationSelector);
  const isNamespaced = readWorkspaceIdFromLocation(location) !== null;

  const getCurrentWorkspaceName = () => {
    if (!currentWorkspace) {
      return '';
    }

    if (currentWorkspace.virtual) {
      return message({ id: 'Shared with me', comment: 'The virtual workspace name' });
    }

    return currentWorkspace.name;
  };

  const getTitle = () => {
    let appTitle = getAppTitle(isEnvironment);
    const currentWorkspaceName = getCurrentWorkspaceName();

    if (isNamespaced && currentWorkspaceName) {
      appTitle = `${appTitle} - ${currentWorkspaceName}`;
    }

    if (title) {
      appTitle = `${appTitle} | ${title}`;
    }

    return appTitle;
  };
  return (
    <Helmet>
      <title>{getTitle()}</title>
    </Helmet>
  );
};

export default localize<Props>(AppTitle);
