// @flow

import React from 'react';

import Svg from 'components/svg';
import style from './oneflow.module.scss';

type Props = {
  isDark?: boolean,
};

const OneflowSymbol = (props: Props) => {
  const { isDark, ...svgProps } = props;
  const color = isDark ? style['forest-green'] : 'white';
  const Logo = Svg(
    <React.Fragment>
      <rect fill={color} x="289.92" y="38.01" width="51.87" height="51.87" />
      <rect fill={color} x="289.92" y="141.75" width="51.87" height="51.86" />
      <rect fill={color} x="238.05" y="89.88" width="51.87" height="51.86" />
      <rect fill={color} x="341.79" y="89.88" width="51.87" height="51.86" />
      <rect fill={color} x="379.71" width="51.87" height="51.87" />
      <path fill={color} d="M138.44,412.73C62.63,412.73,1,351.05,1,275.24S62.63,137.75,138.44,137.75s137.49,61.68,137.49,137.49S214.25,412.73,138.44,412.73m0-225.66C91.33,187.07,53,226.62,53,275.24s38.32,88.17,85.43,88.17,85.43-39.55,85.43-88.17-38.33-88.17-85.43-88.17" transform="translate(-0.95)" />
    </React.Fragment>,
    { viewBox: '0 0 431.58 412.73' },
  );

  return <Logo {...svgProps} />;
};

OneflowSymbol.defaultProps = {
  isDark: undefined,
};

export default OneflowSymbol;
