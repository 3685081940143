// @flow

import React from 'react';

import Svg from 'components/svg';

const MenuArrow = Svg((
  <>
    <line
      x1="1.8"
      y1="4.2"
      x2="14.2"
      y2="4.2"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
      fill="none"
    />
    <line
      x1="1.8"
      y1="20.2"
      x2="14.2"
      y2="20.2"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
      fill="none"
    />
    <path
      d="M1.79999 12.0572H22.2M22.2 12.0572L18 7.85718M22.2 12.0572L18 16.2572"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
      strokeLinejoin="round"
      fill="none"
    />
  </>
), { fill: 'none', viewBox: '0 0 24 24' });

MenuArrow.displayName = 'MenuArrow';

export default MenuArrow;
