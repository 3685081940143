import { DEFAULT_OBSERVER_ID, TOAST_TYPES } from './constants';
import ToastObserver from './toast-observer';
import { ObserverId, Toast } from './types';

const createToast = ({ observerId = DEFAULT_OBSERVER_ID, toast }: {
  observerId?: ObserverId,
  toast: Toast,
}) => {
  const toastObserver = ToastObserver.getInstanceById(observerId) as ToastObserver;
  // If the toast provider hasn't mounted, queue the toast
  if (!toastObserver) {
    ToastObserver.queueToast(observerId, toast);
    return;
  }

  toastObserver.create(toast);
};

type ToastProps = Omit<Toast, 'content' | 'type' | 'remove' | 'reoccurred'>;

const createSuccessToast = ({
  observerId,
  ...toast
}: {
  observerId?: ObserverId,
} & ToastProps) => {
  createToast({
    observerId,
    toast: {
      ...toast,
      type: TOAST_TYPES.SUCCESS,
    },
  });
};

const createErrorToast = ({
  observerId,
  ...toast
}: {
  observerId?: ObserverId,
} & ToastProps) => {
  createToast({
    observerId,
    toast: {
      ...toast,
      type: TOAST_TYPES.ERROR,
    },
  });
};

const createWarningToast = ({
  observerId,
  ...toast
}: {
  observerId?: ObserverId,
} & ToastProps) => {
  createToast({
    observerId,
    toast: {
      ...toast,
      type: TOAST_TYPES.WARNING,
    },
  });
};

const createInfoToast = ({
  observerId,
  ...toast
}: {
  observerId?: ObserverId,
} & ToastProps) => {
  createToast({
    observerId,
    toast: {
      ...toast,
      type: TOAST_TYPES.INFO,
    },
  });
};

const removeToast = ({
  observerId = DEFAULT_OBSERVER_ID,
  toastId: id,
}: {
  observerId?: ObserverId,
  toastId: Toast['id'],
}) => {
  const toastObserver = ToastObserver.toastObservers[observerId] as ToastObserver;
  if (!toastObserver) {
    return;
  }
  toastObserver.remove(id);
};

const updateToast = ({
  observerId = DEFAULT_OBSERVER_ID,
  ...toast
}: {
    observerId?: ObserverId
  } & ToastProps) => {
  const toastObserver = ToastObserver.getInstanceById(observerId) as ToastObserver;
  if (!toastObserver) {
    return;
  }
  toastObserver.update(toast);
};

export const removeReoccurred = ({
  observerId = DEFAULT_OBSERVER_ID,
  toastId: id,
}: {
  observerId?: ObserverId,
  toastId: Toast['id'],
}) => {
  const toastObserver = ToastObserver.getInstanceById(observerId) as ToastObserver;
  if (!toastObserver) {
    return;
  }
  toastObserver.removeReoccurred(id);
};

const toast = Object.assign(createToast, {
  success: createSuccessToast,
  error: createErrorToast,
  warning: createWarningToast,
  info: createInfoToast,
  remove: removeToast,
  update: updateToast,
});

export default toast;
