import React from 'react';

import Logo from '../logo';

import spinner from './spinner.gif';

import style from './spinner.module.scss';

const Spinner = () => (
  <div className={style.Spinner}>
    <Logo />
    <img src={spinner} alt={spinner} width="39" height="13" />
  </div>
);

export default Spinner;
