// @flow

import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

export const modalRoutesQueryStringKeys = ['m'];
export const getModalRoutesQuery = (location: any) => (
  pick(location.query, modalRoutesQueryStringKeys)
);

export const getModalRoutesQueryStrings = (location: any) => {
  const modalRoutesQuery = getModalRoutesQuery(location);

  if (isEmpty(modalRoutesQuery)) {
    return '';
  }

  return `?${queryString.stringify(modalRoutesQuery)}`;
};
