// @flow

import React from 'react';

import Svg from 'components/svg';

const Terminate = Svg((
  <>
    <path d="M7.5 4.5V9.5H6.5V4.5H7.5Z" fill="currentColor" />
    <path d="M6.25 11C6.25 11.1989 6.32902 11.3897 6.46967 11.5303C6.61032 11.671 6.80109 11.75 7 11.75C7.19891 11.75 7.38968 11.671 7.53033 11.5303C7.67098 11.3897 7.75 11.1989 7.75 11C7.75 10.8011 7.67098 10.6103 7.53033 10.4697C7.38968 10.329 7.19891 10.25 7 10.25C6.80109 10.25 6.61032 10.329 6.46967 10.4697C6.32902 10.6103 6.25 10.8011 6.25 11Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99995 0.00109863C6.72163 0.00109863 6.44879 0.0785352 6.21197 0.224746C5.97514 0.370957 5.78366 0.580176 5.65895 0.828998L0.15874 11.8294C0.0444927 12.0581 -0.00949337 12.3122 0.00200642 12.5675C0.0135062 12.8228 0.0900569 13.071 0.224395 13.2884C0.358732 13.5059 0.546401 13.6854 0.769594 13.8099C0.992787 13.9345 1.2441 13.9999 1.49969 14H12.5C12.7555 13.9999 13.0071 13.9345 13.2303 13.8099C13.4535 13.6854 13.6412 13.5059 13.7755 13.2884C13.9099 13.071 13.9864 12.8228 13.9979 12.5675C14.0094 12.3122 13.9555 12.0582 13.8412 11.8295L8.34117 0.829425C8.21646 0.580603 8.02477 0.370957 7.78794 0.224746C7.55111 0.0785352 7.27828 0.00109863 6.99995 0.00109863ZM6.73729 1.07565C6.81623 1.02691 6.90718 1.0011 6.99995 1.0011C7.09273 1.0011 7.18367 1.02691 7.26262 1.07565C7.3415 1.12435 7.40529 1.19402 7.44686 1.27688L12.9467 12.2765C12.9847 12.3527 13.0027 12.4375 12.9989 12.5225C12.9951 12.6076 12.9696 12.6904 12.9248 12.7628C12.88 12.8353 12.8174 12.8951 12.7431 12.9367C12.6687 12.9782 12.5849 13 12.4997 13H1.50022C1.41503 13 1.33125 12.9782 1.25686 12.9367C1.18246 12.8951 1.1199 12.8353 1.07512 12.7628C1.03034 12.6904 1.00483 12.6076 1.00099 12.5225C0.997162 12.4375 1.01512 12.3528 1.05317 12.2766L6.55295 1.27707C6.59453 1.19421 6.65841 1.12435 6.73729 1.07565Z" fill="currentColor" />
  </>
), { viewBox: '0 0 14 14' });

Terminate.displayName = 'Terminate';

export default Terminate;
