type Role = {
  name: string,
}

export const sortedParticipantRoles = (
  roles: Role[],
): Role[] => {
  const order: { [key: string]: number } = {
    signatory: 1,
    organizer: 2,
    internalApprover: 3,
    externalApprover: 4,
    influencer: 5,
    viewer: 6,
  };

  const sortedRoles = roles.sort((a: Role, b: Role) => order[a.name] - order[b.name]);

  return sortedRoles;
};

export const sortedGuestRoles = (
  roles: Role[],
): Role[] => {
  const order: { [key: string]: number } = {
    signatory: 1,
    externalApprover: 2,
    influencer: 3,
    viewer: 4,
  };

  const sortedRoles = roles.sort((a: Role, b: Role) => order[a.name] - order[b.name]);

  return sortedRoles;
};
