// @flow

import React from 'react';

import Svg from 'components/svg';

const NewCross = Svg((
  <path
    d="M1.5 12.5L12.5 1.5M1.5 1.5L12.5 12.5"
    stroke="currentColor "
    strokeWidth="1.3"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), { viewBox: '0 0 14 14', fill: 'none' });

NewCross.displayName = 'NewCross';

export default NewCross;
