// @flow

import React from 'react';

import Svg from 'components/svg';

const Underline = Svg((
  <>
    <path d="M6 20H18V22H6V20Z" fill="currentColor" />
    <path d="M15.4286 5.11517V13.1152C15.4286 14.8948 13.9459 15.9999 12.0001 15.9999C10.0473 15.9999 8.57148 14.9225 8.57148 13.1428V5.1428L6.28577 5.11517V13.3298C6.28577 16.3767 8.53077 18.292 12.0001 18.292C15.4555 18.292 17.7026 16.3767 17.7026 13.3298V5.11517H15.4286Z" fill="currentColor" />
  </>
));

Underline.displayName = 'Underline';

export default Underline;
