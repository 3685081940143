// @flow

import React from 'react';

import Svg from 'components/svg';

const VideoSizeSplash = Svg((
  <>
    <mask id="path-1-inside-1_982_13984" fill="white">
      <rect x="0.996582" y="7.5" width="23.9541" height="9.06667" rx="0.496422" />
    </mask>
    <rect x="0.996582" y="7.5" width="23.9541" height="9.06667" rx="0.496422" fill="currentColor" stroke="currentColor" strokeWidth="2" mask="url(#path-1-inside-1_982_13984)" />
    <mask id="path-2-inside-2_982_13984" fill="white">
      <rect x="0.996582" y="0.5" width="24" height="24" rx="1" />
    </mask>
    <rect x="0.996582" y="0.5" width="24" height="24" rx="1" stroke="currentColor" strokeWidth="4" mask="url(#path-2-inside-2_982_13984)" />
  </>
), {
  viewBox: '0 0 25 25',
  fill: 'none',
});

VideoSizeSplash.displayName = 'VideoSizeSplash';

export default VideoSizeSplash;
