import Svg from 'components/svg';

const EidSignatures = Svg((
  <>
    <path d="M38.5714 18.5812C36.6569 17.5259 34.5064 16.9725 32.3203 16.9725C30.1342 16.9725 27.9837 17.5259 26.0692 18.5812C24.1939 19.5968 22.6255 21.0969 21.5274 22.925C20.4292 24.7531 19.8416 26.8424 19.8257 28.9749" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M44.82 28.9748C44.8246 27.3202 44.4738 25.6839 43.7914 24.1765" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M32.328 52.9715C33.167 52.9709 34.0039 52.8899 34.8274 52.7298" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M19.8257 36.1748V40.9731C19.8492 43.3423 20.5739 45.6516 21.9083 47.6093C23.2428 49.5671 25.1273 51.0858 27.324 51.9737" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M39.8263 31.3715V28.9749C39.8162 27.6949 39.4631 26.441 38.8037 25.3438C38.1443 24.2466 37.2027 23.3463 36.0772 22.7366C34.9279 22.1041 33.6373 21.7724 32.3254 21.7724C31.0136 21.7724 29.723 22.1041 28.5737 22.7366" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M24.8246 28.9749V40.9732C24.8246 44.9486 28.1829 48.1732 32.328 48.1732" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M34.8274 34.9714V28.9749C34.7982 28.3309 34.5219 27.723 34.0558 27.2777C33.5898 26.8323 32.97 26.5838 32.3254 26.5838C31.6808 26.5838 31.061 26.8323 30.595 27.2777C30.129 27.723 29.8526 28.3309 29.8234 28.9749V33.7731" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M29.8286 40.9732C29.8353 41.2954 29.9053 41.6131 30.0348 41.9082C30.1643 42.2032 30.3507 42.4699 30.5833 42.6929C30.8159 42.916 31.0901 43.091 31.3904 43.208C31.6906 43.325 32.011 43.3817 32.3331 43.3749" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M56.9211 43.3748C56.9211 45.2737 56.3581 47.1298 55.3033 48.7087C54.2485 50.2876 52.7492 51.5183 50.995 52.2452C49.2409 52.9721 47.3105 53.1625 45.4481 52.7924C43.5857 52.4224 41.8748 51.5084 40.5318 50.1661C39.1888 48.8238 38.2739 47.1134 37.9028 45.2512C37.5318 43.389 37.7212 41.4586 38.4471 39.704C39.1731 37.9494 40.4029 36.4495 41.9813 35.3938C43.5596 34.3381 45.4155 33.7741 47.3143 33.7731" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
    <path d="M56.9212 36.1748L47.3143 45.7714L43.7143 42.1714" stroke="white" strokeWidth="1.65405" strokeLinecap="square" />
  </>
), { viewBox: '0 0 70 70', fill: 'none' });

EidSignatures.displayName = 'EidSignatures';

export default EidSignatures;
