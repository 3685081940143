// @flow

import React from 'react';

import Svg from 'components/svg';

const NewTemplate = Svg((
  <>
    <path d="M24.2146 10.1133H5.38379V11.798H24.2146V10.1133Z" fill="#013A4C" />
    <path d="M19.6327 17.3467H5.38379V19.0314H19.6327V17.3467Z" fill="#013A4C" />
    <path d="M13.2383 24.5811H5.38379V26.2657H13.2383V24.5811Z" fill="#013A4C" />
    <path d="M2.21189 2.18468H21.7979L27.4371 7.73423V13.036H29.149V6.99099L22.5028 0.5H0.5V33.5H19.6329V31.8153H2.21189V2.18468Z" fill="#013A4C" />
    <path d="M36.4999 24.5809H29.1489V17.3467H27.437V24.5809H20.0859V26.2656H27.437V33.4998H29.1489V26.2656H36.4999V24.5809Z" fill="#013A4C" />
  </>
), { viewBox: '0 0 37 34' });

NewTemplate.displayName = 'NewTemplate';

export default NewTemplate;
