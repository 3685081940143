// @flow

import React from 'react';

import Svg from 'components/svg';

const Fragment = () => (
  <path xmlns="http://www.w3.org/2000/svg" d="M6.5 16H3V7.5H6.5M7.5 3.5H10M12.5 3.5H10M10 3.5V20.5M10 20.5H7.5M10 20.5H12.5M13.5 16H20.5V7.5H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
);

const TypeCursor = Svg(Fragment, { viewBox: '0 0 24 24', fill: 'none' });

TypeCursor.displayName = 'TypeCursor';

export default TypeCursor;
