// @flow

import React from 'react';

import Svg from 'components/svg';

const Phone = Svg((
  <path
    d="M6.08083 8.69004C6.45877 8.93143 6.9078 9.03657 7.35362 8.98808C7.79945 8.93958 8.21537 8.74035 8.53258 8.42335L8.80815 8.14777C8.931 8.02476 9 7.85803 9 7.68418C9 7.51033 8.931 7.3436 8.80815 7.22059L7.65251 6.06494C7.52935 5.94204 7.36246 5.87302 7.18847 5.87302C7.01448 5.87302 6.84759 5.94204 6.72443 6.06494C6.60142 6.18779 6.43469 6.25679 6.26084 6.25679C6.08699 6.25679 5.92026 6.18779 5.79725 6.06494L3.93843 4.20613C3.81559 4.08312 3.74659 3.91638 3.74659 3.74253C3.74659 3.56869 3.81559 3.40195 3.93843 3.27894C4.06133 3.15578 4.13036 2.9889 4.13036 2.8149C4.13036 2.64091 4.06133 2.47403 3.93843 2.35087L2.77745 1.19166C2.65444 1.06882 2.4877 0.999817 2.31386 0.999817C2.14001 0.999817 1.97328 1.06882 1.85027 1.19166L1.57469 1.46724C1.25754 1.78436 1.05821 2.2003 1.00971 2.64617C0.961214 3.09203 1.06645 3.5411 1.308 3.91899C2.57935 5.7998 4.19954 7.41939 6.08083 8.69004Z"
    stroke="currentColor"
    strokeWidth="1.15565"
    strokeLinecap="square"
  />
), { viewBox: '0 0 10 10', fill: 'none' });

Phone.displayName = 'Phone';

export default Phone;
