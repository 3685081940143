// @flow

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'reducers/i18n';

import {
  PublicPasswordFieldSignUp,
} from 'components/fields';

type Props = {
  languageCode?: EnabledLanguages,
};

const SignupInviteFields = ({ languageCode }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (languageCode) {
      dispatch(setLanguage(languageCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <PublicPasswordFieldSignUp
      autoFocus
      hideRequired
    />
  );
};

export default SignupInviteFields;
