// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import { hasAnyGroup } from 'oneflow-client/groups';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  hideLabel?: boolean,
  autoFocus?: boolean,
  name?: string,
};

export const GroupName = ({
  message,
  hideLabel,
  autoFocus,
  name,
}: Props) => (
  <Field
    name="name"
    label={message({ id: 'Group name', comment: 'Field label in add group page' })}
    placeholder={message({ id: 'Enter name', comment: 'Field placeholder in add group page' })}
    component={TextField}
    maxLength={50}
    responsive
    unique={{
      text: message({
        id: 'A group with this name already exists. Try another one.',
        comment: 'Form validation error message.',
      }),
      param: 'name',
      request: hasAnyGroup,
      initialValue: name,
    }}
    hideLabel={hideLabel}
    autoFocus={autoFocus}
    required
    autoComplete="off"
  />
);

GroupName.defaultProps = {
  hideLabel: undefined,
  autoFocus: undefined,
  name: undefined,
};

export default localize<Props>(GroupName);
