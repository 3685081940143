/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// @flow

import * as React from 'react';
import * as ReactPopover from '@radix-ui/react-popover';
import clsx from 'clsx';

import style from './popover.module.scss';

export const Popover = ReactPopover.Root;
export const PopoverTrigger = ReactPopover.Trigger;
export const PopoverPortal = ReactPopover.Portal;
export const PopoverClose = ReactPopover.Close;
export const PopoverAnchor = ReactPopover.Anchor;

/**
 * @typedef {ReactPopover.PopoverContentProps & React.HTMLAttributes<HTMLDivElement> & {
  *      children: React.ReactNode;
  *      className?: string;
  *      style?: React.CSSProperties
  *      sideOffset?: number,
  *      arrowHeight?: number,
 *     }
 *  } Props

 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<HTMLElement>>} */
export const PopoverContent = React.forwardRef<ReactPopover.Content, any>(
  ({
    children,
    sideOffset = 0,
    className,
    arrowClassName,
    arrowHeight = 5,
    ...props
  }, forwardedRef) => (
    <ReactPopover.Content
      className={clsx('legacy-compatible-popover-content', style.PopoverContent, className)}
      sideOffset={sideOffset}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ReactPopover.Arrow
        height={arrowHeight}
        className={clsx(style.PopoverArrow, arrowClassName)}
      />
    </ReactPopover.Content>
  ),
);
