// @flow
import isObject from 'lodash/isObject';
import get from 'lodash/get';

export const webhookFormatOptions = [
  { label: 'Legacy', value: false },
  { label: 'Current', value: true },
];

export const getWebhookFormatValue = (imwProxy: Option | boolean) => {
  if (isObject(imwProxy)) {
    return get(imwProxy, 'value');
  }
  return imwProxy;
};
