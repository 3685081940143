// @flow

import React from 'react';

import Svg from 'components/svg';
import style from './oneflow.module.scss';

type Props = {
  isDark?: boolean,
};

const LogoOneflow = (props: Props) => {
  const { isDark, ...svgProps } = props;
  const color = isDark ? style['forest-green'] : 'white';
  const Logo = Svg(
    <React.Fragment>
      <rect fill={color} x="592.78" y="46.09" width="30.34" height="193.08" />
      <path fill={color} d="M401.43,213.49c-21.59,0-38.39-12.87-44.62-31.49l117-30.29c-5-46.76-39.32-71.13-74.64-71.13a73.48,73.48,0,0,0-56,25.56C330.73,120.66,324,139.88,324,161c0,49.72,35,80.44,78,80.44,28.83,0,51.2-13,63.19-30.15l-21.45-17.94C435,206.21,417.87,213.49,401.43,213.49ZM399,108.67c19.16,0,32.86,10.26,39.79,25.55l-84.62,21.89C354.67,129.34,372.16,108.67,399,108.67Z" />
      <path fill={color} d="M242.55,80.6c-40.45,0-66,25.82-66,64.72v93.85h30.35V145.32c0-23.29,11.38-35.94,35.63-35.94S278,122,278,145.32v93.85h30.35V145.32C308.36,106.42,283,80.6,242.55,80.6Z" />
      <path fill={color} d="M80.7,241.48A80.45,80.45,0,1,1,161.14,161,80.53,80.53,0,0,1,80.7,241.48m0-132c-27.56,0-50,23.14-50,51.59s22.42,51.58,50,51.58,50-23.14,50-51.58-22.42-51.59-50-51.59" />
      <path fill={color} d="M719,241.48A80.45,80.45,0,1,1,799.44,161,80.54,80.54,0,0,1,719,241.48m0-132c-27.56,0-50,23.14-50,51.59s22.43,51.58,50,51.58,50-23.14,50-51.58-22.42-51.59-50-51.59" />
      <path fill={color} d="M520.3,114.84v-8.08c0-13.47.5-19.49,6.14-26.16S542,70.93,555,70.93a85.3,85.3,0,0,1,19,1.77V45.39a110.43,110.43,0,0,0-23.12-2.25c-19.56,0-33.45,4.76-43.32,12.85-13.35,10.94-17.49,25.2-17.49,48.44V239.17h30.34l0-97.77,41.88-10.93V103.9Z" />
      <path fill={color} d="M1016.05,143.05v33.76c0,23.28-11.33,35.93-35.24,35.94-11.92,0-20.84-3.43-26.52-9.44-6.74-7.12-8.45-14.39-8.49-26.29,0-.07,0-.14,0-.22V83H915.46v93.86c0,13.3-2.78,20.87-8.65,26.7s-14.63,9.23-26.58,9.24c-23.63,0-35-12.73-35-35.94V83H814.87v97.37c0,17.93,5,32.3,16.36,43.45,13,12.7,30.17,17.76,49.22,17.76,21,0,38.87-7.87,50.18-20.73,11.26,12.81,29.09,20.73,50.41,20.73,20,0,37.22-6,48.52-17.28,11.51-11.48,16.83-24.38,16.83-45.58V143.05Z" />
      <rect fill={color} x="1016.04" y="22.24" width="30.35" height="30.35" />
      <rect fill={color} x="1016.04" y="82.93" width="30.35" height="30.34" />
      <rect fill={color} x="985.7" y="52.59" width="30.35" height="30.34" />
      <rect fill={color} x="1046.39" y="52.59" width="30.35" height="30.34" />
      <rect fill={color} x="1068.58" width="30.35" height="30.35" />
    </React.Fragment>,
    { viewBox: '0 0 1098.93 241.98' },
  );

  return <Logo {...svgProps} />;
};

LogoOneflow.defaultProps = {
  isDark: undefined,
};

export default LogoOneflow;
