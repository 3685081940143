// @flow

import React from 'react';

import Svg from 'components/svg';

const Template = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.6942 0.426522C11.8854 0.324578 12.1148 0.324626 12.306 0.426649L23.306 6.29704C23.5178 6.41007 23.6501 6.63063 23.65 6.87069C23.6499 7.11076 23.5175 7.33123 23.3057 7.44413L19.801 9.31164L23.3359 11.4451C23.5361 11.5659 23.6559 11.785 23.6497 12.0188C23.6436 12.2526 23.5122 12.465 23.3059 12.5751L19.7996 14.4454L23.3361 16.5823C23.5363 16.7032 23.656 16.9223 23.6497 17.1561C23.6434 17.3899 23.512 17.6022 23.3057 17.7122L12.3057 23.5738C12.1145 23.6757 11.8852 23.6756 11.6941 23.5737L0.694062 17.7062C0.487663 17.5961 0.356341 17.3836 0.3502 17.1498C0.34406 16.9159 0.464047 16.6968 0.664384 16.5761L4.19945 14.4449L0.694062 12.5751C0.487715 12.465 0.356403 12.2526 0.350205 12.0188C0.344006 11.785 0.463877 11.5659 0.664102 11.4451L4.20244 9.30952L0.694062 7.43814C0.482247 7.32515 0.349952 7.10462 0.349976 6.86456C0.350001 6.62449 0.482343 6.40399 0.694181 6.29105L11.6942 0.426522ZM5.53796 10.0219L2.31568 11.9667L12 17.1324L21.6843 11.9667L18.4649 10.0236L12.3057 13.3057C12.1145 13.4076 11.8852 13.4075 11.6941 13.3056L5.53796 10.0219ZM5.53538 15.1575L2.31628 17.0982L12.0001 22.2636L21.6844 17.1031L18.4645 15.1575L12.3059 18.4426C12.1147 18.5445 11.8853 18.5445 11.6941 18.4426L5.53538 15.1575ZM2.38135 6.86477L12.0001 11.9955L21.6186 6.87006L11.9998 1.73679L2.38135 6.86477Z"
    fill="currentColor"
  />
), { viewBox: '0 0 24 24', fill: 'none' });

Template.displayName = 'Template';

export default Template;
