// @flow

import React from 'react';

import Svg from 'components/svg';

const Unlock = Svg((
  <g fill="none">
    <path d="M5.5 1C3.84315 1 2.5 2.34315 2.5 4V5H1.5V4C1.5 1.79086 3.29086 0 5.5 0C6.69469 0 7.76706 0.523755 8.5 1.35418L7.73611 2C7.18679 1.38625 6.3885 1 5.5 1Z" fill="currentColor" />
    <path d="M0.5 5.5H10.5V13.5H0.5V5.5Z" stroke="currentColor" />
    <path d="M6.5 9.5C6.5 10.0523 6.05228 10.5 5.5 10.5C4.94772 10.5 4.5 10.0523 4.5 9.5C4.5 8.94772 4.94772 8.5 5.5 8.5C6.05228 8.5 6.5 8.94772 6.5 9.5Z" fill="currentColor" />
  </g>
), { viewBox: '0 0 11 14' });

Unlock.displayName = 'Unlock';

export default Unlock;
