// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Message } from '@oneflowab/pomes';
import get from 'lodash/get';

import type { FormRenderProps } from 'react-final-form';

import ArrowLeft from 'components/icons/arrow-left';

import style from './login-link.module.scss';

type Props = FormRenderProps & {
  onClick?: () => void,
};

const LoginLink = (props: Props) => (
  <Link
    to={{
      pathname: '/login',
      initialEmail: get(props, 'values.email', undefined),
    }}
    onClick={props.onClick}
    className={style.Link}
  >
    <ArrowLeft className={style.Icon} />
    <Message
      id="Back to login"
      comment="Link text for going to the login page."
    />
  </Link>
);

LoginLink.defaultProps = {
  onClick: undefined,
};

export default LoginLink;
