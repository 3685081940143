// @flow

import React from 'react';

import Svg from 'components/svg';

const Strikethrough = Svg((
  <path fillRule="evenodd" clipRule="evenodd" d="M14.6701 8.79235H17.1423C17.0938 6.52078 15.0991 5 12.0799 5C9.10227 5 6.86558 6.55374 6.87251 8.93035C6.87251 9.32928 6.88131 9.81533 7 10.1466H10.5C10.0439 9.85722 9.4693 9.33868 9.4762 8.77935C9.48313 7.76831 10.4387 7.06148 12.0453 7.06148C13.5756 7.06148 14.5386 7.65 14.6701 8.79235ZM17 12.9909H22V11.0947H2V12.9909H13C14.1236 13.3329 14.8094 13.9281 14.8152 14.7997C14.8082 15.9486 13.7211 16.9188 12.0453 16.9188C10.4387 16.9188 9.20222 16.1633 9.09835 14.7518H6.57083C6.6747 17.4238 8.76296 19 12.066 19C15.4661 19 17.3773 17.1894 17.3842 14.8194C17.3823 14.1583 17.2835 13.4637 17 12.9909Z" fill="currentColor" />
));

Strikethrough.displayName = 'Strikethrough';

export default Strikethrough;
