// @flow

import React from 'react';

import Svg from 'components/svg';

const Bulb = Svg((
  <>
    <path d="M24.0001 18.2857C24.0054 16.8592 23.6292 15.4573 22.9105 14.2251C22.1919 12.9929 21.1568 11.9753 19.9125 11.2777C18.6683 10.5801 17.2602 10.2278 15.834 10.2575C14.4078 10.2871 13.0155 10.6975 11.8013 11.4462C10.5871 12.1948 9.5952 13.2546 8.92829 14.5155C8.26138 15.7765 7.94376 17.1928 8.00832 18.6178C8.07289 20.0428 8.5173 21.4247 9.29551 22.6202C10.0737 23.8156 11.1574 24.7813 12.4344 25.4171V29.3485C12.4403 29.581 12.5369 29.8019 12.7034 29.9642C12.8699 30.1264 13.0933 30.2172 13.3258 30.2171H18.6744C18.9069 30.2172 19.1302 30.1264 19.2967 29.9642C19.4633 29.8019 19.5598 29.581 19.5658 29.3485V25.3485C20.8829 24.6929 21.9937 23.6867 22.776 22.4406C23.5583 21.1945 23.9819 19.7569 24.0001 18.2857Z" stroke="currentColor" strokeWidth="2.28571" strokeLinecap="round" />
    <path d="M16 1.85138V5.27995" stroke="currentColor" strokeWidth="2.28571" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M25.143 5.11993L22.7201 7.56565" stroke="currentColor" strokeWidth="2.28571" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M30.3544 12.2056H26.9258" stroke="currentColor" strokeWidth="2.28571" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M6.85718 5.11993L9.28003 7.56565" stroke="currentColor" strokeWidth="2.28571" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M1.64575 12.2056H5.07432" stroke="currentColor" strokeWidth="2.28571" strokeLinecap="square" strokeLinejoin="round" />
    {' '}
  </>
), {
  viewBox: '0 0 32 32',
  fill: 'none',
});

Bulb.displayName = 'Bulb';

export default Bulb;
