// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';
import { ErrorCode } from 'react-dropzone';

export const MAX_VIDEO_SIZE = 128 * 1000 * 1000;
export const MAX_ATTACHMENT_SIZE = 50 * 1024 * 1024;
export const MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT = 50 * 1024 * 1024;
export const MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT_IN_MB = 50;
export const MAX_ATTACHMENTS_COUNT_PER_CONTRACT_DEFAULT = 30;

export const DROPZONE_INVALID_FILE_ERROR = ErrorCode.FileInvalidType;
export const DROPZONE_TOO_LARGE_FILE_ERROR = ErrorCode.FileTooLarge;

// CUSTOM ERRORS:
export const DROPZONE_EXCEED_FILE_COUNT_ERROR = 'exceed-number-of-files-limit';
export const DROPZONE_EXCEED_FILE_SIZE_ERROR = 'exceed-total-file-size-limit';
export const DOCUMENT_HAS_REACHED_ATTACHMENTS_COUNT_LIMIT_ERROR = 'document-has-reached-attachments-limit';

export const UNKNOWN_ERROR = 'unknown-error';
export const API_ERROR = 'api-error';

export const A4_WIDTH = 793;
export const A4_HEIGHT = A4_WIDTH * 1.4142;

export const fileSizeErrorMessage = (
  <Message
    id="The selected file size is too big. Please select another file."
    comment="This message shows video size is bigger than acceptable size"
  />
);

export const fileTypeErrorMessage = (
  <Message
    id="The selected file type is not supported. Please select another file."
    comment="This message shows video type is not valid"
  />
);

export const fileUploadingRetryErrorMessage = (
  <Message
    id="The file couldn't be uploaded. Please try again."
    comment="This message shows there is an error while uploading the video"
  />
);

export const uploadingFileMessage = (
  <Message
    id="Uploading... Please wait"
    comment="This message shows file is uploading"
  />
);

export const uploadingFileSuccessMessage = (
  <Message
    id="Success!"
    comment="This message shows file is uploaded successfully"
  />
);

export const getMaxNumberOfFilesErrorMessage = (attachmentsCountLimit: number) => (
  <Message
    id="Please select a maximum of {attachmentsCountLimit} files to upload."
    values={{
      attachmentsCountLimit,
    }}
    comment="This message shows when number of files exceeds the limit"
  />
);

export const maxSizePerContractErrorMessage = (
  <Message
    id="The total size of attached files should not exceed {maxAttachmentSize} MB."
    values={{
      maxAttachmentSize: MAX_TOTAL_ATTACHMENT_SIZE_PER_CONTRACT_IN_MB,
    }}
    comment="This message shows when number of files exceeds the size limit"
  />
);

export const getMaxNumberOfFilesAlreadyInDocumentErrorMessage = (attachmentsCountLimit: number) => (
  <Message
    id="Too many files attached. Only {attachmentsCountLimit} can be attached. Please remove some files."
    values={{
      attachmentsCountLimit,
    }}
    comment="This message shows when the number of files has reached the limit and the user persists in uploading more"
  />
);

export const someFilesCouldNotBeUploadedErrorMessage = (
  <Message
    id="Some files couldn't be uploaded. Please try again."
    comment="This message shows when some files couldn't be uploaded"
  />
);

// Agreement data keys
export const OVERLAY_FIELD = 'overlay_field';
export const SIGNATURE_FIELD = 'signature_field';
export const DATA_FIELD = 'data_field';
