import { createReducer, formatActionType } from 'normalized-redux/reducers';
import { FormattedActionType } from 'normalized-redux/reducers/format-action-type';
import type { Entity, Pipe } from 'normalized-redux/entity-normalizer/types';
import { Reducer } from 'redux';

type Action<T extends string = string> = {
  type: T,
};

type LocalState<E extends Entity> = {
  pristine: boolean,
  data: Omit<Partial<E>, 'id'>,
  loading: boolean,
  success: boolean,
  error?: string,
  result?: number,
};

export const initialState: LocalState<Entity> = {
  data: {},
  pristine: true,
  loading: false,
  success: false,
  error: undefined,
  result: undefined,
};

type CreateStartAction<E extends Entity, T extends string> = Action<T> & { data: Omit<E, 'id'>, pipe?: Pipe };
// TODO: figure out a way to make result required
type CreateSuccessAction<T extends string> = Action<T> & { result: number };
// TODO: figure out a way to make error required
type CreateFailAction<T extends string> = Action<T> & { error?: any };
type CreateResetAction<T extends string> = Action<T>;

type CreateCreateStartActionArgs<E extends Entity> = { data: Omit<E, 'id'>, pipe?: Pipe }

// actions
export const createCreateStartAction = <
  E extends Entity,
  T extends string
>(type: T) => (
    { data, pipe } : CreateCreateStartActionArgs<E> = { data: {} as Omit<E, 'id'> },
  ): CreateStartAction<E, T> => ({
    type,
    data,
    pipe,
  });

type CreateCreateSuccessActionArgs = { result: number };
export const createCreateSuccessAction = <
  T extends string
>(type: T) => ({ result }: CreateCreateSuccessActionArgs): CreateSuccessAction<T> => ({
    type,
    result,
  });

type CreateCreateFailActionArgs = { error: any };

export const createCreateFailAction = <
  T extends string
>(type: T) => ({ error }: CreateCreateFailActionArgs): CreateFailAction<T> => ({
    type,
    error,
  });

export const createCreateResetAction = <
  T extends string
>(type: T) => (): CreateResetAction<T> => ({ type });

// handlers
export const createStartHandler = <
  E extends Entity,
  T extends string,
>(state: LocalState<E>, action: CreateStartAction<E, T>): LocalState<E> => ({
    ...state,
    data: action.data,
    pristine: false,
    loading: true,
    success: false,
    result: undefined,
    error: undefined,
  });

export const createSuccessHandler = <
  E extends Entity,
  T extends string
  >(state: LocalState<E>, action: CreateSuccessAction<T>) => ({
    ...state,
    pristine: false,
    loading: false,
    success: true,
    result: action.result,
    error: undefined,
  });

export const createFailHandler = <
  E extends Entity,
  T extends string
  >(state: LocalState<E>, action: CreateFailAction<T>) => ({
    ...state,
    pristine: false,
    loading: false,
    success: false,
    result: undefined,
    error: action.error,
  });

export const createResetHandler = () => ({ ...initialState });

// selectors
export const getCreateSelector = <E extends Entity>(state: LocalState<E>) => state;

type WithKey<Key extends string> = {
  key: Key;
  actionPrefix?: never;
};

type WithActionPrefix<Key extends string> = {
  key?: never;
  actionPrefix: Key;
};

type FormattedCreateStartActionType<T extends string> = FormattedActionType<T, 'CREATE_START'>;
type FormattedCreateSuccessActionType<T extends string> = FormattedActionType<T, 'CREATE_SUCCESS'>;
type FormattedCreateFailActionType<T extends string> = FormattedActionType<T, 'CREATE_FAIL'>;
type FormattedCreateResetActionType<T extends string> = FormattedActionType<T, 'CREATE_RESET'>;

export type CreateReducer<E extends Entity, Key extends string> = {
  reducer: Reducer<LocalState<E>, any>
  actions: {
    createStart: (
      args: CreateCreateStartActionArgs<E>
    ) => CreateStartAction<E, FormattedCreateStartActionType<Key>>;
    createSuccess: (
      args: CreateCreateSuccessActionArgs
    ) => CreateSuccessAction<FormattedCreateSuccessActionType<Key>>;
    createFail: (
      args: CreateCreateFailActionArgs
    ) => CreateFailAction<FormattedCreateFailActionType<Key>>;
    createReset: () => CreateResetAction<FormattedCreateResetActionType<Key>>;
    types: {
      createStart: FormattedCreateStartActionType<Key>;
      createSuccess: FormattedCreateSuccessActionType<Key>;
      createFail: FormattedCreateFailActionType<Key>;
      createReset: FormattedCreateResetActionType<Key>;
    };
  };
  selectors: {
    getCreateSelector: (state: LocalState<E>) => LocalState<E>;
  };
};

// initializer
const createCreateReducer = <
  E extends Entity,
  Key extends string
>({ key, actionPrefix }: WithKey<Key> | WithActionPrefix<Key>): CreateReducer<E, Key> => {
  const resolvedKey = (actionPrefix || key) as Key;

  const CREATE_START = formatActionType({ key: resolvedKey, type: 'CREATE_START' });
  const CREATE_SUCCESS = formatActionType({ key: resolvedKey, type: 'CREATE_SUCCESS' });
  const CREATE_FAIL = formatActionType({ key: resolvedKey, type: 'CREATE_FAIL' });
  const CREATE_RESET = formatActionType({ key: resolvedKey, type: 'CREATE_RESET' });

  return ({
    reducer: createReducer(initialState as LocalState<E>, {
      [CREATE_START]: createStartHandler,
      [CREATE_SUCCESS]: createSuccessHandler,
      [CREATE_FAIL]: createFailHandler,
      [CREATE_RESET]: createResetHandler,
    }),
    actions: {
      createStart: createCreateStartAction(CREATE_START),
      createSuccess: createCreateSuccessAction(CREATE_SUCCESS),
      createFail: createCreateFailAction(CREATE_FAIL),
      createReset: createCreateResetAction(CREATE_RESET),
      types: {
        createStart: CREATE_START,
        createSuccess: CREATE_SUCCESS,
        createFail: CREATE_FAIL,
        createReset: CREATE_RESET,
      },
    },
    selectors: {
      getCreateSelector,
    },
  });
};

export default createCreateReducer;
