import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

const uncheckedValues = ['0', '', 'false'];

export const isUnchecked = (value: string): boolean => {
  if (isNull(value) || isUndefined(value)) {
    return true;
  }

  return uncheckedValues.includes(String(value));
};

export const isChecked = (value: string) => !isUnchecked(value);
