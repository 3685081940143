// @flow

import React from 'react';

import Svg from 'components/svg';

const NewAdd = Svg((
  <path d="M5 0.5V9.5M0.5 5H9.5" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
), { viewBox: '0 0 10 10' });

NewAdd.displayName = 'NewAdd';

export default NewAdd;
