// @flow

import React from 'react';

import Svg from 'components/svg';

const SolidBell = Svg((
  <path d="M5.50033 9.16699C5.95866 9.16699 6.33366 8.79199 6.33366 8.33366H4.66699C4.66699 8.79199 5.03783 9.16699 5.50033 9.16699ZM8.00033 6.66699V4.58366C8.00033 3.30449 7.31699 2.23366 6.12533 1.95033V1.66699C6.12533 1.32116 5.84616 1.04199 5.50033 1.04199C5.15449 1.04199 4.87533 1.32116 4.87533 1.66699V1.95033C3.67949 2.23366 3.00033 3.30033 3.00033 4.58366V6.66699L2.16699 7.50033V7.91699H8.83366V7.50033L8.00033 6.66699Z" fill="currentColor" />
), {
  viewBox: '0 0 11 10',
  fill: 'none',
});

SolidBell.displayName = 'SolidBell';

export default SolidBell;
