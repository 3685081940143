// @flow

import React from 'react';

import Svg from 'components/svg';

const ToggleCheckIcon = Svg((
  <path
    d="M8.75001 0.404251C8.41212 0.142532 7.93946 0.22261 7.69337 0.581985L4.1797 5.72261L2.24415 3.62691C1.959 3.30269 1.48048 3.28706 1.17579 3.59175C0.871105 3.89449 0.85548 4.40425 1.14259 4.72652C1.14259 4.72652 3.49415 7.33589 3.83204 7.59761C4.16993 7.85933 4.64259 7.77925 4.88868 7.41988L8.91798 1.5273C9.16407 1.16597 9.0879 0.664017 8.75001 0.404251Z"
    fill="currentColor"
  />
), { viewBox: '0 0 10 8', fill: 'none' });

ToggleCheckIcon.displayName = 'ToggleCheckIcon';

export default ToggleCheckIcon;
