// @flow

import React from 'react';

import Svg from 'components/svg';

const Copy = Svg((
  <>
    <path
      d="M1.99998 0.350098C1.64099 0.350098 1.34998 0.641113 1.34998 1.0001V11.6501H2.64998V1.6501H9.64998V0.350098H1.99998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99998 3.0001H12V14.0001H3.99998V3.0001ZM5.29998 4.3001H10.7V12.7001H5.29998V4.3001Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

Copy.displayName = 'Copy';

export default Copy;
