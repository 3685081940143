// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19952');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.29988 -0.150024V8.43072L10.5387 6.19185L11.458 7.11108L8.1095 10.4596C7.9876 10.5815 7.82227 10.65 7.64988 10.65C7.47749 10.65 7.31215 10.5815 7.19025 10.4596L3.84181 7.11108L4.76105 6.19185L6.99988 8.43072V-0.150024H8.29988ZM1.8 10.35V11C1.8 11.0945 1.8 11.2073 1.80001 11.333C1.80002 11.7601 1.80003 12.3362 1.79995 12.85H13.4998V10.35H14.7998V13.5C14.7998 13.859 14.5087 14.15 14.1498 14.15H1.14976C0.97731 14.15 0.811932 14.0814 0.690024 13.9595C0.568117 13.8375 0.499672 13.6721 0.499756 13.4997C0.500049 12.8987 0.500024 11.9573 0.500008 11.3331C0.500004 11.2074 0.500001 11.0946 0.500001 11V10.35H1.8Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" transform="translate(0.649902)" />
        </clipPath>
      </defs>
    </>
  );
};

const Download = Svg(Fragment, { viewBox: '0 0 15 14', fill: 'none' });

Download.displayName = 'Download';

export default Download;
