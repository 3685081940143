import type { ReactNode } from 'react';

import ErrorBox from './error-box';

type Props = {
  headerText?: ReactNode,
  bodyText?: ReactNode,
};

const InfoBox = ({ headerText, bodyText }: Props) => (
  <ErrorBox
    headerText={headerText}
    bodyText={bodyText}
    hasInfoStyle
  />
);

InfoBox.defaultProps = {
  headerText: undefined,
  bodyText: undefined,
};

export default InfoBox;
