// @flow

import React from 'react';

import Svg from 'components/svg';

const Cancel = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M1.50005 0.580811L7.00005 6.08081L12.5 0.580811L13.4193 1.50005L7.91929 7.00005L13.4193 12.5L12.5 13.4193L7.00005 7.91929L1.50005 13.4193L0.580811 12.5L6.08081 7.00005L0.580811 1.50005L1.50005 0.580811Z"
  />
), { viewBox: '0 0 14 14', fill: 'none' });

Cancel.displayName = 'Cancel';

export default Cancel;
