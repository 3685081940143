// @flow

import React from 'react';

import Svg from 'components/svg';

const WrapLeft = Svg((
  <>
    <path d="M4 5H20V7H4V5Z" fill="currentColor" />
    <path d="M4 17H20V19H4V17Z" fill="currentColor" />
    <rect x="14" y="9" width="6" height="6" fill="currentColor" />
    <path d="M4 9H11V11H4V9Z" fill="currentColor" />
    <path d="M4 13H11V15H4V13Z" fill="currentColor" />
  </>
));

WrapLeft.displayName = 'WrapLeft';

export default WrapLeft;
