// @flow

import React from 'react';

import Svg from 'components/svg';

const Image = Svg(
  <path d="M4 19C4 14.3333 4.01305 9.66666 4.00102 5C9.334 5.01489 14.667 5.00001 20 5C20 9.66662 19.9869 14.3334 19.999 19C14.666 18.9851 9.33299 18.9981 4 19ZM18 13.8954L18.001 7H6.00102L6 17L13.5994 9.22868L18 13.8954ZM18 16.0949L13.5994 11.4282L7.86215 17H18V16.0949ZM8.79969 10.7842C8.37537 10.7842 7.96843 10.6203 7.66839 10.3286C7.36836 10.0369 7.1998 9.64124 7.1998 9.22868C7.1998 8.81612 7.36836 8.42046 7.66839 8.12874C7.96843 7.83702 8.37537 7.67313 8.79969 7.67313C9.22401 7.67313 9.63095 7.83702 9.93099 8.12874C10.231 8.42046 10.3996 8.81612 10.3996 9.22868C10.3996 9.64124 10.231 10.0369 9.93099 10.3286C9.63095 10.6203 9.22401 10.7842 8.79969 10.7842Z" fill="currentColor" />,
);

Image.displayName = 'Image';

export default Image;
