// @flow

import React from 'react';

import Svg from 'components/svg';

const TextBackgroundColor = Svg(({ pathColor }) => (
  <>
    <path d="M4 17H20V21H4V17Z" fill={pathColor || 'currentColor'} />
    <path
      d="M6 14.2497L8.20412 15.0001L8.9861 14.1693L7.50728 12.598L6 14.2497ZM8.74875 8.62441C8.63262 8.7333 8.54844 8.8754 8.50623 9.03373C8.46403 9.19207 8.46559 9.35996 8.51073 9.51738L8.7986 10.5212L7.67515 11.7151L9.79787 13.9705L10.9198 12.7785L11.8626 13.085C12.1654 13.1835 12.4946 13.0848 12.7041 12.8324L13.4883 11.8564L9.66463 7.79378L8.74875 8.62441ZM17.6453 4.858L16.2512 3.37675C15.7992 2.89651 15.0737 2.87237 14.5941 3.32167L10.2033 7.30511L13.9482 11.2843L17.6971 6.61886C18.1202 6.10933 18.0975 5.33847 17.6453 4.858Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

TextBackgroundColor.displayName = 'TextBackgroundColor';

export default TextBackgroundColor;
