// @flow

import React from 'react';

import Svg from 'components/svg';

const DesktopMonitorRemove = Svg((
  <g>
    <path d="M6,24c-0.414,0-0.75-0.336-0.75-0.75S5.586,22.5,6,22.5h1.664L8.039,21H3.75C1.682,21,0,19.318,0,17.25V6.75C0,4.682,1.682,3,3.75,3h4.5C8.664,3,9,3.336,9,3.75S8.664,4.5,8.25,4.5h-4.5C2.509,4.5,1.5,5.509,1.5,6.75v9.75h21v-2.25c0-0.414,0.336-0.75,0.75-0.75S24,13.836,24,14.25v3c0,2.068-1.682,3.75-3.75,3.75h-4.29l0.375,1.5H18c0.414,0,0.75,0.336,0.75,0.75S18.414,24,18,24H6z M14.79,22.5L14.415,21H9.586l-0.375,1.5H14.79z M1.632,18c0.313,0.879,1.158,1.5,2.118,1.5h16.5c0.96,0,1.805-0.621,2.118-1.5H1.632z" />
    <path d="M17.25,13.5c-3.722,0-6.75-3.028-6.75-6.75S13.528,0,17.25,0S24,3.028,24,6.75S20.972,13.5,17.25,13.5z M17.25,1.5 C14.355,1.5,12,3.855,12,6.75S14.355,12,17.25,12s5.25-2.355,5.25-5.25S20.145,1.5,17.25,1.5z" />
    <path d="M19.371,9.621c-0.2,0-0.389-0.078-0.53-0.22L17.25,7.811l-1.591,1.591c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22c-0.142-0.142-0.22-0.33-0.22-0.53c0-0.2,0.078-0.389,0.22-0.53l1.591-1.591l-1.591-1.591c-0.142-0.141-0.22-0.33-0.22-0.53c0-0.2,0.078-0.389,0.22-0.53c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22l1.591,1.591l1.591-1.591c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22c0.142,0.142,0.22,0.33,0.22,0.53c0,0.2-0.078,0.389-0.22,0.53L18.311,6.75l1.591,1.591c0.142,0.141,0.22,0.33,0.22,0.53c0,0.2-0.078,0.389-0.22,0.53C19.76,9.543,19.571,9.621,19.371,9.621z" />
  </g>
));

DesktopMonitorRemove.displayName = 'DesktopMonitorRemove';

export default DesktopMonitorRemove;
