import { ComponentProps } from 'react';
import * as ReactPopover from '@radix-ui/react-popover';
import clsx from 'clsx';

// eslint-disable-next-line import/named
import { Popover, PopoverTrigger } from 'components/popover';
import InfoIcon from 'components/icons/info';

import Tooltip, { themes } from '../tooltip';

import style from './touchable-tooltip.module.scss';
import tooltipStyle from '../tooltip.module.scss';
import TooltipContent from '../content';

type TouchableTooltipProps =Omit<ComponentProps<typeof Tooltip>, 'touchable'>

type ButtonProps = ComponentProps<typeof PopoverTrigger>;

function TouchableTooltip({
  message,
  header,
  messageClassName,
  contentClassName,
  hideContent = false,
  align = 'center',
  alignOffset = 0,
  theme = 'oneflow',
  zIndex = 10004,
  children,
  side = 'top',
  sideOffset,
  collisionBoundary,
  collisionPadding,
  onPointerDownOutside,
  sticky,
  animate = true,
}: TouchableTooltipProps) {
  return (
    <Popover>
      {children}
      <TooltipContent
        as={ReactPopover.Content}
        side={side}
        sideOffset={sideOffset}
        className={clsx(
          style.ContentContainerWrapper,
          themes[theme]?.ContainerInnerWrapper,
          contentClassName,
          { [tooltipStyle.Animate]: animate },
        )}
        zIndex={zIndex}
        align={align}
        alignOffset={alignOffset}
        collisionBoundary={collisionBoundary}
        collisionPadding={collisionPadding}
        onPointerDownOutside={onPointerDownOutside}
        sticky={sticky}
        hideContent={hideContent}
      >
        <div
          data-testid="touchable-tooltip-content-message"
          className={clsx(
            style.ContentContainer,
            messageClassName,
            themes[theme]?.Container,
          )}
        >
          {header}
          {message}
        </div>
      </TooltipContent>
    </Popover>
  );
}

export function TouchableTooltipTrigger({ children, className, ...rest }: ButtonProps) {
  return (
    <PopoverTrigger className={clsx(style.TouchableTrigger, className)} {...rest}>
      {children}
    </PopoverTrigger>
  );
}

TouchableTooltip.Trigger = TouchableTooltipTrigger;

export const TouchableTooltipInfo = (props: Omit<TouchableTooltipProps, 'children'>) => {
  const { disabled, triggerClassName, ...rest } = props;

  return (
    <TouchableTooltip
      {...rest}
    >
      <TouchableTooltip.Trigger
        className={clsx(
          style.TooltipInfo,
          style.Icon,
          triggerClassName,
          { [style.Disabled]: disabled },
        )}
      >
        <InfoIcon width="12px" />
      </TouchableTooltip.Trigger>
    </TouchableTooltip>
  );
};

export default TouchableTooltip;
