// @flow

import React from 'react';

import Svg from 'components/svg';

const ExternalLink = Svg((
  <path
    d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.73333L3.2 7.86667L4.13333 8.8L10.6667 2.26667V4.66667H12V0H7.33333Z"
    fill="currentColor"
  />
), { viewBox: '0 0 12 12' });

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
