// @flow

import React from 'react';

import Svg from 'components/svg';

const AddComment = Svg((
  <>
    <path d="M0.884363 11.6486C0.304109 10.5199 0.000961924 9.26913 0 7.99994C0 3.58906 3.58909 0 8 0C12.4109 0 16 3.58906 16 7.99994C16 12.4108 12.4109 15.9999 8 15.9999C6.73309 15.9999 5.47782 15.6952 4.35055 15.1162L0.903273 15.9781C0.78152 16.0087 0.653884 16.0072 0.532888 15.9737C0.411892 15.9402 0.30169 15.8758 0.21309 15.7868C0.124246 15.698 0.0599309 15.5876 0.0264102 15.4665C-0.00711055 15.3455 -0.00869226 15.2178 0.0218187 15.0959L0.884363 11.6486ZM2.36291 11.7286L1.72727 14.2726L4.27127 13.6363C4.45112 13.5919 4.64111 13.6173 4.80291 13.7075C5.77927 14.256 6.88014 14.5445 8 14.5453C11.6095 14.5453 14.5455 11.6086 14.5455 7.99994C14.5455 4.39124 11.6095 1.45453 8 1.45453C4.39055 1.45453 1.45455 4.39124 1.45455 7.99994C1.45554 9.11945 1.74376 10.22 2.29164 11.1963C2.38281 11.358 2.40833 11.5486 2.36291 11.7286Z" fill="#013A4C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.59891 4.57031H7.3132V7.14062H4.57129L4.57129 8.42634H7.3132V11.4275H8.59891V8.42634H11.4284V7.14062H8.59891V4.57031Z" fill="#013A4C" />
  </>
), { viewBox: '0 0 16 16', fill: 'none' });

AddComment.displayName = 'AddComment';

export default AddComment;
