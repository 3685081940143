// @flow

import {
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleWare from 'redux-saga';

import history from 'store/history';

import { createRootSaga } from 'public/sagas';
import reducers from 'public/reducers';

import { isEnvironment } from 'utils/environment';

const setupReduxDevTools = () => {
  if (isEnvironment('production')) {
    return (f) => f;
  }

  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (!__REDUX_DEVTOOLS_EXTENSION__) {
    return (f) => f;
  }

  return __REDUX_DEVTOOLS_EXTENSION__();
};

const sagaMiddleware = createSagaMiddleWare();

const store = createStore<State, any, any>(
  reducers,
  compose(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
    ),
    setupReduxDevTools(),
  ),
);

const sagas = createRootSaga();

sagaMiddleware.run(sagas);

export default store;
