// @flow

import * as React from 'react';

import { Message } from '@oneflowab/pomes';

const NoOptionsMessage = () => (
  <div className="ReactSelect__noOptionsMessage">
    <Message id="No results found" comment="Used in the select drop down" />
  </div>
);

export default NoOptionsMessage;
