// @flow

import React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import Info from 'components/icons/info';
import SelectField from 'components/select-field';
import { getSignMethod } from 'components/sign-methods/sign-methods';
import { useReactSelectDropdownMenuPlacement } from 'hooks/use-react-select-dropdown-menu-placement';

type Props = {
  message: MessageTranslator,
  availableSignMethods: any,
  onChange?: Option => void,
  initialValue?: number,
  disabled?: boolean,
  signMethodsDescription?: React.Node,
};

// Also use in add-colleague-body.jsx and default settings
export const SignMethod = ({
  message,
  availableSignMethods,
  onChange,
  initialValue,
  disabled,
  signMethodsDescription,
}: Props) => {
  const menuPlacement = useReactSelectDropdownMenuPlacement('signMethod');

  const getSignMethods = () => (
    availableSignMethods?.map<any>(
      (signMethod) => ({
        value: signMethod && signMethod,
        label: getSignMethod(signMethod, message).label,
      }),
    )
  );

  const defaultsignMethodsDescription = (
    <>
      <p>
        <Message
          id="The sign method decides how the identity of your counterparty is verified."
          comment="Tooltip for sign method dropdown"
        />
      </p>
      <p>
        <Message
          id="More sign methods can be added by enabling extensions."
          comment="Tooltip for sign method dropdown"
        />
      </p>
    </>
  );

  return (
    <Field
      name="signMethod"
      label={message({
        id: 'Sign method',
        comment: 'Label for the sign method field.',
      })}
      placeholder={(
        <Message
          id="Select sign method"
          comment="Placeholder for the sign method field."
        />
      )}
      component={SelectField}
      options={getSignMethods()}
      clearable={false}
      searchable={false}
      required
      fieldinfo={{
        message: signMethodsDescription || defaultsignMethodsDescription,
        icon: <Info width="12px" />,
        side: 'top',
        zIndex: '10004',
        nextToLabel: true,
      }}
      onChange={onChange}
      initialValue={initialValue}
      disabled={disabled}
      menuPlacement={menuPlacement}
    />
  );
};

export default localize<Props>(SignMethod);
