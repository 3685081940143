// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_1_2');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M5.46875 0.875H2.40625C2.00014 0.875 1.61066 1.03633 1.32349 1.32349C1.03633 1.61066 0.875 2.00014 0.875 2.40625V11.5938C0.875 11.9999 1.03633 12.3893 1.32349 12.6765C1.61066 12.9637 2.00014 13.125 2.40625 13.125H11.5938C11.9999 13.125 12.3893 12.9637 12.6765 12.6765C12.9637 12.3893 13.125 11.9999 13.125 11.5938V8.53125M7 7L13.125 0.875M13.125 0.875V4.70312M13.125 0.875H9.29688"
          stroke="#013A4C"
          strokeWidth="1.53125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const OpenFile = Svg(Fragment, { viewBox: '0 0 14 14', fill: 'none' });

OpenFile.displayName = 'Open File';

export default OpenFile;
