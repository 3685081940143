/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';
import localStorage from 'utils/local-storage';
import sessionStorage from 'utils/session-storage';
import localClientFeatureFlagsList from 'reducers/utils/client-feature-flags';
import { setClientFeatureFlags } from 'reducers/app';

const isFeatureFlagEnabled = (featureFlag) => {
  const featureFlagKey = `local-ff:${featureFlag}`;

  const featureFlagSessionValue = sessionStorage.getItem(featureFlagKey);
  if (featureFlagSessionValue) {
    return featureFlagSessionValue === 'true';
  }

  const featureFlagLocalValue = localStorage.getItem(featureFlagKey);
  if (featureFlagLocalValue) {
    return featureFlagLocalValue === 'true';
  }

  return false;
};

export function* loadClientFeatureFlags() {
  const clientFeatureFlagsObject = {};

  localClientFeatureFlagsList.forEach((featureFlag) => {
    clientFeatureFlagsObject[featureFlag] = isFeatureFlagEnabled(featureFlag);
  });

  yield put(setClientFeatureFlags(clientFeatureFlagsObject));
}
