import { createBrowserHistory, createMemoryHistory } from 'history';

export const getHistory = () => {
  if (process.env.NODE_ENV === 'test') {
    return createMemoryHistory();
  }

  return createBrowserHistory();
};

export default getHistory();
