// @flow

import React from 'react';

import Svg from 'components/svg';

const DeleteTableColumn = Svg((
  <>
    <rect x="18.0068" y="19" width="4" height="14" transform="rotate(180 18.0068 19)" stroke="currentColor" strokeWidth="2" />
    <path d="M4.00003 11H10L10.0068 13H4.00684L4.00003 11Z" fill="currentColor" />
  </>
), {
  viewBox: '0 0 24 24',
  fill: 'none',
});

DeleteTableColumn.displayName = 'DeleteTableColumn';

export default DeleteTableColumn;
