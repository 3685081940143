// @flow

import React from 'react';

import Svg from 'components/svg';

const Reminder = Svg((
  <>
    <path d="M6.29922 1.7002C7.414 1.70001 8.49408 2.08785 9.35409 2.79715C10.2141 3.50644 10.8004 4.49297 11.0124 5.5874C11.2244 6.68183 11.0489 7.81593 10.5159 8.79505C9.98292 9.77417 9.12577 10.5372 8.09155 10.9533C7.05734 11.3694 5.91054 11.4125 4.84799 11.0753C3.78545 10.7381 2.8734 10.0415 2.2684 9.10522C1.6634 8.1689 1.40316 7.05119 1.53235 5.94392C1.66154 4.83666 2.17211 3.80888 2.97642 3.037" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" fill="none" />
    <path d="M6.29883 4.7002V6.5002L7.49883 7.7002" fill="currentColor" />
    <path d="M6.29883 4.7002V6.5002L7.49883 7.7002" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M0.599609 2.2998H3.59961V5.2998" stroke="currentColor" />
  </>
), { viewBox: '0 0 12 12' });

Reminder.displayName = 'Reminder';

export default Reminder;
