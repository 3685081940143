import type { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

import { isDeviceWithoutHover } from 'utils/browser';

import InfoIcon from 'components/icons/info';
import Tooltip from 'components/tooltip';
import { TouchableTooltipInfo } from 'components/tooltip/touchable-tooltip';

import style from './tooltip-info.module.scss';

type Props = {
  align?: 'start' | 'center' | 'end',
  alignOffset?: number,
  customClassName?: string,
  disabled?: boolean,
  header?: ReactNode,
  message: ReactNode,
  messageClassName?: string,
  side?: 'top' | 'right' | 'bottom' | 'left',
  sideOffset?: number,
  theme?: 'oneflow' | 'light',
  zIndex?: CSSProperties['zIndex'] | string,
};

const getInfoIcon = (disabled: boolean | undefined) => (
  <div className={clsx(style.Icon, { [style.DisabledIcon]: disabled })}>
    <InfoIcon width="12px" />
  </div>
);

const TooltipInfo = ({
  align = 'center',
  alignOffset = 0,
  customClassName,
  disabled,
  header,
  message,
  messageClassName,
  side = 'top',
  sideOffset = 6,
  theme,
  zIndex = 1,
}: Props) => {
  if (isDeviceWithoutHover()) {
    return (
      <TouchableTooltipInfo
        data-testid="touchable-tooltip-info"
        align={align}
        alignOffset={alignOffset}
        triggerClassName={customClassName}
        disabled={disabled}
        header={header}
        message={message}
        messageClassName={messageClassName}
        side={side}
        theme={theme}
        zIndex={zIndex}
        sideOffset={sideOffset}
      />
    );
  }

  return (
    <Tooltip
      triggerClassName={clsx(style.TooltipInfo, customClassName)}
      header={header}
      message={message}
      html={(
        <div className={clsx(style.Message, messageClassName)}>
          {header}
          {message}
        </div>
      )}
      messageClassName={clsx(style.Message, messageClassName)}
      side={side}
      sideOffset={sideOffset}
      theme={theme}
      zIndex={zIndex}
    >
      {getInfoIcon(disabled)}
    </Tooltip>
  );
};

export default TooltipInfo;
