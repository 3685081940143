// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
};

export const TemplateGroupDescription = ({ message }: Props) => (
  <Field
    name="description"
    label={message({
      id: 'Template group description',
      comment: 'Label for the description field for a template group.',
    })}
    placeholder={message({
      id: 'Enter description',
      comment: 'Description placeholder for a template group.',
    })}
    component={TextField}
    responsive
  />
);

export default localize<Props>(TemplateGroupDescription);
