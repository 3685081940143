// @flow

import React from 'react';
import type { FieldRenderProps } from 'react-final-form';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import { urlValidator } from 'forms/validators';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  verifyWebhookUrl: string => void,
  autoFocus?: boolean,
};

export class WebhookUrl extends React.PureComponent<Props> {
  validations = (({ message }) => ({
    url: urlValidator({
      message,
      field: message({
        id: 'URL',
        comment: 'Used in URL validation of webhooks.',
      }),
      httpsOnly: true,
    }),
  }))(this.props);

  static defaultProps = {
    autoFocus: undefined,
  }

  handleAction = (fieldProps: FieldRenderProps) => () => {
    const { verifyWebhookUrl } = this.props;

    return verifyWebhookUrl(fieldProps.input.value);
  };

  renderField = (fieldProps: FieldRenderProps) => (
    <TextField
      {...fieldProps}
      onAction={this.handleAction(fieldProps)}
    />
  );

  render() {
    const { message, autoFocus } = this.props;

    return (
      <Field
        name="url"
        label={message({
          id: 'URL',
          comment: 'URL label for webhook.',
        })}
        placeholder="https://www.example.com"
        autoFocus={autoFocus}
        validate={this.validations.url}
        maxLength={1000}
        required
        responsive
        actionButtonText={message({
          id: 'Test',
          comment: 'Button text for testing webhook.',
        })}
        render={this.renderField}
      />
    );
  }
}

export default localize<Props>(WebhookUrl);
