// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import { PasswordField } from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
};

export const PasswordNew = ({ message, autoFocus }: Props) => (
  <Field
    name="password"
    label={message({
      id: 'Password',
      comment: 'Password field label.',
    })}
    placeholder={message({
      id: 'New password',
      comment: 'Password field placeholder.',
    })}
    component={PasswordField}
    autoFocus={autoFocus}
    minLength={12}
    maxLength={72}
    required
  />
);

PasswordNew.defaultProps = {
  autoFocus: undefined,
};

export default localize<Props>(PasswordNew);
