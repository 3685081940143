// @flow

import React from 'react';

import Svg from 'components/svg';

const NewEmail = Svg((
  <>
    <path
      d="M1 0.800049H10V7.20005H1V0.800049Z"
      stroke="currentColor"
      strokeWidth="1.12"
      strokeLinecap="round"
    />
    <path
      d="M1 1.59888L4.855 4.20128C5.03542 4.3231 5.2639 4.38993 5.5 4.38993C5.7361 4.38993 5.96458 4.3231 6.145 4.20128L10 1.59888"
      stroke="currentColor"
      strokeWidth="1.12"
      strokeLinecap="round"
    />
  </>
), { viewBox: '0 0 11 8', fill: 'none' });

NewEmail.displayName = 'NewEmail';

export default NewEmail;
