// @flow

import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';

import { getParticipantById } from './selectors';

const snakeToCamel = (snake: string): string => snake.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

type GetRef = (
  event: AgreementEvent,
  ref: string
) => string | number | Array<number> | null

export const getRef: GetRef = (event, ref) => {
  const { refs } = event;

  if (!refs) {
    return null;
  }

  if (Object.hasOwn(event.refs, ref)) {
    return event.refs[ref];
  }
  const camelCaseifiedRef = snakeToCamel(ref);
  if (Object.hasOwn(event.refs, camelCaseifiedRef)) {
    return event.refs[camelCaseifiedRef];
  }
  return null;
};

type GetEventOwner = (
  agreement: Agreement,
  event: AgreementEvent
) => AgreementParticipant | null

export const getEventOwner: GetEventOwner = (agreement, event) => {
  const eventOwner = event.ownerParticipant;
  const owner = eventOwner && eventOwner.id
    ? eventOwner
    : getParticipantById(agreement, eventOwner);
  return owner || null;
};

type GetEventParticipants = (
  event: AgreementEvent
) => Array<AgreementParticipant | null>
export const getEventParticipants: GetEventParticipants = (event) => [].concat(event.participants);

export const getEventsList = (agreement: Agreement) => {
  if (!agreement || !agreement.events) {
    return [];
  }
  const eventsMap = keyBy(agreement.events, 'id');

  const rootEvents = [];

  forEach(eventsMap, (event) => {
    if (event.parent) {
      const parentEvent = eventsMap[event.parent.id];
      if (parentEvent) {
        parentEvent.childEvents = parentEvent.childEvents || [];
        parentEvent.childEvents.push(event);
      }
    } else {
      rootEvents.push(event);
    }
  });

  return rootEvents;
};

export const getEventById = (agreement, eventId) => {
  if (!agreement || !agreement.events) {
    return [];
  }

  const eventsMap = keyBy(agreement.events, 'id');
  return eventsMap[eventId];
};

export const getEventsByParentId = (agreement, parentId) => {
  if (!agreement || !agreement.events) {
    return [];
  }

  return agreement.events.filter((event) => event.parent?.id === parentId);
};

/*
 * Draft approver methods:
 * Since approver is not a real role type, those events rely on the existence of a 'roles'
 * change event
 * Changes between approver and other types will also include a regular 'type' change event
 * Changes between approver and organizer will NOT include the regular 'type' change event
 * (hence the below 'roles' change event)
 *
 * For instance:
 * events = [
 *    "type": 31,
 *    "state": 2,
 *    "refs": {
 *      "to": ["DRAFT_APPROVER"],
 *      "key": "roles",
 *      "from": []
 *     },
 * ]
 *
 * In short, when there is a 'roles' event but NOT a 'type' event, we make the assumption that
 * it means it's a change between organizer and draft approver
 *
 */

export const findAssociatedRolesChangeEvent = (agreement, eventId) => {
  if (!agreement || !agreement.events) {
    return [];
  }

  const eventsMap = keyBy(agreement.events, 'id');
  const event = eventsMap[eventId];

  const siblingEvents = getEventsByParentId(agreement, event.parent?.id);
  return siblingEvents.filter((ev) => getRef(ev, 'key') === 'roles')[0];
};

export const findAssociatedTypeChangeEvent = (agreement, eventId) => {
  if (!agreement || !agreement.events) {
    return [];
  }
  const eventsMap = keyBy(agreement.events, 'id');
  const event = eventsMap[eventId];

  const siblingEvents = getEventsByParentId(agreement, event.parent?.id);
  return siblingEvents.filter((ev) => getRef(ev, 'key') === 'type')[0];
};

export const isRoleChangeToDraftApprover = (agreement, eventId) => {
  const event = findAssociatedRolesChangeEvent(agreement, eventId);

  if (!event) {
    return false;
  }

  const ref = getRef(event, 'to');

  if (ref.length) {
    return ref[0] === 'DRAFT_APPROVER';
  }

  return false;
};

export const isRoleChangeFromDraftApprover = (agreement, eventId) => {
  const event = findAssociatedRolesChangeEvent(agreement, eventId);

  if (!event) {
    return false;
  }

  const ref = getRef(event, 'from');

  if (ref.length) {
    return ref[0] === 'DRAFT_APPROVER';
  }

  return false;
};

/** End Draft approver methods */

export const isRoleChangeToPendingStateApprover = (agreement, eventId) => {
  const event = findAssociatedRolesChangeEvent(agreement, eventId);

  if (!event) {
    return false;
  }

  const ref = getRef(event, 'to');

  if (ref.length) {
    return ref[0] === 'PENDING_STATE_APPROVER';
  }

  return false;
};

export const isRoleChangeFromPendingStateApprover = (agreement, eventId) => {
  const event = findAssociatedRolesChangeEvent(agreement, eventId);

  if (!event) {
    return false;
  }

  const ref = getRef(event, 'from');

  if (ref.length) {
    return ref[0] === 'PENDING_STATE_APPROVER';
  }

  return false;
};
