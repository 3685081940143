// @flow

import React, { useEffect, useState } from 'react';
import type { Match, Location } from 'react-router';
import get from 'lodash/get';
import getAnalytics from 'client-analytics/gtm';

import Wrapper from 'components/public-wrapper';
import PublicAppTitle from 'components/app-title';

import SignupWithToken from 'routes/public/signup/signup-with-token';
import SignupStart from 'routes/public/signup/signup-start';

type Props = {
  location?: Location,
  computedMatch?: Match,
  queryValues: { [any]: string },
};

const Signup = ({ location, computedMatch, queryValues }: Props) => {
  const [invalidTokenReason, setInvalidTokenReason] = useState<string>(undefined);

  useEffect(() => {
    getAnalytics().page();
  }, []);

  const onInvalidToken = (reason: string) => setInvalidTokenReason(reason);

  const renderPage = () => {
    const signupType = get(computedMatch, 'params.token');

    if (invalidTokenReason || !signupType) {
      return (
        <Wrapper>
          <SignupStart
            initialEmail={location ? location.initialEmail : ''}
            invalidTokenReason={invalidTokenReason}
            queryValues={queryValues}
          />
        </Wrapper>
      );
    }

    return (
      <SignupWithToken
        token={computedMatch.params.token}
        onInvalidToken={onInvalidToken}
      />
    );
  };

  return (
    <>
      <PublicAppTitle title="Signup" />
      {renderPage()}
    </>
  );
};

export default Signup;
