// @flow

import * as React from 'react';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import get from 'lodash/get';

import type { FormRenderProps } from 'react-final-form';

import PublicForm from 'hocs/public-form';
import { postForgotPassword } from 'oneflow-client/forgot-password';
import LoginLink from 'components/login-link';

import ResetPasswordStartConfirmation from './reset-password-start-confirmation';
import ResetPasswordStartFields from './reset-password-start-fields';

type Props = {
  initialEmail?: string,
  message: MessageTranslator,
};

type State = {
  requestEmail?: string,
  errorCode?: number,
};

export const isSubmitDisabled = (formProps: FormRenderProps) => {
  const {
    hasSubmitErrors,
    dirtySinceLastSubmit,
    validating,
    submitting,
    pristine,
    invalid,
    values,
    hasValidationErrors,
  } = formProps;

  if (hasSubmitErrors && dirtySinceLastSubmit && !hasValidationErrors) {
    return false;
  }

  if (values && values.email && !invalid) {
    return false;
  }

  return !!(validating || submitting || pristine || invalid);
};

export class ResetPasswordStartComponent extends React.Component<Props, State> {
  static defaultProps = {
    initialEmail: '',
  };

  state = {
    requestEmail: undefined,
    errorCode: undefined,
  }

  onSuccess = (response: Object, values: Object) => {
    this.setState({
      requestEmail: values.email,
    });
  }

  onFailure = (error: Object) => {
    const errorCode = get(error, 'body.api_error_code');
    this.setState({ errorCode });
  }

  onSubmit = (values: {}) => (
    postForgotPassword(values)
      .then((response) => this.onSuccess(response, values))
      .catch(this.onFailure)
  );

  shouldRenderConfirmation() {
    return this.state.requestEmail !== undefined;
  }

  renderFields = () => <ResetPasswordStartFields />;

  render() {
    const { initialEmail, message } = this.props;
    const { requestEmail } = this.state;

    if (this.shouldRenderConfirmation()) {
      return <ResetPasswordStartConfirmation requestEmail={requestEmail} />;
    }

    return (
      <PublicForm
        title={message({
          id: 'Reset password',
          comment: 'Reset password page header.',
        })}
        errorCode={this.state.errorCode}
        onSubmit={this.onSubmit}
        submitText={message({
          id: 'Send recovery email',
          comment: 'Submit button text for the reset password page.',
        })}
        isSubmitDisabled={isSubmitDisabled}
        initialValues={{ email: initialEmail }}
        footer={LoginLink}
      >
        {this.renderFields}
      </PublicForm>
    );
  }
}

export default localize<Props>(ResetPasswordStartComponent);
