// @flow

import React from 'react';

import Wrapper from 'components/public-wrapper';
import PublicAppTitle from 'components/app-title';

import ChangeEmail from './change-email-confirmation';

const ChangeEmailPage = (props: any) => (
  <Wrapper>
    <PublicAppTitle title="Change email" />
    <ChangeEmail {...props} />
  </Wrapper>
);

export default ChangeEmailPage;
