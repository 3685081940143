import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { MouseEvent, useCallback } from 'react';
import clsx from 'clsx';

import { isDeviceWithoutHover } from 'utils/browser';

import TouchableTooltip from './touchable-tooltip';
import TooltipTrigger from './trigger';
import TooltipContent from './content';
import { TooltipProps } from './types';

import style from './tooltip.module.scss';

export const themes = {
  oneflow: {
    ContainerInnerWrapper: style.OneflowContainerInnerWrapper,
    Container: style.OneflowContainer,
  },
  light: {
    ContainerInnerWrapper: style.LightContainerInnerWrapper,
    Container: style.LightContainer,
  },
};

const Tooltip = (props: TooltipProps) => {
  const {
    // general
    touchable = false,
    theme = 'oneflow',
    // for trigger
    children,
    triggerClassName,
    // for content
    align,
    alignOffset,
    animate = true,
    collisionBoundary,
    collisionPadding,
    contentClassName,
    hideContent,
    onPointerDownOutside,
    side = 'right',
    sideOffset = 6,
    zIndex = 1,
    sticky = 'always',
    // for provider
    delayDuration = 300,
    // for root
    open,
    onOpenChange,
    // message inside content
    messageClassName,
    header,
    message,
  } = props;

  const preventNavigation = useCallback((e: MouseEvent) => {
    // When the trigger is inside router Link component or Link component itself
    // Then clicking/selecting the tooltip content will do navigation as well
    // https://github.com/remix-run/react-router/blob/v5.1.2/packages/react-router-dom/modules/Link.js#L46
    e.preventDefault();
  }, []);

  if (touchable && isDeviceWithoutHover()) {
    const { disabled } = props;

    return (
      <TouchableTooltip
        message={message}
        header={header}
        messageClassName={messageClassName}
        hideContent={hideContent}
        align={align}
        alignOffset={alignOffset}
        theme={theme}
        zIndex={zIndex}
        side={side}
        sideOffset={sideOffset}
        collisionBoundary={collisionBoundary}
        collisionPadding={collisionPadding}
        onPointerDownOutside={onPointerDownOutside}
        sticky={sticky}
        animate={animate}
      >
        <TouchableTooltip.Trigger
          disabled={disabled}
          className={triggerClassName}
        >
          {children}
        </TouchableTooltip.Trigger>
      </TouchableTooltip>
    );
  }

  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root
        open={open}
        onOpenChange={onOpenChange}
      >
        <TooltipTrigger className={triggerClassName}>{children}</TooltipTrigger>
        <TooltipPrimitive.Portal>
          <TooltipContent
            align={align}
            alignOffset={alignOffset}
            className={clsx(
              style.ContentContainerInnerWrapper,
              themes[theme]?.ContainerInnerWrapper,
              contentClassName,
              { [style.Animate]: animate },
            )}
            collisionBoundary={collisionBoundary}
            collisionPadding={collisionPadding}
            hideContent={hideContent}
            onClick={preventNavigation}
            onPointerDownOutside={onPointerDownOutside}
            side={side}
            sideOffset={sideOffset}
            zIndex={zIndex}
            sticky={sticky}
          >
            <div
              data-testid="tooltip-content-message"
              className={clsx(
                style.ContentContainer,
                messageClassName,
                themes[theme]?.Container,
              )}
            >
              {header}
              {message}
            </div>
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
