import React from 'react';

import Container from 'components/legacy/container';
import Spinner from 'components/legacy/spinner';

const Loading = () => (
  <Container>
    <Spinner />
  </Container>
);

export default Loading;
