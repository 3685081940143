// @flow

import React from 'react';

import LabelValueSeparator from 'components/label-value-separator';
import * as icons from './icons';

import style from './icon-list.module.scss';

const IconList = () => {
  const iconNames = Object.keys(icons);
  const allIcons = iconNames.map((icon) => {
    const iconSvg = icons[icon];
    const IconComponent: React.ComponentType<IconProps> = iconSvg;
    return (
      <div className={style.Icon} key={icon}>
        <IconComponent height="54px" />
        <span className={style.Name}>{icon}</span>
      </div>
    );
  });

  return (
    <>
      <div className={style.IconList}>
        {allIcons}
      </div>
      <span className={style.Total}>
        Total
        <LabelValueSeparator />
        {iconNames.length}
      </span>
    </>
  );
};

export default IconList;
