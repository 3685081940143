// @flow

import React from 'react';

import Svg from 'components/svg';

const AlignRight = Svg((
  <>
    <path d="M20 6H4V8H20V6Z" fill="currentColor" />
    <path d="M20 11H11V13H20V11Z" fill="currentColor" />
    <path d="M20 16H7V18H20V16Z" fill="currentColor" />
  </>
));

AlignRight.displayName = 'AlignRight';

export default AlignRight;
