// @flow

import map from 'lodash/map';
import * as countries from 'i18n-iso-countries';
import sortBy from 'lodash/sortBy';

type ToSelectOption = (language: string, code: string) => Option;
const toSelectOption: ToSelectOption = (language, code) => ({
  label: language,
  value: code,
});

// Special case for language = 'nb';
const toAdjustedLangCode = (lang) => (lang === 'no' ? 'nb' : lang);

type GetCountryListAsOptions = (lang?: EnabledLanguages) => Array<Option>

export const getCountryListAsOptions: GetCountryListAsOptions = (lang = 'en') => {
  const allCountries = countries.getNames(toAdjustedLangCode(lang));
  const countryOptions = map(allCountries, toSelectOption);

  return sortBy(countryOptions, (country) => country.label);
};

export const getAmericanStatesAsOptions = () => (
  [
    { label: 'Alabama', value: 'US-AL' },
    { label: 'Alaska', value: 'US-AK' },
    { label: 'Arizona', value: 'US-AZ' },
    { label: 'Arkansas', value: 'US-AR' },
    { label: 'California', value: 'US-CA' },
    { label: 'Colorado', value: 'US-CO' },
    { label: 'Connecticut', value: 'US-CT' },
    { label: 'Delaware', value: 'US-DE' },
    { label: 'District of Columbia', value: 'US-DC' },
    { label: 'Florida', value: 'US-FL' },
    { label: 'Georgia', value: 'US-GA' },
    { label: 'Hawaii', value: 'US-HI' },
    { label: 'Idaho', value: 'US-ID' },
    { label: 'Illinois', value: 'US-IL' },
    { label: 'Indiana', value: 'US-IN' },
    { label: 'Iowa', value: 'US-IA' },
    { label: 'Kansas', value: 'US-KS' },
    { label: 'Kentucky', value: 'US-KY' },
    { label: 'Lousiana', value: 'US-LA' },
    { label: 'Maine', value: 'US-ME' },
    { label: 'Maryland', value: 'US-MD' },
    { label: 'Massachusetts', value: 'US-MA' },
    { label: 'Michigan', value: 'US-MI' },
    { label: 'Minnesota', value: 'US-MN' },
    { label: 'Mississippi', value: 'US-MS' },
    { label: 'Missouri', value: 'US-MO' },
    { label: 'Montana', value: 'US-MT' },
    { label: 'Nebraska', value: 'US-NE' },
    { label: 'Nevada', value: 'US-NV' },
    { label: 'New Hampshire', value: 'US-NH' },
    { label: 'New Jersey', value: 'US-NJ' },
    { label: 'New Mexico', value: 'US-NM' },
    { label: 'New York', value: 'US-NY' },
    { label: 'North Carolina', value: 'US-NC' },
    { label: 'North Dakota', value: 'US-ND' },
    { label: 'Ohio', value: 'US-OH' },
    { label: 'Oklahoma', value: 'US-OK' },
    { label: 'Oregon', value: 'US-OR' },
    { label: 'Pennsylvania', value: 'US-PA' },
    { label: 'Rhode Island', value: 'US-RI' },
    { label: 'South Carolina', value: 'US-SC' },
    { label: 'South Dakota', value: 'US-SD' },
    { label: 'Tennessee', value: 'US-TN' },
    { label: 'Texas', value: 'US-TX' },
    { label: 'Utah', value: 'US-UT' },
    { label: 'Vermont', value: 'US-VT' },
    { label: 'Virginia', value: 'US-VA' },
    { label: 'Washington', value: 'US-WA' },
    { label: 'West Virginia', value: 'US-WV' },
    { label: 'Wisconsin', value: 'US-WI' },
    { label: 'Wyoming', value: 'US-WY' },
  ]
);

export const getCanadianProvinceAsOptions = () => (
  [
    { label: 'Alberta', value: 'CA-AB' },
    { label: 'British Columbia', value: 'CA-BC' },
    { label: 'Manitoba', value: 'CA-MB' },
    { label: 'New Brunswick', value: 'CA-NB' },
    { label: 'Newfoundland and Labrador', value: 'CA-NL' },
    { label: 'Northwest Territories', value: 'CA-NT' },
    { label: 'Nova Scotia', value: 'CA-NS' },
    { label: 'Nunavut', value: 'CA-NU' },
    { label: 'Ontario', value: 'CA-ON' },
    { label: 'Prince Edward Island', value: 'CA-PE' },
    { label: 'Quebec', value: 'CA-QC' },
    { label: 'Saskatchewan', value: 'CA-SK' },
    { label: 'Yukon', value: 'CA-YT' },
  ]
);

type Props = {
  country?: string,
  lang?: EnabledLanguages
};

const Country = (props: Props) => {
  const { country, lang } = props;

  if (!country) {
    return null;
  }

  return countries.getName(country, toAdjustedLangCode(lang));
};

Country.defaultProps = {
  lang: 'en',
};

export default Country;
