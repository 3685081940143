// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { MAX_NUMBER_OF_SEATS } from 'account/constants';

import Field from 'components/field';
import { NumberField } from 'components/text-field';

import {
  composeValidators,
  numberValidator,
  minValue,
  maxValue,
} from 'forms/validators';

type Props = {
  message: MessageTranslator,
  min?: number,
  autoFocus?: boolean,
  noPlaceholder?: boolean,
  initialValue?: number,
};

export class AccountSeatsField extends React.PureComponent<Props> {
  static defaultProps = {
    min: 1,
    autoFocus: undefined,
    noPlaceholder: undefined,
    initialValue: undefined,
  }

  validations = (({ message, min }: Props) => ({
    numberOfSeats: composeValidators(
      numberValidator({
        message,
        field: message({
          id: 'Number of seats',
          comment: 'Field name used in validation for the number of seats when buying new seats.',
        }),
      }),
      minValue({
        message,
        field: message({
          id: 'Number of seats',
          comment: 'Field name used in validation for the number of seats when buying new seats.',
        }),
        limit: min,
      }),
      maxValue({
        message,
        field: message({
          id: 'Number of seats',
          comment: 'Field name used in validation for the number of seats when buying new seats.',
        }),
        limit: MAX_NUMBER_OF_SEATS,
      }),
    ),
  }))(this.props);

  render() {
    const {
      message,
      autoFocus,
      min,
      noPlaceholder,
      initialValue,
    } = this.props;

    return (
      <Field
        name="numberOfSeats"
        label={message({ id: 'Number of seats', comment: 'Field label in buy more seats page' })}
        placeholder={
          noPlaceholder
            ? ''
            : message({ id: 'Enter number of seats', comment: 'Field placeholder in buy more seats page' })
        }
        component={NumberField}
        validate={this.validations.numberOfSeats}
        autoFocus={autoFocus}
        responsive
        required
        hideLabel
        min={min}
        max={MAX_NUMBER_OF_SEATS}
        initialValue={initialValue}
      />
    );
  }
}

export default localize<Props>(AccountSeatsField);
