// @flow

import React from 'react';

import Svg from 'components/svg';

const ViewCountEye = Svg((
  <>
    <path
      d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00006 1.75C2.79521 1.75 1.44212 2.84938 0.310106 4.18787C0.110999 4.40379 0.000244141 4.68689 0.000244141 4.981C0.000244141 5.27945 0.114294 5.56657 0.318983 5.78365C1.04896 6.57136 2.76639 8.25 5.00006 8.25C7.23372 8.25 8.95115 6.57136 9.68113 5.78366C9.88582 5.56658 9.99987 5.27945 9.99987 4.981C9.99987 4.68689 9.88911 4.40378 9.69 4.18786C8.55798 2.84938 7.2049 1.75 5.00006 1.75ZM1.06611 4.84255C2.10953 3.60662 3.21028 2.75 5.00006 2.75C6.78984 2.75 7.89058 3.60662 8.934 4.84255C8.9402 4.84989 8.94662 4.85705 8.95323 4.86403C8.98318 4.89561 8.99987 4.93748 8.99987 4.981C8.99987 5.02452 8.98318 5.06639 8.95323 5.09797L8.94926 5.1022C8.21826 5.89129 6.76452 7.25 5.00006 7.25C3.23559 7.25 1.78185 5.89129 1.05085 5.1022L1.04688 5.09797C1.01694 5.06639 1.00024 5.02452 1.00024 4.981C1.00024 4.93748 1.01694 4.89561 1.04688 4.86403C1.0535 4.85705 1.05991 4.84989 1.06611 4.84255Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 10 10', fill: 'none' });

ViewCountEye.displayName = 'ViewCountEye';

export default ViewCountEye;
