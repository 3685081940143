// @flow

import { isEmpty, omit } from 'lodash';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import type {
  CallEffect,
  PutEffect,
  SelectEffect,
} from 'redux-saga';

import localStorage from 'utils/local-storage';

import {
  addParticipant,
  approveDraft,
  approvePendingState,
  cancelAgreement,
  createAgreement,
  declineAgreement,
  emptyAgreementTrash,
  fetchAgreement,
  getAgreements,
  messageParticipants,
  moveAgreement,
  postMessage,
  permanentDeleteAgreement,
  prepareGetAgreementsExtraParams,
  publishAgreement,
  getDocumentDelayedEvents,
  reScheduleInternalReminder,
  removeAgreement,
  removeInternalReminder,
  removeParticipant,
  removeParty,
  replaceAgreement,
  restoreTrashedAgreement,
  requestAuthenticationToken,
  saveAgreement,
  scheduleInternalReminder,
  sendConcludedAgreementCopy,
  sendSignCode,
  signAgreement,
  terminateAgreement,
  toggleNotificationsGuest,
  updateAgreement,
  updateAgreementConfig,
  updateAttachmentBox,
  updateContractName,
  updateContractValue,
  updateExpiryDate,
  updateParticipant,
  updateParty,
  validateAuthenticationToken,
} from 'oneflow-client/agreements';
import { getEventsList } from 'agreement/event';
import { fetchContractLinks } from 'oneflow-client/agreement-links';
import { fetchAgreementsReviews } from 'oneflow-client/workspaces';
import type { NormalizedAgreements } from 'oneflow-client/agreements';

import generateEntitySagas from 'normalized-redux/sagas';
import apiWrapper from 'sagas/api-wrapper';

import {
  cleanupAcceptedSuggestions,
  getCurrentContractId,
  getCurrentContractMessages,
  setContractRawData,
  setCurrentContractData,
  setCurrentContractEventsList,
  setCurrentContractId,
  setCurrentContractMessages,
  setInitialAttachmentsTotalSizeAndCount,
  updateDataFieldMappings,
  updatePriceColumnsAction,
  ATTACHMENTS_MARK_ALL_FILES_AS_OLD,
  cleanupRejectedSuggestions,
  updateCollapsedBoxIds,
  getCurrentBoxes,
  getDataFieldExternalKeyMap,
  getDataFieldExternalKeyValueMap,
} from 'reducers/current-contract';
import tagConnections from 'reducers/entities/tag-connections';
import agreements from 'reducers/entities/agreements';
import tags from 'reducers/entities/tags';
import {
  getCurrentWorkspaceSelector,
  setAgreementSidebarActiveTabName,
  setAgreementSidebarCommentState,
  setAgreementSidebarMessageType,
  setAgreementSidebarOpen,
  setCollapsedLayoutActiveTabName,
  setCurrentWorkspace,
} from 'reducers/app';
import { setHasUser } from 'reducers/session';
import { isTemplate } from 'agreement';
import {
  MESSAGES_TAB,
  PARTICIPANTS_TAB,
  SETTINGS_TAB,
} from 'agreement/constants';
import {
  getMessageIdFromSessionStorage,
  isAnnotation,
  isAnnotationResolved,
  resetMessageIdInSessionStorage,
} from 'comments';
import { COMMENT_STATES, MESSAGE_TYPES } from 'comments/constants';

import { DOCUMENT_COLLAPSED_LAYOUT_SIZE } from 'components/document-layout-container/helpers';
import { getHiddenSections } from 'components/contract-actions/set-all-hidden-sections-collapsed/set-all-hidden-sections-collapsed';
import { checkAcl } from 'components/acl';

type ExtraQueryParams = {
  params: {
    collectionIds?: Array<number>,
    sharedWithMe?: number,
  },
}

type ExtraCreateParams = {
  collectionId: number,
};

type SetWorkspaceId = Generator<SelectEffect<any, any>, ExtraQueryParams | ExtraCreateParams, any>;

type Action = {
  query: Query
};

export function* prepareGetAgreementsQuery({ action }: { action: Action }): SetWorkspaceId {
  const { query } = action;

  if (query.params?.global) {
    return {
      params: query.params,
    };
  }

  const workspace = yield select(getCurrentWorkspaceSelector);

  return {
    params: prepareGetAgreementsExtraParams(
      query.params, workspace.id, false,
    ),
  };
}

type MapperArgs = {
  data: NormalizedAgreements,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;
type UpdateExpireDateDaysMapper = Generator<CallEffect<any, any, any>, void, any>;

export function* mapper({ data }: MapperArgs): Mapper {
  yield put(tagConnections.setTagConnections(data.entities.tagConnections));
  yield put(tags.setTags(data.entities.tags));
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* getAgreementsMapper({ data }: MapperArgs): Mapper {
  yield* mapper({ data });

  const workspace = yield select(getCurrentWorkspaceSelector);
  const { agreements: documents } = data.entities;
  if (documents) {
    const agreementIds = Object.keys(documents).reduce((idsArray, id) => {
      const agreement = documents[id];
      if (checkAcl(agreement.acl, 'agreement:ai_review:view')) {
        idsArray.push(agreement.id);
      }
      return idsArray;
    }, []);

    if (agreementIds.length > 0) {
      yield put(agreements.fetchAiReviews({ id: workspace.id, data: { agreementIds } }));
    }
  }
}

export function* aiReviewMapper({ data }) {
  const reviewedAgreements = data.collection.reduce((acc, agreement) => {
    if (agreement.review) {
      acc[agreement.id] = {
        review: agreement.review,
      };
    }
    return acc;
  }, {});
  yield put(agreements.setAgreements(reviewedAgreements));
}

type CommentMapperArgs = {
  data: any,
};

export function* postMessageMapper({ data }: CommentMapperArgs): Mapper {
  const { id: agreementId } = data.agreement;
  const { checksum } = data.agreement;

  yield put(agreements.setAgreements({
    [agreementId]: {
      messages: [
        ...(data.agreement?.messages || []),
      ],
      checksum,
    },
  }));

  yield put(setCurrentContractMessages(data.agreement?.messages));
}

export function* updateSignOrderMapper({ data }: MapperArgs): Mapper {
  const agreementId = data.result;
  const agreement = data.entities.agreements[agreementId];

  yield put(agreements.setAgreements({
    [agreementId]: {
      parties: agreement.parties,
      pendingStateFlow: agreement.pendingStateFlow,
      config: { ...agreement.config, signOrder: agreement.config.signOrder },
      acl: agreement.acl,
    },
  }));
}

export function* setAgreementMapper({ data }: MapperArgs): Mapper {
  const agreementId = data.result;

  yield put(agreements.setAgreements({
    [agreementId]: data.entities.agreements[agreementId],
  }));
}

export function* changeTemplateGroupMapper({ data }: MapperArgs): Mapper {
  const agreementId = data.result;
  yield put(agreements.setAgreements({
    [agreementId]: data.entities.agreements[agreementId],
  }));

  yield put(setCurrentContractData(data.entities.agreements[agreementId]));
}

export function* loadCollapsedBoxIds(agreementId) {
  // eslint-disable-next-line import/no-named-as-default-member
  const collapsedBoxIds = JSON.parse(localStorage.getItem(`collapsedBoxIds-${agreementId}`));
  const boxes = yield select(getCurrentBoxes);
  const updatedCollapsedBoxIds = collapsedBoxIds?.map((id) => {
    const box = Object.values(boxes).find((b) => b._id === id);
    return box && box.id ? box.id : id;
  });

  yield put(updateCollapsedBoxIds(updatedCollapsedBoxIds ?? []));
}

export function* saveAgreementMapper({ data: { raw, normalized } }: MapperArgs): Mapper {
  const agreement = normalized.entities.agreements[normalized.result];
  const boxes = yield select(getCurrentBoxes);
  const dataFieldExternalKeyMap = yield select(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = yield select(getDataFieldExternalKeyValueMap);

  yield put(agreements.setAgreements({
    // Omit messages to not mess up the order of messages
    [agreement.id]: omit(agreement, 'messages'),
  }));
  yield put(setCurrentContractData(agreement));
  yield put(setCurrentContractMessages(agreement.messages));
  yield put(setContractRawData(raw));
  yield put(updateDataFieldMappings());
  yield put(updatePriceColumnsAction());
  yield put(cleanupAcceptedSuggestions());
  yield put(cleanupRejectedSuggestions());
  yield put({ type: ATTACHMENTS_MARK_ALL_FILES_AS_OLD });
  if (!isEmpty(getHiddenSections(
    boxes,
    dataFieldExternalKeyValueMap,
    dataFieldExternalKeyMap,
  ))) {
    yield call(loadCollapsedBoxIds, agreement.id);
  }
}

export function* handleSetCurrentContractEventsList(agreement) {
  const eventsList = yield call(getEventsList, agreement);
  yield put(setCurrentContractEventsList(eventsList));
}

export function* redirectMessage(message) {
  if (!isAnnotation(message)) {
    return;
  }
  yield put(setAgreementSidebarMessageType(MESSAGE_TYPES.COMMENTS));

  if (isAnnotationResolved(message)) {
    yield put(setAgreementSidebarCommentState(COMMENT_STATES.COMMENT_RESOLVED));
  }
}

function* getParentMessage(messageId) {
  const { allMessages, mappedMessages } = yield select(getCurrentContractMessages);
  let message = mappedMessages[messageId];

  if (!message) {
    const reply = allMessages.find(({ id }) => id === Number(messageId));
    message = mappedMessages[reply?.parent.id];
  }

  return message;
}

export function* prepareSidebarInitialState(agreement, isInitialLoad) {
  if (isInitialLoad && isTemplate(agreement)) {
    yield put(setAgreementSidebarActiveTabName(SETTINGS_TAB));
    return;
  }

  const messageId = getMessageIdFromSessionStorage();

  if (!messageId) {
    // Reset the sidebar in case user navigates from one document to another
    yield put(setAgreementSidebarActiveTabName(PARTICIPANTS_TAB));
    return;
  }
  const message = yield call(getParentMessage, messageId);

  if (message) {
    if (window.innerWidth < DOCUMENT_COLLAPSED_LAYOUT_SIZE) {
      yield put(setCollapsedLayoutActiveTabName(MESSAGES_TAB));
    } else {
      yield put(setAgreementSidebarOpen(true));
      yield put(setAgreementSidebarActiveTabName(MESSAGES_TAB));
    }
    yield call(redirectMessage, message);
    return;
  }

  resetMessageIdInSessionStorage();
}

export function* setUpAgreementMetaData(agreement) {
  yield put(setCurrentContractId(agreement.id));
  yield put(setCurrentContractData(agreement));
  yield put(setCurrentContractMessages(agreement.messages));
  yield put(setInitialAttachmentsTotalSizeAndCount(agreement));
  yield put(updateDataFieldMappings());
  yield put(updatePriceColumnsAction());
}

export function* fetchAgreementMapper({
  data: { raw, normalized, hasUser },
}: MapperArgs): Mapper {
  const currentContractId = yield select(getCurrentContractId);
  const agreement = normalized.entities.agreements[normalized.result];

  yield call(setUpAgreementMetaData, agreement);
  yield call(handleSetCurrentContractEventsList, agreement);
  yield put(setContractRawData(raw));

  yield put(agreements.setAgreements({
    [agreement.id]: agreement,
  }));
  yield put(setCurrentWorkspace({ workspaceId: agreement.collection?.id || 0 }));

  yield put(setHasUser(hasUser));

  const isInitialLoad = currentContractId !== agreement.id;
  yield call(prepareSidebarInitialState, agreement, isInitialLoad);

  const boxes = yield select(getCurrentBoxes);
  const dataFieldExternalKeyMap = yield select(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = yield select(getDataFieldExternalKeyValueMap);

  if (!isEmpty(getHiddenSections(
    boxes,
    dataFieldExternalKeyValueMap,
    dataFieldExternalKeyMap,
  ))) {
    yield call(loadCollapsedBoxIds, agreement.id);
  }
}

export function* addParticipantMapper({ data }: MapperArgs): Mapper {
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* updateParticipantMapper({ data }: MapperArgs): Mapper {
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* updatePartyMapper({ data }: MapperArgs): Mapper {
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* createMapper({ data }: MapperArgs): Mapper {
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* replaceMapper({ data }: MapperArgs): Mapper {
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* clearMapper({ action }: MapperArgs): Mapper {
  yield put(agreements.clearAgreement({ id: action.id }));
}

export function* mfaMapper({ action }: MapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.id]: {
      mfa: {
        token: action.data.token,
        participantId: action.data.participantId,
      },
    },
  }));
}

type NormalizedLinks = {
  collection: Array<any>,
  count: number,
}
type ContractLinksMapperArgs = {
  data: NormalizedLinks,
  action: any,
};

export function* contractLinksMapper({ action, data }: ContractLinksMapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.id]: {
      links: data.collection,
      linkCount: data.collection.length,
    },
  }));
}

type NormalizedInternalReminders = {
  collection: Array<any>,
  count: number,
}
type InternalRemindersMapperArgs = {
  data: NormalizedInternalReminders,
  action: any,
};

export function* contractInternalRemindersMapper({
  action,
  data,
}: InternalRemindersMapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.id]: {
      reminders: data.collection,
      userEventsCount: data.collection.length,
    },
  }));
}

type UpdateContractValueMapperArgs = {
  data: AgreementValue,
  action: any,
};

export function* updateContractValueMapper({
  action,
  data,
}: UpdateContractValueMapperArgs): Mapper {
  let agreementValue = data;

  if (isEmpty(data)) {
    agreementValue = null;
  }

  yield put(agreements.setAgreements({
    [action.id]: {
      agreementValue,
    },
  }));
}

export function* setContractNameMapper({ action }: MapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.id]: {
      name: action.data.name,
    },
  }));
}

type UpdateConfigMapperArgs = {
  action: any,
  data: {
    checksum: string,
    expireDateDays: number,
    [key: string]: any,
  }
}

export function* updateConfigMapper({
  action,
  data: config,
}: UpdateConfigMapperArgs): Mapper {
  yield put(agreements.setAgreements({
    [action.id]: {
      checksum: config.checksum,
      config,
      acl: config.acl,
    },
  }));
}

export function* updateExpireDateDaysMapper({
  data,
}: UpdateContractValueMapperArgs): UpdateExpireDateDaysMapper {
  yield put(agreements.setAgreements(data.entities.agreements));
}

export function* fetchParticipantsSignStateMapper({
  data,
}: { data: AgreementValue }) {
  const agreement = data.normalized.entities.agreements[data.normalized.result];
  yield put(agreements.setAgreements({
    [agreement.id]: {
      parties: agreement.parties,
      checksum: agreement.checksum,
      state: agreement.state,
      pendingStateFlow: agreement.pendingStateFlow,
    },
  }));
}

const mappers = {
  fetch: {
    mapper: fetchAgreementMapper,
    request: fetchAgreement,
  },
  query: {
    prepare: prepareGetAgreementsQuery,
    mapper: getAgreementsMapper,
    request: getAgreements,
  },
  create: {
    mapper: createMapper,
    request: createAgreement,
  },
  remove: {
    mapper: clearMapper,
    request: removeAgreement,
  },
  rpcs: {
    addParticipant: {
      name: 'addParticipant',
      mapper: addParticipantMapper,
      request: addParticipant,
    },
    approveDraft: {
      name: 'approveDraft',
      mapper,
      request: approveDraft,
    },
    approvePendingState: {
      name: 'approvePendingState',
      mapper,
      request: approvePendingState,
    },
    moveAgreement: {
      name: 'moveAgreement',
      mapper,
      request: moveAgreement,
    },
    declineAgreement: {
      name: 'declineAgreement',
      mapper,
      request: declineAgreement,
    },
    cancelAgreement: {
      name: 'cancelAgreement',
      mapper,
      request: cancelAgreement,
    },
    saveAgreement: {
      name: 'saveAgreement',
      mapper: saveAgreementMapper,
      request: saveAgreement,
    },
    signAgreement: {
      name: 'signAgreement',
      mapper,
      request: signAgreement,
    },
    sendSignCode: {
      name: 'sendSignCode',
      mapper,
      request: sendSignCode,
    },
    publishAgreement: {
      name: 'publishAgreement',
      mapper,
      request: publishAgreement,
    },
    messageParticipants: {
      name: 'messageParticipants',
      request: messageParticipants,
    },
    scheduleInternalReminder: {
      name: 'scheduleInternalReminder',
      request: scheduleInternalReminder,
    },
    reScheduleInternalReminder: {
      name: 'reScheduleInternalReminder',
      request: reScheduleInternalReminder,
    },
    removeInternalReminder: {
      name: 'removeInternalReminder',
      request: removeInternalReminder,
    },
    terminateAgreement: {
      name: 'terminateAgreement',
      mapper,
      request: terminateAgreement,
    },
    sendConcludedAgreementCopy: {
      name: 'sendConcludedAgreementCopy',
      request: sendConcludedAgreementCopy,
    },
    requestAuthenticationToken: {
      name: 'requestAuthenticationToken',
      request: requestAuthenticationToken,
    },
    validateAuthenticationToken: {
      name: 'validateAuthenticationToken',
      request: validateAuthenticationToken,
    },
    updateContractValue: {
      name: 'updateContractValue',
      mapper: updateContractValueMapper,
      request: updateContractValue,
    },
    updateContractName: {
      name: 'updateContractName',
      mapper: setContractNameMapper,
      request: updateContractName,
    },
    updateExpiryDate: {
      name: 'updateExpiryDate',
      mapper,
      request: updateExpiryDate,
    },
    updateAttachmentBox: {
      name: 'updateAttachmentBox',
      mapper,
      request: updateAttachmentBox,
    },
    changeTemplateGroup: {
      name: 'changeTemplateGroup',
      mapper: changeTemplateGroupMapper,
      request: updateAgreement,
    },
    updateAgreementPreferences: {
      name: 'updateAgreementPreferences',
      mapper: setAgreementMapper,
      request: updateAgreement,
    },
    updateSignOrder: {
      name: 'updateSignOrder',
      mapper: updateSignOrderMapper,
      request: updateAgreement,
    },
    updateConfig: {
      name: 'updateConfig',
      mapper: updateConfigMapper,
      request: updateAgreementConfig,
    },
    fetchContractLinks: {
      name: 'fetchContractLinks',
      mapper: contractLinksMapper,
      request: fetchContractLinks,
    },
    fetchAiReviews: {
      name: 'fetchAiReviews',
      mapper: aiReviewMapper,
      request: fetchAgreementsReviews,
    },
    fetchContractInternalReminders: {
      name: 'fetchContractInternalReminders',
      mapper: contractInternalRemindersMapper,
      request: getDocumentDelayedEvents,
    },
    replaceAgreement: {
      name: 'replaceAgreement',
      mapper: replaceMapper,
      request: replaceAgreement,
    },
    postMessage: {
      name: 'postMessage',
      mapper: postMessageMapper,
      request: postMessage,
    },
    updateParticipant: {
      name: 'updateParticipant',
      mapper: updateParticipantMapper,
      request: updateParticipant,
    },
    removeParticipant: {
      name: 'removeParticipant',
      mapper: updateParticipantMapper,
      request: removeParticipant,
    },
    updateParty: {
      name: 'updateParty',
      mapper: updatePartyMapper,
      request: updateParty,
    },
    removeParty: {
      name: 'removeParty',
      mapper: updatePartyMapper,
      request: removeParty,
    },
    toggleNotificationsGuest: {
      name: 'toggleNotificationsGuest',
      mapper: updateParticipantMapper,
      request: toggleNotificationsGuest,
    },
    fetchParticipantsSignState: {
      name: 'fetchParticipantsSignState',
      mapper: fetchParticipantsSignStateMapper,
      request: fetchAgreement,
    },
    restoreTrashedAgreement: {
      name: 'restoreTrashedAgreement',
      mapper,
      request: restoreTrashedAgreement,
    },
    permanentDeleteAgreement: {
      name: 'permanentDeleteAgreement',
      request: permanentDeleteAgreement,
    },
    emptyAgreementTrash: {
      name: 'emptyAgreementTrash',
      request: emptyAgreementTrash,
    },
  },
};

const agreementsSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: agreements,
  mappers,
});

export default agreementsSagas;
