import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import apiWrapper from 'sagas/api-wrapper';
import { getMe } from 'oneflow-client/positions';

import { setError } from 'reducers/app';

import {
  REQUEST_ME_START,
  requestMeSuccess,
  getLanguageFromMeSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import {
  getCurrentLanguageSelector,
  setLanguage,
} from 'reducers/i18n';
import { amplitudeSetUserAndGroupProps } from 'client-analytics/amplitude';

import accountsReducer from 'reducers/entities/accounts';
import workspacesReducer from 'reducers/entities/workspaces';
import positionsReducer from 'reducers/entities/positions';

export function* setLanguageFromSession() {
  const currentLanguage = yield select(getCurrentLanguageSelector);
  const sessionPreferredLanguage = yield select(getLanguageFromMeSelector);

  if (sessionPreferredLanguage && currentLanguage !== sessionPreferredLanguage) {
    yield put(setLanguage(sessionPreferredLanguage));
  }
}

export function* setAmplitudePositionFromSession() {
  const position = yield select(getPositionFromSessionSelector);

  if (position) {
    amplitudeSetUserAndGroupProps(
      position.id,
      [['account role', position.userRole], ['application language', position.language]],
      'account id',
      position.account.toString(),
    );
  }
}

export function* requestMe() {
  try {
    const data = yield call(apiWrapper, { method: getMe });

    yield put(accountsReducer.setAccounts(data.entities.accounts));
    yield put(workspacesReducer.setWorkspaces(data.entities.workspaces));
    yield put(positionsReducer.setPositions(data.entities.positions));

    yield put(requestMeSuccess({ id: data.result }));

    yield call(setLanguageFromSession);
    yield call(setAmplitudePositionFromSession);
  } catch (error) {
    yield put(setError(true, error));
  }
}

export default function* me() {
  yield takeLatest(REQUEST_ME_START, requestMe);
}
