// @flow

import React from 'react';

import Svg from 'components/svg';

const AddText = Svg((
  <>
    <path d="M17.4883 11.0752H16.9619C16.8206 10.6605 16.5928 10.2161 16.2783 9.74219C15.9684 9.26823 15.6813 9.01302 15.417 8.97656C15.2712 8.95833 15.0911 8.94238 14.877 8.92871C14.6628 8.91504 14.4782 8.9082 14.3232 8.9082H14.002V16.5166C14.002 16.6807 14.0316 16.8265 14.0908 16.9541C14.1501 17.0771 14.2663 17.1751 14.4395 17.248C14.5443 17.2891 14.7061 17.3324 14.9248 17.3779C15.1481 17.4189 15.3372 17.4486 15.4922 17.4668V18H10.085V17.4668C10.2171 17.4531 10.4017 17.4349 10.6387 17.4121C10.8802 17.3848 11.0443 17.3529 11.1309 17.3164C11.3086 17.2435 11.4271 17.1455 11.4863 17.0225C11.5456 16.8994 11.5752 16.7536 11.5752 16.585V8.9082H11.2539C11.099 8.9082 10.9144 8.91504 10.7002 8.92871C10.486 8.94238 10.306 8.95833 10.1602 8.97656C9.89583 9.01302 9.60645 9.26823 9.29199 9.74219C8.9821 10.2161 8.75651 10.6605 8.61523 11.0752H8.08887V8.2998H17.4883V11.0752Z" fill="currentColor" />
    <rect x="0.996582" y="0.5" width="24" height="24" rx="1" stroke="currentColor" strokeWidth="4" mask="url(#path-2-inside-2_1362_13838)" />
  </>
), {
  viewBox: '0 0 25 25',
  fill: 'none',
});

AddText.displayName = 'AddText';

export default AddText;
