import Svg from 'components/svg';

const DelegateSigning = Svg((
  <>
    <path
      d="M1.125 14.2499H7.6875L13.625 12.3749V12.1299C13.6248 11.8749 13.5648 11.6235 13.4498 11.3959C13.3348 11.1683 13.1681 10.9708 12.963 10.8193C12.7578 10.6678 12.5201 10.5665 12.2687 10.5235C12.0174 10.4805 11.7594 10.497 11.5156 10.5718L7.6875 11.7499M7.6875 11.7499H4.5625M7.6875 11.7499V9.87491L3.75625 9.26991C3.48878 9.22872 3.21556 9.24587 2.95534 9.32017C2.69511 9.39447 2.45403 9.52416 2.24863 9.70036C2.04322 9.87655 1.87835 10.0951 1.76531 10.341C1.65228 10.5869 1.59375 10.8543 1.59375 11.1249"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5753 2.12132L9.60742 7.67805C9.59505 7.69188 9.58216 7.70525 9.56878 7.71812C9.43831 7.8436 9.28522 7.93392 9.12699 7.99201C8.96855 8.05018 8.80132 8.07753 8.63612 8.07559C8.47093 8.07366 8.30411 8.04239 8.14672 7.98007C7.98951 7.91781 7.83813 7.823 7.71072 7.69282C7.6863 7.66788 7.66368 7.64123 7.64303 7.61309L5.68164 4.93965L6.87273 4.06579L8.66783 6.51255L13.4739 1.13672L14.5753 2.12132Z"
      fill="currentColor"
    />

  </>
), { viewBox: '0 0 16 16', fill: 'none' });

DelegateSigning.displayName = 'DelegateSigning';

export default DelegateSigning;
