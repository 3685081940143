// @flow

import React from 'react';

import Svg from 'components/svg';

const NoFilters = Svg((
  <path
    d="M1 17.2609C1 15.8261 1.27186 14.9695 1.78912 14.0088C2.30638 13.048 3.05365 12.2303 3.96412 11.629C4.8746 11.0276 5.91989 10.6613 7.00653 10.5627C8.09318 10.4642 9.18732 10.6365 10.1911 11.0642M19.3333 14.9316L14.9333 19.332M14.9333 14.9316L19.3333 19.332M3.56667 5.03369C3.56667 6.10349 3.99161 7.12948 4.748 7.88594C5.5044 8.64241 6.53029 9.06738 7.6 9.06738C8.66971 9.06738 9.6956 8.64241 10.452 7.88594C11.2084 7.12948 11.6333 6.10349 11.6333 5.03369C11.6333 3.96389 11.2084 2.9379 10.452 2.18144C9.6956 1.42498 8.66971 1 7.6 1C6.53029 1 5.5044 1.42498 4.748 2.18144C3.99161 2.9379 3.56667 3.96389 3.56667 5.03369ZM11.2667 17.1328C11.2667 18.6889 11.8848 20.1812 12.985 21.2815C14.0852 22.3818 15.5774 23 17.1333 23C18.6893 23 20.1815 22.3818 21.2817 21.2815C22.3819 20.1812 23 18.6889 23 17.1328C23 15.5767 22.3819 14.0844 21.2817 12.9841C20.1815 11.8838 18.6893 11.2656 17.1333 11.2656C15.5774 11.2656 14.0852 11.8838 12.985 12.9841C11.8848 14.0844 11.2667 15.5767 11.2667 17.1328Z"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), { viewBox: '0 0 24 24', fill: 'none' });

NoFilters.displayName = 'NoFilters';

export default NoFilters;
