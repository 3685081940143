// @flow

import React from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Info from 'components/block-info';

type Props = {
  message: MessageTranslator,
  signupEmail: string,
};

export const SignupStartConfirmationComponent = ({ message, signupEmail }: Props) => (
  <Info
    title={message({
      id: 'Thank you for signing up with Oneflow!',
      comment: 'Signup page header. Used for confirming that the email has been sent.',
    })}
    paragraphs={[
      <Message
        id="Instructions on how to complete your sign up with Oneflow have been sent to {email}."
        values={{
          email: <strong>{signupEmail}</strong>,
        }}
        comment="Signup page body text. Used for describing which email the recovery mail has been sent to."
      />,
      message({
        id: "If you don't receive the instructions within a few minutes of signing up, please check your spam folder.",
        comment: 'Signup page body text. Used to explain that you should recive the email shortly.',
      }),
    ]}
  />
);

export default localize<Props>(SignupStartConfirmationComponent);
