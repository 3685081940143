import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import omit from 'lodash/omit';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import type { Reducer } from 'redux';

import { createReducer, formatActionType } from 'normalized-redux/reducers';
import type { FormattedActionType } from 'normalized-redux/reducers/format-action-type';
import type { Entity } from 'normalized-redux/entity-normalizer/types';

type LocalState<E extends Entity> = Record<E['id'], E>;

export const initialState = {};
const emptyArray = Object.freeze([]);
const emptyObject = Object.freeze({});

type SetEntityAction<T extends string> = {
  type: T,
  entities: unknown,
};

type CreateSetEntityActionProps<E extends Entity> = Record<E['id'], Partial<E>>
// actions
export const createSetEntityAction = <
  E extends Entity,
  T extends string
>(type: T) => (entities: CreateSetEntityActionProps<E>): SetEntityAction<T> => ({
    type,
    entities,
  });

type WithId<E extends Entity> = {
  id: E['id'],
  ids?: never,
};

type WithIds<E extends Entity> = {
  id?: never,
  ids: E['id'][],
};

type ClearEntityAction<E extends Entity, T> = {
  type: T,
  id?: E['id'],
  ids?: E['id'][],
};

type CreateClearEntityActionProps<E extends Entity> = WithId<E> | WithIds<E>;

export const createClearEntityAction = <
E extends Entity,
T extends string
  >(type: T) => ({ id, ids }: CreateClearEntityActionProps<E>): ClearEntityAction<E, T> => ({
    type,
    id,
    ids,
  });

/**
 * Replaces arrays
 */
// TODO: understand what this is is doing and fix the types
function customizer(previousValue: unknown, newValue: unknown) {
  if (isArray(previousValue)) {
    return newValue;
  }

  return undefined;
}

type SetEntitiesHandlerReturnType<E extends Entity> = LocalState<E>;

// handlers
export const setEntitiesHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: SetEntityAction<T>): SetEntitiesHandlerReturnType<E> => (
    mergeWith({}, state, action.entities, customizer)
  );
export const clearEntityHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: ClearEntityAction<E, T>): LocalState<E> => {
  if (isArray(action.ids)) {
    return omit(state, action.ids) as LocalState<E>;
  }
  return omit(state, action.id!) as LocalState<E>;
};

// selectors
export const getAllEntitiesSelector = <
  E extends Entity
>(state: LocalState<E>) => values(state) as E[];

type GetEntitiesByIdSelectorArgs<E extends Entity> = {
  ids: E['id'][],
};
export const getEntitiesByIdSelector = <
E extends Entity
>(state: LocalState<E>, { ids = [] }: GetEntitiesByIdSelectorArgs<E>) => {
  // need to base the return array on the sent in ids,
  // as they could be sorted (and we need to keep that sort)
  // then filter out any ids that wasn't found
  const allEntities = ids
    .map((id) => state[id])
    .filter((entity) => entity);

  if (isEmpty(allEntities)) {
    return emptyArray as readonly E[];
  }

  return allEntities as E[];
};

type GetEntityByIdSelectorArgs<E extends Entity> = {
  id: E['id'],
};
export const getEntityByIdSelector = <
E extends Entity
>(state: LocalState<E>, { id }: GetEntityByIdSelectorArgs<E>) => (
    state[id] || emptyObject as E
  );

type WithKey<E extends Entity> = {
  key: string,
  actionPrefix?: never,
  initialEntities?: Record<E['id'], Partial<E>>,
};

type WithActionPrefix<E extends Entity> = {
  key?: never,
  actionPrefix: string,
  initialEntities?: Record<E['id'], Partial<E>>,
};

type FormattedSetEntitiesActionType<Key extends string> = FormattedActionType<Key, 'SET_ENTITIES'>;
type FormattedClearEntitiesActionType<Key extends string> = FormattedActionType<Key, 'CLEAR_ENTITY'>;

export type EntitiesReducer<E extends Entity, Key extends string> = {
  reducer: Reducer<LocalState<E>, any>,
  actions: {
    setEntities: (
      entities: CreateSetEntityActionProps<E>
    ) => SetEntityAction<FormattedSetEntitiesActionType<Key>>,
    clearEntity: (
      props: CreateClearEntityActionProps<E>
    ) => ClearEntityAction<E, FormattedClearEntitiesActionType<Key>>,
    types: {
      setEntities: FormattedSetEntitiesActionType<Key>,
      clearEntity: FormattedClearEntitiesActionType<Key>,
    },
  },
  selectors: {
    getAllEntitiesSelector: (state: LocalState<E>) => E[],
    getEntityByIdSelector: (state: LocalState<E>, { id }: { id: E['id'] }) => E,
    getEntitiesByIdSelector: (
      state: LocalState<E>,
      { ids }: GetEntitiesByIdSelectorArgs<E>
    ) => E[] | readonly E[],
  },
}

// initializer
const createEntitiesReducer = <
  E extends Entity,
  Key extends string
>({
    key,
    actionPrefix,
    initialEntities = {} as Record<E['id'], Partial<E>>,
  }: WithActionPrefix<E> | WithKey<E>): EntitiesReducer<E, Key> => {
  const actionKey = (actionPrefix || key) as Key;
  const SET_ENTITIES = formatActionType({ key: actionKey, type: 'SET_ENTITIES' });
  const CLEAR_ENTITY = formatActionType({ key: actionKey, type: 'CLEAR_ENTITY' });

  return ({
    reducer: createReducer({ ...initialState, ...initialEntities } as LocalState<E>, {
      [SET_ENTITIES]: setEntitiesHandler,
      [CLEAR_ENTITY]: clearEntityHandler,
    }),
    actions: {
      setEntities: createSetEntityAction(SET_ENTITIES),
      clearEntity: createClearEntityAction(CLEAR_ENTITY),
      types: {
        setEntities: SET_ENTITIES,
        clearEntity: CLEAR_ENTITY,
      },
    },
    selectors: {
      getAllEntitiesSelector,
      getEntityByIdSelector,
      getEntitiesByIdSelector,
    },
  });
};

export default createEntitiesReducer;
