// @flow

import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Message } from '@oneflowab/pomes';
import { parse } from 'query-string';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import ReCAPTCHA from 'react-google-recaptcha';
import type { FormRenderProps } from 'react-final-form';
import type { Location } from 'react-router';

import { getResourceUrl, isSsoMethod } from 'utils/login-methods';
import { isValidEmail } from 'forms/validators';
import { getLoginMethod, getSSOLoginPath, postLogin } from 'oneflow-client/login';
import { LoginFields } from 'routes/public/login/login-fields';
import PublicForm from 'hocs/public-form';
import type { APIError, ServerError } from 'oneflow-client/core';

import { getErrorMessage } from 'components/api-error';
import LoginLink from 'components/login-link';
import TwoFactorAuthentication, { getSecurityCodeText } from 'components/two-factor-authentication';

import { getLoginMessages, getSSOMessages } from './custom-messages';
import style from './login-form.module.scss';

export type MfaResponse = {
  channelData: string,
  hasCode: boolean,
  mfa: boolean,
  positionId: number,
};

export type Props = {
  location: Location,
  loginSuccess: () => void,
  mfaResponse: MfaResponse,
  requestMfaCode: number => void,
  requestMfaState: RpcState,
  resetRequestMfaCode: number => void,
  setPositionMfa: MfaResponse => void,
};

type LoginCredentials = {
  email: string,
  isPersistent?: boolean,
  loginMethod: string | null,
  password?: string,
  securityCode?: string,
};

export const errorRequiresCaptchaHeader = (error: APIError | ServerError): boolean => {
  const headers = get(error, 'response.headers');

  if (!headers) {
    return false;
  }

  return Boolean(Number(headers.get('X-Flow-Require-Captcha')));
};

export const isSubmitDisabled = (
  allowWhenPristine?: boolean,
  needsCaptchaToken?: boolean,
) => (formProps: FormRenderProps) => {
  const {
    dirtySinceLastSubmit,
    hasSubmitErrors,
    hasValidationErrors,
    invalid,
    pristine,
    submitting,
    validating,
  } = formProps;
  if (needsCaptchaToken) {
    return true;
  }

  let isInvalid = pristine || invalid;

  if (allowWhenPristine) {
    isInvalid = invalid && !pristine;
  }

  if (hasSubmitErrors && dirtySinceLastSubmit && !hasValidationErrors) {
    return false;
  }

  return Boolean(validating || submitting || isInvalid);
};

export const LoginForm = ({
  location,
  loginSuccess,
  mfaResponse,
  requestMfaCode,
  requestMfaState,
  resetRequestMfaCode,
  setPositionMfa,
}: Props) => {
  const [captchaToken, setCaptchaToken] = useState(undefined);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldUseSSO, setShouldUseSSO] = useState(false);
  const [step, setStep] = useState('email');
  const dispatch = useDispatch();
  const formRef = useRef();
  const isMethodStep = /method/.test(window.location.href);
  const isPasswordStep = /password/.test(window.location.href);
  const needsCaptchaToken = isCaptchaVisible && !captchaToken;
  const recaptchaRef = useRef();
  const redirectSearch = location.search ? location.search : '';
  const [userEmail, setUserEmail] = useState(undefined);

  useLayoutEffect(() => {
    if (isMethodStep) {
      setStep('method');
      return;
    }

    if (isPasswordStep) {
      setStep('password');
      return;
    }

    setStep('email');
  }, [dispatch, isMethodStep, isPasswordStep]);

  useEffect(() => {
    if (isMethodStep || isPasswordStep) {
      dispatch(push(`/login${redirectSearch}`));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldUseSSO) {
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true }));
    }
  }, [formRef, shouldUseSSO]);

  const checkIfMfaLogin = (response: MfaResponse) => {
    if (response.mfa) {
      setPositionMfa(response);

      if (!response.hasCode) {
        requestMfaCode(response.positionId);
      }

      return;
    }

    loginSuccess();
  };

  const getCustomMessage = () => {
    const { error, code } = parse(location.search);

    if (mfaResponse.mfa) {
      return getSecurityCodeText(mfaResponse.channelData);
    }

    const customMessages = getLoginMessages();
    if (code && customMessages[code]) {
      return customMessages[code];
    }

    const ssoError = getSSOMessages();
    switch (error) {
      case 'extension-disabled':
        return ssoError.extensionDisabled;
      case 'login-disabled':
        return ssoError.loginDisabled;
      case 'sso-invalid':
        return ssoError.ssoInvalid;
      case 'runtime-problems':
        return ssoError.runtimeProblems;
      default:
        return undefined;
    }
  };

  const onCancel = (formProps: FormRenderProps) => () => {
    setPositionMfa({});
    resetRequestMfaCode(mfaResponse.positionId);
    formProps.form.reset();
  };

  const getAccountLoginMethod = async (currentEmail: string) => {
    if (!currentEmail || !isValidEmail(currentEmail)) {
      return null;
    }

    setLoading(true);

    try {
      const response = await getLoginMethod({ email: currentEmail });
      const hasMultipleLoginMethods = response.loginMethods.length > 1;
      const loginMethod = hasMultipleLoginMethods ? 'multiple' : response.loginMethods[0];

      setLoading(false);
      return loginMethod;
    } catch {
      setLoading(false);
      return 'password';
    }
  };

  const getFooter = () => {
    if (!mfaResponse.mfa) {
      return undefined;
    }

    return (formProps: FormRenderProps) => (
      <LoginLink {...formProps} onClick={onCancel(formProps)} />
    );
  };

  const loginViaSSO = (formData: LoginCredentials) => {
    const resource = getResourceUrl(formData.loginMethod);

    return new Promise<void>(() => {
      if (!resource) {
        return;
      }

      window.location.assign(getSSOLoginPath(resource, formData.email, formData.isPersistent));
    });
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const resetCaptcha = () => {
    const { current } = recaptchaRef;

    setCaptchaToken(undefined);

    if (!current) {
      return;
    }

    current.reset();
  };

  const onFailure = (error: APIError | ServerError) => {
    const errorCode = get(error, 'body.api_error_code');
    const errorMessage = getErrorMessage(errorCode);

    resetCaptcha();

    if (errorRequiresCaptchaHeader(error)) {
      setIsCaptchaVisible(true);
    }

    if (!errorMessage) {
      return {};
    }

    if (mfaResponse.mfa) {
      return {
        securityCode: errorMessage.headerText,
      };
    }

    return {
      password: errorMessage.headerText,
    };
  };

  const onSubmit = async (formData: LoginCredentials) => {
    if (!formData) {
      return null;
    }

    const loginMethod = await getAccountLoginMethod(formData.email);
    setUserEmail(formData.email);

    const data = {
      ...formData,
      loginMethod: shouldUseSSO ? 'sso' : loginMethod,
    };

    if (isSsoMethod(data.loginMethod)) {
      return loginViaSSO(data);
    }

    if (step === 'email') {
      if (data.loginMethod === 'multiple') {
        dispatch(push(`/login/method${redirectSearch}`));
      }

      if (data.loginMethod === 'password') {
        dispatch(push(`/login/password${redirectSearch}`));
      }

      return null;
    }

    return postLogin({
      email: formData.email,
      password: formData.password,
      isPersistent: Boolean(formData.isPersistent),
      securityCode: formData.securityCode,
      captchaResponseToken: captchaToken,
    })
      .then(checkIfMfaLogin)
      .catch(onFailure);
  };

  const renderCaptcha = () => {
    if (!isCaptchaVisible) {
      return null;
    }

    return (
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.RECAPTCHA_SITE_KEY}
        onChange={onCaptchaChange}
      />
    );
  };

  const renderLoginFields = (formProps: FormRenderProps) => {
    const currentEmail = get(formProps, 'values.email', undefined);

    return (
      <LoginFields
        currentEmail={currentEmail}
        redirectToPasswordStep={() => {
          dispatch(push(`/login/password${redirectSearch}`));
        }}
        setShouldUseSSO={setShouldUseSSO}
        step={step}
      />
    );
  };

  const requestSecurityCode = () => {
    requestMfaCode(mfaResponse.positionId);
  };

  const renderMfaFields = () => (
    <TwoFactorAuthentication
      requestMfaState={requestMfaState}
      onAuthenticationTokenRequest={requestSecurityCode}
    />
  );

  const renderInputFields = (formProps: FormRenderProps) => {
    if (mfaResponse.mfa) {
      return renderMfaFields();
    }

    return renderLoginFields(formProps);
  };

  const renderFields = (formProps: FormRenderProps) => (
    <>
      {renderInputFields(formProps)}
      {renderCaptcha()}
    </>
  );

  const renderSubmitText = () => {
    if (step === 'email') {
      return (
        <Message
          id="Continue"
          comment="Submit button text for the login page."
        />
      );
    }

    if (mfaResponse.mfa) {
      return (
        <Message
          id="Authenticate"
          comment="Submit button text for the two-step authentication."
        />
      );
    }

    return (
      <Message
        id="Log in"
        comment="Submit button text for the login page."
      />
    );
  };

  const getSubheader = () => {
    if (step === 'email') {
      return null;
    }
    return userEmail;
  };

  return (
    <PublicForm
      buttonClass={style.Button}
      customMessage={getCustomMessage()}
      footer={getFooter()}
      formRef={formRef}
      hideButton={step === 'method'}
      onSubmit={onSubmit}
      initialValues={{
        email: location.initialEmail,
        loginMethod: null,
      }}
      submitText={renderSubmitText()}
      isLoading={loading}
      isSubmitDisabled={isSubmitDisabled(!mfaResponse.mfa, needsCaptchaToken)}
      title={(
        <Message
          id="Log in to your Oneflow account"
          comment="Login page header."
        />
      )}
      subheader={getSubheader()}
    >
      {renderFields}
    </PublicForm>
  );
};
