import type { DefaultRootState } from 'react-redux';
import sortBy from 'lodash/sortBy';
import isNumber from 'lodash/isNumber';

// eslint-disable-next-line import/named
import { SHARED_WITH_ME_ID } from 'oneflow-client/agreements';

import { checkAcl } from 'components/acl';
import normalizeEntity from 'normalized-redux/entity-normalizer';
import { isStateTemplate } from 'agreement';

export { SHARED_WITH_ME_ID };

export const isAValidWorkspaceId = (id: number) => (
  Number.isInteger(id) && (id === SHARED_WITH_ME_ID || id > 0)
);

export const initialEntities = {
  [SHARED_WITH_ME_ID]: {
    id: SHARED_WITH_ME_ID,
    name: '__shared_me_with',
    virtual: true,
    acl: {
      'collection:module:agreement:use': 'allow',
    },
  },
};

const rpcs = [
  'updateWorkspaceBranding',
  'updateDataRetention',
  'getDataRetention',
  'getAiInsights',
  'getWorkspacePlaybook',
  'updateWorkspacePlaybook',
  'createWorkspacePlaybook',
  'getAiInsightsContractList',
] as const;

const normalizedWorkspaces = normalizeEntity<Oneflow.Workspace, 'workspace', typeof rpcs>({
  entity: 'workspace',
  initialEntities,
  rpcs,
});

const isOtherWorkspace = (currentWorkspaceId?: Oneflow.Workspace['id']) => (
  (workspace: Oneflow.Workspace) => !workspace.virtual && workspace.id !== currentWorkspaceId
);

const hasPermission = (permission: string) => (
  (workspace: Oneflow.Workspace) => checkAcl(workspace.acl, permission)
);

const getWorkspaces = (state: DefaultRootState) => {
  const { getAllWorkspacesSelector } = normalizedWorkspaces;
  const allWorkspaces = getAllWorkspacesSelector(state);

  return sortBy(allWorkspaces, (workspace) => workspace.name.toLowerCase());
};

const getOtherWorkspaces = (state: DefaultRootState, workspaceId: Oneflow.Workspace['id']) => {
  const { getAllWorkspacesSelector } = normalizedWorkspaces;
  const allWorkspaces = getAllWorkspacesSelector(state);
  const otherWorkspaces = allWorkspaces.filter(isOtherWorkspace(workspaceId));

  return sortBy(otherWorkspaces, (workspace) => workspace.name.toLowerCase());
};

export const getAllWorkspaces = (state: DefaultRootState) => getWorkspaces(state)
  .filter((workspace) => !workspace.virtual);

export const getAllShareToWorkspaces = (state: DefaultRootState) => {
  const { currentWorkspaceId } = state.app;

  return getOtherWorkspaces(state, currentWorkspaceId)
    .filter(hasPermission('collection:agreement:template:create'));
};

export const getAllMoveToWorkspaces = (
  state: DefaultRootState,
  workspaceId: Oneflow.Workspace['id'],
  agreementState?: Oneflow.Agreement['state'],
) => {
  let moveAcl = 'collection:agreement:move';

  if (isNumber(agreementState) && isStateTemplate(agreementState)) {
    moveAcl = 'collection:agreement:template:move';
  }

  return getOtherWorkspaces(state, workspaceId).filter(hasPermission(moveAcl));
};

export default normalizedWorkspaces;
