// @flow

import React from 'react';

import Svg from 'components/svg';

const Add = Svg((
  <path
    d="M9 1.5V16.5M1.5 9H16.5"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), {
  viewBox: '0 0 18 18',
  fill: 'none',
  height: '18',
  width: '18',
});

Add.displayName = 'Add';

export default Add;
