// @flow

import React from 'react';

import Svg from 'components/svg';

const LoadPreviousReplies = Svg((
  <path d="M229.75122,173.55273c-.03271.03418-.06055.07081-.094.104l-48,48a8.00018,8.00018,0,0,1-11.31446-11.31348L204.68652,176H88A64,64,0,0,1,88,48h88a8,8,0,0,1,0,16H88a48,48,0,0,0,0,96H204.68652l-34.34375-34.34326a8.00018,8.00018,0,1,1,11.31446-11.31348l48,48c.03051.03028.05615.064.08593.09522.15284.15771.30225.31836.44165.48828.0857.10449.16065.21386.24024.32129.07544.102.15454.20117.22534.30664.08276.12353.155.25195.23022.3789.05762.09717.1189.1919.17237.29151.06811.12744.12646.25879.18725.38916.05.10644.10352.21142.14893.3208.05225.126.09448.25439.13989.38232.04248.11866.08814.23584.125.35694.03882.12841.06763.25879.09986.38916.03076.123.06567.24511.09057.3706.03.15137.04859.3042.06983.457.01464.106.03515.20948.04565.3169a8.02276,8.02276,0,0,1,0,1.584c-.0105.10742-.031.21094-.04565.3169-.02124.15283-.0398.30566-.06983.457-.0249.12549-.05981.24756-.09057.3706-.03223.13037-.061.26075-.09986.38916-.03686.1211-.08252.23828-.125.35694-.04541.12793-.08764.25634-.13989.38232-.04541.10938-.09888.21436-.14893.3208-.06079.13037-.11914.26172-.18725.38916-.05347.09961-.11475.19434-.17237.29151-.07519.127-.14746.25537-.23022.3789-.0708.10547-.1499.20459-.22534.30664-.07959.10743-.15454.2168-.24024.32129C230.04785,173.23975,229.90088,173.398,229.75122,173.55273Z" />
), { viewBox: '0 0 256 256' });

LoadPreviousReplies.displayName = 'LoadPreviousReplies';

export default LoadPreviousReplies;
