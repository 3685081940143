import Svg from 'components/svg';

const Stop = Svg((
  <>
    <path d="M8.99564 18.0001C13.9285 18.0001 18 13.9267 18 9.00048C18 4.07422 13.9197 0.000915527 8.98694 0.000915527C4.06283 0.000915527 0 4.07422 0 9.00048C0 13.9267 4.07153 18.0001 8.99564 18.0001Z" fill="#D6DFE2" />
    <rect x="5" y="5" width="8" height="8" rx="1" fill="#587A85" />
  </>

), { viewBox: '0 0 18 18', fill: 'none' });

Stop.displayName = 'Stop';

export default Stop;
