// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
};

export const ContractNameField = ({ message, autoFocus }: Props) => (
  <Field
    name="name"
    autoFocus={autoFocus}
    label={String(message({
      id: 'Name',
      comment: 'Label for contract name field.',
    }))}
    component={TextField}
    placeholder={message({
      id: 'Enter name',
      comment: 'Placeholder for contract name field.',
    })}
    maxLength={130}
  />
);

ContractNameField.defaultProps = {
  autoFocus: undefined,
};

export default localize<Props>(ContractNameField);
