// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

type MaxLength = ({
  message: MessageTranslator,
  customMessage?: string,
  field: string,
  maxLength: number,
}) => Validator;

const maxLengthValidator: MaxLength = ({
  message, customMessage, field, maxLength,
}) => (value, _, meta) => {
  if (meta && !meta.dirty) {
    return undefined;
  }

  const text = customMessage || message({
    id: '{field} must contain at most 1 character',
    pluralId: '{field} must contain at most {maxLength} characters',
    pluralCondition: 'maxLength',
    values: {
      field,
      maxLength,
    },
    comment: 'Used as the maxLength validator message',
  });

  if (value === undefined || value === null) {
    return undefined;
  }

  if (value.length > maxLength) {
    return text;
  }

  return undefined;
};

export default maxLengthValidator;
