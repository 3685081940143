import Svg from 'components/svg';

const Collaboration = Svg((
  <>
    <path
      d="M42 28.9446C43.4786 28.9461 44.9243 28.509 46.1543 27.6885C47.3843 26.868 48.3432 25.7011 48.9097 24.3354C49.4762 22.9697 49.6249 21.4666 49.3368 20.0164C49.0487 18.5662 48.3369 17.2341 47.2914 16.1886C46.2459 15.1431 44.9138 14.4313 43.4636 14.1432C42.0134 13.8551 40.5103 14.0038 39.1446 14.5703C37.7789 15.1368 36.612 16.0957 35.7915 17.3257C34.971 18.5557 34.5339 20.0014 34.5354 21.48C34.5374 23.4591 35.3245 25.3566 36.724 26.756C38.1234 28.1555 40.0209 28.9426 42 28.9446Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M51.3385 47.5988V42.0004C51.2688 39.571 50.2548 37.2646 48.5118 35.5709C46.7688 33.8772 44.4342 32.9297 42.0039 32.9297C39.5735 32.9297 37.2389 33.8772 35.4959 35.5709C33.753 37.2646 32.7389 39.571 32.6693 42.0004V47.5988H36.4016L38.2677 62.5358H45.7323L47.5985 47.5988H51.3385Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M23.3385 32.6696C24.4457 32.6696 25.5281 32.3412 26.4488 31.7261C27.3695 31.1109 28.087 30.2365 28.5108 29.2136C28.9345 28.1906 29.0454 27.0649 28.8293 25.9789C28.6133 24.8929 28.0801 23.8954 27.2972 23.1124C26.5142 22.3295 25.5167 21.7963 24.4307 21.5802C23.3447 21.3642 22.219 21.4751 21.196 21.8988C20.173 22.3225 19.2987 23.0401 18.6835 23.9608C18.0683 24.8814 17.74 25.9639 17.74 27.0711C17.74 28.5559 18.3298 29.9799 19.3797 31.0298C20.4297 32.0798 21.8537 32.6696 23.3385 32.6696Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M26.3258 36.892C24.9217 36.4197 23.4254 36.2879 21.9603 36.5076C20.4952 36.7273 19.1034 37.2921 17.8995 38.1554C16.6956 39.0188 15.7142 40.1559 15.0362 41.4731C14.3582 42.7903 14.003 44.2498 14 45.7313V47.5975H17.74L19.6061 58.7944H27.0708L28.0077 53.1806"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M60.6692 32.6696C61.7758 32.6665 62.8567 32.3356 63.7753 31.7186C64.694 31.1016 65.4091 30.2262 65.8305 29.2029C66.2519 28.1797 66.3606 27.0545 66.1428 25.9695C65.9251 24.8845 65.3907 23.8884 64.6071 23.107C63.8235 22.3256 62.826 21.7939 61.7404 21.5791C60.6548 21.3643 59.5299 21.4761 58.5078 21.9003C57.4858 22.3245 56.6123 23.042 55.9978 23.9624C55.3833 24.8827 55.0554 25.9645 55.0554 27.0711C55.0554 27.8076 55.2007 28.5369 55.483 29.2171C55.7653 29.8973 56.179 30.5152 56.7005 31.0353C57.222 31.5553 57.841 31.9674 58.522 32.2478C59.203 32.5283 59.9327 32.6716 60.6692 32.6696Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M57.6818 36.8929C59.0837 36.4246 60.5769 36.296 62.0383 36.5177C63.4997 36.7395 64.8875 37.3052 66.0873 38.1684C67.2872 39.0316 68.2649 40.1675 68.9398 41.4825C69.6147 42.7975 69.9675 44.2541 69.9692 45.7322V47.5984H66.2676L64.4015 58.7953H56.9369L55.9999 53.1815"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M14 62.5039V64.4008C14 67.4726 26.5409 69.9992 42 69.9992C57.4591 69.9992 70 67.4957 70 64.4008V62.5039"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </>
), { viewBox: '0 0 84 84', fill: 'none' });

Collaboration.displayName = 'Collaboration';

export default Collaboration;
