// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19959');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M4.755 8.003L9.241 10.246M0.5 7C0.5 7.59674 0.737053 8.16903 1.15901 8.59099C1.58097 9.01295 2.15326 9.25 2.75 9.25C3.34674 9.25 3.91903 9.01295 4.34099 8.59099C4.76295 8.16903 5 7.59674 5 7C5 6.40326 4.76295 5.83097 4.34099 5.40901C3.91903 4.98705 3.34674 4.75 2.75 4.75C2.15326 4.75 1.58097 4.98705 1.15901 5.40901C0.737053 5.83097 0.5 6.40326 0.5 7V7ZM9 11.25C9 11.8467 9.23705 12.419 9.65901 12.841C10.081 13.2629 10.6533 13.5 11.25 13.5C11.8467 13.5 12.419 13.2629 12.841 12.841C13.2629 12.419 13.5 11.8467 13.5 11.25C13.5 10.6533 13.2629 10.081 12.841 9.65901C12.419 9.23705 11.8467 9 11.25 9C10.6533 9 10.081 9.23705 9.65901 9.65901C9.23705 10.081 9 10.6533 9 11.25V11.25ZM9 2.75C9 3.34674 9.23705 3.91903 9.65901 4.34099C10.081 4.76295 10.6533 5 11.25 5C11.8467 5 12.419 4.76295 12.841 4.34099C13.2629 3.91903 13.5 3.34674 13.5 2.75C13.5 2.15326 13.2629 1.58097 12.841 1.15901C12.419 0.737053 11.8467 0.5 11.25 0.5C10.6533 0.5 10.081 0.737053 9.65901 1.15901C9.23705 1.58097 9 2.15326 9 2.75V2.75ZM4.757 5.996L9.241 3.754L4.757 5.996Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="14"
            height="14"
            fill="white"
          />
        </clipPath>
      </defs>
    </>
  );
};

const Share = Svg(Fragment, { viewBox: '0 0 14 14', fill: 'none' });

Share.displayName = 'Share';

export default Share;
