// @flow

import * as React from 'react';

import isFunction from 'lodash/isFunction';

import type { Validator } from './index';

export type UniqueParams = {
  text?: any,
  param: string,
  request: ({ params?: {} }, values: {}, meta: any) => any | Promise<any>,
  initialValue?: any,
  validationErrorMessage: React.Node,
};

export type Unique = UniqueParams => Validator;

export const getReturnValue = (text: any, entity: any) => (
  isFunction(text) ? text(entity) : text
);

const unique: Unique = ({
  text,
  param,
  request,
  initialValue,
  validationErrorMessage,
  /**
   * Has to be debounced with `debounce-promise` package
   * Won't have meta if debounced
   * reason: https://github.com/final-form/final-form/blob/master/src/FinalForm.js#L299
   * Debounced functions return 0 in the length property
   */
}) => async (value, values, meta) => {
  if ((initialValue && value === initialValue)
    || !value) {
    return undefined;
  }

  try {
    const result = await request({ params: { [param]: value } }, values, meta);

    if (result && result.hasAnyEntity) {
      return getReturnValue(text, result.entity[0]);
    }

    if (result) {
      return text;
    }
  } catch (error) {
    return validationErrorMessage;
  }

  return undefined;
};

export default unique;
