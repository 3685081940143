// @flow

import React from 'react';

import Svg from 'components/svg';

const VideoSizeMedium = Svg((
  <>
    <mask id="path-1-inside-1_1362_13838" fill="white">
      <rect x="4.99658" y="6.5" width="16" height="12" rx="0.901854" />
    </mask>
    <rect x="4.99658" y="6.5" width="16" height="12" rx="0.901854" fill="currentColor" stroke="currentColor" strokeWidth="2" mask="url(#path-1-inside-1_1362_13838)" />
    <mask id="path-2-inside-2_1362_13838" fill="white">
      <rect x="0.996582" y="0.5" width="24" height="24" rx="1" />
    </mask>
    <rect x="0.996582" y="0.5" width="24" height="24" rx="1" stroke="currentColor" strokeWidth="4" mask="url(#path-2-inside-2_1362_13838)" />
  </>
), {
  viewBox: '0 0 25 25',
  fill: 'none',
});

VideoSizeMedium.displayName = 'VideoSizeMedium';

export default VideoSizeMedium;
