// @flow

import React from 'react';

import Svg from 'components/svg';

const SearchRemove = Svg((
  <g>
    <path d="M12.932,13.682c-0.2,0-0.389-0.078-0.53-0.22L9.75,10.811l-2.652,2.652c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22c-0.292-0.292-0.292-0.768,0-1.061L8.69,9.75L6.038,7.098c-0.142-0.141-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53c0.141-0.142,0.33-0.22,0.53-0.22l0,0c0.2,0,0.389,0.078,0.53,0.22L9.75,8.689l2.652-2.652c0.142-0.142,0.33-0.22,0.53-0.22l0,0c0.2,0,0.389,0.078,0.53,0.22c0.142,0.141,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53L10.811,9.75l2.652,2.652c0.292,0.292,0.292,0.768,0,1.061C13.321,13.604,13.132,13.682,12.932,13.682z" />
    <path d="M23.25,24c-0.2,0-0.389-0.078-0.53-0.22l-6.519-6.52c-1.777,1.528-4.031,2.366-6.388,2.366c-5.411,0-9.813-4.402-9.814-9.812C-0.001,4.403,4.4,0.001,9.811,0c5.411,0,9.814,4.402,9.814,9.812c0,2.356-0.837,4.61-2.364,6.387l6.52,6.521c0.142,0.142,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53S23.451,24,23.25,24zM9.813,1.5c-2.222,0-4.31,0.865-5.88,2.435c-1.57,1.57-2.434,3.658-2.434,5.879c0,2.22,0.865,4.308,2.435,5.878c1.57,1.57,3.658,2.434,5.878,2.434c2.214,0,4.295-0.86,5.864-2.42c0.005-0.005,0.026-0.026,0.031-0.031c1.56-1.569,2.419-3.65,2.418-5.863C18.125,5.229,14.397,1.5,9.813,1.5L9.813,1.5z" />
  </g>
));

SearchRemove.displayName = 'SearchRemove';

export default SearchRemove;
