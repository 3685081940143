// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19924');

  return (
    <>
      <g clipPath={`url(#${id})`}>
        <rect x="1.65" y="1.65" width="6.7" height="6.7" stroke="currentColor" strokeWidth="1.3" />
        <rect x="11.65" y="11.65" width="6.7" height="6.7" stroke="currentColor" strokeWidth="1.3" />
        <rect width="7.11677" height="7.11677" transform="translate(5.03223 10) rotate(45)" fill="currentColor" />
        <path d="M15 1L15 9" stroke="currentColor" strokeWidth="1.3" />
        <path d="M19 5L11 5" stroke="currentColor" strokeWidth="1.3" />
      </g>
      <defs>
        <clipPath id="clip0_20080_5067">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const Marketplace = Svg(Fragment, { viewBox: '0 0 20 20', fill: 'none' });

Marketplace.displayName = 'Marketplace';

export default Marketplace;
