// @flow

import React from 'react';

import Svg from 'components/svg';

const ToggleOff = Svg((
  <path fill="currentColor" d="M 6 9.6 C 7.326 9.6 8.4 10.674 8.4 12 C 8.4 13.326 7.326 14.4 6 14.4 C 4.674 14.4 3.6 13.326 3.6 12 C 3.6 10.674 4.674 9.6 6 9.6 M 18 6 C 21.313 6 24 8.687 24 12 C 24 15.313 21.313 18 18 18 L 6 18 C 2.687 18 0 15.313 0 12 C 0 8.687 2.687 6 6 6 L 18 6 M 6 8.4 C 4.012 8.4 2.4 10.012 2.4 12 C 2.4 13.988 4.012 15.6 6 15.6 L 18 15.6 C 19.988 15.6 21.6 13.988 21.6 12 C 21.6 10.012 19.988 8.4 18 8.4 L 6 8.4 Z" />
));

ToggleOff.displayName = 'ToggleOff';

export default ToggleOff;
