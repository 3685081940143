// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

type MinValueValidator = ({
  message: MessageTranslator,
  field: string,
  text?: string,
  limit: number,
}) => Validator;

const disallowedValues = [
  '',
  null,
  'Infinity',
  '-Infinity',
];

const minValueValidator: MinValueValidator = ({
  message, field, text, limit,
}) => (value: string) => {
  if (value === undefined) {
    return undefined;
  }

  if (!disallowedValues.includes(value) && !Number.isNaN(Number(value)) && Number(value) >= limit) {
    return undefined;
  }

  return text || message({
    id: '{field} is not allowed to be below {limit}',
    values: {
      field,
      limit,
    },
    comment: 'Validation message for min value validator.',
  });
};

export default minValueValidator;
