// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19909');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M12.5 15.5V10.5M12.5 10.5L10.5 12.5M12.5 10.5L14.5 12.5M8 13C8 14.1935 8.47411 15.3381 9.31802 16.182C10.1619 17.0259 11.3065 17.5 12.5 17.5C13.6935 17.5 14.8381 17.0259 15.682 16.182C16.5259 15.3381 17 14.1935 17 13C17 11.8065 16.5259 10.6619 15.682 9.81802C14.8381 8.97411 13.6935 8.5 12.5 8.5C11.3065 8.5 10.1619 8.97411 9.31802 9.81802C8.47411 10.6619 8 11.8065 8 13Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M5.95453 15.5C4.73204 15.5 1.99993 15.5 0.500052 15.5C0.5 13 0.500029 3.50567 0.5 0.5C1.99993 0.5 7.21334 0.5 9 0.5L12.5 4V6.26923"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
        />
        <path
          d="M4.68075 9.50849C4.60241 9.5476 4.51538 9.56604 4.42792 9.56207C4.34045 9.55809 4.25545 9.53183 4.18099 9.48577C4.10652 9.43971 4.04507 9.37538 4.00245 9.29889C3.95984 9.2224 3.93748 9.13629 3.9375 9.04874V5.57624C3.93748 5.48868 3.95984 5.40257 4.00245 5.32608C4.04507 5.2496 4.10652 5.18527 4.18099 5.13921C4.25545 5.09315 4.34045 5.06688 4.42792 5.06291C4.51538 5.05893 4.60241 5.07737 4.68075 5.11649L8.154 6.85349C8.2393 6.89606 8.31104 6.96156 8.36119 7.04264C8.41133 7.12371 8.4379 7.21716 8.4379 7.31249C8.4379 7.40782 8.41133 7.50126 8.36119 7.58234C8.31104 7.66341 8.2393 7.72891 8.154 7.77149L4.68075 9.50849Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const VideoFileUpload = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

VideoFileUpload.displayName = 'VideoFileUpload';

export default VideoFileUpload;
