// @flow

import React from 'react';

import Svg from 'components/svg';

const ApprovalEye = Svg((
  <>
    <path
      d="M8.0001 9.60005C8.88375 9.60005 9.6001 8.8837 9.6001 8.00005C9.6001 7.11639 8.88375 6.40005 8.0001 6.40005C7.11644 6.40005 6.4001 7.11639 6.4001 8.00005C6.4001 8.8837 7.11644 9.60005 8.0001 9.60005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00019 2.80005C4.47243 2.80005 2.3075 4.55906 0.496267 6.70063C0.177697 7.04611 0.000488281 7.49908 0.000488281 7.96965C0.000488281 8.44717 0.182968 8.90656 0.510471 9.2539C1.67844 10.5142 4.42632 13.2 8.00019 13.2C11.574 13.2 14.3219 10.5142 15.4899 9.2539C15.8174 8.90657 15.9999 8.44717 15.9999 7.96965C15.9999 7.49907 15.8227 7.0461 15.5041 6.70062C13.6929 4.55906 11.5279 2.80005 8.00019 2.80005ZM1.70587 7.74812C3.37534 5.77065 5.13654 4.40005 8.00019 4.40005C10.8638 4.40005 12.625 5.77065 14.2945 7.74812C14.3044 7.75987 14.3147 7.77134 14.3253 7.7825C14.3732 7.83303 14.3999 7.90001 14.3999 7.96965C14.3999 8.03928 14.3732 8.10627 14.3253 8.1568L14.3189 8.16357C13.1493 9.42611 10.8233 11.6 8.00019 11.6C5.17704 11.6 2.85106 9.42611 1.68146 8.16357L1.67511 8.1568C1.6272 8.10627 1.60049 8.03929 1.60049 7.96965C1.60049 7.90001 1.6272 7.83303 1.67511 7.7825C1.68569 7.77134 1.69595 7.75987 1.70587 7.74812Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 16 16', fill: 'none' });

ApprovalEye.displayName = 'ApprovalEye';

export default ApprovalEye;
