// @flow

import React from 'react';

import Svg from 'components/svg';

const Adjuster = Svg((
  <>
    <path d="M5 9C5 7.89543 4.10457 7 3 7C1.89543 7 1 7.89543 1 9C1 10.1046 1.89543 11 3 11C4.10457 11 5 10.1046 5 9Z" stroke="currentColor" fill="transparent" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6.23077L3 1" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M3 13.2308L3 12" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M7 5.23077C7 6.33534 7.89543 7.23077 9 7.23077C10.1046 7.23077 11 6.33534 11 5.23077C11 4.1262 10.1046 3.23077 9 3.23077C7.89543 3.23077 7 4.1262 7 5.23077Z" stroke="currentColor" fill="transparent" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 8L9 13.2308" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M9 1L9 2.23077" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
  </>
), { viewBox: '0 0 12 14' });

Adjuster.displayName = 'Adjuster';

export default Adjuster;
