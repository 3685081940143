// @flow

import React from 'react';

import Svg from 'components/svg';

const Edit = Svg((
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.349731 12.35H11.8039V13.65H0.349731V12.35Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9046 1.72962C10.9045 1.72965 10.9045 1.72967 10.9045 1.72969L4.85859 7.7756L4.56888 9.43386L6.22357 9.14153L12.27 3.09515C12.3212 3.04393 12.3499 2.97448 12.3499 2.90206C12.3499 2.82964 12.3212 2.76019 12.27 2.70897L11.2907 1.72969C11.2395 1.67849 11.17 1.64973 11.0976 1.64973C11.0252 1.64973 10.9558 1.67847 10.9046 1.72962ZM9.9854 0.810316C10.2804 0.515404 10.6805 0.349731 11.0976 0.349731C11.5147 0.349731 11.9148 0.515404 12.2098 0.810316L12.2098 0.810386L13.1893 1.7898L13.1893 1.78987C13.4842 2.08487 13.6499 2.48493 13.6499 2.90206C13.6499 3.31919 13.4842 3.71925 13.1893 4.01425L13.1893 4.01432L6.99797 10.2056C6.9037 10.2999 6.78272 10.3629 6.65144 10.3861L3.88211 10.8753C3.67341 10.9122 3.45984 10.845 3.30984 10.6953C3.15985 10.5456 3.09225 10.3321 3.12873 10.1234L3.61336 7.34943C3.63638 7.21768 3.69947 7.09624 3.79404 7.00168L9.98533 0.810386L9.9854 0.810316Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

Edit.displayName = 'Edit';

export default Edit;
