// @flow

import React from 'react';
import { Message as PomesMessage, type MessageProps } from '@oneflowab/pomes';

const Message = (props: MessageProps) => (
  <span style={{ color: 'inherit' }}>
    <PomesMessage {...props} />
  </span>
);

export default Message;
