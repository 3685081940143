export const enabledLanguages = {
  en: 'English',
  sv: 'Svenska',
  no: 'Norsk',
  da: 'Dansk',
  de: 'Deutsch',
  fi: 'Suomi',
  es: 'Español',
  fr: 'Français',
  nl: 'Nederlands',
  pt: 'Português',
  it: 'Italiano',
} as const;

export type EnabledLanguageCodes = keyof typeof enabledLanguages;

export const enabledLangCodes = Object.keys(enabledLanguages) as EnabledLanguageCodes[];
