// @flow

import navigator from 'utils/navigator';

export const isOpera = () => typeof window.opr !== 'undefined';
export const isMSEdgeLegacy = () => navigator.userAgent.indexOf('Edge') > -1;
export const isIE11 = () => !!window.MSInputMethodContext && !!document.documentMode;
export const isIOSChrome = () => Boolean(navigator.userAgent.match('CriOS'));
export const isIEMobile = () => navigator.userAgent.indexOf('IEMobile') > -1;
export const isMobileIOS = () => Boolean(navigator.userAgent.match(/iPad/i)) || Boolean(navigator.userAgent.match(/iPhone/i));

// https://stackoverflow.com/a/13348618/2877719
export const isChrome = () => {
  const isChromium = window.chrome;

  return isIOSChrome() || (
    isChromium !== null
    && typeof isChromium !== 'undefined'
    && navigator.vendor === 'Google Inc.'
    && isOpera() === false
    && isMSEdgeLegacy() === false
  );
};

export const isFirefox = () => navigator.userAgent.indexOf('Firefox') > -1;

export const isMobileBrowser = () => typeof window.orientation !== 'undefined' || isIEMobile();
export const isRecordingSupported = () => !isMobileBrowser() && (isChrome() || isFirefox());

/**
 * Checks if device has "fine pointer control" (i.e., a mouse or stylus) and can hover over
 * elements. It also checks if window.matchMedia is a function before calling it.
 * @returns {boolean}
 * - `true`: if the device doesn't have fine pointer controls or if window.matchMedia isn't
 * a function.
 * - `false`: if the device has fine pointer controls and window.matchMedia is a function.
 */ export const isDeviceWithoutHover = () => typeof window !== 'undefined'
  && typeof window.matchMedia === 'function'
  && !window.matchMedia('(hover: hover) and (pointer: fine)').matches;
