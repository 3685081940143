import { call, select } from 'redux-saga/effects';
import queryString from 'query-string';
import get from 'lodash/get';

import { getLocationSelector } from 'reducers/router';

export const redirectToLogin = (attemptedPath = '/', attemptedSearch = '') => {
  if (!attemptedSearch && attemptedPath === '/') {
    window.location.replace('/login');

    return;
  }

  if (!attemptedSearch) {
    window.location.replace(`/login?path=${attemptedPath}`);

    return;
  }

  const redirectSearch = queryString.stringify({
    path: attemptedPath + attemptedSearch,
  });
  window.location.replace(`/login?${redirectSearch}`);
};

/**
 * Redirects to the login page. Since window.location is asynchronous,
 * it returns an "unresolvable" Promise to the saga middleware,
 * giving time for the change to happen. Otherwise the code would continue
 * be executed and the setLoading(false) action would be fired.
 * It is supposed to be used like this:
 * @example
 * yield call(silentlyRedirectToLogin)
 */
export const silentlyRedirectToLogin = (attemptedPath = '/', attemptedSearch = '') => (
  new Promise(() => {
    redirectToLogin(attemptedPath, attemptedSearch);
  })
);

export function* checkIfAuthenticated(error) {
  if (get(error, 'response.status') === 401) {
    const location = yield select(getLocationSelector);

    yield call(silentlyRedirectToLogin, location.pathname, location.search);
  }
}
