// @flow

import React from 'react';

import Svg from 'components/svg';

const Video = Svg((
  <>
    <path d="M5 17.5H13M11.5 17.5H6.5L7.5 14H10.5L11.5 17.5ZM0.5 0.5H17.5V14H0.5V0.5Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" />
    <path d="M6.5 9.92685V5.07315C6.50001 4.97171 6.52853 4.87209 6.58267 4.78446C6.6368 4.69683 6.71461 4.62433 6.80813 4.57436C6.90166 4.5244 7.00756 4.49875 7.11502 4.50005C7.22249 4.50134 7.32766 4.52953 7.4198 4.58174L11.7047 7.00821C11.7947 7.0591 11.8693 7.13116 11.921 7.21735C11.9728 7.30354 12 7.40092 12 7.5C12 7.59908 11.9728 7.69646 11.921 7.78265C11.8693 7.86884 11.7947 7.9409 11.7047 7.99179L7.4198 10.4183C7.32766 10.4705 7.22249 10.4987 7.11502 10.5C7.00756 10.5012 6.90166 10.4756 6.80813 10.4256C6.71461 10.3757 6.6368 10.3032 6.58267 10.2155C6.52853 10.1279 6.50001 10.0283 6.5 9.92685V9.92685Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" />
  </>
), { viewBox: '0 0 18 18', fill: 'none' });

Video.displayName = 'Video';

export default Video;
