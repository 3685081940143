// @flow

import React from 'react';

import Svg from 'components/svg';

const ProfileIcon = Svg((
  <>
    <path
      d="M8.68701 10.9375C8.68701 11.8492 9.04918 12.7235 9.69383 13.3682C10.3385 14.0128 11.2128 14.375 12.1245 14.375C13.0362 14.375 13.9105 14.0128 14.5552 13.3682C15.1998 12.7235 15.562 11.8492 15.562 10.9375C15.562 10.0258 15.1998 9.15147 14.5552 8.50682C13.9105 7.86216 13.0362 7.5 12.1245 7.5C11.2128 7.5 10.3385 7.86216 9.69383 8.50682C9.04918 9.15147 8.68701 10.0258 8.68701 10.9375V10.9375Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 21.25C6.5 19.7582 7.09263 18.3274 8.14752 17.2725C9.20241 16.2176 10.6332 15.625 12.125 15.625C13.6168 15.625 15.0476 16.2176 16.1025 17.2725C17.1574 18.3274 17.75 19.7582 17.75 21.25"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="12"
      r="11"
      stroke="currentColor"
      strokeWidth="1.4"
    />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

ProfileIcon.displayName = 'ProfileIcon';

export default ProfileIcon;
