import { useEffect, useState } from 'react';

/**
 * A hook that returns the placement of the react-select dropdown menu based on if there is
 * enough space below the dropdown to render it below the trigger element.
 *
 * @param fieldName - The name of the field to get the placement for.
 * @returns The placement of the react-select dropdown menu.
 */
export function useReactSelectDropdownMenuPlacement(fieldName: string) {
  const [menuPositionForUsage, setMenuPositionForUsage] = useState('bottom');

  const determineMenuPlacement = () => {
    const threshold = 300; // Adjust as needed
    const usageElement = document.getElementById(`select-${fieldName}`);

    if (!usageElement) {
      return 'bottom';
    }

    const rect = usageElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;

    if (spaceBelow < threshold) {
      return 'top';
    }

    return 'bottom';
  };

  useEffect(() => {
    const element = document.body;

    const updateMenuPlacement = () => {
      const placement = determineMenuPlacement();
      setMenuPositionForUsage(placement);
    };

    const resizeObserver = new ResizeObserver(() => {
      updateMenuPlacement();
    });
    resizeObserver.observe(element);

    updateMenuPlacement();

    // Cleanup on unmount
    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
      resizeObserver.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return menuPositionForUsage;
}
