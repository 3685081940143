import Svg from 'components/svg';

const NewDownload = Svg((
  <>
    <path
      d="M1 10.75V11.8333C1 12.4079 1.21074 12.9591 1.58579 13.3654C1.96084 13.7718 2.46958 14 2.99998 14H12C12.5304 14 13.0391 13.7718 13.4142 13.3654C13.7893 12.9591 14 12.4079 14 11.8333V10.75M5.0625 7.5L7.49997 9.9375M7.49997 9.9375L9.9375 7.5M7.49997 9.9375L7.5 1"
      stroke="currentColor"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 16 16', fill: 'none' });

NewDownload.displayName = 'NewDownload';

export default NewDownload;
