// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_5097_183113');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M7.54788 60.125C7.3043 60.125 7.06688 60.088 6.83871 60.014C5.13055 59.8537 3.51796 59.1106 2.28771 57.9143C0.841628 56.5083 0.0307113 54.6213 -0.00012207 52.6048V6.9375C-0.00012207 3.11108 3.11096 0 6.93738 0H20.8124C24.6388 0 27.7499 3.11108 27.7499 6.9375V9.25H57.8124C61.6388 9.25 64.7499 12.3611 64.7499 16.1875V23.125H67.0624C70.8919 23.125 74.0061 26.2361 74.0061 30.0594C74.0061 30.6607 73.929 31.2619 73.7748 31.8447C73.5065 32.8591 72.5877 33.5652 71.5425 33.5652C71.3421 33.5652 71.1416 33.5374 70.9443 33.4881C69.7141 33.1612 68.9771 31.894 69.304 30.6607C69.3564 30.4633 69.3811 30.2629 69.3811 30.0625C69.3811 28.786 68.342 27.75 67.0686 27.75H24.2965C23.2729 27.75 22.3571 28.4376 22.0735 29.4212L14.8245 54.5627C14.7351 54.8833 14.6241 55.1947 14.4885 55.5H25.4405C26.717 55.5 27.753 56.536 27.753 57.8125C27.753 59.089 26.717 60.125 25.4405 60.125H7.54788ZM6.94046 4.625C5.66396 4.625 4.62796 5.661 4.62796 6.9375V52.5708C4.63721 53.3262 4.95479 54.0508 5.51288 54.5966C6.06171 55.1331 6.7863 55.426 7.55405 55.426C8.88296 55.4075 10.0238 54.5318 10.3753 53.2923L17.6273 28.1385C18.4814 25.1878 21.2225 23.125 24.2965 23.125H60.1311V16.1875C60.1311 14.911 59.0951 13.875 57.8186 13.875H25.4435C24.167 13.875 23.131 12.839 23.131 11.5625V6.9375C23.131 5.661 22.095 4.625 20.8185 4.625H6.94046Z"
          fill="#013A4C"
        />
        <path
          d="M53.1904 67.0625C55.1062 67.0625 56.6592 65.5095 56.6592 63.5938C56.6592 61.678 55.1062 60.125 53.1904 60.125C51.2747 60.125 49.7217 61.678 49.7217 63.5938C49.7217 65.5095 51.2747 67.0625 53.1904 67.0625Z"
          fill="#013A4C"
        />
        <path
          d="M53.1904 57.8125C51.9139 57.8125 50.8779 56.7765 50.8779 55.5V46.25C50.8779 44.9735 51.9139 43.9375 53.1904 43.9375C54.4669 43.9375 55.5029 44.9735 55.5029 46.25V55.5C55.5029 56.7765 54.4669 57.8125 53.1904 57.8125Z"
          fill="#013A4C"
        />
        <path
          d="M40.1603 74C35.8714 74 32.378 70.5097 32.3749 66.2208C32.3749 65.0183 32.6585 63.8127 33.1981 62.7335L46.2283 36.6763C47.5541 34.0215 50.2274 32.3719 53.2028 32.3719C54.4145 32.3719 55.58 32.6464 56.6685 33.1921C58.167 33.9414 59.4034 35.1778 60.1526 36.6763L73.1828 62.7335C75.1037 66.5692 73.5435 71.2559 69.7079 73.1737C68.6287 73.7164 67.4231 74 66.2206 74H40.1603ZM53.1997 37C51.991 37 50.9057 37.6691 50.3692 38.7483L37.339 64.8055C37.1201 65.2434 37.003 65.7305 37.003 66.2208C37.003 67.9629 38.4213 69.3781 40.1603 69.3781H66.2206C66.7078 69.3781 67.198 69.261 67.639 69.042C69.193 68.265 69.825 66.3626 69.048 64.8055L56.0179 38.7483C55.7126 38.1409 55.2131 37.6383 54.6057 37.333C54.1617 37.111 53.69 37 53.1997 37Z"
          fill="#013A4C"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="74"
            height="74"
            fill="white"
          />
        </clipPath>
      </defs>
    </>
  );
};

const FolderWarning = Svg(Fragment, { viewBox: '0 0 74 74', fill: 'none' });

FolderWarning.displayName = 'FolderWarning';

export default FolderWarning;
