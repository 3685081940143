// @flow

import React from 'react';

import Svg from 'components/svg';

const NewContractIcon = Svg((
  <>
    <g clipPath="url(#clip0_5541_1120)">
      <path
        d="M1.70944 21.6726L1.00144 9.62114C0.994673 9.50461 1.01177 9.38793 1.0517 9.27824C1.09163 9.16856 1.15353 9.06818 1.23363 8.98327C1.31372 8.89836 1.41032 8.83071 1.51749 8.78445C1.62466 8.7382 1.74015 8.71432 1.85687 8.71428H9.70659C9.89808 8.71395 10.0842 8.77776 10.2352 8.89551C10.3862 9.01327 10.4934 9.1782 10.5397 9.364L11.234 12.1429H22.3083C22.4266 12.1425 22.5436 12.1666 22.6521 12.2137C22.7606 12.2608 22.8581 12.3299 22.9386 12.4165C23.0191 12.5031 23.0809 12.6055 23.1199 12.7171C23.1589 12.8287 23.1744 12.9472 23.1654 13.0651L22.502 21.7034C22.4687 22.1348 22.2735 22.5377 21.9555 22.8311C21.6376 23.1246 21.2204 23.287 20.7877 23.2857H3.42201C2.98465 23.286 2.56369 23.1192 2.24532 22.8193C1.92696 22.5194 1.73525 22.1092 1.70944 21.6726Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.09131 5.28571V0.999973C7.53798 0.999976 22.377 1.00002 22.377 1.00002V8.71429"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M12.9482 5.28571H18.0911"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
  </>
), { viewBox: '0 0 26 26', fill: 'none' });

NewContractIcon.displayName = 'NewContractIcon';

export default NewContractIcon;
