// @flow

import React from 'react';
import { Message, localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Info from 'components/block-info';

type Props = {
  requestEmail: string,
  message: MessageTranslator,
};

export const ResetPasswordStartConfirmationComponent = ({ message, requestEmail }: Props) => (
  <Info
    title={message({
      id: 'Email sent',
      comment: 'Reset password page header. Used for confirming that the email has been sent.',
    })}
    paragraphs={[
      <Message
        id="An email has been sent to {email} with instructions on how to change your password."
        values={{
          email: <strong>{requestEmail}</strong>,
        }}
        comment="Reset password page body text. Used for describing which email the recovery mail has been sent to."
      />,
      message({
        id: "If you don't receive the instructions within a few minutes, please check your spam folder.",
        comment: 'Reset password page body text. Used to explain that you should recive the email shortly.',
      }),
    ]}
  />
);

export default localize<Props>(ResetPasswordStartConfirmationComponent);
