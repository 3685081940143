import normalizeEntity from 'normalized-redux/entity-normalizer';

const rpcs = [
  'resendInvitation',
  'updateSettings',
  'revokeTrustedDevices',
] as const;

const normalizedPositions = normalizeEntity<Oneflow.Position, 'position', typeof rpcs>({
  entity: 'position',
  rpcs,
});

export default normalizedPositions;
