// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19911');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M16.6161 4.67067L13 6C13 4.98527 13 4 13 3C11.5 3 2.96417 3 0.500073 3C0.50004 5.43637 0.5 13 0.5 15C4.66667 15 8.83333 15 13 15C13 13.89 13 13.1137 13 12L16.6161 13.3293C16.715 13.3673 16.8215 13.3802 16.9264 13.367C17.0313 13.3538 17.1315 13.3148 17.2184 13.2535C17.3053 13.1921 17.3763 13.1102 17.4254 13.0147C17.4744 12.9193 17.5001 12.8131 17.5001 12.7053V5.29467C17.5001 5.1869 17.4744 5.08073 17.4254 4.98527C17.3763 4.8898 17.3053 4.80789 17.2184 4.74654C17.1315 4.6852 17.0313 4.64625 16.9264 4.63303C16.8215 4.61982 16.715 4.63273 16.6161 4.67067Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const MeetingCamera = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

MeetingCamera.displayName = 'MeetingCamera';

export default MeetingCamera;
