import Svg from 'components/svg';

export const CheckCircleLight = Svg((
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 12.7C10.148 12.7 12.7 10.148 12.7 7C12.7 3.85198 10.148 1.3 7 1.3C3.85198 1.3 1.3 3.85198 1.3 7C1.3 10.148 3.85198 12.7 7 12.7ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9177 4.9486L6.54599 9.83852C6.53511 9.8507 6.52376 9.86246 6.51199 9.87379C6.39718 9.98421 6.26246 10.0637 6.12321 10.1148C5.98379 10.166 5.83663 10.1901 5.69125 10.1884C5.54589 10.1867 5.39909 10.1591 5.26057 10.1043C5.12223 10.0495 4.98902 9.96608 4.8769 9.85153C4.85541 9.82957 4.83551 9.80612 4.81733 9.78136L3.09131 7.42873L4.13947 6.65974L5.71915 8.81289L9.94853 4.08215L10.9177 4.9486Z" fill="currentColor" />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

CheckCircleLight.displayName = 'CheckCircleLight';
