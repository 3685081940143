// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_20060');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M0.5 1H9.5V9H0.5V1Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M0.5 2L4.355 5.253C4.53542 5.40528 4.7639 5.48882 5 5.48882C5.2361 5.48882 5.46458 5.40528 5.645 5.253L9.5 2"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </>

  );
};

const EnvelopeBold = Svg(Fragment, { viewBox: '0 0 10 10', fill: 'none' });

EnvelopeBold.displayName = 'EnvelopeBold';

export default EnvelopeBold;
