// @flow

import React from 'react';

import Svg from 'components/svg';

const AlignToCenter = Svg((
  <>
    <path d="M4.25049 6.5H20.2505V8.5H4.25049V6.5Z" fill="currentColor" />
    <path d="M8.25049 11.5H16.2505V13.5H8.25049V11.5Z" fill="currentColor" />
    <path d="M6.25049 16.5H18.2505V18.5H6.25049V16.5Z" fill="currentColor" />
    <path d="M1.25049 1.8H23.2505V-0.8H1.25049V1.8ZM22.9505 1.5V23.5H25.5505V1.5H22.9505ZM23.2505 23.2H1.25049V25.8H23.2505V23.2ZM1.55049 23.5V1.5H-1.04951V23.5H1.55049ZM1.25049 23.2C1.41617 23.2 1.55049 23.3343 1.55049 23.5H-1.04951C-1.04951 24.7703 -0.0197654 25.8 1.25049 25.8V23.2ZM22.9505 23.5C22.9505 23.3343 23.0848 23.2 23.2505 23.2V25.8C24.5207 25.8 25.5505 24.7703 25.5505 23.5H22.9505ZM23.2505 1.8C23.0848 1.8 22.9505 1.66569 22.9505 1.5H25.5505C25.5505 0.229744 24.5207 -0.8 23.2505 -0.8V1.8ZM1.25049 -0.8C-0.0197673 -0.8 -1.04951 0.229746 -1.04951 1.5H1.55049C1.55049 1.66569 1.41617 1.8 1.25049 1.8V-0.8Z" fill="currentColor" />
  </>
), {
  viewBox: '0 0 25 25',
  fill: 'none',
});

AlignToCenter.displayName = 'AlignToCenter';

export default AlignToCenter;
