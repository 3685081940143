import Svg from 'components/svg';

const ProfileIcon = Svg((
  <>
    <path
      d="M15.2023 19.1406C15.2023 20.7361 15.8361 22.2661 16.9642 23.3944C18.0924 24.5224 19.6224 25.1562 21.2179 25.1562C22.8134 25.1562 24.3434 24.5224 25.4716 23.3944C26.5997 22.2661 27.2335 20.7361 27.2335 19.1406C27.2335 17.5451 26.5997 16.0151 25.4716 14.8869C24.3434 13.7588 22.8134 13.125 21.2179 13.125C19.6224 13.125 18.0924 13.7588 16.9642 14.8869C15.8361 16.0151 15.2023 17.5451 15.2023 19.1406Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.375 37.1875C11.375 34.5769 12.4121 32.0729 14.2582 30.2269C16.1042 28.3808 18.6081 27.3438 21.2188 27.3438C23.8294 27.3438 26.3333 28.3808 28.1794 30.2269C30.0255 32.0729 31.0625 34.5769 31.0625 37.1875"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 40.25C31.6315 40.25 40.25 31.6315 40.25 21C40.25 10.3685 31.6315 1.75 21 1.75C10.3685 1.75 1.75 10.3685 1.75 21C1.75 31.6315 10.3685 40.25 21 40.25Z"
      stroke="currentColor"
      strokeWidth="1.16667"
    />
  </>
), { viewBox: '0 0 42 42', fill: 'none' });

ProfileIcon.displayName = 'ProfileThinIcon';

export default ProfileIcon;
