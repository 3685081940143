import React from 'react';

import Svg from 'components/svg';

const AiSparkle = Svg((
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.4 2.26667L11.55 0L10.7 2.26667L9.1 2.8L10.7 3.33333L11.55 5.6L12.4 3.33333L14 2.8L12.4 2.26667ZM11 10.6667L10.15 8.4L9.3 10.6667L7.7 11.2L9.3 11.7333L10.15 14L11 11.7333L12.6 11.2L11 10.6667ZM5.92162 5.24054L4.55 1.4L3.17838 5.24054L0 6.3L3.17838 7.35946L4.55 11.2L5.92162 7.35946L9.1 6.3L5.92162 5.24054Z" fill="currentColor" />
  </>
), {
  viewBox: '0 0 14 14',
  fill: 'none',
});

AiSparkle.displayName = 'AiSparkle';

export default AiSparkle;
