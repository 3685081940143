// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19910');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M0.5 0.5H6.5V17.5H0.5V0.5ZM11.5 0.5H17.5V17.5H11.5V0.5Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const ControlPause = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

ControlPause.displayName = 'ControlPause';

export default ControlPause;
