import { combineReducers } from 'redux';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';
import type { Reducer } from 'redux';

import { createReducer, formatActionType } from 'normalized-redux/reducers';
import { Entity, Pipe } from 'normalized-redux/entity-normalizer/types';
import { FormattedActionType } from 'normalized-redux/reducers/format-action-type';

export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
? U extends Uncapitalize<U>
  ? `${Uppercase<T>}${CamelToSnakeCase<U>}`
  : `${Uppercase<T>}_${CamelToSnakeCase<U>}`
: S;

type State<RPCs extends readonly string[]> = {
  [rpcName in RPCs[number]]: {
    [key in Entity['id']]: {
      pristine: boolean,
      id?: Entity['id'],
      data: Record<string, any>
      success: boolean,
      loading: boolean,
      error?: boolean,
    }
  }
};

type Action<T extends string = string> = {
  type: T,
};

type RPCStartAction<RPCs extends readonly string[], T extends string = string> = Action<T> & {
  id: Entity['id'],
  data: State<RPCs>[string][Entity['id']]['data'],
  pipe?: Pipe,
};

type RPCSuccessAction<T extends string = string> = Action<T> & {
  id: Entity['id'],
};

type RPCFailAction<T extends string = string> = Action<T> & {
  id: Entity['id'],
  error: any,
};

type RPCResetAction<T extends string = string> = Action<T> & {
  id: Entity['id'],
};

export const initialRpcState: State<string[]>[string][Entity['id']] = {
  pristine: true,
  id: undefined,
  data: {},
  success: false,
  loading: false,
  error: undefined,
};

export const initialState = {};

type CreateRpcStartActionArgs = {
  id: Entity['id'],
  data?: Record<string, any>,
  pipe?: Pipe,
};

// actions
export const createRpcStartAction = <
  T extends string
  >(type: T) => ({ id, data, pipe }: CreateRpcStartActionArgs) => ({
    type,
    id,
    data,
    pipe,
  });

type CreateRpcSuccessActionArgs = {
  id: Entity['id'],
};

export const createRpcSuccessAction = <
  T extends string
>(type: T) => ({ id }: CreateRpcSuccessActionArgs) => ({
    type,
    id,
  });

type CreateRpcFailActionArgs = {
  id: Entity['id'],
  error: any,
};

export const createRpcFailAction = <
  T extends string
>(type: T) => ({ id, error }: CreateRpcFailActionArgs) => ({
    type,
    id,
    error,
  });

type CreateRpcResetActionArgs = {
  id: Entity['id'],
};

export const createRpcResetAction = <
  T extends string
>(type: T) => ({ id }: CreateRpcResetActionArgs) => ({
    type,
    id,
  });

// handlers
export const rpcStartHandler = <
    RPCs extends readonly string[],
    T extends string
  >(state: State<RPCs>[string], action: RPCStartAction<RPCs, T>) => ({
    ...state,
    [action.id]: {
      pristine: false,
      id: action.id,
      data: action.data,
      loading: true,
      success: false,
      error: undefined,
    },
  });

export const rpcSuccessHandler = <
  RPCs extends readonly string[],
  T extends string
>(state: State<RPCs>[string], action: RPCSuccessAction<T>) => ({
    ...state,
    [action.id]: {
      ...initialRpcState,
      ...state[action.id],
      pristine: false,
      id: action.id,
      success: true,
      loading: false,
      error: undefined,
    },
  });

export const rpcFailHandler = <
    RPCs extends readonly string[],
    T extends string
  >(state: State<RPCs>[string], action: RPCFailAction<T>) => ({
    ...state,
    [action.id]: {
      ...initialRpcState,
      ...state[action.id],
      id: action.id,
      pristine: false,
      success: false,
      loading: false,
      error: action.error,
    },
  });

export const rpcResetHandler = <
  T extends string,
  RPCs extends readonly string[],
>(state: State<RPCs>[string], action: RPCResetAction<T>) => ({
    ...state,
    [action.id]: {
      ...initialRpcState,
    },
  });

// selectors
type CreateRpcSelectorArgs = {
  id: Entity['id'],
};
export const createRpcSelector = <RPCs extends readonly string[]>(
  rpcName: RPCs[number],
) => (state: State<RPCs>, { id }: CreateRpcSelectorArgs) => {
    if (!state[rpcName][id]) {
      return initialRpcState;
    }

    return state[rpcName][id];
  };
type FormattedRPCStartActionType<Key extends string, RPCActionName extends string> = FormattedActionType<Key, `${RPCActionName}_START`>;
type FormattedRPCSuccessActionType<Key extends string, RPCActionName extends string> = FormattedActionType<Key, `${RPCActionName}_SUCCESS`>;
type FormattedRPCFailActionType<Key extends string, RPCActionName extends string> = FormattedActionType<Key, `${RPCActionName}_FAIL`>;
type FormattedRPCResetActionType<Key extends string, RPCActionName extends string> = FormattedActionType<Key, `${RPCActionName}_RESET`>;

export type RPCsReducer<EntityKey extends string, RPCs extends readonly string[]> = {
  reducer: Reducer<State<RPCs>, any>,
} & {
  [RPC in RPCs[number]]: {
    actionName: CamelToSnakeCase<RPC>,
    actions: {
      rpcStart: (args: CreateRpcStartActionArgs) => RPCStartAction<
        RPCs,
        FormattedRPCStartActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>
      >,
      rpcSuccess: (args: CreateRpcSuccessActionArgs) => RPCSuccessAction<
        FormattedRPCSuccessActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>
      >,
      rpcFail: (args: CreateRpcFailActionArgs) => RPCFailAction<
        FormattedRPCFailActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>
      >,
      rpcReset: (args: CreateRpcResetActionArgs) => RPCResetAction<
        FormattedRPCResetActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>
      >,
      types: {
        rpcStart: FormattedRPCStartActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>,
        rpcSuccess: FormattedRPCSuccessActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>,
        rpcFail: FormattedRPCFailActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>,
        rpcReset: FormattedRPCResetActionType<`${EntityKey}s`, CamelToSnakeCase<RPC>>,
      },
    },
    selectors: {
      getRpcSelector: (state: State<RPCs>, args: CreateRpcSelectorArgs) => State<RPCs>[RPCs[number]][Entity['id']],
    },
  }
}

// initializer
const createRPCsReducer = <EntityKey extends string, R extends readonly string[]>({
  key,
  rpcs,
}: {
  key: EntityKey,
  rpcs: R
}): RPCsReducer<EntityKey, R> => {
  const reducers = rpcs.reduce((acc, rpc) => {
    const rpcActionName = toUpper(snakeCase(rpc)) as CamelToSnakeCase<R[number]>;

    const RPC_START = formatActionType({ key, type: `${rpcActionName}_START` });
    const RPC_SUCCESS = formatActionType({ key, type: `${rpcActionName}_SUCCESS` });
    const RPC_FAIL = formatActionType({ key, type: `${rpcActionName}_FAIL` });
    const RPC_RESET = formatActionType({ key, type: `${rpcActionName}_RESET` });

    return {
      ...acc,
      [rpc]: createReducer(initialState as State<R>, {
        [RPC_START]: rpcStartHandler,
        [RPC_SUCCESS]: rpcSuccessHandler,
        [RPC_FAIL]: rpcFailHandler,
        [RPC_RESET]: rpcResetHandler,
      }),
    };
  }, {});

  return {
    reducer: combineReducers(reducers),
    ...rpcs.reduce((acc, rpc: R[number]) => {
      const rpcActionName = toUpper(snakeCase(rpc)) as CamelToSnakeCase<R[number]>;

      const RPC_START = formatActionType({ key, type: `${rpcActionName}_START` });
      const RPC_SUCCESS = formatActionType({ key, type: `${rpcActionName}_SUCCESS` });
      const RPC_FAIL = formatActionType({ key, type: `${rpcActionName}_FAIL` });
      const RPC_RESET = formatActionType({ key, type: `${rpcActionName}_RESET` });

      return {
        ...acc,
        [rpc]: {
          actionName: rpcActionName,
          actions: {
            rpcStart: createRpcStartAction(RPC_START),
            rpcSuccess: createRpcSuccessAction(RPC_SUCCESS),
            rpcFail: createRpcFailAction(RPC_FAIL),
            rpcReset: createRpcResetAction(RPC_RESET),
            types: {
              rpcStart: RPC_START,
              rpcSuccess: RPC_SUCCESS,
              rpcFail: RPC_FAIL,
              rpcReset: RPC_RESET,
            },
          },
          selectors: {
            getRpcSelector: createRpcSelector(rpc),
          },
        },
      };
    }, {}),
  } as RPCsReducer<EntityKey, R>;
};

export default createRPCsReducer;
