// @flow

import React from 'react';

import Svg from 'components/svg';

const BreakRight = Svg((
  <>
    <path d="M4 5H20V7H4V5Z" fill="currentColor" />
    <path d="M4 17H20V19H4V17Z" fill="currentColor" />
    <rect x="14" y="9" width="6" height="6" fill="currentColor" />
  </>
));

BreakRight.displayName = 'BreakRight';

export default BreakRight;
