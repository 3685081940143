// @flow

import map from 'lodash/map';

import { enabledLanguages, enabledLangCodes } from 'utils/languages';

type GetEnabledLanguages = () => Array<{
  label: string | EnabledLanguages,
  value: string,
}>;

export const getEnabledLanguagesAsOptions: GetEnabledLanguages = () => (
  map(enabledLanguages, (lang, key) => ({
    label: lang,
    value: key,
  }))
);

type GetOption = EnabledLanguages => ?Option;

export const getLanguageOption: GetOption = (langCode: EnabledLanguages) => {
  if (!enabledLangCodes.includes(langCode)) {
    return undefined;
  }

  return {
    label: enabledLanguages[langCode],
    value: langCode,
  };
};

type Props = {
  lang: EnabledLanguages
};

const EnabledLanguage = (props: Props) => enabledLanguages[props.lang];

export default EnabledLanguage;
