import { combineReducers } from 'redux';

import snakeCase from 'lodash/snakeCase';
import upperFirst from 'lodash/upperFirst';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

import createQueriesReducer from 'normalized-redux/queries';
import createFetchReducer from 'normalized-redux/fetch';
import createEntitiesReducer from 'normalized-redux/entities';
import createCreateReducer from 'normalized-redux/create';
import createUpdateReducer from 'normalized-redux/update';
import createRemoveReducer from 'normalized-redux/remove';
import createRPCsReducer from 'normalized-redux/rpcs';
import type { RPCsReducer } from 'normalized-redux/rpcs/rpcs';

import type {
  Entity as EntityType,
  Reducers,
  Actions,
  Constants,
  Selectors,
  MergedActions,
  NormalizedEntity,
  GlobalState,
  CamelToSnakeCase,
  UpperFirst,
} from './types';

type Props<E extends EntityType, Name extends string, RPCs extends readonly string[]> = {
  entity: Name;
  entities?: `${Name}s`;
  rpcs?: RPCs;
  initialEntities?: Record<E['id'], E>;
};

const normalizeEntity = <
  E extends EntityType,
  Name extends string,
  RPCs extends readonly string[],
>({
    entity,
    entities = `${entity}s` as const,
    rpcs = [] as unknown as RPCs,
    initialEntities = {} as Record<E['id'], E>,
  }: Props<E, Name, RPCs>) => {
  type Names = `${Name}s`;
  const Entity = upperFirst(entity) as UpperFirst<Name>;
  const Entities = upperFirst(entities) as UpperFirst<Names>;
  const ENTITY = snakeCase(entity).toUpperCase() as CamelToSnakeCase<Name>;
  const ENTITIES = snakeCase(entities).toUpperCase() as CamelToSnakeCase<Names>;

  const stateKey = entities;

  const entitiesReducer = createEntitiesReducer<E, Names>({ key: stateKey, initialEntities });
  const create = createCreateReducer<E, Names>({ key: stateKey });
  const fetch = createFetchReducer<E, Names>({ key: stateKey });
  const queries = createQueriesReducer<E, Names>({ key: stateKey });
  const update = createUpdateReducer<E, Names>({ key: stateKey });
  const remove = createRemoveReducer<E, Names>({ key: stateKey });

  let rpcsReducer: RPCsReducer<Names, RPCs>;

  let reducers = {
    entities: entitiesReducer.reducer,
    create: create.reducer,
    fetch: fetch.reducer,
    queries: queries.reducer,
    update: update.reducer,
    remove: remove.reducer,
  } as Reducers<E, Names, RPCs>;

  if (!isEmpty(rpcs)) {
    rpcsReducer = createRPCsReducer<Names, RPCs>({ key: stateKey, rpcs });

    reducers = {
      ...reducers,
      rpcs: rpcsReducer.reducer as RPCsReducer<Names, RPCs>['reducer'],
    };
  }

  const actions = {
    [`set${Entities}`]: entitiesReducer.actions.setEntities,
    [`clear${Entity}`]: entitiesReducer.actions.clearEntity,

    [`create${Entity}`]: create.actions.createStart,
    [`create${Entity}Success`]: create.actions.createSuccess,
    [`create${Entity}Fail`]: create.actions.createFail,
    [`create${Entity}Reset`]: create.actions.createReset,

    [`query${Entities}`]: queries.actions.queryStart,
    [`query${Entities}Success`]: queries.actions.querySuccess,
    [`query${Entities}Fail`]: queries.actions.queryFail,
    [`query${Entities}Reset`]: queries.actions.queryReset,
    [`query${Entities}Reload`]: queries.actions.queryReload,
    [`query${Entities}LoadMore`]: queries.actions.queryLoadMore,
    setQueryParams: queries.actions.querySetParams,

    [`fetch${Entity}`]: fetch.actions.fetchStart,
    [`fetch${Entity}Success`]: fetch.actions.fetchSuccess,
    [`fetch${Entity}Fail`]: fetch.actions.fetchFail,

    [`update${Entity}`]: update.actions.updateStart,
    [`update${Entity}Success`]: update.actions.updateSuccess,
    [`update${Entity}Fail`]: update.actions.updateFail,
    [`update${Entity}Reset`]: update.actions.updateReset,

    [`remove${Entity}`]: remove.actions.removeStart,
    [`remove${Entity}Success`]: remove.actions.removeSuccess,
    [`remove${Entity}Fail`]: remove.actions.removeFail,
    [`remove${Entity}Reset`]: remove.actions.removeReset,

    ...rpcs.reduce((acc, rpc: RPCs[number]) => ({
      ...acc,
      [`${rpc}`]: rpcsReducer![rpc].actions.rpcStart,
      [`${rpc}Success`]: rpcsReducer![rpc].actions.rpcSuccess,
      [`${rpc}Fail`]: rpcsReducer![rpc].actions.rpcFail,
      [`${rpc}Reset`]: rpcsReducer![rpc].actions.rpcReset,
    }), {}),
  } as Actions<E, Name, RPCs>;

  const constants = {
    [`SET_${ENTITIES}`]: entitiesReducer.actions.types.setEntities,
    [`CLEAR_${ENTITY}`]: entitiesReducer.actions.types.clearEntity,

    [`CREATE_${ENTITY}`]: create.actions.types.createStart,
    [`CREATE_${ENTITY}_SUCCESS`]: create.actions.types.createSuccess,
    [`CREATE_${ENTITY}_FAIL`]: create.actions.types.createFail,
    [`CREATE_${ENTITY}_RESET`]: create.actions.types.createReset,

    [`FETCH_${ENTITY}`]: fetch.actions.types.fetchStart,
    [`FETCH_${ENTITY}_SUCCESS`]: fetch.actions.types.fetchSuccess,
    [`FETCH_${ENTITY}_FAIL`]: fetch.actions.types.fetchFail,

    [`QUERY_${ENTITIES}`]: queries.actions.types.queryStart,
    [`QUERY_${ENTITIES}_SUCCESS`]: queries.actions.types.querySuccess,
    [`QUERY_${ENTITIES}_FAIL`]: queries.actions.types.queryFail,
    [`QUERY_${ENTITIES}_RESET`]: queries.actions.types.queryReset,
    [`QUERY_${ENTITIES}_RELOAD`]: queries.actions.types.queryReload,
    [`QUERY_${ENTITIES}_LOAD_MORE`]: queries.actions.types.queryLoadMore,
    QUERY_SET_PARAMS: queries.actions.types.querySetParams,

    [`UPDATE_${ENTITY}`]: update.actions.types.updateStart,
    [`UPDATE_${ENTITY}_SUCCESS`]: update.actions.types.updateSuccess,
    [`UPDATE_${ENTITY}_FAIL`]: update.actions.types.updateFail,
    [`UPDATE_${ENTITY}_RESET`]: update.actions.types.updateReset,

    [`REMOVE_${ENTITY}`]: remove.actions.types.removeStart,
    [`REMOVE_${ENTITY}_SUCCESS`]: remove.actions.types.removeSuccess,
    [`REMOVE_${ENTITY}_FAIL`]: remove.actions.types.removeFail,
    [`REMOVE_${ENTITY}_RESET`]: remove.actions.types.removeReset,

    ...rpcs.reduce((acc, rpc: RPCs[number]) => {
      const { actionName } = rpcsReducer![rpc];

      return {
        ...acc,
        [`${actionName}`]: rpcsReducer![rpc].actions.types.rpcStart,
        [`${actionName}_SUCCESS`]: rpcsReducer![rpc].actions.types.rpcSuccess,
        [`${actionName}_FAIL`]: rpcsReducer![rpc].actions.types.rpcFail,
        [`${actionName}_RESET`]: rpcsReducer![rpc].actions.types.rpcReset,
      };
    }, {}),
  } as Constants<E, Name, RPCs>;

  const selectors = {
    [`getAll${Entities}Selector`]: (state: GlobalState<E, Names, RPCs>) => (
      entitiesReducer.selectors.getAllEntitiesSelector(state.entities[stateKey].entities)
    ),
    [`get${Entity}Selector`]: (state: GlobalState<E, Names, RPCs>, { id }: { id: E['id'] }) => (
      entitiesReducer.selectors.getEntityByIdSelector(state.entities[stateKey].entities, { id })
    ),
    [`get${Entities}Selector`]: (state: GlobalState<E, Names, RPCs>, { ids }: { ids: E['id'][] }) => (
      entitiesReducer.selectors.getEntitiesByIdSelector(state.entities[stateKey].entities, { ids })
    ),
    getCreateSelector: (state: GlobalState<E, Names, RPCs>) => (
      create.selectors.getCreateSelector(state.entities[stateKey].create)
    ),
    getFetchSelector: (state: GlobalState<E, Names, RPCs>, { id }: { id: E['id'] }) => (
      fetch.selectors.getFetchSelector(state.entities[stateKey].fetch, { id })
    ),
    getQuerySelector: (state: GlobalState<E, Names, RPCs>, { name }: { name: string }) => (
      queries.selectors.getQuerySelector(state.entities[stateKey].queries, { name })
    ),
    getUpdateSelector: (state: GlobalState<E, Names, RPCs>, { id }: { id: E['id'] }) => (
      update.selectors.getUpdateSelector(state.entities[stateKey].update, { id })
    ),
    getRemoveSelector: (state: GlobalState<E, Names, RPCs>, { id }: { id: E['id'] }) => (
      remove.selectors.getRemoveSelector(state.entities[stateKey].remove, { id })
    ),
    ...rpcs.reduce((acc, rpc: RPCs[number]) => ({
      ...acc,
      [`get${upperFirst(rpc)}Selector`]: (state: GlobalState<E, Names, RPCs>, { id }: { id: E['id'] }) => rpcsReducer![rpc].selectors.getRpcSelector(state.entities[stateKey].rpcs!, { id }),
    }), {}),
  } as Selectors<E, Name, RPCs>;

  const reducer = combineReducers(reducers);

  return {
    ...actions,
    ...constants,
    ...selectors,
    actions: {
      ...merge(
        {},
        queries.actions,
        fetch.actions,
        create.actions,
        update.actions,
        remove.actions,
      ),
    } as MergedActions<E, Name>,
    reducer,
  } as NormalizedEntity<E, RPCs, Name, typeof reducer>;
};

export default normalizeEntity;
