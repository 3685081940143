// @flow

import { connect } from 'react-redux';
import { getLocationSelector } from 'reducers/router';

import Redirect from './redirect';

export const mapStateToProps = (state: State) => ({
  location: getLocationSelector(state),
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
