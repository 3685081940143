// @flow

import { decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';

import {
  get,
  getMultiple,
  post,
  put,
  remove,
  hasAnyEntity,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import type { SortParams } from 'oneflow-client/core';

import { groupSchema } from 'oneflow-client/positions';

export type NormalizedGroups = {
  entities: {
    groups: {
      [number]: Group,
    },
  },
  result: number | Array<number>,
  count: number,
};

type GroupNormalizer = (any) => NormalizedGroups;

export const normalizeGroups: GroupNormalizer = (groups) => ({
  ...normalize(groups.collection, [groupSchema]),
  count: groups.count,
});

export const normalizeGroup: GroupNormalizer = (group) => ({
  ...normalize(group, groupSchema),
  count: 1,
});

type GetGroups = ({
  params?: {},
  pagination?: {},
  sorting?: SortParams,
}) => Promise<NormalizedGroups>;

export const getGroups: GetGroups = ({
  pagination,
  params,
  sorting = {
    attr: 'name',
    direction: 'asc',
  },
}) => (
  getMultiple({
    url: '/groups/',
    pagination,
    params: decamelizeKeys(params, { separator: '_' }),
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroups)
);

type GetGroup = ({
  id: number,
}) => Promise<NormalizedGroups>;

export const getGroup: GetGroup = ({ id }) => (
  get({
    url: `/groups/${id}`,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroup)
);

type CreateGroup = ({
  name: string,
}) => Promise<NormalizedGroups>;

export const createGroup: CreateGroup = ({ name }) => (
  post({
    url: '/groups/',
    body: { name },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroup)
);

type UpdateGroup = ({
  id: number,
  name?: string,
}) => Promise<NormalizedGroups>;

export const updateGroup: UpdateGroup = ({ id, name }) => (
  put({
    url: `/groups/${id}`,
    body: { name },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeGroup)
);

type DeleteGroups = ({
  ids: Array<number>,
}) => Promise<{}>;

export const deleteGroups: DeleteGroups = ({ ids }) => (
  remove({ url: '/groups/', body: { group_ids: ids } })
    .then(extractResponseBodyAsJSON)
);

export const hasAnyGroup = hasAnyEntity({ url: '/groups/' });
