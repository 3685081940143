// @flow

import React from 'react';

import Svg from 'components/svg';

const SingleUserActionsAdd = Svg((
  <g>
    <path d="M5.78568 16.0714H0.642823V14.1428C0.642527 13.1564 0.89444 12.1863 1.37463 11.3246C1.85482 10.463 2.54734 9.73838 3.3864 9.21972C4.22546 8.70105 5.1832 8.40553 6.16863 8.36122C7.15406 8.3169 8.13445 8.52528 9.01668 8.96654M13.5 9.64282V17.3571M9.64282 13.5H17.3571M3.53568 3.53568C3.53568 3.91557 3.61051 4.29175 3.75589 4.64273C3.90127 4.99371 4.11435 5.31261 4.38298 5.58124C4.6516 5.84986 4.97051 6.06295 5.32149 6.20833C5.67247 6.35371 6.04864 6.42854 6.42854 6.42854C6.80843 6.42854 7.18461 6.35371 7.53559 6.20833C7.88656 6.06295 8.20547 5.84986 8.4741 5.58124C8.74272 5.31261 8.95581 4.99371 9.10119 4.64273C9.24657 4.29175 9.32139 3.91557 9.32139 3.53568C9.32139 3.15578 9.24657 2.77961 9.10119 2.42863C8.95581 2.07765 8.74272 1.75875 8.4741 1.49012C8.20547 1.22149 7.88656 1.00841 7.53559 0.863028C7.18461 0.717648 6.80843 0.642822 6.42854 0.642822C6.04864 0.642822 5.67247 0.717648 5.32149 0.863028C4.97051 1.00841 4.6516 1.22149 4.38298 1.49012C4.11435 1.75875 3.90127 2.07765 3.75589 2.42863C3.61051 2.77961 3.53568 3.15578 3.53568 3.53568Z" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
  </g>
), { viewBox: '0 0 18 18', fill: 'none' });

SingleUserActionsAdd.displayName = 'SingleUserActionsAdd';

export default SingleUserActionsAdd;
