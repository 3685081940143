import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const PRODUCTION_GTMID = 'GTM-M6TXZ5F';
const TEST_GTMID = 'GTM-W6CFFG4';

export const getGoogleTagManagerId = () => {
  if (window.FLOW_CLIENT_ENVIRONMENT === 'production') {
    return PRODUCTION_GTMID;
  }
  return TEST_GTMID;
};

let analytics;

const getAnalytics = () => {
  if (analytics) {
    return analytics;
  }

  analytics = Analytics({
    app: 'oneflow-client',
    plugins: [
      googleTagManager({
        containerId: getGoogleTagManagerId(),
      }),
    ],
  });

  return analytics;
};

export default getAnalytics;
