// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import LogoOneflow from './logo-oneflow';

const Logo = ({ LogoComponent }: Function) => (
  <LogoComponent />
);

const mapStateToProps = () => ({
  LogoComponent: LogoOneflow,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
