import Svg from 'components/svg';

const NewSettings = Svg((
  <>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M9.57955 5.30177L10.1973 3.72768C10.3008 3.4637 10.4815 3.23704 10.7157 3.07727C10.95 2.91749 11.2269 2.83203 11.5105 2.83203H12.6558C12.9393 2.83203 13.2163 2.91749 13.4506 3.07727C13.6848 3.23704 13.8655 3.4637 13.9689 3.72768L14.5867 5.30177L16.6319 6.48375L18.3033 6.22986C18.5839 6.18736 18.8707 6.23044 19.1263 6.35349C19.382 6.47653 19.5946 6.67382 19.7364 6.91958L20.309 7.90691C20.4509 8.15245 20.5156 8.43507 20.4944 8.71788C20.4733 9.0007 20.3674 9.27057 20.1905 9.49229L19.1355 10.8195V13.1807L20.1905 14.5023C20.368 14.7243 20.4742 14.9947 20.4954 15.2781C20.5165 15.5615 20.4516 15.8446 20.309 16.0905L19.7364 17.0778C19.5946 17.3236 19.382 17.5209 19.1263 17.6439C18.8707 17.767 18.5839 17.81 18.3033 17.7675L16.6319 17.5136L14.5867 18.6956L13.9689 20.2697C13.8655 20.5337 13.6848 20.7604 13.4506 20.9201C13.2163 21.0799 12.9393 21.1654 12.6558 21.1654H11.5105C11.2269 21.1654 10.95 21.0799 10.7157 20.9201C10.4815 20.7604 10.3008 20.5337 10.1973 20.2697L9.57955 18.6956L7.53437 17.5136L5.86296 17.7675C5.58243 17.81 5.29561 17.767 5.03996 17.6439C4.7843 17.5209 4.5717 17.3236 4.42992 17.0778L3.85726 16.0905C3.71534 15.8449 3.65073 15.5623 3.67186 15.2795C3.693 14.9967 3.7989 14.7268 3.97574 14.5051L5.03078 13.1807V10.8195L3.97574 9.49793C3.7983 9.27595 3.69204 9.00555 3.6709 8.72215C3.64976 8.43876 3.71472 8.15558 3.85726 7.90974L4.42992 6.9224C4.5713 6.67612 4.78373 6.47826 5.03942 6.35469C5.29511 6.23113 5.58214 6.18762 5.86296 6.22986L7.53437 6.48375L9.57955 5.30177ZM9.2622 12.0001C9.2622 12.558 9.42764 13.1034 9.73761 13.5673C10.0476 14.0312 10.4882 14.3928 11.0036 14.6063C11.5191 14.8198 12.0863 14.8757 12.6335 14.7669C13.1807 14.658 13.6833 14.3893 14.0779 13.9948C14.4724 13.6003 14.741 13.0977 14.8499 12.5504C14.9587 12.0032 14.9029 11.436 14.6894 10.9206C14.4758 10.4051 14.1143 9.96455 13.6504 9.65458C13.1865 9.34461 12.6411 9.17916 12.0831 9.17916C11.335 9.17916 10.6175 9.47637 10.0884 10.0054C9.5594 10.5344 9.2622 11.2519 9.2622 12.0001V12.0001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

NewSettings.displayName = 'NewSettings';

export default NewSettings;
