import Svg from 'components/svg';
import { uniqueId } from 'lodash';

const Fragment = () => {
  const id = uniqueId('clip0_13823_6394');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M4.57129 7.42773L7.99986 10.8563L11.4284 7.42773" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 0.570312L8 10.856" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 15.4277L12 15.4277" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </>
  );
};

const Unpublish = Svg(Fragment, {
  viewBox: '0 0 16 16',
  fill: 'none',
});

Unpublish.displayName = 'Unpublish';

export default Unpublish;
