// @flow

import React from 'react';

import Svg from 'components/svg';

const TimeReset = Svg((
  <>
    <path d="M34.7403 3C41.5346 2.99885 48.1174 5.42284 53.359 9.85595C58.6006 14.2891 62.1741 20.4548 63.4661 27.295C64.7582 34.1352 63.6882 41.2233 60.4399 47.3428C57.1916 53.4623 51.9674 58.2316 45.6641 60.8321C39.3608 63.4325 32.3714 63.7021 25.8954 61.5945C19.4194 59.4868 13.8607 55.1334 10.1733 49.2814C6.48598 43.4294 4.89989 36.4437 5.68728 29.5233C6.47468 22.6029 9.58645 16.1793 14.4885 11.355" stroke="currentColor" strokeWidth="5" strokeLinecap="square" strokeLinejoin="round" fill="none" />
    <path d="M34.7402 21.75V33L42.054 40.5" stroke="currentColor" strokeWidth="5" strokeLinecap="square" strokeLinejoin="round" fill="none" />
    <path d="M0 6.75H18.2843V25.5" stroke="currentColor" strokeWidth="5" fill="none" />
  </>
), { viewBox: '0 0 67 66' });

TimeReset.displayName = 'TimeReset';

export default TimeReset;
