import Svg from 'components/svg';

const PermanentDeleteThin = Svg((
  <>
    <path d="M27.553 16.4027C27.5287 16.6151 27.5961 16.8277 27.7384 16.9872C27.8807 17.1467 28.0843 17.2379 28.2981 17.2379H45.7087C45.9225 17.2379 46.1261 17.1467 46.2684 16.9872C46.4107 16.8277 46.4782 16.6151 46.4539 16.4027C46.2108 14.278 45.2566 12.2851 43.7295 10.758C41.9456 8.97418 39.5262 7.97201 37.0034 7.97201C34.4806 7.97201 32.0612 8.97418 30.2773 10.758C28.7502 12.2851 27.796 14.278 27.553 16.4027ZM51.8567 16.5357C51.8819 16.9306 52.2095 17.2379 52.6052 17.2379H68.9638V22.6094H62.3952C61.9809 22.6094 61.6452 22.9452 61.6452 23.3594V63.8358C61.6452 65.8421 60.8482 67.7662 59.4295 69.1849C58.0108 70.6036 56.0866 71.4006 54.0803 71.4006H19.9265C17.9202 71.4006 15.996 70.6036 14.5773 69.1849C13.1587 67.7662 12.3617 65.8421 12.3617 63.8358V23.3594C12.3617 22.9452 12.0259 22.6094 11.6116 22.6094H5.04297V17.2379H21.4016C21.7973 17.2379 22.1249 16.9306 22.1501 16.5357C22.3799 12.9345 23.9124 9.52669 26.4792 6.95987C29.2704 4.16867 33.0561 2.60059 37.0034 2.60059C40.9508 2.60059 44.7364 4.16867 47.5276 6.95987C50.0945 9.52669 51.6269 12.9345 51.8567 16.5357ZM18.4831 22.6094C18.0689 22.6094 17.7331 22.9452 17.7331 23.3594V63.8358C17.7331 64.4175 17.9642 64.9754 18.3755 65.3867C18.7869 65.7981 19.3447 66.0292 19.9265 66.0292H54.0803C54.6621 66.0292 55.22 65.7981 55.6313 65.3867C56.0426 64.9754 56.2737 64.4175 56.2737 63.8358V23.3594C56.2737 22.9452 55.938 22.6094 55.5237 22.6094H18.4831Z" fill="currentColor" stroke="none" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M49.3281 32.8887L24.6615 57.5553" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
    <path d="M49.3281 57.5547L24.6615 32.888" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
  </>
), { viewBox: '0 0 74 74', fill: 'none' });

PermanentDeleteThin.displayName = 'PermanentDeleteThin';

export default PermanentDeleteThin;
