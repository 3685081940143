// @flow

import React from 'react';

import Svg from 'components/svg';

const DataField = Svg((
  <>
    <path d="M5 10L5 15L3 15L3 10L5 10Z" fill="currentColor" />
    <path d="M21 10L21 15L19 15L19 10L21 10Z" fill="currentColor" />
    <path d="M3 15H7V17H3V15Z" fill="currentColor" />
    <path d="M13 15H21V17H13V15Z" fill="currentColor" />
    <path d="M3 8H7V10H3V8Z" fill="currentColor" />
    <path d="M7 4H13V6H7V4Z" fill="currentColor" />
    <path d="M7 19H13V21H7V19Z" fill="currentColor" />
    <path d="M13 8H21V10H13V8Z" fill="currentColor" />
    <path d="M11 6L11 19L9 19L9 6L11 6Z" fill="currentColor" />
  </>
), { viewBox: '0 0 24 24' });

DataField.displayName = 'DataField';

export default DataField;
