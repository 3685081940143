// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_293_5538');
  return (
    <>
      <path d="M41.7253 5.56592C41.2858 4.68912 40.6111 3.95187 39.7765 3.43665C38.942 2.92143 37.9806 2.64856 36.9998 2.64856C36.0191 2.64856 35.0576 2.92143 34.2231 3.43665C33.3886 3.95187 32.7138 4.68912 32.2744 5.56592L3.20297 63.7088C2.80039 64.5144 2.61034 65.4096 2.65086 66.3093C2.69138 67.209 2.96113 68.0835 3.43452 68.8497C3.9079 69.6159 4.56921 70.2484 5.35569 70.6873C6.14218 71.1261 7.02776 71.3567 7.9284 71.3572H66.0713C66.9719 71.3567 67.8575 71.1261 68.644 70.6873C69.4305 70.2484 70.0918 69.6159 70.5651 68.8497C71.0385 68.0835 71.3083 67.209 71.3488 66.3093C71.3893 65.4096 71.1993 64.5144 70.7967 63.7088L41.7253 5.56592Z" stroke="currentColor" fill="transparent" strokeWidth="5.28571" strokeLinecap="square" strokeLinejoin="round" />
      <g clipPath={`url(#${id})`}>
        <path d="M34.4288 49.5842C34.4288 46.7439 32.1262 44.4413 29.2859 44.4413C26.4456 44.4413 24.1431 46.7439 24.1431 49.5842C24.1431 52.4245 26.4456 54.7271 29.2859 54.7271C32.1262 54.7271 34.4288 52.4245 34.4288 49.5842Z" stroke="currentColor" fill="transparent" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.2856 42.4633L29.2856 29.0127" stroke="currentColor" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M29.2856 60.4633L29.2856 57.2984" stroke="currentColor" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M39.5717 39.8919C39.5717 42.7322 41.8742 45.0347 44.7146 45.0347C47.5549 45.0347 49.8574 42.7322 49.8574 39.8919C49.8574 37.0516 47.5549 34.749 44.7146 34.749C41.8742 34.749 39.5717 37.0516 39.5717 39.8919Z" stroke="currentColor" fill="transparent" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M44.7148 47.0128L44.7148 60.4634" stroke="currentColor" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M44.7148 29.0126L44.7148 32.1774" stroke="currentColor" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="36" height="36" transform="translate(19 26)" />
        </clipPath>
      </defs>
    </>
  );
};

const TerminateRules = Svg(Fragment, { viewBox: '0 0 74 74' });

TerminateRules.displayName = 'TerminateRules';

export default TerminateRules;
