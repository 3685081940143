import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';
import webSocket from 'web-socket';
import { matchPath } from 'react-router';

import readCookies from 'utils/read-cookies';
import sessionStorage from 'utils/session-storage';

import {
  setLoading,
  setError,
  setDefaultWorkspace as setDefaultWorkspaceAction,
  setCurrentWorkspace as setCurrentWorkspaceAction,
} from 'reducers/app';
import {
  getAllWorkspacesFromSessionSelector,
} from 'reducers/session';
import { getCurrentLanguageSelector, setLanguage } from 'reducers/i18n';
import { isAValidWorkspaceId } from 'reducers/entities/workspaces';
import { getLocationSelector } from 'reducers/router';

import {
  readDefaultWorkspaceIdFromLocalStorage,
  getFirstAccessibleWorkspaceId,
  extractWorkspaceIdFromUrl,
} from 'sagas/current-workspace';
import { requestMe } from 'sagas/me';
import { registerIsoCountryLanguages } from 'sagas/i18n';
import { loadClientFeatureFlags } from 'sagas/client-feature-flags';
import { loadAppcues } from 'sagas/third-parties/appcues';
import { loadUserflow } from 'sagas/third-parties/userflow';

import { checkIfAuthenticated } from './authentication';

/**
 * Decorates a loading function. It will silently handle exceptions raised from the
 * function call, allowing a custom code to be executed when an error occurs.
 * @param fn Function to be wrapped
 * @param onError Custom callback to be called when an error occurs
 *
 * @returns A function generator that is yielded to the redux-saga middleware
 */
export function silentWrapper(fn, onError = (f) => f) {
  return function* wrapped() {
    try {
      yield call(fn);
    } catch (error) {
      yield call(onError, error);
    }
  };
}

/**
 * Decorates a loading function. It will noisily handle exceptions raised from the
 * function call, allowing a custom code to be executed when an error occurs and
 * bubbling up the error afterwards.
 * @param fn Function to be wrapped
 * @param onError Custom callback to be called when an error occurs
 *
 * @returns A function generator that is yielded to the redux-saga middleware
 */
export function noisyWrapper(fn, onError = (f) => f) {
  return function* wrapped() {
    try {
      yield call(fn);
    } catch (error) {
      yield call(onError, error);
      throw error;
    }
  };
}

export const noisilyRequestMeAndCheckIfAuthenticated = (
  noisyWrapper(requestMe, checkIfAuthenticated)
);

export function* requestMeAndCheckIfAuthenticated() {
  yield call(noisilyRequestMeAndCheckIfAuthenticated);
}

export function* initializePusher() {
  yield call(webSocket.initialize);
}

export function* setLanguageFromCookie() {
  const currentLanguage = yield select(getCurrentLanguageSelector);
  const cookies = yield call(readCookies);
  const cookiePreferredLanguage = cookies['flow-preferred-language'];

  if (cookiePreferredLanguage && currentLanguage !== cookiePreferredLanguage) {
    yield put(setLanguage(cookiePreferredLanguage));
  }
}

export function* setDefaultWorkspace() {
  const workspaces = yield select(getAllWorkspacesFromSessionSelector);
  const workspaceIds = workspaces.map((workspace) => workspace.id);
  const storageWorkspaceId = yield call(readDefaultWorkspaceIdFromLocalStorage);
  const firstWorkspaceId = workspaces[0].id;

  let defaultWorkspaceId = firstWorkspaceId;

  if (isAValidWorkspaceId(storageWorkspaceId) && workspaceIds.includes(storageWorkspaceId)) {
    defaultWorkspaceId = storageWorkspaceId;
  }

  yield put(setDefaultWorkspaceAction({ workspaceId: defaultWorkspaceId }));
}

export function* setCurrentWorkspace() {
  const workspaceIdFromUrl = yield call(extractWorkspaceIdFromUrl);
  const currentWorkspaceId = yield call(getFirstAccessibleWorkspaceId, workspaceIdFromUrl);

  yield put(setCurrentWorkspaceAction({ workspaceId: currentWorkspaceId }));
}

export function* setUrlMessageId() {
  const location = yield select(getLocationSelector);
  const match = matchPath(location.pathname, {
    path: [
      '/documents/:agreementId/m/:messageId',
      '/documents/:agreementId/at/:guestToken/m/:messageId',
    ],
    exact: true,
  });
  const { messageId } = match?.params || {};

  if (!messageId) {
    sessionStorage.removeItem('url-message-id');
    return;
  }

  sessionStorage.setItem('url-message-id', messageId);
}

export default function* bootup() {
  try {
    yield put(setLoading(true));

    yield call(registerIsoCountryLanguages);

    yield call(setLanguageFromCookie);

    yield call(requestMeAndCheckIfAuthenticated);

    yield call(setDefaultWorkspace);
    yield call(setCurrentWorkspace);
    yield call(setUrlMessageId);

    yield fork(loadUserflow);
    yield fork(loadAppcues);

    yield call(loadClientFeatureFlags);

    yield call(initializePusher);
  } catch (error) {
    yield put(setError(true, error));
  } finally {
    yield put(setLoading(false));
  }
}
