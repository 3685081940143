// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_575_45070');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M0.643066 15.1071V2.89283C0.643066 2.55183 0.778525 2.22481 1.01964 1.98369C1.26076 1.74257 1.58779 1.60711 1.92878 1.60711H6.67307C6.96677 1.59856 7.25454 1.69086 7.48847 1.86864C7.72241 2.04643 7.88839 2.29899 7.95878 2.58426L8.35735 4.17854H16.0716C16.4126 4.17854 16.7397 4.314 16.9808 4.55512C17.2219 4.79624 17.3574 5.12326 17.3574 5.46426V15.1071C17.3574 15.4481 17.2219 15.7751 16.9808 16.0163C16.7397 16.2574 16.4126 16.3928 16.0716 16.3928H1.92878C1.58779 16.3928 1.26076 16.2574 1.01964 16.0163C0.778525 15.7751 0.643066 15.4481 0.643066 15.1071Z"
          fill="transparent"
          stroke="#013A4C"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16724 8.03601V12.536"
          stroke="#013A4C"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.91724 10.286H11.4172"
          stroke="#013A4C"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="18"
            height="18"
            fill="white"
          />
        </clipPath>
      </defs>
    </>
  );
};

const AddFolderOutlineIcon = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

AddFolderOutlineIcon.displayName = 'AddFolderOutlineIcon';

export default AddFolderOutlineIcon;
