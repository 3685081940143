// @flow

import React from 'react';

import Svg from 'components/svg';

const BrokenImage = Svg((
  <>
    <path d="M21.1476 9.90149V13.8017H19.8475V9.90149H21.1476Z" fill="#99B0B7" />
    <path d="M19.5225 15.4268C19.5225 15.6854 19.6252 15.9334 19.808 16.1163C19.9909 16.2991 20.2389 16.4019 20.4975 16.4019C20.7561 16.4019 21.0041 16.2991 21.187 16.1163C21.3698 15.9334 21.4726 15.6854 21.4726 15.4268C21.4726 15.1682 21.3698 14.9202 21.187 14.7373C21.0041 14.5545 20.7561 14.4518 20.4975 14.4518C20.2389 14.4518 19.9909 14.5545 19.808 14.7373C19.6252 14.9202 19.5225 15.1682 19.5225 15.4268Z" fill="#99B0B7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.4975 6C20.1657 6 19.84 6.08931 19.5546 6.25856C19.27 6.42732 19.0359 6.66935 18.8768 6.95938L14.325 15.1296L14.3234 15.1326C14.1047 15.5301 13.9933 15.9777 14.0003 16.4313C14.0073 16.8849 14.1324 17.3289 14.3632 17.7194C14.594 18.11 14.9227 18.4337 15.3167 18.6585C15.7107 18.8834 16.1566 19.0016 16.6102 19.0017H24.3821C24.8363 19.0017 25.2826 18.8834 25.6771 18.6583C26.0716 18.4333 26.4006 18.1093 26.6317 17.7183C26.8629 17.3274 26.9881 16.8829 26.9951 16.4288C27.0021 15.9747 26.8906 15.5266 26.6717 15.1287L22.1183 6.95934C21.9591 6.66933 21.7251 6.42731 21.4405 6.25856C21.1551 6.08931 20.8294 6 20.4975 6ZM16.6103 17.7016C16.3826 17.7016 16.1588 17.6422 15.961 17.5293C15.7632 17.4165 15.5983 17.254 15.4824 17.0579C15.3665 16.8619 15.3037 16.639 15.3002 16.4113C15.2967 16.1841 15.3524 15.9599 15.4617 15.7607L15.4624 15.7594L20.0136 7.59008L20.0161 7.58554C20.0634 7.49913 20.133 7.42703 20.2177 7.37681C20.3024 7.32658 20.3991 7.30007 20.4975 7.30007C20.596 7.30007 20.6927 7.32658 20.7774 7.37681C20.8621 7.42703 20.9317 7.49913 20.9789 7.58554L20.9815 7.5902L25.5327 15.7555L25.5334 15.7569C25.6429 15.9565 25.6987 16.1811 25.6952 16.4088C25.6917 16.637 25.6287 16.8603 25.5126 17.0568C25.3965 17.2532 25.2311 17.416 25.0329 17.5291C24.8347 17.6422 24.6104 17.7016 24.3822 17.7016H16.6103Z" fill="#99B0B7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.77701 5.1953C9.77701 6.48179 10.8062 7.55386 12.1356 7.55386C13.422 7.55386 14.4941 6.48179 14.4941 5.1953C14.4941 5.18098 14.494 5.16669 14.4937 5.15242C14.4702 3.88508 13.4077 2.83675 12.1356 2.83675C10.8634 2.83675 9.8009 3.88508 9.7774 5.15242C9.77714 5.16669 9.77701 5.18098 9.77701 5.1953ZM10.892 5.15242C10.892 4.46629 11.4494 3.90882 12.1356 3.90882C12.8217 3.90882 13.3792 4.46629 13.3792 5.15242C13.3792 5.16677 13.3789 5.18107 13.3784 5.1953C13.3558 5.86162 12.8073 6.39603 12.1356 6.39603C11.4638 6.39603 10.9153 5.86162 10.8927 5.1953C10.8922 5.18107 10.892 5.16677 10.892 5.15242ZM7.56008 7.55745L13.9019 14.4473L14.667 15.2786L15.0504 15.6951L15.5 14.5L13.0377 11.8656L13.5592 11.4337L14.7699 10.4311L15.1373 10.1268L15.3742 10.3655L15.4092 10.4007L16.2573 11.2554L16.5 11.5L17 10.5L15.8754 9.39442L15.1373 8.66882L14.4164 9.25626L12.2493 11.0221L7.56812 6.01366L7.30307 5.73009L6.53165 4.90475L5.76673 5.72452L5.49612 6.01454L1 10.8331L1.66379 11.6434L1.75918 11.5415L2.70748 10.5278L5.4942 7.54908L6.21263 6.78114L6.53165 6.44014L6.84874 6.78463L7.56008 7.55745Z" fill="#99B0B7" />
    <path d="M19 8V2C19 1.44772 18.5523 1 18 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14.5" stroke="#99B0B7" strokeWidth="1.25" strokeLinecap="round" />
  </>
), {
  viewBox: '0 0 28 25',
  fill: 'none',
});

BrokenImage.displayName = 'BrokenImage';

export default BrokenImage;
