import React from 'react';

import Svg from 'components/svg';

const EmailWithCheck = Svg((
  <g clipPath="url(#clip0_15530_4691)">
    <path d="M0.572266 2.28564L7.42941 6.85707L14.2866 2.28564" stroke="#013A4C" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4294 9.71411L10.858 15.4284L8.57227 13.7141" stroke="#013A4C" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.4294 10.857H1.71512C1.08394 10.857 0.572266 10.3453 0.572266 9.71415V1.71415C0.572266 1.08296 1.08394 0.571289 1.71512 0.571289H13.1437C13.7749 0.571289 14.2866 1.08296 14.2866 1.71415V7.42843" stroke="#013A4C" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
  </g>
), { viewBox: '0 0 16 16', fill: 'none' });

EmailWithCheck.displayName = 'EmailWithCheck';

export default EmailWithCheck;
