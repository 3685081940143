// @flow

import React from 'react';

import Svg from 'components/svg';

const ProfileIcon = Svg((
  <>
    <path d="M5.49998 1.00003C7.70912 1.00003 9.49997 2.79088 9.49997 5.00002C9.49997 7.20915 7.70912 9 5.49998 9C3.29085 9 1.5 7.20915 1.5 5.00002C1.5 2.79088 3.29085 1.00003 5.49998 1.00003Z" stroke="currentColor" strokeWidth="0.88888" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.5 5H9.49997" stroke="currentColor" strokeWidth="0.88888" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.03883 5.00002C6.96326 3.53726 6.42448 2.13643 5.50037 1.00003C4.57626 2.13643 4.03748 3.53726 3.96191 5.00002C4.03748 6.46277 4.57626 7.8636 5.50037 9C6.42448 7.8636 6.96326 6.46277 7.03883 5.00002V5.00002Z" stroke="currentColor" strokeWidth="0.88888" strokeLinecap="round" strokeLinejoin="round" />
  </>
), { viewBox: '0 0 11 10', fill: 'none' });

ProfileIcon.displayName = 'ProfileIcon';

export default ProfileIcon;
