// @flow

import React from 'react';

import Svg from 'components/svg';

const CheckShield = Svg((
  <g>
    <path d="M10.9615 7V5.96159C10.9615 5.70399 10.9108 5.44891 10.8122 5.21092C10.7136 4.97293 10.5692 4.75669 10.387 4.57454C10.2049 4.39239 9.98862 4.2479 9.75064 4.14932C9.51265 4.05074 9.25758 4 8.99999 4C8.7424 4 8.48733 4.05074 8.24934 4.14932C8.01136 4.2479 7.79512 4.39239 7.61297 4.57454C7.43083 4.75669 7.28634 4.97293 7.18777 5.21092C7.08919 5.44891 7.03845 5.70399 7.03845 5.96159V7M9.46946 17.4128C9.16728 17.5291 8.83272 17.5291 8.53054 17.4128C6.1621 16.4835 4.12887 14.8616 2.69628 12.759C1.26368 10.6563 0.498285 8.1706 0.500003 5.62629V1.80773C0.500003 1.4609 0.637777 1.12827 0.883017 0.883024C1.12826 0.637778 1.46087 0.5 1.80769 0.5H16.1923C16.5391 0.5 16.8717 0.637778 17.117 0.883024C17.3622 1.12827 17.5 1.4609 17.5 1.80773V5.62629C17.5017 8.1706 16.7363 10.6563 15.3037 12.759C13.8711 14.8616 11.8379 16.4835 9.46946 17.4128ZM5.5 7H12.5V12H5.5V7Z" stroke="currentColor" strokeLinecap="square" />
  </g>
), { fill: 'none', viewBox: '0 0 18 18' });

CheckShield.displayName = 'CheckShield';

export default CheckShield;
