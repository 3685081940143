import Svg from 'components/svg';

const UserCircleSingle = Svg((
  <>
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 8c1.38071 0 2.5 -1.11929 2.5 -2.5S8.38071 3 7 3 4.5 4.11929 4.5 5.5 5.61929 8 7 8Z" strokeWidth="1" />
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M2.72998 11.9c0.44624 -0.7325 1.07341 -1.3379 1.82121 -1.7579 0.7478 -0.42012 1.59108 -0.64076 2.44879 -0.64076 0.85771 0 1.70099 0.22064 2.44879 0.64076 0.74783 0.42 1.37493 1.0254 1.82123 1.7579" strokeWidth="1" />
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 13.5c3.5899 0 6.5 -2.9101 6.5 -6.5C13.5 3.41015 10.5899 0.5 7 0.5 3.41015 0.5 0.5 3.41015 0.5 7c0 3.5899 2.91015 6.5 6.5 6.5Z" strokeWidth="1" />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

UserCircleSingle.displayName = 'UserCircleSingle';

export default UserCircleSingle;
