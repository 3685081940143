// @flow

import React from 'react';

import Svg from 'components/svg';

const EyeHidden = Svg((
  <g>
    <path d="M 4.9397202,22.465714 22.967308,4.4381208" />
    <path d="m 21.80397,1.7883918 a 1.125,1.125 0 0 0 -0.785157,0.328125 L 17.526626,5.6087043 C 15.912237,4.7793344 14.090498,4.299134 12.15065,4.299134 c -5.0000005,0 -9.270977,3.1099999 -11.0009771,7.5 0.91162,2.313302 2.5422433,4.252224 4.5996094,5.586914 l -2.7597657,2.759766 a 1.125,1.125 0 0 0 0,1.587891 1.125,1.125 0 0 0 1.5820313,0.01172 l 0.00879,-0.01172 3.2753907,-3.275391 h 0.00293 l 2.1533204,-2.15625 h 0.0029 l 1.558595,-1.561523 h 0.0029 l 3.515625,-3.518555 1.561523,-1.5615238 2.794922,-2.794922 3.149415,-3.1494141 0.0088,-0.00879 a 1.125,1.125 0 0 0 0,-1.5908204 1.125,1.125 0 0 0 -0.802734,-0.328125 z m -9.65332,5.0097656 c 1.172494,0 2.235253,0.4228126 3.087891,1.0986328 l -1.41211,1.4121095 C 13.348273,8.9867777 12.770553,8.799134 12.15065,8.799134 c -1.656854,0 -3.0000005,1.343146 -3.0000005,3 0,0.619903 0.1876435,1.197623 0.5097657,1.675782 L 8.2483057,14.887025 C 7.5724855,14.034387 7.1496729,12.971629 7.1496729,11.799134 c 0,-1.721237 0.8703655,-3.2401871 2.1943359,-4.1396484 C 10.144117,7.1159184 11.110463,6.7981574 12.15065,6.7981574 Z m 8.396484,1.0517578 -3.454101,3.4541018 c 0.01786,0.16584 0.05859,0.324132 0.05859,0.495117 0,2.761424 -2.239552,5.000976 -5.000976,5.000976 -0.170985,0 -0.329277,-0.04073 -0.495117,-0.05859 l -2.2119148,2.211914 c 0.8690588,0.214566 1.7706188,0.345703 2.7070318,0.345703 4.999999,0 9.270976,-3.109999 11.000976,-7.5 C 22.561637,10.30199 21.661862,8.9701073 20.547134,7.8499152 Z" />
    <path d="M 3.6346667,21.140362 21.662251,3.1127732" />
  </g>
));

EyeHidden.displayName = 'EyeHidden';

export default EyeHidden;
