// @flow
import { uniqueId } from 'lodash';

import React from 'react';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_18861_4380');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M13.6482 3.9381L7.28451 0.756261C7.10569 0.666533 6.89441 0.666533 6.71496 0.756261L0.351275 3.9381C0.223365 4.00174 0.126001 4.11183 0.0680914 4.23274C0.0299093 4.31038 0 4.44274 0 4.50765V8.17631C0 8.52695 0.285093 8.81268 0.636368 8.81268C0.987644 8.81268 1.27274 8.52695 1.27274 8.17631V5.4482L2.54547 5.9573V9.07741C2.54547 9.3186 2.68166 9.53878 2.89738 9.64696C3.9913 10.1936 5.11576 10.8682 7.00005 10.8682C8.88434 10.8682 10.0094 10.193 11.1027 9.64696C11.3184 9.53814 11.4546 9.3186 11.4546 9.07741V5.9573L13.6005 5.09884C13.8327 5.00593 13.9893 4.78511 13.9995 4.53438C14.0103 4.28365 13.8722 4.05138 13.6482 3.9381ZM10.1819 8.68414L9.56143 8.99405C7.95715 9.79651 6.04232 9.79651 4.43867 8.99405L3.81821 8.68414V6.46639L6.76332 7.64495C6.83969 7.67549 6.91987 7.68949 7.00005 7.68949C7.08023 7.68949 7.16042 7.67549 7.23678 7.64431L10.1819 6.46576V8.68414ZM7.00005 6.36776L2.18847 4.44274L7.00005 2.03791L11.8116 4.44274L7.00005 6.36776Z"
          fill="currentColor"
        />
        <path
          d="M0.636368 9.59839C0.285093 9.59839 0 9.88348 0 10.2348V11.5075C0 11.8594 0.285093 12.1439 0.636368 12.1439C0.987644 12.1439 1.27274 11.8594 1.27274 11.5075V10.2348C1.27274 9.88348 0.987644 9.59839 0.636368 9.59839Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const Academy = Svg(Fragment, { viewBox: '0 0 14 14' });

Academy.displayName = 'Academy';

export default Academy;
