import Svg from 'components/svg';

const Organization = Svg((
  <>
    <path
      d="M13.3772 12.7H14.6499V14H0.649902V12.7H1.92263C1.92263 11.5745 1.92263 1 1.92263 1H13.3772C13.3772 1 13.3772 11.5745 13.3772 12.7ZM12.1044 12.7V2.3H3.19536V12.7H12.1044ZM5.10445 6.85H7.01354V8.15H5.10445V6.85ZM5.10445 4.25H7.01354V5.55H5.10445V4.25ZM5.10445 9.45H7.01354V10.75H5.10445V9.45ZM8.28627 9.45H10.1954V10.75H8.28627V9.45ZM8.28627 6.85H10.1954V8.15H8.28627V6.85ZM8.28627 4.25H10.1954V5.55H8.28627V4.25Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

Organization.displayName = 'Organization';

export default Organization;
