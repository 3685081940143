import Svg from 'components/svg';

const DocumentStar = Svg((
  <>
    <path d="M24.13 28H41.13" stroke="white" strokeWidth="1.7" strokeLinecap="square" />
    <path d="M24.13 35.285H36.285" stroke="white" strokeWidth="1.7" strokeLinecap="square" />
    <path d="M24.13 42.58H30.21" stroke="white" strokeWidth="1.7" strokeLinecap="square" />
    <path d="M30.21 49.87H19.27V18.275H39.93L46 24.35V29.21" stroke="white" strokeWidth="1.7" strokeLinecap="square" />
    <path d="M42.135 47.005L37.295 42.915L43.615 42.455L46.005 36.59L48.4 42.455L54.72 42.915L49.875 47.005L51.39 53.155L46.005 49.815L40.625 53.155L42.135 47.005Z" stroke="white" strokeWidth="1.7" strokeLinecap="square" />
  </>
), { viewBox: '0 0 70 70', fill: 'none' });

DocumentStar.displayName = 'DocumentStar';

export default DocumentStar;
