// @flow

import React from 'react';

import Svg from 'components/svg';

const NewNotification = Svg((
  <>
    <path d="M7.49967 12.833C8.14134 12.833 8.66634 12.308 8.66634 11.6663H6.33301C6.33301 12.308 6.85217 12.833 7.49967 12.833ZM10.9997 9.33301V6.41634C10.9997 4.62551 10.043 3.12634 8.37467 2.72967V2.33301C8.37467 1.84884 7.98384 1.45801 7.49967 1.45801C7.01551 1.45801 6.62467 1.84884 6.62467 2.33301V2.72967C4.95051 3.12634 3.99967 4.61967 3.99967 6.41634V9.33301L2.83301 10.4997V11.083H12.1663V10.4997L10.9997 9.33301Z" fill="currentColor" />
  </>
), { viewBox: '0 0 14 15' });

NewNotification.displayName = 'NewNotification';

export default NewNotification;
