// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import { isFeatureEnabledSelector } from 'reducers/session';

import Field from 'components/field';
import Info from 'components/icons/info';
import SelectField from 'components/select-field';
import {
  TWO_FACTOR_AUTHENTICATION_NONE,
  getTwoFactorAuthenticationMethod,
} from 'components/two-factor-authentication-method';
import { useReactSelectDropdownMenuPlacement } from 'hooks/use-react-select-dropdown-menu-placement';

type Props = {
  message: MessageTranslator,
  availableMethods: Array<string>,
  onChange?: Option => void,
  initialValue?: string,
  disabled?: boolean,
};

// Also use in add-colleague-body.jsx and default settings
export const TwoStepAuthenticationMethod = ({
  message,
  availableMethods,
  onChange,
  initialValue,
  disabled,
}: Props) => {
  const isPersonalIdFeatureEnabled = useSelector((state) => isFeatureEnabledSelector(state, {
    feature: 'personalIdentificationMfa',
  }));

  const menuPlacement = useReactSelectDropdownMenuPlacement('twoStepAuthenticationMethod');

  const getAvailableMethods = () => (
    availableMethods?.map<any>(
      (method) => {
        let methodValue = method;
        if (method === null) {
          methodValue = TWO_FACTOR_AUTHENTICATION_NONE;
        }
        return ({
          value: methodValue && methodValue,
          label: getTwoFactorAuthenticationMethod(methodValue, message).label,
        });
      },
    )
  );

  const getAccessText = () => {
    if (isPersonalIdFeatureEnabled) {
      return (
        <Message
          id="To access the document, counterparties must enter a code they’ll get via email or SMS, or provide a personal ID number."
          comment="Tooltip for authentication method dropdown"
        />
      );
    }

    return (
      <Message
        id="To access the document, counterparties must enter a code they’ll get via email or SMS."
        comment="Tooltip for authentication method dropdown"
      />
    );
  };

  const description = (
    <>
      <p>
        <Message
          id="Two-factor authentication adds an extra level of security to your document."
          comment="Tooltip for authentication method dropdown"
        />
      </p>
      <p>
        {getAccessText()}
      </p>
    </>
  );

  return (
    <Field
      name="twoStepAuthenticationMethod"
      label={message({
        id: 'Two-factor authentication',
        comment: 'Label for the two-factor authentication method field.',
      })}
      placeholder={(
        <Message
          id="Select two-factor authentication method"
          comment="Placeholder for the two-factor authentication method field."
        />
      )}
      component={SelectField}
      options={getAvailableMethods()}
      clearable={false}
      searchable={false}
      fieldinfo={{
        message: description,
        icon: <Info width="12px" />,
        side: 'top',
        zIndex: '10004',
        nextToLabel: true,
      }}
      onChange={onChange}
      initialValue={initialValue}
      disabled={disabled}
      menuPlacement={menuPlacement}
    />
  );
};

export default localize<Props>(TwoStepAuthenticationMethod);
