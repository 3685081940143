// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
};

export const MessageTemplateName = ({ message, autoFocus }: Props) => (
  <Field
    name="name"
    label={message({ id: 'Template name', comment: 'The field label in add message template page' })}
    placeholder={message({ id: 'Enter name', comment: 'The field placeholder in add message template page' })}
    component={TextField}
    maxLength={50}
    required
    autoFocus={autoFocus}
    autoComplete="off"
  />
);

MessageTemplateName.defaultProps = {
  autoFocus: undefined,
};

export default localize<Props>(MessageTemplateName);
