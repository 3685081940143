// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_30_1401');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M5.00006 17.3333L5.00006 2.625M5.00006 2.625L1.37506 6.2197M5.00006 2.625L8.62506 6.2197" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
        <rect width="9.67" height="1.3" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 10 16.6667)" fill="#013A4C" />
        <rect width="9.67" height="1.3" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 10 12.6666)" fill="#013A4C" />
        <rect width="7" height="1.3" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 12.6666 8.66663)" fill="#013A4C" />
        <rect width="7" height="1.3" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 12.6666 4.66669)" fill="#013A4C" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="20" height="20" fill="white" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 20)" />
        </clipPath>
      </defs>
    </>
  );
};

const SortAscending = Svg(Fragment, { viewBox: '0 0 18 18' });

SortAscending.displayName = 'SortAscending';

export default SortAscending;
