import { Helmet } from 'react-helmet-async';
import faviconAppleTouch from './apple-touch-icon.png';

const FaviconOneflow = () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href={faviconAppleTouch} />
  </Helmet>
);

export default FaviconOneflow;
