// @flow

import React from 'react';

import Svg from 'components/svg';

const ReorderIcon = Svg(
  (
    <>
      <path
        d="M447.755,5172.416a.667.667,0,0,1,.667-.667h.667a.667.667,0,0,1,.667.667v.667a.667.667,0,0,1-.667.667h-.667a.666.666,0,0,1-.667-.667Z"
        transform="translate(-448.3 -5171.75)"
      />
      <path
        d="M456.755,5172.417a.667.667,0,0,1,.667-.667h.667a.667.667,0,0,1,.667.667v.667a.667.667,0,0,1-.667.667h-.667a.667.667,0,0,1-.667-.667Z"
        transform="translate(-453.301 -5171.749)"
      />
      <path
        d="M447.755,5181.417a.667.667,0,0,1,.667-.667h.667a.667.667,0,0,1,.667.667v.667a.666.666,0,0,1-.667.667h-.667a.666.666,0,0,1-.667-.667Z"
        transform="translate(-448.3 -5176.749)"
      />
      <path
        d="M456.755,5181.417a.667.667,0,0,1,.667-.667h.667a.667.667,0,0,1,.667.667v.667a.667.667,0,0,1-.667.667h-.667a.666.666,0,0,1-.667-.667Z"
        transform="translate(-453.301 -5176.749)"
      />
      <path
        d="M447.755,5190.417a.667.667,0,0,1,.667-.667h.667a.667.667,0,0,1,.667.667v.666a.667.667,0,0,1-.667.667h-.667a.667.667,0,0,1-.667-.667Z"
        transform="translate(-448.3 -5181.749)"
      />
      <path
        d="M456.755,5190.417a.667.667,0,0,1,.667-.667h.667a.667.667,0,0,1,.667.667v.666a.667.667,0,0,1-.667.667h-.667a.667.667,0,0,1-.667-.667Z"
        transform="translate(-453.301 -5181.749)"
      />
    </>
  ), { viewBox: '0 0 6 10' },
);

ReorderIcon.displayName = 'ReorderIcon';

export default ReorderIcon;
