// @flow

import * as React from 'react';
import { Message } from '@oneflowab/pomes';

import style from './terms-link.module.scss';

type props = {
  isSelfUpgradeFlow?: boolean,
};

const TermsLink = ({ isSelfUpgradeFlow }: props) => {
  if (isSelfUpgradeFlow) {
    return (
      <a
        href="https://oneflow.com/terms-of-use/"
        target="_blank"
        rel="noopener noreferrer"
        className={style.SelfUpgradeFlowLink}
      >
        <Message
          id="Read our Terms of Use and Data Processing Agreement."
          comment="Link to our terms of use page"
        />
      </a>
    );
  }

  return (
    <Message
      id="I agree to Oneflow's {jsx-start}Terms of Use{jsx-end}."
      comment="Text with link regarding terms of use"
      component="a"
      className={style.Link}
      target="_blank"
      rel="noopener noreferrer"
      href="https://oneflow.com/terms-of-use/"
    />
  );
};

export default TermsLink;
