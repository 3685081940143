// @flow

import urlJoin from 'url-join';
import { matchPath, type Location } from 'react-router';
import get from 'lodash/get';

type WithWorkspaceNamespace = (workspace: Workspace) => (path: string) => string;

const WORKSPACE_NAMESPACE_STRING = '/c/:workspaceId(\\d+)';

export const withWorkspaceNamespacePath = (path: string) => (
  urlJoin(WORKSPACE_NAMESPACE_STRING, path)
);

export const withWorkspaceNamespace: WithWorkspaceNamespace = (workspace) => (path) => (
  urlJoin(`/c/${workspace.id}`, path)
);

export const readWorkspaceIdFromLocation = (location: Location) => {
  const match = matchPath(location.pathname, {
    path: WORKSPACE_NAMESPACE_STRING,
    exact: false,
    strict: false,
  });

  if (!match) {
    return null;
  }

  return Number.parseInt(get(match, 'params.workspaceId'), 10);
};

export const pathWithWorkspaceNamespace = (path: string) => [
  withWorkspaceNamespacePath(path),
  path,
];
