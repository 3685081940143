// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837:19947');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.14995 -0.149902V0.850063L6.84995 0.850098V-0.149902H8.14995V0.85011L10.1499 0.850141V10.1501H7.84995V8.8501H8.84995V4.6501H1.14992L1.15 8.8501H2.14995V10.1501H-0.149943L-0.150146 0.850039L1.84995 0.850051V-0.149902H3.14995ZM1.84995 2.15005L1.14987 2.15004L1.14989 3.3501H8.84995V2.15012L3.14995 2.15006L1.84995 2.15005ZM3.76313 5.60581L4.99996 6.84314L6.23676 5.60583L7.15619 6.52489L5.91901 7.76256L7.15618 9.00023L6.23676 9.91928L4.99996 8.68198L3.76313 9.91931L2.84371 9.00026L4.08091 7.76256L2.8437 6.52486L3.76313 5.60581Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const CalendarCross = Svg(Fragment, { viewBox: '0 0 10 10', fill: 'none' });

CalendarCross.displayName = 'CalendarCross';

export default CalendarCross;
