// @flow

import Rollbar from 'rollbar';
import each from 'lodash/each';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import getEnvironment, { getCurrentVersion, isEnvironment } from 'utils/environment';
import { getRollbarUrl, replaceUrl } from './helpers';

type TransformHelper = (payload: any) => void;

export const sanitizeUrl: TransformHelper = (payload) => {
  const url = get(payload, 'request.url');
  if (!url) {
    return;
  }

  const replacedUrl = replaceUrl(url);

  set(payload, 'request.url', replacedUrl);

  const errorContext = get(payload, 'body.trace.extra.errorContext');
  if (errorContext) {
    set(payload, 'context', errorContext);
  }
};

const logCategories = {
  CKEditor: 'ckeditor',
  Pusher: 'push/dist/pusher',
  'Unknown error': 'script error',
};
const prefixCategory: TransformHelper = (trace) => {
  if (isEmpty(trace)) {
    return;
  }

  try {
    let framesContent = '';
    const stack = get(trace, 'extra.data.stack');

    if (isArray(trace.frames) && trace.frames.length) {
      framesContent = trace.frames.reduce((content, frame) => (
        content + (frame.filename || '') + (frame.method || '')
      ), '');
    } else if (isArray(stack)) {
      framesContent = stack.join(' ');
    }

    each(logCategories, (pattern, category) => {
      // Check if the pattern matches
      if (!framesContent.toLowerCase().includes(pattern.toLowerCase())) {
        return;
      }

      const dataMessage = get(trace, 'extra.data.message');
      const exceptionMessage = get(trace, 'exception.message');
      const exceptionDescription = get(trace, 'exception.description');

      if (dataMessage) {
        set(trace, 'extra.data.message', `[${category}] ${trace.extra.data.message}`);
      }

      if (exceptionMessage) {
        set(trace, 'exception.message', `[${category}] ${exceptionMessage}`);
      }

      if (exceptionDescription) {
        set(trace, 'exception.description', `[${category}] ${exceptionDescription}`);
      }
    });
    // eslint-disable-next-line no-empty
  } catch (e) { }
};

const scrubFields = ['value', 'password', 'password_again', 'password_verification'];

const ignoredMessages = [
  'ResizeObserver loop completed with undelivered notifications',
];

const transform: TransformHelper = (payload) => {
  sanitizeUrl(payload);

  // Stack trace
  prefixCategory(get(payload, 'data.body.trace'));

  // if extra.data is not set on message, then to do nothing
  let extraData = get(payload, 'data.body.message.extra.data');
  if (!extraData) {
    return;
  }

  const basicMatches = filter(scrubFields, (field) => {
    const regexp = new RegExp(field, 'i');
    return regexp.test(extraData);
  });

  if (!basicMatches.length) {
    return;
  }

  each(basicMatches, (field) => {
    const regexp = new RegExp(`\\\\?["']${field}\\\\?["'] *: *\\\\?["'].*?\\\\?["']`, 'i');
    extraData = extraData.replace(regexp, `\\"${field}\\":\\"*****\\"`);
  });

  set(payload, 'data.body.message.extra.data', extraData);
};

const rollbarConfig = {
  scrubFields,
  transform,
  accessToken: process.env.ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: false,
  ignoredMessages,
  autoInstrument: true,
  reportLevel: 'error',
  endpoint: getRollbarUrl(),
  payload: {
    environment: getEnvironment(),
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: getCurrentVersion(),
      },
    },
  },
};

export const init = () => {
  const rollbarInstance = Rollbar.init(rollbarConfig);

  rollbarInstance.global({ itemsPerMinute: 300 });
  return rollbarInstance;
};

type Payload = {
  person: {
    id: string | number,
  },
};
type SetPayload = Payload => any;
export const setPayload: SetPayload = ({ person }) => {
  if (!window.rollbar || isEnvironment('development')) {
    return {};
  }

  const settings = {
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: getCurrentVersion(),
        },
      },
      environment: getEnvironment(),
      person: {
        id: person.id,
        username: person.id,
      },
    },
  };

  return Rollbar.configure(settings);
};

export default {
  init,
  setPayload,
  transform,
  sanitizeUrl,
};
