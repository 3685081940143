import { connectRouter } from 'connected-react-router';

import history from 'store/history';

type RootState = {
  router: ReturnType<ReturnType<typeof connectRouter>>;
};

export const getLocationSelector = (state: RootState) => state.router.location;

export const getPathnameSelector = (state: RootState) => (state.router.location ? state.router.location.pathname : '/');

export const ORIGINAL_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const LOCATION_CHANGE = 'router/LOCATION_CHANGE';
export const SEARCH_CHANGE = 'router/SEARCH_CHANGE';
export const LOCATION_ASSIGN = 'router/LOCATION_ASSIGN';

type LocationChangeAction = {
  action: string;
  location: Location;
  previousLocation: Location;
};

export const locationChange = ({ action, location, previousLocation }: LocationChangeAction) => ({
  type: LOCATION_CHANGE,
  action,
  location,
  previousLocation,
});

type SearchChangeAction = {
  action: string;
  location: Location;
  previousLocation: Location;
};

export const searchChange = ({ action, location, previousLocation }: SearchChangeAction) => ({
  type: SEARCH_CHANGE,
  action,
  location,
  previousLocation,
});

type LocationAssignAction = {
  path: string;
};

export const locationAssign = ({ path }: LocationAssignAction) => ({
  type: LOCATION_ASSIGN,
  path,
});

export default connectRouter(history);
