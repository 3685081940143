/* eslint-disable max-classes-per-file */
// @flow

import { camelizeKeys } from 'humps';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { getCurrentVersion } from 'utils/environment';

import readCookies from 'utils/read-cookies';
import * as amplitude from '@amplitude/analytics-browser';
import { setReloadMarker } from './reload-marker';

export const OPERATOR_AND = 'AND';
export const OPERATOR_OR = 'OR';

const DEFAULT_LIMIT = 20;

export class ServerError {
  message: string

  response: Response

  body: string

  constructor(message: string, response: Response, body: string) {
    this.message = message;
    this.response = response;
    this.headers = Object.fromEntries(response.headers.entries());
    this.body = body;
  }
}

export class APIError {
  message: string

  response: Response

  body: string

  constructor(message: string, response: Response, body: string) {
    this.message = message;
    this.response = response;
    this.body = body;
  }
}

const formatSort = (sort?: Array<string>) => {
  if (!isArray(sort) || isEmpty(sort)) {
    return undefined;
  }

  return sort.join(',');
};

export const readErrorBody = (response: Response) => {
  const isJsonBody = /^application\/json/;
  const contentType = response.headers.get('content-type');

  if (!contentType) {
    return '';
  }

  if (isJsonBody.test(contentType)) {
    return response.json();
  }

  return response.text();
};

export const checkResponse = async (response: Response) => {
  if (response.status >= 500) {
    const body = await readErrorBody(response);

    throw new ServerError(response.statusText, response, body);
  }

  if (!response.ok) {
    const body = await readErrorBody(response);

    throw new APIError(response.statusText, response, body);
  }

  return response;
};

export const storeHeaders = async (response: Response) => {
  const { headers } = response;

  setReloadMarker(headers.get('x-flow-reload-marker'));
  window.currentVersion = getCurrentVersion();

  return response;
};

export const camelize = (response: any) => camelizeKeys(response, (key, convert) => {
  /**
   * Every string that has a ":" between two words is considered an ACL
   * therefore it will NOT be camelized
   * Check the tests for examples
   */
  if (/.+:.+/.test(key)) {
    return key;
  }

  return convert(key);
});

type ResponseToJson = (response: Response) => any;

export const extractResponseBodyAsJSON: ResponseToJson = (response) => (
  response.json().then(camelize)
);

const baseApiUrl = () => process.env.ONEFLOW_API_URL || '/api';

export const getAPIPath = (resource: string) => `${baseApiUrl()}${resource}`;

export const cleanParameter = (param?: any) => (isUndefined(param) || param === '' || (isArray(param) && isEmpty(param)));

export const getUrlWithParams = (url: string, params: any) => (
  `${url}?${(map(omitBy(params, cleanParameter), (value, key) => {
    // check if array
    if (Array.isArray(value)) {
      // if it is, then write out like multiple key[]=value (where %5B%5D == [], url encoded)
      return map(omitBy(value, cleanParameter), (v) => `${key}%5B%5D=${encodeURIComponent(v)}`).join('&');
    }
    // not array value
    return `${key}=${encodeURIComponent(value)}`;
  }).join('&'))}`
);

const getCompleteURL = (url) => (params) => {
  if (params) {
    return getUrlWithParams(getAPIPath(url), params);
  }
  return getAPIPath(url);
};

export type SortParams = {
  attr?: string,
  direction?: string,
};

type SortGenerator = (SortParams) => {
  sort?: string,
};

export const buildSort: SortGenerator = ({ attr, direction = '' }) => (
  attr ? { sort: `${attr}:${direction}` } : {}
);

export const getAmplitudeSessionId = () => {
  if (amplitude.getSessionId) {
    return amplitude.getSessionId();
  }
  return null;
};

export const getAmplitudeDeviceId = () => {
  if (amplitude.getDeviceId) {
    return amplitude.getDeviceId();
  }
  return null;
};

export type RequestOptions = {|
  token: string,
  amplitudeScope: string,
  signal?: AbortSignal,
|};

type Request = ({
  url: string,
  params?: {
    [any]: any,
  },
  body?: {
    [any]: any,
  },
}, options?: RequestOptions) => Promise<Response>;

export const get: Request = ({ url, params }, options) => {
  let headers = {
    'Content-Type': 'application/json',
    'x-flow-react-client-version': getCurrentVersion(),
    'X-Flow-Source': 'Oneflow App',
    'X-Flow-Amplitude-Session-Id': getAmplitudeSessionId(),
    'X-Flow-Amplitude-Device-Id': getAmplitudeDeviceId(),
  };

  if (options && options.token) {
    headers = {
      ...headers,
      'X-Flow-Access-Token': options.token,
    };
  }

  if (options && options.timezone) {
    headers = {
      ...headers,
      'X-Flow-Browser-Timezone': options.timezone,
    };
  }

  const fetchOptions = {
    method: 'GET',
    credentials: 'same-origin',
    headers,
  };

  if (options?.signal) {
    fetchOptions.signal = options?.signal;
  }

  return fetch(getCompleteURL(url)(params), fetchOptions)
    .then(checkResponse)
    .then(storeHeaders);
};

const DEFAULT_PAGINATION = { limit: DEFAULT_LIMIT, offset: 0 };

export type MultipleParams = {
  params?: {
    [any]: any,
  },
  pagination?: {
    [any]: any,
  },
  sorting?: SortParams,
  sort?: Array<string>,
};

export type MultipleParamsWithUrl = MultipleParams & {
  url: string,
};

export type GetRequestParams = MultipleParams => {};

export const getRequestParams: GetRequestParams = ({
  pagination = {},
  params = {},
  sorting = {},
  sort,
}) => ({
  ...DEFAULT_PAGINATION,
  ...pagination,
  ...params,
  sort: formatSort(sort),
  ...buildSort(sorting),
});

export type RequestWithPagination = MultipleParamsWithUrl => Promise<Response>;

export const getMultiple: RequestWithPagination = ({
  url,
  pagination = {},
  params = {},
  sorting = {},
  sort,
}) => (
  get({
    url,
    params: getRequestParams({
      pagination,
      params,
      sorting,
      sort,
    }),
  })
);

export const post: Request = ({ url, body }, options) => {
  let headers = {
    'Content-Type': 'application/json',
    'x-flow-react-client-version': getCurrentVersion(),
    'x-xsrf-token': readCookies()['xsrf-token'] || '',
    'X-Flow-Source': 'Oneflow App',
    'X-Flow-Amplitude-Session-Id': getAmplitudeSessionId(),
    'X-Flow-Amplitude-Device-Id': getAmplitudeDeviceId(),
  };

  if (options && options.token) {
    headers = {
      ...headers,
      'X-Flow-Access-Token': options.token,
    };
  }

  if (options && options.amplitudeScope) {
    headers = {
      ...headers,
      'X-Flow-Amplitude-Location': options.amplitudeScope,
    };
  }

  return (
    fetch(getAPIPath(url), {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers,
      signal: options?.signal,
    }).then(checkResponse)
      .then(storeHeaders)
  );
};

export const postMultipart: Request = ({ url, body }, options) => {
  let headers = {
    'x-flow-react-client-version': getCurrentVersion(),
    'x-xsrf-token': readCookies()['xsrf-token'] || '',
    'X-Flow-Source': 'Oneflow App',
    'X-Flow-Amplitude-Session-Id': getAmplitudeSessionId(),
    'X-Flow-Amplitude-Device-Id': getAmplitudeDeviceId(),
  };

  if (options && options.token) {
    headers = {
      ...headers,
      'X-Flow-Access-Token': options.token,
    };
  }

  return (
    fetch(getAPIPath(url), {
      method: 'POST',
      credentials: 'same-origin',
      body,
      headers,
    }).then(checkResponse)
      .then(storeHeaders)
  );
};

export const put: Request = ({ url, body }, options) => {
  let headers = {
    'Content-Type': 'application/json',
    'x-flow-react-client-version': getCurrentVersion(),
    'x-xsrf-token': readCookies()['xsrf-token'] || '',
    'X-Flow-Source': 'Oneflow App',
    'X-Flow-Amplitude-Session-Id': getAmplitudeSessionId(),
    'X-Flow-Amplitude-Device-Id': getAmplitudeDeviceId(),
  };

  if (options && options.token) {
    headers = {
      ...headers,
      'X-Flow-Access-Token': options.token,
    };
  }

  if (options && options.amplitudeScope) {
    headers = {
      ...headers,
      'X-Flow-Amplitude-Location': options.amplitudeScope,
    };
  }

  return fetch(getAPIPath(url), {
    method: 'PUT',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    headers,
  }).then(checkResponse)
    .then(storeHeaders);
};

export const remove: Request = ({ url, body }, options) => {
  let headers = {
    'Content-Type': 'application/json',
    'x-flow-react-client-version': getCurrentVersion(),
    'x-xsrf-token': readCookies()['xsrf-token'] || '',
    'X-Flow-Source': 'Oneflow App',
    'X-Flow-Amplitude-Session-Id': getAmplitudeSessionId(),
    'X-Flow-Amplitude-Device-Id': getAmplitudeDeviceId(),
  };

  if (options && options.amplitudeScope) {
    headers = {
      ...headers,
      'X-Flow-Amplitude-Location': options.amplitudeScope,
    };
  }

  return fetch(getAPIPath(url), {
    method: 'DELETE',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    headers,
  }).then(checkResponse)
    .then(storeHeaders);
};

export type RequestHasAny = (
  { url: string }) => ({ params?: {}, entityId?: any, props?: any, }
) => Promise<any>;

export const hasAnyEntity: RequestHasAny = ({ url }) => ({ params, entityId, props }) => {
  const { returnEntity } = { ...props };

  return (
    getMultiple({
      url,
      params,
      pagination: {
        limit: 1,
        offset: 0,
      },
    })
      .then(extractResponseBodyAsJSON)
      .then(({ collection }) => {
        if (isEmpty(collection)) {
          return false;
        }

        if (entityId && lodashGet(collection[0], 'id') === entityId) {
          return false;
        }

        if (returnEntity) {
          return {
            hasAnyEntity: collection.length > 0,
            entity: collection,
          };
        }

        return collection.length > 0;
      })
  );
};
