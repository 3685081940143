// @flow

import React from 'react';

import Svg from 'components/svg';

const TextColor = Svg(({ pathColor }) => (
  <>
    <path d="M4 17H20V21H4V17Z" fill={pathColor || 'currentColor'} />
    <path d="M7 15L10.7249 5H13.2751L17 15H14.7898L13.9397 12.5606H10.0139L9.17929 15H7ZM11.5286 8.16667L10.5394 11.0152H13.4142L12.456 8.19697L11.9923 6.68182H11.9614L11.5286 8.16667Z" fill="currentColor" />

  </>
), { viewBox: '0 0 24 24', fill: 'none' });

TextColor.displayName = 'TextColor';

export default TextColor;
