import Svg from 'components/svg';

const Monitor = Svg((
  <g clipPath="url(#clip0_1465_243)">
    <path d="M14.72 1.56H1.27997C0.970714 1.56 0.719971 1.81074 0.719971 2.12V11.08C0.719971 11.3893 0.970714 11.64 1.27997 11.64H14.72C15.0292 11.64 15.28 11.3893 15.28 11.08V2.12C15.28 1.81074 15.0292 1.56 14.72 1.56Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.87995 11.64L5.75995 14.44" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.12 11.64L10.24 14.44" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.63995 14.44H11.36" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>
), { viewBox: '0 0 16 16', fill: 'none' });

Monitor.displayName = 'Monitor';

export default Monitor;
