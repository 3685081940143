import normalizeEntity from 'normalized-redux/entity-normalizer';

const rpcs = [
  'buySeats',
  'exportAccount',
  'startTrial',
  'updateAccountDataManagement',
  'createCancellation',
] as const;

const normalizedAccounts = normalizeEntity<Oneflow.Account, 'account', typeof rpcs>({
  entity: 'account',
  rpcs,
});

export default normalizedAccounts;
