import Svg from 'components/svg';

const BurgerMenu = Svg((
  <>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M1 9.01587H16C16.552 9.01587 17 8.56076 17 8C17 7.43924 16.552 6.98413 16 6.98413H1C0.448 6.98413 0 7.43924 0 8C0 8.56076 0.448 9.01587 1 9.01587ZM1 2.03175H19C19.552 2.03175 20 1.57664 20 1.01587C20 0.455111 19.552 0 19 0H1C0.448 0 0 0.455111 0 1.01587C0 1.57664 0.448 2.03175 1 2.03175ZM1 16H19C19.552 16 20 15.5449 20 14.9841C20 14.4234 19.552 13.9683 19 13.9683H1C0.448 13.9683 0 14.4234 0 14.9841C0 15.5449 0.448 16 1 16Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 20 16', fill: 'none' });

BurgerMenu.displayName = 'BurgerMenu';

export default BurgerMenu;
