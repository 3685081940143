/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { localize, Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import { amplitudeLogEvent } from 'client-analytics/amplitude';

import Button from 'components/button';
import SignupUserDetails from './signup-user-details/signup-user-details';
import SignupCompanyDetails from './signup-company-details/signup-company-details';
import SignupSettingsDetails from './signup-settings-details/signup-settings-details';

import style from './signup-flow.module.scss';

const SignupFlowFields = () => {
  const [step, setStep] = useState('1');
  const formState = useFormState();

  const stepOneEvent = 'Go to Signup Personal Information';
  const stepTwoEvent = 'Go to Signup Company Details';
  const stepThreeEvent = 'Go to Signup Complete Registration';

  useEffect(() => {
    const pageViewEvent = 'Pageview';
    const pageViewEventProp = {
      'page url': '/signup/xx',
      'page title': document.title,
      'page path': '/signup/xx',
      domain: window.location.hostname,
    };
    amplitudeLogEvent(pageViewEvent, pageViewEventProp);
    amplitudeLogEvent(stepOneEvent);
  }, []);

  const isDisabled = () => {
    const {
      hasSubmitErrors,
      dirtySinceLastSubmit,
      validating,
      submitting,
      pristine,
      invalid,
      hasValidationErrors,
    } = formState;

    if (hasSubmitErrors && dirtySinceLastSubmit && !hasValidationErrors) {
      return false;
    }

    return !!(validating || submitting || pristine || invalid);
  };

  const settingsActionButtonsClasses = clsx(style.ActionButtons, style.SettingsActionButtons);

  const actionButtons = {
    1: (
      <div className={style.ActionButtons}>
        <Button
          kind="primary"
          onClick={() => {
            setStep('2');
            amplitudeLogEvent(stepTwoEvent);
          }}
          disabled={isDisabled()}
        >
          <Message
            id="Next step"
            comment="Button text for going to the second step of the sign up flow."
          />
        </Button>
      </div>
    ),
    2: (
      <div className={style.ActionButtons}>
        <Button
          kind="linkSeparate"
          customClass={style.ButtonPrevious}
          onClick={() => {
            setStep('1');
            amplitudeLogEvent(stepOneEvent);
          }}
        >
          <Message
            id="Previous"
            comment="Button text for going to the previous step of the sign up flow."
          />
        </Button>
        <Button
          kind="primary"
          onClick={() => {
            setStep('3');
            amplitudeLogEvent(stepThreeEvent);
          }}
          disabled={isDisabled()}
        >
          <Message
            id="Next step"
            comment="Button text for going to the third step of the sign up flow."
          />
        </Button>
      </div>
    ),
    3: (
      <div className={settingsActionButtonsClasses}>
        <Button
          kind="linkSeparate"
          customClass={style.ButtonPrevious}
          onClick={() => {
            setStep('2');
            amplitudeLogEvent(stepTwoEvent);
          }}
        >
          <Message
            id="Previous"
            comment="Button text for going to the previous step of the sign up flow."
          />
        </Button>
        <Button
          kind="primary"
          type="submit"
          disabled={isDisabled() || !formState.values.hasAgreedToTerms}
        >
          <Message
            id="Create your free account"
            comment="Button text for completing the sign up flow."
          />
        </Button>
      </div>
    ),
  };

  const renderActionButtons = () => actionButtons[step];

  const renderStepsDetails = () => (
    <span className={style.StepDetails}>
      <Message
        id="Step {step} out of 3"
        comment="Step details for the first step of the sign up flow."
        values={{
          step,
        }}
      />
    </span>
  );

  const renderStep1 = () => {
    if (step !== '1') {
      return null;
    }

    const stepContainerClasses = clsx(style.FormContainer, {
      [style.HideStep]: step !== '1',
    });

    return (
      <div className={stepContainerClasses}>
        <div className={style.TitleContainer}>
          <span className={style.TitleHeader}>
            <Message
              id="Personal details"
              comment="Header text for first step of the sign up flow."
            />
          </span>
          {renderStepsDetails()}
        </div>
        <SignupUserDetails />
        {renderActionButtons()}
      </div>
    );
  };

  const renderStep2 = () => {
    if (step !== '2') {
      return null;
    }

    const stepContainerClasses = clsx(style.FormContainer, {
      [style.HideStep]: step !== '2',
    });

    return (
      <div className={stepContainerClasses}>
        <div className={style.TitleContainer}>
          <span className={style.TitleHeader}>
            <Message
              id="Company details"
              comment="Header text for second step of the sign up flow."
            />
          </span>
          {renderStepsDetails()}
        </div>
        <SignupCompanyDetails />
        {renderActionButtons()}
      </div>
    );
  };

  const renderStep3 = () => {
    if (step !== '3') {
      return null;
    }

    const stepContainerClasses = clsx(style.FormContainer, {
      [style.HideStep]: step !== '3',
    });

    return (
      <>
        <div className={stepContainerClasses}>
          <div className={style.TitleContainer}>
            <span className={style.TitleHeader}>
              <Message
                id="Settings"
                comment="Header text for third step of the sign up flow."
              />
            </span>
            {renderStepsDetails()}
          </div>
          <SignupSettingsDetails />
          {renderActionButtons()}
        </div>
      </>
    );
  };

  return (
    <>
      {renderStep1()}
      {renderStep2()}
      {renderStep3()}
    </>
  );
};

export default localize(SignupFlowFields);
