import Svg from 'components/svg';

const HatMagic = Svg((
  <>
    <path
      d="M54.2446 40.1328L57.47 69.999H23.0191L26.2369 40.1328"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      d="M55.2507 49.4648H25.2156"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      d="M16 40.1328H64.5354"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M68.2216 14L42.1108 30.8031"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M17.843 17.7324H25.3153"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M21.5754 14V21.4646"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M27.1816 28.9453H34.6463"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M30.9138 25.2051V32.6697"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
  </>
), { viewBox: '0 0 84 84', fill: 'none' });

HatMagic.displayName = 'HatMagic';

export default HatMagic;
