import { isEmpty } from 'lodash';
import { TYPE_IS_ORGANIZER } from './constants';

const isInternalApprover = (participant) => {
  if (isEmpty(participant) || isEmpty(participant.roles)) {
    return false;
  }

  return (
    participant.type === TYPE_IS_ORGANIZER && participant.roles[0].role === 'DRAFT_APPROVER'
  );
};

export default isInternalApprover;
