import type { Reducer } from 'redux';

import { createReducer, formatActionType } from 'normalized-redux/reducers';
import { FormattedActionType } from 'normalized-redux/reducers/format-action-type';
import { Entity, Pipe } from 'normalized-redux/entity-normalizer/types';

type Action<T extends string = string> = {
  type: T,
  id: number,
  name?: string,
};

type RemoveState = {
  id?: number,
  data: Record<string, any>,
  success: boolean,
  loading: boolean,
  error?: string,
}

type LocalState<E extends Entity> = {
  [key in E['id'] | NonNullable<Action['name']>]: RemoveState;
};

type RemoveStartAction<T extends string> = Action<T> & { data?: Record<string, any>, pipe?: Pipe };
type RemoveSuccessAction<T extends string> = Action<T> & { data?: Record<string, any> };
// TODO: figure out a way to make error required
type RemoveFailAction<T extends string> = Action<T> & { error?: any };
type RemoveResetAction<T extends string> = Action<T>;

const actionTypes = {
  REMOVE_START: 'REMOVE_START',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
  REMOVE_RESET: 'REMOVE_RESET',
} as const;

export const initialRemoveState: LocalState<Entity>[Entity['id']] = {
  id: undefined,
  data: {},
  success: false,
  loading: false,
  error: undefined,
};

export const initialState: LocalState<Entity> = {};

type CreateRemoveStartActionArgs<E extends Entity> = {
  id: E['id'],
  name?: Action['name'],
  data?: LocalState<E>[E['id']]['data'],
  pipe?: Pipe,
};

// actions
export const createRemoveStartAction = <E extends Entity, T extends string >(type: T) => ({
  id,
  name,
  data,
  pipe,
}: CreateRemoveStartActionArgs<E>): RemoveStartAction<T> => ({
  type,
  id,
  name,
  data,
  pipe,
});

type CreateRemoveSuccessActionArgs<E extends Entity> = {
  id: E['id'],
  name?: Action['name'],
};

export const createRemoveSuccessAction = <E extends Entity, T extends string>(type: T) => ({
  id,
  name,
}: CreateRemoveSuccessActionArgs<E>): RemoveSuccessAction<T> => ({
  type,
  id,
  name,
});

type CreateRemoveFailActionArgs<E extends Entity> = {
  id: E['id'],
  name?: Action['name'],
  error: any,
};

export const createRemoveFailAction = <E extends Entity, T extends string>(type: T) => ({
  id,
  name,
  error,
}: CreateRemoveFailActionArgs<E>): RemoveFailAction<T> => ({
  type,
  id,
  name,
  error,
});

type CreateRemoveResetActionArgs<E extends Entity> = {
  id: E['id'],
  name?: Action['name'],
};

export const createRemoveResetAction = <E extends Entity, T extends string>(type: T) => ({
  id,
  name,
}: CreateRemoveResetActionArgs<E>): RemoveResetAction<T> => ({
  type,
  id,
  name,
});

type GetEntityKeyArgs = Pick<Action, 'id' | 'name'>;
const getEntityKey = (action: GetEntityKeyArgs) => action.name || action.id;

// handlers
export const removeStartHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: RemoveStartAction<T>) => ({
    ...state,
    [getEntityKey(action)]: {
      id: action.id,
      data: action.data,
      loading: true,
      success: false,
      error: undefined,
    },
  });

export const removeSuccessHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: RemoveSuccessAction<T>) => ({
    ...state,
    [getEntityKey(action)]: {
      ...initialRemoveState,
      ...state[getEntityKey(action)],
      id: action.id,
      success: true,
      loading: false,
      error: undefined,
    },
  });

export const removeFailHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: RemoveFailAction<T>) => ({
    ...state,
    [getEntityKey(action)]: {
      ...initialRemoveState,
      ...state[getEntityKey(action)],
      success: false,
      loading: false,
      error: action.error,
    },
  });

export const removeResetHandler = <
  E extends Entity,
  T extends string
>(state: LocalState<E>, action: RemoveResetAction<T>) => ({
    ...state,
    [getEntityKey(action)]: {
      ...initialRemoveState,
    },
  });

// selectors
type GetRemoveSelectorArgs = Pick<Action, 'id' | 'name'>;
export const getRemoveSelector = <
  E extends Entity
>(state: LocalState<E>, action: GetRemoveSelectorArgs): LocalState<E>[E['id']] => {
  if (!state[getEntityKey(action)]) {
    return initialRemoveState;
  }

  return state[getEntityKey(action)];
};

type FormattedRemoveStartActionType<Key extends string> = FormattedActionType<Key, 'REMOVE_START'>;
type FormattedRemoveSuccessActionType<Key extends string> = FormattedActionType<Key, 'REMOVE_SUCCESS'>;
type FormattedRemoveFailActionType<Key extends string> = FormattedActionType<Key, 'REMOVE_FAIL'>;
type FormattedRemoveResetActionType<Key extends string> = FormattedActionType<Key, 'REMOVE_RESET'>;

export type RemoveReducer<E extends Entity, Key extends string> = {
  reducer: Reducer<LocalState<E>, any>
  actions: {
    removeStart: (
      args: CreateRemoveStartActionArgs<E>
    ) => RemoveStartAction<FormattedRemoveStartActionType<Key>>;
    removeSuccess: (
      args: CreateRemoveSuccessActionArgs<E>
      ) => RemoveSuccessAction<FormattedRemoveSuccessActionType<Key>>;
    removeFail: (
      args: CreateRemoveFailActionArgs<E>
      ) => RemoveFailAction<FormattedRemoveFailActionType<Key>>;
    removeReset: (
      args: CreateRemoveResetActionArgs<E>
    ) => RemoveResetAction<FormattedRemoveResetActionType<Key>>;
    types: {
      removeStart: FormattedRemoveStartActionType<Key>;
      removeSuccess: FormattedRemoveSuccessActionType<Key>;
      removeFail: FormattedRemoveFailActionType<Key>;
      removeReset: FormattedRemoveResetActionType<Key>;
    };
  };
  selectors: {
    getRemoveSelector: (state: LocalState<E>, action: GetRemoveSelectorArgs) => LocalState<E>[Action['id']];
  };
};

// initializer
const createRemoveReducer = <
  E extends Entity,
  Key extends string,
>({ key }: { key: Key }): RemoveReducer<E, Key> => {
  const REMOVE_START = formatActionType({ key, type: actionTypes.REMOVE_START });
  const REMOVE_SUCCESS = formatActionType({ key, type: actionTypes.REMOVE_SUCCESS });
  const REMOVE_FAIL = formatActionType({ key, type: actionTypes.REMOVE_FAIL });
  const REMOVE_RESET = formatActionType({ key, type: actionTypes.REMOVE_RESET });

  return ({
    reducer: createReducer(initialState, {
      [REMOVE_START]: removeStartHandler,
      [REMOVE_SUCCESS]: removeSuccessHandler,
      [REMOVE_FAIL]: removeFailHandler,
      [REMOVE_RESET]: removeResetHandler,
    }),
    actions: {
      removeStart: createRemoveStartAction(REMOVE_START),
      removeSuccess: createRemoveSuccessAction(REMOVE_SUCCESS),
      removeFail: createRemoveFailAction(REMOVE_FAIL),
      removeReset: createRemoveResetAction(REMOVE_RESET),
      types: {
        removeStart: REMOVE_START,
        removeSuccess: REMOVE_SUCCESS,
        removeFail: REMOVE_FAIL,
        removeReset: REMOVE_RESET,
      },
    },
    selectors: {
      getRemoveSelector,
    },
  });
};

export default createRemoveReducer;
