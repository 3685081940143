// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import { PasswordFieldWithEye } from 'components/text-field';

type Props = {
  message: MessageTranslator,
  hideRequired: boolean,
};

export const PublicPasswordConfirmation = ({ message, hideRequired = false }: Props) => (
  <Field
    name="passwordConfirmation"
    component={PasswordFieldWithEye}
    label={message({
      id: 'Password confirmation',
      comment: 'Label for the confirm password field on a public page.',
    })}
    placeholder={message({
      id: 'Confirm password',
      comment: 'Confirm password placeholder on a public page.',
    })}
    responsive
    required
    hideRequired={hideRequired}
  />
);

export default localize<Props>(PublicPasswordConfirmation);
