// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_3245_173003');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M5.27116 66.8229L3.08816 29.6643C3.06728 29.305 3.12002 28.9452 3.24312 28.607C3.36622 28.2688 3.55711 27.9594 3.80407 27.6975C4.05103 27.4357 4.34886 27.2271 4.6793 27.0845C5.00974 26.9419 5.36583 26.8683 5.72573 26.8682H29.929C30.5194 26.8671 31.0932 27.0639 31.5588 27.4269C32.0244 27.79 32.355 28.2986 32.4979 28.8714L34.6386 37.4396H68.7843C69.1489 37.4385 69.5098 37.5129 69.8443 37.6581C70.1788 37.8033 70.4796 38.0162 70.7278 38.2833C70.9761 38.5504 71.1664 38.8659 71.2867 39.2101C71.4071 39.5543 71.4549 39.9197 71.4272 40.2833L69.3816 66.918C69.2789 68.2482 68.6769 69.4903 67.6966 70.3952C66.7162 71.3 65.43 71.8007 64.0959 71.7967H10.5516C9.20304 71.7977 7.9051 71.2833 6.92346 70.3586C5.94182 69.434 5.35072 68.1691 5.27116 66.8229Z"
          stroke="currentColor"
          strokeWidth="4.93333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7814 16.2971V3.08275C23.242 3.08276 68.9957 3.0829 68.9957 3.0829V26.8685"
          stroke="currentColor"
          strokeWidth="4.93333"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M39.924 16.2973H55.7812"
          stroke="currentColor"
          strokeWidth="4.93333"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="74" height="74" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const ContractListFolderIcon = Svg(
  Fragment,
  { fill: 'none', viewBox: '0 0 74 74' },
);

ContractListFolderIcon.displayName = 'ContractListFolderIcon';

export default ContractListFolderIcon;
