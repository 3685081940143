import React from 'react';

import Svg from 'components/svg';

const Comment = Svg(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80004 1.80007C1.80005 3.05863 1.80007 4.3172 1.80008 5.57577C1.80013 8.67274 1.80017 11.7697 1.80003 14.8667L6.20004 14.8667V18.4667L8.49337 16.7467L9.45337 18.0267L4.60004 21.6667V16.4667L0.199951 16.4667L0.199995 15.6667C0.200179 12.3031 0.200131 8.93948 0.200084 5.57585C0.200062 4.0506 0.200041 2.52534 0.200041 1.00007L0.200041 0.200074L20.0659 0.200073C20.0661 0.200073 20.0667 0.200073 20.0667 1.00007L20.0659 0.200073L20.8667 0.200073V7.66673H19.2667V1.80007H1.80004Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6001 4.6001H16.4668V6.2001H4.6001V4.6001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6001 9H9.13343V10.6H4.6001V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4667 9H23.8V19.4H20.8667V23.6292L15.3936 19.4H10.4667V9ZM12.0667 10.6V17.8H15.9398L19.2667 20.3708V17.8H22.2V10.6H12.0667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8889 13.4H20.3778V15H13.8889V13.4Z"
      fill="currentColor"
    />
  </>,
  { viewBox: '0 0 24 24', fill: 'none' },
);

export default Comment;
