import Svg from 'components/svg';

const QRCode = Svg((
  <>
    <path d="M14.6667 14.6667V11.3333H16V13.2222C16 14.4111 15.9556 15.1111 15.8889 15.1111C15.8222 15.1111 15.7778 15.1889 15.7778 15.2778C15.7778 15.3667 15.7111 15.5222 15.6222 15.6111C15.5333 15.7 15.3778 15.7778 15.2889 15.7778C15.1889 15.7778 15.1111 15.8222 15.1111 15.8889C15.1111 15.9556 14.4111 16 11.3333 16V14.6667H14.6667Z" fill="currentColor" />
    <path d="M7.33333 12.6667V11.3333H8.66667V12.6667H7.33333Z" fill="currentColor" />
    <path d="M0.111111 15.1111C0.0444444 15.1111 0 14.4111 0 13.2222V11.3333H1.33333V14.6667H4.66667V16H2.77778C1.58889 16 0.888889 15.9556 0.888889 15.8889C0.888889 15.8222 0.811111 15.7778 0.722222 15.7778C0.633333 15.7778 0.477778 15.7 0.388889 15.6111C0.3 15.5222 0.222222 15.3667 0.222222 15.2778C0.222222 15.1889 0.166667 15.1111 0.111111 15.1111Z" fill="currentColor" />
    <path d="M12 12V10H13.3333V13.3333H9.33333V10.6667H10.6667V12H12Z" fill="currentColor" />
    <path d="M2.66667 13.3333V9.33333H6.66667V13.3333H2.66667Z" fill="currentColor" />
    <path d="M4 12V10.6667H5.33333V12H4Z" fill="#FEFEFE" />
    <path d="M10.6667 8.66667V7.33333H12V8.66667H10.6667Z" fill="currentColor" />
    <path d="M7.33333 10V7.33333H10V8.66667H8.66667V10H7.33333Z" fill="currentColor" />
    <path d="M7.33333 6.66667V5.33333H8.66667V6.66667H7.33333Z" fill="currentColor" />
    <path d="M9.33333 6.66667V2.66667H13.3333V6.66667H9.33333Z" fill="currentColor" />
    <path d="M10.6667 5.33333V4H12V5.33333H10.6667Z" fill="#FEFEFE" />
    <path d="M7.33333 4V2.66667H8.66667V4H7.33333Z" fill="currentColor" />
    <path d="M2.66667 6.66667V2.66667H6.66667V6.66667H2.66667Z" fill="currentColor" />
    <path d="M4 5.33333V4H5.33333V5.33333H4Z" fill="#FEFEFE" />
    <path d="M11.3333 1.33333V0H13.2222C14.4111 0 15.1111 0.0444444 15.1111 0.111111C15.1111 0.166667 15.1889 0.222222 15.2778 0.222222C15.3667 0.222222 15.5222 0.3 15.6111 0.388889C15.7 0.477778 15.7778 0.633333 15.7778 0.722222C15.7778 0.811111 15.8222 0.888889 15.8889 0.888889C15.9556 0.888889 16 1.58889 16 4.66667H14.6667V1.33333H11.3333Z" fill="currentColor" />
    <path d="M0.711111 0.222222C0.811111 0.222222 0.888889 0.166667 0.888889 0.111111C0.888889 0.0444444 1.58889 0 4.66667 0V1.33333H1.33333V4.66667H0V2.77778C0 1.58889 0.0444444 0.888889 0.111111 0.888889C0.166667 0.888889 0.222222 0.811111 0.222222 0.722222C0.222222 0.633333 0.288889 0.477778 0.377778 0.388889C0.466667 0.3 0.622222 0.222222 0.711111 0.222222Z" fill="currentColor" />
  </>
), { viewBox: '0 0 16 16', fill: 'none' });

QRCode.displayName = 'QRCode';

export default QRCode;
