// @flow

import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  get,
  getMultiple,
  post,
  put,
  remove,
  hasAnyEntity,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import {
  camelizeKeys,
} from 'utils/camelizer';
import type { SortParams } from 'oneflow-client/core';

export const accountSchema = new schema.Entity('accounts');

export const workspaceSchema = new schema.Entity('workspaces', {
  account: accountSchema,
});

export type NormalizedWorkspaces = {
  entities: {
    workspaces: {
      [number]: Workspace,
    },
    accounts: {
      [number]: Account,
    },
  },
  result: number | Array<number>,
  count: number,
};

type WorkspaceNormalizer = (any) => NormalizedWorkspaces;

const normalizeWorkspaces: WorkspaceNormalizer = (workspaces) => ({
  ...normalize(workspaces.collection, [workspaceSchema]),
  count: workspaces.count,
});

const normalizeWorkspace = (workspace) => ({
  ...normalize(workspace, workspaceSchema),
  count: 1,
});

type CreateWorkspace = ({
  name: string,
  type: string,
  description: string,
}) => Promise<NormalizedWorkspaces>;

export const createWorkspace: CreateWorkspace = ({
  name,
  type,
  description,
}) => (
  post({
    url: '/collections/',
    body: {
      type,
      name,
      description,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspace)
);

type RemoveWorkspaces = ({
  ids: Array<number>,
}) => Promise<{}>;

export const removeWorkspaces: RemoveWorkspaces = ({ ids }) => (
  remove({ url: '/collections/', body: { ids } })
    .then(extractResponseBodyAsJSON)
);

type GetWorkspace = ({
  id: number,
  accessStats?: number,
}) => Promise<NormalizedWorkspaces>;

export const getWorkspace: GetWorkspace = ({ id, accessStats, includeAgreementStats }) => (
  get({
    url: `/collections/${id}`,
    params: decamelizeKeys({
      accessStats,
      includeAgreementStats,
    }, {
      separator: '_',
    }),
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspace)
);

type GetWorkspaces = ({
  params?: {},
  pagination?: {},
  sorting?: SortParams,
}) => Promise<NormalizedWorkspaces>;

const DEFAULT_SORT = { attr: 'name', direction: 'asc' };
export const getWorkspaces: GetWorkspaces = ({ params, pagination, sorting = DEFAULT_SORT }) => (
  getMultiple({
    url: '/collections/',
    pagination,
    params: decamelizeKeys(params, { separator: '_' }),
    sorting,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspaces)
);

export const hasAnyWorkspace = hasAnyEntity({ url: '/collections/' });

type UpdateWorkspace = ({
  id: number,
  name: string,
  type: string,
  description?: string,
  amplitudeScope?: string,
}) => Promise<NormalizedWorkspaces>;

export const updateWorkspace: UpdateWorkspace = ({
  id,
  name,
  type,
  description,
  amplitudeScope,
}) => (
  put({
    url: `/collections/${id}`,
    body: {
      type,
      name,
      description,
    },
  }, { amplitudeScope })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspace)
);

type UpdateWorkspaceBranding = ({
  id: number,
  brandingName?: string,
  brandingCountry?: string,
  brandingDateFormat?: string,
  brandingOrgnr?: string,
  logo?: {
    id: number,
  },
}) => Promise<NormalizedWorkspaces>;

export const updateWorkspaceBranding: UpdateWorkspaceBranding = ({
  id,
  brandingName = null,
  brandingCountry = null,
  brandingDateFormat,
  brandingOrgnr = null,
  logo,
}) => (
  put({
    url: `/collections/${id}`,
    body: decamelizeKeys({
      brandingName,
      brandingCountry,
      brandingDateFormat,
      brandingOrgnr,
      logo,
    }, {
      separator: '_',
    }),
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspace)
);

type UpdateDataRetention = ({
  id: number,
  agreementDraft?: number,
  agreementExpired?: number,
  agreementDeclined?: number,
  agreementTerminated?: number,
}) => Promise<NormalizedWorkspaces>;

export const updateDataRetention: UpdateDataRetention = ({
  id,
  agreementDraft = null,
  agreementExpired = null,
  agreementDeclined = null,
  agreementTerminated = null,
}) => (
  put({
    url: `/collections/${id}/data_retention_policy`,
    body: decamelizeKeys({
      agreementDraft,
      agreementExpired,
      agreementDeclined,
      agreementTerminated,
    }, {
      separator: '_',
    }),
  })
    .then(extractResponseBodyAsJSON)
    .then((dataRetention) => ({
      id: dataRetention.collection.id,
      dataRetentionPolicy: {
        agreementDraft: dataRetention.agreementDraft,
        agreementExpired: dataRetention.agreementExpired,
        agreementDeclined: dataRetention.agreementDeclined,
        agreementTerminated: dataRetention.agreementTerminated,
        suggestedDraftDays: null,
        suggestedOverdueDays: null,
        suggestedDeclinedDays: null,
        suggestedTerminatedDays: null,
        affectedAgreementDraft: null,
        affectedAgreementExpired: null,
        affectedAgreementDeclined: null,
        affectedAgreementTerminated: null,
      },
    }))
    .then(normalizeWorkspace)
);

type GetDataRetention = ({
  id: number,
  agreementDraft?: number,
  agreementExpired?: number,
  agreementDeclined?: number,
  agreementTerminated?: number,
}) => Promise<NormalizedWorkspaces>;

export const getDataRetention: GetDataRetention = ({
  id,
  agreementDraft = undefined,
  agreementExpired = undefined,
  agreementDeclined = undefined,
  agreementTerminated = undefined,
}) => (
  get({
    url: `/collections/${id}/data_retention_policy`,
    params: decamelizeKeys({
      agreementDraft,
      agreementExpired,
      agreementDeclined,
      agreementTerminated,
    }, {
      separator: '_',
    }),
  })
    .then(extractResponseBodyAsJSON)
    .then(({
      affectedAgreementDraft,
      affectedAgreementExpired,
      affectedAgreementDeclined,
      affectedAgreementTerminated,
    }) => ({
      id,
      dataRetentionPolicy: {
        suggestedDraftDays: agreementDraft || undefined,
        suggestedOverdueDays: agreementExpired || undefined,
        suggestedDeclinedDays: agreementDeclined || undefined,
        suggestedTerminatedDays: agreementTerminated || undefined,
        affectedAgreementDraft: agreementDraft ? affectedAgreementDraft : undefined,
        affectedAgreementExpired: agreementExpired ? affectedAgreementExpired : undefined,
        affectedAgreementDeclined: agreementDeclined ? affectedAgreementDeclined : undefined,
        affectedAgreementTerminated: agreementTerminated ? affectedAgreementTerminated : undefined,
      },
    }))
    .then(normalizeWorkspace)
);

export const getAiInsights = ({ id: workspaceId, params }) => (
  get({
    url: `/ext/ai_review/workspaces/${workspaceId}/insights/`,
    params: decamelizeKeys(params, { separator: '_' }),
  })
    .then(extractResponseBodyAsJSON)
);

export const getAiInsightsContractList = ({
  id: workspaceId,
  insightType,
  params,
}) => (
  get({
    url: `/ext/ai_review/workspaces/${workspaceId}/insights/${insightType}/documents/`,
    params,
  })
    .then(extractResponseBodyAsJSON)
);

export const getWorkspacePlaybook = ({ id: workspaceId }) => (
  get({
    url: `/ext/ai_review/workspaces/${workspaceId}/config`,
  })
    .then(extractResponseBodyAsJSON)
);

export const createWorkspacePlaybook = ({
  id: workspaceId,
  enabled,
  playbookTemplateType,
  playbookValues,
  accountId,
}) => (
  post({
    url: `/ext/ai_review/workspaces/${workspaceId}/config`,
    body: {
      workspaceId,
      enabled,
      playbookTemplateType,
      playbookValues,
      accountId,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspace)
);

export const updateWorkspacePlaybook = ({
  id: workspaceId,
  enabled,
  playbookTemplateType,
  playbookValues,
}) => (
  put({
    url: `/ext/ai_review/workspaces/${workspaceId}/config`,
    body: {
      workspaceId,
      enabled,
      playbookTemplateType,
      playbookValues,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeWorkspace)
);

export const fetchAgreementsReviews = ({ id, agreementIds }) => (
  get({
    url: `/ext/ai_review/workspaces/${id}/agreements/`,
    params: {
      ids: agreementIds,
    },
  })
    .then((response) => response.json())
    .then(camelizeKeys)
);
