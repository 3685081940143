import { components } from 'react-select';
import clsx from 'clsx';

import NewCheck from 'components/icons/new-check';
import style from './role-option.module.scss';

export const getSelected = (isSelected: boolean) => {
  if (!isSelected) {
    return null;
  }

  return <NewCheck height="12px" />;
};

const RoleOption = ({ isDisabled, isSelected, ...restProps }: any) => {
  const { label, extraInformation } = restProps.data;

  return (
    <components.Option
      className={clsx(
        { [style.DisabledOption]: isDisabled },
      )}
      {...restProps}
    >
      <div>
        <div className={style.OptionLabel}>{label}</div>
        {extraInformation && (<div className={style.OptionExtra}>{extraInformation}</div>)}
      </div>
      <div className={style.OptionIcon}>{getSelected(isSelected)}</div>
    </components.Option>
  );
};

export default RoleOption;
