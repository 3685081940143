// @flow

import React from 'react';

import Svg from 'components/svg';

const ChevronDown = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.07575 2.08398L4.99997 6.71596L8.92419 2.08398L9.91608 2.92432L5.81058 7.77026L5.801 7.78132C5.70343 7.89136 5.58333 7.98334 5.44526 8.04779C5.3069 8.11238 5.15532 8.14699 4.99997 8.14699C4.84463 8.14699 4.69305 8.11238 4.55468 8.04779C4.41661 7.98334 4.29651 7.89136 4.19895 7.78132L4.18936 7.77026L0.0838623 2.92432L1.07575 2.08398Z"
    fill="currentColor"
  />
), { viewBox: '0 0 10 10', fill: 'none' });

ChevronDown.displayName = 'ChevronDown';

export default ChevronDown;
