import compact from 'lodash/compact';

/**
 * TODO: compose promises instead of functions
 * so it works regardless of the validations being sync/async.
 *
 * This will work with any mix of sync/async functions as long as
 * - There is only one asynchronous function (with any number of sync functions)
 * and it is the last one in the "pipe"
*/
const composeValidators = (...validators) => (value, allValues, meta) => {
  if (validators.length === 0) {
    throw new Error('Invalid number of arguments. composeValidators expects at least one function');
  }

  return compact(validators)
    .reduce(
      (err, validator) => (
        err || validator(value, allValues, meta)
      ),
      undefined,
    );
};

export default composeValidators;
