// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_3_4');
  return (
    <>
      <circle
        cx="36"
        cy="36"
        r="36"
        fill="backgroundColor"
      />
      <g clipPath={`url(#${id})`}>
        <path
          d="M18.5439 28.2864C18.5439 28.2864 29.7099 22.4188 32.4337 21.2048C34.8158 22.2663 46.3237 28.2853 46.3237 28.2853C46.3237 28.2853 35.1552 34.1523 32.4315 35.3657C29.7082 34.1522 18.5439 28.2864 18.5439 28.2864Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M33.2054 40.9158L32.4337 41.0481L18.5433 34.821M33.977 47.0893L32.3988 47.6625L18.5433 41.3523"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M49.7071 39.998L43.1071 46.598M43.1071 39.998L49.7071 46.598M37.6071 43.298C37.6071 45.6319 38.5343 47.8702 40.1846 49.5206C41.8349 51.1709 44.0732 52.098 46.4071 52.098C48.741 52.098 50.9793 51.1709 52.6296 49.5206C54.2799 47.8702 55.2071 45.6319 55.2071 43.298C55.2071 40.9641 54.2799 38.7258 52.6296 37.0755C50.9793 35.4252 48.741 34.498 46.4071 34.498C44.0732 34.498 41.8349 35.4252 40.1846 37.0755C38.5343 38.7258 37.6071 40.9641 37.6071 43.298Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id={id}>
          <rect width="30.8674" height="30.8674" fill="white" transform="translate(17 19)" />
        </clipPath>
      </defs>
    </>
  );
};

const NoTemplates = Svg(Fragment, { viewBox: '0 0 72 72', fill: 'none' });

NoTemplates.displayName = 'NoTemplates';

export default NoTemplates;
