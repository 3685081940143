import Svg from 'components/svg';

const FolderEmpty = Svg((
  <>
    <path
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.28012 45.1047L8.0026 23.474C7.99039 23.2648 8.02125 23.0554 8.09329 22.8585C8.16533 22.6616 8.27704 22.4815 8.42156 22.3291C8.56609 22.1767 8.74038 22.0552 8.93376 21.9722C9.12714 21.8892 9.33553 21.8463 9.54615 21.8463H23.7102C24.0558 21.8457 24.3915 21.9602 24.664 22.1715C24.9365 22.3829 25.1299 22.6789 25.2136 23.0124L26.4663 28.0001H46.4489C46.6623 27.9995 46.8735 28.0428 47.0692 28.1273C47.265 28.2119 47.441 28.3357 47.5863 28.4912C47.7315 28.6467 47.8429 28.8304 47.9133 29.0308C47.9837 29.2311 48.0117 29.4438 47.9955 29.6555L46.7984 45.1601C46.7383 45.9344 46.3861 46.6575 45.8123 47.1842C45.2386 47.7109 44.4859 48.0024 43.7051 48.0001H12.3703C11.5811 48.0007 10.8215 47.7012 10.2471 47.1629C9.67261 46.6247 9.32669 45.8884 9.28012 45.1047Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M17.1862 15.6923V8C19.8461 8 46.5723 8.00001 46.5723 8.00001V21.8461"
    />
    <path
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M29.5588 15.6923H38.8386"
    />

  </>
), { viewBox: '0 0 56 56', fill: 'none' });

FolderEmpty.displayName = 'FolderEmpty';

export default FolderEmpty;
