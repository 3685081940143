// @flow

import React from 'react';

import Svg from 'components/svg';

const FolderPathIcon = Svg((
  <>
    <path
      d="M1.08333 8C0.485815 8 0 7.55156 0 7V1C0 0.448444 0.485815 0 1.08333 0H2.88889C3.22785 0 3.55189 0.149333 3.75556 0.4L4.51389 1.33333H9.02778C9.6253 1.33333 10.1111 1.78178 10.1111 2.33333V7C10.1111 7.55156 9.6253 8 9.02778 8H1.08333ZM1.08333 0.666667C0.884 0.666667 0.722222 0.816 0.722222 1V7C0.722222 7.184 0.884 7.33333 1.08333 7.33333H9.02778C9.22711 7.33333 9.38889 7.184 9.38889 7V2.33333C9.38889 2.14933 9.22711 2 9.02778 2H4.33333C4.22019 2 4.11233 1.95022 4.04444 1.86667L3.17778 0.8C3.10989 0.716445 3.00204 0.666667 2.88889 0.666667H1.08333Z"
      fill="#678994"
    />
  </>
), { viewBox: '0 0 10 10', fill: 'none' });

FolderPathIcon.displayName = 'FolderPathIcon';

export default FolderPathIcon;
