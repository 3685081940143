// @flow

import Pusher from 'pusher-js';
import { camelizeKeys } from 'humps';

import type { Subscription } from 'web-socket';

const authEndpoint = '/api/push/auth?_source=form_pretty';
const cluster = 'eu';

type CamelizeArgumentsFor = (fn: Function) => (...args) => (...args) => any;

export const camelizeArgumentsFor: CamelizeArgumentsFor = (fn: Function) => (...args) => (
  fn(...args.map((arg) => camelizeKeys(arg)))
);

class PusherWrapper {
  socket: Pusher;

  bindings: Map<string, number>;

  getPusherInstance = (pusherInstance?: Pusher) => {
    if (pusherInstance) {
      return pusherInstance;
    }

    return new Pusher(window.PUSHER_KEY, {
      authEndpoint,
      auth: {
        params: {
          cluster,
        },
        headers: {},
      },
      encrypted: true,
      disableStats: true,
      cluster,
    });
  }

  initialize = (pusherInstance?: Pusher) => {
    this.socket = this.getPusherInstance(pusherInstance);

    this.bindings = new Map<string, number>();
  }

  setGuestToken(guestToken: string) {
    this.socket.config.auth.headers['X-Flow-Access-Token'] = guestToken;
  }

  isConnected = () => {
    if (!this.socket) {
      return false;
    }

    return this.socket.connection.state === 'connected';
  }

  throwIfNotInitialized() {
    if (!this.socket) {
      throw Error('The socket is not initialized');
    }
  }

  subscribeToChannel = (channelName: string) => {
    this.throwIfNotInitialized();

    return this.socket.subscribe(channelName);
  };

  unsubscribeFromChannel = (channelName: string) => {
    this.throwIfNotInitialized();

    return this.socket.unsubscribe(channelName);
  }

  bindCallback = (subscription: Subscription) => {
    const { channelName, event, eventCallback } = subscription;
    const bindingsCount = this.bindings.get(channelName) || 0;
    const channel = this.subscribeToChannel(channelName);

    this.bindings.set(channelName, bindingsCount + 1);
    channel.bind(event, camelizeArgumentsFor(eventCallback));
  }

  unbindCallback = (subscription: Subscription) => {
    this.throwIfNotInitialized();

    const { channelName, event, eventCallback } = subscription;
    const channel = this.socket.channel(channelName);
    const bindingsCount = this.bindings.get(channelName);

    if (!channel || !bindingsCount) {
      return;
    }

    channel.unbind(event, eventCallback);

    this.bindings.set(channelName, bindingsCount - 1);

    if (this.bindings.get(channelName) === 0) {
      this.unsubscribeFromChannel(channelName);
    }
  }

  onConnect = (callback: () => void) => {
    this.throwIfNotInitialized();

    this.socket.connection.bind('connected', callback);
  }

  onDisconnect = (callback: () => void) => {
    this.throwIfNotInitialized();

    // @see https://github.com/pusher/pusher-js/tree/v5.0.3#connection-states
    this.socket.connection.bind('disconnected', callback);
  }
}

export default new PusherWrapper();
