import { uniqueId } from 'lodash';

import type { Toast } from 'components/toasts/types';

type SetToasts = (cb: (toasts: Toast[]) => Toast[]) => void;

type ObserverId = string;

class ToastObserver {
  static toastObservers: Record<string, ToastObserver | null> = {};

  static queue: Record<ObserverId, Toast[]> = {};

  private setToasts: SetToasts;

  constructor(setToasts: SetToasts) {
    this.setToasts = setToasts;
  }

  public removeReoccurred(id: Toast['id']) {
    this.setToasts?.((toasts: Toast[]) => {
      const newToasts = toasts.map(
        (toast) => (toast.id === id ? { ...toast, reoccurred: false } : toast),
      );
      return newToasts;
    });
  }

  public create(toast: Toast): Toast['id'] {
    const id = toast.id || Number(uniqueId());
    const newToast: Toast = { ...toast, id };
    this.setToasts?.((toasts: Toast[]) => {
      const toastExists = toasts.some((_toast) => _toast.id === id);
      if (toastExists) {
        return toasts.map((_toast) => (_toast.id === id ? {
          ...toast,
          reoccurred: true,
        } : _toast));
      }
      return [...toasts, newToast];
    });
    return id;
  }

  public remove(id: Toast['id']) {
    // We set the remove here, the component will remove itself after the animation
    this.setToasts?.((toasts: Toast[]) => toasts.map(
      (toast) => (toast.id !== id ? toast : { ...toast, remove: true }),
    ));
  }

  public update(toast: Toast) {
    this.setToasts?.((toasts: Toast[]) => toasts.map(
      (_toast) => (_toast.id === toast.id ? { ..._toast, ...toast } : _toast),
    ));
  }

  static getInstanceById(observerId: ObserverId) {
    return ToastObserver.toastObservers[observerId];
  }

  static createInstance(observerId: ObserverId, setToasts: SetToasts) {
    if (!ToastObserver.toastObservers[observerId]) {
      ToastObserver.toastObservers[observerId] = new ToastObserver(setToasts);
    }

    return ToastObserver.toastObservers[observerId];
  }

  static clearInstance(observerId: ObserverId) {
    ToastObserver.toastObservers[observerId] = null;
    delete ToastObserver.toastObservers[observerId];
  }

  /**
   * When a toast is created, But the provider is not mounted yet, the toast is queued
   * As soon as the provider is mounted, the queued toasts will be rendered and the queue will
   * be cleared
   */
  static queueToast(observerId: ObserverId, toast: Toast) {
    if (!ToastObserver.queue[observerId]) {
      ToastObserver.queue[observerId] = [];
    }

    ToastObserver.queue[observerId].push(toast);
  }

  static getQueuedToasts(observerId: ObserverId): Toast[] | null {
    if (!ToastObserver.queue[observerId]) {
      return null;
    }

    return ToastObserver.queue[observerId];
  }

  static clearQueue(observerId: ObserverId) {
    ToastObserver.queue[observerId] = [];
  }

  static flushQueuedToasts(observerId: ObserverId) {
    const toasts = ToastObserver.getQueuedToasts(observerId);

    if (!toasts) {
      return;
    }

    const instance = ToastObserver.getInstanceById(observerId);
    toasts.forEach((toast) => {
      if (instance) {
        instance.create(toast);
      }
    });

    this.clearQueue(observerId);
  }
}

export default ToastObserver;
