import { isEmpty } from 'lodash';

import { getCurrentAgreementUpdater } from 'agreement/selectors';
import { isTemplate } from 'agreement';

// eslint-disable-next-line import/prefer-default-export
export const isContractEditable = (agreement: Oneflow.Agreement) => {
  const myParticipantWhenUpdater = getCurrentAgreementUpdater(agreement);
  return !isEmpty(myParticipantWhenUpdater) || isTemplate(agreement);
};

export const EXPANDED = 'expanded' as const;
export const COLLAPSED = 'collapsed' as const;
export const DOCUMENT_COLLAPSED_LAYOUT_SIZE = '840' as const;
