// @flow

import React from 'react';

import Svg from 'components/svg';

const License = Svg((
  <>
    <circle
      cx="7"
      cy="7"
      r="6.35"
      stroke="currentColor"
      strokeWidth="1.3"
    />
    <path
      d="M6.99992 2.8335L8.28742 5.44183L11.1666 5.86266L9.08325 7.89183L9.57492 10.7585L6.99992 9.40433L4.42492 10.7585L4.91659 7.89183L2.83325 5.86266L5.71242 5.44183L6.99992 2.8335Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

License.displayName = 'License';

export default License;
