import { get, isEmpty, map } from 'lodash';

import { isChecked } from 'utils/checkbox';
import { getPriceColumns } from 'reducers/helpers/product-sum-box';
import {
  BOX_ATTACHMENTS,
  BOX_FORM,
  BOX_PDF,
  BOX_PRODUCT_TABLE,
  MAX_DATA_FIELD_LENGTH,
} from 'agreement/constants';
import { canCounterpartEditBoxDataItemField } from 'agreement/permissions';
import { isBoxDataSharedValueUpdateAllowed } from 'agreement/box-data-shared-value-update-permissions';
import type { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';
import type { Box } from 'data-validators/entity-schemas/document-box';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type {
  Field,
  FormBox,
} from 'data-validators/entity-schemas/document-box/form-box';

import {
  getHasBrokenSectionRule,
  getSectionRule,
  getVisibility,
} from 'components/contract-boxes/box-wrapper/helpers';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { getFieldData } from 'components/contract-boxes/form-box/helpers';
import { DATA_FIELD } from 'components/contract-boxes/constants';

export type PriceColumn = {
  visible: boolean,
  column: {
    label: string,
    key: string,
    fixedAmount: number,
    enabled: boolean,
    uuid: string,
    boxId: number,
    price: number,
    // eslint-disable-next-line camelcase
    price_discounted: number,
  },
  formatted: {
    boxId: number,
    value: string,
    label: string,
    priceLabel?: string;
  }
}

export type PriceColumnsGroup = {
  boxId: number,
  priceColumns: PriceColumn[]
};

export const createDataFieldExternalKeyValueMap = (
  data?: Record<DataField['id'], DataField>,
) => {
  if (isEmpty(data)) {
    return {};
  }

  return Object.values(data).reduce<Record<string, DataField['value']['value']>>((
    dataFieldExternalKeyValueMap,
    field,
  ) => {
    const { externalKey, value } = field.value;

    if (!externalKey) {
      return dataFieldExternalKeyValueMap;
    }
    return {
      ...dataFieldExternalKeyValueMap,
      [externalKey]: value,
    };
  }, {});
};

export const createDataFieldKeyValueMap = (
  data?: Record<DataField['id'], DataField>,
): ContractView.DataFieldKeyValueMap => {
  if (isEmpty(data)) {
    return {};
  }

  return Object.values(data).reduce<Record<string, DataField['value']['value']>>((
    dataFieldExternalKeyValueMap,
    field,
  ) => {
    const { key, value } = field.value;

    if (!key) {
      return dataFieldExternalKeyValueMap;
    }
    return {
      ...dataFieldExternalKeyValueMap,
      [key]: value,
    };
  }, {});
};

export const producePriceColumns = (
  boxes: {[key: string]: Box },
  boxOrder: ContractView.BoxOrder,
  dataFieldExternalKeyValueMap: { [key: string]: string | undefined },
) => map(boxOrder, ({ id, _id }) => boxes[id ?? _id])
  .filter((box: Box) => box && box.type === BOX_PRODUCT_TABLE && !box._removed)
  .reduce((allPriceColumns: PriceColumn[], box: Box) => {
    const boxId = getId(box);
    const sectionRule = get(box, 'config.visibility.rule');
    const isBoxVisible = getVisibility(sectionRule, dataFieldExternalKeyValueMap);

    return [...allPriceColumns, {
      boxId,
      priceColumns: getPriceColumns({
        boxId,
        isBoxVisible,
        columns: box.config.columns,
        sums: box.store.sums,
      }),
    }];
  }, []);

export const getEnabledPriceColumns = (priceColumnsGroups: PriceColumnsGroup[]) => (
  priceColumnsGroups.map((priceColumnsGroup) => ({
    ...priceColumnsGroup,
    priceColumns: priceColumnsGroup.priceColumns
      .filter(({ column }) => column.enabled),
  }))
);

export const getVisiblePriceColumns = (priceColumnsGroups: PriceColumnsGroup[]) => (
  priceColumnsGroups.map((priceColumnsGroup) => ({
    ...priceColumnsGroup,
    priceColumns: priceColumnsGroup.priceColumns
      .filter(({ column, visible }) => column.enabled && visible),
  }))
);

const hasMissingRequiredAttachment = (box: AttachmentBox) => (
  box.config.requiredForSignatures && !box.content?.data?.length
);

export const getFieldValue = (
  field: Field,
  dataFieldKeyValueMap: ContractView.DataFieldKeyValueMap,
) => {
  if (field.value.valueDataFieldKey) {
    return dataFieldKeyValueMap[field.value.valueDataFieldKey];
  }

  return field.value.value;
};

const isInputEmptyAndRequired = (
  field: Field,
  dataFieldKeyValueMap: ContractView.DataFieldKeyValueMap,
) => {
  if (!field.value.required) {
    return false;
  }

  let value: string | boolean | undefined = getFieldValue(field, dataFieldKeyValueMap);

  if (field.value.type === 'checkbox') {
    value = isChecked(value || '');
  }
  return !value;
};

type GetEmptyRequiredInputCountProps = {
  agreement: Oneflow.Agreement,
  isGuest: boolean,
  box: FormBox,
  dataFieldKeyValueMap: ContractView.DataFieldKeyValueMap,
}

// For Box fields with data field connected
const getEmptyRequiredInputCount = ({
  isGuest,
  box,
  dataFieldKeyValueMap,
}: GetEmptyRequiredInputCountProps) => {
  let editableEmptyRequiredInputCount = 0;
  let nonEditableEmptyRequiredInputCount = 0;
  box.content?.data?.forEach((field) => {
    const isAllowedToUpdateDataValue = isBoxDataSharedValueUpdateAllowed(box, field);
    const isEmptyAndRequired = isInputEmptyAndRequired(field, dataFieldKeyValueMap);

    if (!isEmptyAndRequired) {
      return;
    }

    if (!isGuest) {
      editableEmptyRequiredInputCount += 1;
      return;
    }

    if (isGuest) {
      const fieldData = get(getFieldData(box.content.data, field.id), 'value');
      const isReadOnly = get(fieldData, 'readOnly', false);
      const isRequired = get(fieldData, 'required', false);
      const permissions = get(fieldData, 'permissions', {});
      const canCounterpartEdit = canCounterpartEditBoxDataItemField({
        box,
        isReadOnly,
        isAllowedToUpdateDataValue,
        counterpartEdit: permissions.counterpartEdit,
      });

      if (isRequired && !canCounterpartEdit) {
        nonEditableEmptyRequiredInputCount += 1;
        return;
      }

      if (isRequired || canCounterpartEdit) {
        editableEmptyRequiredInputCount += 1;
        return;
      }
    }
    nonEditableEmptyRequiredInputCount += 1;
  });
  return {
    editableEmptyRequiredInputCount,
    nonEditableEmptyRequiredInputCount,
  };
};

type GetEmptyRequiredElementsCountProps = {
  isGuest: boolean,
  boxMap : Record<Box['id'], Box>,
  data: Record<DataField['id'], DataField>,
  dataFieldExternalKeyMap: Record<string, number>,
  dataFieldExternalKeyValueMap: Record<string, DataField['value']['value']>,
}

export const getEmptyRequiredElementsCount = ({
  isGuest,
  boxMap,
  data,
  dataFieldExternalKeyMap,
  dataFieldExternalKeyValueMap,
}: GetEmptyRequiredElementsCountProps) => {
  const dataFieldKeyValueMap = createDataFieldKeyValueMap(data);
  const boxes = Object.values(boxMap) || [];

  if (boxes.length === 0) {
    return {
      editableEmptyRequiredElementsCount: 0,
      nonEditableEmptyRequiredElementsCount: 0,
    };
  }

  let editableEmptyRequiredElementsCount = 0;
  let nonEditableEmptyRequiredElementsCount = 0;

  boxes.forEach((box) => {
    const rule = getSectionRule(box);
    const hasBrokenSectionRule = getHasBrokenSectionRule(rule, dataFieldExternalKeyMap);
    const isVisible = getVisibility(rule, dataFieldExternalKeyValueMap);
    const hasBrokenOrHiddenSectionRule = hasBrokenSectionRule || !isVisible;

    if (hasBrokenOrHiddenSectionRule) {
      return;
    }

    const { type } = box;
    if (type === BOX_ATTACHMENTS && hasMissingRequiredAttachment(box)) {
      editableEmptyRequiredElementsCount += 1;
    }
    if (type === BOX_FORM || type === BOX_PDF) {
      const {
        editableEmptyRequiredInputCount,
        nonEditableEmptyRequiredInputCount,
      } = getEmptyRequiredInputCount({
        isGuest,
        box,
        dataFieldKeyValueMap,
      });
      if (editableEmptyRequiredInputCount > 0) {
        editableEmptyRequiredElementsCount += editableEmptyRequiredInputCount;
      } else if (nonEditableEmptyRequiredInputCount > 0) {
        nonEditableEmptyRequiredElementsCount += nonEditableEmptyRequiredInputCount;
      }
    }
  }, 0);

  return { editableEmptyRequiredElementsCount, nonEditableEmptyRequiredElementsCount };
};

export const getInvalidElementsCount = (data: Record<DataField['id'], DataField>) => {
  const invalidDataFields = Object.values(data).filter((dataItem: DataField) => {
    if (dataItem.key === DATA_FIELD
      && (dataItem.value.value?.length ?? 0) > MAX_DATA_FIELD_LENGTH
    ) {
      return true;
    }
    return false;
  });
  return invalidDataFields.length;
};

export const filterOutInvisibleBoxes = (
  requiredBoxIds: Box['id'][],
  dataFieldExternalKeyValueMap: Record<string, string>,
  boxes: Record<Box['id'], Box>,
) => (
  requiredBoxIds.filter((boxId) => {
    const box = boxes[boxId];
    const sectionRule = get(box, 'config.visibility.rule');
    return getVisibility(sectionRule, dataFieldExternalKeyValueMap);
  })
);
