// @flow

import React from 'react';

import Svg from 'components/svg';

const SecurityLock = Svg((
  <g>
    <path d="M5.25,24C4.009,24,3,22.991,3,21.75v-10.5C3,10.009,4.009,9,5.25,9H6V6c0-3.308,2.692-6,6-6c3.308,0,6,2.692,6,6v3h0.75C19.991,9,21,10.009,21,11.25v10.5c0,1.241-1.009,2.25-2.25,2.25H5.25z M5.25,10.5c-0.414,0-0.75,0.336-0.75,0.75v10.5c0,0.414,0.336,0.75,0.75,0.75h13.5c0.414,0,0.75-0.336,0.75-0.75v-10.5c0-0.414-0.336-0.75-0.75-0.75H5.25z M16.5,9V6c0-2.481-2.019-4.5-4.5-4.5C9.519,1.5,7.5,3.519,7.5,6v3H16.5z" />
    <path d="M12,18.75c-0.414,0-0.75-0.336-0.75-0.75v-3c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v3C12.75,18.414,12.414,18.75,12,18.75z" />
  </g>
));

SecurityLock.displayName = 'SecurityLock';

export default SecurityLock;
