import { i18nState } from '@oneflowab/pomes';
import type { IreduxI18nState } from '@oneflowab/pomes';

export const REDUX_I18N_SET_LANGUAGE = 'REDUX_I18N_SET_LANGUAGE' as const;

export default i18nState;

export const setLanguage = (lang: string) => ({
  type: REDUX_I18N_SET_LANGUAGE,
  lang,
});

type RootState = {
  i18nState: IreduxI18nState,
};

export const getCurrentLanguageSelector = (state: RootState) => state.i18nState.lang;

export const getAdjustedCurrentLanguageSelector = (state: RootState) => {
  const lang = getCurrentLanguageSelector(state);

  if (lang === 'no') {
    return 'nb';
  }

  return lang;
};
