// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import { PasswordFieldWithEye } from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
};

export const PasswordVerification = ({ message, autoFocus }: Props) => (
  <Field
    name="passwordVerification"
    label={message({
      id: 'Current password',
      comment: 'Password verification field label.',
    })}
    placeholder={message({
      id: 'Enter current password',
      comment: 'Password verification field placeholder.',
    })}
    autoFocus={autoFocus}
    component={PasswordFieldWithEye}
    maxLength={72}
    required
    hideRequired
  />
);

PasswordVerification.defaultProps = {
  autoFocus: undefined,
};

export default localize<Props>(PasswordVerification);
