import { Message } from '@oneflowab/pomes';

import Button from 'components/button';

import style from './signup-link.module.scss';

const SignupLinkComponent = () => (
  <div className={style.Wrapper}>
    <div className={style.LinkText}>
      <Message
        id="Don't have an account?"
        comment="Explanatory text. Used next to the link to the signup page."
      />
    </div>
    <Button
      href="/signup"
      secondary
      customClass={style.LinkButton}
    >
      <Message
        id="Try Oneflow free"
        comment="Button link text for going to the signup page."
      />
    </Button>
  </div>
);

export default SignupLinkComponent;
