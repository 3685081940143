// @flow

import React from 'react';

import Svg from 'components/svg';

const PageBreak = Svg((
  <>
    <path d="M19 10L19 4L17 4L17 10L19 10Z" fill="currentColor" />
    <path d="M9 10L9 4L7 4L7 10L9 10Z" fill="currentColor" />
    <path d="M9 10L19 10L19 8L9 8L9 10Z" fill="currentColor" />
    <path d="M7 14L7 20L9 20L9 14L7 14Z" fill="currentColor" />
    <path d="M17 14L17 20L19 20L19 14L17 14Z" fill="currentColor" />
    <path d="M19 14L9 14L9 16L19 16L19 14Z" fill="currentColor" />
    <path d="M7 12L3 15L3 9L7 12Z" fill="currentColor" />
  </>
), { viewBox: '0 0 24 24' });

PageBreak.displayName = 'PageBreak';

export default PageBreak;
