import Svg from 'components/svg';

const ExclamationCircle = Svg((
  <>
    <rect width="24" height="24" rx="12" fill="currentColor" />
    <path d="M12 8V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="12" cy="16" r="1" fill="white" />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

ExclamationCircle.displayName = 'ExclamationCircle';

export default ExclamationCircle;
