// @flow

import React from 'react';
import PublicAppTitle from 'components/app-title';
import Wrapper from 'components/public-wrapper';

import { LoginForm } from 'routes/public/login/login-form';

const Login = (props: any) => (
  <Wrapper hasSignupLink>
    <PublicAppTitle title="Login" />
    <LoginForm {...props} />
  </Wrapper>
);

export default Login;
