// @flow

import React from 'react';
import { Route } from 'react-router';
import debounce from 'debounce-promise';
import { amplitudeInit } from 'client-analytics/amplitude';

import Switch from 'components/switch';
import { ErrorPage } from 'components/legacy/error';
import Loading from 'components/legacy/loading';
import { NotFound } from 'components/errors';

import client from 'oneflow-client';

import ChangeEmail from 'routes/public/change-email';
import Login from 'routes/public/login';
import ResetPassword from 'routes/public/reset-password';
import Signup from 'routes/public/signup';
import IconList from 'components/icon-list';

type Props = LoadingState & { location: Location };

type State = {
  queryValues: { [any]: string },
};

class PublicPages extends React.Component<Props, State> {
  state = {
    queryValues: {},
  };

  checkIfAuthenticated = debounce(async () => {
    const reloadPage = () => new Promise(() => {
      window.location.reload(true);
    });

    return (
      client
        .ping()
        .then(reloadPage)
        .catch(() => undefined)
    );
  }, 1000);

  componentDidMount() {
    const { location } = this.props;
    const isEmailChange = /change-email/.test(window.location.href);

    amplitudeInit(window.TRACE_KEY, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
      includeFbclid: true,
      serverUrl: window.TRACE_URL,
      defaultTracking: false,
    });

    if (!isEmailChange) {
      window.addEventListener('focus', this.checkIfAuthenticated);
    }

    this.setState({ queryValues: location.query });
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.checkIfAuthenticated);
  }

  render() {
    const { isLoading, hasError } = this.props;
    const { queryValues } = this.state;

    if (isLoading) {
      return (
        <Loading />
      );
    }

    if (hasError) {
      return <ErrorPage />;
    }

    return (
      <>
        <Switch>
          <Route path="/" exact strict component={NotFound} />
          <Login path="/login" />
          <Signup path="/signup/:token?" queryValues={queryValues} />
          <ResetPassword path="/recover-account/:token?" />
          <ChangeEmail path="/change-email/:token" />
          <Route path="/oneflow-icons" component={IconList} />
          <Route>
            <NotFound redirect to="/login" />
          </Route>
        </Switch>
      </>
    );
  }
}

export default PublicPages;
