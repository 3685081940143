// @flow

import React from 'react';

import Svg from 'components/svg';

const WrapRight = Svg((
  <>
    <path d="M20 5H4V7H20V5Z" fill="currentColor" />
    <path d="M20 17H4V19H20V17Z" fill="currentColor" />
    <path d="M20 9H13V11H20V9Z" fill="currentColor" />
    <path d="M20 13H13V15H20V13Z" fill="currentColor" />
    <rect x="4" y="9" width="6" height="6" fill="currentColor" />
  </>
));

WrapRight.displayName = 'WrapRight';

export default WrapRight;
