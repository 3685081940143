// @flow

import React from 'react';

import Svg from 'components/svg';

const AlignCenter = Svg((
  <>
    <path d="M4 6H20V8H4V6Z" fill="currentColor" />
    <path d="M8 11H16V13H8V11Z" fill="currentColor" />
    <path d="M6 16H18V18H6V16Z" fill="currentColor" />
  </>
));

AlignCenter.displayName = 'AlignCenter';

export default AlignCenter;
