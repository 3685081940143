import createEntitiesReducer from './entities';

export default createEntitiesReducer;

// const entities = createEntitiesReducer({ key: 'agreements' });

// export const SET_AGREEMENTS = entities.actions.types.setEntities;

// const reducer = entities.reducer;

// const setAgreements = entities.actions.setEntities;

// const getAllAgreementsSelector = (state) => (
//  entities.getQuerySelector(state.agreements.entities);
// );

// const getAgreementByIdSelector = (state, id) => (
//  entities.getEntityByIdSelector(state.agreements.entities, id);
// );

// const getAgreementsByIdSelector = (state, ids = []) => (
//  entities.getEntitiesByIdSelector(state.agreements.entities, ids);
// );
