import Svg from 'components/svg';

const DelegateApproval = Svg((
  <>
    <path
      d="M0.984375 12.4684H6.72656L11.9219 10.8278V10.6134C11.9217 10.3903 11.8692 10.1703 11.7686 9.97117C11.6679 9.77202 11.5221 9.59921 11.3426 9.46664C11.1631 9.33408 10.9551 9.24544 10.7351 9.20782C10.5152 9.17019 10.2895 9.18463 10.0762 9.25008L6.72656 10.2809M6.72656 10.2809H3.99219M6.72656 10.2809V8.6403L3.28672 8.11093C3.05268 8.07489 2.81361 8.08989 2.58592 8.15491C2.35822 8.21992 2.14728 8.3334 1.96755 8.48757C1.78782 8.64174 1.64356 8.83297 1.54465 9.04813C1.44575 9.26329 1.39453 9.49727 1.39453 9.73404"
      stroke="#013A4C"
      strokeWidth="0.875"
    />
    <path
      d="M8.9998 5.79941C9.44163 5.79941 9.7998 5.44124 9.7998 4.99941C9.7998 4.55759 9.44163 4.19941 8.9998 4.19941C8.55798 4.19941 8.1998 4.55759 8.1998 4.99941C8.1998 5.44124 8.55798 5.79941 8.9998 5.79941Z"
      fill="#013A4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99985 2.39941C7.23597 2.39941 6.1535 3.27892 5.24789 4.34971C5.0886 4.52244 5 4.74893 5 4.98421C5 5.22297 5.09124 5.45267 5.25499 5.62634C5.83897 6.2565 7.21292 7.59941 8.99985 7.59941C10.7868 7.59941 12.1607 6.2565 12.7447 5.62634C12.9085 5.45267 12.9997 5.22297 12.9997 4.98421C12.9997 4.74893 12.9111 4.52244 12.7518 4.3497C11.8462 3.27892 10.7637 2.39941 8.99985 2.39941ZM5.85269 4.87345C6.68743 3.88471 7.56802 3.19941 8.99985 3.19941C10.4317 3.19941 11.3123 3.88471 12.147 4.87345C12.152 4.87933 12.1571 4.88506 12.1624 4.89064C12.1863 4.9159 12.1997 4.9494 12.1997 4.98421C12.1997 5.01903 12.1863 5.05252 12.1624 5.07779L12.1592 5.08118C11.5744 5.71244 10.4114 6.79941 8.99985 6.79941C7.58828 6.79941 6.42529 5.71244 5.84048 5.08118L5.83731 5.07779C5.81335 5.05252 5.8 5.01903 5.8 4.98421C5.8 4.9494 5.81335 4.9159 5.83731 4.89064C5.8426 4.88506 5.84773 4.87933 5.85269 4.87345Z"
      fill="#013A4C"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

DelegateApproval.displayName = 'DelegateApproval';

export default DelegateApproval;
