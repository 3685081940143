import Svg from 'components/svg';

const Message = Svg(
  <>
    <circle cx="18" cy="7" r="4" fill="#D13D47" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5605 3.37099C13.7486 3.12963 12.8891 3 12 3C7.03773 3 3 7.0377 3 11.9999C3 16.9622 7.03773 20.9999 12 20.9999C13.4253 20.9999 14.8375 20.657 16.1056 20.0058L19.9838 20.9753C20.1208 21.0098 20.2644 21.0081 20.4005 20.9704C20.5366 20.9327 20.6606 20.8602 20.7603 20.7601C20.8602 20.6602 20.9326 20.5361 20.9703 20.3999C21.008 20.2636 21.0098 20.12 20.9755 19.9829L20.0051 16.1047C20.6579 14.8348 20.9989 13.4278 21 11.9999C21 11.6751 20.9827 11.3542 20.949 11.0382C20.476 11.3842 19.9399 11.6491 19.3613 11.8125C19.3628 11.8748 19.3636 11.9373 19.3636 11.9999C19.3625 13.2594 19.0383 14.4975 18.4219 15.5958C18.3193 15.7778 18.2906 15.9922 18.3417 16.1947L19.0568 19.0567L16.1948 18.3408C15.9925 18.2908 15.7788 18.3195 15.5967 18.421C14.4983 19.038 13.2598 19.3625 12 19.3635C7.93936 19.3635 4.63636 16.0597 4.63636 11.9999C4.63636 7.94014 7.93936 4.63635 12 4.63635C12.5183 4.63635 13.0242 4.69017 13.5124 4.7925C13.776 4.25773 14.1324 3.77688 14.5605 3.37099Z"
      fill="currentColor"
    />
  </>,
  { viewBox: '0 0 24 24', fill: 'none' },
);

Message.displayName = 'Message';

export default Message;
