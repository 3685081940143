// @flow

import React from 'react';
import clsx from 'clsx';
import TextAreaAutoSize from 'react-textarea-autosize';
import Message from 'components/message';

import style from './text-area.module.scss';

type Props = {
  customClass?: string,
  disabled?: boolean,
  displayErrorEarly?: boolean,
  input: Input,
  isDropdownValuesField?: boolean,
  label: React.Node,
  labelCustomClass?: string,
  maxRows?: number,
  meta?: InputMeta,
  minRows?: number,
  resize?: 'both' | 'vertical' | 'horizontal',
};

const TextArea = (props: Props) => {
  const {
    customClass,
    disabled,
    displayErrorEarly,
    input,
    isDropdownValuesField,
    label,
    labelCustomClass,
    maxRows = 3,
    meta = {},
    minRows = 3,
    resize,
    ...textAreaProps
  } = props;

  const displayError = meta.dirty || meta.touched;

  const hasError = () => {
    const hasSubmitError = meta.submitError && !meta.dirtySinceLastSubmit;

    return Boolean(meta?.error) || Boolean(hasSubmitError);
  };

  const renderTextArea = () => {
    const textAreaContainerClasses = clsx(style.InputContainer, customClass, {
      [style.Disabled]: disabled,
    });
    const textAreaClasses = clsx(style.InputField, {
      [style.Error]: displayError && hasError(),
      [style.ResizeBoth]: resize === 'both',
      [style.ResizeHorizontal]: resize === 'horizontal',
      [style.ResizeVertical]: resize === 'vertical',
    });

    return (
      <div className={textAreaContainerClasses}>
        <TextAreaAutoSize
          {...input}
          disabled={disabled}
          {...textAreaProps}
          className={textAreaClasses}
          maxRows={maxRows}
          minRows={minRows}
          onKeyDown={(event) => {
            /*
              When we don't stop propagation it reaches keydown event handler
              Defined in hook (useKeyboardMovement) used in DropdownMenu and
              It does preventDefault => hence the cursor will not move

              Refer:
              - https://github.com/mlaursen/react-md/blob/v3.1.0/packages/menu/src/useMenuKeyDown.ts#L59
              - https://github.com/mlaursen/react-md/blob/v3.1.0/packages/utils/src/wia-aria/movement/presets.ts#L7
              - https://github.com/mlaursen/react-md/blob/v3.1.0/packages/utils/src/wia-aria/movement/useKeyboardMovement.ts#L206

              TODO: There is possibility that it will be fixed in new version
              which requires React upgrade, so upgrade the library after React upgrade
            */

            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
              event.stopPropagation();
            }
          }}
        />
      </div>
    );
  };

  const renderDropdownValuesDescription = () => {
    if (!isDropdownValuesField) {
      return null;
    }

    return (
      <span className={style.DropdownValuesField}>
        <Message
          id="Separate each value by a new line."
          comment="Help for the dropdown values field."
        />
      </span>
    );
  };

  const renderError = () => {
    let error = '';

    if (displayError && hasError()) {
      error = meta.error || meta.submitError;
    }

    return (
      <span data-testid="error" className={style.ErrorMessage}>
        {error}
      </span>
    );
  };

  const labelClasses = clsx(style.Label, labelCustomClass);

  if (label) {
    return (
      <label htmlFor={input.name} className={style.TextArea}>
        <span className={labelClasses}>
          {label}
        </span>
        {renderTextArea()}
        {renderDropdownValuesDescription()}
        {renderError()}
      </label>
    );
  }

  return (
    <div className={style.TextArea}>
      {renderTextArea()}
      {renderDropdownValuesDescription()}
      {renderError()}
    </div>
  );
};

export default TextArea;
