// @flow

import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';

import Button from 'components/button';
import Eye from 'components/icons/eye';
import EyeHidden from 'components/icons/eye-hidden';
import inputControl from 'hocs/input-control';
import NewCross from 'components/icons/new-cross';

import style from './text-field.module.scss';

type Props = {
  actionButtonText?: string,
  autoFocus?: boolean,
  clearable?: boolean,
  customClass?: string,
  disabled?: boolean,
  displayError?: (meta: InputMeta) => boolean;
  displayErrorEarly?: boolean,
  ellipsis?: boolean,
  errorCustomClass?: string,
  hideErrorsUntilTouched?: boolean,
  icon?: React.Node,
  staticIconSize?: boolean,
  input: Input,
  inputRef?: any,
  label?: React.Node | string,
  labelCustomClass?: string,
  lengthLimit?: number,
  loading?: boolean,
  meta?: InputMeta,
  name?: string,
  noErrorMessage?: boolean,
  onAction?: () => void,
  onBlur?: (reactFinalFormOnBlur: () => void, event: React.FocusEvent) => void,
  placeholder?: string,
  responsive?: boolean,
  reverseDirection?: boolean,
  type?: string,
  onFocus?: (event: React.FocusEvent) => void,
};

class TextField extends React.Component<Props> {
  static defaultProps = {
    actionButtonText: undefined,
    autoFocus: undefined,
    clearable: false,
    customClass: undefined,
    disabled: false,
    displayErrorEarly: false,
    hideErrorsUntilTouched: undefined,
    icon: null,
    staticIconSize: false,
    inputRef: undefined,
    label: null,
    lengthLimit: undefined,
    loading: false,
    meta: undefined,
    onAction: undefined,
    placeholder: '',
    responsive: false,
    reverseDirection: undefined,
    type: 'text',
  };

  inputRef: {
    current: HTMLInputElement | null,
  };

  constructor(props: Props) {
    super(props);

    this.inputRef = props.inputRef || React.createRef();
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      setImmediate(() => {
        if (this.inputRef.current) {
          this.inputRef.current.focus();
        }
      });
    }
  }

  onChange(value: string) {
    const { input } = this.props;

    if (isFunction(input.onChange)) {
      input.onChange({ target: { value } });
    }
  }

  getRemoveIcon = () => (
    <NewCross responsive />
  );

  clear = () => {
    const { input, clearable } = this.props;

    if (clearable && input.value) {
      this.onChange('');

      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  };

  displayError() {
    const {
      meta = {},
      displayErrorEarly,
      hideErrorsUntilTouched,
      displayError: displayErrorProp,
    } = this.props;

    if (displayErrorProp) {
      return displayErrorProp(meta);
    }

    const showDirtyErrors = !hideErrorsUntilTouched && meta.dirty;

    return displayErrorEarly || showDirtyErrors || meta.touched;
  }

  hasError() {
    const { meta = {} } = this.props;
    const hasSubmitError = meta.submitError && !meta.dirtySinceLastSubmit;

    return !!meta.error || !!hasSubmitError;
  }

  renderInput() {
    const {
      actionButtonText,
      autoFocus,
      clearable,
      customClass,
      disabled,
      displayErrorEarly,
      ellipsis,
      hideErrorsUntilTouched,
      icon,
      input,
      inputRef,
      label,
      labelCustomClass,
      errorCustomClass,
      lengthLimit,
      loading,
      meta,
      noErrorMessage,
      onAction,
      placeholder,
      responsive,
      reverseDirection,
      type,
      staticIconSize,
      onFocus,
      ...inputProps
    } = this.props;

    const inputContainerClasses = clsx(style.InputContainer, customClass, {
      [style.Error]: this.displayError() && this.hasError(),
      [style.Disabled]: disabled,
      [style.ReversedDirection]: reverseDirection,
    });

    const inputClasses = clsx(style.InputField, {
      [style.Ellipsis]: ellipsis,
    });

    const inputPropsBlur = (...args) => {
      inputProps.onBlur?.(input.onBlur, ...args);
    };

    const onBlurMethod = inputProps.onBlur ? inputPropsBlur : input.onBlur;

    return (
      <div className={inputContainerClasses}>
        {!reverseDirection && this.renderIcon()}
        <input
          {...input}
          {...inputProps}
          className={inputClasses}
          disabled={disabled}
          maxLength={lengthLimit}
          placeholder={placeholder}
          ref={this.inputRef}
          type={type}
          onBlur={onBlurMethod}
          onFocus={onFocus}
        />
        {reverseDirection && this.renderIcon()}
        {this.renderClearable()}
        {this.renderActionButton()}
      </div>
    );
  }

  renderIcon() {
    const { icon } = this.props;

    if (!icon) {
      return null;
    }

    return (
      <span className={style.icon}>
        {icon}
      </span>
    );
  }

  renderActionButton() {
    const { onAction, actionButtonText } = this.props;

    if (!onAction || !actionButtonText) {
      return null;
    }

    return (
      <Button
        onClick={onAction}
        color="thunder"
        customClass={style.ActionButton}
      >
        {actionButtonText}
      </Button>
    );
  }

  renderClearable() {
    const { input, clearable, staticIconSize } = this.props;
    const clearableClasses = clsx(style.Icon, {
      [style.StaticSize]: staticIconSize,
    });

    if (!clearable || !input.value) {
      return null;
    }

    return (
      <Button
        onClick={this.clear}
        customClass={clearableClasses}
        icon={this.getRemoveIcon}
      />
    );
  }

  renderError() {
    const { noErrorMessage, errorCustomClass, meta = {} } = this.props;
    let error = '';

    if (noErrorMessage) {
      return null;
    }

    if (this.displayError() && this.hasError()) {
      error = meta.error || meta.submitError;
    }

    return (
      <span className={clsx(style.ErrorMessage, errorCustomClass)}>
        {error}
      </span>
    );
  }

  render() {
    const {
      clearable,
      icon,
      input,
      label,
      labelCustomClass,
      name,
      responsive,
    } = this.props;

    const className = clsx(style.TextField, {
      [style.HasIcon]: icon,
      [style.IsClearable]: clearable && input.value,
      [style.Responsive]: responsive,
      [style.SearchField]: name === 'search',
    });

    if (label && input.name) {
      return (
        <label htmlFor={input.name} className={className}>
          <span className={clsx(style.Label, labelCustomClass)}>
            {label}
          </span>
          {this.renderInput()}
          {this.renderError()}
        </label>
      );
    }

    return (
      <div className={className}>
        {this.renderInput()}
        {this.renderError()}
      </div>
    );
  }
}

export const PasswordField = (props: any) => (
  <TextField
    type="password"
    autoComplete="new-password"
    {...props}
  />
);

export const PasswordFieldWithEye = (props: any) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const eye = <Eye className={style.EyeIcon} />;
  const eyeHidden = <EyeHidden className={style.EyeIcon} />;

  return (
    <TextField
      type={passwordShown ? 'text' : 'password'}
      icon={(
        <Button
          onClick={togglePasswordVisiblity}
          icon={!passwordShown ? eyeHidden : eye}
        />
      )}
      {...props}
      reverseDirection
      customClass={style.PasswordFieldWithEye}
    />
  );
};

export const NumberField = (props: any) => <TextField type="number" {...props} />;

export default TextField;

export const ControlledTextField = inputControl(TextField);
