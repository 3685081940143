// @flow

import React from 'react';

import Svg from 'components/svg';

const CrossCircle = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.17317 0.761205C7.38642 0.258658 8.68678 0 10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761204 13.8268C0.258657 12.6136 0 11.3132 0 10C0 8.68678 0.258657 7.38642 0.761204 6.17317C1.26375 4.95991 2.00035 3.85752 2.92893 2.92893C3.85752 2.00035 4.95991 1.26375 6.17317 0.761205ZM10 2C8.94942 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C3.60028 5.08601 3.011 5.96793 2.60896 6.93853C2.20693 7.90914 2 8.94943 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM6.625 5.20929L10 8.58429L13.375 5.20929L14.7892 6.6235L11.4142 9.9985L14.7892 13.3735L13.375 14.7877L10 11.4127L6.625 14.7877L5.21079 13.3735L8.58579 9.9985L5.21079 6.6235L6.625 5.20929Z"
    fill="currentColor"
  />
), { viewBox: '0 0 20 20', fill: 'none' });

CrossCircle.displayName = 'CrossCircle';

export default CrossCircle;
