// @flow

import React from 'react';

import Svg from 'components/svg';

const IndentIncrease = Svg((
  <>
    <path d="M20 5H4V7H20V5Z" fill="currentColor" />
    <path d="M20 17H4V19H20V17Z" fill="currentColor" />
    <path d="M8 12L4 15L4 9L8 12Z" fill="currentColor" />
    <path d="M20 9H12V11H20V9Z" fill="currentColor" />
    <path d="M20 13H12V15H20V13Z" fill="currentColor" />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

IndentIncrease.displayName = 'IndentIncrease';

export default IndentIncrease;
