// @flow

import React from 'react';

import Svg from 'components/svg';

const MoveToWorkspace = Svg((
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09998 8.5L8.09975 8.46872H9.99947C10.2222 9.34879 11.0194 10 11.9687 10C13.0906 10 14 9.09058 14 7.96875C14 7.03073 13.3642 6.24121 12.5 6.00768V3.99232C13.3642 3.75879 14 2.96927 14 2.03125C14 0.909422 13.0906 0 11.9687 0C11.0194 0 10.2223 0.651182 9.99949 1.53122H8.00047C7.7777 0.651182 6.98051 0 6.03123 0C4.9094 0 3.99998 0.909422 3.99998 2.03125C3.99998 2.99174 4.66664 3.79653 5.56248 4.00815V5.90092C5.54169 5.90031 5.52086 5.9 5.49998 5.9V7.1C5.80484 7.1 6.15992 7.25847 6.45072 7.54926C6.74151 7.84006 6.89998 8.19514 6.89998 8.5H8.09998ZM6.03123 2.8625C6.49032 2.8625 6.86248 2.49034 6.86248 2.03125C6.86248 1.57216 6.49032 1.2 6.03123 1.2C5.57214 1.2 5.19998 1.57216 5.19998 2.03125C5.19998 2.49034 5.57214 2.8625 6.03123 2.8625ZM8.00049 2.53122C7.82087 3.24089 7.26771 3.80174 6.56248 3.99232V6.16435C6.83847 6.3039 7.08843 6.48992 7.29924 6.70074C7.51798 6.91947 7.71002 7.18035 7.85116 7.46872H9.99949C10.1844 6.73825 10.7651 6.16545 11.5 5.99185V4.00815C10.765 3.83454 10.1844 3.26172 9.99947 2.53122H8.00049ZM12.8 2.03125C12.8 2.49034 12.4278 2.8625 11.9687 2.8625C11.5096 2.8625 11.1375 2.49034 11.1375 2.03125C11.1375 1.57216 11.5096 1.2 11.9687 1.2C12.4278 1.2 12.8 1.57216 12.8 2.03125ZM11.9687 8.8C12.4278 8.8 12.8 8.42784 12.8 7.96875C12.8 7.50966 12.4278 7.1375 11.9687 7.1375C11.5096 7.1375 11.1375 7.50966 11.1375 7.96875C11.1375 8.42784 11.5096 8.8 11.9687 8.8Z"
      fill="currentColor"
    />
    <path
      d="M3.47653 5.90516C3.62418 5.90453 3.76456 5.96921 3.86004 6.08184L6.7047 9.43763L5.94189 10.0843L3.99998 7.79342L3.99998 14H2.99998L2.99998 7.76056L1.064 10.0837L0.295776 9.44356L3.09452 6.08506C3.18905 5.97163 3.32888 5.90578 3.47653 5.90516Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

MoveToWorkspace.displayName = 'MoveToWorkspace';

export default MoveToWorkspace;
