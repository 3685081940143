// @flow

import React from 'react';

import Svg from 'components/svg';

const DeleteTableRow = Svg((
  <>
    <rect x="5" y="18" width="4" height="14" transform="rotate(-90 5 18)" stroke="currentColor" strokeWidth="2" />
    <rect width="6" height="2" transform="matrix(1 0 0.00340344 0.999994 9 7)" fill="currentColor" />
  </>
), {
  viewBox: '0 0 24 24',
  fill: 'none',
});

DeleteTableRow.displayName = 'DeleteTableRow';

export default DeleteTableRow;
