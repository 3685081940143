// @flow

import type { MessageTranslator } from '@oneflowab/pomes';

export const TWO_FACTOR_AUTHENTICATION_NONE = 'none';
export const TWO_FACTOR_AUTHENTICATION_EMAIL = 'email';
export const TWO_FACTOR_AUTHENTICATION_SMS = 'sms';
export const TWO_FACTOR_AUTHENTICATION_PERSONAL_ID = 'personal_identification';

export const twoFactorAuthenticationMethods = (message: MessageTranslator) => ({
  [TWO_FACTOR_AUTHENTICATION_NONE]: {
    label: message({
      id: 'None',
      comment: 'Used as the label of the two step authentication method none option.',
    }),
  },
  [TWO_FACTOR_AUTHENTICATION_EMAIL]: {
    label: message({
      id: 'Email',
      comment: 'Used as the label of the two step authentication method email option.',
    }),
  },
  [TWO_FACTOR_AUTHENTICATION_SMS]: {
    label: message({
      id: 'SMS',
      comment: 'Used as the label of the two step authentication method SMS option.',
    }),
  },
  [TWO_FACTOR_AUTHENTICATION_PERSONAL_ID]: {
    label: message({
      id: 'Personal identification',
      comment: 'Used as the label of the two step authentication method Personal identification option.',
    }),
  },
});

export const getTwoFactorAuthenticationMethod = (
  channel?: MfaChannelType, message: MessageTranslator,
) => (
  twoFactorAuthenticationMethods(message)[channel]
);
