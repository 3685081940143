import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { localize, Message } from '@oneflowab/pomes';
import clsx from 'clsx';
import debounce from 'debounce-promise';
import type { ReactNode, ComponentProps } from 'react';

import type { MessageTranslator } from '@oneflowab/pomes';
import type { UniqueParams } from 'forms/validators/unique';

import * as validators from 'forms/validators';
import Tooltip from 'components/tooltip';

import style from './field.module.scss';

type PatternParams = {
  regexp: RegExp,
  text?: string,
};

type Props<
  FieldValue = any,
  T extends HTMLElement = HTMLElement,
  InputValue = FieldValue,
  RP extends FieldRenderProps<FieldValue, T, InputValue> = FieldRenderProps<
    FieldValue,
    T,
    InputValue
  >> = {
    label: ReactNode,
    name: string,
    required?: boolean,
    minLength?: number,
    maxLength?: number,
    unique?: UniqueParams,
    hideLabel?: boolean,
    hideRequired?: boolean,
    phone?: boolean,
    email?: boolean,
    pattern?: PatternParams,
    fieldinfo?: {
      nextToLabel?: boolean,
      messageClassName?: string,
      icon?: ReactNode,
    } & Omit<ComponentProps<typeof Tooltip>, 'children'>,
    autoComplete?: string | boolean,
    customErrorMessage?: string,
  } & FieldProps<FieldValue, RP, T, InputValue>;

type InternalProps = {
  message: MessageTranslator,
} & Props;

export class FieldComponent extends React.Component<InternalProps> {
  static defaultProps = {
    minLength: undefined,
    maxLength: undefined,
    required: undefined,
    unique: undefined,
    hideLabel: undefined,
    phone: undefined,
    email: undefined,
    pattern: undefined,
    fieldinfo: undefined,
    customErrorMessage: undefined,
  };

  get validations() {
    const rawValidations = validators.composeValidators(
      this.getRequired(),
      this.getMinLength(),
      this.getMaxLength(),
      this.getPhone(),
      this.getEmail(),
      this.props.validate,
      this.getPattern(),
      this.getRole(),
      this.getUnique(), // Unique currently needs to be the last validator
    );

    if (this.props.unique) {
      return debounce(rawValidations, 300);
    }

    return rawValidations;
  }

  getRole() {
    const {
      name,
      customErrorMessage,
    } = this.props;

    if (name !== 'role' || !customErrorMessage) {
      return undefined;
    }

    return validators.role({
      customErrorMessage,
    });
  }

  getRequired() {
    const {
      label,
      message,
      required,
    } = this.props;

    if (!required) {
      return undefined;
    }

    return validators.required({
      field: label,
      message,
    });
  }

  getPhone() {
    const {
      label,
      phone,
      message,
    } = this.props;

    if (!phone) {
      return undefined;
    }

    return validators.phoneNumber({
      message,
      field: label,
    });
  }

  getEmail() {
    const {
      email,
      message,
    } = this.props;

    if (!email) {
      return undefined;
    }

    return validators.emailValidator({ message });
  }

  getMinLength() {
    const {
      label,
      minLength,
      message,
    } = this.props;

    if (!minLength) {
      return undefined;
    }

    return validators.minLength({
      message,
      field: label,
      minLength,
    });
  }

  getMaxLength() {
    const {
      label,
      maxLength,
      message,
    } = this.props;

    if (!maxLength) {
      return undefined;
    }

    return validators.maxLength({
      message,
      field: label,
      maxLength,
    });
  }

  getPattern() {
    const {
      label,
      pattern,
      message,
    } = this.props;

    if (!pattern) {
      return undefined;
    }

    return validators.patternValidator({
      message,
      field: label,
      regexp: pattern.regexp,
      text: pattern.text,
    });
  }

  getUnique() {
    const { unique, label } = this.props;

    if (!unique) {
      return undefined;
    }

    return validators.unique({
      ...unique,
      validationErrorMessage: (
        <Message
          id="{field} is not valid"
          comment="An error message shown the user when the string provided is not valid."
          values={{
            field: label,
          }}
        />
      ),
    });
  }

  getLabelWithFieldInfo() {
    const { fieldinfo } = this.props;

    if (fieldinfo) {
      const labelClasses = clsx(style.LabelContainer, {
        [style.NextToLabel]: fieldinfo.nextToLabel,
      });
      return (
        <div className={labelClasses}>
          {this.getLabel()}
          <Tooltip
            {...fieldinfo}
            messageClassName={
              fieldinfo.messageClassName
                ? fieldinfo.messageClassName
                : style.FieldInfoTooltip
            }
          >
            {fieldinfo.icon}
          </Tooltip>
        </div>
      );
    }

    return this.getLabel();
  }

  getLabel() {
    const {
      hideLabel,
      hideRequired,
      label,
      required,
    } = this.props;

    if (hideLabel) {
      return undefined;
    }

    if (required && hideRequired) {
      return (
        <div>
          {label}
        </div>
      );
    }

    if (required) {
      return (
        <div>
          {label}
          <span className={style.Required}> *</span>
        </div>
      );
    }

    return label;
  }

  render() {
    const {
      label,
      required,
      minLength,
      maxLength,
      message,
      hideLabel,
      phone,
      email,
      pattern,
      unique,
      hideRequired,
      autoComplete,
      ...fieldProps
    } = this.props;

    let autoFillProps = {};

    if (autoComplete === 'off' || autoComplete === false) {
      autoFillProps = {
        autoComplete: 'off',
        'data-1p-ignore': true,
        'data-lpignore': true,
        'data-bwignore': true,
        'data-form-type': 'other',
      };
    }

    return (
      <Field
        {...fieldProps}
        {...autoFillProps}
        label={this.getLabelWithFieldInfo()}
        validate={this.validations}
      />
    );
  }
}

export default localize(FieldComponent) as unknown as (<
  FieldValue = any,
  T extends HTMLElement = HTMLElement,
  InputValue = FieldValue,
  RP extends FieldRenderProps<FieldValue, T, InputValue> = FieldRenderProps<
    FieldValue,
    T,
    InputValue
  >
>(props: Props<FieldValue, T, InputValue, RP>) => React.ReactElement);
