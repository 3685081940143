// @flow

import React from 'react';

import Svg from 'components/svg';

const PasswordLock = Svg((
  <g id="Interface-Essential_Lock_Unlock_key-lock" data-name="Interface-Essential / Lock/Unlock / key-lock" transform="translate(-399.005 -1367)">
    <g id="Group_114" data-name="Group 114">
      <g id="key-lock">
        <path id="Oval_57" data-name="Oval 57" d="M402.755,1390.25a3,3,0,1,0-3-3A3,3,0,0,0,402.755,1390.25Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Shape_578" data-name="Shape 578" d="M405.755,1387.25h7.5v2.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Shape_579" data-name="Shape 579" d="M410.255,1387.25v1.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Shape_580" data-name="Shape 580" d="M405.755,1382v-3.75a1.5,1.5,0,0,1,1.5-1.5h13.5a1.5,1.5,0,0,1,1.5,1.5v10.5a1.5,1.5,0,0,1-1.5,1.5h-4.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Shape_581" data-name="Shape 581" d="M408.755,1376.75V1373a5.25,5.25,0,0,1,10.5,0v3.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Shape_582" data-name="Shape 582" d="M414.005,1381.25v3" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </g>
  </g>
));

PasswordLock.displayName = 'PasswordLock';

export default PasswordLock;
