// @flow

import React from 'react';

import Svg from 'components/svg';

const VideoSizeSmall = Svg((
  <>
    <mask id="path-1-inside-1_1565_7732" fill="white">
      <rect x="6.55713" y="7.46512" width="11.2811" height="9.63186" rx="0.76109" />
    </mask>
    <rect x="6.55713" y="7.46512" width="11.2811" height="9.63186" rx="0.76109" fill="currentColor" stroke="currentColor" strokeWidth="4" mask="url(#path-1-inside-1_1565_7732)" />
    <mask id="path-2-inside-2_1565_7732" fill="white">
      <rect y="0.500183" width="24" height="24" rx="1" />
    </mask>
    <rect y="0.500183" width="24" height="24" rx="1" stroke="currentColor" strokeWidth="4" mask="url(#path-2-inside-2_1565_7732)" />
  </>
), {
  viewBox: '0 0 24 25',
  fill: 'none',
});

VideoSizeSmall.displayName = 'VideoSizeSmall';

export default VideoSizeSmall;
