// @flow

import {
  isArrayLike,
  isBoolean,
  isEmpty,
  isFunction,
  isNumber,
  isObjectLike,
  isString,
} from 'lodash';
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

type Required = ({
  field?: string,
  message?: MessageTranslator,
  text?: string,
  usePlural?: boolean,
}) => Validator;

const required: Required = ({
  field,
  message,
  text,
  usePlural = false,
}) => {
  if (!message && !text && !field) {
    throw new Error('Text or (message and field) is mandatory for creating a required validation.');
  }

  return (value) => {
    let validationText;

    if (text) {
      validationText = text;
    } else {
      if (!field) {
        throw new Error('Field is mandatory for creating a required validation.');
      }

      if (!message || !isFunction(message)) {
        throw new Error('Message is mandatory for creating a required validation.');
      }

      validationText = message({
        id: '{field} is required',
        pluralId: '{field} are required',
        pluralCondition: 'pluralCount',
        values: {
          field,
          pluralCount: usePlural ? 2 : 1,
        },
        comment: 'The validation message for required fields.',
      });
    }

    if (isString(value)) {
      return value.trim() ? undefined : validationText;
    }

    if (isArrayLike(value) || isObjectLike(value)) {
      return isEmpty(value) ? validationText : undefined;
    }

    if (isBoolean(value) || isNumber(value)) {
      return undefined;
    }

    return value ? undefined : validationText;
  };
};

export default required;
