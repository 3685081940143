import { forwardRef } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { ContentProps } from './types';

const TooltipContent = forwardRef<HTMLDivElement, ContentProps>(({
  as: Component = TooltipPrimitive.Content,
  align,
  alignOffset,
  children,
  className,
  collisionBoundary,
  collisionPadding,
  hideContent,
  onClick,
  onPointerDownOutside,
  side,
  sideOffset,
  sticky,
  zIndex,
}, ref) => {
  if (hideContent) return null;

  return (
    <Component
      align={align}
      alignOffset={alignOffset}
      className={className}
      collisionBoundary={collisionBoundary}
      collisionPadding={collisionPadding}
      onClick={onClick}
      onPointerDownOutside={onPointerDownOutside}
      side={side}
      sideOffset={sideOffset}
      sticky={sticky}
      style={{ userSelect: 'auto', zIndex }}
      ref={ref}
    >
      {children}
    </Component>
  );
});

TooltipContent.displayName = 'TooltipContent';

export default TooltipContent;
