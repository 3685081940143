import Svg from 'components/svg';

const Fragment = () => (
  <>
    <path
      d="M18 16.2471C17.586 16.2471 17.25 15.9111 17.25 15.4971V10.2471H16.5C16.086 10.2471 15.75 9.91107 15.75 9.49707C15.75 9.08307 16.086 8.74707 16.5 8.74707H19.5C19.914 8.74707 20.25 9.08307 20.25 9.49707C20.25 9.91107 19.914 10.2471 19.5 10.2471H18.75V15.4971C18.75 15.9111 18.414 16.2471 18 16.2471Z"
      fill="currentColor"
    />
    <path
      d="M13.5 16.25C12.259 16.25 11.25 15.241 11.25 14V9.5C11.25 9.086 11.586 8.75 12 8.75C12.414 8.75 12.75 9.086 12.75 9.5V14C12.75 14.414 13.086 14.75 13.5 14.75H15C15.414 14.75 15.75 15.086 15.75 15.5C15.75 15.914 15.414 16.25 15 16.25H13.5Z"
      fill="currentColor"
    />
    <path
      d="M9 16.25C8.586 16.25 8.25 15.914 8.25 15.5V13.247H6.75V15.5C6.75 15.914 6.414 16.25 6 16.25C5.586 16.25 5.25 15.914 5.25 15.5V11C5.25 9.759 6.259 8.75 7.5 8.75C8.741 8.75 9.75 9.759 9.75 11V15.5C9.75 15.914 9.414 16.25 9 16.25ZM8.25 11.747V11C8.25 10.586 7.914 10.25 7.5 10.25C7.086 10.25 6.75 10.586 6.75 11V11.747H8.25Z"
      fill="currentColor"
    />
  </>
);

const AltText = Svg(Fragment, { viewBox: '0 0 24 25', fill: 'none' });

AltText.displayName = 'AltText';

export default AltText;
