import { COMMENT_STATES, MESSAGE_TYPES } from 'comments/constants';
import {
  PARTICIPANTS_TAB,
  DOCUMENT_TAB,
  MESSAGES_TAB,
  AUDIT_TRAIL_TAB,
  SETTINGS_TAB,
  DATA_FIELDS_TAB,
  MORE_OPTIONS_TAB,
} from 'agreement/constants';
import workspacesReducer from 'reducers/entities/workspaces';
import type { RootState } from 'agreement-view/reducers/reducers';

export const CHANGE_CURRENT_WORKSPACE = 'app/CHANGE_CURRENT_WORKSPACE' as const;
export const SELECT_WORKSPACE = 'app/SELECT_WORKSPACE' as const;
export const SET_AGREEMENT_SIDEBAR_OPEN = 'app/SET_AGREEMENT_SIDEBAR_STATUS ' as const;
export const SET_COLLAPSED_LAYOUT_ACTIVE_TAB_NAME = 'app/SET_COLLAPSED_LAYOUT_ACTIVE_TAB_NAME' as const;
export const SET_AGREEMENT_SIDEBAR_ACTIVE_TAB_NAME = 'app/SET_AGREEMENT_SIDEBAR_ACTIVE_TAB_NAME' as const;
export const SET_AGREEMENT_SIDEBAR_COMMENT_STATE = 'app/SET_AGREEMENT_SIDEBAR_COMMENT_STATE' as const;
export const SET_AGREEMENT_SIDEBAR_MESSAGE_TYPE = 'app/SET_AGREEMENT_SIDEBAR_MESSAGE_TYPE' as const;
export const SET_AVAILABLE_CONTENT_INLINE_SIZE = 'app/SET_AVAILABLE_CONTENT_INLINE_SIZE' as const;
export const SET_CLIENT_FEATURE_FLAGS = 'app/SET_CLIENT_FEATURE_FLAGS' as const;
export const SET_CONTRACT_WORKSPACE = 'app/SET_CONTRACT_WORKSPACE' as const;
export const SET_CURRENT_POSITION_ID = 'app/SET_CURRENT_POSITION_ID' as const;
export const SET_CURRENT_WORKSPACE = 'app/SET_CURRENT_WORKSPACE' as const;
export const SET_DASHBOARD_DATE_RANGE = 'app/SET_DASHBOARD_DATE_RANGE' as const;
export const SET_DASHBOARD_USER = 'app/SET_DASHBOARD_USER' as const;
export const SET_DEFAULT_WORKSPACE = 'app/SET_DEFAULT_WORKSPACE' as const;
export const SET_ERROR = 'app/SET_ERROR' as const;
export const SET_LOADING = 'app/SET_LOADING' as const;
export const SET_SUCCESSFULLY_SIGNED_MODAL_OPEN = 'app/SET_SUCCESSFULLY_SIGNED_MODAL_OPEN' as const;
export const SET_TOKEN = 'app/SET_TOKEN' as const;
export const UPDATE_SEEN_THREADS = 'app/UPDATE_SEEN_THREADS' as const;
export const SET_RESTORE_TOASTS_LIST = 'app/SET_RESTORE_TOASTS_LIST' as const;
export const REMOVE_ID_IN_RESTORE_TOASTS_LIST = 'app/REMOVE_ID_IN_RESTORE_TOASTS_LIST' as const;

type AgreementSidebarActiveTabName = typeof PARTICIPANTS_TAB
  | typeof MESSAGES_TAB
  | typeof AUDIT_TRAIL_TAB
  | typeof SETTINGS_TAB
  | typeof DATA_FIELDS_TAB
  | typeof MORE_OPTIONS_TAB;

type CollapsedLayoutActiveTabName = typeof DOCUMENT_TAB
  | typeof PARTICIPANTS_TAB
  | typeof MESSAGES_TAB
  | typeof AUDIT_TRAIL_TAB
  | typeof SETTINGS_TAB
  | typeof DATA_FIELDS_TAB
  | typeof MORE_OPTIONS_TAB;

type AgreementSidebarMessageType = typeof MESSAGE_TYPES[keyof typeof MESSAGE_TYPES];
type AgreementSidebarCommentState = typeof COMMENT_STATES[keyof typeof COMMENT_STATES];

export const setDefaultWorkspace = (
  { workspaceId }: { workspaceId: Oneflow.Workspace['id'] },
) => ({
  type: SET_DEFAULT_WORKSPACE,
  workspaceId,
});

export const setContractWorkspace = (
  { workspaceId }: { workspaceId: Oneflow.Workspace['id']},
) => ({
  type: SET_CONTRACT_WORKSPACE,
  workspaceId,
});

export const changeCurrentWorkspace = (
  { workspaceId }: { workspaceId: Oneflow.Workspace['id'] },
) => ({
  type: CHANGE_CURRENT_WORKSPACE,
  workspaceId,
});

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  token,
});

export const setCurrentWorkspace = ({ workspaceId }: { workspaceId: Oneflow.Workspace['id']}) => ({
  type: SET_CURRENT_WORKSPACE,
  workspaceId,
});

export const selectWorkspace = ({ workspaceId }: { workspaceId: Oneflow.Workspace['id'] }) => ({
  type: SELECT_WORKSPACE,
  workspaceId,
});

export const setLoading = (isLoading: boolean) => ({
  type: SET_LOADING,
  isLoading,
});

type DateRange = {
  form: number,
  to: number,
};

export const setDashboardDateRange = ({ dateRange }: { dateRange: DateRange }) => ({
  type: SET_DASHBOARD_DATE_RANGE,
  dateRange,
});

export const setDashboardUser = ({ user }: { user: string }) => ({
  type: SET_DASHBOARD_USER,
  user,
});

export const setError = (hasError: boolean, error: any) => ({
  type: SET_ERROR,
  hasError,
  error,
});

export const setCurrentPositionId = (currentPositionId: Oneflow.Position['id']) => ({
  type: SET_CURRENT_POSITION_ID,
  currentPositionId,
});

export const setAvailableContentInlineSize = (availableContentInlineSize: number) => ({
  type: SET_AVAILABLE_CONTENT_INLINE_SIZE,
  availableContentInlineSize,
});

export const setClientFeatureFlags = (clientFeatureFlags: Record<string, boolean>) => ({
  type: SET_CLIENT_FEATURE_FLAGS,
  clientFeatureFlags,
});

export const setAgreementSidebarActiveTabName = (activeTabName: AgreementSidebarActiveTabName) => ({
  type: SET_AGREEMENT_SIDEBAR_ACTIVE_TAB_NAME,
  activeTabName,
});

export const setCollapsedLayoutActiveTabName = (activeTabName: CollapsedLayoutActiveTabName) => ({
  type: SET_COLLAPSED_LAYOUT_ACTIVE_TAB_NAME,
  activeTabName,
});

export const setAgreementSidebarOpen = (status: boolean) => ({
  type: SET_AGREEMENT_SIDEBAR_OPEN,
  status,
});

export const setAgreementSidebarMessageType = (messageType: AgreementSidebarMessageType) => ({
  type: SET_AGREEMENT_SIDEBAR_MESSAGE_TYPE,
  messageType,
});

export const setAgreementSidebarCommentState = (commentState: AgreementSidebarCommentState) => ({
  type: SET_AGREEMENT_SIDEBAR_COMMENT_STATE,
  commentState,
});

export const setSuccessfullySignedModalOpen = (isOpen: boolean) => ({
  type: SET_SUCCESSFULLY_SIGNED_MODAL_OPEN,
  isOpen,
});

export const updateSeenMessageThreads = (seenThreadId: number) => ({
  type: UPDATE_SEEN_THREADS,
  seenThreadId,
});

export const setRestoreToastList = (toastId: string) => ({
  type: SET_RESTORE_TOASTS_LIST,
  toastId,
});

export const removeIdInRestoreToastList = (toastId: string) => ({
  type: REMOVE_ID_IN_RESTORE_TOASTS_LIST,
  toastId,
});

type Action =
  | ReturnType<typeof changeCurrentWorkspace>
  | ReturnType<typeof setCurrentWorkspace>
  | ReturnType<typeof setDefaultWorkspace>
  | ReturnType<typeof setContractWorkspace>
  | ReturnType<typeof setToken>
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setCurrentPositionId>
  | ReturnType<typeof setError>
  | ReturnType<typeof setDashboardDateRange>
  | ReturnType<typeof setDashboardUser>
  | ReturnType<typeof setAvailableContentInlineSize>
  | ReturnType<typeof setClientFeatureFlags>
  | ReturnType<typeof setAgreementSidebarActiveTabName>
  | ReturnType<typeof setCollapsedLayoutActiveTabName>
  | ReturnType<typeof setAgreementSidebarOpen>
  | ReturnType<typeof setAgreementSidebarMessageType>
  | ReturnType<typeof setAgreementSidebarCommentState>
  | ReturnType<typeof setSuccessfullySignedModalOpen>
  | ReturnType<typeof updateSeenMessageThreads>
  | ReturnType<typeof setRestoreToastList>
  | ReturnType<typeof removeIdInRestoreToastList>;

type AppState = {
  currentWorkspaceId: Oneflow.Workspace['id'] | null;
  defaultWorkspaceId: Oneflow.Workspace['id'] | null;
  contractWorkspaceId: Oneflow.Workspace['id'] | null;
  currentPositionId: Oneflow.Position['id'] | null;
  isLoading: boolean;
  hasError: boolean;
  error: any;
  token: string | null;
  dashboardDateRange: DateRange | null;
  dashboardUser: string | null;
  clientFeatureFlags: Record<string, boolean>;
  collapsedLayoutActiveTabName: CollapsedLayoutActiveTabName;
  agreementSidebarOpen: boolean;
  agreementSidebarActiveTabName: AgreementSidebarActiveTabName;
  agreementSidebarMessageType: AgreementSidebarMessageType;
  agreementSidebarCommentState: AgreementSidebarCommentState;
  seenMessageThreads: number[];
  successfullySignedModalOpen: boolean;
  availableContentInlineSize: number;
  toastIds: string[];
};

const initialState: AppState = {
  currentWorkspaceId: null,
  defaultWorkspaceId: null,
  contractWorkspaceId: null,
  currentPositionId: null,
  isLoading: false,
  hasError: false,
  error: undefined,
  token: null,
  dashboardDateRange: null,
  dashboardUser: null,
  clientFeatureFlags: {},
  collapsedLayoutActiveTabName: DOCUMENT_TAB,
  agreementSidebarOpen: true,
  agreementSidebarActiveTabName: PARTICIPANTS_TAB,
  agreementSidebarMessageType: MESSAGE_TYPES.CHAT,
  agreementSidebarCommentState: COMMENT_STATES.COMMENT_ACTIVE,
  seenMessageThreads: [],
  successfullySignedModalOpen: false,
  availableContentInlineSize: 0,
  toastIds: [],
};

const app = (state: AppState = initialState, action: Action) => {
  switch (action.type) {
    case CHANGE_CURRENT_WORKSPACE:
    case SET_CURRENT_WORKSPACE:
      return {
        ...state,
        currentWorkspaceId: action.workspaceId,
      };
    case SET_DEFAULT_WORKSPACE:
      return {
        ...state,
        defaultWorkspaceId: action.workspaceId,
      };
    case SET_CONTRACT_WORKSPACE:
      return {
        ...state,
        contractWorkspaceId: action.workspaceId,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_CURRENT_POSITION_ID:
      return {
        ...state,
        currentPositionId: action.currentPositionId,
      };
    case SET_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        error: action.error,
      };
    case SET_DASHBOARD_DATE_RANGE:
      return {
        ...state,
        dashboardDateRange: action.dateRange,
      };
    case SET_DASHBOARD_USER:
      return {
        ...state,
        dashboardUser: action.user,
      };
    case SET_AVAILABLE_CONTENT_INLINE_SIZE:
      return {
        ...state,
        availableContentInlineSize: action.availableContentInlineSize,
      };
    case SET_CLIENT_FEATURE_FLAGS:
      return {
        ...state,
        clientFeatureFlags: action.clientFeatureFlags,
      };
    case SET_AGREEMENT_SIDEBAR_ACTIVE_TAB_NAME:
      return {
        ...state,
        agreementSidebarActiveTabName: action.activeTabName,
      };
    case SET_COLLAPSED_LAYOUT_ACTIVE_TAB_NAME:
      return {
        ...state,
        collapsedLayoutActiveTabName: action.activeTabName,
      };
    case SET_AGREEMENT_SIDEBAR_OPEN:
      return {
        ...state,
        agreementSidebarOpen: action.status,
      };
    case SET_AGREEMENT_SIDEBAR_MESSAGE_TYPE:
      return {
        ...state,
        agreementSidebarMessageType: action.messageType,
      };
    case SET_AGREEMENT_SIDEBAR_COMMENT_STATE:
      return {
        ...state,
        agreementSidebarCommentState: action.commentState,
      };
    case SET_SUCCESSFULLY_SIGNED_MODAL_OPEN:
      return {
        ...state,
        successfullySignedModalOpen: action.isOpen,
      };
    case UPDATE_SEEN_THREADS:
      if (state.seenMessageThreads.includes(action.seenThreadId)) {
        return state;
      }
      return {
        ...state,
        seenMessageThreads: [...state.seenMessageThreads, action.seenThreadId],
      };
    case SET_RESTORE_TOASTS_LIST:
      return {
        ...state,
        toastIds: [...state.toastIds, action.toastId],
      };
    case REMOVE_ID_IN_RESTORE_TOASTS_LIST:
      return {
        ...state,
        toastIds: state.toastIds.filter((id) => id !== action.toastId),
      };
    default:
      return state;
  }
};

export default app;

// selectors
export const getLoadingStateSelector = (state: RootState) => (
  state.app.isLoading
);

export const getErrorStateSelector = (state: RootState) => (
  state.app.hasError
);

export const getCurrentWorkspaceIdSelector = (state: RootState) => {
  const { contractWorkspaceId, currentWorkspaceId } = state.app;

  if (contractWorkspaceId !== null && contractWorkspaceId !== undefined) {
    return contractWorkspaceId;
  }

  return currentWorkspaceId;
};

export const getDefaultWorkspaceIdSelector = (state: RootState) => (
  state.app.defaultWorkspaceId
);

export const getCurrentPositionIdSelector = (state: RootState) => (
  state.app.currentPositionId
);

export const getCurrentWorkspaceSelector = (state: RootState) => {
  const workspaceId = getCurrentWorkspaceIdSelector(state);

  // currentWorkspaceId can be 0, so cannot do simple falsy check
  if (workspaceId === null || workspaceId === undefined) {
    return {} as Record<string, never>;
  }

  return workspacesReducer.getWorkspaceSelector(state, { id: workspaceId });
};

export const getDefaultWorkspaceSelector = (state: RootState) => {
  if (state.app.defaultWorkspaceId === null
    || state.app.defaultWorkspaceId === undefined) {
    return {} as Record<string, never>;
  }

  return workspacesReducer.getWorkspaceSelector(state, { id: state.app.defaultWorkspaceId });
};

export const getDashboardDateRangeSelector = (state: RootState) => (
  state.app.dashboardDateRange
);

export const getDashboardUserSelector = (state: RootState) => (
  state.app.dashboardUser
);

export const getGuestTokenSelectorFromSession = (state: RootState) => (
  state.session && state.session.guestToken
);

export const getCurrentTokenSelector = (state: RootState) => (
  state.app.token || getGuestTokenSelectorFromSession(state)
);

export const getAvailableContentInlineSize = (state: RootState) => (
  state.app.availableContentInlineSize
);

export const getClientFeatureFlagSelector = (
  state: RootState,
  featureFlag: string,
) => (
  state.app.clientFeatureFlags[featureFlag]
);

export const getCollapseLayoutActiveTabNameSelector = (state: RootState) => (
  state.app.collapsedLayoutActiveTabName
);

export const getAgreementSidebarOpenSelector = (state: RootState) => (
  state.app.agreementSidebarOpen
);

export const getAgreementSidebarActiveTabNameSelector = (state: RootState) => (
  state.app.agreementSidebarActiveTabName
);

export const getAgreementSidebarMessageTypeSelector = (state: RootState) => (
  state.app.agreementSidebarMessageType
);

export const getAgreementSidebarCommentStateSelector = (state: RootState) => (
  state.app.agreementSidebarCommentState
);

export const getSeenMessageThreadsSelector = (state: RootState) => (
  state.app.seenMessageThreads
);

export const getSuccessfullySignedModalOpen = (state: RootState) => (
  state.app.successfullySignedModalOpen
);

export const getRestoreToastIds = (state: RootState) => (
  state.app.toastIds
);
