// @flow

import React from 'react';

import Svg from 'components/svg';

const BreakLeft = Svg((
  <>
    <path d="M20 5H4V7H20V5Z" fill="currentColor" />
    <path d="M20 17H4V19H20V17Z" fill="currentColor" />
    <rect width="6" height="6" transform="matrix(-1 0 0 1 10 9)" fill="currentColor" />
  </>
));

BreakLeft.displayName = 'BreakLeft';

export default BreakLeft;
