// @flow

import React from 'react';

import Svg from 'components/svg';

const NoWorkspaces = Svg((
  <>
    <path
      d="M1.5 17.75C1.5 19.275 2.1058 20.7375 3.18414 21.8159C4.26247 22.8942 5.72501 23.5 7.25 23.5C8.77499 23.5 10.2375 22.8942 11.3159 21.8159C12.3942 20.7375 13 19.275 13 17.75C13 16.225 12.3942 14.7625 11.3159 13.6841C10.2375 12.6058 8.77499 12 7.25 12C5.72501 12 4.26247 12.6058 3.18414 13.6841C2.1058 14.7625 1.5 16.225 1.5 17.75V17.75Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.18433 21.8157L11.3157 13.6843"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7063 3.45312C13.7063 4.69749 12.6975 5.70625 11.4531 5.70625C10.2088 5.70625 9.2 4.69749 9.2 3.45312C9.2 2.20876 10.2088 1.2 11.4531 1.2C12.6975 1.2 13.7063 2.20876 13.7063 3.45312ZM12.3732 6.78231C13.564 6.45394 14.497 5.5038 14.8009 4.30307L18.1991 4.30307C18.5135 5.54492 19.5006 6.51872 20.75 6.81385V10.1862C19.5035 10.4806 18.518 11.4506 18.2013 12.6884H14.5581C14.3231 12.2409 14.009 11.8317 13.6488 11.4834C13.279 11.1257 12.8454 10.8172 12.3732 10.5902V6.78231ZM14.4057 14.413H13.8058C13.8058 13.7069 13.4342 12.9452 12.8146 12.3459C12.1962 11.7478 11.3997 11.379 10.6486 11.379V10.6558V10.179V6.81203C9.12952 6.44952 8 5.08323 8 3.45312C8 1.54602 9.54602 0 11.4531 0C13.0669 0 14.4221 1.10701 14.8008 2.60307H18.1992C18.5779 1.10701 19.9331 0 21.5469 0C23.454 0 25 1.54602 25 3.45312C25 5.04776 23.9191 6.38994 22.45 6.78695V10.213C23.9191 10.6101 25 11.9522 25 13.5469C25 15.454 23.454 17 21.5469 17C19.9389 17 18.5876 15.9009 18.2033 14.4131H14.4057V14.413ZM21.5469 5.70625C22.7912 5.70625 23.8 4.69749 23.8 3.45312C23.8 2.20876 22.7912 1.2 21.5469 1.2C20.3025 1.2 19.2937 2.20876 19.2937 3.45312C19.2937 4.69749 20.3025 5.70625 21.5469 5.70625ZM23.8 13.5469C23.8 14.7912 22.7912 15.8 21.5469 15.8C20.3025 15.8 19.2937 14.7912 19.2937 13.5469C19.2937 12.3025 20.3025 11.2937 21.5469 11.2937C22.7912 11.2937 23.8 12.3025 23.8 13.5469Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 25 25', fill: 'none' });

NoWorkspaces.displayName = 'NoWorkspaces';

export default NoWorkspaces;
