import type { ReactNode } from 'react';
import clsx from 'clsx';

import style from './error-box.module.scss';

export type ErrorMessage = {
  headerText: ReactNode,
  bodyText?: ReactNode,
  hasInfoStyle?: boolean,
  hasErrorStyle?: boolean,
};

type Props = ErrorMessage;

const ErrorBox = ({
  headerText, bodyText, hasInfoStyle, hasErrorStyle,
}: Props) => {
  const renderHeader = () => {
    if (!headerText) {
      return null;
    }

    return (
      <div className={style.Header}>
        {headerText}
      </div>
    );
  };

  if (!headerText && !bodyText) {
    return null;
  }

  const boxClasses = clsx({
    [style.Info]: hasInfoStyle,
    [style.Error]: hasErrorStyle,
  });

  return (
    <div className={boxClasses}>
      {renderHeader()}
      <div className={style.Paragraph}>
        {bodyText}
      </div>
    </div>
  );
};

export default ErrorBox;
