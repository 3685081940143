// @flow

import React from 'react';

import Svg from 'components/svg';

const CancelContractChanges = Svg(
  (
    <>
      <path d="M6.25 4.00073C4.925 4.00073 3.725 4.49573 2.8 5.30073L1 3.50073V8.00073H5.5L3.69 6.19073C4.385 5.61073 5.27 5.25073 6.25 5.25073C8.02 5.25073 9.525 6.40573 10.05 8.00073L11.235 7.61073C10.54 5.51573 8.575 4.00073 6.25 4.00073Z" fill="currentColor" />
    </>
  ), { viewBox: '0 0 12 12', fill: 'none' },
);

CancelContractChanges.displayName = 'CancelContractChanges';

export default CancelContractChanges;
