import Svg from 'components/svg';

const Gift = Svg((
  <>
    <path id="Vector" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M12.5 3h-11c-0.552285 0 -1 0.44772 -1 1v2c0 0.55228 0.447715 1 1 1h11c0.5523 0 1 -0.44772 1 -1V4c0 -0.55228 -0.4477 -1 -1 -1Z" strokeWidth="1" />
    <path id="Vector_2" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M12.5 7v5.5c0 0.2652 -0.1054 0.5196 -0.2929 0.7071s-0.4419 0.2929 -0.7071 0.2929h-9c-0.26522 0 -0.51957 -0.1054 -0.70711 -0.2929C1.60536 13.0196 1.5 12.7652 1.5 12.5V7" strokeWidth="1" />
    <path id="Vector_3" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M7 3v10.5" strokeWidth="1" />
    <path id="Vector_4" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M10 0.5 7 3 4 0.5" strokeWidth="1" />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

Gift.displayName = 'Gift';

export default Gift;
