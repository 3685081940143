// @flow

import React from 'react';

import Svg from 'components/svg';

const Check = Svg((
  <g transform="matrix(2.666667, 0, 0, 2.666667, 0, 2.65)">
    <path d="M 3.096 4.895 L 1.08 2.79 L 0 3.876 L 3.096 7.055 L 9 1.043 L 7.92 -0.037 L 3.096 4.895 Z" />
  </g>
));

Check.displayName = 'Check';

export default Check;
