import {
  call,
  select,
  race,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import scriptjs from 'scriptjs';
import { capitalize } from 'lodash';

import {
  getAccountFromSessionSelector,
  getPositionFromSessionSelector,
} from 'reducers/session';
import {
  getCurrentWorkspaceSelector,
  getCurrentWorkspaceIdSelector,
} from 'reducers/app';
import { createAgreement } from 'oneflow-client/agreements';
import { ORIGINAL_LOCATION_CHANGE } from 'reducers/router';
import { redirectToDocument } from 'agreement/navigation-helpers';

import { getDaysSinceTimestamp } from 'date';
import { checkAcl } from 'components/acl';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import sessionStorage from 'utils/session-storage';
import log from 'logging';

const BUTTON_PIN_CLICKED = 'Button Pin Clicked';
const BUTTON_CLICK = 'click';
const END_FLOW = 'end_flow';
const CANCEL_SUBSCRIPTION = 'Cancel subscription';
const CHURN_OTHER = 'Churn - Other';
const CREATE_FIRST_DOCUMENT = 'Create your first document';

export const cancellationReasons = Object.freeze({
  INFREQUENT_USAGE: 'We don\'t use Oneflow enough',
  TOO_DIFFICULT: 'Oneflow is too difficult to use',
  MISSING_FEATURES: 'There are missing features or integrations',
  FOUND_ATERNATIVE: 'We have found an alternative',
  TOO_EXPENSIVE: 'It\'s too expensive',
  BUSINESS_CLOSING: 'Our business is closing',
  OTHER_REASON: 'Other',
});

export const acceptOfferEvents = Object.freeze({
  BOOK_TIME: 'Let\'s book a time!',
  BOOK_SESSION: 'Book a free session',
  GET_FREE_PLAN: 'Get Oneflow\'s Free Plan',
  REACH_OUT: 'Reach out to me*',
  SCHEDULE_MEETING: 'Schedule a meeting',
});

export const eventNames = Object.freeze({
  CONFIRM_CANCELLATION: 'Confirm cancellation',
  CONFIRM_CHANGE: 'Confirm change',
  CHECKLIST_ITEM_COMPLETED: 'checklist_item_completed',
});

const capitalizeEventName = (text) => text.split(' ').map((word) => capitalize(word)).join(' ');

const initEventsListeners = (workspaceId) => (
  window.Appcues.on('all', (eventName, {
    interaction,
    itemLabel,
    experienceName,
    interactionType,
    flowName,
  }) => {
    if (experienceName === CANCEL_SUBSCRIPTION && interactionType === BUTTON_PIN_CLICKED) {
      amplitudeLogEvent('Go To Cancel Subscription');
    }

    if (interaction?.text) {
      if (Object.values(cancellationReasons).includes(interaction.text)) {
        let reason = '';

        switch (interaction.text) {
          case cancellationReasons.INFREQUENT_USAGE:
            reason = 'Infrequent use';
            break;
          case cancellationReasons.TOO_DIFFICULT:
            reason = 'Too difficult to use';
            break;
          case cancellationReasons.MISSING_FEATURES:
            reason = 'Missing features';
            break;
          case cancellationReasons.FOUND_ATERNATIVE:
            reason = 'Found alternative';
            break;
          case cancellationReasons.TOO_EXPENSIVE:
            reason = 'Too expensive';
            break;
          case cancellationReasons.BUSINESS_CLOSING:
            reason = 'Business closing';
            break;
          case cancellationReasons.OTHER_REASON:
            reason = 'Other reason';
            break;
          default:
            reason = '';
            break;
        }

        sessionStorage.setItem('cancellation_reason', reason);
      }

      if (
        flowName === CHURN_OTHER
        && interactionType === BUTTON_CLICK
        && interaction.destination !== END_FLOW
      ) {
        const reason = sessionStorage.getItem('cancellation_reason');
        const capitalizedEventName = capitalizeEventName(eventNames.CONFIRM_CANCELLATION);

        amplitudeLogEvent(
          capitalizedEventName,
          { reason },
        );
      }

      if (Object.values(eventNames).includes(interaction.text)) {
        let reason = sessionStorage.getItem('cancellation_reason');
        let capitalizedEventName = capitalizeEventName(interaction.text);

        if (interaction.text === eventNames.CONFIRM_CHANGE) {
          capitalizedEventName = 'Request Update Subscription';
          reason = null;
        }

        amplitudeLogEvent(
          capitalizedEventName,
          { reason },
        );
      }

      if (Object.values(acceptOfferEvents).includes(interaction.text)) {
        const reason = sessionStorage.getItem('cancellation_reason');
        let offer = 'Free consultation';

        if (interaction.text === acceptOfferEvents.GET_FREE_PLAN) {
          offer = 'Switch to free plan';
        }
        if (interaction.text === acceptOfferEvents.REACH_OUT) {
          offer = 'Discount plan - Reach out to user';
        }
        if (interaction.text === acceptOfferEvents.SCHEDULE_MEETING) {
          offer = 'Discount plan - Meeting booked';
        }

        amplitudeLogEvent(
          'Accept Offer',
          { reason, offer },
        );
      }
    }

    if (
      itemLabel === CREATE_FIRST_DOCUMENT
      && eventName !== eventNames.CHECKLIST_ITEM_COMPLETED
    ) {
      createAgreement({ workspaceId, amplitudeScope: 'onboarding - checklist' })
        .then(({ result }) => redirectToDocument(result));
    }

    return null;
  }));

export function* identifyAppcues() {
  const position = yield select(getPositionFromSessionSelector);
  const workspace = yield select(getCurrentWorkspaceSelector);

  if (position && position.id && !position.ghost) {
    const account = yield select(getAccountFromSessionSelector);
    const daysSinceSignup = getDaysSinceTimestamp(position.registerTimestampTs);
    let newSignup = 'No';

    if (daysSinceSignup <= 14) {
      newSignup = 'Yes';
    }

    const canCreateBlankContract = checkAcl(workspace.acl, 'collection:agreement:create_blank');
    const canUseTemplates = Boolean(
      checkAcl(workspace.acl, 'collection:agreement:template:view')
      && checkAcl(workspace.acl, 'collection:agreement:template:create'),
    );
    const canCreateUser = checkAcl(account.acl, 'account:position:create');

    yield call(window.Appcues.identify, position.id, {
      accountId: account.id,
      signupTimestamp: position.registerTimestampTs,
      lastLoggedIn: position.loginTimestampTs,
      isTrial: account.trial ? 'Yes' : 'No',
      userRole: position.userRole, // DEPRECATED: remove when appcues is not using
      language: position.language,
      newSignup,
      canCreateBlankContract,
      canUseTemplates,
      canCreateUser,
      hasCreatedWorkspace: !!account.onboardingStage,
      plan: account.planName,
      isDemo: account.isDemo,
    });
  } else {
    yield call(window.Appcues.anonymous);
  }
}

export function* updateAppcues() {
  if (typeof window.Appcues !== 'undefined') {
    yield call(identifyAppcues);
  }
}

export function* refreshAppcues() {
  yield takeEvery(ORIGINAL_LOCATION_CHANGE, updateAppcues);
}

export function loadAppcuesScript() {
  return new Promise((resolve, reject) => {
    scriptjs('https://fast.appcues.com/30161.js', () => {
      if (window.Appcues) {
        resolve(true);
      } else {
        reject();
      }
    });
  }).catch(() => log.warning('Appcues was not loaded'));
}

export function* loadAppcues() {
  const position = yield select(getPositionFromSessionSelector);

  // Do not load appcues for guests
  if (!(position && position.id)) {
    return;
  }

  const { loaded, timeout } = yield race({
    loaded: call(loadAppcuesScript),
    timeout: delay(5000),
  });

  if (loaded) {
    yield call(identifyAppcues);

    const workspaceId = yield select(getCurrentWorkspaceIdSelector);
    yield call(initEventsListeners, workspaceId);
  } else if (timeout && !window.Appcues) {
    yield call(log.warning, 'Appcues was not loaded on time');
  }
}
