// @flow

import React from 'react';

import Svg from 'components/svg';

const Dashboard = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M14.0848 1.6501L14.0848 7.52401H22.35V1.6501H14.0848ZM23.65 0.350098V8.82401H12.7848L12.7848 0.350098L23.65 0.350098ZM0.349976 0.3501H11.2152V13.6066H0.349976V0.3501ZM1.64998 1.6501V12.3066H9.91519V1.6501H1.64998ZM12.7848 10.3936L23.65 10.3936L23.65 23.6501L12.7848 23.6501L12.7848 10.3936ZM14.0848 11.6936L14.0848 22.3501L22.35 22.3501L22.35 11.6936L14.0848 11.6936ZM0.349976 15.1762H11.2152V23.6501H0.349976V15.1762ZM1.64998 16.4762V22.3501H9.91519V16.4762H1.64998Z"
  />
), { viewBox: '0 0 24 24', fill: 'none' });

Dashboard.displayName = 'Dashboard';

export default Dashboard;
