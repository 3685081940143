// @flow

import { enabledLangCodes } from 'utils/languages';
import createMediaStream from 'utils/media-stream';

export const getFirstEnabledLanguage = (candidateLanguages?: Array<string>) => {
  if (!candidateLanguages) {
    return 'en';
  }

  return [
    ...candidateLanguages,
    'en',
  ].find((candidate) => (
    enabledLangCodes.includes(candidate.split('-')[0])
  )).split('-')[0];
};

const getRecommendedLanguage = () => getFirstEnabledLanguage(window.navigator.languages);

const BASE_VIDEO_CAPABILITIES = {
  aspectRatio: 1.7777777778,
};

export const CAMERA_CAPABILITIES = {
  ...BASE_VIDEO_CAPABILITIES,
  frameRate: {
    min: 23.0,
    ideal: 30.0,
    max: 60.0,
  },
  width: {
    min: 640,
    ideal: 1280,
    max: 1920,
  },
  height: {
    min: 480,
    ideal: 720,
    max: 1080,
  },
  facingMode: 'user',
};

export const SCREEN_CAPABILITIES = {
  ...BASE_VIDEO_CAPABILITIES,
  frameRate: 30,
  width: 1280,
  height: 720,
};

export const BASE_AUDIO_CAPABILITIES = {
  echoCancellation: true,
  sampleRate: 44100,
  sampleSize: 24,
};

type Navigator = {
  userAgent: string,
  vendor: string,
  requestCamera: () => Promise<MediaStream>,
  requestAudio: () => Promise<MediaStream>,
  requestScreen: () => Promise<MediaStream>,
};

const navigator: Navigator = {
  get userAgent(): string {
    return window.navigator.userAgent;
  },

  get vendor(): string {
    return window.navigator.vendor;
  },

  requestCamera(): Promise<MediaStream> {
    if (!window.navigator.mediaDevices) {
      throw new Error('Unsupported browser');
    }

    return (
      window.navigator
        .mediaDevices
        .getUserMedia({
          video: {
            ...CAMERA_CAPABILITIES,
          },
          audio: {
            ...BASE_AUDIO_CAPABILITIES,
          },
        })
    );
  },

  requestAudio() {
    if (!window.navigator.mediaDevices) {
      throw new Error('Unsupported browser');
    }

    return (
      window.navigator
        .mediaDevices
        .getUserMedia({
          audio: {
            ...BASE_AUDIO_CAPABILITIES,
          },
        })
    );
  },

  requestScreen() {
    if (!window.navigator.mediaDevices) {
      throw new Error('Unsupported browser');
    }

    return (
      window.navigator
        .mediaDevices
        .getDisplayMedia({
          video: {
            ...SCREEN_CAPABILITIES,
          },
        })
        .then((videoStream: MediaStream) => navigator.requestAudio()
          .then((audioStream: MediaStream) => createMediaStream([
            ...videoStream.getVideoTracks(),
            ...audioStream.getAudioTracks(),
          ])))
    );
  },
};

export {
  navigator as default,
  getRecommendedLanguage,
};
