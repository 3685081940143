// @flow

import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { checkAcl } from 'components/acl';

import { isAnyDeclinedState, isAnySignedState, isTemplate } from 'agreement/states';
import {
  getAgreementMyParticipant,
  getAgreementPossibleDeliveryChannels,
  isAgreementOwner,
} from 'agreement/selectors';
import hasDeliveryChannelEmail from './has-delivery-channel-email';
import hasDeliveryChannelSms from './has-delivery-channel-sms';
import hasDeliveryChannelEmailAndSms from './has-delivery-channel-email-and-sms';

import isOrganizer from './is-organizer';
import isSignatory from './is-signatory';
import isInfluencer from './is-influencer';
import isInternalApprover from './is-internal-approver';
import isExternalApprover from './is-external-approver';
import {
  SIGN_METHOD_DK_MITID_NEMID_SIGN,
  SIGN_METHOD_EID_SIGN,
  SIGN_METHOD_ESIGN,
  SIGN_METHOD_FI_FTN_SIGN,
  SIGN_METHOD_HANDWRITTEN_SIGN,
  SIGN_METHOD_MANUAL,
  SIGN_METHOD_NO_BANKID_SIGN,
  SIGN_METHOD_SE_BANKID,
  SIGN_METHOD_SMS,
} from './constants';
import * as participantConstants from './constants';

type ParticipantHelper = (participant: AgreementParticipant) => boolean;
type ParticipantsSort = (participant: Array<AgreementParticipant>) => Array<AgreementParticipant>;
/* type */

export const isViewer: ParticipantHelper = (participant) => (
  participant.type === participantConstants.TYPE_IS_VIEWER
);

export const hasNoPosition: ParticipantHelper = (participant) => (
  !participant.position || !participant.position.id
);

/* deliveryChannel */
export const hasDeliveryChannel: ParticipantHelper = (participant) => (
  Boolean(participant.deliveryChannel !== participantConstants.DELIVERY_CHANNEL_NONE)
);

/* deliveryChannelStatus */
export const hasFailed: ParticipantHelper = (participant) => (
  participant.deliveryChannelStatus === participantConstants.DELIVERY_FAILED
);
export const hasBounced: ParticipantHelper = (participant) => (
  participant.deliveryChannelStatus === participantConstants.DELIVERY_BOUNCE
);
export const hasAnyFailureStatus: ParticipantHelper = (participant) => (
  hasFailed(participant) || hasBounced(participant)
);
export const hasEmailDeliveryFailed: ParticipantHelper = (participant) => (

  hasAnyFailureStatus(participant) && (
    hasDeliveryChannelEmail(participant) || hasDeliveryChannelEmailAndSms(participant)
  )
);

export const hasSMSDeliveryFailed: ParticipantHelper = (participant) => (
  hasAnyFailureStatus(participant) && hasDeliveryChannelSms(participant)
);
export const hasDeliverySuccess: ParticipantHelper = (participant) => (
  participant.deliveryChannelStatus === participantConstants.DELIVERY_SUCCESS
);
export const hasDeliveryOpened: ParticipantHelper = (participant) => (
  participant.deliveryChannelStatus === participantConstants.DELIVERY_OPENED
);
export const hasAnyDeliverySuccess: ParticipantHelper = (participant) => (
  hasDeliverySuccess(participant)
  || hasDeliveryOpened(participant)
  || !participant.deliveryChannelStatus
);
export const isDeliveryChannelVerified: ParticipantHelper = (participant) => (
  participant.deliveryChannelVerified
);

/* mfaChannel */
export const hasMfaChannel: ParticipantHelper = (participant) => (
  Boolean(participant.mfaChannel !== participantConstants.MFA_CHANNEL_NONE)
);
export const hasMfaChannelEmail: ParticipantHelper = (participant) => (
  participant.mfaChannel === participantConstants.MFA_CHANNEL_EMAIL
);
export const hasMfaChannelSms: ParticipantHelper = (participant) => (
  participant.mfaChannel === participantConstants.MFA_CHANNEL_SMS
);
export const hasMfaChannelPersonalIdentification: ParticipantHelper = (participant) => (
  participant.mfaChannel === participantConstants.MFA_CHANNEL_PERSONAL_IDENTIFICATION
);

/* signMethod */
export const hasSignMethodEsign: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_ESIGN
);
export const hasSignMethodSMS: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_SMS
);

export const hasSignMethodSwedishBankId: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_SE_BANKID
);

export const hasSignMethodNorwegianBankIdSign: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_NO_BANKID_SIGN
);
export const hasSignMethodDanishMitIdNemIdSign: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_DK_MITID_NEMID_SIGN
);
export const hasSignMethodFinnishFtnSign: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_FI_FTN_SIGN
);

export const hasSignMethodEidSign: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_EID_SIGN
);

export const hasSignMethodElectronicIdExceptSwedishBankId: ParticipantHelper = (participant) => (
  hasSignMethodNorwegianBankIdSign(participant)
  || hasSignMethodDanishMitIdNemIdSign(participant)
  || hasSignMethodFinnishFtnSign(participant)
  || hasSignMethodEidSign(participant)
);

export const hasSignMethodHandwritten: ParticipantHelper = (participant) => (
  participant.signMethod === participantConstants.SIGN_METHOD_HANDWRITTEN_SIGN
);

/* sorting */
const typeSortOrder = {
  [participantConstants.TYPE_IS_SIGNATORY]: 1,
  [participantConstants.TYPE_IS_INFLUENCER]: 2,
  [participantConstants.TYPE_IS_VIEWER]: 3,
};
export const sortByType: ParticipantsSort = (participants) => (
  sortBy<AgreementParticipant>(participants, (participant: AgreementParticipant) => ([
    typeSortOrder[participant.type],
    participant.fullname.toLowerCase(),
  ]))
);

export const sortParticipantsByFullname = (participants) => (
  participants.sort((a, b) => {
    if (a.fullname && b.fullname) {
      return a.fullname.localeCompare(b.fullname);
    }
    return a.name.localeCompare(b.name);
  })
);

export const getParticipantRole = (message, participant) => {
  const roles = [
    {
      role: message({ id: 'Internal approver', comment: 'Participant role' }),
      condition: isInternalApprover,
    },
    {
      role: message({ id: 'Approver', comment: 'Participant role' }),
      condition: isExternalApprover,
    },
    {
      role: message({ id: 'Organizer', comment: 'Participant role' }),
      condition: isOrganizer,
    },
    {
      role: message({ id: 'Signatory', comment: 'Participant role' }),
      condition: isSignatory,
    },
    {
      role: message({ id: 'Influencer', comment: 'Participant role' }),
      condition: isInfluencer,
    },
    {
      role: message({ id: 'Viewer', comment: 'Participant role' }),
      condition: isViewer,
    },
  ];

  return get(
    roles.find((role) => role.condition(participant)),
    'role',
    null,
  );
};

export const mapParticipantRoleType = (type, participant) => {
  if (isInternalApprover(participant)) {
    return participantConstants.TYPE_IS_INTERNAL_APPROVER;
  }

  if (isExternalApprover(participant)) {
    return participantConstants.TYPE_IS_EXTERNAL_APPROVER;
  }

  return type;
};

type ShareSameParty = (
  AgreementParticipant,
  AgreementParticipant,
) => boolean;
export const shareSameParty: ShareSameParty = (participant1, participant2) => (
  participant1.agreementCompany.id === participant2.agreementCompany.id
);

type IsEditable = (
  Account,
  Agreement,
  AgreementParty,
  AgreementParticipant,
) => boolean;
export const isEditable: IsEditable = (account, agreement, party, participant) => {
  const myParticipant = getAgreementMyParticipant(agreement);
  if (isTemplate(agreement)) {
    return true;
  }

  if (!myParticipant || !party || isViewer(myParticipant)) {
    return false;
  }

  const organizer = isOrganizer(participant);
  if (!organizer && (isAnySignedState(agreement) || isAnyDeclinedState(agreement))) {
    return false;
  }

  const currentUserIsOwner = isAgreementOwner(account, agreement);
  const canChangeParticipant = currentUserIsOwner
    || participant.id === myParticipant.id
    || shareSameParty(participant, myParticipant);

  if (canChangeParticipant) {
    return true;
  }

  return false;
};

type CanSendSms = (
  Account,
  Agreement,
) => boolean;
export const canSendSms: CanSendSms = (account, agreement) => {
  const possibleChannels = getAgreementPossibleDeliveryChannels(agreement);
  // Enough to check for a single delivery channel using sms
  return possibleChannels.indexOf(participantConstants.DELIVERY_CHANNEL_SMS) > -1;
};

export const getParticipantSigningMethod = (message, participant) => {
  const signingMethods = [
    {
      label: message({ id: 'Standard e-signature', comment: 'Label for e-sign signing method' }),
      short: message({ id: 'E-sign', comment: 'Short label for e-sign signing method' }),
      signMethod: SIGN_METHOD_ESIGN,
    },
    {
      label: message({ id: 'SMS verification', comment: 'Label for sms signing method' }),
      short: message({ id: 'SMS', comment: 'Short label for sms signing method' }),
      signMethod: SIGN_METHOD_SMS,
    },
    {
      label: message({ id: 'Swedish BankID', comment: 'Label for Swedish BankID signing method' }),
      short: message({ id: 'Swedish BankID', comment: 'Short label for Swedish BankID signing method' }),
      signMethod: SIGN_METHOD_SE_BANKID,
    },
    {
      label: message({ id: 'Manual', comment: 'Label for manual signing method' }),
      short: message({ id: 'Manual', comment: 'Short label for manual signing method' }),
      signMethod: SIGN_METHOD_MANUAL,
    },
    {
      label: message({ id: 'Norwegian BankID', comment: 'Label for norwegian BankID signing method' }),
      short: message({ id: 'Norwegian BankID', comment: 'Short label for norwegian BankID signing method' }),
      signMethod: SIGN_METHOD_NO_BANKID_SIGN,
    },
    {
      label: message({ id: 'Danish MitID', comment: 'Label for Danish MitID signing method' }),
      short: message({ id: 'Danish MitID', comment: 'Short label for Danish MitID signing method' }),
      signMethod: SIGN_METHOD_DK_MITID_NEMID_SIGN,
    },
    {
      label: message({ id: 'Finnish FTN', comment: 'Label for Finnish FTN signing method' }),
      short: message({ id: 'Finnish FTN', comment: 'Short label for Finnish FTN signing method' }),
      signMethod: SIGN_METHOD_FI_FTN_SIGN,
    },
    {
      label: message({ id: 'Handwritten Signature', comment: 'Label for finnish BankID signing method' }),
      short: message({ id: 'Handwritten Signature', comment: 'Short label for Finnish BankID signing method' }),
      signMethod: SIGN_METHOD_HANDWRITTEN_SIGN,
    },
    {
      label: message({ id: 'Electronic ID', comment: 'Label for Electronic ID signing method' }),
      short: message({ id: 'Electronic ID', comment: 'Short label for Electronic ID signing method' }),
      signMethod: SIGN_METHOD_EID_SIGN,
    },
  ];

  return signingMethods.find((method) => method.signMethod === participant.signMethod);
};

export const canEditAnyParticipantDetail: ParticipantHelper = (participant) => {
  const hasUpdateFullnamePermission = checkAcl(participant.acl, 'participant:update:fullname');
  const hasUpdateTitlePermission = checkAcl(participant.acl, 'participant:update:title');
  const hasUpdatePhoneNumberPermission = checkAcl(participant.acl, 'participant:update:phone_number');
  const hasUpdateSsnPermission = checkAcl(participant.acl, 'participant:update:ssn');
  const hasUpdateEmailPermission = checkAcl(participant.acl, 'participant:update:email');
  const hasUpdateTypePermission = checkAcl(participant.acl, 'participant:update:type');
  const hasUpdateDeliveryChannelPermission = checkAcl(participant.acl, 'participant:update:delivery_channel');
  const hasUpdateSignMethodPermission = checkAcl(participant.acl, 'participant:update:sign_method');
  const hasUpdateMfaChannelPermission = checkAcl(participant.acl, 'participant:update:mfa_channel');
  const hasUpdateCountryPermission = checkAcl(participant.acl, 'participant:update:country');

  const result = hasUpdateFullnamePermission
  || hasUpdateTitlePermission
  || hasUpdatePhoneNumberPermission
  || hasUpdateSsnPermission
  || hasUpdateEmailPermission
  || hasUpdateTypePermission
  || hasUpdateDeliveryChannelPermission
  || hasUpdateSignMethodPermission
  || hasUpdateMfaChannelPermission
  || hasUpdateCountryPermission;

  return result;
};

export const isParticipantInOwnerParty = (account, participant) => (
  account.id === participant.account?.id
);

export const getParty = (participant) => participant?.agreementCompany;

export const hasAccess = (participant) => {
  if (participant.hasAccess === undefined) {
    return true;
  }

  return !!participant.hasAccess;
};

export const isFormerColleague = (participant) => {
  const participantHasPositionId = (
    participant.position !== null && participant.position?.id !== null
  );
  return !hasAccess(participant) && !participantHasPositionId;
};

export const isPhoneNumberRequired = (signingDetails) => (
  hasDeliveryChannelSms(signingDetails)
  || hasDeliveryChannelEmailAndSms(signingDetails)
  || hasSignMethodSMS(signingDetails)
  || hasMfaChannelSms({
    ...signingDetails, mfaChannel: signingDetails.twoStepAuthenticationMethod,
  })
);

export const isEmailRequired = (signingDetails) => (
  hasDeliveryChannelEmail(signingDetails)
  || hasDeliveryChannelEmailAndSms(signingDetails)
  || hasMfaChannelEmail({
    ...signingDetails, mfaChannel: signingDetails.twoStepAuthenticationMethod,
  })
);

export const shouldValidatePersonalId = (signMethod) => {
  if (signMethod === participantConstants.SIGN_METHOD_SE_BANKID) {
    return true;
  }

  return false;
};

export const getSignMethodPlaceholder = (message, signMethod) => {
  let placeholder = '';

  if (signMethod === participantConstants.SIGN_METHOD_SE_BANKID) {
    placeholder = message({
      id: 'YYYYMMDD-XXXX',
      comment: 'Placeholder (Swedish) for personal identification field',
    });
  }

  if (signMethod === participantConstants.SIGN_METHOD_NO_BANKID_SIGN) {
    placeholder = message({
      id: 'DDMMYY',
      comment: 'Placeholder (Norwegian) for personal identification field',
    });
  }

  if (signMethod === participantConstants.SIGN_METHOD_DK_MITID_NEMID_SIGN) {
    placeholder = message({
      id: 'DDMMYYXXXX',
      comment: 'Placeholder (Danish) for personal identification field',
    });
  }

  if (signMethod === participantConstants.SIGN_METHOD_FI_FTN_SIGN) {
    placeholder = message({
      id: 'DDMMYY-XXXX',
      comment: 'Placeholder (Finnish) for personal identification field',
    });
  }

  return placeholder;
};
