// @flow

import * as React from 'react';
import { orderBy } from 'natural-orderby';
import SelectField from 'components/select-field';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { DATA_FIELD } from 'components/contract-boxes/constants';

type Props = {
  agreement: Agreement,
  onChange: Function,
  selectedDataField: ?number,
  message: MessageTranslator,
};

const ContractDataFields = ({
  agreement,
  onChange,
  selectedDataField,
  message,
}: Props) => {
  const getDataFields = () => agreement.data
    .filter<{}>((item) => item.key === DATA_FIELD)
    .map((item) => item.value);

  const input = {
    onChange,
    value: selectedDataField,
    name: 'dataFieldSelector',
  };

  const sortedDataFields = orderBy(
    getDataFields(),
    [(v) => v.name],
    ['asc'],
  );

  return (
    <SelectField
      input={input}
      options={sortedDataFields}
      onChange={onChange}
      valueKey="id"
      labelKey="name"
      multi={false}
      searchable
      clearable={false}
      noScroll
      small
      placeholder={message({
        id: 'Select data fields',
        comment: 'The placeholder for selecting a data field',
      })}
    />
  );
};

export default localize<Props>(ContractDataFields);
