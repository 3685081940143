// @flow

import React from 'react';

import Svg from 'components/svg';

const Bell = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9.00005 1.63521C7.77217 1.63521 6.59535 2.12015 5.72827 2.98212C4.86132 3.84397 4.37497 5.01208 4.37497 6.22925C4.37497 9.21532 3.8279 11.0908 3.13992 12.2438C3.07068 12.3598 2.99995 12.4686 2.92816 12.5705H15.072C15.0007 12.4694 14.9305 12.3615 14.8618 12.2464C14.1731 11.0941 13.6251 9.21842 13.6251 6.22925C13.6251 5.62649 13.5057 5.02953 13.2736 4.47244C13.0415 3.91534 12.7011 3.4089 12.2718 2.98212C11.8425 2.55534 11.3327 2.2166 10.7712 1.98542C10.2098 1.75424 9.60795 1.63521 9.00005 1.63521ZM17 12.5705C17.0076 12.5705 17.0075 12.5705 17.0001 12.5686C16.9755 12.5622 16.8701 12.5348 16.7041 12.4177C16.5042 12.2765 16.2412 12.0205 15.9777 11.5796C15.4491 10.695 14.9251 9.07509 14.9251 6.22925C14.9251 5.4547 14.7717 4.68782 14.4736 3.97244C14.1755 3.25707 13.7387 2.60732 13.1884 2.06018C12.638 1.51305 11.9848 1.07924 11.2662 0.783346C10.5476 0.487452 9.77761 0.335205 9.00005 0.335205C7.42985 0.335205 5.9232 0.955257 4.81174 2.06018C3.70016 3.16523 3.07497 4.66481 3.07497 6.22925C3.07497 9.07208 2.55189 10.6922 2.02356 11.5776C1.7602 12.019 1.49724 12.2756 1.29714 12.4171C1.13043 12.535 1.02458 12.5623 1.00009 12.5686C0.992909 12.5705 0.992723 12.5705 1.00005 12.5705V13.8705H17V12.5705ZM7.57992 14.3083L7.74609 14.9367C7.81449 15.1953 7.97153 15.4294 8.19791 15.599C8.42468 15.7689 8.70688 15.8632 9.00005 15.8632C9.29322 15.8632 9.57542 15.7689 9.80219 15.599C10.0286 15.4294 10.1856 15.1953 10.254 14.9367L10.4202 14.3083L11.677 14.6406L11.5108 15.269C11.3659 15.817 11.037 16.2982 10.5817 16.6394C10.1267 16.9802 9.5702 17.1632 9.00005 17.1632C8.42989 17.1632 7.87336 16.9802 7.41841 16.6394C6.96306 16.2982 6.63422 15.817 6.4893 15.269L6.32312 14.6406L7.57992 14.3083Z"
    fill="currentColor"
  />
), { viewBox: '0 0 18 18', fill: 'none' });

Bell.displayName = 'Bell';

export default Bell;
