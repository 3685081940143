/**
 * state
 * 0: Undecided/Enabled
 * 1: Signed/Enabled
 * 2: Declined/Enabled
 * 3: Disabled
 */
export const STATE_UNDECIDED = 0;
export const STATE_HAS_SIGNED = 1;
export const STATE_HAS_DECLINED = 2;
export const STATE_IS_DISABLED = 3;

/**
 * type
 * 0: Influencer
 * 1: Signatory
 * 2: Organizer
 * 3: Viewer
 * 1000: Internal approver (client-only, maps to organizer)
 * 1001: External Approver
 */
export const TYPE_IS_INFLUENCER = 0;
export const TYPE_IS_SIGNATORY = 1;
export const TYPE_IS_ORGANIZER = 2;
export const TYPE_IS_VIEWER = 3;
export const TYPE_IS_INTERNAL_APPROVER = 1000; // Not a real type, just used in client
export const TYPE_IS_EXTERNAL_APPROVER = 1001; // Not a real type, just used in client

/**
 * deliveryChannelStatus
 * 1: Pending
 * 2: Failure
 * 3: Bounce
 * 4: Success
 * 5: Opened
 */
export const DELIVERY_FAILED = 2;
export const DELIVERY_BOUNCE = 3;
export const DELIVERY_SUCCESS = 4;
export const DELIVERY_OPENED = 5;

/**
 * deliveryChannel
 * 0: Email
 * 1: Sms
 * 5: Email and Sms
 * 2: Same device
 * 4: none
*/
export const DELIVERY_CHANNEL_EMAIL = 0;
export const DELIVERY_CHANNEL_SMS = 1;
export const DELIVERY_CHANNEL_EMAIL_AND_SMS = 5;
export const DELIVERY_CHANNEL_SAME_DEVICE = 2;
export const DELIVERY_CHANNEL_NONE = 4;

/**
 * mfaChannel:
 * Email
 * SMS
 * Personal identification
 * None
*/
export const MFA_CHANNEL_EMAIL = 'email';
export const MFA_CHANNEL_SMS = 'sms';
export const MFA_CHANNEL_PERSONAL_IDENTIFICATION = 'personal_identification';
export const MFA_CHANNEL_NONE = null;
export const BODY_MFA_CHANNEL_NONE = 'none';

/**
 * signMethod
 * 0: E-signature
 * 1: SMS
 * 2: Swedish BankID (AdES signing)
 * 3: Manual (set after mark as sign, not an option)
 * 5: Danish MitID (AdES signing)
 * 6: Finnish BankID (AdES signing)
 * 10: Handwritten and typed signature
*/
export const SIGN_METHOD_ESIGN = 0;
export const SIGN_METHOD_SMS = 1;
export const SIGN_METHOD_SE_BANKID = 2;
export const SIGN_METHOD_MANUAL = 3;
export const SIGN_METHOD_NO_BANKID_SIGN = 4;
export const SIGN_METHOD_DK_MITID_NEMID_SIGN = 5;
export const SIGN_METHOD_FI_FTN_SIGN = 6;
export const SIGN_METHOD_HANDWRITTEN_SIGN = 10;
export const SIGN_METHOD_EID_SIGN = 13;
