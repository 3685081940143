// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_20030');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M0.5 1.5H13.5V12.5H0.5V1.5Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M0.5 2.8252L6.355 8.00148C6.53542 8.16102 6.7639 8.24853 7 8.24853C7.2361 8.24853 7.46458 8.16102 7.645 8.00148L13.5 2.8252"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>

  );
};

const EnvelopeMedium = Svg(Fragment, { viewBox: '0 0 14 14', fill: 'none' });

EnvelopeMedium.displayName = 'EnvelopeMedium';

export default EnvelopeMedium;
