// @flow

import React, { useRef } from 'react';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { localize } from '@oneflowab/pomes';
import PhoneInput from 'react-phone-input-2';

import style from './phone-number.module.scss';

type Props = {
  input: Input,
  meta?: InputMeta,
  label?: string,
  displayErrorEarly?: boolean,
  hideErrorsUntilTouched?: boolean,
  noErrorMessage?: boolean,
  disabled?: boolean,
  defaultCountry?: string,
  preferredCountries?: Array<string>,
  isPhoneInputValid?: () => boolean,
  setCountryCode?: (string) => void,
  setCountry?: (string) => void,
  onCountryChangeState?: string,
  setInputFieldCurrentValue?: (string) => void
}

export const PhoneNumber = ({
  input,
  meta,
  label,
  displayErrorEarly,
  hideErrorsUntilTouched,
  noErrorMessage,
  disabled,
  defaultCountry = 'se',
  preferredCountries = ['fi', 'dk', 'no', 'se'],
  isPhoneInputValid,
  setCountryCode,
  setCountry,
  onCountryChangeState,
  setInputFieldCurrentValue,
  ...inputProps
}: Props) => {
  const phoneInputRef = useRef();
  const displayError = () => {
    const showDirtyErrors = !hideErrorsUntilTouched && meta.dirty;

    return displayErrorEarly || showDirtyErrors || meta.touched;
  };

  const hasError = () => {
    const hasSubmitError = meta.submitError && !meta.dirtySinceLastSubmit;

    return !!meta.error || !!hasSubmitError;
  };

  const inputContainerClasses = clsx(style.InputField, {
    [style.Error]: displayError() && hasError(),
    [style.Disabled]: disabled,
  });

  const handleChange = (value: string) => {
    const fullValue = value.startsWith('+') ? value : '+'.concat(value);

    if (isFunction(input.onChange)) {
      const currentState = phoneInputRef?.current?.state;
      setCountryCode?.(currentState?.selectedCountry?.countryCode);
      setCountry?.(currentState?.selectedCountry?.iso2);
      input.onChange({ target: { value: fullValue } });
    }
  };

  const renderError = () => {
    let error = '';

    if (noErrorMessage) {
      return null;
    }

    if (displayError() && hasError()) {
      error = meta.error || meta.submitError;
    }

    return (
      <span className={style.ErrorMessage}>
        {error}
      </span>
    );
  };

  const setStates = () => {
    const currentState = phoneInputRef?.current?.state;
    setInputFieldCurrentValue?.(currentState?.formattedNumber?.replace('+', ''));
    setCountryCode?.(currentState?.selectedCountry?.countryCode);
    setCountry?.(currentState?.selectedCountry?.iso2);
  };

  const renderPhoneInput = () => (
    <div className={inputContainerClasses}>
      <PhoneInput
        {...input}
        {...inputProps}
        country={defaultCountry}
        disabled={disabled}
        preferredCountries={preferredCountries}
        disableAreaCodes
        autoFormat={false}
        onChange={handleChange}
        buttonClass={style.FlagDropdown}
        inputClass={style.Input}
        isValid={() => {
          // Calling this function because we get an error if we directly set state here.
          setStates();
          // Returning true because we are not using this validator.
          return true;
        }}
        ref={phoneInputRef}
      />
    </div>
  );

  if (label) {
    return (
      <label htmlFor={input.name} className={style.PhoneNumber}>
        <span className={style.Label}>
          {label}
        </span>
        {renderPhoneInput()}
        {renderError()}
      </label>
    );
  }

  return (
    <div className={style.PhoneNumber}>
      {renderPhoneInput()}
      {renderError()}
    </div>
  );
};

export default localize<Props>(PhoneNumber);
