import { call, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import * as countries from 'i18n-iso-countries';

import en from 'i18n-iso-countries/langs/en.json';
import fi from 'i18n-iso-countries/langs/fi.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import de from 'i18n-iso-countries/langs/de.json';
import nb from 'i18n-iso-countries/langs/nb.json';
import es from 'i18n-iso-countries/langs/es.json';
import sv from 'i18n-iso-countries/langs/sv.json';
import da from 'i18n-iso-countries/langs/da.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import pt from 'i18n-iso-countries/langs/pt.json';
import it from 'i18n-iso-countries/langs/it.json';

import { REDUX_I18N_SET_LANGUAGE } from 'reducers/i18n';

export function* setMomentLanguage(action) {
  if (action.lang === 'en') {
    yield call(moment.locale, 'en-gb');
  } else {
    yield call(moment.locale, action.lang);
  }
}

export function* registerIsoCountryLanguages() {
  yield call(countries.registerLocale, en);
  yield call(countries.registerLocale, fi);
  yield call(countries.registerLocale, fr);
  yield call(countries.registerLocale, de);
  yield call(countries.registerLocale, nb);
  yield call(countries.registerLocale, es);
  yield call(countries.registerLocale, sv);
  yield call(countries.registerLocale, da);
  yield call(countries.registerLocale, nl);
  yield call(countries.registerLocale, pt);
  yield call(countries.registerLocale, it);
}

export default function* i18n() {
  yield takeEvery(REDUX_I18N_SET_LANGUAGE, setMomentLanguage);
}
