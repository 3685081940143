// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19908');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M1.5 15.9974C1.50261 16.2717 1.58389 16.54 1.73493 16.773C1.88598 17.006 2.10097 17.1946 2.35635 17.3181C2.61678 17.4473 2.90769 17.5096 3.20069 17.4988C3.49369 17.488 3.77877 17.4046 4.02813 17.2567L15.7552 10.2593C15.9823 10.1291 16.1703 9.9446 16.3008 9.72388C16.4314 9.50317 16.5 9.25375 16.5 9C16.5 8.74625 16.4314 8.49683 16.3008 8.27612C16.1703 8.0554 15.9823 7.87092 15.7552 7.74071L4.02813 0.743317C3.77877 0.595386 3.49369 0.511966 3.20069 0.501194C2.90769 0.490423 2.61678 0.552667 2.35635 0.681856C2.10097 0.805445 1.88598 0.994049 1.73493 1.22701C1.58389 1.45997 1.50261 1.7283 1.5 2.00261V15.9974Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const ControlPlay = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

ControlPlay.displayName = 'ControlPlay';

export default ControlPlay;
