// @flow

import React from 'react';

import Svg from 'components/svg';

const Bold = Svg((
  <path d="M7 19H13.0065C16.2647 19 18 17.285 18 15.0699C18 13.011 16.463 11.7676 14.8126 11.6929V11.557C16.3213 11.2308 17.4334 10.2048 17.4334 8.56726C17.4334 6.48126 15.8184 5 12.6169 5H7V11.9579V19ZM9.92247 16.7572L9.87574 12.814H12.4469C13.9202 12.814 14.8339 13.6294 14.8339 14.7777C14.8339 15.8241 14.1369 16.7572 12.4228 16.7572H9.92247ZM9.87574 10.5894V7.22517H12.2061C13.566 7.22517 14.3381 8.05086 14.3381 9.05648C14.3381 10.1572 13.4031 10.5894 12.1494 10.5894H9.87574Z" fill="currentColor" />
));

Bold.displayName = 'Bold';

export default Bold;
