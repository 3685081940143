import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_377_1614');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M5.51697 8.30517L6.18264 4.10584C5.9204 4.10584 5.47158 4.10584 5.47158 4.10584C5.13875 4.10584 4.7101 3.91909 4.58403 3.57588C4.54369 3.45979 4.44787 3.06106 4.99755 2.67242C5.19422 2.53614 5.3203 2.38472 5.34551 2.26864C5.37073 2.1475 5.34047 2.04151 5.25474 1.96075C5.13371 1.84467 4.89669 1.77905 4.59411 1.77905C4.08478 1.77905 3.72673 2.07179 3.69143 2.28378C3.66622 2.44024 3.78725 2.56643 3.89315 2.64718C4.21085 2.8844 4.2865 3.22762 4.08982 3.55064C3.8881 3.88376 3.44937 4.10079 2.98038 4.10584C2.98038 4.10584 2.51643 4.10584 2.2542 4.10584C2.19368 4.51467 1.20527 10.7834 1.12962 11.278H5.05302C5.08832 11.061 5.26987 9.87487 5.51697 8.30517Z" fill="white" />
        <path d="M0.917815 12.7317H2.52651C3.21235 12.7317 3.37876 13.0799 3.32833 13.3979C3.28799 13.6553 3.11149 13.8471 2.80891 13.9733C3.19218 14.1197 3.34346 14.3468 3.28799 14.7052C3.21739 15.1544 2.82908 15.4875 2.31975 15.4875H0.489166L0.917815 12.7317ZM1.98187 13.8724C2.29453 13.8724 2.44078 13.7058 2.47104 13.509C2.50129 13.297 2.40548 13.1506 2.09282 13.1506H1.81546L1.70451 13.8724H1.98187ZM1.81041 15.0585C2.13316 15.0585 2.31975 14.9272 2.36514 14.6597C2.40044 14.4276 2.26932 14.2913 1.95666 14.2913H1.644L1.52297 15.0635H1.81041V15.0585Z" fill="white" />
        <path d="M5.54217 15.5077C5.12361 15.5379 4.92189 15.4925 4.82103 15.3108C4.59915 15.4471 4.35204 15.5177 4.08981 15.5177C3.61577 15.5177 3.44936 15.2704 3.49474 14.9979C3.51492 14.8666 3.59056 14.7405 3.71159 14.6345C3.97382 14.4073 4.61932 14.3771 4.87146 14.2055C4.89164 14.0137 4.81599 13.943 4.57897 13.943C4.30161 13.943 4.06964 14.0338 3.67125 14.3064L3.76706 13.6805C4.10998 13.4332 4.44282 13.3171 4.82608 13.3171C5.31524 13.3171 5.74893 13.519 5.66825 14.054L5.57243 14.6597C5.53713 14.8717 5.54722 14.9373 5.78423 14.9424L5.54217 15.5077ZM4.81599 14.5537C4.5941 14.695 4.18058 14.6698 4.1352 14.9625C4.11502 15.0988 4.20075 15.1998 4.33691 15.1998C4.46803 15.1998 4.6294 15.1442 4.76052 15.0534C4.75043 15.0029 4.75548 14.9525 4.77061 14.8566L4.81599 14.5537Z" fill="white" />
        <path d="M6.32384 13.3525H7.16096L7.11558 13.6301C7.38285 13.4029 7.58457 13.3171 7.8468 13.3171C8.3158 13.3171 8.53264 13.6048 8.457 14.0742L8.24015 15.4824H7.40303L7.58457 14.3165C7.61987 14.1045 7.55431 14.0036 7.39294 14.0036C7.26182 14.0036 7.14079 14.0742 7.02481 14.2307L6.83317 15.4774H5.99605L6.32384 13.3525Z" fill="white" />
        <path d="M9.11258 12.7317H9.9497L9.7379 14.0844L10.5397 13.3525H11.5735L10.5448 14.261L11.3718 15.4824H10.3178L9.68243 14.4932H9.67234L9.52106 15.4774H8.68393L9.11258 12.7317Z" fill="white" />
        <path d="M12.0728 12.7317H13.036L12.6124 15.4824H11.6492L12.0728 12.7317Z" fill="white" />
        <path d="M13.505 12.7317H14.8817C15.9457 12.7317 16.2534 13.5039 16.1525 14.1449C16.0567 14.7708 15.5625 15.4824 14.6295 15.4824H13.0763L13.505 12.7317ZM14.3976 14.8263C14.8665 14.8263 15.1237 14.5941 15.1994 14.1045C15.2549 13.7411 15.1439 13.3828 14.6245 13.3828H14.3673L14.1454 14.8263H14.3976Z" fill="white" />
        <path d="M10.7919 0.436523H6.78273L6.24818 3.83333H6.92898C7.30216 3.83333 7.65516 3.66173 7.80645 3.41441C7.85688 3.33365 7.87705 3.26299 7.87705 3.19738C7.87705 3.05605 7.78123 2.95006 7.68542 2.8794C7.42319 2.68256 7.36771 2.47562 7.36771 2.32925C7.36771 2.29896 7.36772 2.27373 7.37276 2.24849C7.42823 1.89014 7.91235 1.5015 8.5528 1.5015C8.93607 1.5015 9.22855 1.59235 9.40506 1.75891C9.56139 1.90528 9.6219 2.11222 9.57652 2.32925C9.52104 2.58666 9.26386 2.79864 9.11761 2.90464C8.72931 3.17719 8.77973 3.41441 8.80495 3.48507C8.88564 3.69706 9.19325 3.83333 9.43027 3.83333H10.4691C10.4691 3.83333 10.4691 3.83333 10.4691 3.83838C11.8811 3.84847 12.6376 4.49957 12.4006 6.01375C12.1787 7.42194 11.0995 8.02761 9.81353 8.03771L9.3042 11.2881H10.0556C13.2276 11.2881 15.8197 9.24905 16.329 6.02889C16.9594 2.03146 14.4228 0.436523 10.7919 0.436523Z" fill="white" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </>
  );
};

const BankID = Svg(Fragment, { viewBox: '0 0 17 16', fill: 'none' });

BankID.displayName = 'BankID';

export default BankID;
