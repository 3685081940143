// @flow

import React from 'react';

import Svg from 'components/svg';

const ArrowRight = Svg((
  <>
    <path d="M12 8V4l8 8-8 8v-4H4V8z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </>
));

ArrowRight.displayName = 'ArrowRight';

export default ArrowRight;
