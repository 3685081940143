/* eslint-disable react/prop-types */

import React from 'react';
import Message from 'components/message';
import { localize } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import { OPTIONS_COMPANY_SIZE, OPTIONS_TOOLS } from '../constants';

const SignupCompanyDetails = ({ message }) => (
  <>
    <Field
      autoComplete="no"
      autoFocus
      name="companyName"
      component={TextField}
      label={(
        <Message
          id="Company name"
          comment="Used in required validation of the company name field on the signup company details page."
        />
      )}
      placeholder={
        message({
          id: 'Enter company name',
          comment: 'Company name placeholder on the complete company page',
        })
      }
      maxLength={100}
      responsive
      required
    />
    <Field
      name="companySize"
      component={SelectField}
      label={(
        <Message
          id="Company size"
          comment="Used in required validation of the company size field on the signup company details page."
        />
      )}
      placeholder={(
        <Message
          id="Number of employees"
          comment="Number of employees placeholder on the signup company details page."
        />
      )}
      options={OPTIONS_COMPANY_SIZE}
      clearable={false}
      searchable={false}
      required
    />
    <Field
      name="tools"
      component={SelectField}
      label={(
        <Message
          id="Do you use any of these systems or products?"
          comment="Used in required validation of the tools field on the signup company details page."
        />
      )}
      placeholder={(
        <Message
          id="Choose for example your CRM system"
          comment="Systems or products placeholder on the signup company details page."
        />
      )}
      options={OPTIONS_TOOLS}
      clearable={false}
      searchable={false}
      required
      multi
    />
  </>
);

export default localize(SignupCompanyDetails);
