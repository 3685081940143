// @flow

import React from 'react';

import Svg from 'components/svg';

const AddColumnBefore = Svg((
  <>
    <rect x="14" y="5" width="4" height="14" stroke="currentColor" strokeWidth="2" />
    <rect x="9" y="9" width="6" height="2" transform="rotate(90 9 9)" fill="currentColor" />
    <rect x="5" y="11" width="6" height="2" fill="currentColor" />
  </>
), {
  viewBox: '0 0 24 24',
  fill: 'none',
});

AddColumnBefore.displayName = 'AddColumnBefore';

export default AddColumnBefore;
