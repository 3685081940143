// @flow

import React from 'react';

import Svg from 'components/svg';

const AlignLeft = Svg((
  <>
    <path d="M4 6H20V8H4V6Z" fill="currentColor" />
    <path d="M4 11H13V13H4V11Z" fill="currentColor" />
    <path d="M4 16H17V18H4V16Z" fill="currentColor" />
  </>
));

AlignLeft.displayName = 'AlignLeft';

export default AlignLeft;
