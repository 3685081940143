import Svg from 'components/svg';

const Pen = Svg((
  <>
    <path d="M1.25214 7.45837L0.594727 10.108L3.22563 9.44547L7.02767 5.58024L5.05419 3.59314L1.25214 7.45837Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.47518 3.15332L10.1326 3.81527C10.219 3.90226 10.2876 4.00555 10.3344 4.11924C10.3812 4.23293 10.4053 4.35478 10.4053 4.47785C10.4053 4.60091 10.3812 4.72277 10.3344 4.83645C10.2876 4.95014 10.219 5.05343 10.1326 5.14042L8.0791 7.20746" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.17271 5.47141L9.47552 3.15333C9.64993 2.97767 9.7479 2.73945 9.7479 2.49107C9.7479 2.24268 9.64993 2.00446 9.47552 1.8288L8.81748 1.16623C8.64303 0.990619 8.40644 0.891968 8.15976 0.891968C7.91308 0.891968 7.67649 0.990619 7.50203 1.16623L5.19922 3.48368" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </>
), { viewBox: '0 0 11 11', fill: 'none' });

Pen.displayName = 'Pen';

export default Pen;
