// @flow

import {
  get,
  post,
  remove,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

type GetPositionMfa = ({
  id: number,
}) => Promise<PositionMfa>;

type CreatePositionMfa = ({
  positionId: number,
  channel: MfaChannelType,
  channelData: string,
  password: string,
}) => Promise<PositionMfa>;

type RequestPositionMfaCode = ({
 id: number,
}) => Promise<PositionMfa>;

type ValidatePositionMfaDevice = ({
  id: number,
  securityCode: string,
}) => Promise<PositionMfa>;

type RemovePositionMfa = ({
  id: number,
  password: string,
}) => Promise<PositionMfa>;

export const getPositionMfa: GetPositionMfa = ({
  id,
}) => (
  get({
    url: `/positions/${id}/mfa/`,
  })
    .then(extractResponseBodyAsJSON)
);

export const requestPositionMfaCode: RequestPositionMfaCode = ({
  id,
}) => (
  post({
    url: `/positions/${id}/mfa/code_request`,
  })
    .then(extractResponseBodyAsJSON)
);

export const requestPositionMfaLoginCode: RequestPositionMfaCode = ({
  id,
}) => (
  post({
    url: '/positions/login_mfa_code_request',
    body: {
      position_id: id,
    },
  }).then(extractResponseBodyAsJSON)
);

export const validateChannel: ValidatePositionMfaDevice = ({
  id,
  securityCode,
}) => (
  post({
    url: `/positions/${id}/mfa/channel_validate`,
    body: {
      security_code: securityCode,
    },
  })
    .then(extractResponseBodyAsJSON)
);

export const createPositionMfa: CreatePositionMfa = ({
  positionId,
  channel,
  channelData,
  password,
}) => (
  post({
    url: `/positions/${positionId}/mfa/`,
    body: {
      positionId,
      channel,
      channel_data: channelData,
      password,
    },
  })
    .then(extractResponseBodyAsJSON)
);

export const removePositionMfa: RemovePositionMfa = ({ id, password }) => (
  remove({
    url: `/positions/${id}/mfa/`,
    body: {
      password,
    },
  })
    .then(extractResponseBodyAsJSON)
);
