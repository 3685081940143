// @flow

import React from 'react';

import Svg from 'components/svg';

const NumberedList = Svg((
  <>
    <path d="M9 6H20V8H9V6Z" fill="currentColor" />
    <path d="M9 11H20V13H9V11Z" fill="currentColor" />
    <path d="M9 16H20V18H9V16Z" fill="currentColor" />
    <path d="M6.05543 3.99536V9.99998H4.39621V6.06537C4.12772 6.26941 3.8673 6.43453 3.61492 6.56072C3.36523 6.6869 3.05111 6.80772 2.67255 6.92317V5.57807C3.23099 5.39819 3.66459 5.18206 3.97335 4.92968C4.2821 4.67731 4.52374 4.36587 4.69825 3.99536H6.05543Z" fill="currentColor" />
    <path d="M7.13876 19.3156H2.21747C2.27385 18.8296 2.44434 18.3732 2.72893 17.9463C3.01621 17.5168 3.55317 17.0107 4.33983 16.4281C4.82041 16.071 5.12782 15.7998 5.26207 15.6146C5.39631 15.4293 5.46343 15.2534 5.46343 15.087C5.46343 14.9071 5.39631 14.7541 5.26207 14.6279C5.13051 14.499 4.96405 14.4346 4.76269 14.4346C4.55327 14.4346 4.38144 14.5004 4.2472 14.6319C4.11564 14.7635 4.02704 14.9957 3.9814 15.3286L2.33829 15.1957C2.40272 14.7339 2.52085 14.3742 2.69268 14.1164C2.86451 13.856 3.10615 13.6573 3.41759 13.5204C3.73171 13.3808 4.16531 13.311 4.71839 13.311C5.29563 13.311 5.74399 13.3768 6.06349 13.5083C6.38567 13.6399 6.63804 13.8426 6.82061 14.1164C7.00586 14.3876 7.09849 14.6923 7.09849 15.0306C7.09849 15.3904 6.99244 15.734 6.78034 16.0616C6.57092 16.3891 6.18833 16.7489 5.63257 17.1409C5.30234 17.3691 5.08084 17.5288 4.96808 17.6201C4.858 17.7114 4.72778 17.8309 4.57743 17.9786H7.13876V19.3156Z" fill="currentColor" />
  </>
));

NumberedList.displayName = 'NumberedList';

export default NumberedList;
