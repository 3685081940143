import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  pickBy, isEqual,
} from 'lodash';

import {
  getDataFieldExternalKeyValueMap,
  getDataFieldExternalKeyMap,
  getCollapsedBoxIds,
  updateCollapsedBoxIds,
  getCurrentBoxes,
} from 'reducers/current-contract';
import { isAgreementLayoutEditable } from 'agreement/agreement-permissions';

import {
  getHasBrokenSectionRule,
  getSectionRule,
  getVisibility,
} from 'components/contract-boxes/box-wrapper/helpers';
import AdjusterIcon from 'components/icons/adjuster';
import Message from 'components/message';
import { MenuItem } from 'components/menu-item';

export const getHiddenSections = (
  boxes: {[key: string]: ContractView.Box },
  dataFieldExternalKeyValueMap: Record<string, DataFieldValue['value']>,
  dataFieldExternalKeyMap: any,
) => pickBy(boxes, (box) => {
  const rule = getSectionRule(box);
  const isSectionVisible = getVisibility(rule, dataFieldExternalKeyValueMap);
  const isSectionBroken = getHasBrokenSectionRule(rule, dataFieldExternalKeyMap);
  const isSectionHidden = !isSectionVisible && !isSectionBroken;

  return isSectionHidden;
});

type Props = {
  agreement: Agreement,
};

const CollapseAllHiddenSections = ({
  agreement,
}: Props) => {
  const dispatch = useDispatch();
  const collapsedBoxIds = useSelector(getCollapsedBoxIds);
  const boxes = useSelector(getCurrentBoxes);
  const dataFieldExternalKeyMap = useSelector(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = useSelector(getDataFieldExternalKeyValueMap);

  const hiddenSectionIds = useMemo(() => (Object.keys(
    getHiddenSections(boxes, dataFieldExternalKeyValueMap, dataFieldExternalKeyMap),
  ).map((key) => Number(key))), [
    boxes,
    dataFieldExternalKeyValueMap,
    dataFieldExternalKeyMap,
  ]);

  const isAtLeastOneSectionExpanded = !isEqual(hiddenSectionIds, collapsedBoxIds);

  const handleClick = useCallback(() => {
    if (isAtLeastOneSectionExpanded) {
      dispatch(updateCollapsedBoxIds(
        (hiddenSectionIds),
      ));
    } else {
      dispatch(updateCollapsedBoxIds([]));
    }
  }, [
    hiddenSectionIds,
    isAtLeastOneSectionExpanded,
    dispatch,
  ]);

  return (
    <MenuItem
      disabled={!isAgreementLayoutEditable(agreement)}
      label={isAtLeastOneSectionExpanded ? (
        <Message
          id="Collapse all hidden sections"
          comment="Text for button to expand all hidden sections"
        />
      ) : (
        <Message
          id="Expand all hidden sections"
          comment="Text for button to expand all hidden sections"
        />
      )}
      icon={AdjusterIcon}
      onClick={handleClick}
      buttonId="expand-all-hidden-sections"
    />
  );
};

export default CollapseAllHiddenSections;
