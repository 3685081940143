// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_278_2992');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M12.3724 2.62509C12.4265 2.6796 12.4693 2.74424 12.4983 2.81531C12.5273 2.88639 12.5421 2.96249 12.5416 3.03926V13.1251C12.5416 13.2798 12.4801 13.4282 12.3707 13.5376C12.2614 13.647 12.113 13.7084 11.9583 13.7084H2.04159C1.88687 13.7084 1.7385 13.647 1.6291 13.5376C1.51971 13.4282 1.45825 13.2798 1.45825 13.1251V0.875093C1.45825 0.720381 1.51971 0.572008 1.6291 0.462612C1.7385 0.353216 1.88687 0.291758 2.04159 0.291758H9.79409C9.87085 0.291314 9.94698 0.306028 10.018 0.335057C10.0891 0.364087 10.1538 0.40686 10.2083 0.460924L12.3724 2.62509Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.41671 2.39758C6.43153 2.51881 6.4205 2.64181 6.38434 2.75845C6.34811 2.87509 6.28756 2.98273 6.20671 3.07425C6.12353 3.16667 6.02191 3.24057 5.90834 3.29116C5.79478 3.34175 5.67185 3.36789 5.54753 3.36789C5.42321 3.36789 5.30028 3.34175 5.18672 3.29116C5.07316 3.24057 4.97151 3.16667 4.88837 3.07425C4.80542 2.98371 4.74274 2.87652 4.70452 2.75983C4.6663 2.64315 4.6534 2.51965 4.6667 2.39758L4.88253 0.291748H6.12504L6.41671 2.39758Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.54232 4.81242C5.46178 4.81242 5.39648 4.74713 5.39648 4.66659C5.39648 4.58604 5.46178 4.52075 5.54232 4.52075" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54224 4.81242C5.62278 4.81242 5.68807 4.74713 5.68807 4.66659C5.68807 4.58604 5.62278 4.52075 5.54224 4.52075" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54232 6.27091C5.46178 6.27091 5.39648 6.20564 5.39648 6.12508C5.39648 6.04452 5.46178 5.97925 5.54232 5.97925" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54224 6.27091C5.62278 6.27091 5.68807 6.20564 5.68807 6.12508C5.68807 6.04452 5.62278 5.97925 5.54224 5.97925" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54232 7.72917C5.46178 7.72917 5.39648 7.66389 5.39648 7.58333C5.39648 7.50277 5.46178 7.4375 5.54232 7.4375" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54224 7.72917C5.62278 7.72917 5.68807 7.66389 5.68807 7.58333C5.68807 7.50277 5.62278 7.4375 5.54224 7.4375" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54232 9.18742C5.46178 9.18742 5.39648 9.12214 5.39648 9.04159C5.39648 8.96103 5.46178 8.89575 5.54232 8.89575" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54224 9.18717C5.62278 9.18717 5.68807 9.1219 5.68807 9.04134C5.68807 8.96078 5.62278 8.89551 5.54224 8.89551" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54232 10.6459C5.46178 10.6459 5.39648 10.5806 5.39648 10.5001C5.39648 10.4195 5.46178 10.3542 5.54232 10.3542" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54224 10.6462C5.62278 10.6462 5.68807 10.5809 5.68807 10.5003C5.68807 10.4198 5.62278 10.3545 5.54224 10.3545" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54232 12.1042C5.46178 12.1042 5.39648 12.0389 5.39648 11.9583C5.39648 11.8778 5.46178 11.8125 5.54232 11.8125" stroke="currentColor" strokeWidth="0.583333" />
        <path d="M5.54224 12.1042C5.62278 12.1042 5.68807 12.0389 5.68807 11.9583C5.68807 11.8778 5.62278 11.8125 5.54224 11.8125" stroke="currentColor" strokeWidth="0.583333" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const ZipIcon = Svg(Fragment, { viewBox: '0 0 14 14', fill: 'none' });

ZipIcon.displayName = 'ZipIcon';

export default ZipIcon;
