import { Reducer } from 'redux';

/**
 * improvement for a later point: replace any with proper generic type
 * It doesn't really matter ATM since we don't use the function directly
 */
const createReducer = <State extends Record<string, any>, HandlerKey extends string>(
  initialState: State,
  handlers: Record<HandlerKey, any>,
): Reducer<State, any> => (state = initialState, action: any) => {
    if (action && handlers[action.type as HandlerKey]) {
      return handlers[action.type as HandlerKey](state, action);
    }

    return state;
  };

export default createReducer;
