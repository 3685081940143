// @flow

import React from 'react';

import Svg from 'components/svg';

const Renew = Svg((
  <>
    <path fill="none" d="M.75.5h10.5v10H.75z" />
    <path d="M8.615 6.834L9.923 6.5l.327 1.334" fill="currentColor" />
    <path d="M8.615 6.834L9.923 6.5l.327 1.334" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" fill="none" />
    <path d="M10.272 6.663a.5.5 0 10-.966-.257l.966.257zM6 9.5l-.002.5H6L6 9.5zM2.787 6.697l-.17-.47-.94.338.169.47.94-.338zm6.519-.291a3.52 3.52 0 01-1.229 1.87l.617.787a4.52 4.52 0 001.578-2.4l-.966-.257zm-1.229 1.87A3.39 3.39 0 015.998 9l.003 1a4.39 4.39 0 002.693-.937l-.617-.787zM6.002 9a3.387 3.387 0 01-1.958-.635l-.582.814c.742.53 1.627.818 2.536.821l.004-1zm-1.958-.635a3.507 3.507 0 01-1.257-1.668l-.941.339c.31.864.874 1.612 1.616 2.143l.582-.814z" fill="currentColor" />
    <path d="M3.385 4.167L2.077 4.5 1.75 3.167" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" fill="none" />
    <path d="M1.728 4.337a.5.5 0 00.966.257l-.966-.257zM6 1.5l.002-.5h-.004L6 1.5zm3.213 2.803l.169.47.941-.338-.17-.47-.94.338zm-6.519.291a3.52 3.52 0 011.228-1.87l-.616-.787a4.52 4.52 0 00-1.578 2.4l.966.257zm1.228-1.87A3.39 3.39 0 016.002 2l-.004-1a4.39 4.39 0 00-2.692.937l.616.787zM5.998 2c.7.003 1.383.224 1.958.635l.582-.814A4.387 4.387 0 006.002 1l-.004 1zm1.958.635a3.507 3.507 0 011.257 1.668l.94-.339a4.507 4.507 0 00-1.615-2.143l-.582.814z" fill="currentColor" />
  </>
), { viewBox: '0 0 12 11' });

Renew.displayName = 'Renew';

export default Renew;
