// @flow

import React from 'react';

import Svg from 'components/svg';

const Dash = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.350098 8.34998H17.6501V9.64998H0.350098V8.34998Z"
    fill="currentColor"
  />
), { viewBox: '0 0 18 18', fill: 'none' });

Dash.displayName = 'Dash';

export default Dash;
