import Svg from 'components/svg';

const DocumentTemplates = Svg((
  <>
    <path d="M17.2491 17.2491H32.2509V32.2509H17.2491V17.2491Z" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M17.2491 22.2531H32.2509" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M39.7491 17.2491H54.7509V32.2509H39.7491V17.2491Z" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M39.7491 22.2531H54.7509" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M17.2491 39.7491H32.2509V54.7509H17.2491V39.7491Z" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M17.2491 44.7429H32.2509" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M39.7491 39.7491H54.7509V54.7509H39.7491V39.7491Z" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M39.7491 44.7429H54.7509" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
  </>
), { viewBox: '0 0 70 70', fill: 'none' });

DocumentTemplates.displayName = 'DocumentTemplates';

export default DocumentTemplates;
