import Svg from 'components/svg';

const SpinnerComponent = Svg((
  <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(278.824 50 50)">
    <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.7s" begin="0s" repeatCount="indefinite" />
  </circle>
), { viewBox: '0 0 100 100' });

const CircularSpinner = (props: React.ComponentPropsWithoutRef<typeof SpinnerComponent>) => (
  <SpinnerComponent
    height="24px"
    {...props}
  />
);

CircularSpinner.displayName = 'CircularSpinner';

export default CircularSpinner;
