// @flow

import * as React from 'react';
import isFunction from 'lodash/isFunction';

type Props = {
  input: Input,
};

type State = {
  value?: any,
};

type WrappedProps = Props & any;

type WrappedInput = Class<React.Component<WrappedProps>> | (WrappedProps) => React.Node;

const inputControl = (InputComponent: WrappedInput) => (
  class InputControl extends React.PureComponent<Props, State> {
    constructor(props: Props) {
      super(props);

      if (!this.isControlled()) {
        this.state = {
          value: '',
        };
      }
    }

    componentDidUpdate(prevProps: Props) {
      if (this.changedFromControlledToUncontrolled(prevProps)) {
        const { input = {} } = prevProps;
        this.setValue(input.value);
      }

      if (this.changedFromUncontrolledToControlled(prevProps)) {
        this.setValue(undefined);
      }
    }

    onChange: (SyntheticInputEvent<*> | any) => void = (event) => {
      const { input = {} } = this.props;

      this.setValue(event.target.value);

      if (isFunction(input.onChange)) {
        input.onChange(event);
      }
    }

    setValue(value: any) {
      this.setState({
        value,
      });
    }

    getValue() {
      if (!this.state) {
        return '';
      }

      return this.state.value;
    }

    getInputProps() {
      const { input = {} } = this.props;

      if (this.isControlled()) {
        return input;
      }

      return {
        ...input,
        value: this.getValue(),
        onChange: this.onChange,
      };
    }

    changedFromUncontrolledToControlled({ input = {} }: Props) {
      return this.isControlled() && input.value === undefined;
    }

    changedFromControlledToUncontrolled({ input = {} }: Props) {
      return !this.isControlled() && input.value !== undefined;
    }

    isControlled() {
      const { input = {} } = this.props;

      return input.value !== undefined;
    }

    render() {
      const { input, ...restProps } = this.props;

      return (
        <InputComponent
          input={this.getInputProps()}
          {...restProps}
          defaultValue={undefined}
        />
      );
    }
  }
);

export default inputControl;
