// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_1500_38473');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M17.357 7.7142C17.357 7.20271 17.1538 6.71217 16.7921 6.35049C16.4304 5.98881 15.9399 5.78562 15.4284 5.78562H8.99984L7.14842 1.92848H2.57127C2.05978 1.92848 1.56924 2.13167 1.20757 2.49335C0.845888 2.85502 0.6427 3.34556 0.6427 3.85705V14.1428C0.6427 14.6543 0.845888 15.1448 1.20757 15.5065C1.56924 15.8682 2.05978 16.0713 2.57127 16.0713H15.4284C15.9399 16.0713 16.4304 15.8682 16.7921 15.5065C17.1538 15.1448 17.357 14.6543 17.357 14.1428V7.7142Z"
          fill="#8EC4E3"
          stroke="#509DD1"
          strokeWidth="1.0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="18"
            height="18"
            fill="white"
          />
        </clipPath>
      </defs>
    </>
  );
};

const FolderIcon = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

FolderIcon.displayName = 'FolderIcon';

export default FolderIcon;
