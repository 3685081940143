// @flow

import React from 'react';
import type { RouterHistory, Location, Match } from 'react-router';

import Redirect from 'components/redirect';
import log from 'logging';

type Props = AdminPageComponentProps & {
  history?: RouterHistory,
  location?: Location,
  match?: Match,
  redirect?: boolean,
  to?: string,
};

class NotFound extends React.Component<Props> {
  static defaultProps = {
    history: undefined,
    location: undefined,
    match: undefined,
    redirect: false,
    to: undefined,
  }

  componentDidMount() {
    const {
      history,
      location,
      match,
      redirect,
      to,
    } = this.props;

    log.warning('Route not found', {
      history,
      location,
      match,
      redirect,
      to,
    });

    if (!this.props.redirect) {
      window.location.reload(true);
    }
  }

  render() {
    const { redirect, to } = this.props;

    if (redirect && to) {
      return <Redirect to={to} />;
    }

    return null;
  }
}

export default NotFound;
