import { ReactNode } from 'react';
import style from './block-content.module.scss';

type Props = {
  children: ReactNode,
}

const Content = ({ children }: Props) => (
  <div className={style.Content}>
    {children}
  </div>
);

export default Content;
