// @flow

import React from 'react';

import Svg from 'components/svg';

const Cross = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.50005 0.580811L9.00005 8.08081L16.5 0.580811L17.4193 1.50005L9.91929 9.00005L17.4193 16.5L16.5 17.4193L9.00005 9.91929L1.50005 17.4193L0.580811 16.5L8.08081 9.00005L0.580811 1.50005L1.50005 0.580811Z"
    fill="currentColor"
  />

), { viewBox: '0 0 18 18', fill: 'none' });

Cross.displayName = 'Cross';

export default Cross;
