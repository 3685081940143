// @flow

import { put } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga';
import generateEntitySagas from 'normalized-redux/sagas';
import {
  getPositionMfa,
  createPositionMfa,
  removePositionMfa,
  requestPositionMfaCode,
  requestPositionMfaLoginCode,
  validateChannel,
} from 'oneflow-client/position-mfa';

import apiWrapper from 'sagas/api-wrapper';

import positionMfas from 'reducers/entities/position-mfa';

type MapperArgs = {
  data: PositionMfa,
  action: any,
};
type Mapper = Generator<PutEffect<any, null>, void, any>;

export function* mapper({ action, data }: MapperArgs): Mapper {
  yield put(positionMfas.setPositionMfas({
    [action.id]: {
      channel: null,
      channelData: null,
      channelStatus: null,
      enabled: false,
      hasCode: false,
      ...data,
    },
  }));
}

export function* createMapper({ action, data }: MapperArgs): Mapper {
  yield put(positionMfas.setPositionMfas({
    [action.data.positionId]: data,
  }));
}

const mappers = {
  fetch: {
    mapper,
    request: getPositionMfa,
  },
  create: {
    mapper: createMapper,
    request: createPositionMfa,
  },
  remove: {
    mapper,
    request: removePositionMfa,
  },
  rpcs: {
    requestPositionMfaCode: {
      name: 'requestPositionMfaCode',
      request: requestPositionMfaCode,
    },
    requestPositionMfaLoginCode: {
      name: 'requestPositionMfaLoginCode',
      request: requestPositionMfaLoginCode,
    },
    validateChannel: {
      name: 'validateChannel',
      request: validateChannel,
    },
  },
};
const positionMfasSagas = generateEntitySagas({
  apiWrapper,
  normalizedEntity: positionMfas,
  mappers,
});

export default positionMfasSagas;
