// @flow

import React from 'react';

import Svg from 'components/svg';

const UploadFailedIcon = Svg((
  <>
    <path
      d="M6.22462 27.4127C4.9017 27.4127 2.04508 27.4127 1 27.4127C1.00026 22.3333 1.00007 6.13227 1.00013 1.00011C4.13472 0.999929 12.6294 1.00003 15.6451 1.00003C15.8831 1.00007 16.1187 1.04572 16.3386 1.13434C16.5584 1.22297 16.7581 1.35284 16.9262 1.51654L24.0151 8.40849C24.1833 8.57208 24.3166 8.76628 24.4076 8.97998C24.4985 9.19369 24.5453 9.42272 24.5452 9.654V14.2064"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
    />
    <path
      d="M15.6235 1.13098L15.6235 8.42592C15.6235 8.89292 15.6232 10.1868 15.6232 10.1868C15.6232 10.1868 15.9196 10.1868 16.4 10.1868H23.9033"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
    />
    <path
      d="M15.6286 28.4286L15.6286 14.2064M15.6286 14.2064L20.8531 19.1841M15.6286 14.2064L10.4041 19.1841"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M34.8337 31.001L29.9702 21.4541C29.9633 21.4406 29.9565 21.4271 29.9496 21.4137C29.7727 21.0661 29.6081 20.743 29.4444 20.5122C29.2761 20.2752 28.9814 19.9515 28.5 19.9515C28.0186 19.9515 27.7239 20.2752 27.5556 20.5122C27.3919 20.743 27.2273 21.0661 27.0504 21.4137C27.0435 21.4271 27.0367 21.4406 27.0298 21.4541L22.1663 31.001C22.1599 31.0136 22.1535 31.0262 22.1471 31.0388C21.998 31.3313 21.8546 31.6125 21.7745 31.8515C21.6879 32.1094 21.6162 32.4855 21.8402 32.8509C22.0641 33.2164 22.4317 33.3231 22.7008 33.3631C22.9502 33.4001 23.2658 33.4001 23.5941 33.4C23.6082 33.4 23.6224 33.4 23.6365 33.4H33.3635C33.3776 33.4 33.3918 33.4 33.4059 33.4C33.7342 33.4001 34.0498 33.4001 34.2992 33.3631C34.5683 33.3231 34.9359 33.2164 35.1598 32.8509C35.3838 32.4855 35.3121 32.1094 35.2255 31.8515C35.1454 31.6125 35.0021 31.3313 34.8529 31.0388L34.8337 31.001Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <line x1="28.5" y1="23.6" x2="28.5" y2="29.6" stroke="currentColor" strokeWidth="1.2" />
    <circle cx="28.5" cy="31.1" r="0.7" fill="currentColor" />
  </>
), {
  viewBox: '0 0 36 35',
  fill: 'none',
});

UploadFailedIcon.displayName = 'UploadFailedIcon';

export default UploadFailedIcon;
