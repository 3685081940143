import {
  find,
  omit,
  remove,
} from 'lodash';

import { getAPIPath } from 'oneflow-client/core';

import { PREVIEW } from 'hooks/use-is-in-preview-mode';
import { getDevicePixelRelativeRatio, isDevicePixelRatioUnreliable } from 'utils/device-settings';

export const getId = (item) => item?.id || item?._id;

export const isSavedData = (data) => {
  if (!data) {
    return false;
  }

  return Boolean(data.id && data._id);
};

export const isUnsavedData = (data) => {
  if (!data) {
    return true;
  }

  return Boolean(!data.id && data._id);
};

export const getCurrentBox = (boxes, boxId) => (
  boxes[boxId] || find(boxes, ({ _id }) => _id === boxId)
);

export const getPreviousContentData = (contentData) => (
  contentData.map((d) => {
    if (d.key === 'text') {
      return d;
    }

    let oldFile = {
      ...d,
      _removed: true,
    };

    if (isSavedData(oldFile)) {
      oldFile = omit(oldFile, ['_id']);
    }

    if (isUnsavedData(oldFile)) {
      return null;
    }

    return oldFile;
  }).filter(Boolean)
);

export const removeContentDataItems = (box, dataIds) => {
  const newData = [...box.content.data];

  dataIds.forEach((dataId) => {
    // Remove data with _id
    remove(newData, (data) => data._id === dataId);

    // Add _removed to data with id
    const dataIndex = newData.findIndex((data) => data.id === dataId);
    if (dataIndex > -1) {
      newData[dataIndex] = {
        ...newData[dataIndex],
        _removed: true,
      };
    }
  });

  return newData;
};

export const generateDocumentOpenURL = (
  contractId, // number
  value, // AgreementData.Value
  guestToken, // string
) => {
  if (!value || !value.assetIdentifier) {
    return '';
  }
  const assetsRoot = getAPIPath(`/agreements/${contractId}/assets/`);
  const link = `${assetsRoot}${value.assetIdentifier}`;

  if (guestToken && guestToken !== PREVIEW) {
    return `${link}?at=${guestToken}`;
  }

  return link;
};

// Allow to adjust required minWidth by minWidthToleranceDeltaForZoom
// When browser zoom is not 100% and battles browser inconsistencies
export const getRequiredExpandedViewMinWidth = (
  availableContentSize,
  minWidth,
  {
    minWidthToleranceDeltaForZoom = 0,
  } = {},
) => {
  const isPixelRatioUnreliable = isDevicePixelRatioUnreliable();

  const devicePixelRelativeRatio = getDevicePixelRelativeRatio();
  if (devicePixelRelativeRatio === 1 && !isPixelRatioUnreliable) {
    return minWidth;
  }

  if (isPixelRatioUnreliable) {
    return minWidth - minWidthToleranceDeltaForZoom;
  }

  const scaledWidth = availableContentSize * devicePixelRelativeRatio;

  if (scaledWidth <= minWidth) {
    return minWidth;
  }

  return minWidth - minWidthToleranceDeltaForZoom;
};

export const fileToJson = (file) => ({
  name: file.name,
  size: file.size,
  type: file.type,
  lastModified: file.lastModified,
  lastModifiedDate: file.lastModifiedDate,
  path: file.path,
});
