// @flow

import React from 'react';

import Svg from 'components/svg';

const Undo = Svg((
  <path d="M14.7445 8.15622H7.34853L10.2911 5.54553L8.85083 4L3 9.18797L8.85083 14.378L10.2911 12.8325L7.36993 10.2448H14.7445C15.6085 10.3045 16.4173 10.6817 17.0077 11.3002C17.5982 11.9187 17.9265 12.7327 17.9265 13.5781C17.9265 14.4235 17.5982 15.2375 17.0077 15.856C16.4173 16.4746 15.6085 16.8517 14.7445 16.9114H11.9624V19H14.7445C16.1645 18.923 17.5005 18.3183 18.4779 17.3099C19.4553 16.3015 20 14.9662 20 13.5781C20 12.19 19.4553 10.8547 18.4779 9.84632C17.5005 8.83797 16.1645 8.23319 14.7445 8.15622Z" fill="currentColor" />
));

Undo.displayName = 'Undo';

export default Undo;
