// @flow

import React from 'react';

import Svg from 'components/svg';

const Sigma = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.7639 0.349976C3.76395 0.349976 3.76385 0.349976 3.7639 0.349976L21.6501 0.349976V1.64998H3.76362C3.7356 1.64996 3.70991 1.65805 3.69051 1.6705C3.67135 1.68278 3.66045 1.69757 3.65511 1.70995C3.64991 1.72199 3.649 1.73364 3.65126 1.74458C3.65356 1.75571 3.65994 1.76961 3.67387 1.78304C3.6739 1.78307 3.67384 1.78302 3.67387 1.78304L12.7391 10.4939C13.1498 10.8886 13.3856 11.4297 13.3856 11.9995C13.3856 12.5693 13.1499 13.1103 12.7392 13.505L3.67457 22.2153C3.67446 22.2154 3.67468 22.2151 3.67457 22.2153C3.66072 22.2287 3.65401 22.243 3.65169 22.2542C3.64941 22.2654 3.65034 22.2772 3.6556 22.2894C3.66101 22.3019 3.672 22.3168 3.69127 22.3292C3.71079 22.3418 3.73633 22.3499 3.76451 22.35H21.6501V23.65H3.76362C3.48995 23.6496 3.21964 23.5715 2.98828 23.4227C2.75667 23.2739 2.57211 23.0597 2.46193 22.8043C2.35161 22.5486 2.32211 22.2656 2.37838 21.9922C2.43461 21.719 2.57307 21.4715 2.77201 21.2796L2.77293 21.2788L11.8383 12.5678C11.8383 12.5678 11.8383 12.5678 11.8383 12.5678C11.9999 12.4125 12.0856 12.2075 12.0856 11.9995C12.0856 11.7915 11.9999 11.5866 11.8384 11.4312C11.8384 11.4312 11.8384 11.4313 11.8384 11.4312L2.77293 2.72022C2.5736 2.52845 2.43451 2.2807 2.37809 2.00734C2.32163 1.73376 2.35112 1.45055 2.46159 1.19467C2.57191 0.939127 2.75676 0.724969 2.98868 0.576226C3.2203 0.427673 3.49011 0.349886 3.7639 0.349976Z"
    fill="currentColor"
  />
), { viewBox: '0 0 24 24', fill: 'none' });

Sigma.displayName = 'Sigma';

export default Sigma;
