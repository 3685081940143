import normalizeEntity from 'normalized-redux/entity-normalizer';

const normalizedPositionMfa = normalizeEntity({
  entity: 'positionMfa',
  rpcs: [
    'requestPositionMfaCode',
    'requestPositionMfaLoginCode',
    'validateChannel',
  ],
});

export default normalizedPositionMfa;
