// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837:19938');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.1501 -0.149902V0.850098H6.8501V-0.149902H8.1501V0.850098H10.1501V10.1501H-0.149902V0.850098H1.8501V-0.149902H3.1501ZM1.8501 2.1501H1.1501V3.8501H8.8501V2.1501H8.1501V3.1501H6.8501V2.1501H3.1501V3.1501H1.8501V2.1501ZM8.8501 5.1501H1.1501V8.8501H8.8501V5.1501Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const CalendarSimple = Svg(Fragment, { viewBox: '0 0 10 10', fill: 'none' });

CalendarSimple.displayName = 'CalendarSimple';

export default CalendarSimple;
