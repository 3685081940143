// @flow

import * as React from 'react';

import NewCross from 'components/icons/new-cross';

type InnerProps = {
  [any]: any
}

type Props = {
  innerProps: InnerProps,
}

const MultiValueRemove = (props: Props) => {
  const {
    innerProps: { ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
    >
      <NewCross height="8px" />
    </div>
  );
};

export default MultiValueRemove;
