// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19903');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M9 14.75V17.5M9.43711 12.5243C9.29211 12.5414 9.14613 12.55 9 12.55C8.07174 12.55 7.1815 12.2023 6.52513 11.5835C5.86875 10.9646 5.5 10.1252 5.5 9.25V8.7M5.93867 2.69913C6.2417 2.18411 6.68539 1.75494 7.22371 1.45616C7.76202 1.15737 8.37534 0.999874 9 1C9.92826 1 10.8185 1.34768 11.4749 1.96655C12.1313 2.58542 12.5 3.42479 12.5 4.3V9.25"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M11.4329 14.2668C10.6667 14.5865 9.83789 14.7512 9 14.75C7.48698 14.7758 6.02453 14.2364 4.93172 13.2495C3.8389 12.2625 3.20441 10.9081 3.16667 9.48177V7.79416M14.8333 7.79416V9.48177C14.8337 10.1667 14.6854 10.8445 14.3978 11.4735M2 2.10004L16 15.85"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const MicrophoneOff = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

MicrophoneOff.displayName = 'MicrophoneOff';

export default MicrophoneOff;
