import Svg from 'components/svg';

const LiveEditing = Svg((
  <>
    <path d="M32.8783 36.5503L16.38 53.0486" stroke="white" strokeWidth="1.74857" />
    <path d="M26.0023 17.1823H21.0034" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M23.5029 14.6777V19.6817" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M52.2514 47.1806H47.2526" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M49.752 44.6811V49.68" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M41.004 18.432V14.6777" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M54.7509 29.6794H51.0017" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M33.2537 36.18L25.6937 34.4109L32.3383 30.3891L31.6851 22.6491L37.5583 27.7251L44.7171 24.7166L41.7086 31.8703L46.7846 37.7486L39.0446 37.0954L35.0229 43.74L33.2537 36.18Z" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />

  </>
), { viewBox: '0 0 70 70', fill: 'none' });

LiveEditing.displayName = 'LiveEditing';

export default LiveEditing;
