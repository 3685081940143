import { ComponentProps, forwardRef } from 'react';
import Button from './button';

type Props = ComponentProps<typeof Button>;

const ButtonFC = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <Button {...props} triggerRef={ref} />
));

ButtonFC.displayName = 'FunctionalButton';

export default ButtonFC;
