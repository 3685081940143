// @flow

import { connect } from 'react-redux';
import { Switch } from 'react-router';

import { getLocationSelector } from 'reducers/router';

export const mapStateToProps = (state: State) => {
  const location = getLocationSelector(state);

  return {
    location,
  };
};

export default connect(mapStateToProps)(Switch);
