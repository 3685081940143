// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_20037');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8501 13.85H24.1501V24.15H13.8501V13.85ZM15.1501 15.15V22.85H22.8501V15.15H15.1501Z"
          fill="currentColor"
        />
        <path
          d="M17.4627 16.8843C17.5385 16.8843 17.6126 16.9068 17.6757 16.9489C17.7387 16.991 17.7878 17.0509 17.8169 17.1209C17.8459 17.191 17.8535 17.268 17.8387 17.3424C17.8239 17.4167 17.7874 17.485 17.7338 17.5386C17.6802 17.5922 17.6119 17.6287 17.5375 17.6435C17.4631 17.6583 17.3861 17.6507 17.316 17.6217C17.246 17.5927 17.1861 17.5436 17.144 17.4805C17.1019 17.4175 17.0794 17.3434 17.0794 17.2676C17.0794 17.166 17.1198 17.0685 17.1916 16.9966C17.2635 16.9247 17.361 16.8843 17.4627 16.8843ZM4.49995 17.5H11.5H4.49995Z"
          fill="currentColor"
        />
        <path
          d="M17.3646 23.5L19.8179 19.2074C19.8826 19.0938 19.9752 18.9986 20.0871 18.931C20.199 18.8633 20.3263 18.8254 20.457 18.8209C20.5877 18.8164 20.7173 18.8454 20.8336 18.9052C20.9499 18.965 21.0489 19.0536 21.1212 19.1625L23.2444 22.7294M11.5 23.4959C9.18105 23.4959 2.99999 23.5 0.499887 23.4959C0.499893 20 0.499985 4.94085 0.499878 0.5C2.99999 0.5 13.7214 0.5 17 0.5L21.5 5V11.5M4.49995 7.50001H17.5375M4.49995 12.5H11.5M4.49995 17.5H11.5M17.4627 16.8843C17.5385 16.8843 17.6126 16.9068 17.6757 16.9489C17.7387 16.991 17.7878 17.0509 17.8169 17.1209C17.8459 17.191 17.8535 17.268 17.8387 17.3424C17.8239 17.4167 17.7874 17.485 17.7338 17.5386C17.6802 17.5922 17.6119 17.6287 17.5375 17.6435C17.4631 17.6583 17.3861 17.6507 17.316 17.6217C17.246 17.5927 17.1861 17.5436 17.144 17.4805C17.1019 17.4175 17.0794 17.3434 17.0794 17.2676C17.0794 17.166 17.1198 17.0685 17.1916 16.9966C17.2635 16.9247 17.361 16.8843 17.4627 16.8843Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const TextFormat = Svg(Fragment, { viewBox: '0 0 24 24', fill: 'none' });

TextFormat.displayName = 'TextFormat';

export default TextFormat;
