// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_20053');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.64998 -0.150024V4.34998H10.15V5.64998H5.64998V10.15H4.34998V5.64998H-0.150024V4.34998H4.34998V-0.150024H5.64998Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const SmallAdd = Svg(Fragment, {
  viewBox: '0 0 10 10',
  width: '10',
  height: '10',
  fill: 'none',
});

SmallAdd.displayName = 'SmallAdd';

export default SmallAdd;
