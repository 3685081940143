/**
 * Agreement states
 * 0: Drafted agreement, can only be viewed by owner
 * 1: Pending, under negotiation
 * 2: Overdue agreement
 * 3: Signature process ongoing, will be grouped with signed when viewed
 * 4: Signed agreement
 * 5: Declined agreement
 * 6: Templated agreement, can't publish
 * 7: Declining process ongoing, will be grouped with declined when viewed
 */
export const STATE_DRAFT = 0;
export const STATE_PENDING = 1;
export const STATE_OVERDUE = 2;
export const STATE_SIGN_IN_PROGRESS = 3;
export const STATE_SIGNED = 4;
export const STATE_DECLINED = 5;
export const STATE_TEMPLATE = 6;
export const STATE_DECLINE_IN_PROGRESS = 7;

export const IMPORT_NONE = 0;
export const IMPORT_IN_PROGRESS = 1;
export const IMPORT_FINISHED = 2;

/**
 * Agreement signed lifecycle
 * 0: Active agreement
 * 1: Ended agreement
 * 4: Start date is in the future when the agreement is signed
 * null: No lifecycle, e.g. used when a contract with no duration type starts immediately
 * -1: No lifecycle, used for searching for contracts without lifecycle
 */
export const LIFECYCLE_ACTIVE = 0;
export const LIFECYCLE_ENDED = 1;
export const LIFECYCLE_AWAITING = 4;
export const LIFECYCLE_NONE = null;
export const LIFECYCLE_NOT_SET = -1;

/**
 * Agreement duration types
 * 0: No duration, without end date
 * 1: Single period, with duration
 * 2: Recurring
 * 3: Recurring with initial
 * 4: Not set
 */
export const TYPE_NO_DURATION = 0;
export const TYPE_SINGLE_PERIOD = 1;
export const TYPE_RECURRING = 2;
export const TYPE_RECURRING_WITH_INITIAL = 3;
export const TYPE_NOT_SET = 4;

/**
 * Agreement signed types
 * - manual
 * - guardtime
 * - trustweaver
 */
export const SIGN_PLATFORM_MANUAL = 'manual';
export const SIGN_PLATFORM_TRUSTWEAVER = 'trustweaver';

/**
 * Agreement video status
*/
export const AGREEMENT_WITHOUT_VIDEO = 0;
export const AGREEMENT_VIDEO_PROCESSING = 1;
export const AGREEMENT_WITH_VIDEO = 2;

/**
 * Agreement boxes
 */
export const BOX_COMMENTS = 1;
export const BOX_DURATION = 2;
export const BOX_PDF = 3;
export const BOX_ATTACHMENTS = 4;
export const BOX_AUDIT_TRAIL = 5;
export const BOX_TEXT_AND_IMAGE = 10;
export const BOX_FORM = 12;
export const BOX_PRODUCT_TABLE = 13;
export const BOX_PRODUCT_SUMMATION = 14;
export const BOX_VIDEO = 15;

export const BOX_TYPES = {
  BOX_DURATION,
  BOX_PDF,
  BOX_ATTACHMENTS,
  BOX_TEXT_AND_IMAGE,
  BOX_FORM,
  BOX_PRODUCT_TABLE,
  BOX_PRODUCT_SUMMATION,
  BOX_VIDEO,
} as const;

/**
 * Agreement messages
 */
export const MESSAGE_TYPE_REMINDER = 2;

/**
  * Agreement link types
*/
export const LINK_TYPE_RELATION = 'relation';
export const LINK_TYPE_SUBCONTRACT = 'sub_contract';
export const LINK_TYPE_REPLACEMENT = 'replacement';
export const LINK_TYPE_EXTENSION = 'extension';
export const LINK_TYPE_EXTERNAL_URL = 'external_url';

/**
 * Modals opened with query string
 */
export const GUEST_CONVERSION_QUERY = 'gc';

/**
 * Max file sizes
*/
export const MAX_PDF_SIZE = 200 * 100 * 100;

/**
 * Default notice period reminder
 */
export const DEFAULT_NOTICE_PERIOD_REMINDER = 28;

/**
 * Max data field value length
 */
export const MAX_DATA_FIELD_LENGTH = 1024;

/**
 * Sidebar tabs
*/
export const DOCUMENT_TAB = 'document';
export const CONTENT_TAB = 'content';
export const PARTICIPANTS_TAB = 'participants';
export const MESSAGES_TAB = 'messages';
export const AUDIT_TRAIL_TAB = 'audit-trail';
export const SETTINGS_TAB = 'settings';
export const DATA_FIELDS_TAB = 'data-fields';
export const MORE_OPTIONS_TAB = 'more-options';

/**
 * Removed
*/
export const REMOVED = 1;
export const NOT_REMOVED = 0;
