// @flow

import React from 'react';

import Svg from 'components/svg';

const ControlStop = Svg((
  <path
    d="M2 2H16V16H2V2Z"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="round"
  />
), { viewBox: '0 0 18 18', fill: 'none' });

ControlStop.displayName = 'ControlStop';

export default ControlStop;
