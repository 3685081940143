import type { ReactNode, InputHTMLAttributes } from 'react';
import { uniqueId } from 'lodash';
import clsx from 'clsx';

import CheckIcon from 'components/icons/check';
import Tooltip from 'components/tooltip';
import TooltipInfo from 'components/tooltip-info';

import style from './checkbox.module.scss';

type CheckboxInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  checked: boolean,
};

type Props = {
  customCheckboxClass?: string,
  customClass?: string,
  customLabelStyle?: string,
  disabled?: boolean,
  handleTextOverflow?: boolean,
  wrapLabelText?: boolean,
  height?: string,
  input: CheckboxInputProps,
  label?: ReactNode,
  preserveStyleOnDisable?: boolean,
  reverseDirection?: boolean,
  tooltipMessage?: ReactNode,
  tooltipOnLabel?: boolean,
  tooltipType?: string,
  required?: boolean,
  invalid?: boolean,
  onPointerDown?: (event: React.PointerEvent<HTMLInputElement>) => void,
};

const Checkbox = ({
  customCheckboxClass,
  customClass,
  customLabelStyle,
  disabled,
  handleTextOverflow,
  wrapLabelText = false,
  height = '10px',
  input,
  label,
  preserveStyleOnDisable = false,
  reverseDirection,
  tooltipMessage,
  tooltipOnLabel,
  tooltipType,
  required,
  invalid,
  onPointerDown,
}: Props) => {
  const id = uniqueId();

  const icon = input.checked ? <CheckIcon className={style.Icon} height={height} /> : '';

  const labelClasses = clsx(
    style.LabelText,
    customClass,
    customLabelStyle,
    {
      [style.NoMargin]: reverseDirection,
      [style.TextOverflow]: handleTextOverflow,
      [style.CheckBoxLabelWrapText]: wrapLabelText,
    },
  );

  const text = label ? <span className={labelClasses}>{label}</span> : '';

  const containerClasses = clsx(
    style.Label,
    {
      [style.DisabledCursor]: disabled,
      [style.ReversedDirection]: reverseDirection,
      [style.CheckBoxContainerWithWrappedText]: wrapLabelText,
    },
  );

  const checkboxClasses = clsx(
    style.Checkbox,
    customCheckboxClass,
    {
      [style.Invalid]: invalid,
      [style.Checked]: input.checked,
      [style.Disabled]: disabled && !preserveStyleOnDisable,
    },
  );

  const getTooltip = () => {
    if (tooltipOnLabel) {
      return (
        <div className={style.TextContainer}>
          <Tooltip
            messageClassName={style.TooltipText}
            message={tooltipMessage}
            side="top"
            theme={tooltipType}
          >
            {text}
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={style.TextContainer}>
        {text}
        <TooltipInfo
          message={tooltipMessage}
          side="top"
          theme={tooltipType}
          zIndex="10002"
          disabled={disabled}
        />
      </div>
    );
  };

  const getText = () => {
    if (tooltipMessage) {
      return getTooltip();
    }

    return text;
  };

  return (
    <div className={style.CheckboxContainer}>
      <input
        className={style.HiddenCheckbox}
        type="checkbox"
        id={`checkbox-${id}`}
        disabled={disabled}
        required={required}
        {...input}
      />
      <label className={containerClasses} htmlFor={`checkbox-${id}`}>
        <div
          className={checkboxClasses}
          aria-required={required}
          aria-disabled={disabled}
          aria-checked={input.checked}
          role="checkbox"
          onPointerDown={onPointerDown}
        >
          {icon}
        </div>
        {getText()}
      </label>
    </div>
  );
};

export default Checkbox;
