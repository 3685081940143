// @flow

import React from 'react';

import Svg from 'components/svg';

const SmallWorkspace = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M4.00051 8.46872C3.77776 9.34879 2.98055 10 2.03125 10C0.909422 10 0 9.09058 0 7.96875C0 7.00826 0.666656 6.20347 1.5625 5.99185V4.00815C0.666656 3.79653 0 2.99174 0 2.03125C0 0.909422 0.909422 0 2.03125 0C2.98053 0 3.77772 0.651182 4.00049 1.53122H5.99951C6.22228 0.651182 7.01947 0 7.96875 0C9.09058 0 10 0.909422 10 2.03125C10 2.96927 9.36417 3.75879 8.5 3.99232V6.00768C9.36417 6.24121 10 7.03073 10 7.96875C10 9.09058 9.09058 10 7.96875 10C7.01945 10 6.22224 9.34879 5.99949 8.46872H4.00051ZM2.8625 2.03125C2.8625 2.49034 2.49034 2.8625 2.03125 2.8625C1.57216 2.8625 1.2 2.49034 1.2 2.03125C1.2 1.57216 1.57216 1.2 2.03125 1.2C2.49034 1.2 2.8625 1.57216 2.8625 2.03125ZM2.5625 3.99232C3.26773 3.80174 3.82089 3.24089 4.00051 2.53122H5.99949C6.18439 3.26172 6.76507 3.83454 7.5 4.00815V5.99185C6.76509 6.16545 6.18442 6.73825 5.99951 7.46872H4.00049C3.82086 6.75908 3.26771 6.19825 2.5625 6.00768V3.99232ZM2.8625 7.96875C2.8625 8.42784 2.49034 8.8 2.03125 8.8C1.57216 8.8 1.2 8.42784 1.2 7.96875C1.2 7.50966 1.57216 7.1375 2.03125 7.1375C2.49034 7.1375 2.8625 7.50966 2.8625 7.96875ZM7.96875 2.8625C8.42784 2.8625 8.8 2.49034 8.8 2.03125C8.8 1.57216 8.42784 1.2 7.96875 1.2C7.50966 1.2 7.1375 1.57216 7.1375 2.03125C7.1375 2.49034 7.50966 2.8625 7.96875 2.8625ZM8.8 7.96875C8.8 8.42784 8.42784 8.8 7.96875 8.8C7.50966 8.8 7.1375 8.42784 7.1375 7.96875C7.1375 7.50966 7.50966 7.1375 7.96875 7.1375C8.42784 7.1375 8.8 7.50966 8.8 7.96875Z"
  />
), { viewBox: '0 0 10 10', fill: 'none' });

SmallWorkspace.displayName = 'SmallWorkspace';

export default SmallWorkspace;
