import Rollbar from 'rollbar';

import { isEnvironment } from 'utils/environment';

const stripTextContentFromMessage = (error) => {
  if (error && typeof error.message === 'string') {
    const regex = /"text":"[^"]*"/g;
    return error.message.replace(regex, '"text":"<stripped content>"');
  }
  return error;
};

const critical = (message, data) => {
  if (!isEnvironment('development')) {
    Rollbar.critical(stripTextContentFromMessage(message), data);
  }
  console.error(message, data); // eslint-disable-line no-console
};

const error = (message, data) => {
  if (!isEnvironment('development')) {
    Rollbar.error(stripTextContentFromMessage(message), data);
  }
  console.error(message, data); // eslint-disable-line no-console
};

const warning = (message, data) => {
  if (!isEnvironment('development')) {
    Rollbar.warning(stripTextContentFromMessage(message), data);
  }
  console.warn(message, data); // eslint-disable-line no-console
};

const info = (message, data) => {
  if (!isEnvironment('development')) {
    Rollbar.info(stripTextContentFromMessage(message), data);
  }
  console.info(message, data); // eslint-disable-line no-console
};

const debug = (message, data) => {
  if (!isEnvironment('development')) {
    Rollbar.debug(stripTextContentFromMessage(message), data);
  }
  console.debug(message, data); // eslint-disable-line no-console
};

export default {
  critical,
  error,
  warning,
  info,
  debug,
};
