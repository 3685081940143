// @flow

import React from 'react';

import Svg from 'components/svg';

const Unlink = Svg((
  <>
    <path d="M10.3334 12.6667C10.6197 13.0494 10.9849 13.3661 11.4044 13.5953C11.8239 13.8245 12.2877 13.9607 12.7645 13.9949C13.2412 14.029 13.7198 13.9602 14.1676 13.7932C14.6154 13.6261 15.0221 13.3647 15.36 13.0267L17.36 11.0267C17.9672 10.398 18.3032 9.55598 18.2956 8.68199C18.288 7.808 17.9375 6.97196 17.3194 6.35394C16.7014 5.73591 15.8654 5.38535 14.9914 5.37775C14.1174 5.37016 13.2754 5.70614 12.6467 6.31333L11.5 7.45333" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M13.3333 11.3333C13.047 10.9506 12.6818 10.6339 12.2623 10.4047C11.8428 10.1755 11.379 10.0392 10.9022 10.0051C10.4255 9.97095 9.94695 10.0397 9.49911 10.2068C9.05127 10.3739 8.6446 10.6353 8.30668 10.9733L6.30668 12.9733C5.69948 13.602 5.3635 14.444 5.3711 15.318C5.37869 16.192 5.72926 17.028 6.34728 17.646C6.96531 18.2641 7.80135 18.6146 8.67534 18.6222C9.54933 18.6298 10.3913 18.2938 11.02 17.6867L12.16 16.5467" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M8.06474 2.62708L6.29374 3.10191L7.24341 6.64482L9.01349 6.16999L8.06474 2.62708Z" fill="currentColor" />
    <path d="M16.7566 17.3561L14.9865 17.8309L15.9352 21.3729L17.7062 20.8981L16.7566 17.3561Z" fill="currentColor" />
    <path d="M6.64391 7.24341L3.10191 6.29374L2.62708 8.06474L6.16908 9.01349L6.64391 7.24341Z" fill="currentColor" />
    <path d="M21.3729 15.9352L17.8309 14.9865L17.3561 16.7566L20.8981 17.7062L21.3729 15.9352Z" fill="currentColor" />
  </>
));

Unlink.displayName = 'Unlink';

export default Unlink;
