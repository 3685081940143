// @flow

import React from 'react';

import Svg from 'components/svg';

const ClearFormatting = Svg((
  <path fillRule="evenodd" clipRule="evenodd" d="M20 18.5965L5.40351 4L4 5.40351L11.3502 12.7537L10.216 19H12.8293L13.5919 14.9954L18.5965 20L20 18.5965ZM15 7L14.2306 11.1429L11.989 8.90127L12.3867 7H10.216L8.13552 5.21704L8.17181 5H19.7755L19.4952 7H15Z" fill="currentColor" />
));

ClearFormatting.displayName = 'ClearFormatting';

export default ClearFormatting;
