// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_4533_7734');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.04765 4.5548C8.11225 4.69317 8.14685 4.84475 8.14685 5.00009C8.14685 5.15544 8.11225 5.30702 8.04765 5.44539C7.9832 5.58346 7.89122 5.70356 7.78118 5.80112L7.77012 5.81071L2.92418 9.9162L2.08385 8.92432L6.71582 5.00009L2.08385 1.07587L2.92418 0.0839841L7.77012 4.18948L7.78118 4.19907C7.89122 4.29663 7.9832 4.41673 8.04765 4.5548Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)" />
        </clipPath>
      </defs>
    </>
  );
};

const ChevronRight = Svg(Fragment, { viewBox: '0 0 10 10', fill: 'none' });

ChevronRight.displayName = 'ChevronRight';

export default ChevronRight;
