// @flow

import React from 'react';

import Svg from 'components/svg';

const MarkAsSigned = Svg((
  <>
    <circle cx="7" cy="7" r="6.35" stroke="currentColor" strokeWidth="1.3" />
    <path d="M4 7.56836L5.34153 9.3969C5.38397 9.44027 5.43813 9.4755 5.5 9.5C5.56187 9.5245 5.62987 9.53764 5.69898 9.53845C5.76809 9.53926 5.83655 9.52772 5.8993 9.50468C5.96205 9.48164 6.01749 9.44769 6.06153 9.40534L10 5" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
  </>
), { fill: 'none', viewBox: '0 0 14 14' });

MarkAsSigned.displayName = 'MarkAsSigned';

export default MarkAsSigned;
