// @flow

import React from 'react';

import Svg from 'components/svg';

const NewArrowLeft = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.80134 11.4189L2.02865 7.44769C1.79032 7.19682 1.79032 6.8032 2.02865 6.55232L5.80134 2.58107L6.74384 3.47645L4.01396 6.35001L12.6499 6.35001L12.6499 7.65001L4.01396 7.65001L6.74384 10.5236L5.80134 11.4189Z"
    fill="currentColor"
  />
), { viewBox: '0 0 14 14', fill: 'none' });

NewArrowLeft.displayName = 'NewArrowLeft';

export default NewArrowLeft;
