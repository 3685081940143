// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Message } from '@oneflowab/pomes';
import { isEmpty } from 'lodash';

import { EmailField, PublicPasswordField } from 'components/fields';
import Button from 'components/button';

import style from './login-fields.module.scss';

type Props = {
  currentEmail?: string,
  redirectToPasswordStep: () => void,
  setShouldUseSSO: (boolean) => void,
  step: string,
};

export const LoginFields = ({
  currentEmail,
  redirectToPasswordStep,
  setShouldUseSSO,
  step,
}: Props) => {
  const getMultipleLoginAlternatives = () => (
    <div className={style.ButtonsContainer}>
      <Button
        color="yellow"
        customClass={style.Button}
        onClick={redirectToPasswordStep}
        type="submit"
      >
        <Message
          id="Log in with Oneflow password"
          comment="Button in log in form."
        />
      </Button>
      <Button
        color="yellow"
        customClass={style.Button}
        onClick={() => setShouldUseSSO(true)}
        type="submit"
      >
        <Message
          id="Log in with Single Sign-on (SSO)"
          comment="Button in log in form."
        />
      </Button>
    </div>
  );

  // eslint-disable-next-line class-methods-use-this
  const handleOnBlur = (reactFinalFormOnBlur, ...args) => {
    /*
    * Only update the final form meta state when the user has interacted with page.
    * This solves the issue of validation triggering on autofill and tabbing out of the window.
    * Autofill/tabbing out of the window does not trigger onChange
    * until user has interacted with the page.
    */
    const [event] = args;

    /* If the blurred element and the active element is the same,
    * that means that we have changed window and not clicked elsewhere on the page
    * When the user about to move to other tab or app, otherwise reaching here simply means
    * User set the focus off (blur) from the field and need to run validation and update meta
    */
    const hasChangedWindow = event.target === document.activeElement;

    if (hasChangedWindow) {
      return;
    }

    reactFinalFormOnBlur(...args);
  };

  const displayError = (meta) => {
    if (!isEmpty(meta.submitError)) {
      return true;
    }

    return meta.error && (meta.dirty || meta.submitFailed) && meta.touched;
  };

  const renderField = () => {
    switch (step) {
      case 'email':
        return (
          <EmailField
            autoComplete="email"
            autoFocus
            hideErrorsUntilTouched
            hideRequired
            onBlur={handleOnBlur}
            displayError={displayError}
          />
        );
      case 'password':
        return (
          <PublicPasswordField
            autoComplete="current-password"
            autoFocus
            hideRequired
            noMinLengthValidation
            onBlur={handleOnBlur}
            displayError={displayError}
          />
        );
      case 'method':
        return getMultipleLoginAlternatives();
      default:
        return (
          <EmailField
            autoComplete="email"
            autoFocus
            hideErrorsUntilTouched
            hideRequired
            onBlur={handleOnBlur}
            displayError={displayError}
          />
        );
    }
  };

  const renderForgotPassword = () => {
    if (step !== 'password') {
      return null;
    }

    return (
      <div className={style.RecoverLinkContainer}>
        <Link
          to={{
            initialEmail: currentEmail,
            pathname: '/recover-account',
          }}
        >
          <Message
            id="Forgot your password?"
            comment="Link text for going to the forgot password from the login page."
          />
        </Link>
      </div>
    );
  };

  return (
    <>
      {renderField()}
      {renderForgotPassword()}
    </>
  );
};
