import * as React from 'react';

// copy of https://github.com/radix-ui/primitives/blob/main/packages/react/compose-refs/src/composeRefs.tsx

function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

function composeRefs(...refs) {
  return (node) => refs.forEach((ref) => setRef(ref, node));
}

function useComposedRefs(...refs) {
  // refs here dynaic and can't help eslint
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(composeRefs(...refs), refs);
}

export { composeRefs, useComposedRefs };
