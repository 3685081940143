import { combineReducers } from 'redux';

import app from 'reducers/app';
import i18nState from 'reducers/i18n';
import router from 'reducers/router';
import positionMfa from 'reducers/entities/position-mfa';

export default combineReducers({
  app,
  i18nState,
  router,

  entities: combineReducers({
    positionMfas: positionMfa.reducer,
  }),
});
