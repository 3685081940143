// @flow

import React from 'react';

import Svg from 'components/svg';

const CreateContractIcon = Svg((
  <>
    <circle cx="24" cy="24" r="24" fill="backgroundColor" />
    <path
      d="M24 14V34"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M14 24H34"
      stroke="#013A4C"
      strokeWidth="1.6"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </>
), { viewBox: '0 0 48 48', fill: 'none' });

CreateContractIcon.displayName = 'CreateContractIcon';

export default CreateContractIcon;
