const logCompanyInfo = () => {
  console.log(`
 _____                      ___  ___
/\\  __\`\\                  /'___\\/\\_ \\
\\ \\ \\/\\ \\    ___      __ /\\ \\__/\\//\\ \\     ___   __  __  __
 \\ \\ \\ \\ \\ /' _ \`\\  /'__\`\\ \\ ,__\\ \\ \\ \\   / __\`\\/\\ \\/\\ \\/\\ \\
  \\ \\ \\_\\ \\/\\ \\/\\ \\/\\  __/\\ \\ \\_/  \\_\\ \\_/\\ \\L\\ \\ \\ \\_/ \\_/ \\
   \\ \\_____\\ \\_\\ \\_\\ \\____\\\\ \\_\\   /\\____\\ \\____/\\ \\___x___/'
    \\/_____/\\/_/\\/_/\\/____/ \\/_/   \\/____/\\/___/  \\/__//__/
`);

  console.log('%c WE\'RE HIRING: https://career.oneflow.com/jobs', 'font-family: monospace;');
  console.log('%c HELP CENTER: https://support.oneflow.com/', 'font-family: monospace;');
  console.log('%c PUBLIC API: https://developer.oneflow.com/', 'font-family: monospace;');
};

export default logCompanyInfo;
