// @flow

import React from 'react';

import Svg from 'components/svg';

const ImportContract = Svg(
  (
    <>
      <g clipPath="url(#clip0_252:15)">
        <path d="M3.42857 15.4286C2.70509 15.4286 1.14288 15.4286 0.57135 15.4286C0.57149 12.5714 0.571391 3.45833 0.571419 0.571489C2.28565 0.571387 6.93118 0.571441 8.58042 0.571441C8.71055 0.571468 8.83941 0.597142 8.95962 0.646995C9.07984 0.696847 9.18904 0.769901 9.28101 0.86198L13.1577 4.7387C13.2497 4.83072 13.3226 4.93996 13.3724 5.06017C13.4221 5.18038 13.4477 5.30921 13.4476 5.4393V8.00001" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" />
        <path d="M8.56856 0.645111L8.56855 4.74851C8.56855 5.0112 8.56841 5.73899 8.56841 5.73899C8.56841 5.73899 8.73052 5.73899 8.99321 5.73899H13.0966" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" />
        <path d="M8.57145 16L8.57145 8M8.57145 8L11.4286 10.8M8.57145 8L5.7143 10.8" stroke="currentColor" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
      </g>
    </>
  ), { viewBox: '0 0 16 16', fill: 'none' },
);

ImportContract.displayName = 'ImportContract';

export default ImportContract;
