// @flow

import React from 'react';

import Svg from 'components/svg';

const CreateInternalReminder = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.00699 1.3986V0H2.0979V1.3986H1.15385C0.847827 1.3986 0.554342 1.52017 0.337954 1.73656C0.121566 1.95294 0 2.24643 0 2.55245V8.84615C0 9.15217 0.121566 9.44566 0.337954 9.66205C0.554342 9.87843 0.847827 10 1.15385 10H8.84615C9.15217 10 9.44566 9.87843 9.66205 9.66205C9.87843 9.44566 10 9.15217 10 8.84615V2.55245C10 2.24643 9.87843 1.95294 9.66205 1.73656C9.44566 1.52017 9.15217 1.3986 8.84615 1.3986H7.9021V0H6.99301V1.3986H3.00699ZM1.15385 2.30769H2.0979V3.70629H3.00699V2.30769H6.99301V3.70629H7.9021V2.30769H8.84615C8.91107 2.30769 8.97332 2.33348 9.01922 2.37938C9.06512 2.42528 9.09091 2.48753 9.09091 2.55245V4.54559H0.909091V2.55245C0.909091 2.48753 0.934878 2.42528 0.980778 2.37938C1.02668 2.33348 1.08893 2.30769 1.15385 2.30769ZM0.909091 5.45468V8.84615C0.909091 8.91107 0.934877 8.97332 0.980778 9.01922C1.02668 9.06512 1.08893 9.09091 1.15385 9.09091H8.84615C8.91107 9.09091 8.97332 9.06512 9.01922 9.01922C9.06512 8.97332 9.09091 8.91107 9.09091 8.84615V5.45468H0.909091ZM4.69408 7.57008H3.47026V6.95819H4.69408V5.73474H5.30597V6.95819H6.5297V7.57008H5.30597V8.79418H4.69408V7.57008Z"
    fill="currentColor"
  />
), { viewBox: '0 0 10 10', fill: 'none' });

CreateInternalReminder.displayName = 'CreateInternalReminder';

export default CreateInternalReminder;
