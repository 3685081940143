// @flow

import React from 'react';

import Svg from 'components/svg';

const NewCheck = Svg((
  <path
    d="M1 8.4209L3.52369 11.646C3.60859 11.7544 3.7169 11.8425 3.84064 11.9038C3.96437 11.965 4.10037 11.9979 4.2386 11.9999C4.37682 12.0019 4.51374 11.9731 4.63924 11.9155C4.76474 11.8579 4.87562 11.773 4.96369 11.6671L13 2"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), { viewBox: '0 0 14 14', fill: 'none' });

NewCheck.displayName = 'NewCheck';

export default NewCheck;
