// @flow

import React from 'react';

import Svg from 'components/svg';

const Administrator = Svg((
  <>
    <path d="M14 10.2064V2.50645C14 1.80645 13.3 1.22311 12.6 1.33978C12.1333 1.45645 11.6667 1.45645 11.2 1.45645C9.91667 1.45645 8.63333 1.10645 7.7 0.406445C7.35 0.173112 6.88333 0.173112 6.53333 0.406445C5.36667 1.10645 4.31667 1.45645 3.03333 1.45645C2.45 1.45645 1.86667 1.45645 1.4 1.33978C0.7 1.10645 0 1.68978 0 2.50645V10.3231" fill="url(#paint0_linear_2272_24164)" />
    <path d="M0 5.18945V11.9561C0 12.8895 0.466667 13.5895 1.28333 14.0561L5.95 16.5061C6.65 16.8561 7.35 16.8561 8.05 16.5061L12.7167 14.1728C13.5333 13.8228 14 13.0061 14 12.0728V5.18945" fill="url(#paint1_linear_2272_24164)" />
    <path d="M7.30972 4.83835L8.12639 6.47169C8.24306 6.70502 8.47639 6.82168 8.70972 6.82168L10.4597 7.05502C10.6931 7.05502 10.6931 7.28835 10.5764 7.40502L9.29306 8.68835C9.17639 8.80502 9.05972 9.03835 9.05972 9.27169L9.40972 11.0217C9.40972 11.255 9.29306 11.3717 9.05972 11.255L7.42639 10.4384C7.19306 10.3217 6.95972 10.3217 6.72639 10.4384L5.09306 11.255C4.97639 11.3717 4.74306 11.255 4.74306 11.0217L5.09306 9.27169C5.09306 9.03835 5.09306 8.80502 4.85972 8.68835L3.57639 7.40502C3.45972 7.28835 3.45972 7.05502 3.69306 7.05502L5.55972 6.93835C5.79306 6.93835 6.02639 6.70502 6.14306 6.58835L6.95972 4.95502C6.95972 4.72169 7.19306 4.72168 7.30972 4.83835Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_2272_24164" x1="0" y1="5.26561" x2="14" y2="5.26561" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCA40" />
        <stop offset="1" stopColor="#F07540" />
      </linearGradient>
      <linearGradient id="paint1_linear_2272_24164" x1="0" y1="10.8933" x2="14" y2="10.8933" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCA40" />
        <stop offset="1" stopColor="#F07540" />
      </linearGradient>
    </defs>
  </>
), { viewBox: '0 0 14 17' });

Administrator.displayName = 'Administrator';

export default Administrator;
