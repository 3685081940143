// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

type PatternValidator = ({
  message: MessageTranslator,
  field: string,
  regexp: RegExp,
  text?: string,
}) => Validator;

const patternValidator: PatternValidator = ({
  message,
  field,
  regexp,
  text,
}) => (value: string) => {
  if (value === undefined || regexp.test(value)) {
    return undefined;
  }

  return text || message({
    id: '{field} is not valid',
    values: {
      field,
    },
    comment: 'Validation message for pattern validator.',
  });
};

export default patternValidator;
