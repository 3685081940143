// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import { EmailField } from 'components/fields';

const ResetPasswordStartFieldsComponent = () => (
  <>
    <p>
      <Message
        id="Enter your email address to receive a link to reset your password."
        comment="Reset password page body text. Used for describing that you will receive an email after submitting."
      />
    </p>
    <EmailField
      autoFocus
      hideRequired
      hideErrorsUntilTouched
      autoComplete="email"
    />
  </>
);

export default ResetPasswordStartFieldsComponent;
