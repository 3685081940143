// @flow

import React from 'react';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import get from 'lodash/get';

import { postChangeEmail } from 'oneflow-client/change-email';

import Info from 'components/block-info';

const getMessages = (message: MessageTranslator) => ({
  valid: {
    headerText: message({
      id: 'Email address changed',
      comment: 'Change email page header when email was successfully changed.',
    }),
    bodyText: message({
      id: 'Your email address has been successfully changed.',
      comment: 'Body text for the change email page when email was successfully changed.',
    }),
  },
  invalid: {
    headerText: message({
      id: 'Invalid link',
      comment: 'Change email page header when invalid link.',
    }),
    bodyText: message({
      id: "The link has expired or doesn't exist. Please try again.",
      comment: 'Body text for the change email page when invalid link.',
    }),
  },
  emailTaken: {
    headerText: message({
      id: 'Email address already registered',
      comment: 'Change email page header when email already taken.',
    }),
    bodyText: message({
      id: "Your email address hasn't been changed because the email you requested was claimed by someone else before you verified the change. If you still want to change your email, please try again with another email address.",
      comment: 'Body text for the change email page when email already taken.',
    }),
  },
});

type Params = {
  token: string,
};

type Match = {
  params: Params,
};

type Props = {
  message: MessageTranslator,
  computedMatch: Match,
};

type State = {
  headerText: string,
  bodyText: string,
};

export class ChangeEmailComponent extends React.Component<Props, State> {
  state = {
    headerText: '',
    bodyText: '',
  }

  componentDidMount() {
    this.onInit();
  }

  getErrorTexts(statusCode: number) {
    const { message } = this.props;
    const apiErrors = getMessages(message);

    switch (statusCode) {
      case 404:
        return apiErrors.invalid;
      case 409:
        return apiErrors.emailTaken;
      default:
        return apiErrors.invalid;
    }
  }

  onInitSuccess = () => {
    const { message } = this.props;

    this.setState(getMessages(message).valid);
  }

  onInitFailure = (error: Object) => {
    const statusCode = get(error, 'body.status_code');

    this.setState(this.getErrorTexts(statusCode));
  }

  onInit = () => {
    const { token } = this.props.computedMatch.params;

    return postChangeEmail({
      token,
    }).then(this.onInitSuccess)
      .catch(this.onInitFailure);
  }

  shouldRenderInfo() {
    const { headerText } = this.state;

    return !!headerText;
  }

  render() {
    const { message } = this.props;
    const { headerText, bodyText } = this.state;

    if (!this.shouldRenderInfo()) {
      return null;
    }

    return (
      <Info
        title={headerText}
        paragraphs={[bodyText]}
        buttonText={message({
          id: 'Go to Oneflow',
          comment: 'Link button text for going to the dashboard from the change email page.',
        })}
        buttonLink="/"
      />
    );
  }
}

export default localize<Props>(ChangeEmailComponent);
