// @flow

import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

import NewCheck from 'components/icons/new-check';

type InnerProps = {
  [any]: any
}

type Props = {
  getStyles: () => void,
  innerProps: InnerProps,
  isSelected: boolean,
  isFocused: boolean,
  isDisabled?: boolean,
  children: React.Node,
}

const getSelected = (isSelected) => {
  if (!isSelected) {
    return null;
  }

  return <NewCheck height="12px" />;
};

const Option = (props: Props) => {
  const {
    children,
    innerProps: { ref, ...restInnerProps },
    isSelected,
    isFocused,
    isDisabled,
  } = props;

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current && isFocused) {
      myRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [isFocused]);

  return (
    <div
      {...restInnerProps}
      ref={(node) => {
        myRef.current = node;
        if (typeof ref === 'function') ref(node);
      }}
      className={clsx('ReactSelect__option', {
        SelectedOptionBackground: isSelected && isFocused,
        FocusedOptionBackground: isFocused,
        DisabledOption: isDisabled,
      })}
      data-testid="ReactSelectOption"
    >
      <div>{children}</div>
      <div className="ReactSelect__option-selected">{getSelected(isSelected)}</div>
    </div>
  );
};

export default Option;
