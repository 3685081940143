const readCookies = () => {
  if (!document || !document.cookie) {
    return {};
  }

  return (
    document
      .cookie
      .split('; ')
      .map((cookie) => cookie.split('='))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  );
};

export default readCookies;
