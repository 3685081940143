// @flow

import React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import {
  TYPE_IS_INTERNAL_APPROVER,
  TYPE_IS_ORGANIZER,
  TYPE_IS_EXTERNAL_APPROVER,
} from 'agreement/participant/constants';
import { sortedGuestRoles } from 'utils/participant/roles';
import { checkAcl } from 'components/acl';
import Field from 'components/field';
import Info from 'components/icons/info';
import {
  getParticipantRolesWithApproverAsOptions,
  getParticipantRolesAsOptions,
  participantRoles,
  participantRolesWithApprover,
} from 'components/participant-roles/participant-roles';
import {
  isFormerColleague,
  mapParticipantRoleType,
} from 'agreement/participant';

import SelectField from 'components/select-field';
import RoleOption from 'components/fields/role-option';

type Props = {
  isColleague?: boolean,
  participant: AgreementParticipant,
  message: MessageTranslator,
  hiddenRoles?: Array<number>,
  onChange?: Option => void,
  initialValue?: number,
  disabled?: boolean,
  hasSignOrder?: boolean,
  fieldInfoRightPosition?: boolean,
  agreement?: Agreement,
};

export const ParticipantRole = ({
  isColleague,
  participant,
  message,
  hiddenRoles,
  onChange,
  initialValue,
  disabled,
  hasSignOrder,
  fieldInfoRightPosition,
  agreement,
}: Props) => {
  const hasAccessToInternalApproval = agreement
    ? checkAcl(agreement.acl, 'agreement:draft_approval_flow:update') : false;

  const hasAccessToPendingStateApprover = agreement
    ? checkAcl(agreement.acl, 'agreement:participant:pending_state_approver:create') : false;

  const [rolesErrorMessage, setRolesErrorMessage] = React.useState(undefined);

  const renderAvailableUserRolesDescription = () => {
    const canHaveInternalApprover = hasAccessToInternalApproval && isColleague;
    const getRoles = () => (canHaveInternalApprover
      ? participantRolesWithApprover(message, hasAccessToInternalApproval,
        hasAccessToPendingStateApprover)
      : participantRoles(message, hasAccessToPendingStateApprover));

    const allAvailableParticipantRoles = Object.values(getRoles()).filter(
      (role) => !hiddenRoles?.includes(role.value),
    );
    const visibleParticipantRoleDescription = (
      <ul>
        {allAvailableParticipantRoles.map((role) => (
          <li key={role?.value}>
            {role?.description}
          </li>
        )).filter(Boolean)}
      </ul>
    );
    return visibleParticipantRoleDescription;
  };

  const participantIsNotFormerColleague = !participant || !isFormerColleague(participant);

  const getOptions = () => {
    if (hasAccessToInternalApproval && isColleague && participantIsNotFormerColleague) {
      const options = getParticipantRolesWithApproverAsOptions(
        message,
        hasAccessToInternalApproval,
        hasAccessToPendingStateApprover,
      ).filter((role) => !hiddenRoles?.includes(role.value));

      const modifiedOptions = options.map((option) => {
        if (option.value === TYPE_IS_ORGANIZER || option.value === TYPE_IS_INTERNAL_APPROVER) {
          return {
            ...option,
            extraInformation: message(
              {
                id: 'Invisible to counterparties',
                comment: 'Extra information for roles that are invisible to counterparties.',
              },
            ),
          };
        }
        if (!hasSignOrder && option.value === TYPE_IS_EXTERNAL_APPROVER) {
          return {
            ...option,
            extraInformation: message(
              {
                id: 'Requires signing order',
                comment: 'Extra information for roles that are disabled.',
              },
            ),
          };
        }
        return option;
      });

      return modifiedOptions;
    }

    const roles = getParticipantRolesAsOptions(
      message,
      hasAccessToPendingStateApprover,
    ).filter(
      (role) => !hiddenRoles?.includes(role.value),
    );

    const modifiedOptions = roles.map((option) => {
      if (!hasSignOrder && option.value === TYPE_IS_EXTERNAL_APPROVER) {
        return {
          ...option,
          extraInformation: message(
            {
              id: 'Requires signing order',
              comment: 'Extra information for roles that are disabled.',
            },
          ),
        };
      }
      return option;
    });

    if (!isColleague || !participantIsNotFormerColleague) {
      return sortedGuestRoles(modifiedOptions);
    }

    return modifiedOptions;
  };

  const onRoleChange = (option: Option) => {
    if (onChange) {
      if (option.value === TYPE_IS_EXTERNAL_APPROVER && !hasSignOrder) {
        const errorMessageRole = message({
          id: 'Signing order is required for approvers. Enable signing order in additional options.',
          comment: 'Error messagge to show for approval role when signing order is disabled',
        });
        setRolesErrorMessage(errorMessageRole);
      } else {
        setRolesErrorMessage(undefined);
      }
      onChange(option);
    }
  };

  const field = (
    <Field
      name="role"
      label={message({
        id: 'Role',
        comment: 'Field label in add/edit counterparty modal.',
      })}
      placeholder={(
        <Message
          id="Select role"
          comment="Field placeholder in add/edit counterparty modal."
        />
      )}
      component={SelectField}
      options={getOptions()}
      clearable={false}
      searchable={false}
      required
      fieldinfo={{
        message: renderAvailableUserRolesDescription(),
        icon: <Info width="12px" />,
        side: 'top',
        zIndex: '10004',
        nextToLabel: !fieldInfoRightPosition,
      }}
      onChange={onRoleChange}
      initialValue={mapParticipantRoleType(initialValue, participant)}
      disabled={disabled}
      components={{ Option: RoleOption }}
      message={message}
      customErrorMessage={rolesErrorMessage}
    />
  );

  return field;
};

export default localize<Props>(ParticipantRole);
