// @flow

import React from 'react';

import Svg from 'components/svg';

const Attachment = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.3279 1.93569C15.8684 1.74262 15.3766 1.6457 14.8811 1.65012C14.3857 1.65454 13.8956 1.76022 13.4393 1.9615C12.9829 2.16279 12.569 2.45591 12.2222 2.82464L12.2144 2.83289L3.39874 11.8841C3.39874 11.8841 3.39875 11.8841 3.39874 11.8841C2.28094 13.0319 1.64998 14.5923 1.64998 16.2228C1.64998 17.8533 2.28092 19.4137 3.39874 20.5615C4.516 21.7087 6.0276 22.35 7.6 22.35C9.17241 22.35 10.684 21.7087 11.8013 20.5615L22.9878 9.07365L23.9191 9.98059L12.7326 21.4684C11.3744 22.8631 9.52847 23.65 7.6 23.65C5.67154 23.65 3.82565 22.8632 2.46741 21.4685C1.10975 20.0744 0.349976 18.1871 0.349976 16.2228C0.349976 14.2584 1.10975 12.3712 2.46741 10.9771L11.2793 1.92968C11.7434 1.43735 12.2991 1.04357 12.9146 0.772073C13.5319 0.49977 14.1965 0.356177 14.8695 0.350172C15.5425 0.344167 16.2095 0.475879 16.8315 0.73716C17.4533 0.998422 18.0172 1.38376 18.4905 1.86981C18.9638 2.35581 19.3372 2.93279 19.5897 3.56664C19.8423 4.20046 19.9692 4.87901 19.9634 5.56284C19.9576 6.24668 19.8192 6.9229 19.556 7.5522C19.2935 8.17969 18.9118 8.74852 18.4322 9.22524L9.6135 18.2857C9.0773 18.8177 8.35539 19.1151 7.60257 19.1085C6.84976 19.1019 6.13315 18.7918 5.60605 18.2507C5.0795 17.7102 4.78367 16.9825 4.77715 16.2251C4.77062 15.4678 5.05385 14.7349 5.57082 14.1851L5.57855 14.1769L14.8477 4.65595L15.7791 5.56278L6.51437 15.0794C6.23219 15.3815 6.07343 15.7884 6.0771 16.2139C6.08078 16.6412 6.24787 17.0466 6.53724 17.3436C6.82607 17.6401 7.2131 17.805 7.61396 17.8085C8.01311 17.812 8.40106 17.6553 8.69415 17.3664L17.5125 8.30631C17.8715 7.95032 18.1588 7.52362 18.3567 7.05049C18.5546 6.57733 18.6591 6.06783 18.6634 5.55184C18.6678 5.03585 18.572 4.52451 18.3821 4.04782C18.1922 3.57115 17.9121 3.13922 17.5592 2.77679C17.2062 2.4144 16.7875 2.12877 16.3279 1.93569Z"
    fill="currentColor"
  />
), { viewBox: '0 0 24 24', fill: 'none' });

Attachment.displayName = 'Attachment';

export default Attachment;
