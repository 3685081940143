/*
  import and use this instead of testEnvs var in legacy/src/app/tools/client_environment.js
  They will be basically the same when we remove from the array below
*/
export const TEST_ENVS = [
  'aurora1',
  'aurora2',
  'nebula1',
  'libra1',
  'apollo1',
  'nova1',
  'data1',
  'taurus1',
  'taurus2',
  'phoenix1',
  'phoenix2',
  'seal1',
  'polaris1',
  'polaris2',
  'ai1',
] as const;

declare global {
  interface Window {
    FLOW_CLIENT_ENVIRONMENT: typeof TEST_ENVS[number] & 'development';
    FLOW_CLIENT_BUILD: string;
    ASSETS_DOMAIN: string;
  }
}

const getEnvironment = () => window.FLOW_CLIENT_ENVIRONMENT;

const getCurrentVersion = () => window.FLOW_CLIENT_BUILD || 'dev';

const isEnvironment = (expectedEnvironment: string) => (
  getEnvironment() === expectedEnvironment
);

const isDevEnv = () => isEnvironment('development');
const isStagingEnv = () => isEnvironment('staging');
const isTestEnv = () => TEST_ENVS.includes(getEnvironment());
const isPublicTestEnv = () => isEnvironment('public-test');
const isProductionEnv = () => isEnvironment('production');

export {
  isEnvironment,
  getCurrentVersion,
  isDevEnv,
  isStagingEnv,
  isTestEnv,
  isPublicTestEnv,
  isProductionEnv,
};

export default getEnvironment;
