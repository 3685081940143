import Svg from 'components/svg';

const Fragment = () => (
  <>
    <circle cx="37" cy="37" r="37" fill="#F2F6F8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M36.4982 19.5391C35.0222 19.5391 33.6067 20.1254 32.5631 21.1691C31.6697 22.0625 31.1114 23.2284 30.9692 24.4715H42.0273C41.885 23.2284 41.3268 22.0625 40.4333 21.1691C39.3897 20.1254 37.9742 19.5391 36.4982 19.5391ZM46.4074 24.4715C46.2541 22.069 45.2318 19.7955 43.5194 18.0831C41.6572 16.2209 39.1316 15.1748 36.4982 15.1748C33.8648 15.1748 31.3392 16.2209 29.4771 18.0831C27.7646 19.7955 26.7423 22.069 26.589 24.4715H15.7227V28.8358H20.371V54.5438C20.371 55.9444 20.9274 57.2876 21.9178 58.278C22.9082 59.2684 24.2514 59.8248 25.6521 59.8248H47.3444C48.745 59.8248 50.0882 59.2684 51.0786 58.278C52.069 57.2876 52.6254 55.9444 52.6254 54.5438V28.8358H57.2738V24.4715H46.4074ZM24.7353 28.8358V54.5438C24.7353 54.7869 24.8319 55.0201 25.0038 55.192C25.1757 55.3639 25.4089 55.4605 25.6521 55.4605H47.3444C47.5875 55.4605 47.8207 55.3639 47.9926 55.192C48.1645 55.0201 48.2611 54.7869 48.2611 54.5438V28.8358H24.7353Z" fill="#013A4C" stroke="#F2F6F8" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M44.332 34.8887L28.6654 50.5553" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M44.332 50.5557L28.6654 34.889" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" />
  </>
);

const EmptyTrashState = Svg(Fragment, { viewBox: '0 0 74 74', fill: 'none' });

EmptyTrashState.displayName = 'EmptyTrashState';

export default EmptyTrashState;
