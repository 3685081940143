// @flow

import React from 'react';

import Svg from 'components/svg';

const NoContracts = Svg((
  <>
    <path
      d="M20.6666 16.0667L16.0666 20.6667M16.0666 16.0667L20.6666 20.6667M4.5 7.5H10H15.5M4.5 12H10.5M4.5 16.5H8.5M12.2333 18.3667C12.2333 19.9933 12.8795 21.5534 14.0297 22.7036C15.18 23.8538 16.74 24.5 18.3666 24.5C19.9933 24.5 21.5533 23.8538 22.7036 22.7036C23.8538 21.5534 24.5 19.9933 24.5 18.3667C24.5 16.74 23.8538 15.18 22.7036 14.0297C21.5533 12.8795 19.9933 12.2333 18.3666 12.2333C16.74 12.2333 15.18 12.8795 14.0297 14.0297C12.8795 15.18 12.2333 16.74 12.2333 18.3667Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8.50003 21.5C6.82607 21.5 3.0334 21.5 1.50003 21.5C1.49993 17.5488 1.50012 4 1.50002 1.5C4.00012 1.5 11.447 1.5 14.0001 1.5L18.5001 6V8.5"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
    />
  </>
), { viewBox: '0 0 26 26', fill: 'none' });

NoContracts.displayName = 'NoContracts';

export default NoContracts;
