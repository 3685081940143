// @flow

import React from 'react';

import Svg from 'components/svg';

const BreakCenter = Svg((
  <>
    <path d="M4 5H20V7H4V5Z" fill="currentColor" />
    <path d="M4 17H20V19H4V17Z" fill="currentColor" />
    <rect x="9" y="9" width="6" height="6" fill="currentColor" />
  </>
));

BreakCenter.displayName = 'BreakCenter';

export default BreakCenter;
