// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19906');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M14.0769 11.5092L16.6154 10.8837L17.25 13.386"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M17.1166 11.2055C17.2302 10.865 17.0461 10.4968 16.7055 10.3833C16.365 10.2698 15.9969 10.4539 15.8834 10.7944L17.1166 11.2055ZM8.96333 16.5L8.96518 15.85L8.96333 16.5ZM2.44999 11.3378L2.22415 10.7283L1.00514 11.18L1.23097 11.7895L2.44999 11.3378ZM4.06499 7.13341L4.69599 6.9774L4.38396 5.7154L3.75296 5.87141L4.06499 7.13341ZM1.38179 7.12726L0.751617 7.28656C0.83927 7.63331 1.1906 7.84411 1.53781 7.75826L1.38179 7.12726ZM1.38018 4.46857L1.22088 3.83839L-0.0394745 4.15699L0.119822 4.78716L1.38018 4.46857ZM15.8834 10.7944C15.4323 12.1476 14.4871 13.365 13.2267 14.2999L14.0011 15.344C15.4387 14.2777 16.5677 12.8523 17.1166 11.2055L15.8834 10.7944ZM13.2267 14.2999C11.9525 15.245 10.5713 15.8545 8.96518 15.85L8.96148 17.15C10.929 17.1556 12.5774 16.4001 14.0011 15.344L13.2267 14.2999ZM8.96518 15.85C7.54135 15.8459 6.15359 15.4105 4.98876 14.6033L4.24827 15.6717C5.63042 16.6296 7.27529 17.1452 8.96148 17.15L8.96518 15.85ZM4.98876 14.6033C3.82402 13.7961 2.93844 12.6562 2.44999 11.3378L1.23097 11.7895C1.81258 13.3592 2.86604 14.7138 4.24827 15.6717L4.98876 14.6033ZM3.75296 5.87141L1.22578 6.49626L1.53781 7.75826L4.06499 7.13341L3.75296 5.87141ZM2.01197 6.96796L1.38018 4.46857L0.119822 4.78716L0.751617 7.28656L2.01197 6.96796Z"
          fill="currentColor"
        />
        <path
          d="M0.775184 6.90392C0.650207 7.24045 0.821701 7.61457 1.15823 7.73955C1.49476 7.86453 1.86888 7.69303 1.99386 7.35651L0.775184 6.90392ZM8.99991 1.5L9.00175 0.85H8.99991V1.5ZM15.5453 6.66856L15.7718 7.27783L16.9903 6.82486L16.7638 6.21559L15.5453 6.66856ZM1.99386 7.35651C2.47999 6.0475 3.48288 4.73526 4.76109 3.75099C6.03948 2.76658 7.54046 2.15 8.99991 2.15V0.85C7.18347 0.85 5.4111 1.60969 3.96794 2.72098C2.52461 3.8324 1.35774 5.33527 0.775184 6.90392L1.99386 7.35651ZM8.99806 2.15C10.4293 2.15406 11.8241 2.59032 12.9947 3.39889L13.7335 2.32926C12.3458 1.37064 10.6944 0.854803 9.00175 0.850003L8.99806 2.15ZM12.9947 3.39889C14.1651 4.20737 15.0547 5.34885 15.5453 6.66856L16.7638 6.21559C16.1796 4.64388 15.1214 3.28795 13.7335 2.32926L12.9947 3.39889Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const ButtonRefreshArrows = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

ButtonRefreshArrows.displayName = 'ButtonRefreshArrows';

export default ButtonRefreshArrows;
