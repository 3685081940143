/* eslint-disable import/named */
/* eslint-disable no-param-reassign */
// @flow

import {
  cloneDeep,
  filter,
  get,
  head,
  isArray,
  isEmpty,
  mapValues,
  mergeWith,
  omit,
  pick,
  set,
} from 'lodash';
import type {
  BaseEditor,
  Range,
} from 'slate';

import {
  BOX_ATTACHMENTS,
  BOX_DURATION,
  BOX_FORM,
  BOX_PDF,
  BOX_PRODUCT_TABLE,
  BOX_TEXT_AND_IMAGE,
  TYPE_NOT_SET,
} from 'agreement/constants';
import { camelize } from 'oneflow-client/core';

// This import automatically freeze the state object since one of the state updaters
// uses the util and prevents property overwrite in some other state updaters,
// Specially those are executed after produceUpdatedBoxOrder being executed
// As a hidden side effect
import {
  createDataFieldExternalKeyValueMap,
  producePriceColumns,
} from 'reducers/helpers/current-contract';
import { getMessagesData } from 'comments';
import { isChecked } from 'utils/checkbox';
import {
  produceUpdatedBoxOrder,
  produceUpdatedDataField,
} from 'producers/current-contract';
import omitUndefinedValues from 'utils/omit-undefined-values';
import type { AttachmentBox } from 'data-validators/entity-schemas/document-box/attachment-box';
import type { Box } from 'data-validators/entity-schemas/document-box';
import type { ConvertKeysToSnakeCase } from 'types/utils';
import type { DataField } from 'data-validators/entity-schemas/agreement-data';
import type { DurationBox } from 'data-validators/entity-schemas/document-box/duration-box';
import type { FormBox } from 'data-validators/entity-schemas/document-box/form-box';
import type { PriceRoundingMethod } from 'data-validators/entity-schemas/document-box/product-table';
import type { TextBox } from 'data-validators/entity-schemas/document-box/text-box'

import { DATA_FIELD } from 'components/contract-boxes/constants';

import { updateTotalPrices } from './helpers/product-table';

export const RESET_CURRENT_CONTRACT = 'currentContract/RESET_CURRENT_CONTRACT' as const;
export const ADD_ATTACHMENT_FILE = 'currentContract/ADD_ATTACHMENT_FILE' as const;
export const ADD_BOX = 'currentContract/ADD_BOX' as const;
export const ON_UPLOAD_PDF_FILE = 'currentContract/ON_UPLOAD_PDF_FILE' as const;
export const REMOVE_BOX = 'currentContract/REMOVE_BOX' as const;
export const SET_CURRENT_CONTRACT_DATA = 'currentContract/SET_CURRENT_CONTRACT_DATA' as const;
export const SET_CURRENT_CONTRACT_HAS_ERROR = 'currentContract/SET_CURRENT_CONTRACT_HAS_ERROR' as const;
export const SET_CURRENT_CONTRACT_ID = 'currentContract/SET_CURRENT_CONTRACT_ID' as const;
export const SET_RAW_DATA = 'currentContract/SET_RAW_DATA' as const;
export const SET_DATA_FIELD_EXTERNAL_KEY_VALUE_MAP = 'currentContract/SET_DATA_FIELD_EXTERNAL_KEY_VALUE_MAP' as const;
export const POPULATE_PRICE_COLUMNS = 'currentContract/POPULATE_PRICE_COLUMNS' as const;
export const SET_CURRENT_CONTRACT_EVENTS_LIST = 'currentContract/SET_CURRENT_CONTRACT_EVENTS_LIST ' as const;
export const UPDATE_BOX = 'currentContract/UPDATE_BOX' as const;
export const UPDATE_BOX_CONFIG = 'currentContract/UPDATE_BOX_CONFIG' as const;
export const UPDATE_BOX_DATA_ITEM = 'currentContract/UPDATE_BOX_DATA_ITEM' as const;
export const UPDATE_BOX_ORDER = 'currentContract/UPDATE_BOX_ORDER' as const;
export const SET_PRICE_COLUMNS = 'currentContract/SET_PRICE_COLUMNS' as const;
export const UPDATE_DATA_FIELD = 'currentContract/UPDATE_DATA_FIELD' as const;
export const UPDATE_DATA_FIELD_ACTION = 'currentContract/UPDATE_DATA_FIELD_ACTION' as const;
export const UPDATE_DATA_FIELD_MAPPINGS = 'currentContract/UPDATE_DATA_FIELD_MAPPINGS' as const;
export const UPDATE_DURATION_BOX_ATTRIBUTE = 'currentContract/UPDATE_DURATION_BOX_ATTRIBUTE' as const;
export const UPDATE_PRISTINE_CONTRACT = 'currentContract/UPDATE_PRISTINE_CONTRACT' as const;
export const UPDATE_TOTAL_FILE_COUNT_AND_SIZE = 'currentContract/UPDATE_TOTAL_FILE_COUNT_AND_SIZE' as const;
export const UPDATE_META_DATA = 'currentContract/UPDATE_META_DATA' as const;
export const UPDATE_BOX_ACTION = 'currentContract/UPDATE_BOX_ACTION' as const;
export const SILENT_UPDATE_BOX_ACTION = 'currentContract/SILENT_UPDATE_BOX_ACTION' as const;
export const SILENT_UPDATE_PDF_BOX_ACTION = 'currentContract/SILENT_UPDATE_PDF_BOX_ACTION' as const;
export const UPDATE_BOX_CONFIG_ACTION = 'currentContract/UPDATE_BOX_CONFIG_ACTION' as const;
export const UPDATE_BOX_VISIBILITY_ACTION = 'currentContract/UPDATE_BOX_VISIBILITY_ACTION' as const;
export const UPDATE_BOX_DATA_ITEM_ACTION = 'currentContract/UPDATE_BOX_DATA_ITEM_ACTION' as const;
export const UPDATE_BOX_ORDER_ACTION = 'currentContract/UPDATE_BOX_ORDER_ACTION' as const;
export const UPDATE_BOXES_PRISTINE_MAP = 'currentContract/UPDATE_BOXES_PRISTINE_MAP' as const;
export const UPDATE_PRICE_COLUMNS_ACTION = 'currentContract/UPDATE_PRICE_COLUMNS_ACTION' as const;
export const UPDATE_IS_PRISTINE = 'currentContract/UPDATE_IS_PRISTINE' as const;
export const DUPLICATE_BOX = 'currentContract/DUPLICATE_BOX' as const;
export const INSERT_BOX = 'currentContract/INSERT_BOX' as const;
export const EJECT_BOX = 'currentContract/EJECT_BOX' as const;
export const UPDATE_SIGN_ATTEMPTED = 'currentContract/UPDATE_SIGN_ATTEMPTED' as const;
export const BANNER_VISIBILITY = 'currentContract/BANNER_VISIBILITY' as const; // has banner or not
export const DISCARD_CHANGES = 'currentContract/DISCARD_CHANGES' as const;
export const DISCARD_NEW_FILE_UPLOADS = 'currentContract/DISCARD_NEW_FILE_UPLOADS' as const;
export const SET_RESET_KEY = 'currentContract/SET_RESET_KEY' as const;
export const FILES_UPDATE_AFTER_DISCARD = 'currentContract/FILES_UPDATE_AFTER_DISCARD' as const;

// Multiple Attachments
export const FILES_DROP = 'currentContract/FILES_DROP' as const;
export const FILES_UPDATE_STATUS = 'currentContract/FILES_UPDATE_STATUS' as const;
export const FILES_UPLOAD_FAIL = 'currentContract/FILES_UPLOAD_ERROR' as const;
export const FILES_UPLOAD_FAIL_BATCH = 'currentContract/FILES_UPLOAD_ERROR_BATCH' as const;
export const ATTACHMENTS_MARK_ALL_FILES_AS_OLD = 'currentContract/ATTACHMENTS_MARK_ALL_FILES_AS_OLD' as const;
export const FILES_STATUS_BOX_MINIMIZE = 'currentContract/FILES_STATUS_BOX_MINIMIZE' as const;
export const FILES_STATUS_BOX_MAXIMIZE = 'currentContract/FILES_STATUS_BOX_MAXIMIZE' as const;
export const FILES_STATUS_BOX_CLOSE = 'currentContract/FILES_STATUS_BOX_CLOSE' as const;
export const FILES_STATUS_BOX_OPEN = 'currentContract/FILES_STATUS_BOX_OPEN' as const;
export const RECALCULATE_ATTACHED_FILES_SIZE_AND_COUNT = 'currentContract/RECALCULATE_ATTACHED_FILES_SIZE_AND_COUNT' as const;

export const SET_CURRENT_CONTRACT_MESSAGES = 'currentContract/SET_CURRENT_CONTRACT_MESSAGES' as const;
export const UPDATE_COLLAPSED_BOX_IDS = 'currentContract/UPDATE_COLLAPSED_BOX_IDS' as const;
export const ADD_ACCEPTED_SUGGESTION = 'currentContract/ADD_ACCEPTED_SUGGESTION' as const;
export const ADD_REJECTED_SUGGESTION = 'currentContract/ADD_REJECTED_SUGGESTION' as const;
export const CLEAN_UP_ACCEPTED_SUGGESTIONS = 'currentContract/CLEAN_UP_ACCEPTED_SUGGESTIONS' as const;
export const CLEAN_UP_REJECTED_SUGGESTIONS = 'currentContract/CLEAN_UP_REJECTED_SUGGESTIONS' as const;
export const ON_SAVE_COMMENT = 'currentContract/ON_SAVE_COMMENT' as const;
export const ON_SAVE_SUGGESTION = 'currentContract/ON_SAVE_SUGGESTION' as const;
export const ON_RESOLVE_COMMENT = 'currentContract/ON_RESOLVE_COMMENT' as const;
export const REMOVE_ACCEPTED_SUGGESTION = 'currentContract/REMOVE_ACCEPTED_SUGGESTION' as const;
export const REMOVE_REJECTED_SUGGESTION = 'currentContract/REMOVE_REJECTED_SUGGESTION' as const;
export const UPDATE_SUGGESTION_STATUS_WITHOUT_SAVING = 'currentContract/UPDATE_SUGGESTION_STATUS_WITHOUT_SAVING' as const;

export type Asset = {
  assetExtension: string;
  assetId: number;
  assetIdentifier: string;
  assetName: string;
  mimetype: string;
  pages: number;
  signature: string;
  status: 'processing' | 'failed' | 'ready' | 'success';
};

type RawAgreement = Omit<ConvertKeysToSnakeCase<Oneflow.Agreement>, 'events'>;

type MetaData = {
  boxesWithEmptyRequiredInput: number[];
  emptyBoxes: number[];
  hasNonPdfSections: boolean;
  durationBoxId: number | null;
  nonPdfSectionsCount: number;
  priceColumnsGroups: Array<{
    boxId: number,
    priceColumns: Array<{
      visible: boolean,
      column: {
        label: string,
        key: string,
        fixedAmount: number,
        enabled: boolean,
        uuid: string,
        boxId: number,
        price: number,
        // eslint-disable-next-line camelcase
        price_discounted: number,
      },
      formatted: {
        boxId: number,
        value: string,
        label: string,
      }
    }>;
  }>;
};

type FileType = {
  file: File;
  errors: Array<{ code: string }>;
  id: string;
}[];

type BoxOrder = { id: number; _id?: number }[];
type Boxes = Record<number, Box>;
export type Data = Record<DataField['id'], DataField>;
export type PristineContract = {
  boxes: Boxes;
  boxOrder: BoxOrder;
  data: Data;
};

type DataFieldExternalKeyValueMap = Record<string, string>;

export type CurrentContractState = {
  metaData: MetaData;
  agreementId?: number;
  filesUploadStatus: {
    files: FileType;
    isClosed: boolean;
    isMinimized: boolean;
  },
  boxes?: Boxes;
  boxOrder?: BoxOrder;
  data?: Data;
  dataFieldExternalKeyMap?: Record<NonNullable<DataField['value']['externalKey']>, DataField['id']>;
  valueDataFieldKeyMap?: Record<DataField['value']['key'], DataField['id']>;
  dataFieldExternalKeyValueMap: DataFieldExternalKeyValueMap;
  hasError: boolean;
  isPristine: boolean;
  pristineState: {
    boxOrder: boolean;
    boxesMap: {
      [boxId: number]: boolean;
    };
    dataFieldsMap: {
      [dataFieldId: number]: boolean;
    }
  };
  // FIXME: make sure to fix the types for nodes
  acceptedSuggestions: { id: number, nodes: any }[];
  // FIXME: make sure to fix the types for nodes
  rejectedSuggestions: { id: number, nodes: any }[];
  idMap: Record<number, number>;
  collapsedBoxIds: Box['id'][];
  rawData?: RawAgreement;
  totalFileCount: number;
  totalFileSize: number;
  /*
    TODO: replace with SystemAuditLogEvent[]
    when 'app/types/audit-log-event.js.flow' is converted to ts
  */
  eventsList: any[];
  signAttempted: boolean;
  pristineContract?: PristineContract;
  createNewDocumentPageFromTemplate: boolean;
  messages: Oneflow.Message[];
  bannerVisibility: boolean;
  resetKey: string | undefined;
};

export const FILES_UPLOAD_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
} as const;

export type AgreementFileUploadStatusType = typeof FILES_UPLOAD_STATUS[
  keyof typeof FILES_UPLOAD_STATUS
];

const initialState: CurrentContractState = {
  resetKey: undefined,
  agreementId: undefined,
  filesUploadStatus: {
    files: [],
    isClosed: true,
    isMinimized: false,
  },
  boxes: undefined,
  boxOrder: undefined,
  data: undefined,
  dataFieldExternalKeyMap: {},
  valueDataFieldKeyMap: {},
  dataFieldExternalKeyValueMap: {},
  hasError: false,
  idMap: {},
  collapsedBoxIds: [],
  pristineState: {
    boxesMap: {},
    boxOrder: true,
    dataFieldsMap: {},
  },
  rawData: undefined,
  totalFileCount: 0,
  totalFileSize: 0,
  isPristine: true,
  acceptedSuggestions: [],
  rejectedSuggestions: [],
  eventsList: [],
  metaData: {
    boxesWithEmptyRequiredInput: [],
    emptyBoxes: [],
    hasNonPdfSections: false,
    durationBoxId: null,
    nonPdfSectionsCount: 0,
    priceColumnsGroups: [],
  },
  signAttempted: false,
  createNewDocumentPageFromTemplate: false,
  messages: [],
  bannerVisibility: true,
};

export const resetCurrentContract = () => ({
  type: RESET_CURRENT_CONTRACT,
});

const getId = <Id, _Id>(item: {
  id: Id;
  _id?: _Id;
} | {
  id?: Id;
  _id?: _Id;
}) => item?.id || item?._id;

const getRemovedBox = (state: CurrentContractState, { boxId }: { boxId: Box['id']}) => {
  const boxToBeRemoved = state.boxes[boxId];

  if (!boxToBeRemoved) {
    return state;
  }

  if (!boxToBeRemoved.id && boxToBeRemoved._id) {
    return {
      ...state,
      isPristine: false,
      boxes: omit(state.boxes, boxId),
      pristineState: {
        ...state.pristineState,
        boxesMap: omit(state.pristineState.boxesMap, boxId),
      },
    };
  }

  let removedBox = { ...state.boxes[boxId] };
  if (removedBox.id && removedBox._id) {
    removedBox = omit(removedBox, ['_id']);
  }
  removedBox._removed = true;

  const updatedState = {
    ...state,
    isPristine: false,
    boxes: {
      ...state.boxes,
      [boxId]: removedBox,
    },
    pristineState: {
      ...state.pristineState,
      boxesMap: {
        ...state.pristineState.boxesMap,
        [boxId]: false,
      },
    },
  };

  if (boxToBeRemoved.type === BOX_DURATION) {
    return {
      ...updatedState,
      metaData: { ...state.metaData, durationBoxId: null },
    };
  }

  return updatedState;
};

const removeUnusedIdsFromBox = (boxToUpdate: Box) => {
  let box = boxToUpdate;

  if (box.id && box._id) {
    box = omit(box, ['_id']);
  }

  if (box.content?.data) {
    const newContent = box.content.data.map((dataItem) => {
      if (dataItem.id && dataItem._id) {
        return omit(dataItem, ['_id']);
      }
      return dataItem;
    });

    const newBox = {
      ...box,
      content: {
        ...box.content,
        data: newContent,
      },
    };

    return newBox;
  }

  return box;
};

const getUpdatedBox = (state: CurrentContractState, action) => {
  // FIXME:
  const boxId = getId(action.box) as unknown as number;
  const { pricePrecision, quantityPrecision } = action.box.config;
  const oldBox = state.boxes[boxId];
  const { type: boxType } = action.box;

  if (!oldBox) {
    return state;
  }

  let updatedBox = {
    ...oldBox,
    ...action.box,
  };

  if (boxType === BOX_PRODUCT_TABLE) {
    updatedBox = {
      ...updatedBox,
      store: {
        ...updatedBox.store,
        sums: updateTotalPrices(
          updatedBox,
          action.priceRoundingMethod,
          pricePrecision,
          quantityPrecision,
        ),
      },
    };
  }

  updatedBox = {
    ...updatedBox,
    store: {
      ...updatedBox.store,
    },
  };

  updatedBox = removeUnusedIdsFromBox(updatedBox);

  const updatedState = {
    ...state,
    boxes: {
      ...state.boxes,
      [boxId]: updatedBox,
    },
  };

  if (boxType === BOX_DURATION) {
    return {
      ...updatedState,
      metaData: { ...state.metaData, durationBoxId: boxId },
    };
  }

  return updatedState;
};

const mergeDataCustomizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return srcValue;
  }

  return undefined;
};

const mergeBoxData = (sourceData, partialData) => (
  mergeWith({}, sourceData, partialData, mergeDataCustomizer)
);

const getUpdatedBoxDataItem = (state: CurrentContractState, action) => {
  const {
    boxId,
    dataId,
    dataItem,
    priceRoundingMethod,
  } = action;

  let oldBox = state.boxes[boxId];

  if (!oldBox) {
    return state;
  }

  const updatedData = [...oldBox.content.data];
  const dataIndex = updatedData.findIndex(({ _id, id }) => (
    _id === dataId || id === dataId
  ));

  if (dataIndex > -1) {
    updatedData[dataIndex] = mergeBoxData(updatedData[dataIndex], dataItem);

    oldBox = removeUnusedIdsFromBox(oldBox);
  }

  const updatedBox = {
    ...oldBox,
    content: {
      ...oldBox.content,
      data: updatedData,
    },
  };

  return getUpdatedBox(state, { box: updatedBox, priceRoundingMethod });
};

const getUpdatedBoxConfig = (state: CurrentContractState, action) => {
  let oldBox = state.boxes[action.boxId];

  if (!oldBox) {
    return state;
  }

  oldBox = removeUnusedIdsFromBox(oldBox);

  const updatedBox = {
    ...oldBox,
    config: {
      ...oldBox.config,
      ...action.boxConfig,
    },
  };

  return getUpdatedBox(state, { box: updatedBox, priceRoundingMethod: action.priceRoundingMethod });
};

export const getAgreementBoxesTotalSizeAndCountOfFiles = (boxes: CurrentContractState['boxes']) => {
  let totalFileSize = 0;
  let totalFileCount = 0;

  filter(boxes, (box) => !box?._removed && (box.type === BOX_ATTACHMENTS || box.type === BOX_PDF))
    .forEach((box) => {
      const boxData = box?.content?.data;
      if (!boxData || boxData.length === 0) {
        return;
      }
      const fileBoxes = boxData.filter((boxFile) => !boxFile._removed && boxFile.key === 'file');

      totalFileCount += fileBoxes.length;
      fileBoxes.forEach((fileBox) => {
        totalFileSize += fileBox.value.size;
      });
    });

  return { totalFileSize, totalFileCount };
};

const getReCalculatedAttachedFilesSizeAndCount = (state: CurrentContractState) => {
  const { totalFileSize, totalFileCount } = getAgreementBoxesTotalSizeAndCountOfFiles(state.boxes);
  return {
    ...state,
    totalFileCount,
    totalFileSize,
  };
};

export const updateCollapsedBoxIds = (boxIds: Box['id'][]) => ({
  type: UPDATE_COLLAPSED_BOX_IDS,
  boxIds,
});

// FIXME: add types for nodes
export const addAcceptedSuggestion = (annotationId: number, nodes: any) => ({
  type: ADD_ACCEPTED_SUGGESTION,
  annotationId,
  nodes,
});

// FIXME: add types for nodes
export const addRejectedSuggestion = (annotationId: number, nodes: any) => ({
  type: ADD_REJECTED_SUGGESTION,
  annotationId,
  nodes,
});

export const cleanupAcceptedSuggestions = () => ({
  type: CLEAN_UP_ACCEPTED_SUGGESTIONS,
});

export const cleanupRejectedSuggestions = () => ({
  type: CLEAN_UP_REJECTED_SUGGESTIONS,
});

export const removeAcceptedSuggestionAction = (suggestionId: number) => ({
  type: REMOVE_ACCEPTED_SUGGESTION,
  suggestionId,
});

export const removeRejectedSuggestionAction = (suggestionId: number) => ({
  type: REMOVE_REJECTED_SUGGESTION,
  suggestionId,
});

export const removeAcceptedSuggestionReducer = (
  state: CurrentContractState,
  { suggestionId }: { suggestionId: number },
) => ({
  ...state,
  acceptedSuggestions: state.acceptedSuggestions
    .filter((suggestion) => suggestion.id !== suggestionId),
});

export const removeRejectedSuggestionReducer = (
  state: CurrentContractState,
  { suggestionId }: { suggestionId: number },
) => ({
  ...state,
  rejectedSuggestions: state.rejectedSuggestions
    .filter((suggestion) => suggestion.id !== suggestionId),
});

export const removeUnusedAttributes = <T>(item: T) => {
  let updatedItem = { ...item };

  if (updatedItem.content?.data) {
    const updatedData = updatedItem.content.data.map((d) => omit(d, ['agreement']));
    updatedItem = {
      ...updatedItem,
      content: {
        ...updatedItem.content,
        data: updatedData,
      },
    };
  }

  delete updatedItem.agreement;
  delete updatedItem.empty;

  return updatedItem;
};

const durationAttributes = [
  'id',
  'type',
  'duration',
  'initialDuration',
  'noticePeriod',
  'startDate',
  'endDate',
];

export const normalizeBoxes = (agreementEntity: Oneflow.Agreement) => (
  agreementEntity.boxes.reduce((acc, item) => {
    let box = removeUnusedAttributes(
      omitUndefinedValues(cloneDeep(item)),
    );

    if (box.type === BOX_DURATION) {
      const agreementContent = pick(agreementEntity, durationAttributes);
      box = {
        ...box,
        content: {
          agreement: agreementContent,
        },
      };
    }

    acc[item.id] = box;
    return acc;
  }, {} as Record<Box['id'], Box>)
);

const normalizeDataFields = (agreementEntity: Oneflow.Agreement) => {
  const dataFields = agreementEntity.data.filter((item) => item.key === DATA_FIELD);

  type DataFields = {
    data: Record<DataField['id'], DataField>;
    dataFieldExternalKeyMap: Record<string, DataField['id']>;
    valueDataFieldKeyMap: Record<string, DataField['id']>;
  };

  return dataFields.reduce<DataFields>((acc, item) => {
    const dataField = removeUnusedAttributes(cloneDeep(item));
    acc.data[item.id] = dataField;
    acc.dataFieldExternalKeyMap[item.value.externalKey] = item.id;
    acc.valueDataFieldKeyMap[item.value.key] = item.id;
    return acc;
  }, { data: {}, dataFieldExternalKeyMap: {}, valueDataFieldKeyMap: {} } as DataFields);
};

export const createIdMap = (list: Box[]) => (
  list.reduce((acc, item) => {
    if (item._id && item.id) {
      return {
        ...acc,
        [item._id]: item.id,
      };
    }
    return acc;
  }, {})
);

const mapToPristineContract = (agreementEntity: Oneflow.Agreement) => {
  const boxes = normalizeBoxes(agreementEntity);
  const {
    data,
    dataFieldExternalKeyMap,
    valueDataFieldKeyMap,
  } = normalizeDataFields(agreementEntity);
  const idMap = createIdMap(agreementEntity.boxes as Box[]);

  return {
    boxes,
    data,
    dataFieldExternalKeyMap,
    valueDataFieldKeyMap,
    idMap,
    boxOrder: cloneDeep(agreementEntity.boxOrder),
  };
};

const hasMissingRequiredAttachment = (box: AttachmentBox) => (
  box.config.requiredForSignatures && !box.content.data.length
);

const hasEmptyRequiredInput = (box: FormBox) => (
  box.content.data.find(({ value }) => {
    if (!value.required) {
      return false;
    }

    let boxValue = value.value;
    if (value.type === 'checkbox') {
      boxValue = isChecked(value.value);
    }
    return !boxValue;
  })
);

const isEmptyTextBox = (contentData: TextBox['content']['data']) => {
  const data = head(contentData);
  if (isEmpty(data)) {
    return true;
  }

  return isEmpty(data.value?.text) && isEmpty(data.value?.nodes);
};

// OBS! Use only when necessary
export const populateMetaData = (_currentContract: ReturnType<typeof mapToPristineContract>) => {
  const emptyBoxes: Box['id'][] = [];
  const boxesWithEmptyRequiredInput: Box['id'][] = [];
  let hasNonPdfSections = false;
  let durationBoxId = null;

  const priceColumnsGroups = producePriceColumns(
    _currentContract.boxes,
    _currentContract.boxOrder as unknown as ContractView.BoxOrder,
    createDataFieldExternalKeyValueMap(_currentContract.data),
  );

  Object.values(_currentContract.boxes).forEach((box) => {
    const {
      type,
      content,
    } = box;
    const boxId = getId(box) as Box['id'];

    if (type !== BOX_PDF) {
      hasNonPdfSections = true;
    }
    if (type === BOX_DURATION) {
      durationBoxId = boxId;
    }
    // Empty required inputs
    if (type === BOX_ATTACHMENTS && hasMissingRequiredAttachment(box)) {
      boxesWithEmptyRequiredInput.push(boxId);
    }
    if (type === BOX_FORM && hasEmptyRequiredInput(box)) {
      boxesWithEmptyRequiredInput.push(boxId);
    }
    // Unfinished sections
    if (type === BOX_DURATION && content.agreement.type === TYPE_NOT_SET) {
      emptyBoxes.push(boxId);
    }
    if (type === BOX_TEXT_AND_IMAGE && isEmptyTextBox(content.data)) {
      emptyBoxes.push(boxId);
    }
    if (type !== BOX_DURATION && type !== BOX_TEXT_AND_IMAGE && isEmpty(content?.data)) {
      emptyBoxes.push(boxId);
    }
  });

  return {
    emptyBoxes,
    boxesWithEmptyRequiredInput,
    hasNonPdfSections,
    durationBoxId,
    priceColumnsGroups,
  };
};

export const setCurrentContractData = (agreementEntity: Oneflow.Agreement) => {
  const newData = mapToPristineContract(agreementEntity);

  return {
    type: SET_CURRENT_CONTRACT_DATA,
    boxes: newData.boxes,
    metaData: populateMetaData(newData),
    boxOrder: newData.boxOrder,
    data: newData.data,
    dataFieldExternalKeyMap: newData.dataFieldExternalKeyMap,
    valueDataFieldKeyMap: newData.valueDataFieldKeyMap,
    idMap: newData.idMap,
  };
};

export const addBox = (box: Box) => ({
  type: ADD_BOX,
  box: removeUnusedAttributes(box),
});

export const removeBox = (boxId: number) => ({
  type: REMOVE_BOX,
  boxId,
});

export const silentUpdateBoxAction = (box: Box) => ({
  type: SILENT_UPDATE_BOX_ACTION,
  box,
});

export const silentUpdatePdfBoxAction = ({
  boxId, pages = null, status, assetId, apiCode = null,
}) => ({
  type: SILENT_UPDATE_PDF_BOX_ACTION,
  boxId,
  pages,
  status,
  assetId,
  apiCode,
});

export const updateBoxOrder = (boxOrder: BoxOrder) => ({
  type: UPDATE_BOX_ORDER,
  boxOrder,
});

export const updateBox = (box: Box, priceRoundingMethod: PriceRoundingMethod) => ({
  type: UPDATE_BOX,
  box: removeUnusedAttributes(box),
  priceRoundingMethod,
});

export const updateBoxAction = (box: Box) => ({
  type: UPDATE_BOX_ACTION,
  box,
});

export const updateBoxOrderAction = (boxOrder: BoxOrder) => ({
  type: UPDATE_BOX_ORDER_ACTION,
  boxOrder,
});

export const updateBoxConfig = (boxId: number, boxConfig: Box['config'], priceRoundingMethod: PriceRoundingMethod) => ({
  type: UPDATE_BOX_CONFIG,
  boxId,
  boxConfig,
  priceRoundingMethod,
});

export const updateBoxConfigAction = (boxId: number, boxConfig: Box['config']) => ({
  type: UPDATE_BOX_CONFIG_ACTION,
  boxId,
  boxConfig,
});

export const updateBoxVisibilityAction = (boxId: number, boxConfig: Box['config']) => ({
  type: UPDATE_BOX_VISIBILITY_ACTION,
  boxId,
  boxConfig,
});

export const updateBoxDataItem = (
  boxId: number,
  dataId: NonNullable<Box['content']['data']>[number]['id'],
  dataItem: Partial<NonNullable<Box['content']['data']>[number]>,
  priceRoundingMethod: PriceRoundingMethod,
) => ({
  type: UPDATE_BOX_DATA_ITEM,
  boxId,
  dataId,
  dataItem,
  priceRoundingMethod,
});

export const updateBoxDataItemAction = (
  boxId: number,
  dataId: NonNullable<Box['content']['data']>[number]['id'],
  dataItem: Partial<NonNullable<Box['content']['data']>[number]>,
) => ({
  type: UPDATE_BOX_DATA_ITEM_ACTION,
  boxId,
  dataId,
  dataItem,
});

export const updateBoxesPristineMap = (
  boxState: CurrentContractState['pristineState']['boxesMap'],
) => ({
  type: UPDATE_BOXES_PRISTINE_MAP,
  boxState,
});

export const updateIsPristine = (isPristine: boolean) => ({
  type: UPDATE_IS_PRISTINE,
  isPristine,
});

export const updateDataField = (
  dataField: DataField,
  pristineContract: CurrentContractState['pristineContract'],
) => ({
  type: UPDATE_DATA_FIELD,
  dataField: removeUnusedAttributes(dataField),
  pristineContract,
});

export const updateDataFieldAction = (dataField: DataField) => ({
  type: UPDATE_DATA_FIELD_ACTION,
  dataField,
});

export const updatePristineContract = (
  pristineContract: CurrentContractState['pristineContract'],
) => ({
  type: UPDATE_PRISTINE_CONTRACT,
  pristineContract,
});

export const setCurrentContractMessages = (messages: Oneflow.Message[]) => ({
  type: SET_CURRENT_CONTRACT_MESSAGES,
  messages,
});

export const setContractRawData = (rawData: RawAgreement) => ({
  type: SET_RAW_DATA,
  rawData,
});

export const setPriceColumnsGroups = (priceColumnsGroups: any) => ({
  type: SET_PRICE_COLUMNS,
  priceColumnsGroups,
});

export const setDataFieldExternalKeyValueMap = (
  dataFieldExternalKeyValueMap: DataFieldExternalKeyValueMap,
) => ({
  type: SET_DATA_FIELD_EXTERNAL_KEY_VALUE_MAP,
  dataFieldExternalKeyValueMap,
});

export const updateDataFieldMappings = () => ({
  type: UPDATE_DATA_FIELD_MAPPINGS,
});

export const updatePriceColumnsAction = () => ({
  type: UPDATE_PRICE_COLUMNS_ACTION,
});

export const setCurrentContractError = (hasError: boolean) => ({
  type: SET_CURRENT_CONTRACT_HAS_ERROR,
  hasError,
});

export const setFilesInUploadStatus = (files: FileType) => ({
  type: FILES_UPDATE_AFTER_DISCARD,
  files,
});

export const discardNewFileUploads = () => ({
  type: DISCARD_NEW_FILE_UPLOADS,
});

/*
  TODO: replace with SystemAuditLogEvent[]
  when 'app/types/audit-log-event.js.flow' is converted to ts
*/
export const setCurrentContractEventsList = (eventsList: unknown[]) => ({
  type: SET_CURRENT_CONTRACT_EVENTS_LIST,
  eventsList,
});

export const updateMetaData = (metaData: CurrentContractState['metaData']) => ({
  type: UPDATE_META_DATA,
  metaData,
});

type AddAttachmentFileParams = {
  boxId: Box['id'];
  asset: Asset;
  status: Asset['status'];
  ownerParticipantId?: Oneflow.Participant['id'];
}

export const addAttachmentFile = ({
  boxId,
  asset,
  status,
  ownerParticipantId,
}: AddAttachmentFileParams) => ({
  type: ADD_ATTACHMENT_FILE,
  boxId,
  asset,
  status,
  ownerParticipantId,
});

type OnUploadPdfFileParams = {
  boxId: Box['id'];
  file: File;
  fileType: string;
  myParticipant: Oneflow.Participant;
  onSuccess: () => void;
  onFailure: () => void;
}

export const onUploadPdfFile = ({
  boxId,
  file,
  fileType,
  myParticipant,
  onSuccess,
  onFailure,
}: OnUploadPdfFileParams) => ({
  type: ON_UPLOAD_PDF_FILE,
  boxId,
  file,
  fileType,
  myParticipant,
  onSuccess,
  onFailure,
});

export const updateDurationBoxAttribute = (boxId: number, durationData: Partial<DurationBox['content']['agreement']>) => ({
  type: UPDATE_DURATION_BOX_ATTRIBUTE,
  boxId,
  durationData,
});

export const updateTotalFileCountAndSize = (totalFileCount: number, totalFileSize: number) => ({
  type: UPDATE_TOTAL_FILE_COUNT_AND_SIZE,
  totalFileCount,
  totalFileSize,
});

export const setInitialAttachmentsTotalSizeAndCount = (agreementEntity: Oneflow.Agreement) => {
  const { totalFileCount, totalFileSize } = getAgreementBoxesTotalSizeAndCountOfFiles(
    // FIXME: agreementEntity.boxes should be typed as Box[]
    agreementEntity.boxes as Box[],
  );
  return updateTotalFileCountAndSize(totalFileCount, totalFileSize);
};

export const recalculateAttachedFilesSizeAndCount = () => ({
  type: RECALCULATE_ATTACHED_FILES_SIZE_AND_COUNT,
});

export const setSignAttempted = (signAttempted: boolean) => ({
  type: UPDATE_SIGN_ATTEMPTED,
  signAttempted,
});

type OnSaveCommentParams = {
  contractId: Oneflow.Agreement['id'];
  boxId: Box['id'];
  dataId: DataField['id'];
  message: string;
  onSuccess: (commentId: Oneflow.Message['id']) => void;
  onFailure: (error: any) => void;
  editor: BaseEditor;
  editorSelection: Range;
}

export const onSaveComment = ({
  contractId,
  boxId,
  dataId,
  message,
  onSuccess,
  onFailure,
  editor,
  editorSelection,
}: OnSaveCommentParams) => ({
  type: ON_SAVE_COMMENT,
  contractId,
  boxId,
  dataId,
  message,
  onSuccess,
  onFailure,
  editor,
  editorSelection,
});

type OnResolveCommentParams = {
  annotationId: number;
  resolvedByParticipant: Oneflow.Participant;
  amplitudeScope: string;
  onFailure: (error: any) => void;
}

export const onResolveComment = ({
  annotationId,
  resolvedByParticipant,
  amplitudeScope,
  onFailure,
}: OnResolveCommentParams) => ({
  type: ON_RESOLVE_COMMENT,
  annotationId,
  resolvedByParticipant,
  amplitudeScope,
  onFailure,
});

type OnSaveSuggestionParams = {
  boxId: Box['id'];
  dataId: NonNullable<Box['content']['data']>[number]['id'];
  editor: BaseEditor;
  editorSelection: Range;
  suggestedText: string;
  readOnly: boolean;
  onSuccess: (suggestionId: Oneflow.Message['id']) => void;
  onFailure: (error: any) => void;
}

export const onSaveSuggestion = ({
  boxId,
  dataId,
  editor,
  editorSelection,
  suggestedText,
  readOnly,
  onSuccess,
  onFailure,
}: OnSaveSuggestionParams) => ({
  type: ON_SAVE_SUGGESTION,
  boxId,
  dataId,
  editor,
  editorSelection,
  suggestedText,
  readOnly,
  onSuccess,
  onFailure,
});

export const updateSuggestionStatusWithoutSaving = (
  suggestionId: number,
  contractId: number,
  myParticipant: Oneflow.Participant,
  resolveStatus: 'accepted' | 'rejected' | 'deleted',
  editor: BaseEditor,
) => ({
  type: UPDATE_SUGGESTION_STATUS_WITHOUT_SAVING,
  suggestionId,
  contractId,
  myParticipant,
  resolveStatus,
  editor,
});

export const duplicateBox = (boxId: number) => ({
  type: DUPLICATE_BOX,
  boxId,
});

type InsertBoxParams = {
  index?: number;
  boxType: Box['type'];
  productColumnLabels?: {
    name: string;
    description: string;
    // eslint-disable-next-line camelcase
    price_1: string;
    // eslint-disable-next-line camelcase
    price_2: string;
    count: string;
  };
}
export const insertBox = ({ index = 0, boxType, productColumnLabels }: InsertBoxParams) => ({
  type: INSERT_BOX,
  index,
  boxType,
  productColumnLabels,
});

type EjectBoxParams = {
  boxId: number;
  boxType: Box['type'];
}

export const ejectBox = ({ boxId, boxType }: EjectBoxParams) => ({
  type: EJECT_BOX,
  boxId,
  boxType,
});

export const setBannerVisibility = (
  visible: boolean,
) => ({
  type: BANNER_VISIBILITY,
  bannerVisibility: visible,
});

export const setCurrentContractId = (agreementId: Oneflow.Agreement['id']) => ({
  type: SET_CURRENT_CONTRACT_ID,
  agreementId,
});

export const normalizeIds = <Item extends { id: number }>(list: Item[]) => (
  list.reduce((acc, item) => {
    acc[item.id] = removeUnusedAttributes(cloneDeep(item));
    return acc;
  }, {} as Record<number, Item>)
);

const getInitialPristineContract = (
  state: CurrentContractState,
  action: ReturnType<typeof setCurrentContractData>,
) => {
  const initialContractData = {
    boxes: action.boxes || state.boxes,
    boxOrder: action.boxOrder || state.boxOrder,
    data: action.data || state.data,
  };

  return {
    ...state,
    ...initialContractData,
    dataFieldExternalKeyMap: action.dataFieldExternalKeyMap || state.dataFieldExternalKeyMap,
    valueDataFieldKeyMap: action.valueDataFieldKeyMap || state.valueDataFieldKeyMap,
    metaData: action.metaData || state.metaData,
    idMap: {
      ...state.idMap,
      ...action.idMap,
    },
    pristineContract: cloneDeep(initialContractData),
    pristineState: {
      boxOrder: true,
      boxesMap: mapValues(initialContractData.boxes, () => true),
      dataFieldsMap: mapValues(initialContractData.data, () => true),
    },
    isPristine: true,
  };
};

const getAddedBox = (state: CurrentContractState, action: ReturnType<typeof addBox>) => {
  const boxId = getId(action.box) as Box['id'];
  const { type: boxType } = action.box;

  const baseState = {
    ...state,
    isPristine: false,
    boxes: {
      ...state.boxes,
      [boxId]: action.box,
    },
    pristineState: {
      ...state.pristineState,
      boxesMap: {
        ...state.pristineState.boxesMap,
        [boxId]: false,
      },
    },
  };

  if (boxType === BOX_DURATION) {
    const agreement = get(action.box, 'content.agreement', {});
    set(action.box, 'content.agreement', camelize(agreement));

    return {
      ...baseState,
      metaData: {
        ...state.metaData,
        durationBoxId: boxId,
      },
    };
  }
  return baseState;
};

type AgreementAttachmentFileUploadFailBatchActionType = {
  type: typeof FILES_UPLOAD_FAIL_BATCH,
  files: FileType,
};

type AgreementAttachmentDropActionType = {
  type: typeof FILES_DROP,
  files: FileType,
}

type AgreementAttachmentFileUpdateStatusActionType = {
  type: typeof FILES_UPDATE_STATUS,
  status: AgreementFileUploadStatusType,
  fileId: string;
}

type AgreementAttachmentFileUpdateFailActionType = {
  type: typeof FILES_UPLOAD_FAIL,
  id: string;
  errors: Array<{ code: string }>,
}

type AgreementAttachmentFileUploadStatusBoxCloseActionType = {
  type: typeof FILES_STATUS_BOX_CLOSE,
}

type AgreementAttachmentFileUploadStatusBoxOpenActionType = {
  type: typeof FILES_STATUS_BOX_OPEN,
}

type AgreementAttachmentFileUploadStatusBoxMinimizeActionType = {
  type: typeof FILES_STATUS_BOX_MINIMIZE,
}

type AgreementAttachmentFileUploadStatusBoxMaximizeActionType = {
  type: typeof FILES_STATUS_BOX_MAXIMIZE,
}

type AgreementAttachmentFilesMarkAllFilesAsOldActionType = {
  type: typeof ATTACHMENTS_MARK_ALL_FILES_AS_OLD,
}

type RecalculateAttachedFilesSizeAndCountActionType = {
  type: typeof RECALCULATE_ATTACHED_FILES_SIZE_AND_COUNT,
}

type AgreementAttachmentFileAfterDiscardActionType = {
  type: typeof FILES_UPDATE_AFTER_DISCARD,
  files: FileType,
}

type Action =
  | ReturnType<typeof updateBoxesPristineMap>
  | ReturnType<typeof updateIsPristine>
  | ReturnType<typeof setCurrentContractData>
  | ReturnType<typeof setCurrentContractMessages>
  | ReturnType<typeof addBox>
  | ReturnType<typeof removeBox>
  | ReturnType<typeof updateBox>
  | ReturnType<typeof updateBoxConfig>
  | ReturnType<typeof updateBoxDataItem>
  | ReturnType<typeof updateDataField>
  | ReturnType<typeof updatePristineContract>
  | ReturnType<typeof updateBoxOrder>
  | ReturnType<typeof setCurrentContractId>
  | ReturnType<typeof setContractRawData>
  | ReturnType<typeof updateTotalFileCountAndSize>
  | ReturnType<typeof updateMetaData>
  | ReturnType<typeof addAcceptedSuggestion>
  | ReturnType<typeof addRejectedSuggestion>
  | ReturnType<typeof cleanupAcceptedSuggestions>
  | ReturnType<typeof cleanupRejectedSuggestions>
  | ReturnType<typeof removeAcceptedSuggestionAction>
  | ReturnType<typeof removeRejectedSuggestionAction>
  | ReturnType<typeof setPriceColumnsGroups>
  | ReturnType<typeof setDataFieldExternalKeyValueMap>
  | ReturnType<typeof setCurrentContractEventsList>
  | ReturnType<typeof setCurrentContractError>
  | ReturnType<typeof resetCurrentContract>
  | ReturnType<typeof setSignAttempted>
  | ReturnType<typeof setBannerVisibility>
  | ReturnType<typeof updateCollapsedBoxIds>
  | AgreementAttachmentFileUploadFailBatchActionType
  | AgreementAttachmentDropActionType
  | AgreementAttachmentFileUpdateStatusActionType
  | AgreementAttachmentFileUpdateFailActionType
  | AgreementAttachmentFilesMarkAllFilesAsOldActionType
  | AgreementAttachmentFileUploadStatusBoxCloseActionType
  | AgreementAttachmentFileUploadStatusBoxOpenActionType
  | AgreementAttachmentFileUploadStatusBoxMinimizeActionType
  | AgreementAttachmentFileUploadStatusBoxMaximizeActionType
  | AgreementAttachmentFileAfterDiscardActionType
  | RecalculateAttachedFilesSizeAndCountActionType
  | AgreementAttachmentFileAfterDiscardActionType;

const currentContract = (state: CurrentContractState = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_BOXES_PRISTINE_MAP:
      return {
        ...state,
        pristineState: {
          ...state.pristineState,
          boxesMap: {
            ...state.pristineState.boxesMap,
            ...action.boxState,
          },
        },
      };
    case UPDATE_IS_PRISTINE:
      return {
        ...state,
        isPristine: action.isPristine,
      };
    case SET_RESET_KEY:
      return {
        ...state,
        resetKey: action.resetKey,
      };
    case SET_CURRENT_CONTRACT_DATA:
      return getInitialPristineContract(state, action);
    case SET_CURRENT_CONTRACT_MESSAGES:
      return {
        ...state,
        messages: getMessagesData(state, action.messages),
      };
    case ADD_BOX:
      return getAddedBox(state, action);
    case REMOVE_BOX:
      return getRemovedBox(state, action);
    case UPDATE_BOX:
      return getUpdatedBox(state, action);
    case UPDATE_BOX_CONFIG:
      return getUpdatedBoxConfig(state, action);
    case UPDATE_BOX_DATA_ITEM:
      return getUpdatedBoxDataItem(state, action);
    case UPDATE_DATA_FIELD:
      return produceUpdatedDataField(state, action);
    case UPDATE_PRISTINE_CONTRACT:
      return {
        ...state,
        pristineContract: action.pristineContract,
      };
    case UPDATE_BOX_ORDER:
      return produceUpdatedBoxOrder(state, action);
    case SET_CURRENT_CONTRACT_ID:
      return {
        ...state,
        agreementId: action.agreementId,
      };
    case SET_RAW_DATA:
      return {
        ...state,
        rawData: action.rawData,
      };
    case UPDATE_TOTAL_FILE_COUNT_AND_SIZE:
      return {
        ...state,
        totalFileCount: action.totalFileCount,
        totalFileSize: action.totalFileSize,
      };
    case UPDATE_META_DATA:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          ...action.metaData,
        },
      };
    case UPDATE_COLLAPSED_BOX_IDS:
      return {
        ...state,
        collapsedBoxIds: action.boxIds,
      };
    case ADD_ACCEPTED_SUGGESTION:
      return {
        ...state,
        acceptedSuggestions: [
          ...state.acceptedSuggestions,
          {
            id: action.annotationId,
            nodes: action.nodes,
          },
        ],
      };
    case ADD_REJECTED_SUGGESTION:
      return {
        ...state,
        rejectedSuggestions: [
          ...state.rejectedSuggestions,
          {
            id: action.annotationId,
            nodes: action.nodes,
          },
        ],
      };
    case CLEAN_UP_ACCEPTED_SUGGESTIONS:
      return {
        ...state,
        acceptedSuggestions: [],
      };
    case CLEAN_UP_REJECTED_SUGGESTIONS:
      return {
        ...state,
        rejectedSuggestions: [],
      };
    case REMOVE_ACCEPTED_SUGGESTION:
      return removeAcceptedSuggestionReducer(state, action);
    case REMOVE_REJECTED_SUGGESTION:
      return removeRejectedSuggestionReducer(state, action);
    case SET_PRICE_COLUMNS:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          priceColumnsGroups: action.priceColumnsGroups,
        },
      };
    case SET_DATA_FIELD_EXTERNAL_KEY_VALUE_MAP:
      return {
        ...state,
        dataFieldExternalKeyValueMap: action.dataFieldExternalKeyValueMap,
      };
    case SET_CURRENT_CONTRACT_EVENTS_LIST:
      return {
        ...state,
        eventsList: action.eventsList,
      };
    case SET_CURRENT_CONTRACT_HAS_ERROR:
      return {
        ...state,
        hasError: action.hasError,
      };
    case FILES_DROP:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          files: [
            ...state.filesUploadStatus.files.map((file) => ({ ...file, new: false })),
            ...action.files.map((file) => ({
              ...file,
              status: FILES_UPLOAD_STATUS.LOADING,
              new: state?.filesUploadStatus?.files.length > 0,
              saved: false,
            })),
          ],
        },
      };
    case FILES_UPDATE_STATUS:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          files: [
            ...state.filesUploadStatus.files.map((file) => (file.id === action.id ? {
              ...file,
              status: action.status,
            } : file)),
          ],
        },
      };
    case FILES_UPLOAD_FAIL:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          files: [
            ...state.filesUploadStatus.files.map((file) => (file.id === action.id ? {
              ...file,
              status: FILES_UPLOAD_STATUS.FAIL,
              errors: action.errors,
            } : file)),
          ],
        },
      };
    case FILES_UPLOAD_FAIL_BATCH:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          files: [
            ...state.filesUploadStatus.files.map((file) => {
              const incomingFileUpload = action.files.find(
                (actionFile) => file.id === actionFile.id,
              );
              const shouldUpdate = incomingFileUpload
                && incomingFileUpload.errors
                && incomingFileUpload.errors.length > 0;

              if (shouldUpdate) {
                return {
                  ...file,
                  status: FILES_UPLOAD_STATUS.FAIL,
                  errors: incomingFileUpload.errors,
                };
              }
              return file;
            })],
        },
      };
    case FILES_STATUS_BOX_CLOSE:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          isClosed: true,
        },
      };
    case FILES_STATUS_BOX_OPEN:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          isClosed: false,
        },
      };
    case FILES_STATUS_BOX_MINIMIZE:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          isMinimized: true,
        },
      };
    case FILES_STATUS_BOX_MAXIMIZE:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          isMinimized: false,
        },
      };
    case FILES_UPDATE_AFTER_DISCARD:
      return {
        ...state,
        filesUploadStatus: {
          files: [
            ...action.files,
          ],
        },
      };
    case ATTACHMENTS_MARK_ALL_FILES_AS_OLD:
      return {
        ...state,
        filesUploadStatus: {
          ...state.filesUploadStatus,
          files: [
            ...state.filesUploadStatus.files.map((file) => ({ ...file, new: false, saved: true })),
          ],
        },
      };
    case RECALCULATE_ATTACHED_FILES_SIZE_AND_COUNT:
      return getReCalculatedAttachedFilesSizeAndCount(state);
    case RESET_CURRENT_CONTRACT:
      return initialState;
    case UPDATE_SIGN_ATTEMPTED:
      return {
        ...state,
        signAttempted: action.signAttempted,
      };
    case BANNER_VISIBILITY:
      return {
        ...state,
        bannerVisibility: action.bannerVisibility,
      };
    default:
      return state;
  }
};

// Selectors

type RootState = {
  currentContract: CurrentContractState;
};

export const getCurrentContractId = (state: RootState) => (
  state.currentContract?.agreementId
);

export const getCurrentBoxOrder = (state: RootState) => (
  state.currentContract?.boxOrder
);

export const getCurrentBoxes = (state: RootState) => (
  state.currentContract?.boxes
);

export const getCurrentData = (state: RootState) => (
  state.currentContract?.data
);

export const getCurrentContractData = (state: RootState) => {
  if (!state.currentContract) {
    return null;
  }

  return {
    agreementId: state.currentContract.agreementId,
    boxes: state.currentContract.boxes,
    boxOrder: state.currentContract.boxOrder,
    data: state.currentContract.data,
    dataFieldExternalKeyMap: state.currentContract.dataFieldExternalKeyMap,
    valueDataFieldKeyMap: state.currentContract.valueDataFieldKeyMap,
    idMap: state.currentContract.idMap,
    pristineContract: state.currentContract.pristineContract,
    dataFieldExternalKeyValueMap: state.currentContract.dataFieldExternalKeyValueMap,
  };
};

export const getPristineContractData = (state: RootState) => (
  state.currentContract?.pristineContract
);

export const getContractRawData = (state: RootState) => (
  state.currentContract?.rawData
);

export const getCollapsedBoxIds = (state: RootState) => (
  state.currentContract?.collapsedBoxIds
);

export const isContractPristine = (state: RootState) => {
  if (!state.currentContract) {
    return true;
  }

  return state.currentContract.isPristine;
};

export const isContractBoxPristine = (state: RootState, boxId: number) => {
  if (!state.currentContract || !boxId) {
    return true;
  }

  const { boxesMap } = state.currentContract.pristineState;
  if (!Object.hasOwn(boxesMap, boxId)) {
    return true;
  }

  return Boolean(state.currentContract.pristineState.boxesMap[boxId]);
};

export const hasContractError = (state: RootState) => (
  state.currentContract?.hasError
);

// temporary
window.__isContractPristine__ = isContractPristine;

export const getDataFieldExternalKeyValueMap = (state: RootState) => {
  if (!state.currentContract) {
    return null;
  }

  return state.currentContract.dataFieldExternalKeyValueMap;
};

export const getContractMetaData = (state: RootState) => (
  state.currentContract?.metaData
);

export const getHasNonPdfSections = (state: RootState) => (
  state.currentContract?.metaData?.hasNonPdfSections
);

export const getAttachedFilesTotalSize = (state: RootState): number => (
  state.currentContract?.totalFileSize
);

export const getAttachedFilesTotalCount = (state: RootState): number => (
  state.currentContract?.totalFileCount
);

export const getCurrentContractMessages = (state: RootState) => (
  state.currentContract?.messages
);

export const getDataFieldExternalKeyMap = (state: RootState) => (
  state.currentContract?.dataFieldExternalKeyMap
);

export const getValueDataFieldKeyMap = (state: RootState) => (
  state.currentContract?.valueDataFieldKeyMap
);

export const getDataFieldsMap = (state: RootState) => (
  state.currentContract?.data
);

export const getDataField = (state: RootState, dataFieldKey: string): DataField | null => {
  const valueDataFieldKeyMap = state.currentContract?.valueDataFieldKeyMap;
  const dataFieldsMap = state.currentContract?.data;

  if (!valueDataFieldKeyMap || !dataFieldsMap) {
    return null;
  }

  const dataFieldId = valueDataFieldKeyMap[dataFieldKey];

  if (!dataFieldId) {
    return null;
  }

  const dataField = dataFieldsMap[dataFieldId];

  if (!dataField) {
    return null;
  }

  return dataField;
};

export const getAcceptedSuggestions = (state: RootState) => (
  state.currentContract.acceptedSuggestions
);

export const getRejectedSuggestions = (state: RootState) => (
  state.currentContract.rejectedSuggestions
);

export const getSignAttempted = (state: RootState) => (
  state.currentContract.signAttempted
);

export const getPristineState = (state: RootState) => (
  state.currentContract.pristineState
);

export const getBannerVisibility = (state: RootState) => (
  state.currentContract?.bannerVisibility
);

export const discardChanges = (agreementObj: Oneflow.Agreement) => ({
  type: DISCARD_CHANGES,
  agreement: agreementObj,
});

export const setResetKey = (resetKey: string | undefined) => ({
  type: SET_RESET_KEY,
  resetKey,
});

export const getResetKey = (state: RootState) => (
  state.currentContract.resetKey
);

export default currentContract;
