import Svg from 'components/svg';

const CommentsAllResolvedEmpty = Svg((
  <>
    <g clipPath="url(#clip0_9351_22426)">
      <mask
        id="mask0_9351_22426"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="215"
        height="233"
      >
        <path d="M215 0H0V233H215V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_9351_22426)">
        <g filter="url(#filter0_d_9351_22426)">
          <path
            d="M24.1638 50.5356C24.1638 48.2636 26.0056 46.4219 28.2775 46.4219H182.542C184.813 46.4219 186.655 48.2636 186.655 50.5356V107.613C186.655 109.885 184.813 111.727 182.542 111.727H28.2775C26.0056 111.727 24.1638 109.885 24.1638 107.613V50.5356Z"
            fill="white"
          />
          <mask
            id="mask1_9351_22426"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="24"
            y="46"
            width="163"
            height="66"
          >
            <path
              d="M24.1636 50.5395C24.1636 48.2675 26.0053 46.4258 28.2773 46.4258H182.541C184.813 46.4258 186.655 48.2675 186.655 50.5395V107.617C186.655 109.889 184.813 111.731 182.541 111.731H28.2773C26.0053 111.731 24.1636 109.889 24.1636 107.617V50.5395Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_9351_22426)">
            <path
              d="M28.2773 111.731C24.8694 111.731 22.1067 108.968 22.1067 105.56V52.5963C22.1067 49.1884 24.8694 46.4258 28.2773 46.4258C27.1413 46.4258 26.2204 48.2675 26.2204 50.5395V107.617C26.2204 109.889 27.1413 111.731 28.2773 111.731Z"
              fill="#FFF3D8"
            />
          </g>
          <path d="M163.516 78.3047H95.6396V83.961H163.516V78.3047Z" fill="#D6DFE2" />
          <path d="M91.526 78.3047H66.3296V83.961H91.526V78.3047Z" fill="#D6DFE2" />
          <path d="M62.2157 78.3047H37.0193V83.961H62.2157V78.3047Z" fill="#D6DFE2" />
          <path
            d="M51.9313 62.3616C51.9313 58.1017 48.478 54.6484 44.2181 54.6484C39.9582 54.6484 36.5049 58.1017 36.5049 62.3616C36.5049 66.6215 39.9582 70.0748 44.2181 70.0748C48.478 70.0748 51.9313 66.6215 51.9313 62.3616Z"
            fill="white"
          />
          <path
            d="M51.9313 62.3616C51.9313 58.1017 48.478 54.6484 44.2181 54.6484C39.9582 54.6484 36.5049 58.1017 36.5049 62.3616C36.5049 66.6215 39.9582 70.0748 44.2181 70.0748C48.478 70.0748 51.9313 66.6215 51.9313 62.3616Z"
            stroke="#E0A8CC"
          />
          <path
            d="M41.7876 59.8563C41.7876 60.5009 42.0436 61.1191 42.4995 61.5749C42.9553 62.0307 43.5734 62.2867 44.218 62.2867C44.8626 62.2867 45.4808 62.0307 45.9366 61.5749C46.3924 61.1191 46.6484 60.5009 46.6484 59.8563C46.6484 59.2116 46.3924 58.5935 45.9366 58.1377C45.4808 57.6819 44.8626 57.4258 44.218 57.4258C43.5734 57.4258 42.9553 57.6819 42.4995 58.1377C42.0436 58.5935 41.7876 59.2116 41.7876 59.8563Z"
            stroke="#E0A8CC"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.2407 67.2935C40.2407 66.2388 40.6598 65.2271 41.4056 64.4813C42.1515 63.7354 43.1631 63.3164 44.2179 63.3164C45.2726 63.3164 46.2843 63.7354 47.0301 64.4813C47.776 65.2271 48.195 66.2388 48.195 67.2935"
            stroke="#E0A8CC"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M106.952 56.707H55.5308V62.3634H106.952V56.707Z" fill="#D6DFE2" />
          <path d="M90.4972 64.9336H55.5308V68.0189H90.4972V64.9336Z" fill="#D6DFE2" />
          <path
            d="M189.597 40H174.403C173.628 40 173 40.6282 173 41.4031V56.5969C173 57.3718 173.628 58 174.403 58H189.597C190.372 58 191 57.3718 191 56.5969V41.4031C191 40.6282 190.372 40 189.597 40Z"
            fill="#ECF9F3"
          />
          <mask
            id="mask2_9351_22426"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="176"
            y="43"
            width="13"
            height="13"
          >
            <path d="M188.314 43H176V55.3141H188.314V43Z" fill="white" />
          </mask>
          <g mask="url(#mask2_9351_22426)">
            <path
              d="M179 50.2104L180.262 51.8231C180.304 51.8772 180.359 51.9213 180.42 51.952C180.482 51.9827 180.55 51.9991 180.619 51.9999C180.688 52.0011 180.757 51.9865 180.82 51.9578C180.882 51.9289 180.938 51.8865 180.982 51.8336L185 47"
              stroke="#B6E0CC"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </g>
          <path
            d="M37.0193 94.2404C37.0193 93.1045 37.9402 92.1836 39.0761 92.1836H63.2444C64.3803 92.1836 65.3012 93.1045 65.3012 94.2404V99.3826C65.3012 100.518 64.3803 101.439 63.2444 101.439H39.0761C37.9402 101.439 37.0193 100.518 37.0193 99.3826V94.2404Z"
            fill="#D6DFE2"
          />
        </g>
        <g filter="url(#filter1_d_9351_22426)">
          <path
            d="M24.1638 130.239C24.1638 127.967 26.0056 126.125 28.2775 126.125H182.541C184.813 126.125 186.655 127.967 186.655 130.239V182.174C186.655 184.446 184.813 186.288 182.541 186.288H28.2775C26.0056 186.288 24.1638 184.446 24.1638 182.174V130.239Z"
            fill="white"
          />
          <mask
            id="mask3_9351_22426"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="24"
            y="126"
            width="163"
            height="61"
          >
            <path
              d="M24.1638 130.247C24.1638 127.975 26.0056 126.133 28.2775 126.133H182.541C184.813 126.133 186.655 127.975 186.655 130.247V182.182C186.655 184.454 184.813 186.296 182.541 186.296H28.2775C26.0056 186.296 24.1638 184.454 24.1638 182.182V130.247Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_9351_22426)">
            <path
              d="M28.2775 186.296C24.8696 186.296 22.1069 183.533 22.1069 180.125V132.303C22.1069 128.896 24.8696 126.133 28.2775 126.133C27.1415 126.133 26.2206 127.975 26.2206 130.247V182.182C26.2206 184.454 27.1415 186.296 28.2775 186.296Z"
              fill="#8EC4E3"
            />
          </g>
          <path
            d="M51.9315 139.498C51.9315 135.238 48.4782 131.785 44.2183 131.785C39.9584 131.785 36.5051 135.238 36.5051 139.498C36.5051 143.758 39.9584 147.212 44.2183 147.212C48.4782 147.212 51.9315 143.758 51.9315 139.498Z"
            fill="white"
          />
          <path
            d="M51.9315 139.498C51.9315 135.238 48.4782 131.785 44.2183 131.785C39.9584 131.785 36.5051 135.238 36.5051 139.498C36.5051 143.758 39.9584 147.212 44.2183 147.212C48.4782 147.212 51.9315 143.758 51.9315 139.498Z"
            stroke="#8EC4E3"
          />
          <path
            d="M41.7876 136.993C41.7876 137.637 42.0437 138.257 42.4995 138.713C42.9553 139.168 43.5735 139.424 44.2181 139.424C44.8626 139.424 45.4809 139.168 45.9367 138.713C46.3924 138.257 46.6485 137.637 46.6485 136.993C46.6485 136.348 46.3924 135.73 45.9367 135.275C45.4809 134.819 44.8626 134.562 44.2181 134.562C43.5735 134.562 42.9553 134.819 42.4995 135.275C42.0437 135.73 41.7876 136.348 41.7876 136.993Z"
            stroke="#8EC4E3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.2407 144.427C40.2407 143.372 40.6598 142.36 41.4056 141.614C42.1515 140.868 43.1631 140.449 44.2179 140.449C45.2726 140.449 46.2843 140.868 47.0301 141.614C47.776 142.36 48.195 143.372 48.195 144.427"
            stroke="#8EC4E3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M106.952 133.84H55.5305V139.496H106.952V133.84Z" fill="#D6DFE2" />
          <path d="M90.4969 142.066H55.5305V145.152H90.4969V142.066Z" fill="#D6DFE2" />
          <path
            d="M189.597 120H174.403C173.628 120 173 120.628 173 121.403V136.597C173 137.372 173.628 138 174.403 138H189.597C190.372 138 191 137.372 191 136.597V121.403C191 120.628 190.372 120 189.597 120Z"
            fill="#ECF9F3"
          />
          <mask
            id="mask4_9351_22426"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="176"
            y="123"
            width="13"
            height="13"
          >
            <path d="M188.314 123H176V135.314H188.314V123Z" fill="white" />
          </mask>
          <g mask="url(#mask4_9351_22426)">
            <path
              d="M179 130.21L180.262 131.823C180.304 131.877 180.359 131.921 180.42 131.952C180.482 131.983 180.55 131.999 180.619 132C180.688 132.001 180.757 131.986 180.82 131.958C180.882 131.929 180.938 131.887 180.982 131.834L185 127"
              stroke="#B6E0CC"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </g>
          <path d="M149.632 152.863H37.0193V158.52H149.632V152.863Z" fill="#D6DFE2" />
          <path
            d="M37.0193 168.807C37.0193 167.671 37.9402 166.75 39.0761 166.75H63.2439C64.3803 166.75 65.3007 167.671 65.3007 168.807V173.949C65.3007 175.085 64.3803 176.006 63.2439 176.006H39.0761C37.9402 176.006 37.0193 175.085 37.0193 173.949V168.807Z"
            fill="#D6DFE2"
          />
        </g>
        <path
          d="M9.28272 81.5508L7.88257 82.951L10.1745 85.2428L11.5746 83.8426L9.28272 81.5508Z"
          fill="#99B0B7"
        />
        <path
          d="M14.8957 87.168L13.4956 88.5682L15.7875 90.86L17.1876 89.4598L14.8957 87.168Z"
          fill="#99B0B7"
        />
        <path
          d="M15.7823 81.5469L13.489 83.8397L14.8883 85.2394L17.1816 82.946L15.7823 81.5469Z"
          fill="#99B0B7"
        />
        <path
          d="M10.1659 87.168L7.87256 89.4614L9.27184 90.8605L11.5651 88.5677L10.1659 87.168Z"
          fill="#99B0B7"
        />
        <path
          d="M5.59766 60.2701L5.59668 63.5124L7.57614 63.5117L7.57712 60.2695L5.59766 60.2701Z"
          fill="#99B0B7"
        />
        <path
          d="M5.58887 68.2193L5.58789 71.4614L7.56735 71.4609L7.56833 68.2188L5.58887 68.2193Z"
          fill="#99B0B7"
        />
        <path
          d="M8.95399 64.8799L8.95337 66.8593L12.1956 66.8584L12.1962 64.8789L8.95399 64.8799Z"
          fill="#99B0B7"
        />
        <path
          d="M1.00158 64.876L1.00098 66.8554L4.24318 66.8545L4.24378 64.875L1.00158 64.876Z"
          fill="#99B0B7"
        />
        <path
          d="M177.749 224.82L176.341 226.229L177.748 227.636L179.156 226.228L177.749 224.82Z"
          fill="#99B0B7"
        />
        <path
          d="M171.257 224.828L169.849 226.236L171.256 227.643L172.665 226.236L171.257 224.828Z"
          fill="#99B0B7"
        />
        <path
          d="M177.759 218.332L176.351 219.74L177.758 221.147L179.167 219.739L177.759 218.332Z"
          fill="#99B0B7"
        />
        <path
          d="M171.26 218.336L169.851 219.744L171.259 221.151L172.667 219.743L171.26 218.336Z"
          fill="#99B0B7"
        />
        <path
          d="M211.646 191.786L211.645 193.776H213.636L213.637 191.785L211.646 191.786Z"
          fill="#99B0B7"
        />
        <path d="M207.07 196.387L207.069 198.378H209.06L209.061 196.387H207.07Z" fill="#99B0B7" />
        <path d="M207.086 187.184L207.085 189.175H209.076L209.077 187.184H207.086Z" fill="#99B0B7" />
        <path
          d="M202.482 191.797L202.481 193.788L204.472 193.787L204.473 191.797H202.482Z"
          fill="#99B0B7"
        />
        <path
          d="M211.166 194.473L209.758 195.881L211.165 197.288L212.573 195.88L211.166 194.473Z"
          fill="#99B0B7"
        />
        <path
          d="M204.682 194.496L203.274 195.904L204.681 197.311L206.089 195.903L204.682 194.496Z"
          fill="#99B0B7"
        />
        <path
          d="M211.184 187.992L209.776 189.401L211.183 190.808L212.591 189.4L211.184 187.992Z"
          fill="#99B0B7"
        />
        <path
          d="M204.684 187.996L203.276 189.404L204.684 190.811L206.091 189.403L204.684 187.996Z"
          fill="#99B0B7"
        />
        <path
          d="M44.984 32.2478L44.9834 34.2266L48.1849 34.2257L45.9238 36.4868L47.3231 37.886L49.5842 35.6249L49.5832 38.8265L51.5622 38.8259L51.5633 35.6243L53.8227 37.8841L55.2229 36.4839L52.9629 34.2242L56.1649 34.2232L56.1655 32.2444L52.964 32.2454L55.225 29.9843L53.8258 28.585L51.5643 30.8461L51.5653 27.6445L49.5866 27.6451L49.5856 30.8467L47.3259 28.587L45.9258 29.9871L48.1856 32.2468L44.984 32.2478ZM48.2177 32.2629L49.5937 30.8869L51.5401 30.8863L52.9156 32.2615L52.9151 34.2081L51.5391 35.5841L49.5922 35.5847L48.2171 34.2096L48.2177 32.2629Z"
          fill="#99B0B7"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_9351_22426"
        x="4.96357"
        y="20.8"
        width="205.236"
        height="110.13"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22426" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22426"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_9351_22426"
        x="4.96382"
        y="100.8"
        width="205.236"
        height="104.697"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22426" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22426"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_9351_22426">
        <rect width="215" height="233" fill="white" />
      </clipPath>
    </defs>
  </>
), {
  fill: 'none',
  viewBox: '0 0 215 233',
});

CommentsAllResolvedEmpty.displayName = 'CommentsAllResolvedEmpty';

export { CommentsAllResolvedEmpty };
