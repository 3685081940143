// @flow

import React from 'react';
import {
  Redirect as ReactRouterRedirect,
  type Location,
} from 'react-router';

import { getModalRoutesQueryStrings } from 'modal-routes';

type Props = {|
  location: Location,
  to: string,
  push?: boolean,
  from?: string,
  exact?: boolean,
  strict?: boolean,
|};

const Redirect = ({
  location,
  to,
  push,
  from,
  exact,
  strict,
}: Props) => (
  <ReactRouterRedirect
    to={`${to}${getModalRoutesQueryStrings(location)}`}
    push={push}
    from={from}
    exact={exact}
    strict={strict}
  />
);

Redirect.defaultProps = {
  push: undefined,
  from: undefined,
  exact: undefined,
  strict: undefined,
};

export default Redirect;
