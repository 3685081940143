// @flow

import * as React from 'react';
import log from 'logging';

import ErrorPage from './error-page';

type Props = {
  children: React.Node,
};

type State = {
  hasError: boolean,
}

class ErrorHandler extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    log.error(error, info);

    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorHandler;
