// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import { PasswordFieldWithEye } from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
  noMinLengthValidation?: boolean,
  placeholder?: string,
  hideRequired?: boolean,
};

export const PublicPasswordSignUp = ({
  message,
  autoFocus,
  noMinLengthValidation,
  placeholder,
  hideRequired = false,
}: Props) => (
  <Field
    autoFocus={autoFocus}
    autoComplete="new-password"
    name="password"
    component={PasswordFieldWithEye}
    label={message({
      id: 'Password',
      comment: 'Label for the password field on sign up page',
    })}
    placeholder={placeholder || message({
      id: 'Create a password',
      comment: 'Password placeholder on a sign up page.',
    })}
    minLength={noMinLengthValidation ? undefined : 12}
    maxLength={72}
    responsive
    required
    hideRequired={hideRequired}
  />
);

PublicPasswordSignUp.defaultProps = {
  autoFocus: undefined,
  noMinLengthValidation: undefined,
  placeholder: undefined,
};

export default localize<Props>(PublicPasswordSignUp);
