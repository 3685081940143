// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import { PasswordField } from 'components/text-field';

type Props = {
  message: MessageTranslator,
};

export const PasswordConfirmation = ({ message }: Props) => (
  <Field
    name="passwordConfirmation"
    component={PasswordField}
    label={message({
      id: 'Password confirmation',
      comment: 'Password confirmation field label.',
    })}
    placeholder={message({
      id: 'Confirm new password',
      comment: 'Password confirmation field placeholder.',
    })}
    minLength={12}
    maxLength={72}
    required
  />
);

export default localize<Props>(PasswordConfirmation);
