// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837:19935');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M3.1501 -0.149902V0.850123L6.8501 0.850132V-0.149902H8.1501V0.850135L10.1502 0.850154L10.1501 10.1501L7.35019 10.1501V8.85011L8.85011 8.85011L8.85016 4.6501H7.3501V3.3501H8.85017L8.85019 2.15014L1.1501 2.15012L1.15011 3.3501H2.6501V4.6501H1.15012L1.15014 8.85011L2.6502 8.85011V10.1501L-0.149837 10.1501L-0.149902 0.85013L1.8501 0.850119V-0.149902H3.1501Z"
          fill="currentColor"
        />
        <path
          d="M5.6501 3.3501H4.3501V7.1501H5.6501V3.3501Z"
          fill="currentColor"
        />
        <path
          d="M4.2501 8.7501C4.2501 8.94901 4.32912 9.13978 4.46977 9.28043C4.61042 9.42108 4.80119 9.5001 5.0001 9.5001C5.19901 9.5001 5.38978 9.42108 5.53043 9.28043C5.67108 9.13978 5.7501 8.94901 5.7501 8.7501C5.7501 8.55118 5.67108 8.36042 5.53043 8.21977C5.38978 8.07911 5.19901 8.0001 5.0001 8.0001C4.80119 8.0001 4.61042 8.07911 4.46977 8.21977C4.32912 8.36042 4.2501 8.55118 4.2501 8.7501Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const CalendarAlert = Svg(Fragment, { viewBox: '0 0 10 10', fill: 'none' });

CalendarAlert.displayName = 'CalendarAlert';

export default CalendarAlert;
