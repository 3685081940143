import { call, select, takeEvery } from 'redux-saga/effects';
import { parse } from 'query-string';

import { getLocationSelector } from 'reducers/router';

import { LOGIN_SUCCESS } from 'public/reducers/login';
import sessionStorage from 'utils/session-storage';

export const redirectToPath = (path) => {
  window.location.replace(`//${window.location.hostname}${path}`);
};

export function* tryToRedirectToPath() {
  const location = yield select(getLocationSelector);
  const query = parse(location.search, { decode: false });

  if (query.path && query.path.startsWith('/')) {
    yield call(redirectToPath, query.path);

    return;
  }

  yield call(redirectToPath, '/');
}

export default function* login() {
  yield call(sessionStorage.removeItem, 'session_timedout');
  yield takeEvery(LOGIN_SUCCESS, tryToRedirectToPath);
}
