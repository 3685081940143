// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { getCurrentWorkspaceSelector } from 'reducers/app';

import { withWorkspaceNamespace } from './with-workspace-namespace';

export const mapStateToProps = (state: State) => ({
  workspace: getCurrentWorkspaceSelector(state),
});

type Props = {
  workspace: Workspace,
  children: ({
    withCurrentWorkspaceNamespace: (path: string) => string,
  }) => React.Node,
};

export class WithCurrentWorkspaceLink extends React.Component<Props> {
  render() {
    const { children, workspace } = this.props;

    return children({
      withCurrentWorkspaceNamespace: withWorkspaceNamespace(workspace),
    });
  }
}

export default connect(mapStateToProps)(WithCurrentWorkspaceLink);
