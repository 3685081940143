// @flow

import React from 'react';

import Svg from 'components/svg';

const User = Svg((
  <g>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.17293 6.77826C4.30549 6.77826 3.47359 7.12285 2.86021 7.73622C2.24684 8.34959 1.90226 9.1815 1.90226 10.0489V10.5001H1V10.0489C1 8.94221 1.43965 7.88081 2.22222 7.09823C3.0048 6.31565 4.0662 5.87601 5.17293 5.87601C6.27966 5.87601 7.34106 6.31565 8.12364 7.09823C8.90622 7.88081 9.34586 8.94221 9.34586 10.0489V10.5001H8.44361V10.0489C8.44361 9.1815 8.09902 8.34959 7.48565 7.73622C6.87228 7.12285 6.04037 6.77826 5.17293 6.77826Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.24568 1.29836C3.75682 0.787218 4.45008 0.500061 5.17294 0.500061C5.89581 0.500061 6.58906 0.787218 7.10021 1.29836C7.61135 1.8095 7.89851 2.50276 7.89851 3.22562C7.89851 3.94849 7.61135 4.64175 7.10021 5.15289C6.58906 5.66403 5.89581 5.95119 5.17294 5.95119C4.45008 5.95119 3.75682 5.66403 3.24568 5.15289C2.73454 4.64175 2.44738 3.94849 2.44738 3.22562C2.44738 2.50276 2.73454 1.8095 3.24568 1.29836ZM5.17294 1.40232C4.68937 1.40232 4.22561 1.59441 3.88367 1.93635C3.54173 2.27829 3.34963 2.74205 3.34963 3.22562C3.34963 3.7092 3.54173 4.17296 3.88367 4.5149C4.22561 4.85683 4.68937 5.04893 5.17294 5.04893C5.65651 5.04893 6.12028 4.85683 6.46222 4.5149C6.80415 4.17296 6.99625 3.7092 6.99625 3.22562C6.99625 2.74205 6.80415 2.27829 6.46222 1.93635C6.12028 1.59441 5.65651 1.40232 5.17294 1.40232Z" fill="currentColor" />
  </g>
), { viewBox: '0 0 14 14', fill: 'none' });

User.displayName = 'User';

export default User;
