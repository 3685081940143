// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import type { Validator } from './index';

type PhoneNumberValidator = ({
  message: MessageTranslator,
  field: string,
}) => Validator;

const phoneNumber: PhoneNumberValidator = ({ message, field }) => (value: string) => {
  if (!value) {
    return undefined;
  }

  const phone = parsePhoneNumberFromString(value);
  if (phone && phone.isValid()) {
    return undefined;
  }

  return message({
    id: '{field} is not a valid phone number',
    values: {
      field,
    },
    comment: 'Validation message for phone number validator.',
  });
};

export default phoneNumber;
