import { ReactNode } from 'react';
import { uniqueId } from 'lodash';

import Button from 'components/button';
import Content from 'components/block-content';
import Header from 'components/block-header';

import style from './block-info.module.scss';

type Props = {
  title: string,
  paragraphs: ReactNode[],
  buttonText: string,
  buttonLink: string,
};

const Info = (props: Props) => {
  const {
    title,
    paragraphs,
    buttonText,
    buttonLink,
  } = props;

  let button = null;

  if (buttonText && buttonLink) {
    button = (
      <Button
        href={buttonLink}
        color="yellow"
        customClass={style.LinkButton}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <>
      <Header title={title} />
      <Content>
        {paragraphs.map((paragraph) => (
          <p key={uniqueId()} className={style.Paragraph}>
            {paragraph}
          </p>
        ))}
        {button}
      </Content>
    </>
  );
};

export default Info;
