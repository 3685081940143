import { isBoolean } from 'lodash';
// eslint-disable-next-line import/named
import { setItem, removeItem } from 'utils/local-storage';
import sessionStorage from 'utils/session-storage';

const localClientFeatureFlagsList = [
  'migrated-box-manager',
  'ai-assist',
  'qes',
  'document-preview',
  'discard-changes-without-saving',
];

const setPersistedClientFeatureFlag = (featureFlag, value) => {
  const featureFlagKey = `local-ff:${featureFlag}`;

  sessionStorage.removeItem(featureFlagKey);

  if (!value) {
    removeItem(featureFlagKey);
    return;
  }

  setItem(featureFlagKey, value);
};

const setSessionClientFeatureFlag = (featureFlag, value) => {
  sessionStorage.setItem(`local-ff:${featureFlag}`, value);
};

// Temporary
window.__setClientFeatureFlag__ = (featureFlag, value, persist) => {
  if (!isBoolean(value)) {
    throw Error('Expected value is a boolean!');
  }

  if (persist) {
    setPersistedClientFeatureFlag(featureFlag, value);
    window.location.reload();
    return;
  }

  setSessionClientFeatureFlag(featureFlag, value);
  window.location.reload();
};

export default localClientFeatureFlagsList;
