// @flow

import React from 'react';

import Svg from 'components/svg';

const Superscript = Svg((
  <>
    <path d="M17.112 9.81189H21.5129V8.56888H19.2733V8.53529L19.8108 8.08736C21.1182 6.99833 21.4513 6.43282 21.4513 5.76932C21.4513 4.69989 20.575 4 19.2005 4C17.8651 4 16.9972 4.74188 17 5.94849H18.4782C18.4754 5.46697 18.7693 5.19821 19.2005 5.19821C19.6288 5.19821 19.9339 5.46417 19.9339 5.9037C19.9339 6.30964 19.6736 6.5728 19.2509 6.92274L17.112 8.69207V9.81189Z" fill="currentColor" />
    <path d="M9.59003 6.9538H7.07849L10.0609 11.9769L7 17H9.51155L11.6176 13.3831L13.7432 17H16.2352L13.1546 11.9769L16.1828 6.9538H13.6778L11.6176 10.6623L9.59003 6.9538Z" fill="currentColor" />
  </>
));

Superscript.displayName = 'Superscript';

export default Superscript;
