import Svg from 'components/svg';

const Conversation = Svg((
  <>
    <path
      d="M24.1476 60.9998C25.3724 60.9998 26.5696 60.6366 27.5879 59.9562C28.6062 59.2758 29.3999 58.3087 29.8686 57.1772C30.3372 56.0457 30.4599 54.8007 30.2209 53.5995C29.982 52.3983 29.3923 51.2949 28.5262 50.4289C27.6602 49.5629 26.5569 48.9732 25.3557 48.7342C24.1545 48.4953 22.9094 48.6179 21.7779 49.0866C20.6464 49.5553 19.6793 50.349 18.9989 51.3673C18.3185 52.3856 17.9553 53.5828 17.9553 54.8075C17.9553 56.4498 18.6077 58.0249 19.769 59.1861C20.9303 60.3474 22.5053 60.9998 24.1476 60.9998Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M33.2865 68.0757C32.3654 66.4638 31.0346 65.124 29.4289 64.1922C27.8232 63.2603 25.9997 62.7695 24.1432 62.7695C22.2867 62.7695 20.4633 63.2603 18.8576 64.1922C17.2519 65.124 15.921 66.4638 15 68.0757"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M58.935 60.9998C60.1597 60.9998 61.3569 60.6366 62.3752 59.9562C63.3936 59.2758 64.1872 58.3087 64.6559 57.1772C65.1246 56.0457 65.2472 54.8007 65.0083 53.5995C64.7693 52.3983 64.1796 51.2949 63.3136 50.4289C62.4476 49.5629 61.3442 48.9732 60.143 48.7342C58.9418 48.4953 57.6968 48.6179 56.5653 49.0866C55.4338 49.5553 54.4667 50.349 53.7863 51.3673C53.1058 52.3856 52.7427 53.5828 52.7427 54.8075C52.7427 56.4498 53.3951 58.0249 54.5564 59.1861C55.7176 60.3474 57.2927 60.9998 58.935 60.9998Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M68.0804 68.0757C67.1593 66.4638 65.8285 65.124 64.2228 64.1922C62.6172 63.2603 60.7937 62.7695 58.9372 62.7695C57.0807 62.7695 55.2572 63.2603 53.6515 64.1922C52.0458 65.124 50.715 66.4638 49.7939 68.0757"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M30.9273 24.918H55.6958"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M30.9273 32.582H38.004"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M22.0811 15V41.5351H39.7735V52.1502L50.3886 41.5351H64.5398V15H22.0811Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
  </>
), { viewBox: '0 0 84 84', fill: 'none' });

Conversation.displayName = 'Conversation';

export default Conversation;
