// @flow

import React from 'react';

import Svg from 'components/svg';

const ControlCameraOff = Svg((
  <g>
    <path d="M23.25,24c-0.2,0-0.389-0.078-0.53-0.22L0.22,1.28C0.078,1.139,0,0.95,0,0.75s0.078-0.389,0.22-0.53C0.361,0.078,0.55,0,0.75,0s0.389,0.078,0.53,0.22l22.5,22.5c0.292,0.292,0.292,0.768,0,1.061C23.639,23.922,23.45,24,23.25,24z" />
    <path d="M15.75,12C15.336,12,15,11.664,15,11.25v-3c0-0.414-0.336-0.75-0.75-0.75H10.5c-0.414,0-0.75-0.336-0.75-0.75S10.086,6,10.5,6h3.75c1.241,0,2.25,1.009,2.25,2.25v3C16.5,11.664,16.164,12,15.75,12z" />
    <path d="M2.25,19.5C1.009,19.5,0,18.491,0,17.25v-9C0,7.009,1.009,6,2.25,6C2.664,6,3,6.336,3,6.75S2.664,7.5,2.25,7.5S1.5,7.836,1.5,8.25v9C1.5,17.664,1.836,18,2.25,18H13.5c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H2.25z" />
    <path d="M22.498,18.286c-0.233,0-0.458-0.053-0.668-0.158l-2.586-1.292C18.476,16.452,18,15.681,18,14.823v-4.146c0-0.858,0.476-1.629,1.244-2.013l2.586-1.292c0.207-0.103,0.439-0.158,0.67-0.158c0.827,0,1.5,0.673,1.5,1.5v8.072c0,0.23-0.054,0.462-0.157,0.669C23.587,17.968,23.071,18.286,22.498,18.286z M19.914,10.006c-0.256,0.128-0.415,0.385-0.414,0.671v4.146c0,0.286,0.159,0.543,0.415,0.671l2.586,1.292l0-8.072v0L19.914,10.006z" />
  </g>
));

ControlCameraOff.displayName = 'ControlCameraOff';

export default ControlCameraOff;
