// @flow

import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  getMultiple,
  post,
  put,
  remove,
  hasAnyEntity,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

const tagSchema = new schema.Entity('tags');

const tagConnectionSchema = new schema.Entity('tagConnections', {
  tag: tagSchema,
});

export type NormalizedTags = {
  entities: {
    tags: {
      [number]: Tag,
    },
  },
  result: number | Array<number>,
  count: number,
}

type TagsNormalizer = (any) => NormalizedTags;

export const normalizeTags: TagsNormalizer = (tags) => ({
  ...normalize(tags.collection, [tagSchema]),
  count: tags.count,
});

const normalizeTag = (tag) => ({
  ...normalize(tag, tagSchema),
  count: 1,
});

export type NormalizedTagConnections = {
  entities: {
    tagConnections: {
      [number]: TagConnection,
    },
    tags: {
      [number]: Tag,
    },
  },
  result: number | Array<number>,
  count: number,
}

type TagConnectionsNormalizer = (any) => NormalizedTagConnections;

export const normalizeTagConnections: TagConnectionsNormalizer = (tagConnections) => ({
  ...normalize(tagConnections.collection, [tagConnectionSchema]),
  count: tagConnections.count,
});

export const normalizeTagConnection: TagConnectionsNormalizer = (tagConnection) => ({
  ...normalize(tagConnection, tagConnectionSchema),
  count: 1,
});

type GetTags = ({
  params?: {},
  pagination?: {},
  sort: Array<string>,
}) => Promise<NormalizedTags>;

const DEFAULT_SORT = ['name'];
export const getTags: GetTags = ({ params, pagination, sort = DEFAULT_SORT }) => (
  getMultiple({
    url: '/tags/',
    params: decamelizeKeys(params, { separator: '_' }),
    pagination,
    sort,
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeTags)
);

type RunExport = (Query) => Promise<NormalizedTags>;
export const runExport: RunExport = ({
  params,
  pagination,
}: Query) => (
  getMultiple({
    url: '/tags/export/',
    params: {
      ...params,
      export: 1,
    },
    pagination,
  })
    .then(extractResponseBodyAsJSON)
);

type CreateTag = ({
  name: string,
}) => Promise<NormalizedTags>;

export const createTag: CreateTag = ({ name }) => (
  post({
    url: '/tags/',
    body: {
      name,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeTag)
);

type UpdateTag = ({
  id: number,
  name: string,
}) => Promise<NormalizedTags>;

export const updateTag: UpdateTag = ({ id, name }) => (
  put({
    url: `/tags/${id}`,
    body: {
      name,
    },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeTag)
);

type DeleteTags = ({
  ids: Array<number>,
}) => Promise<{}>;

export const deleteTags: DeleteTags = ({ ids }) => (
  remove({
    url: '/tags/',
    body: { tag_ids: ids },
  })
    .then(extractResponseBodyAsJSON)
);

type GetTagConnections = ({
  params: {
    targetId?: number,
  },
}) => Promise<NormalizedTagConnections>;

export const getTagConnections: GetTagConnections = ({
  params: {
    targetId,
  },
}) => (
  getMultiple({
    url: '/tags/connections/',
    params: {
      target_id: targetId,
      target_type: 'agreement',
    },
    pagination: { limit: undefined, offset: undefined },
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizeTagConnections)
);

type CreateTagConnection = ({
  tagId: number,
  targetId: number,
  targetType: string,
  amplitudeScope?: string,
}) => Promise<NormalizedTagConnections>;

export const createTagConnection: CreateTagConnection = ({
  tagId,
  targetId,
  targetType,
  amplitudeScope,
}) => (
  post({
    url: '/tags/connections/',
    body: {
      tag_id: tagId,
      target_id: targetId,
      target_type: targetType,
    },
  }, { amplitudeScope })
    .then(extractResponseBodyAsJSON)
    .then(normalizeTagConnection)
);

type RemoveTagConnection = ({
  id: number,
  amplitudeScope?: string,
}) => Promise<{}>;

export const removeTagConnection: RemoveTagConnection = ({ id, amplitudeScope }) => {
  remove({
    url: `/tags/connections/${id}`,
  }, { amplitudeScope })
    .then(extractResponseBodyAsJSON);
};

export const hasAnyTag = hasAnyEntity({ url: '/tags/' });
