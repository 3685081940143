// @flow

import React from 'react';

import Svg from 'components/svg';

const CalendarPlus = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.20395 0V0.969676L6.79615 0.969709V0H8.05827V0.969721L10 0.969751V9.988H7.76701V8.72738H8.73788V4.6546H1.26219L1.26227 8.72738H2.23308V9.988H0.000197504L0 0.969652L1.94182 0.969664V0H3.20395ZM1.94182 2.23029L1.26214 2.23028L1.26216 3.39398H8.73788V2.23035L3.20395 2.2303L1.94182 2.23029ZM5.63156 5.34591L5.63121 7.04239L7.32972 7.04205L7.32997 8.30267L5.63096 8.30301L5.63062 10L4.36849 9.99975L4.36883 8.30327L2.67033 8.30361L2.67008 7.04299L4.36909 7.04264L4.36943 5.34565L5.63156 5.34591Z"
    fill="currentColor"
  />
), { viewBox: '0 0 10 10', fill: 'none' });

CalendarPlus.displayName = 'CalendarPlus';

export default CalendarPlus;
