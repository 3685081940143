// @flow

/* state */
export const STATE_IS_ENABLED = 0;
export const STATE_IS_DISABLED = 1;

export const COMPANY = 'company';
export const INDIVIDUAL = 'individual';

export const IS_INDIVIDUAL = 1;

export default { };
