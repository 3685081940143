// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19922');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.14998 -0.150024V2.84998H16.85V-0.150024H18.15V2.84998H24.15V24.15H-0.150024V2.84998H5.84998V-0.150024H7.14998ZM5.84998 4.14998H1.14998V8.84998H22.85V4.14998H18.15V7.14998H16.85V4.14998H7.14998V7.14998H5.84998V4.14998ZM22.85 10.15H1.14998V22.85H22.85V10.15Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const CalendarBox = Svg(Fragment, { viewBox: '0 0 24 24', fill: 'none' });

CalendarBox.displayName = 'CalendarBox';

export default CalendarBox;
