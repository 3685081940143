// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import { webhookFormatOptions } from 'extensions';

import Field from 'components/field';
import SelectField from 'components/select-field';

type Props = {
  message: MessageTranslator,
};

export const WebhookFormat = ({ message }: Props) => (
  <Field
    name="imwProxy"
    label={message({
      id: 'Format',
      comment: 'Webhook format field label',
    })}
    placeholder={message({
      id: 'Select format',
      comment: 'Webhook format field placeholder',
    })}
    component={SelectField}
    options={webhookFormatOptions}
    clearable={false}
    searchable={false}
    required
  />
);

export default localize<Props>(WebhookFormat);
