// @flow

export const UNLIMITED_AVAILABLE_SEATS = -1;
export const MAX_NUMBER_OF_SEATS = 10000;

export const PLAN_NAMES = {
  FREEMIUM: 'freemium',
  ESSENTIALS: 'essentials',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise',
};

export const getFormattedPlanName = ({ plan }) => {
  switch (plan) {
    case 'freemium':
      return 'Freemium';
    case 'essentials':
      return 'Essentials';
    case 'business':
      return 'Business';
    case 'enterprise':
      return 'Enterprise';
    default: return plan;
  }
};
