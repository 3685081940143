import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  fork,
  cancel,
} from 'redux-saga/effects';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';
import map from 'lodash/map';
import merge from 'lodash/merge';

export const generateQueryEntitySaga = ({
  apiWrapper,
  entity,
  prepare,
  request,
  mapper,
}) => function* query(action) {
  try {
    let params = {
      params: action.query.params,
      pagination: action.query.pagination,
      sort: action.query.sort,
    };

    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }

    const data = yield call(apiWrapper, { method: request, params });

    yield call(mapper, { action, data });

    if (action.pipe) {
      if (action.pipe.action) {
        yield put(action.pipe.action({ data, action }));
      }
      if (action.pipe.wait) {
        yield take(action.pipe.wait);
      }
    }

    yield put(entity.actions.querySuccess({
      name: action.query.name,
      result: data.result,
      count: data.count,
    }));
    if (action.pipe && action.pipe.onSuccess) {
      yield call(action.pipe.onSuccess, data);
    }
  } catch (error) {
    console.error('Error in query entity saga: \n', error);
    yield put(entity.actions.queryFail({ name: action.query.name, error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export const generateQueryReloadSaga = ({ entity }) => function* queryReload(action) {
  const query = yield select(entity.getQuerySelector, { name: action.query.name });

  yield put(entity.actions.queryStart({
    name: action.query.name,
    params: query.params,
    pagination: query.pagination,
    sort: query.sort,
  }));
};

export const generateQueryLoadMoreSaga = ({
  apiWrapper,
  entity,
  prepare,
  request,
  mapper,
}) => function* queryLoadMore(action) {
  try {
    const query = yield select(entity.getQuerySelector, { name: action.query.name });

    let params = {
      params: query.params,
      pagination: {
        limit: action.query.additionalResults,
        offset: query.pagination.offset + query.pagination.limit,
      },
      sort: query.sort,
    };

    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }

    const data = yield call(apiWrapper, { method: request, params });

    if (isEmpty(data.result)) {
      yield put(entity.actions.querySuccess({
        name: action.query.name,
        result: query.result,
        count: query.count,
      }));
    } else {
      yield call(mapper, { action, data });

      const pagination = {
        limit: query.pagination.limit + action.query.additionalResults,
        offset: query.pagination.offset,
      };

      yield put(entity.actions.querySuccess({
        name: action.query.name,
        result: concat([], query.result, data.result),
        count: data.count,
        pagination,
        sort: query.sort,
      }));
    }
  } catch (error) {
    console.error('Error in query load more saga: \n', error);
    yield put(entity.actions.queryFail({ name: action.query.name, error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export const generateFetchEntitySaga = ({
  apiWrapper,
  entity,
  prepare,
  request,
  mapper,
}) => function* fetch(action) {
  try {
    let params = { id: action.id, ...action.params };
    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }
    const data = yield call(apiWrapper, { method: request, params });

    yield call(mapper, { action, data });

    if (action.pipe) {
      if (action.pipe.action) {
        yield put(action.pipe.action({ data, action }));
      }
      if (action.pipe.wait) {
        yield take(action.pipe.wait);
      }
    }

    yield put(entity.actions.fetchSuccess({ id: action.id }));
    if (action.pipe && action.pipe.onSuccess) {
      yield call(action.pipe.onSuccess, data);
    }
  } catch (error) {
    console.error('Error in fetch entity saga: \n', error);
    yield put(entity.actions.fetchFail({ id: action.id, error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export const generateCreateEntitySaga = ({
  apiWrapper,
  entity,
  prepare,
  request,
  mapper,
}) => function* create(action) {
  try {
    let params = action.data;
    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }

    const data = yield call(apiWrapper, { method: request, params });

    if (mapper) {
      yield call(mapper, { action, data });
    }

    if (action.pipe) {
      if (action.pipe.action) {
        yield put(action.pipe.action({ data, action }));
      }
      if (action.pipe.wait) {
        yield take(action.pipe.wait);
      }
    }

    yield put(entity.actions.createSuccess({ result: data.result }));
    if (action.pipe && action.pipe.onSuccess) {
      yield call(action.pipe.onSuccess, data);
    }
  } catch (error) {
    console.error('Error in create entity saga: \n', error);
    yield put(entity.actions.createFail({ error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export const generateUpdateEntitySaga = ({
  apiWrapper,
  entity,
  prepare,
  request,
  mapper,
}) => function* update(action) {
  try {
    let params = { ...action.data, id: action.id };
    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }

    const data = yield call(apiWrapper, { method: request, params });

    if (mapper) {
      yield call(mapper, { action, data });
    }

    if (action.pipe) {
      if (action.pipe.action) {
        yield put(action.pipe.action({ data, action }));
      }
      if (action.pipe.wait) {
        yield take(action.pipe.wait);
      }
    }

    yield put(entity.actions.updateSuccess({ id: action.id }));
    if (action.pipe && action.pipe.onSuccess) {
      yield call(action.pipe.onSuccess, data);
    }
  } catch (error) {
    console.error('Error in update entity saga: \n', error);
    yield put(entity.actions.updateFail({ id: action.id, error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export const generateEntityRpcSaga = ({
  apiWrapper,
  entity,
  prepare,
  rpcName,
  request,
  mapper,
}) => function* rpc(action) {
  try {
    let params = { ...action.data, id: action.id };
    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }

    const data = yield call(apiWrapper, { method: request, params });

    if (mapper) {
      yield call(mapper, { action, data });
    }

    if (action.pipe) {
      if (action.pipe.action) {
        yield put(action.pipe.action({ data, action }));
      }
      if (action.pipe.wait) {
        yield take(action.pipe.wait);
      }
    }

    yield put(entity[`${rpcName}Success`]({ id: action.id }));
    if (action.pipe && action.pipe.onSuccess) {
      yield call(action.pipe.onSuccess, data);
    }
  } catch (error) {
    console.error(`Error in ${rpcName} RPC saga: \n`, error);
    yield put(entity[`${rpcName}Fail`]({ id: action.id, error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export const generateRemoveEntitySaga = ({
  apiWrapper,
  entity,
  prepare,
  request,
  mapper,
}) => function* remove(action) {
  try {
    let params = { ...action.data, id: action.id, name: action.name };
    if (prepare) {
      params = merge({}, params, yield call(prepare, { action }));
    }

    const data = yield call(apiWrapper, { method: request, params });

    if (mapper) {
      yield call(mapper, { action, data });
    }

    if (action.pipe) {
      if (action.pipe.action) {
        yield put(action.pipe.action({ data, action }));
      }
      if (action.pipe.wait) {
        yield take(action.pipe.wait);
      }
    }

    yield put(entity.actions.removeSuccess({ id: action.id, name: action.name }));
    if (action.pipe && action.pipe.onSuccess) {
      yield call(action.pipe.onSuccess, data);
    }
  } catch (error) {
    console.error('Error in remove entity saga: \n', error);
    yield put(entity.actions.removeFail({ id: action.id, error }));
    if (action.pipe?.onFailure) {
      yield call(action.pipe.onFailure, error);
    }
  }
};

export function* prepareRpcSaga({
  entity,
  apiWrapper,
  rpcName,
  rpc,
  sagaGenerator,
}) {
  yield takeLatest(
    entity[`${toUpper(snakeCase(rpcName))}`],
    sagaGenerator({
      entity,
      rpcName,
      apiWrapper,
      ...rpc,
    }),
  );
}

export function* generateExclusiveQuerySagas(querySaga, normalizedEntity) {
  const tasks = {};

  let action = yield take(normalizedEntity.actions.types.queryStart);
  tasks[action.query.name] = yield fork(querySaga, action);

  while (true) {
    action = yield take(normalizedEntity.actions.types.queryStart);
    const task = tasks[action.query.name];

    if (task && task.isRunning()) {
      yield cancel(task);
    }

    tasks[action.query.name] = yield fork(querySaga, action);
  }
}

const generateEntitySagas = ({ apiWrapper, normalizedEntity, mappers }) => {
  function* rootSaga() {
    if (mappers.query) {
      const querySaga = generateQueryEntitySaga({
        entity: normalizedEntity,
        apiWrapper,
        ...mappers.query,
      });

      yield fork(generateExclusiveQuerySagas, querySaga, normalizedEntity);

      yield takeEvery(
        normalizedEntity.actions.types.queryReload,
        generateQueryReloadSaga({ entity: normalizedEntity }),
      );
      yield takeEvery(
        normalizedEntity.actions.types.queryLoadMore,
        generateQueryLoadMoreSaga({ entity: normalizedEntity, apiWrapper, ...mappers.query }),
      );
    }

    if (mappers.fetch) {
      yield takeEvery(
        normalizedEntity.actions.types.fetchStart,
        generateFetchEntitySaga({ entity: normalizedEntity, apiWrapper, ...mappers.fetch }),
      );
    }

    if (mappers.create) {
      yield takeLatest(
        normalizedEntity.actions.types.createStart,
        generateCreateEntitySaga({ entity: normalizedEntity, apiWrapper, ...mappers.create }),
      );
    }

    if (mappers.update) {
      yield takeLatest(
        normalizedEntity.actions.types.updateStart,
        generateUpdateEntitySaga({ entity: normalizedEntity, apiWrapper, ...mappers.update }),
      );
    }

    if (mappers.remove) {
      yield takeLatest(
        normalizedEntity.actions.types.removeStart,
        generateRemoveEntitySaga({ entity: normalizedEntity, apiWrapper, ...mappers.remove }),
      );
    }

    if (mappers.rpcs) {
      yield all(map(mappers.rpcs, (rpc) => call(
        prepareRpcSaga,
        {
          entity: normalizedEntity,
          rpcName: rpc.name,
          apiWrapper,
          rpc: mappers.rpcs[rpc.name],
          sagaGenerator: generateEntityRpcSaga,
        },
      )));
    }
  }

  return rootSaga;
};

export default generateEntitySagas;
