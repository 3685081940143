// @flow

import React from 'react';

import Svg from 'components/svg';

const MoveToIcon = Svg((
  <>
    <g clipPath="url(#clip0_4119_183841)">
      <path
        d="M0.571533 13.4285V2.5714C0.571533 2.2683 0.691941 1.97761 0.906268 1.76328C1.1206 1.54895 1.41129 1.42854 1.71439 1.42854H5.93153C6.1926 1.42094 6.4484 1.50298 6.65634 1.66102C6.86428 1.81905 7.01182 2.04354 7.07439 2.29712L7.42868 3.71426H14.2858C14.5889 3.71426 14.8796 3.83467 15.0939 4.04899C15.3083 4.26332 15.4287 4.55401 15.4287 4.85712V13.4285C15.4287 13.7317 15.3083 14.0223 15.0939 14.2367C14.8796 14.451 14.5889 14.5714 14.2858 14.5714H1.71439C1.41129 14.5714 1.1206 14.451 0.906268 14.2367C0.691941 14.0223 0.571533 13.7317 0.571533 13.4285Z"
        stroke="currentColor"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.19995 9H11.2M11.2 9L9.19995 11M11.2 9L9.19995 7"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath
        id="clip0_4119_183841"
      >
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
), { viewBox: '0 0 16 16', fill: 'none' });

MoveToIcon.displayName = 'MoveToIcon';

export default MoveToIcon;
