import Svg from 'components/svg';

const HatRabbit = Svg((
  <>
    <path
      d="M53.4302 45.4727L59.7109 69.9994H20.8501L27.1384 45.4727"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      d="M18 45.4727H62.5684"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="square"
    />
    <path
      d="M24.2808 56.6133H56.2878"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      d="M42.7179 45.4726L39.2108 21.5189C38.6072 17.3929 36.6206 13.4579 32.4793 14.0616C28.338 14.6652 27.574 19.0127 28.1776 23.1387L31.4402 45.4726"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      d="M51.0615 45.4721L57.0824 29.9844"
      stroke="currentColor"
      strokeWidth="2.5"
    />
    <path
      d="M54.6907 27.4557C55.7186 29.1461 57.3328 30.3989 59.2254 30.9753C61.118 31.5517 63.1565 31.4112 64.9522 30.5807C67.5882 28.938 67.5424 25.9963 64.4555 23.8645C62.2703 22.3364 60.4594 21.0527 60.4594 21.0527H49.984C42.8552 39.2682 42.137 41.0409 42.137 41.209"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </>
), { viewBox: '0 0 84 84', fill: 'none' });

HatRabbit.displayName = 'HatRabbit';

export default HatRabbit;
