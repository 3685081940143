// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_19921');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.15 1.15013L1.14998 22.8501H5.64998V24.1501H-0.150024L-0.149999 -0.149858L12.9652 -0.149902C13.252 -0.149841 13.5362 -0.0932478 13.8012 0.0166432C14.0661 0.126483 14.3067 0.287417 14.5094 0.490256L20.5108 6.49172C20.7135 6.69456 20.8745 6.9355 20.9841 7.20048C21.0937 7.46537 21.1501 7.74925 21.15 8.03592V13.1501H19.85V8.03592C19.85 7.9199 19.8272 7.80471 19.7829 7.6975C19.7385 7.59036 19.6735 7.493 19.5916 7.41096L13.5901 1.40949C13.5081 1.32738 13.4104 1.26194 13.3032 1.21748C13.1961 1.17304 13.0812 1.15014 12.9652 1.1501L1.15 1.15013Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.65 0.350078V7.35008H20.65V8.65008H13.001L13 8.00008L12.35 8.00002V0.350078H13.65ZM13 8.00008L12.35 8.00002V8.65008H13.001L13 8.00008ZM7.51666 15.1834H8.93741C9.7198 15.1834 10.4701 15.4942 11.0234 16.0475C11.5766 16.6007 11.8874 17.351 11.8874 18.1334C11.8874 18.9158 11.5766 19.6662 11.0234 20.2194C10.4701 20.7726 9.7198 21.0834 8.93741 21.0834H8.81666V24.1501H7.51666V15.1834ZM8.82017 19.7834H8.93741C9.37502 19.7834 9.7947 19.6096 10.1041 19.3002C10.4136 18.9907 10.5874 18.571 10.5874 18.1334C10.5874 17.6958 10.4136 17.2761 10.1041 16.9667C9.7947 16.6573 9.37502 16.4834 8.93741 16.4834H8.81723L8.82017 19.7834ZM13.65 15.1834H14.3C15.2857 15.1834 16.2311 15.575 16.9281 16.272C17.6251 16.969 18.0167 17.9144 18.0167 18.9001V20.4334C18.0167 21.4192 17.6251 22.3645 16.9281 23.0615C16.2311 23.7585 15.2857 24.1501 14.3 24.1501H13.65V15.1834ZM14.95 16.5725V22.761C15.3468 22.6502 15.7124 22.4387 16.0088 22.1423C16.462 21.6891 16.7167 21.0744 16.7167 20.4334V18.9001C16.7167 18.2592 16.462 17.6445 16.0088 17.1913C15.7124 16.8948 15.3468 16.6833 14.95 16.5725ZM20.5157 15.1834V15.8334H19.8657V15.1834H20.5157ZM21.1657 16.4834H24.15V15.1834H20.5157V15.8334H19.8657V24.1501H21.1657V20.3188H23.15V19.0188H21.1657V16.4834Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>

    </>
  );
};

const PdfDocument = Svg(Fragment, { viewBox: '0 0 24 24', fill: 'none' });

PdfDocument.displayName = 'PdfDocument';

export default PdfDocument;
