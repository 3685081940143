// @flow

import * as React from 'react';

import NewCross from 'components/icons/new-cross';

type InnerProps = {
  [any]: any
}

type Props = {
  getStyles: () => void,
  innerProps: InnerProps,
}

const ClearIndicator = (props: Props) => {
  const {
    getStyles,
    innerProps: { ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      style={getStyles('clearIndicator', props)}
    >
      <div className="ReactSelect__clearIndicator">
        <NewCross height="10px" />
      </div>
    </div>
  );
};

export default ClearIndicator;
