import { isEmpty } from 'lodash';
import {
  get,
  getAPIPath,
  getUrlWithParams,
  post,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';

export const postLogin = ({
  email,
  password,
  isPersistent,
  securityCode,
  captchaResponseToken,
}) => (
  post({
    url: '/positions/login',
    body: {
      email,
      password,
      persistent: isPersistent,
      security_code: securityCode,
      captcha_response_token: captchaResponseToken,
    },
  }).then(extractResponseBodyAsJSON)
);

export const logout = () => (
  post({
    url: '/positions/logout',
  }).then(extractResponseBodyAsJSON)
);

export const getLoginMethod = ({ email }) => (
  get({
    url: '/positions/login_method',
    params: {
      email,
    },
  }).then(extractResponseBodyAsJSON)

);

export const getSSOLoginPath = (resource, email, isPersistent) => {
  const apiPath = getAPIPath(resource);
  // This block will "avoid" the flashing of login page
  let redirectUrl = window.location.origin;

  // If the url contains a search, for example a path to document
  // Redirect to the origin and path directly instead to the login page
  if (!isEmpty(window.location.search)) {
    const search = new URLSearchParams(window.location.search);
    const path = search.get('path');
    const decodedPath = decodeURIComponent(path);

    redirectUrl = `${window.location.origin}${decodedPath}`;
  }

  return getUrlWithParams(apiPath, {
    email,
    return_to: redirectUrl,
    persistent: isPersistent ? 1 : 0,
  });
};
