// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import { PasswordFieldWithEye } from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
  autoComplete?: string,
  noMinLengthValidation?: boolean,
  placeholder?: string,
  hideRequired?: boolean,
  onBlur?: (reactFinalFormOnBlur: () => void, event: React.FocusEvent) => void,
  displayError?: (meta: InputMeta) => boolean
};

export const PublicPassword = ({
  message,
  autoFocus,
  autoComplete,
  noMinLengthValidation,
  placeholder,
  hideRequired = false,
  onBlur,
  displayError,
}: Props) => (
  <Field
    autoFocus={autoFocus}
    autoComplete={autoComplete}
    name="password"
    component={PasswordFieldWithEye}
    label={message({
      id: 'Password',
      comment: 'Label for the password field on a public page.',
    })}
    placeholder={placeholder || message({
      id: 'Enter password',
      comment: 'Password placeholder on a public page.',
    })}
    minLength={noMinLengthValidation ? undefined : 12}
    maxLength={72}
    responsive
    hideRequired={hideRequired}
    onBlur={onBlur}
    displayError={displayError}
    required
  />
);

PublicPassword.defaultProps = {
  autoFocus: undefined,
  autoComplete: undefined,
  noMinLengthValidation: undefined,
  placeholder: undefined,
};

export default localize<Props>(PublicPassword);
