// @flow

import React from 'react';

import Svg from 'components/svg';

const MagnifyingGlass = Svg((
  <path
    fill="none"
    d="M12.1658 12.1648L17 17M1.10406 10.0176C1.8092 11.6767 3.14455 12.9878 4.81634 13.6623C6.48814 14.3369 8.35943 14.3197 10.0186 13.6146C11.6777 12.9094 12.9887 11.5741 13.6633 9.90229C14.3379 8.2305 14.3207 6.35921 13.6156 4.70008C13.2664 3.87856 12.7589 3.13386 12.1219 2.50849C11.4849 1.88312 10.7311 1.38934 9.90327 1.05533C9.07548 0.721313 8.19001 0.553616 7.29741 0.56181C6.40482 0.570003 5.52257 0.753927 4.70106 1.10308C3.87954 1.45223 3.13484 1.95977 2.50947 2.59673C1.8841 3.23368 1.39031 3.98758 1.0563 4.81537C0.72229 5.64315 0.554593 6.52862 0.562786 7.42122C0.57098 8.31382 0.754903 9.19606 1.10406 10.0176Z"
    stroke="currentColor"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), { viewBox: '0 0 18 18' });

MagnifyingGlass.displayName = 'MagnifyingGlass';

export default MagnifyingGlass;
