// @flow

import React from 'react';

import Svg from 'components/svg';

const Comment = Svg((
  <path
    d="M17.0879 59.6093L0.333044 63.3333L4.05704 46.5785C1.60456 41.9912 0.325212 36.8684 0.333044 31.6667C0.333044 14.1772 14.5102 0 31.9997 0C49.4892 0 63.6664 14.1772 63.6664 31.6667C63.6664 49.1562 49.4892 63.3333 31.9997 63.3333C26.798 63.3412 21.6752 62.0618 17.0879 59.6093V59.6093Z"
    fill="currentColor"
  />
), { viewBox: '0 0 64 64', fill: 'none' });

Comment.displayName = 'Comment';

export default Comment;
