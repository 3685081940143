// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

type MinLength = ({
  message: MessageTranslator,
  customMessage?: string,
  field: string,
  minLength: number,
}) => Validator;

const minLengthValidator: MinLength = ({
  message,
  customMessage,
  field,
  minLength,
}) => (value, _, meta) => {
  if (meta && !meta.dirty) {
    return undefined;
  }

  const text = customMessage || message({
    id: '{field} must contain at least 1 character',
    pluralId: '{field} must contain at least {minLength} characters',
    pluralCondition: 'minLength',
    values: {
      field,
      minLength,
    },
    comment: 'Used as the minLength validator message',
  });

  if (value && value.length && value.length < minLength) {
    return text;
  }

  return undefined;
};

export default minLengthValidator;
