// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  parse?: Function,
  message: MessageTranslator,
};

export const DataFieldKey = ({ message, parse }: Props) => (
  <Field
    name="key"
    label={message({
      id: 'Key',
      comment: 'Label for the key field for a data field.',
    })}
    component={TextField}
    disabled
    parse={parse}
  />
);

export default localize<Props>(DataFieldKey);
