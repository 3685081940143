import { components } from 'react-select';
import type { InputProps } from 'react-select';

const Input = (props: InputProps) => {
  const value = props.getValue();
  const selectedOptionValue = value[0]?.value || '';
  return <components.Input {...props} data-selected-option-value={selectedOptionValue} />;
};

export default Input;
