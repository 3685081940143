// @flow

import React from 'react';

import Svg from 'components/svg';

const Delete = Svg(({ pathColor = 'currentColor' }) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.99999 1.6501C6.56034 1.6501 6.1387 1.82475 5.82782 2.13562C5.56169 2.40175 5.3954 2.74905 5.35303 3.11933H8.64694C8.60457 2.74905 8.43828 2.40175 8.17215 2.13562C7.86127 1.82475 7.43963 1.6501 6.99999 1.6501ZM9.95167 3.11933C9.90601 2.40369 9.60148 1.72647 9.09139 1.21639C8.53671 0.661711 7.78441 0.350098 6.99999 0.350098C6.21556 0.350098 5.46326 0.661711 4.90858 1.21639C4.39849 1.72647 4.09396 2.40369 4.0483 3.11933H0.811523V4.41933H2.19614V12.077C2.19614 12.4942 2.36187 12.8943 2.65688 13.1894C2.95189 13.4844 3.35201 13.6501 3.76922 13.6501H10.2308C10.648 13.6501 11.0481 13.4844 11.3431 13.1894C11.6381 12.8943 11.8038 12.4942 11.8038 12.077V4.41933H13.1884V3.11933H9.95167ZM3.49614 4.41933V12.077C3.49614 12.1494 3.52491 12.2189 3.57612 12.2701C3.62733 12.3213 3.69679 12.3501 3.76922 12.3501H10.2308C10.3032 12.3501 10.3726 12.3213 10.4238 12.2701C10.4751 12.2189 10.5038 12.1494 10.5038 12.077V4.41933H3.49614ZM6.26537 4.96548V11.8039H4.96537V4.96548H6.26537ZM9.0346 4.96548V11.8039H7.7346V4.96548H9.0346Z"
    fill={pathColor}
  />
), { viewBox: '0 0 14 14', fill: 'none' });

Delete.displayName = 'Delete';

export default Delete;
