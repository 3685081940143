// @flow

import React from 'react';

import Svg from 'components/svg';

const NewArrowRight = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.19866 2.58105L11.9713 6.5523C12.2097 6.80318 12.2097 7.1968 11.9713 7.44768L8.19866 11.4189L7.25616 10.5236L9.98604 7.64999L1.3501 7.64999L1.3501 6.34999L9.98604 6.34999L7.25616 3.47643L8.19866 2.58105Z"
    fill="currentColor"
  />
), { viewBox: '0 0 14 14', fill: 'none' });

NewArrowRight.displayName = 'NewArrowRight';

export default NewArrowRight;
