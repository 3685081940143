// @flow

import React from 'react';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
};

export const WebhookSignKey = ({ message }: Props) => (
  <Field
    name="signKey"
    label={message({
      id: 'Sign key',
      comment: 'Sign key label for webhook.',
    })}
    placeholder={message({
      id: 'Enter sign key',
      comment: 'Sign key placeholder for webhook.',
    })}
    component={TextField}
    maxLength={50}
    responsive
  />
);

export default localize<Props>(WebhookSignKey);
