// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const filterId1 = uniqueId('filter0_d_127_3170');
  const filterId2 = uniqueId('filter1_d_127_3170');
  const filterId3 = uniqueId('filter2_d_127_3170');

  return (
    <>
      <g filter={`url(#${filterId1})`}>
        <path d="M53.3928 24.3108C51.7933 24.987 49.9911 25.0012 48.3812 24.3503C46.7713 23.6994 45.4854 22.4366 44.8053 20.8388L41.257 12.4679L14.3198 23.8864C12.2331 24.771 10.5832 26.4483 9.73313 28.5493C8.88307 30.6503 8.90249 33.0029 9.78704 35.0896L29.8924 82.5197C30.777 84.6065 32.4542 86.2564 34.5552 87.1064C36.6562 87.9565 39.0089 87.9371 41.0956 87.0525L73.433 73.3449C75.5198 72.4603 77.1696 70.7831 78.0196 68.6821C78.8697 66.5811 78.8504 64.2284 77.9658 62.1417L60.6292 21.2434L53.3928 24.3108Z" fill="white" />
        <path d="M60.6292 21.2436L53.3927 24.3111C51.7933 24.9872 49.991 25.0014 48.3812 24.3505C46.7713 23.6996 45.4853 22.4368 44.8053 20.839L41.2569 12.4681L60.6292 21.2436Z" fill="#E8ECEE" />
        <path d="M55.0113 35.5175L24.8259 48.3129C24.5391 48.4174 24.2232 48.4077 23.9433 48.286C23.6634 48.1643 23.441 47.9397 23.3219 47.6587C23.2028 47.3778 23.1961 47.0618 23.3032 46.776C23.4103 46.4902 23.6231 46.2565 23.8976 46.123L54.083 33.3275C54.2283 33.2569 54.3865 33.2165 54.5479 33.209C54.7092 33.2014 54.8705 33.2267 55.0218 33.2834C55.1731 33.3401 55.3112 33.427 55.4279 33.5388C55.5445 33.6506 55.6372 33.785 55.7002 33.9337C55.7633 34.0825 55.7954 34.2425 55.7947 34.4041C55.7939 34.5656 55.7603 34.7254 55.6958 34.8735C55.6314 35.0217 55.5375 35.1552 55.4198 35.2659C55.3021 35.3766 55.1631 35.4622 55.0113 35.5175Z" fill="#D6DFE2" />
        <path d="M57.7838 42.0582L27.5984 54.8537C27.3116 54.9581 26.9957 54.9485 26.7158 54.8268C26.4359 54.705 26.2135 54.4805 26.0944 54.1995C25.9753 53.9185 25.9686 53.6025 26.0757 53.3167C26.1828 53.0309 26.3956 52.7972 26.6701 52.6637L56.8555 39.8683C57.0008 39.7976 57.159 39.7573 57.3204 39.7497C57.4817 39.7421 57.643 39.7674 57.7943 39.8241C57.9455 39.8809 58.0837 39.9677 58.2004 40.0795C58.317 40.1914 58.4097 40.3257 58.4727 40.4745C58.5358 40.6232 58.5679 40.7832 58.5672 40.9448C58.5664 41.1064 58.5327 41.2661 58.4683 41.4143C58.4039 41.5624 58.3099 41.6959 58.1923 41.8066C58.0746 41.9173 57.9356 42.0029 57.7838 42.0582Z" fill="#CCD5D8" />
        <path d="M60.5564 48.5986L30.3709 61.3941C30.0842 61.4985 29.7682 61.4889 29.4884 61.3672C29.2085 61.2454 28.9861 61.0209 28.867 60.7399C28.7479 60.4589 28.7412 60.1429 28.8483 59.8571C28.9554 59.5713 29.1682 59.3376 29.4426 59.2041L59.6281 46.4087C59.7734 46.338 59.9316 46.2977 60.0929 46.2901C60.2543 46.2825 60.4156 46.3078 60.5668 46.3646C60.7181 46.4213 60.8563 46.5082 60.973 46.62C61.0896 46.7318 61.1822 46.8661 61.2453 47.0149C61.3083 47.1636 61.3405 47.3237 61.3397 47.4852C61.339 47.6468 61.3053 47.8065 61.2409 47.9547C61.1764 48.1028 61.0825 48.2363 60.9649 48.347C60.8472 48.4577 60.7082 48.5433 60.5564 48.5986Z" fill="#D6DFE2" />
        <path d="M51.4218 60.1833L33.1421 67.932C32.8553 68.0365 32.5394 68.0268 32.2595 67.9051C31.9796 67.7833 31.7572 67.5588 31.6381 67.2778C31.519 66.9968 31.5123 66.6808 31.6194 66.395C31.7265 66.1093 31.9393 65.8755 32.2138 65.7421L50.4935 57.9934C50.6388 57.9228 50.7969 57.8824 50.9583 57.8748C51.1197 57.8672 51.2809 57.8926 51.4322 57.9493C51.5835 58.006 51.7217 58.0929 51.8383 58.2047C51.9549 58.3165 52.0476 58.4509 52.1106 58.5996C52.1737 58.7484 52.2058 58.9084 52.2051 59.0699C52.2043 59.2315 52.1707 59.3912 52.1062 59.5394C52.0418 59.6875 51.9479 59.821 51.8302 59.9317C51.7125 60.0424 51.5736 60.128 51.4218 60.1833Z" fill="#CCD5D8" />
      </g>
      <g filter={`url(#${filterId2})`}>
        <path d="M70.059 30.2622C68.3332 30.4552 66.6015 29.9558 65.2434 28.8736C63.8854 27.7915 63.012 26.2149 62.8149 24.4896L61.7957 15.455L32.7228 18.7346C30.4706 18.9887 28.4116 20.127 26.9987 21.8992C25.5858 23.6714 24.9348 25.9322 25.1889 28.1844L30.9635 79.3752C31.2176 81.6274 32.3559 83.6864 34.128 85.0993C35.9002 86.5121 38.1611 87.1632 40.4133 86.9091L75.3147 82.972C77.5669 82.7179 79.6258 81.5796 81.0387 79.8074C82.4516 78.0353 83.1026 75.7744 82.8486 73.5222L77.8692 29.3811L70.059 30.2622Z" fill="white" />
        <path d="M77.8691 29.3813L70.0589 30.2623C68.3332 30.4553 66.6014 29.9559 65.2434 28.8737C63.8854 27.7916 63.012 26.215 62.8148 24.4897L61.7957 15.4551L77.8691 29.3813Z" fill="#E8ECEE" />
        <path d="M68.421 41.4663L35.8422 45.1414C35.5375 45.1599 35.2374 45.0607 35.0038 44.8644C34.7701 44.668 34.6208 44.3895 34.5866 44.0862C34.5524 43.7829 34.6359 43.4781 34.8199 43.2346C35.004 42.9912 35.2744 42.8276 35.5755 42.7778L68.1544 39.1027C68.3138 39.0763 68.4769 39.0827 68.6337 39.1213C68.7906 39.16 68.938 39.2302 69.0669 39.3276C69.1957 39.425 69.3035 39.5476 69.3835 39.688C69.4635 39.8284 69.514 39.9836 69.5321 40.1441C69.5503 40.3047 69.5355 40.4672 69.4888 40.6219C69.4421 40.7765 69.3644 40.9201 69.2604 41.0438C69.1565 41.1675 69.0285 41.2687 68.8842 41.3413C68.7398 41.414 68.5823 41.4565 68.421 41.4663Z" fill="#CCD5D8" />
        <path d="M69.2173 48.5251L36.6385 52.2002C36.3339 52.2187 36.0338 52.1196 35.8001 51.9232C35.5665 51.7269 35.4171 51.4483 35.3829 51.145C35.3487 50.8418 35.4322 50.5369 35.6163 50.2935C35.8003 50.05 36.0708 49.8865 36.3719 49.8366L68.9507 46.1615C69.1101 46.1352 69.2732 46.1415 69.4301 46.1802C69.5869 46.2188 69.7343 46.289 69.8632 46.3864C69.9921 46.4838 70.0998 46.6065 70.1798 46.7468C70.2598 46.8872 70.3104 47.0424 70.3285 47.203C70.3466 47.3635 70.3319 47.526 70.2851 47.6807C70.2384 47.8354 70.1607 47.9789 70.0568 48.1026C69.9528 48.2263 69.8248 48.3275 69.6805 48.4002C69.5362 48.4728 69.3786 48.5153 69.2173 48.5251Z" fill="#D6DFE2" />
        <path d="M70.0136 55.5846L37.4348 59.2597C37.1302 59.2782 36.8301 59.179 36.5964 58.9827C36.3628 58.7863 36.2134 58.5078 36.1792 58.2045C36.145 57.9012 36.2286 57.5964 36.4126 57.3529C36.5966 57.1094 36.8671 56.9459 37.1682 56.8961L69.747 53.221C69.9064 53.1946 70.0695 53.201 70.2264 53.2396C70.3833 53.2783 70.5306 53.3485 70.6595 53.4459C70.7884 53.5433 70.8961 53.6659 70.9761 53.8063C71.0561 53.9467 71.1067 54.1019 71.1248 54.2624C71.1429 54.4229 71.1282 54.5855 71.0815 54.7402C71.0348 54.8948 70.957 55.0384 70.8531 55.1621C70.7492 55.2857 70.6211 55.387 70.4768 55.4596C70.3325 55.5323 70.1749 55.5748 70.0136 55.5846Z" fill="#CCD5D8" />
        <path d="M57.9598 64.09L38.2307 66.3155C37.9261 66.334 37.626 66.2349 37.3924 66.0385C37.1587 65.8422 37.0094 65.5636 36.9752 65.2603C36.941 64.9571 37.0245 64.6522 37.2085 64.4087C37.3925 64.1653 37.663 64.0018 37.9641 63.9519L57.6932 61.7264C57.8526 61.7 58.0157 61.7063 58.1726 61.745C58.3294 61.7837 58.4768 61.8538 58.6057 61.9513C58.7346 62.0487 58.8423 62.1713 58.9223 62.3117C59.0023 62.4521 59.0529 62.6072 59.071 62.7678C59.0891 62.9283 59.0744 63.0909 59.0276 63.2455C58.9809 63.4002 58.9032 63.5438 58.7993 63.6674C58.6953 63.7911 58.5673 63.8924 58.423 63.965C58.2787 64.0376 58.1211 64.0802 57.9598 64.09Z" fill="#D6DFE2" />
      </g>
      <g filter={`url(#${filterId3})`}>
        <path d="M84.1863 40.7757C82.4768 40.471 80.9579 39.5007 79.9628 38.0777C78.9677 36.6546 78.5777 34.895 78.8782 33.1847L80.4649 24.2323L51.6564 19.1266C49.4248 18.7311 47.1274 19.2383 45.2697 20.5366C43.412 21.835 42.1461 23.8182 41.7506 26.0498L32.7606 76.7748C32.3651 79.0065 32.8723 81.3039 34.1707 83.1616C35.469 85.0193 37.4521 86.2852 39.6838 86.6807L74.2677 92.81C76.4993 93.2055 78.7968 92.6983 80.6545 91.4C82.5122 90.1016 83.778 88.1184 84.1735 85.8867L91.9254 42.1473L84.1863 40.7757Z" fill="white" />
        <path d="M91.9254 42.1474L84.1863 40.7757C82.4767 40.4711 80.9579 39.5008 79.9628 38.0777C78.9677 36.6547 78.5776 34.895 78.8782 33.1847L80.4648 24.2324L91.9254 42.1474Z" fill="#E8ECEE" />
        <path d="M75.7176 72.0358L43.4353 66.3144C43.1379 66.2457 42.8783 66.0654 42.71 65.8108C42.5416 65.5563 42.4774 65.2468 42.5307 64.9462C42.5839 64.6457 42.7506 64.3771 42.9962 64.1959C43.2417 64.0147 43.5475 63.9346 43.8504 63.9723L76.1327 69.6937C76.2931 69.7137 76.4477 69.766 76.5871 69.8476C76.7266 69.9292 76.8479 70.0383 76.9439 70.1683C77.0398 70.2983 77.1083 70.4464 77.1451 70.6038C77.182 70.7611 77.1865 70.9242 77.1583 71.0833C77.1301 71.2424 77.0698 71.3941 76.9811 71.5291C76.8924 71.6642 76.7773 71.7798 76.6425 71.8689C76.5077 71.958 76.3562 72.0187 76.1972 72.0474C76.0382 72.0761 75.8751 72.0722 75.7176 72.0358Z" fill="#D6DFE2" />
        <path d="M78.058 58.8308L45.7756 53.1094C45.4783 53.0407 45.2186 52.8604 45.0503 52.6058C44.882 52.3512 44.8178 52.0417 44.871 51.7412C44.9243 51.4407 45.091 51.1721 45.3365 50.9909C45.5821 50.8097 45.8879 50.7296 46.1907 50.7673L78.4731 56.4887C78.6334 56.5087 78.788 56.561 78.9275 56.6426C79.0669 56.7242 79.1883 56.8333 79.2842 56.9633C79.3802 57.0933 79.4486 57.2414 79.4855 57.3987C79.5224 57.556 79.5269 57.7192 79.4987 57.8783C79.4705 58.0374 79.4102 58.189 79.3215 58.3241C79.2328 58.4592 79.1176 58.5748 78.9829 58.6639C78.8481 58.753 78.6966 58.8137 78.5376 58.8424C78.3786 58.8711 78.2154 58.8671 78.058 58.8308Z" fill="#D6DFE2" />
        <path d="M76.8875 65.4351L44.6051 59.7136C44.3078 59.6449 44.0481 59.4647 43.8798 59.2101C43.7115 58.9555 43.6473 58.646 43.7005 58.3455C43.7538 58.045 43.9205 57.7764 44.166 57.5952C44.4116 57.4139 44.7174 57.3339 45.0202 57.3716L77.3026 63.093C77.4629 63.1129 77.6175 63.1653 77.757 63.2469C77.8964 63.3285 78.0178 63.4376 78.1137 63.5676C78.2097 63.6976 78.2781 63.8457 78.315 64.003C78.3519 64.1603 78.3564 64.3235 78.3282 64.4826C78.3 64.6417 78.2397 64.7933 78.151 64.9284C78.0623 65.0634 77.9471 65.179 77.8123 65.2681C77.6776 65.3572 77.5261 65.418 77.3671 65.4467C77.2081 65.4754 77.0449 65.4714 76.8875 65.4351Z" fill="#CCD5D8" />
        <path d="M66.4956 49.9697L46.9461 46.505C46.6488 46.4363 46.3891 46.256 46.2208 46.0015C46.0525 45.7469 45.9883 45.4374 46.0415 45.1369C46.0948 44.8363 46.2615 44.5678 46.507 44.3865C46.7526 44.2053 47.0584 44.1252 47.3612 44.1629L66.9107 47.6277C67.2081 47.6964 67.4678 47.8766 67.6361 48.1312C67.8044 48.3858 67.8685 48.6953 67.8153 48.9958C67.762 49.2963 67.5954 49.5649 67.3499 49.7462C67.1043 49.9274 66.7985 50.0074 66.4956 49.9697Z" fill="#CCD5D8" />
        <path d="M67.666 43.366L48.1165 39.9013C47.8191 39.8326 47.5595 39.6523 47.3912 39.3978C47.2229 39.1432 47.1586 38.8337 47.2119 38.5332C47.2652 38.2326 47.4318 37.9641 47.6774 37.7828C47.9229 37.6016 48.2287 37.5215 48.5316 37.5592L68.081 41.024C68.3784 41.0927 68.6381 41.2729 68.8064 41.5275C68.9747 41.7821 69.0389 42.0916 68.9856 42.3921C68.9324 42.6926 68.7658 42.9612 68.5202 43.1425C68.2747 43.3237 67.9688 43.4037 67.666 43.366Z" fill="#D6DFE2" />
        <path d="M80.2418 43.1789L74.7134 42.1991C73.7497 42.0283 72.8301 42.6711 72.6593 43.6347L71.6795 49.1631C71.5087 50.1268 72.1515 51.0464 73.1151 51.2172L78.6435 52.197C79.6071 52.3678 80.5267 51.725 80.6975 50.7614L81.6773 45.233C81.8481 44.2693 81.2054 43.3497 80.2418 43.1789Z" fill="#99B0B7" />
        <path d="M43.1727 77.0879C44.947 76.5689 46.5683 75.6524 48.2876 74.982C49.3362 74.5731 48.7061 75.0451 48.2308 75.6184C47.7811 76.1609 46.9971 76.9775 46.9449 77.7326C46.9265 78 47.5062 77.696 47.7265 77.5432C48.8307 76.7772 49.8583 75.9062 50.9484 75.1209C51.0964 75.0142 51.6624 74.5064 51.919 74.6051C52.2796 74.744 51.6984 76.4318 51.6435 76.6482C51.0818 78.8589 53.7689 75.8914 54.443 76.1691C54.6984 76.2742 54.3074 77.9438 54.3846 78.2207C55.0148 80.482 57.6543 81.4675 59.5 81.0001C61.5 80.4936 62.5 78.5001 62.5 78.5001" stroke="#187BB7" strokeWidth="1.59141" strokeLinecap="round" fill="none" />
      </g>
      <defs>
        <filter id={filterId1} x="2.00592" y="3.98548" width="83.741" height="92.436" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.58783" />
          <feGaussianBlur stdDeviation="2.22296" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_3170" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_3170" result="shape" />
        </filter>
        <filter id={filterId2} x="19.785" y="10.9815" width="68.4674" height="82.9193" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.58783" />
          <feGaussianBlur stdDeviation="2.22296" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_3170" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_3170" result="shape" />
        </filter>
        <filter id={filterId3} x="26.8234" y="14.7772" width="72.2774" height="85.5579" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.58783" />
          <feGaussianBlur stdDeviation="2.22296" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_3170" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_3170" result="shape" />
        </filter>
      </defs>
    </>
  );
};

const Documents = Svg(Fragment,
  { viewBox: '0 0 106 104' });

Documents.displayName = 'Documents';

export default Documents;
