// @flow

import map from 'lodash/map';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';

export const messageTemplateTypes = (message: any) => ({
  publish: message({
    id: 'Document invitation',
    comment: 'Used as the text of the document invitation',
  }),
  reminder: message({
    id: 'Document reminder',
    comment: 'Used as the text of the document reminder',
  }),
});

export const getMessageTemplateType = (type: string, message: MessageTranslator) => (
  messageTemplateTypes(message)[type]
);

export const getMessageTemplateTypesAsOptions = (message: MessageTranslator) => (
  map(messageTemplateTypes(message), (type, key: string) => ({
    label: type,
    value: key,
  }))
);

type Props = {
  type: 'publish' | 'reminder';
  message: MessageTranslator;
}

const MessageTemplateType = (props: Props) => (
  getMessageTemplateType(props.type, props.message)
);

export default localize<Props>(MessageTemplateType);
