import { call, fork } from 'redux-saga/effects';

import login from 'public/sagas/login';
import bootup from 'public/sagas/bootup';
import positionMfa from 'sagas/position-mfa';
import agreements from 'sagas/agreements';

export function createRootSaga({ isRTLEnv = false } = {}) {
  return function* root() {
    yield fork(login);
    yield fork(positionMfa);
    yield fork(agreements);

    if (!isRTLEnv) {
      yield call(bootup);
    }
  };
}
