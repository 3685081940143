// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_30_1393');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M5.00006 2.66667L5.00006 17.375M5.00006 17.375L1.37506 13.7803M5.00006 17.375L8.62506 13.7803" stroke="#013A4C" strokeWidth="1.3" strokeLinecap="square" strokeLinejoin="round" />
        <rect x="10" y="3.33331" width="9.67" height="1.3" fill="#013A4C" />
        <rect x="10" y="7.33337" width="9.67" height="1.3" fill="#013A4C" />
        <rect x="12.6666" y="11.3334" width="7" height="1.3" fill="#013A4C" />
        <rect x="12.6666" y="15.3333" width="7" height="1.3" fill="#013A4C" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const SortDescending = Svg(Fragment, { viewBox: '0 0 18 18' });

SortDescending.displayName = 'SortDescending';

export default SortDescending;
