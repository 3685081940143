// @flow

import React from 'react';

import Svg from 'components/svg';

const AddRowAbove = Svg((
  <>
    <rect x="19" y="14" width="4" height="14" transform="rotate(90 19 14)" stroke="currentColor" strokeWidth="2" />
    <rect x="15" y="9" width="6" height="2" transform="rotate(-180 15 9)" fill="currentColor" />
    <rect x="13" y="5" width="6" height="2" transform="rotate(90 13 5)" fill="currentColor" />
  </>
), {
  viewBox: '0 0 24 24',
  fill: 'none',
});

AddRowAbove.displayName = 'AddRowAbove';

export default AddRowAbove;
