import Svg from 'components/svg';

const ChatSupport = Svg((
  <>
    <path d="M31.0157 35.7729H35.2457V42.1329L41.6143 35.7771H45.8571V14.5714H24.6557V23.0486" stroke="white" strokeWidth="1.42286" strokeLinecap="square" />
    <path d="M22.5343 36.8314C23.4777 36.8314 24.3999 36.5517 25.1844 36.0275C25.9688 35.5034 26.5802 34.7584 26.9412 33.8868C27.3022 33.0152 27.3967 32.0561 27.2126 31.1308C27.0286 30.2056 26.5743 29.3556 25.9072 28.6885C25.2401 28.0214 24.3902 27.5671 23.4649 27.3831C22.5396 27.199 21.5805 27.2935 20.7089 27.6545C19.8373 28.0156 19.0923 28.6269 18.5682 29.4114C18.044 30.1958 17.7643 31.118 17.7643 32.0614C17.7643 33.3265 18.2668 34.5398 19.1614 35.4343C20.0559 36.3289 21.2692 36.8314 22.5343 36.8314Z" stroke="white" strokeWidth="1.42286" strokeLinecap="square" />
    <path d="M31.0157 46.3757C30.7182 44.3387 29.6974 42.4768 28.1401 41.1304C26.5828 39.7841 24.5929 39.0431 22.5343 39.0431C20.4757 39.0431 18.4858 39.7841 16.9285 41.1304C15.3711 42.4768 14.3504 44.3387 14.0529 46.3757" stroke="white" strokeWidth="1.42286" strokeLinecap="square" />
    <path d="M35.2586 30.0986L30.6386 25.2857C30.2331 24.8793 29.9653 24.3559 29.873 23.7892C29.7808 23.2225 29.8687 22.6412 30.1243 22.1271C30.3198 21.7411 30.6041 21.4068 30.9538 21.1518C31.3035 20.8968 31.7086 20.7283 32.136 20.6602C32.5634 20.592 33.0009 20.6261 33.4125 20.7597C33.8242 20.8932 34.1983 21.1225 34.5043 21.4286L35.2586 22.1829L36 21.4286C36.3068 21.1229 36.6818 20.8944 37.0941 20.7618C37.5063 20.6292 37.9442 20.5963 38.3717 20.6657C38.7992 20.7352 39.2041 20.905 39.5532 21.1613C39.9023 21.4175 40.1857 21.753 40.38 22.14C40.6349 22.6511 40.7236 23.2292 40.6337 23.7933C40.5438 24.3573 40.2798 24.8792 39.8786 25.2857L35.2586 30.0986Z" stroke="white" strokeWidth="1.42286" strokeLinecap="square" />
  </>
), { viewBox: '0 0 60 60', fill: 'none' });

ChatSupport.displayName = 'ChatSupport';

export default ChatSupport;
