import { call, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { getCurrentTokenSelector } from 'reducers/app';
import { getPositionFromSessionSelector } from 'reducers/session';

import {
  ServerError,
  APIError,
} from 'oneflow-client';
import log from 'logging';
import { checkIfAuthenticated } from './authentication';

const ignoreRedirectAPIErrorCodes = [1003, 4108, 1013];
const ignoreRollbarAPIErrorCodes = [96, 4996];

export default function* apiWrapper({ method, params }) {
  try {
    const token = yield select(getCurrentTokenSelector);

    return yield call(method, params, { token });
  } catch (error) {
    if (error instanceof ServerError) {
      const position = yield select(getPositionFromSessionSelector);
      const positionId = position?.id;
      if (positionId !== 981084) {
        yield call(log.error, `SERVER ERROR: ${error.message}`, error);
      }
    } else if (error instanceof APIError) {
      const apiErrorCode = get(error, 'body.api_error_code');

      if (!ignoreRedirectAPIErrorCodes.includes(apiErrorCode)) {
        yield call(checkIfAuthenticated, error);
      }

      if (!ignoreRollbarAPIErrorCodes.includes(apiErrorCode)) {
        yield call(log.error, `API ERROR: ${error.body.error}`, error);
      }
    } else if (!(error instanceof TypeError)) {
      yield call(log.error, `OTHER ERROR: ${error.message}`, error);
    }

    throw error;
  }
}
