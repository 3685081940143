// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import map from 'lodash/map';

import {
  SIGN_METHOD_ESIGN,
  SIGN_METHOD_SMS,
  SIGN_METHOD_SE_BANKID,
  SIGN_METHOD_NO_BANKID_SIGN,
  SIGN_METHOD_DK_MITID_NEMID_SIGN,
  SIGN_METHOD_FI_FTN_SIGN,
  SIGN_METHOD_HANDWRITTEN_SIGN,
  SIGN_METHOD_EID_SIGN,
} from 'agreement/participant/constants';

import Tooltip from 'components/tooltip';

export const signMethods = (
  message: MessageTranslator,
) => {
  const availableSignMethods = {
    [SIGN_METHOD_ESIGN]: {
      label: message({
        id: 'Standard e-signature',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_ESIGN,
    },
    [SIGN_METHOD_SMS]: {
      label: message({
        id: 'SMS verification',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_SMS,
    },
    [SIGN_METHOD_SE_BANKID]: {
      label: message({
        id: 'Swedish BankID',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_SE_BANKID,
    },
    [SIGN_METHOD_NO_BANKID_SIGN]: {
      label: message({
        id: 'Norwegian BankID',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_NO_BANKID_SIGN,
    },
    [SIGN_METHOD_DK_MITID_NEMID_SIGN]: {
      label: message({
        id: 'Danish MitID',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_DK_MITID_NEMID_SIGN,
    },
    [SIGN_METHOD_FI_FTN_SIGN]: {
      label: message({
        id: 'Finnish FTN',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_FI_FTN_SIGN,
    },
    [SIGN_METHOD_HANDWRITTEN_SIGN]: {
      label: message({
        id: 'Handwritten signature',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_HANDWRITTEN_SIGN,
    },
    [SIGN_METHOD_EID_SIGN]: {
      label: message({
        id: 'Electronic ID Hub',
        comment: 'Sign methods',
      }),
      value: SIGN_METHOD_EID_SIGN,
    },
  };

  return availableSignMethods;
};

export const getSignMethod = (
  signMethod?: any,
  message: MessageTranslator,
) => (
  signMethods(message)[signMethod]
);

export const getSignMethodsAsOptions = (
  message: MessageTranslator,
) => (
  map(signMethods(message), (item) => ({
    label: item.label,
    value: item.value,
  }))
);
type Props = {
  signMethod?: SignMethodType,
  withTooltip?: boolean,
  message: MessageTranslator,
};

export class SignMethodComponent extends React.PureComponent<Props> {
  static defaultProps = {
    signMethod: undefined,
    withTooltip: false,
  };

  renderSignMethodWithTooltip() {
    const {
      signMethod,
      message,
    } = this.props;

    const role = getSignMethod(signMethod, message);

    return (
      <Tooltip
        message={role?.description}
        side="top"
      >
        <span>{role?.label}</span>
      </Tooltip>
    );
  }

  render() {
    const {
      signMethod,
      message,
      withTooltip,
    } = this.props;

    const role = getSignMethod(signMethod, message);

    if (!role) {
      return null;
    }

    if (withTooltip) {
      return this.renderSignMethodWithTooltip();
    }

    return role.label;
  }
}

export default localize<Props>(SignMethodComponent);
