// @flow

import React from 'react';

import Svg from 'components/svg';

const Warning = Svg(
  <>
    <path d="M7.5 4.49988V9.49988H6.5V4.49988H7.5Z" fill="currentColor" />
    <path
      d="M6.25 10.9999C6.25 11.1988 6.32902 11.3896 6.46967 11.5302C6.61032 11.6709 6.80109 11.7499 7 11.7499C7.19891 11.7499 7.38968 11.6709 7.53033 11.5302C7.67098 11.3896 7.75 11.1988 7.75 10.9999C7.75 10.801 7.67098 10.6102 7.53033 10.4695C7.38968 10.3289 7.19891 10.2499 7 10.2499C6.80109 10.2499 6.61032 10.3289 6.46967 10.4695C6.32902 10.6102 6.25 10.801 6.25 10.9999Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99995 0.000976562C6.72163 0.000976562 6.44879 0.0784131 6.21197 0.224624C5.97514 0.370835 5.78366 0.580054 5.65895 0.828876L0.15874 11.8293C0.0444927 12.0579 -0.00949337 12.3121 0.00200642 12.5674C0.0135062 12.8227 0.0900569 13.0709 0.224395 13.2883C0.358732 13.5058 0.546401 13.6853 0.769594 13.8098C0.992787 13.9343 1.2441 13.9998 1.49969 13.9999H12.5C12.7555 13.9998 13.0071 13.9343 13.2303 13.8098C13.4535 13.6853 13.6412 13.5058 13.7755 13.2883C13.9099 13.0709 13.9864 12.8227 13.9979 12.5674C14.0094 12.3121 13.9555 12.058 13.8412 11.8294L8.34117 0.829303C8.21646 0.58048 8.02477 0.370835 7.78794 0.224624C7.55111 0.0784131 7.27828 0.000976562 6.99995 0.000976562ZM6.73729 1.07553C6.81623 1.02679 6.90718 1.00098 6.99995 1.00098C7.09273 1.00098 7.18367 1.02679 7.26262 1.07553C7.3415 1.12423 7.40529 1.1939 7.44686 1.27676L12.9467 12.2764C12.9847 12.3526 13.0027 12.4373 12.9989 12.5224C12.9951 12.6075 12.9696 12.6902 12.9248 12.7627C12.88 12.8352 12.8174 12.895 12.7431 12.9365C12.6687 12.9781 12.5849 12.9999 12.4997 12.9999H1.50022C1.41503 12.9999 1.33125 12.9781 1.25686 12.9365C1.18246 12.895 1.1199 12.8352 1.07512 12.7627C1.03034 12.6902 1.00483 12.6075 1.00099 12.5224C0.997162 12.4373 1.01512 12.3527 1.05317 12.2765L6.55295 1.27694C6.59453 1.19409 6.65841 1.12422 6.73729 1.07553Z"
      fill="currentColor"
    />
  </>,
  { viewBox: '0 0 14 14', fill: 'none' },
);

Warning.displayName = 'Warning';

export default Warning;
