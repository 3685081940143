// @flow

import React from 'react';

import Svg from 'components/svg';

const Contract = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M8.64998 2.1501V11.8346C8.8425 11.9359 9.0205 12.0662 9.17716 12.2229C9.58661 12.6324 9.81664 13.1877 9.81664 13.7667V14.6501H18.3666C18.9457 14.6501 19.501 14.8801 19.9105 15.2896C20.3199 15.699 20.55 16.2544 20.55 16.8334V20.8501H24.35V5.76934L20.7307 2.1501L8.64998 2.1501ZM20.55 22.1501H25.65V5.23086L21.2692 0.850098L7.34998 0.850106V11.5834H3.03331C2.45425 11.5834 1.89891 11.8134 1.48946 12.2229C1.08 12.6324 0.849976 13.1877 0.849976 13.7667V22.9667C0.849976 23.5458 1.08001 24.1011 1.48946 24.5106C1.89891 24.92 2.45425 25.1501 3.03331 25.1501H18.3666C18.9457 25.1501 19.501 24.92 19.9105 24.5106C20.3199 24.1011 20.55 23.5458 20.55 22.9667V22.1501ZM7.34998 12.8834H3.03331C2.79903 12.8834 2.57435 12.9765 2.4087 13.1421C2.24304 13.3078 2.14998 13.5325 2.14998 13.7667V22.9667C2.14998 23.201 2.24304 23.4257 2.4087 23.5914C2.57436 23.757 2.79904 23.8501 3.03331 23.8501H18.3666C18.6009 23.8501 18.8256 23.757 18.9913 23.5914C19.1569 23.4257 19.25 23.201 19.25 22.9667V16.8334C19.25 16.5991 19.1569 16.3745 18.9913 16.2088C18.8256 16.0431 18.6009 15.9501 18.3666 15.9501H8.51664V13.7667C8.51664 13.5325 8.42358 13.3078 8.25792 13.1421C8.09337 12.9776 7.87058 12.8847 7.63799 12.8834L7.34998 12.8834ZM10.85 5.85008H21.15V7.15008H10.85V5.85008ZM10.85 9.85008H21.15V11.1501H10.85V9.85008Z"
  />
), { viewBox: '0 0 26 26', fill: 'none' });

Contract.displayName = 'Contract';

export default Contract;
