/* eslint-disable react/prop-types */

import * as React from 'react';
import clsx from 'clsx';

import useWindowSize from 'hooks/use-window-size';

import { LogoOneflow } from 'components/logo';
import SignupLink from 'components/signup-link';

import style from './public-wrapper.module.scss';

const Wrapper = ({
  children,
  hasSignupLink = false,
  centered = undefined,
  hasPersonalIdentificationMFA = undefined,
  noBackgroundColor,
}) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 840;

  const publicWrapperClasses = clsx(style.Wrapper, {
    [style.Centered]: centered,
  });

  const publicContainerClasses = clsx(style.Container, {
    [style.ContainerSignupFlow]: noBackgroundColor,
  });

  const publicContentClasses = clsx(style.Wrapper, {
    [style.Content]: !hasPersonalIdentificationMFA,
    [style.ContentPersonalId]: hasPersonalIdentificationMFA,
    [style.ContentSignupFlow]: isMobile && noBackgroundColor,
  });

  const getRotatingBackgroundClass = () => {
    const interval = new Date().getHours();

    const classes = [
      style.BackgroundPink1,
      style.BackgroundBlue1,
      style.BackgroundPink2,
      style.BackgroundBlue2,
      style.BackgroundPink3,
      style.BackgroundBlue3,
    ];

    const currentClassIndex = interval % classes.length;
    const chosenClass = classes[currentClassIndex];

    return clsx(style.Background, {
      [chosenClass]: true,
    });
  };

  const renderSignupLink = () => {
    if (!hasSignupLink) {
      return null;
    }

    return (
      <div className={style.SignupLink}>
        <SignupLink />
      </div>
    );
  };

  return (
    <div className={publicWrapperClasses}>
      <div className={getRotatingBackgroundClass()} />
      <div className={style.RightColumn}>
        <div className={publicContainerClasses}>
          <div className={style.Logo}>
            <LogoOneflow width="200px" height="45px" isDark />
          </div>
          <div className={publicContentClasses}>
            {children}
          </div>
          {renderSignupLink()}
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
