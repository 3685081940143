import Svg from 'components/svg';

const DeleteThin = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M36.9994 8.72153C34.6755 8.72153 32.4469 9.64467 30.8036 11.2879C29.397 12.6946 28.518 14.5303 28.2941 16.4875H45.7047C45.4808 14.5303 44.6018 12.6946 43.1951 11.2879C41.5519 9.64467 39.3232 8.72153 36.9994 8.72153ZM52.6012 16.4875C52.3598 12.7048 50.7501 9.12522 48.0539 6.42905C45.1221 3.4972 41.1456 1.8501 36.9994 1.8501C32.8531 1.8501 28.8767 3.4972 25.9448 6.42905C23.2486 9.12522 21.639 12.7048 21.3976 16.4875H4.28894V23.3589H11.6076V63.8353C11.6076 66.0405 12.4836 68.1554 14.043 69.7147C15.6023 71.2741 17.7172 72.1501 19.9225 72.1501H54.0763C56.2815 72.1501 58.3964 71.2741 59.9558 69.7147C61.5151 68.1554 62.3911 66.0405 62.3911 63.8353V23.3589H69.7098V16.4875H52.6012ZM18.4791 23.3589V63.8353C18.4791 64.2181 18.6311 64.5852 18.9018 64.8559C19.1725 65.1266 19.5396 65.2787 19.9225 65.2787H54.0763C54.4591 65.2787 54.8263 65.1266 55.0969 64.8559C55.3676 64.5852 55.5197 64.2181 55.5197 63.8353V23.3589H18.4791ZM33.1164 26.2457V62.3918H26.245V26.2457H33.1164ZM47.7538 26.2457V62.3918H40.8823V26.2457H47.7538Z"
    fill="#013A4C"
    stroke="white"
    strokeWidth="1.5"
    strokeLinecap="square"
    strokeLinejoin="round"
  />
), {
  viewBox: '0 0 74 74',
  fill: 'none',
});

DeleteThin.displayName = 'DeleteThin';

export default DeleteThin;
