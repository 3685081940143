import jsonLogic from 'json-logic-js';
import get from 'lodash/get';
import { isChecked, isUnchecked } from 'utils/checkbox';
import type { Box } from 'data-validators/entity-schemas/document-box';
import {
  BOX_PDF,
  BOX_ATTACHMENTS,
  BOX_FORM,
  BOX_PRODUCT_SUMMATION,
  BOX_PRODUCT_TABLE,
  BOX_VIDEO,
  BOX_TEXT_AND_IMAGE,
} from 'agreement/constants';
import { checkAcl } from 'components/acl';

const enabledBoxTypesSectionRules = [
  BOX_ATTACHMENTS,
  BOX_FORM,
  BOX_PDF,
  BOX_PRODUCT_SUMMATION,
  BOX_PRODUCT_TABLE,
  BOX_VIDEO,
  BOX_TEXT_AND_IMAGE,
];

export const getSectionRule = (box: Box) => get(box, 'config.visibility.rule');

export const shouldRenderSectionRules = (box: Box, agreement: Oneflow.Agreement) => {
  if (!box) {
    return false;
  }

  if (!enabledBoxTypesSectionRules.includes(box.type)) {
    return false;
  }

  const isBoxNew = !box.id && box._id;
  if (isBoxNew) {
    return checkAcl(agreement.acl, 'agreement:box:section_rule_management');
  }

  if (checkAcl(box.acl, 'agreementbox:config:section_rules:update')) {
    return true;
  }

  return checkAcl(box.acl, 'agreementbox:config:section_rules:remove') && getSectionRule(box);
};

export const getExternalKeyFromRule = (rule: any) => {
  const condition = Object.keys(rule)[0];
  const dataFieldString = rule[condition][0].var;
  const regEx = /data\.data_fields\./g;
  const dataFieldExternalKey = dataFieldString.split(regEx)[1];

  return dataFieldExternalKey;
};

export const getVisibility = (rule: any, dataFieldExternalKeyValueMap: any) => {
  if (!rule) {
    return true;
  }

  const context = {
    data: {
      data_fields: {
        ...dataFieldExternalKeyValueMap,
      },
    },
  };

  jsonLogic.add_operation('isChecked', isChecked);
  jsonLogic.add_operation('isUnchecked', isUnchecked);
  jsonLogic.add_operation('is_checked', isChecked);
  jsonLogic.add_operation('is_unchecked', isUnchecked);

  return jsonLogic.apply(rule, context);
};

export const getIsBoxRestricted = (isGuest: boolean, hasBrokenOrHiddenSectionRule: boolean) => (
  isGuest && hasBrokenOrHiddenSectionRule
);

export const checkIfRuleDataFieldExists = (rule: any, dataFieldExternalKeyMap: any) => {
  if (!dataFieldExternalKeyMap) {
    return false;
  }

  const externalKey = getExternalKeyFromRule(rule);

  return Boolean(dataFieldExternalKeyMap[externalKey] !== undefined);
};

export const getHasBrokenSectionRule = (rule: any, dataFieldExternalKeyMap: any) => {
  if (!rule || !dataFieldExternalKeyMap) {
    return false;
  }

  const externalKey = getExternalKeyFromRule(rule);

  return Boolean(dataFieldExternalKeyMap[externalKey] === undefined);
};
