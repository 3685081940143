// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_78_881');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.6923 0.41713C36.284 0.101662 36.994 0.10181 37.5856 0.417523L71.6254 18.5836C72.2808 18.9333 72.6901 19.6159 72.6898 20.3587C72.6896 21.1016 72.2799 21.7839 71.6243 22.1333L60.7792 27.9123L71.7178 34.5143C72.3374 34.8883 72.7083 35.5663 72.6891 36.2897C72.67 37.0132 72.2636 37.6705 71.6251 38.0111L60.7748 43.7987L71.7186 50.4114C72.338 50.7856 72.7086 51.4638 72.6891 52.1872C72.6696 52.9106 72.263 53.5678 71.6243 53.9081L37.5845 72.0469C36.993 72.3621 36.2833 72.3619 35.6919 72.0465L1.65207 53.8895C1.01336 53.5488 0.606979 52.8912 0.587976 52.1676C0.568974 51.4439 0.940278 50.7659 1.56023 50.3922L12.4996 43.7973L1.65207 38.0111C1.01352 37.6705 0.60717 37.0132 0.58799 36.2897C0.568809 35.5663 0.939752 34.8883 1.55935 34.5143L12.5088 27.9057L1.65207 22.1147C0.996599 21.7651 0.587206 21.0826 0.587283 20.3398C0.58736 19.5969 0.996895 18.9145 1.65243 18.565L35.6923 0.41713ZM16.6417 30.1102L6.67023 36.1285L36.6386 52.1137L66.6069 36.1285L56.6444 30.1156L37.5845 40.2721C36.993 40.5873 36.2833 40.5871 35.6919 40.2717L16.6417 30.1102ZM16.6337 46.0024L6.67207 52.0078L36.639 67.9923L66.6073 52.0231L56.6433 46.0025L37.5852 56.1681C36.9936 56.4837 36.2836 56.4837 35.6919 56.1681L16.6337 46.0024ZM6.87343 20.3404L36.639 36.2175L66.4037 20.3568L36.6381 4.47177L6.87343 20.3404Z" fill="currentColor" />
        <path d="M32.8889 47.4539L81.6349 22.9047L76.1095 72.8254H32.8889V47.4539Z" fill="white" />
        <path d="M49.2487 57.2144L60.2505 62.7153M38.8135 54.7546C38.8135 56.218 39.3948 57.6216 40.4297 58.6564C41.4645 59.6912 42.8681 60.2726 44.3315 60.2726C45.795 60.2726 47.1985 59.6912 48.2334 58.6564C49.2682 57.6216 49.8496 56.218 49.8496 54.7546C49.8496 53.2911 49.2682 51.8875 48.2334 50.8527C47.1985 49.8179 45.795 49.2365 44.3315 49.2365C42.8681 49.2365 41.4645 49.8179 40.4297 50.8527C39.3948 51.8875 38.8135 53.2911 38.8135 54.7546V54.7546ZM59.6595 65.1775C59.6595 66.641 60.2408 68.0446 61.2757 69.0794C62.3105 70.1142 63.714 70.6956 65.1775 70.6956C66.641 70.6956 68.0445 70.1142 69.0794 69.0794C70.1142 68.0446 70.6956 66.641 70.6956 65.1775C70.6956 63.7141 70.1142 62.3105 69.0794 61.2757C68.0445 60.2409 66.641 59.6595 65.1775 59.6595C63.714 59.6595 62.3105 60.2409 61.2757 61.2757C60.2408 62.3105 59.6595 63.7141 59.6595 65.1775V65.1775ZM59.6595 44.3316C59.6595 45.795 60.2408 47.1986 61.2757 48.2334C62.3105 49.2682 63.714 49.8496 65.1775 49.8496C66.641 49.8496 68.0445 49.2682 69.0794 48.2334C70.1142 47.1986 70.6956 45.795 70.6956 44.3316C70.6956 42.8681 70.1142 41.4645 69.0794 40.4297C68.0445 39.3949 66.641 38.8135 65.1775 38.8135C63.714 38.8135 62.3105 39.3949 61.2757 40.4297C60.2408 41.4645 59.6595 42.8681 59.6595 44.3316V44.3316ZM49.2536 52.2923L60.2505 46.7938L49.2536 52.2923Z" stroke="currentColor" strokeWidth="2.48462" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="74" height="74" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const SharedTemplate = Svg(Fragment, { viewBox: '0 0 74 74', fill: 'none' });

SharedTemplate.displayName = 'SharedTemplate';

export default SharedTemplate;
