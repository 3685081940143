/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Message from 'components/message';
import { localize } from '@oneflowab/pomes';
import { setLanguage } from 'reducers/i18n';
import { getEnabledLanguagesAsOptions } from 'components/languages';

import Field from 'components/field';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import { OPTIONS_JOB_LEVEL, OPTIONS_TEAM, OPTIONS_USAGE } from '../constants';

import style from './signup-user-details.module.scss';

const SignupUserDetails = ({ message }) => {
  const dispatch = useDispatch();
  const handleLanguageChange = (language) => {
    if (!language) {
      return;
    }
    dispatch(setLanguage(language.value));
  };

  const [menuPositionForUsage, setMenuPositionForUsage] = useState('bottom');

  const determineMenuPlacement = () => {
    const threshold = 300; // Adjust as needed
    const usageElement = document.getElementById('select-usage');

    if (!usageElement) {
      return 'bottom';
    }

    const rect = usageElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;

    if (spaceBelow < threshold) {
      return 'top';
    }

    return 'bottom';
  };

  useEffect(() => {
    const element = document.body;

    const updateMenuPlacement = () => {
      const placement = determineMenuPlacement();
      setMenuPositionForUsage(placement);
    };

    const resizeObserver = new ResizeObserver(() => {
      updateMenuPlacement();
    });
    resizeObserver.observe(element);

    updateMenuPlacement();

    // Cleanup on unmount
    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Field
        autoComplete="no"
        autoFocus
        name="name"
        component={TextField}
        label={(
          <Message
            id="Your name"
            comment="Used in required validation of the name field on the signup user details page."
          />
        )}
        placeholder={
          message({
            id: 'Enter full name',
            comment: 'Name placeholder on the complete signup page',
          })
        }
        maxLength={100}
        responsive
        required
      />
      <Field
        name="language"
        component={SelectField}
        label={(
          <Message
            id="Language"
            comment="Used in required validation of the language field on the third step of the signup flow page."
          />
        )}
        placeholder={(
          <Message
            id="Select language"
            comment="Language placeholder on the third step of the signup flow page."
          />
        )}
        options={getEnabledLanguagesAsOptions()}
        clearable={false}
        searchable={false}
        onChange={handleLanguageChange}
        required
      />
      <div className={style.JobDetailsContainer}>
        <Field
          name="jobLevel"
          component={SelectField}
          label={(
            <Message
              id="Job level"
              comment="Used in required validation of the job level field on the signup user details page."
            />
          )}
          placeholder={(
            <Message
              id="Select your job level"
              comment="Job level placeholder on the signup user details page."
            />
          )}
          options={OPTIONS_JOB_LEVEL}
          clearable={false}
          searchable={false}
          required
        />
        <Field
          name="team"
          component={SelectField}
          label={(
            <Message
              id="Team"
              comment="Used in required validation of the team field on the signup user details page."
            />
          )}
          placeholder={(
            <Message
              id="Select your team"
              comment="Team placeholder on the signup user details page."
            />
          )}
          options={OPTIONS_TEAM}
          clearable={false}
          searchable={false}
          required
        />
      </div>
      <Field
        name="usage"
        component={SelectField}
        label={(
          <Message
            id="What do you want to use Oneflow for?"
            comment="Used in required validation of the reason for using Oneflow field on the signup user details page."
          />
        )}
        placeholder={(
          <Message
            id="Choose all that applies"
            comment="Reason for using Oneflow placeholder on the signup user details page."
          />
        )}
        options={OPTIONS_USAGE}
        clearable={false}
        searchable={false}
        multi
        required
        menuPlacement={menuPositionForUsage}
      />
    </>
  );
};

export default localize(SignupUserDetails);
