// @flow

import React from 'react';
import { localize, Message, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import Info from 'components/icons/info';
import SelectField from 'components/select-field';
import { getDeliveryChannel } from 'components/delivery-channels';
import { useReactSelectDropdownMenuPlacement } from 'hooks/use-react-select-dropdown-menu-placement';

type Props = {
  message: MessageTranslator,
  availableChannels: Array<number>,
  onChange?: Option => void,
  initialValue?: number,
  disabled?: boolean,
};

// Also use in channels list in participant-statistics, lockdown methods and default settings
export const DeliveryChannel = ({
  message,
  availableChannels,
  onChange,
  initialValue,
  disabled,
}: Props) => {
  const getDeliveryChannels = () => (
    availableChannels?.map<any>(
      (deliveryChannel) => ({
        value: deliveryChannel && deliveryChannel,
        label: getDeliveryChannel(deliveryChannel).label,
      }),
    )
  );

  const menuPlacement = useReactSelectDropdownMenuPlacement('deliveryChannel');

  const description = (
    <>
      <p>
        <Message
          id="This setting decides how the document is sent to your counterparty."
          comment="Tooltip for delivery channel type dropdown"
        />
      </p>
      <p>
        <Message
          id="More delivery methods can be added by enabling extensions."
          comment="Tooltip for delivery channel type dropdown"
        />
      </p>
    </>
  );

  return (
    <Field
      name="deliveryChannel"
      label={message({
        id: 'Delivery channel',
        comment: 'Label for delivery channel field.',
      })}
      placeholder={(
        <Message
          id="Select delivery channel"
          comment="Placeholder for delivery channel field."
        />
      )}
      component={SelectField}
      options={getDeliveryChannels()}
      clearable={false}
      searchable={false}
      required
      fieldinfo={{
        message: description,
        icon: <Info width="12px" />,
        side: 'top',
        zIndex: '10004',
        nextToLabel: true,
      }}
      onChange={onChange}
      initialValue={initialValue}
      disabled={disabled}
      menuPlacement={menuPlacement}
    />
  );
};

export default localize<Props>(DeliveryChannel);
