// @flow

import React from 'react';

import Svg from 'components/svg';

const AddMembers = Svg((
  <path d="M7.0947 1.07881C6.87079 1.02645 6.6416 1.00001 6.41165 1C5.62779 1 4.87605 1.31138 4.32178 1.86565C3.76751 2.41992 3.45613 3.17167 3.45613 3.95552C3.45613 4.73937 3.76751 5.49112 4.32178 6.04538C4.87605 6.59965 5.62779 6.91104 6.41165 6.91104C6.62359 6.91096 6.83493 6.8885 7.04216 6.84404M5.12961 9.02062C3.81642 9.31238 2.64203 10.0433 1.80041 11.0927C0.958787 12.1421 0.500277 13.4472 0.500611 14.7924V17H3.12774M11.0347 17H5.7805V14.7924C5.78019 13.7846 6.03756 12.7935 6.52815 11.9132C7.01874 11.0328 7.72626 10.2926 8.5835 9.76268C9.44074 9.23278 10.4192 8.93085 11.426 8.88558C12.4328 8.84031 13.4344 9.05319 14.3357 9.50401M14.9999 12V14.5M15 17L14.9999 14.5M12.4999 14.5H14.9999M17.5 14.5H14.9999M8.73601 3.95552C8.73601 4.34364 8.81246 4.72797 8.96099 5.08655C9.10952 5.44513 9.32722 5.77094 9.60166 6.04538C9.87611 6.31983 10.2019 6.53753 10.5605 6.68606C10.9191 6.83459 11.3034 6.91104 11.6915 6.91104C12.0797 6.91104 12.464 6.83459 12.8226 6.68606C13.1811 6.53753 13.507 6.31983 13.7814 6.04538C14.0558 5.77094 14.2735 5.44513 14.4221 5.08655C14.5706 4.72797 14.647 4.34364 14.647 3.95552C14.647 3.56739 14.5706 3.18307 14.4221 2.82449C14.2735 2.46591 14.0558 2.1401 13.7814 1.86565C13.507 1.59121 13.1811 1.3735 12.8226 1.22498C12.464 1.07645 12.0797 1 11.6915 1C11.3034 1 10.9191 1.07645 10.5605 1.22498C10.2019 1.3735 9.87611 1.59121 9.60166 1.86565C9.32722 2.1401 9.10952 2.46591 8.96099 2.82449C8.81246 3.18307 8.73601 3.56739 8.73601 3.95552Z" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
), { fill: 'none', viewBox: '0 0 18 18' });

AddMembers.displayName = 'AddMembers';

export default AddMembers;
