import React from 'react';

import Svg from 'components/svg';

const SmallDragHandler = Svg((
  <>
    <circle cx="1.875" cy="1.875" r="1.875" fill="black" />
    <circle cx="8.125" cy="1.875" r="1.875" fill="black" />
    <circle cx="8.125" cy="8.125" r="1.875" fill="black" />
    <circle cx="1.875" cy="8.125" r="1.875" fill="black" />
  </>
), { viewBox: '0 0 10 10', fill: 'none' });

SmallDragHandler.displayName = 'SmallDragHandler';

export default SmallDragHandler;
