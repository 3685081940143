// @flow

import React from 'react';

import Svg from 'components/svg';

const NoTags = Svg((
  <>
    <path
      d="M8.99998 19.188C8.71869 19.4692 8.33723 19.6272 7.93948 19.6272C7.54174 19.6272 7.16027 19.4692 6.87898 19.188L1.49998 13.81C1.36059 13.6707 1.25002 13.5053 1.17458 13.3232C1.09913 13.1412 1.0603 12.9461 1.0603 12.749C1.0603 12.5519 1.09913 12.3568 1.17458 12.1747C1.25002 11.9927 1.36059 11.8273 1.49998 11.688L12 1.18799C12.1393 1.04873 12.3048 0.938276 12.4868 0.862952C12.6689 0.787627 12.864 0.748904 13.061 0.748993H18.439C18.8368 0.748993 19.2183 0.907028 19.4996 1.18833C19.7809 1.46964 19.939 1.85117 19.939 2.24899V7.62799C19.9389 8.02554 19.781 8.40679 19.5 8.68799"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
    />
    <path
      d="M19.189 14.998L14.689 19.498M14.689 14.998L19.189 19.498M10.939 17.249C10.939 18.0369 11.0942 18.8171 11.3957 19.5451C11.6972 20.273 12.1392 20.9345 12.6963 21.4916C13.2535 22.0488 13.9149 22.4907 14.6429 22.7923C15.3708 23.0938 16.151 23.249 16.939 23.249C17.7269 23.249 18.5071 23.0938 19.2351 22.7923C19.963 22.4907 20.6245 22.0488 21.1816 21.4916C21.7388 20.9345 22.1807 20.273 22.4822 19.5451C22.7838 18.8171 22.939 18.0369 22.939 17.249C22.939 16.4611 22.7838 15.6808 22.4822 14.9529C22.1807 14.2249 21.7388 13.5635 21.1816 13.0064C20.6245 12.4492 19.963 12.0072 19.2351 11.7057C18.5071 11.4042 17.7269 11.249 16.939 11.249C16.151 11.249 15.3708 11.4042 14.6429 11.7057C13.9149 12.0072 13.2535 12.4492 12.6963 13.0064C12.1392 13.5635 11.6972 14.2249 11.3957 14.9529C11.0942 15.6808 10.939 16.4611 10.939 17.249Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <circle
      cx="14.9998"
      cy="5.99997"
      r="1"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 24 24', fill: 'none' });

NoTags.displayName = 'NoTags';

export default NoTags;
