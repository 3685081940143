import Svg from 'components/svg';

const TwoStepAuth = Svg((
  <>
    <path d="M22.4614 26.07V36.6429C22.4627 39.9842 23.4761 43.2467 25.368 46.0008C27.2599 48.7549 29.9416 50.8714 33.06 52.0714L36 53.2114L38.9443 52.08C42.0627 50.8799 44.7444 48.7635 46.6363 46.0094C48.5282 43.2553 49.5415 39.9927 49.5429 36.6514V26.07V24.7329L48.33 24.18C44.4437 22.4803 40.2415 21.6229 36 21.6643C31.7569 21.6211 27.553 22.4786 23.6657 24.18L22.4529 24.7329L22.4614 26.07Z" stroke="white" strokeWidth="1.45714" strokeLinecap="square" />
    <path d="M29.7514 34.1614H42.2529V44.5714H29.7514V34.1614Z" stroke="white" strokeWidth="1.45714" strokeLinecap="square" />
    <path d="M36 27.9129C34.8952 27.9129 33.8356 28.3517 33.0544 29.133C32.2732 29.9142 31.8343 30.9738 31.8343 32.0786V34.1614H40.17V32.0786C40.1689 30.9734 39.729 29.9138 38.9471 29.1327C38.1652 28.3516 37.1052 27.9129 36 27.9129Z" stroke="white" strokeWidth="1.45714" strokeLinecap="square" />
    <path d="M36 38.8886C36.0724 38.882 36.1453 38.8906 36.2141 38.9138C36.283 38.9369 36.3462 38.9742 36.3999 39.0232C36.4535 39.0722 36.4964 39.1318 36.5257 39.1983C36.555 39.2648 36.5701 39.3366 36.5701 39.4093C36.5701 39.4819 36.555 39.5538 36.5257 39.6203C36.4964 39.6867 36.4535 39.7464 36.3999 39.7954C36.3462 39.8444 36.283 39.8816 36.2141 39.9048C36.1453 39.928 36.0724 39.9366 36 39.93C35.8701 39.9182 35.7492 39.8582 35.6612 39.7619C35.5732 39.6655 35.5244 39.5398 35.5244 39.4093C35.5244 39.2788 35.5732 39.153 35.6612 39.0567C35.7492 38.9603 35.8701 38.9004 36 38.8886Z" stroke="white" strokeWidth="1.45714" strokeLinecap="round" />
  </>
), { viewBox: '0 0 72 72', fill: 'none' });

TwoStepAuth.displayName = 'TwoStepAuth';

export default TwoStepAuth;
