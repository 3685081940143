import Svg from 'components/svg';

const DynamicTemplates = Svg((
  <>
    <path d="M47.2526 25.1177L54.7509 21.3634C54.7509 35.1154 47.2526 33.8657 47.2526 33.8657" stroke="white" strokeWidth="1.74857" />
    <path d="M24.7526 25.1177L17.2491 21.3634C17.2491 35.1154 24.7526 33.8657 24.7526 33.8657" stroke="white" strokeWidth="1.74857" />
    <path d="M36 46.368V58.8651" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M42.2537 43.8634V51.3669" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M29.7514 43.8634V51.3669" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M29.6177 31.8497H41.4463" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M29.6177 27.0463H41.4463" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M29.6177 36.6531H36.576" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
    <path d="M47.1446 21.3634H24.7526V42.336H47.1446V21.3634Z" stroke="white" strokeWidth="1.74857" strokeLinecap="square" />
  </>
), { viewBox: '0 0 70 70', fill: 'none' });

DynamicTemplates.displayName = 'DynamicTemplates';

export default DynamicTemplates;
