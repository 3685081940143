// @flow

import React from 'react';

import Svg from 'components/svg';

const Subscript = Svg((
  <>
    <path d="M17.112 19.8119H21.5129V18.5689H19.2733V18.5353L19.8108 18.0874C21.1182 16.9983 21.4513 16.4328 21.4513 15.7693C21.4513 14.6999 20.575 14 19.2005 14C17.8651 14 16.9972 14.7419 17 15.9485H18.4782C18.4754 15.467 18.7693 15.1982 19.2005 15.1982C19.6288 15.1982 19.9339 15.4642 19.9339 15.9037C19.9339 16.3096 19.6736 16.5728 19.2509 16.9227L17.112 18.6921V19.8119Z" fill="currentColor" />
    <path d="M9.59003 6.9538H7.07849L10.0609 11.9769L7 17H9.51155L11.6176 13.3831L13.7432 17H16.2352L13.1546 11.9769L16.1828 6.9538H13.6778L11.6176 10.6623L9.59003 6.9538Z" fill="currentColor" />
  </>
));

Subscript.displayName = 'Subscript';

export default Subscript;
