// @flow

import React from 'react';

import Svg from 'components/svg';

const NoGroupMember = Svg((
  <path
    d="M19.3333 14.9324L14.9333 19.3324M14.9333 14.9324L19.3333 19.3324M10.8091 10.546C9.72077 9.58622 8.31774 9.05974 6.86667 9.06667C5.42983 9.0554 4.03881 9.57184 2.95745 10.518C1.87608 11.4642 1 12.9565 1 14.3913M20.2133 9.06667C19.8633 8.46073 19.3737 7.94703 18.7854 7.5682C18.197 7.18938 17.5267 6.95635 16.8302 6.88847C16.1338 6.82059 15.4311 6.91982 14.7807 7.1779C14.1302 7.43599 13.5507 7.8455 13.0902 8.37244M11.2667 17.1333C11.2667 18.6893 11.8848 20.1815 12.985 21.2817C14.0852 22.3819 15.5774 23 17.1333 23C18.6893 23 20.1815 22.3819 21.2817 21.2817C22.3819 20.1815 23 18.6893 23 17.1333C23 15.5774 22.3819 14.0852 21.2817 12.985C20.1815 11.8848 18.6893 11.2667 17.1333 11.2667C15.5774 11.2667 14.0852 11.8848 12.985 12.985C11.8848 14.0852 11.2667 15.5774 11.2667 17.1333ZM3.56667 4.3C3.56667 5.17521 3.91434 6.01458 4.53321 6.63345C5.15208 7.25232 5.99145 7.6 6.86667 7.6C7.74188 7.6 8.58125 7.25232 9.20012 6.63345C9.81899 6.01458 10.1667 5.17521 10.1667 4.3C10.1667 3.42479 9.81899 2.58542 9.20012 1.96655C8.58125 1.34768 7.74188 1 6.86667 1C5.99145 1 5.15208 1.34768 4.53321 1.96655C3.91434 2.58542 3.56667 3.42479 3.56667 4.3ZM13.8333 3.56667C13.8333 4.24739 14.1038 4.90023 14.5851 5.38157C15.0664 5.86292 15.7193 6.13333 16.4 6.13333C17.0807 6.13333 17.7336 5.86292 18.2149 5.38157C18.6963 4.90023 18.9667 4.24739 18.9667 3.56667C18.9667 2.88594 18.6963 2.2331 18.2149 1.75176C17.7336 1.27042 17.0807 1 16.4 1C15.7193 1 15.0664 1.27042 14.5851 1.75176C14.1038 2.2331 13.8333 2.88594 13.8333 3.56667Z"
    stroke="currentColor"
    strokeWidth="1.3"
    strokeLinecap="square"
    strokeLinejoin="round"
  />

), { viewBox: '0 0 24 24', fill: 'none' });

NoGroupMember.displayName = 'NoGroupMember';

export default NoGroupMember;
