// @flow

import React from 'react';

import Svg from 'components/svg';

const ChevronUp = Svg((
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.55468 1.95222C4.69305 1.88763 4.84463 1.85303 4.99997 1.85303C5.15532 1.85303 5.3069 1.88763 5.44527 1.95223C5.58333 2.01668 5.70343 2.10866 5.801 2.21869L5.81058 2.22976L9.91608 7.0757L8.92419 7.91603L4.99997 3.28406L1.07575 7.91603L0.0838623 7.0757L4.18936 2.22976L4.19895 2.21869C4.29651 2.10866 4.41661 2.01668 4.55468 1.95222Z"
    fill="currentColor"
  />
), { viewBox: '0 0 10 10', fill: 'none' });

ChevronUp.displayName = 'ChevronUp';

export default ChevronUp;
