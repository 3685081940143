import { get, post, extractResponseBodyAsJSON } from 'oneflow-client/core';

export const postForgotPassword = ({ email }) => (
  post({
    url: '/positions/forgot_password',
    body: {
      email,
    },
  }).then(extractResponseBodyAsJSON)
);

export const getForgotPassword = ({ token }) => (
  get({
    url: `/positions/forgot_password/${token}`,
  }).then(extractResponseBodyAsJSON)
);

export const postChangePassword = ({ token, password }) => (
  post({
    url: `/positions/forgot_password/${token}`,
    body: {
      password,
    },
  }).then(extractResponseBodyAsJSON)
);
