import type { Reducer } from 'redux';

import { createReducer, formatActionType } from 'normalized-redux/reducers';
import { FormattedActionType } from 'normalized-redux/reducers/format-action-type';
import { Entity, Pipe } from 'normalized-redux/entity-normalizer/types';

type Action<E extends Entity, T extends string = string> = {
  type: T,
  id: E['id'],
};

type State<E extends Entity> = {
  [key in E['id']]: {
    pristine: boolean,
    id?: E['id'],
    data: Partial<E>,
    success: boolean,
    loading: boolean,
    error?: string,
  }
};

type UpdateStartAction<E extends Entity, T extends string> = Action<E, T> & { data: State<E>[E['id']]['data'], pipe?: Pipe };
type UpdateSuccessAction<E extends Entity, T extends string> = Action<E, T>;
type UpdateFailAction<E extends Entity, T extends string> = Action<E, T> & { error?: any };
type UpdateResetAction<E extends Entity, T extends string> = Action<E, T>;

export const initialUpdateState: State<Entity>[Entity['id']] = {
  pristine: true,
  id: undefined,
  data: {},
  success: false,
  loading: false,
  error: undefined,
};

export const initialState: State<Entity> = {};

type CreateUpdateStartActionArgs<E extends Entity> = {
  id: E['id'],
  data: State<E>[E['id']]['data'],
  pipe?: Pipe,
};

// actions
export const createUpdateStartAction = <
  E extends Entity,
  T extends string,
>(type: T) => ({ id, data, pipe }: CreateUpdateStartActionArgs<E>): UpdateStartAction<E, T> => ({
    type,
    id,
    data,
    pipe,
  });

type CreateUpdateSuccessActionArgs<E extends Entity> = {
  id: E['id'],
};

export const createUpdateSuccessAction = <
  E extends Entity,
  T extends string,
>(type: T) => ({ id }: CreateUpdateSuccessActionArgs<E>): UpdateSuccessAction<E, T> => ({
    type,
    id,
  });

type CreateUpdateFailActionArgs<E extends Entity> = {
  id: E['id'],
  error: any,
};

export const createUpdateFailAction = <
  E extends Entity,
  T extends string,
>(type: T) => ({ id, error }: CreateUpdateFailActionArgs<E>): UpdateFailAction<E, T> => ({
    type,
    id,
    error,
  });

type CreateUpdateResetActionArgs<E extends Entity> = {
  id: E['id'],
};

export const createUpdateResetAction = <
  E extends Entity,
  T extends string,
>(type: T) => ({ id }: CreateUpdateResetActionArgs<E>): UpdateResetAction<E, T> => ({
    type,
    id,
  });

// handlers
export const updateStartHandler = <
  E extends Entity,
  T extends string,
>(state: State<E>, action: UpdateStartAction<E, T>): State<E> => ({
    ...state,
    [action.id]: {
      pristine: false,
      id: action.id,
      data: action.data,
      loading: true,
      success: false,
      error: undefined,
    },
  });

export const updateSuccessHandler = <
  E extends Entity,
  T extends string,
>(state: State<E>, action: UpdateSuccessAction<E, T>): State<E> => ({
    ...state,
    [action.id]: {
      ...initialUpdateState,
      ...state[action.id],
      pristine: false,
      id: action.id,
      success: true,
      loading: false,
      error: undefined,
    },
  });

export const updateFailHandler = <
  E extends Entity,
  T extends string
>(state: State<E>, action: UpdateFailAction<E, T>): State<E> => ({
    ...state,
    [action.id]: {
      ...initialUpdateState,
      ...state[action.id],
      pristine: false,
      success: false,
      loading: false,
      error: action.error,
    },
  });

export const updateResetHandler = <
  E extends Entity,
  T extends string
>(state: State<E>, action: UpdateResetAction<E, T>): State<E> => ({
    ...state,
    [action.id]: {
      ...initialUpdateState,
    },
  });

type GetUpdateSelectorProps<E extends Entity> = {
  id: E['id'],
};
// selectors
export const getUpdateSelector = <E extends Entity>(state: State<E>, { id }: GetUpdateSelectorProps<E>): State<E>[E['id']] => {
  if (!state[id]) {
    return initialUpdateState as State<E>[E['id']];
  }

  return state[id];
};

// type FormattedCreateStartActionType<T extends string> = FormattedActionType<T, 'CREATE_START'>;
type FormattedCreateUpdateStartActionType<T extends string> = FormattedActionType<T, 'UPDATE_START'>;
type FormattedCreateUpdateSuccessActionType<T extends string> = FormattedActionType<T, 'UPDATE_SUCCESS'>;
type FormattedCreateUpdateFailActionType<T extends string> = FormattedActionType<T, 'UPDATE_FAIL'>;
type FormattedCreateUpdateResetActionType<T extends string> = FormattedActionType<T, 'UPDATE_RESET'>;

export type UpdateReducer<E extends Entity, T extends string> = {
  reducer: Reducer<State<E>, any>
  actions: {
    updateStart: (
      args: CreateUpdateStartActionArgs<E>
    ) => UpdateStartAction<E, FormattedCreateUpdateStartActionType<T>>;
    updateSuccess: (
      args: CreateUpdateSuccessActionArgs<E>
    ) => UpdateSuccessAction<E, FormattedCreateUpdateSuccessActionType<T>>;
    updateFail: (
      args: CreateUpdateFailActionArgs<E>
    ) => UpdateFailAction<E, FormattedCreateUpdateFailActionType<T>>;
    updateReset: (
      args: CreateUpdateResetActionArgs<E>
    ) => UpdateResetAction<E, FormattedCreateUpdateResetActionType<T>>;
    types: {
      updateStart: FormattedCreateUpdateStartActionType<T>;
      updateSuccess: FormattedCreateUpdateSuccessActionType<T>;
      updateFail: FormattedCreateUpdateFailActionType<T>;
      updateReset: FormattedCreateUpdateResetActionType<T>;
    };
  };
  selectors: {
    getUpdateSelector: (state: State<E>, { id }: GetUpdateSelectorProps<E>) => State<E>[E['id']];
  };
};

// initializer
const createUpdateReducer = <
  E extends Entity,
  T extends string,
>({ key }: { key: T }): UpdateReducer<E, T> => {
  const UPDATE_START = formatActionType({ key, type: 'UPDATE_START' });
  const UPDATE_SUCCESS = formatActionType({ key, type: 'UPDATE_SUCCESS' });
  const UPDATE_FAIL = formatActionType({ key, type: 'UPDATE_FAIL' });
  const UPDATE_RESET = formatActionType({ key, type: 'UPDATE_RESET' });

  return ({
    reducer: createReducer(initialState as State<E>, {
      [UPDATE_START]: updateStartHandler,
      [UPDATE_SUCCESS]: updateSuccessHandler,
      [UPDATE_FAIL]: updateFailHandler,
      [UPDATE_RESET]: updateResetHandler,
    }),
    actions: {
      updateStart: createUpdateStartAction(UPDATE_START),
      updateSuccess: createUpdateSuccessAction(UPDATE_SUCCESS),
      updateFail: createUpdateFailAction(UPDATE_FAIL),
      updateReset: createUpdateResetAction(UPDATE_RESET),
      types: {
        updateStart: UPDATE_START,
        updateSuccess: UPDATE_SUCCESS,
        updateFail: UPDATE_FAIL,
        updateReset: UPDATE_RESET,
      },
    },
    selectors: {
      getUpdateSelector,
    },
  });
};

export default createUpdateReducer;
