// @flow

import * as amplitude from '@amplitude/analytics-browser';

import log from 'logging';
import client from 'oneflow-client';

type SetStorageAndLog= (
  event: string,
  count: string,
  logRollbarError: boolean,
) => void;

export const setStorageAndLog: SetStorageAndLog = (event, count, logRollbarError) => {
  sessionStorage.setItem('amplitudeEventError', logRollbarError ? '' : event);
  sessionStorage.setItem('amplitudeErrorCount', count);
  if (logRollbarError) {
    const lastRecorded = parseInt(sessionStorage.getItem(event), 10);
    // log only if this error has not been logged in the last hour
    if (!lastRecorded || (Date.now() - lastRecorded) >= 3600) {
      log.error('Failed to send events to Amplitude', event);
      sessionStorage.setItem(event, Date.now().toString());
    }
  }
};

export const errorHandler = (eventName: string) => {
  const lastError = sessionStorage.getItem('amplitudeEventError');
  if (lastError && lastError.length > 0 && lastError === eventName) {
    const errorCount = parseInt(sessionStorage.getItem('amplitudeErrorCount'), 10) + 1;
    if (errorCount > 4) {
      setStorageAndLog(eventName, '0', true);
    } else {
      sessionStorage.setItem('amplitudeErrorCount', errorCount.toString());
    }
  } else {
    setStorageAndLog(eventName, '1', false);
  }
};

type LogEventWithTimestamp = (
  eventName: string,
  date: string,
  eventProps?: Object,
  groups?: Object,
) => void;

export const logEventWithTimestamp: LogEventWithTimestamp = (
  eventName,
  date,
  eventProps,
  groups,
) => {
  let hasCalledError = false;
  const timestamp = Date.parse(date);

  try {
    amplitude.track({
      event_type: eventName,
      event_properties: eventProps,
      groups,
      time: timestamp,
    });
  } catch (error) {
    if (!hasCalledError) { // workaround for error callback being called more than once
      errorHandler(eventName);
      hasCalledError = true;
    }
  }
};

type AmplitudeLogEvent = (
  event: string,
  props?: Object,
  groups?: Object,
) => void;

export const amplitudeLogEvent: AmplitudeLogEvent = (event, props, groups) => {
  const eventProps = props?.location
    ? { ...props, location: props.location.toLowerCase() } : props;
  client.ping().then((response) => { // api call to get server time
    const { headers } = response;
    const date = headers.get('date');
    logEventWithTimestamp(event, date, eventProps, groups);
  }).catch((error) => {
    const { headers } = error.response;
    const date = headers.get('date');
    logEventWithTimestamp(event, date, eventProps, groups);
  });
};

type AmplitudeInit = (
  apiKey: string,
  config?: Object,
  user?: string,
) => void;

export const amplitudeInit: AmplitudeInit = (apiKey, config, user = '') => {
  amplitude.init(apiKey, user, config);
};

type AmplitudeSetUserId = (
  userId: string,
) => void;

export const amplitudeSetUserId: AmplitudeSetUserId = (userId) => {
  amplitude.setUserId(userId);
};

type AmplitudeSetGroup = (
  groupType: string,
  groupName: string,
) => void;

export const amplitudeSetGroup: AmplitudeSetGroup = (groupType, groupName) => {
  amplitude.setGroup(groupType, groupName);
};

type AmplitudeSetUserProperties = (
  userProperties: Array<[string, any]>,
) => void;

export const amplitudeSetUserProperties: AmplitudeSetUserProperties = (userProperties) => {
  const properties = new amplitude.Identify();
  userProperties.forEach(([key, value]) => {
    properties.set(key, value);
  });

  amplitude.identify(properties);
};

type AmplitudeSetUserAndGroupProps = (
  userId: string,
  userProperties: Array<[string, any]>,
  groupType: string,
  groupName: string,
) => void;

export const amplitudeSetUserAndGroupProps: AmplitudeSetUserAndGroupProps = (
  userId,
  userProperties,
  groupType,
  groupName,
) => {
  amplitudeSetUserId(userId);
  amplitudeSetUserProperties(userProperties);
  amplitudeSetGroup(groupType, groupName);
};

export default {
  amplitudeInit,
  amplitudeLogEvent,
  amplitudeSetUserAndGroupProps,
};
