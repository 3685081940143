// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import type { UniqueParams } from 'forms/validators/unique';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  autoFocus?: boolean,
  hideLabel?: boolean,
  unique?: UniqueParams,
  autoComplete?: string,
  hideErrorsUntilTouched?: boolean,
  required?: boolean,
  hideRequired?: boolean,
  tabIndex?: number,
  initialValue?: string,
  disabled?: boolean,
  validateFields?: Array<string>,
  onBlur?: (reactFinalFormOnBlur: () => void, event: React.FocusEvent) => void,
  displayErrorEarly?: boolean,
  displayError?: (meta: InputMeta) => boolean,
};

export const EmailField = ({
  message,
  autoFocus,
  hideLabel,
  unique,
  autoComplete,
  hideErrorsUntilTouched,
  required = true,
  hideRequired = false,
  initialValue,
  disabled,
  tabIndex,
  validateFields,
  onBlur,
  displayErrorEarly = false,
  displayError,
}: Props) => (
  <Field
    autoFocus={autoFocus}
    autoComplete={autoComplete}
    hideErrorsUntilTouched={hideErrorsUntilTouched}
    name="email"
    component={TextField}
    label={message({
      id: 'Email address',
      comment: 'Label for an email input field.',
    })}
    placeholder={message({
      id: 'Enter email',
      comment: 'Placeholder for an email input field.',
    })}
    maxLength={70}
    hideLabel={hideLabel}
    unique={unique}
    email
    responsive
    required={required}
    hideRequired={hideRequired}
    initialValue={initialValue}
    tabIndex={tabIndex}
    disabled={disabled}
    validateFields={validateFields}
    onBlur={onBlur}
    displayErrorEarly={displayErrorEarly}
    displayError={displayError}
  />
);

EmailField.defaultProps = {
  autoFocus: undefined,
  hideLabel: undefined,
  unique: undefined,
  autoComplete: undefined,
  hideErrorsUntilTouched: undefined,
  required: undefined,
};

export default localize<Props>(EmailField);
