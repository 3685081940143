// @flow

import {
  isArray,
  isEmpty,
  isNumber,
  isString,
} from 'lodash';

import { normalize, denormalize, schema } from 'normalizr';
import {
  camelizeKeys,
  decamelizeKeys,
  excludedAttributes,
  excludedKeys,
} from 'utils/camelizer';
import { STATE_DRAFT, STATE_TEMPLATE, MESSAGE_TYPE_REMINDER } from 'agreement/constants';
import {
  BODY_MFA_CHANNEL_NONE,
  DELIVERY_CHANNEL_SAME_DEVICE,
  TYPE_IS_EXTERNAL_APPROVER,
  TYPE_IS_INFLUENCER,
  TYPE_IS_INTERNAL_APPROVER,
  TYPE_IS_ORGANIZER,
  TYPE_IS_SIGNATORY,
} from 'agreement/participant/constants';
import { isTemplate, isDraft } from 'agreement/states';
import { getAgreementMyParticipant } from 'agreement/selectors';

import {
  extractResponseBodyAsJSON,
  get,
  getMultiple,
  post,
  put,
  remove,
} from 'oneflow-client/core';
import type { SortParams, RequestOptions } from 'oneflow-client/core';
import { PREVIEW } from 'hooks/use-is-in-preview-mode';

export const SHARED_WITH_ME_ID = 0;

export const camelizeAgreement = (obj: any) => camelizeKeys(obj, {
  process: (key, convert) => {
    if (/.+:.+/.test(key) || excludedAttributes.includes(key)) {
      return key;
    }
    return convert(key);
  },
  excludedKeys,
});

export const decamelizeAgreement = (agreement: Agreement) => decamelizeKeys(agreement, {
  process: (key, convert) => {
    if (excludedAttributes.includes(key)) {
      return key;
    }
    return convert(key);
  },
  excludedKeys,
  separator: '_',
});

export const tagSchema = new schema.Entity('tags');

export const agreementParticipantsSchema = new schema.Entity('agreementParticipants');

export const tagConnectionSchema = new schema.Entity('tagConnections', {
  tag: tagSchema,
});

export const agreementSchema = new schema.Entity('agreements', {
  tags: [tagConnectionSchema],
  agreementParticipants: [agreementParticipantsSchema],
});

export const agreementDenormalizeSchema = new schema.Entity('agreements');

export type NormalizedAgreements = {
  entities: {
    agreements: {
      [number]: Agreement & {
        Template: any,
      },
    },
    tagConnections: {
      [number]: Tag,
    },
  },
  result: number,
  count: number,
}

export type AgreementNormalizer = (any) => NormalizedAgreements;

const normalizeAgreements: AgreementNormalizer = (agreements) => ({
  ...normalize(agreements.collection, [agreementSchema]),
  count: agreements.count,
});

export const normalizeAgreement: AgreementNormalizer = (agreement) => ({
  ...normalize(agreement, agreementSchema),
  count: 1,
});

export const denormalizeAgreement: AgreementNormalizer = (agreement) => (
  decamelizeKeys(denormalize(agreement, agreementDenormalizeSchema), {
    separator: '_',
    excludedKeys: ['nodes'],
  })
);

type CreateAgreement = ({
  amplitudeScope?: string,
  folderId?: number,
  isImported?: number,
  isTemplate?: boolean,
  name: string,
  sourceId: number,
  workspaceId?: number,
}) => Promise<NormalizedAgreements>;

type ImportContract = ({
  workspaceId: number,
  folderId?: number,
}) => Promise<NormalizedAgreements>;

// eslint-disable-next-line no-shadow
const getAgreementState = (isTemplate?: boolean): number => {
  if (isTemplate) {
    return STATE_TEMPLATE;
  }

  return STATE_DRAFT;
};

export const createAgreement: CreateAgreement = ({
  sourceId,
  workspaceId,
  name,
  // eslint-disable-next-line no-shadow
  isTemplate,
  isImported,
  amplitudeScope,
  folderId,
  fromPdf,
}) => (
  post({
    url: '/agreements/',
    body: {
      source_id: sourceId,
      collection_id: workspaceId,
      state: getAgreementState(isTemplate),
      source: 'oneflow_client',
      name,
      import: isImported,
      folder_id: folderId,
      from_pdf: fromPdf,
    },
  }, { amplitudeScope })
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);

export const importContract: ImportContract = ({ workspaceId, folderId }) => (
  post({
    url: '/agreements/',
    body: {
      collection_id: workspaceId,
      source: 'oneflow_client',
      import: 1,
      folder_id: folderId,
    },
  }, { amplitudeScope: 'contract list - import contract modal' })
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);

export type AgreementParams = AgreementParams;

export type AgreementRequestParams = {
  url: string,
  params?: {},
  pagination?: {},
  sorting?: SortParams,
  sort?: Array<string>,
};

const getRequestParams = ({
  collectionIds,
  date: {
    field,
    from,
    to,
  } = {},
  global,
  isGlobalSearch,
  sendWorkspaceIds,
  withoutWorkspaceId,
  isTrashSearch,
  ...restParams
}: any) => {
  const includeFolderSubtree = () => {
    if (!isEmpty(restParams.q) || restParams.includeFolderSubtree) {
      return 1;
    }

    return 0;
  };

  const noFolder = () => {
    const {
      folderIds,
      isExport,
      q,
      sharedWithMe,
    } = restParams;

    if (isExport || isGlobalSearch || sharedWithMe || isTrashSearch) {
      return 0;
    }

    if (isEmpty(q) && isEmpty(folderIds)) {
      return 1;
    }

    return 0;
  };

  if (withoutWorkspaceId && !sendWorkspaceIds) {
    return decamelizeKeys({
      ...restParams,
      dateField: field,
      fromDate: from,
      includeFolderSubtree: includeFolderSubtree(),
      noFolder: noFolder(),
      sharedWithMe: undefined,
      toDate: to,
    }, { separator: '_' });
  }

  const otherParams = {
    ...restParams,
  };

  if (sendWorkspaceIds && otherParams.sharedWithMe === 1) {
    otherParams.sharedWithMe = undefined;
  }

  return decamelizeKeys({
    ...otherParams,
    collectionIds,
    dateField: field,
    fromDate: from,
    includeFolderSubtree: includeFolderSubtree(),
    noFolder: noFolder(),
    toDate: to,
  }, { separator: '_' });
};

type GetAgreements = AgreementParams => Promise<NormalizedAgreements>;

export const getAgreements: GetAgreements = ({
  params = {},
  pagination,
  sort,
  normalizeOutput = true,
}) => {
  if (!normalizeOutput) {
    return getMultiple({
      url: '/agreements/',
      pagination,
      params: getRequestParams(params),
      sort,
    })
      .then((response) => response.json())
      .then(camelizeAgreement);
  }

  return getMultiple({
    url: '/agreements/',
    pagination,
    params: getRequestParams(params),
    sort,
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreements);
};

type GetAgreement = ({ id: number }, options: RequestOptions) => Promise<NormalizedAgreements>;

export const getAgreement: GetAgreement = ({ id }, options) => (
  get({
    url: `/agreements/${id}`,
  }, options)
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);

type GetAgreementViewablePositions = ({
  contractId: number,
  positionIds?: Array<number>
}) => Promise<NormalizedAgreements>;

export const getAgreementViewablePositions: GetAgreementViewablePositions = ({
  contractId,
  positionIds,
}) => (
  get({
    url: `/agreements/${contractId}/viewable_positions`,
    params: {
      ids: positionIds,
    },
  })
    .then((response) => response.json())
    .then(camelizeKeys)
);

type UpdateAgreement = ({
  id: number,
  expireDate?: string | null,
  name?: string | null,
  data?: Array<any> | null,
  boxes?: Array<any> | null,
  boxOrder?: Array<any> | null,
  signOrder?: Array<any> | null,
  config?: Object,
  subject?: string,
  message?: string,
  language?: string;
  templateGroup?: number | null;
}) => Promise<NormalizedAgreements>;

export const updateAgreement: UpdateAgreement = ({
  id,
  expireDate,
  name,
  data,
  boxes,
  boxOrder,
  signOrder,
  config,
  subject,
  message,
  language,
  templateGroup,
  counterpartDecline,
}) => {
  let signOrderEntries;
  if (signOrder) {
    signOrderEntries = {
      entries: signOrder,
    };
  }
  return (
    put({
      url: `/agreements/${id}`,
      body: decamelizeKeys({
        expireDate,
        name,
        data,
        boxes,
        boxOrder,
        config,
        signOrder: signOrderEntries,
        subject,
        message,
        counterpartDecline,
        language,
        templateGroup,
      }, {
        separator: '_',
      }),
    })
      .then((response) => response.json())
      .then(camelizeAgreement)
      .then(normalizeAgreement)
  );
};

type UpdateAgreementAttribute = ({
  id: number,
  [key: string]: any,
}) => Promise<NormalizedAgreements>;

export const updateAgreementAttribute: UpdateAgreementAttribute = ({
  id,
  ...args
}) => (
  put({
    url: `/agreements/${id}`,
    body: decamelizeKeys(args, {
      separator: '_',
    }),
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
);

type SaveAgreement = (contractData: any) => Promise<NormalizedAgreements>;

export const saveAgreement: SaveAgreement = async ({
  guestToken,
  ...contractData
}) => {
  const body = decamelizeAgreement(contractData);

  const response = await put({
    url: `/agreements/${contractData.id}`,
    body,
  }, {
    token: guestToken,
  });
  const raw = await response.json();

  return {
    raw,
    normalized: normalizeAgreement(camelizeAgreement(raw)),
  };
};

type FetchAgreement = (contractData: any) => Promise<NormalizedAgreements>;

export const fetchAgreement: FetchAgreement = async ({
  guestToken,
  ...contractData
}) => {
  const isAgreementIdValid = !Number.isNaN(Number(contractData.id));

  if (!isAgreementIdValid) {
    throw new Error('Invalid agreement Id', {
      cause: 'invalid_agreement_id',
    });
  }

  if (guestToken === 'preview') {
    const response = await post({
      url: `/agreements/${contractData.id}/preview`,
    });

    const raw = await response.json();
    const hasUser = Number(response.headers.get('X-Flow-Has-User'));

    return {
      raw,
      normalized: normalizeAgreement(camelizeAgreement(raw)),
      hasUser,
    };
  }

  const response = await get({
    url: `/agreements/${contractData.id}`,
  }, {
    token: guestToken,
  });

  const raw = await response.json();
  const hasUser = Number(response.headers.get('X-Flow-Has-User'));

  return {
    raw,
    normalized: normalizeAgreement(camelizeAgreement(raw)),
    hasUser,
  };
};

type UpdatedConfig = {
  expireDateDays: number,
  [key: string]: any,
}

type UpdateAgreementConfig = ({
  id: number,
  [key: string]: any,
}) => Promise<UpdatedConfig>;

export const updateAgreementConfig: UpdateAgreementConfig = ({
  id,
  ...args
}) => (
  put({
    url: `/agreements/${id}/config`,
    body: decamelizeKeys(args, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
);

type UpdateContractName = ({
  id: number,
  name: string | null,
}) => Promise<NormalizedAgreements>;

export const updateContractName: UpdateContractName = ({
  id,
  name,
}) => updateAgreement({ id, name });

type UpdateExpiryDate = ({
  id: number,
  expireDate: string | null,
  expireDate: number | null,
}) => Promise<NormalizedAgreements>;

export const updateExpiryDate: UpdateExpiryDate = ({
  id,
  expireDate,
  expireDateDays,
}) => {
  const params = {
    id, expireDate,
  };

  if (isNumber(expireDateDays)) {
    params.config = {
      expireDateDays,
    };
  }

  return updateAgreement(params);
};

type UpdateAttachmentBox = ({
  id: number,
  data: Array<any>,
  boxes: Array<any>,
}) => Promise<NormalizedAgreements>;

export const updateAttachmentBox: UpdateAttachmentBox = ({
  id,
  data,
  boxes,
}) => updateAgreement({ id, data, boxes });

export const prepareGetAgreementsExtraParams = (
  params: {},
  workspaceId: number,
  includeFolderSubtree: boolean,
) => {
  const extraParams = {};
  const {
    signOrder,
    tagOperator,
    positionOperator,
    sendWorkspaceIds,
    noTag,
  } = params;

  if (workspaceId === SHARED_WITH_ME_ID) {
    extraParams.sharedWithMe = 1;
  } else if (sendWorkspaceIds) {
    extraParams.collectionIds = params.collectionIds;
  } else {
    extraParams.collectionIds = [workspaceId];
  }

  if (isArray(signOrder) && signOrder[0]) {
    const [signOrderValue] = signOrder;
    extraParams.signOrder = [signOrderValue];
  }

  if (isArray(noTag) && noTag[0]) {
    extraParams.noTag = true;
  }

  if (isArray(tagOperator) && tagOperator[0]) {
    const [tagOperatorValue] = tagOperator;
    extraParams.tagOperator = tagOperatorValue;
  }

  if (isArray(positionOperator) && positionOperator[0]) {
    const [positionOperatorValue] = positionOperator;
    extraParams.positionOperator = positionOperatorValue;
  }

  if (includeFolderSubtree) {
    extraParams.includeFolderSubtree = 1;
  }

  return extraParams;
};

export const runExport = (workspaceId: number, {
  params,
  pagination,
  sort,
}: Query,
includeFolderSubtree: boolean) => (
  getMultiple({
    url: '/agreements/',
    params: getRequestParams({
      ...params,
      ...prepareGetAgreementsExtraParams(params, workspaceId, includeFolderSubtree),
      export: 1,
      limit: undefined,
      offset: undefined,
    }),
    pagination,
    sort,
  }).then(extractResponseBodyAsJSON)
);
type RemoveAgreement = ({
  id: number,
}) => Promise<{}>;

export const removeAgreement: RemoveAgreement = ({ id }) => (
  remove({
    url: `/agreements/${id}`,
  })
    .then(extractResponseBodyAsJSON)
);

type MoveAgreement = ({
  id: number,
  targetFolderId?: number,
  targetWorkspaceId: number,
}) => Promise<Agreement>;

export const moveAgreement: MoveAgreement = ({
  id,
  targetFolderId,
  targetWorkspaceId,
}) => (
  post({
    url: `/agreements/${id}/move`,
    body: {
      collection_id: targetWorkspaceId,
      folder_id: targetFolderId,
    },
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);

type RestoreTrashedAgreement = {
  id: number,
}

export const restoreTrashedAgreement: RestoreTrashedAgreement = ({
  id,
}) => (
  post({
    url: `/agreements/${id}/restore`,
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);

type PermanentDeleteAgreement = {
  id: number,
}

export const permanentDeleteAgreement: PermanentDeleteAgreement = ({
  id,
}) => (
  post({
    url: `/agreements/${id}/permanently_delete`,
  })
    .then(extractResponseBodyAsJSON)
);

type EmptyAgreementTrash = {
  id: Oneflow.Workspace['id'],
}

export const emptyAgreementTrash: EmptyAgreementTrash = ({
  id,
}) => (
  remove({
    url: `/collections/${id}/empty_agreement_trash`,
  })
    .then(extractResponseBodyAsJSON)
);

type ParticipantAgreementActionParams = {
  id: number,
  participantId: number,
  checksum: string,
  guestToken: string,
  signCode?: string,
  signMethod?: number,
  markAsSignedDate?: moment$Moment,
};

type ParticipantAgreementAction = (
  action: string,
  actionParams: ParticipantAgreementActionParams,
) => Promise<Object>;

const participantAgreementAction: ParticipantAgreementAction = (action, {
  id,
  participantId,
  checksum,
  guestToken,
  signCode,
  signMethod,
  markAsSignedDate,
  handwrittenSignature,
  handwrittenSignatureType,
}) => {
  const options = {};
  const body = {};
  if (guestToken) {
    options.token = guestToken;
  }
  if (checksum) {
    body.checksum = checksum;
  }
  if (signCode) {
    body.sign_code = signCode;
  }
  if (signMethod) {
    body.sign_method = signMethod;
  }
  if (markAsSignedDate) {
    body.mark_as_signed_date = markAsSignedDate;
  }
  if (handwrittenSignature) {
    body.handwritten_signature = handwrittenSignature;
  }

  if (handwrittenSignatureType) {
    body.handwritten_signature_type = handwrittenSignatureType;
  }
  return post({
    url: `/agreements/${id}/participants/${participantId}/${action}`,
    body,
  }, options)
    .then((response) => response.json())
    .then((participant) => participant.agreement)
    .then(camelizeAgreement)
    .then(normalizeAgreement);
};

type AgreementAction = (
  params: ParticipantAgreementActionParams,
) => Promise<Object>;

export const declineAgreement: AgreementAction = (declineParams) => (
  participantAgreementAction('decline', declineParams)
);

export const signAgreement: AgreementAction = (signParams) => (
  participantAgreementAction('sign', signParams)
);

export const approveDraft: AgreementAction = (signParams) => (
  participantAgreementAction('approve', signParams)
);

export const approvePendingState: AgreementAction = (signParams) => (
  participantAgreementAction('approve', signParams)
);

export type SendSignCodeParams = {
  agreement: Agreement,
  participantId: number,
  phoneNumber: string | null,
  checksum: string,
  ssn?: string,
  guestToken?: string,
  signMethodName?: string,
};

type SendSignCode = (
  params: SendSignCodeParams,
) => Promise<any>;

export const sendSignCode: SendSignCode = (params) => {
  const options = {};
  if (params.guestToken) {
    options.token = params.guestToken;
  }

  return post({
    url: `/agreements/${params.agreement.id}/participants/${params.participantId}/sign_code`,
    token: params.guestToken,
    body: ({
      ssn: params.ssn,
      phone_number: params.phoneNumber,
      checksum: params.checksum,
      sign_method_name: params.signMethodName,
    }),
  }, options).then(extractResponseBodyAsJSON);
};

type QRCodeParams = {
  guestToken?: string,
  orderRef: string,
};

export const getSEBankIDQRCode = (params: QRCodeParams) => {
  const options = {};
  if (params.guestToken) {
    options.token = params.guestToken;
  }

  return get({
    url: `/ext/eid/sweden_bankid/${params.orderRef}/qrcode`,
  }, options).then(extractResponseBodyAsJSON);
};

type SignatureInfoParams = {
  guestToken?: string,
  agreementId: Agreement,
  participantId: string,
}

export const getParticipantInfo = (params: SignatureInfoParams) => {
  const options = {
    token: params.guestToken ?? undefined,
  };

  return get(
    {
      url: `/agreements/${params.agreementId}/participants/${params.participantId}`,
      token: params.guestToken,
    },
    options,
  ).then(extractResponseBodyAsJSON);
};

type MFATokenRequest = ({
  id: number,
  participantId: number,
  securityCode?: string,
}, options?: RequestOptions) => Promise<Agreement>;

export const requestAuthenticationToken: MFATokenRequest = ({
  id,
  participantId,
}, options) => (
  post({
    url: `/agreements/${id}/participants/${participantId}/mfa_code_request`,
    body: {},
  }, options).then(extractResponseBodyAsJSON)
);

export const validateAuthenticationToken: MFATokenRequest = ({
  id,
  participantId,
  securityCode,
}, options) => (
  post({
    url: `/agreements/${id}/participants/${participantId}/mfa_code_validate`,
    body: {
      security_code: securityCode,
    },
  }, options).then(extractResponseBodyAsJSON)
);

type UpdateContractValue = ({
  id: number,
  amount: number,
}) => Promise<Object>;

export const updateContractValue: UpdateContractValue = ({
  id,
  amount,
}) => (
  put({
    url: `/agreements/${id}/agreement_value`,
    body: { amount },
  })
    .then(extractResponseBodyAsJSON)
);

type PublishAgreementRequestOptions = {
  private?: number,
}

type PublishAgreement = ({
  id: number,
  subject?: string,
  message?: string,
}, options?: PublishAgreementRequestOptions) => Promise<Object>;

export const publishAgreement: PublishAgreement = ({
  id,
  subject,
  message,
}, options) => {
  let opts = {};
  if (options) {
    opts = {
      private: Number(options.private),
    };
  }

  return post({
    url: `/agreements/${id}/publish`,
    body: {
      subject,
      message,
      private: opts.private,
    },
  }, options)
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement);
};

type CancelAgreement = ({
  id: number,
  manual: boolean,
  guestToken?: string,
}) => Promise<Object>;

export const cancelAgreement: CancelAgreement = ({
  id,
  manual,
  guestToken,
}) => {
  const options = {};
  if (guestToken) {
    options.token = guestToken;
  }

  return post({
    url: `/agreements/${id}/cancel`,
    body: {
      manual: Number(manual),
    },
  }, options)
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement);
};
type MessageParticipants = ({
  id: number,
  subject: string,
  message: string,
  toParticipantIds: Array<number>,
  isPrivate: number,
}) => Promise<any>;

export const messageParticipants: MessageParticipants = ({
  id,
  subject,
  message,
  toParticipantIds,
  isPrivate,
}) => (
  post({
    url: `/agreements/${id}/messages/`,
    body: {
      agreement: id,
      type: MESSAGE_TYPE_REMINDER,
      justPosted: true,
      to_participant_ids: toParticipantIds,
      subject,
      body: message,
      private: isPrivate,
    },
  })
    .then(extractResponseBodyAsJSON)
);

export const saveComment: any = ({
  id,
  boxId,
  dataId,
  nodes,
  message: commentPayload,
  checksum,
  guestToken,
}) => (
  post({
    url: `/agreements/${id}/box/${boxId}/data/${dataId}/inline_comment`,
    body: {
      nodes,
      body: commentPayload.message,
      private: commentPayload.isPrivate,
      to_participant_ids: commentPayload.recipients,
      mentioned_participant_ids: commentPayload.mentionedParticipantIds,
      comment_scope: commentPayload.messageScope,
      checksum,
    },
  }, {
    token: guestToken,
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
);

export const resolveComment: any = ({
  agreementId,
  commentId,
  nodes,
  checksum,
  guestToken,
  amplitudeScope,
}) => (
  post({
    url: `/agreements/${agreementId}/inline_comment/${commentId}/resolve`,
    body: {
      nodes,
      checksum,
    },
  }, {
    token: guestToken,
    amplitudeScope,
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
);

export const saveSuggestion: any = ({
  id,
  boxId,
  dataId,
  nodes,
  checksum,
  guestToken,
}) => (
  post({
    url: `/agreements/${id}/box/${boxId}/data/${dataId}/suggestion`,
    body: {
      nodes,
      checksum,
    },
  }, {
    token: guestToken,
  })
    .then((response) => response.json())
    .then(camelizeAgreement)
);

type GetDocumentDelayedEventsParams = {
  eventTypes?: Array<string>,
  sort?: Array<string>,
  agreementId: number,
};

type DelayedEventsResponse = Promise<{
  collection: Array<DelayedEvent>,
  count: number,
}>

type GetDocumentDelayedEvents = GetDocumentDelayedEventsParams => DelayedEventsResponse;

export const getDocumentDelayedEvents: GetDocumentDelayedEvents = ({
  eventTypes,
  agreementId,
  sort,
}) => (
  getMultiple({
    url: `/agreements/${agreementId}/delayed_events/`,
    params: decamelizeKeys({
      eventTypes,
      agreementId,
      sort,
    }),
  })
    .then(extractResponseBodyAsJSON)
);
type ScheduleInternalReminder = ({
  id: number,
  agreementId: number,
  recipients: Array<number>,
  date: string,
  subject: string,
  message: string,
}) => Promise<any>;

export const scheduleInternalReminder: ScheduleInternalReminder = ({
  agreementId,
  recipients,
  date,
  subject,
  message,
}) => post({
  url: '/agreements/delayed_events/',
  body: {
    agreement_id: agreementId,
    to_positions: recipients,
    date_time: date,
    subject,
    message,
  },
}).then(extractResponseBodyAsJSON);

type ReScheduleInternalReminder = ({
  agreementId: number,
  recipients: Array<number>,
  date: string,
  subject: string,
  message: string,
  internalReminderId: number,
}) => Promise<any>;

// Contract id will be figured out by API since the event already has contract id
export const reScheduleInternalReminder: ReScheduleInternalReminder = ({
  recipients,
  date,
  subject,
  message,
  internalReminderId,
}) => put({
  url: `/agreements/delayed_events/${internalReminderId}`,
  body: ({
    to_positions: recipients,
    date_time: date,
    subject,
    message,
  }),
}).then(extractResponseBodyAsJSON);

type RemoveInternalReminder = ({
  agreementId: number,
  internalReminderId: number,
}) => Promise<any>;

// Contract id will be figured out by API since the event already has agreement id
export const removeInternalReminder: RemoveInternalReminder = ({
  internalReminderId,
}) => (
  remove({
    url: `/agreements/delayed_events/${internalReminderId}`,
  }).then(extractResponseBodyAsJSON)
);

type TerminateAgreement = ({
  id: number,
}) => Promise<Object>;

export const terminateAgreement: TerminateAgreement = ({
  id,
}, options) => (
  post({
    url: `/agreements/${id}/cancel`,
    body: {
      manual: 1,
    },
  }, options)
    .then((response) => response.json())
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);

type SendConcludedAgreementCopy = ({
  agreementId: number,
  participantId: number,
}) => Promise<any>;

export const sendConcludedAgreementCopy: SendConcludedAgreementCopy = ({
  agreementId,
  participantId,
}) => (
  post({
    url: `/agreements/${agreementId}/participants/${participantId}/send_concluded_copy`,
    body: {},
  })
    .then(extractResponseBodyAsJSON)
);

type ReplaceAgreement = ({
  agreementId: number,
  workspaceId: number,
  sourceId: number,
  amplitudeScope?: string,
}) => Promise<any>;

export const replaceAgreement: ReplaceAgreement = ({
  agreementId,
  workspaceId,
  sourceId,
  amplitudeScope,
}) => (
  post({
    url: `/agreements/${agreementId}/replace`,
    body: decamelizeKeys({
      collectionId: workspaceId,
      sourceId,
    }, { separator: '_' }),
  }, { amplitudeScope })
    .then(extractResponseBodyAsJSON)
    .then(camelizeAgreement)
    .then(normalizeAgreement)
);
type AddParticipantParams = {
  agreementCompany: number,
  deliveryChannel: number,
  email: string,
  fullname?: string,
  companyName?: string,
  companyRegNumber?: string,
  country?: string,
  id: number,
  individual?: number,
  invite: number,
  isDelegate?: boolean,
  isFormerColleague?: boolean,
  isPrivate: number,
  jobTitle?: string,
  message: string,
  personalIdentification?: string,
  phoneNumber?: string,
  positionId?: number,
  saveToAddressBook?: boolean,
  signMethod?: number,
  subject: string,
  twoStepAuthenticationMethod?: string,
  type?: number,
  token?: string,
  flowId?: number,
  blockIndex?: number,
  pendingStateFlowId?: number,
  signOrderBlockIndex?: number,
};

type AddParticipant = (
  addParticipantParams: AddParticipantParams,
) => Promise<Object>;

export const addParticipant: AddParticipant = ({
  agreementCompany,
  companyRegNumber,
  deliveryChannel,
  email,
  fullname,
  companyName,
  country,
  id,
  individual,
  invite,
  isDelegate,
  isFormerColleague,
  isPrivate,
  jobTitle,
  message,
  personalIdentification,
  phoneNumber,
  positionId,
  saveToAddressBook,
  signMethod,
  subject,
  twoStepAuthenticationMethod,
  type,
  token,
  flowId,
  blockIndex,
  pendingStateFlowId,
  signOrderBlockIndex,
}) => {
  const body = {};
  if (id) {
    body.agreement = id;
  }
  if (agreementCompany) {
    body.agreement_company = agreementCompany;
  }
  if (email) {
    body.email = email;
  }
  if (isNumber(deliveryChannel)) {
    body.delivery_channel = deliveryChannel;
  }
  if (fullname) {
    body.fullname = fullname;
  }
  if (positionId) {
    body.position_id = positionId;
  }
  if (isNumber(invite)) {
    body.invite = invite;
  }
  if (isNumber(isPrivate)) {
    body.private = isPrivate;
  }
  if (jobTitle) {
    body.title = jobTitle;
  }
  if (isString(subject)) {
    body.subject = subject;
  }
  if (isString(message)) {
    body.message = message;
  }
  if (personalIdentification) {
    body.ssn = personalIdentification;
  }
  if (phoneNumber && phoneNumber.length > 1) {
    body.phone_number = phoneNumber;
  }
  if (isNumber(type)) {
    body.type = type;
    body.roles = [];

    if (type === TYPE_IS_INTERNAL_APPROVER) {
      body.type = TYPE_IS_ORGANIZER;
      body.roles = [
        {
          role: 'DRAFT_APPROVER',
          block_index: blockIndex || 0,
          agreement_flow_id: flowId || null,
        },
      ];
    } else if (signOrderBlockIndex) {
      switch (type) {
        case TYPE_IS_EXTERNAL_APPROVER:
          body.type = TYPE_IS_INFLUENCER;
          body.roles = [
            {
              role: 'PENDING_STATE_APPROVER',
              block_index: signOrderBlockIndex || 1,
              agreement_flow_id: pendingStateFlowId || null,
            },
          ];
          break;

        case TYPE_IS_SIGNATORY:
          body.roles = [
            {
              role: 'PENDING_STATE_SIGNATORY',
              block_index: signOrderBlockIndex || 1,
              agreement_flow_id: pendingStateFlowId || null,
            },
          ];
          break;

        default:
          body.roles = [
            {
              role: 'PENDING_STATE_PARTICIPANT',
              block_index: signOrderBlockIndex || 1,
              agreement_flow_id: pendingStateFlowId || null,
            },
          ];
          break;
      }
    }
  }
  if (saveToAddressBook) {
    body.save_to_address_book = saveToAddressBook;
  }
  if (isNumber(signMethod)) {
    body.sign_method = signMethod;
  }
  if (twoStepAuthenticationMethod) {
    body.mfa_channel = twoStepAuthenticationMethod;
  }
  if (deliveryChannel === DELIVERY_CHANNEL_SAME_DEVICE) {
    body.mfa_channel = BODY_MFA_CHANNEL_NONE;
  }
  if (isNumber(isDelegate)) {
    body.delegate = isDelegate;
  }
  if (isFormerColleague) {
    body.former = 1;
    body.delivery_channel = 4;
    body.invite = 0;
  }
  if (individual) {
    body.individual = individual;
  }
  if (companyName) {
    body.company_name = companyName;
  }
  if (companyRegNumber) {
    body.company_orgnr = companyRegNumber;
  }
  if (country) {
    body.company_country = country;
  }

  return post({
    url: `/agreements/${id}/participants/`,
    body,
  }, {
    token,
  })
    .then((response) => response.json())
    .then((participant) => participant.agreement)
    .then(camelizeAgreement)
    .then(normalizeAgreement);
};

type UpdateParticipant = (
  updateParticipantParams: AddParticipantParams,
) => Promise<Object>;

export const updateParticipant: UpdateParticipant = ({
  agreement,
  deliveryChannel,
  email,
  fullname,
  id,
  invite,
  isDelegate,
  isFormerColleague,
  isPrivate,
  jobTitle,
  message,
  personalIdentification,
  phoneNumber,
  positionId,
  saveToAddressBook,
  signMethod,
  subject,
  twoStepAuthenticationMethod,
  type,
  token,
  country,
  flowId,
  blockIndex,
  pendingStateFlowId,
  signOrderBlockIndex,
}) => {
  const body = {};
  if (agreement) {
    body.agreement = agreement;
  }
  if (isNumber(deliveryChannel)) {
    body.delivery_channel = deliveryChannel;
  }
  if (email) {
    body.email = email;
  }
  if (fullname) {
    body.fullname = fullname;
  }
  if (positionId) {
    body.position_id = positionId;
  }
  if (isNumber(invite)) {
    body.invite = invite;
  }
  if (isNumber(isPrivate)) {
    body.private = isPrivate;
  }
  if (jobTitle) {
    body.title = jobTitle;
  }
  if (isString(subject)) {
    body.subject = subject;
  }
  if (isString(message)) {
    body.message = message;
  }
  if (personalIdentification) {
    body.ssn = personalIdentification;
  }

  if (phoneNumber) {
    body.phone_number = phoneNumber === '+' ? '' : phoneNumber;
  }
  if (isNumber(type)) {
    body.type = type;
    body.roles = [];

    if (type === TYPE_IS_INTERNAL_APPROVER) {
      body.type = TYPE_IS_ORGANIZER;
      body.roles = [
        {
          role: 'DRAFT_APPROVER',
          block_index: blockIndex || 0,
          agreement_flow_id: flowId || null,
        },
      ];
    } else if (signOrderBlockIndex) {
      switch (type) {
        case TYPE_IS_EXTERNAL_APPROVER:
          body.type = TYPE_IS_INFLUENCER;
          body.roles = [
            {
              role: 'PENDING_STATE_APPROVER',
              block_index: signOrderBlockIndex || 1,
              agreement_flow_id: pendingStateFlowId || null,
            },
          ];
          break;

        case TYPE_IS_SIGNATORY:
          body.roles = [
            {
              role: 'PENDING_STATE_SIGNATORY',
              block_index: signOrderBlockIndex || 1,
              agreement_flow_id: pendingStateFlowId || null,
            },
          ];
          break;

        default:
          body.roles = [
            {
              role: 'PENDING_STATE_PARTICIPANT',
              block_index: signOrderBlockIndex || 1,
              agreement_flow_id: pendingStateFlowId || null,
            },
          ];
          break;
      }
    }
  }

  if (saveToAddressBook) {
    body.save_to_address_book = saveToAddressBook;
  }
  if (isNumber(signMethod)) {
    body.sign_method = signMethod;
  }
  if (twoStepAuthenticationMethod) {
    body.mfa_channel = twoStepAuthenticationMethod;
  }
  if (deliveryChannel === DELIVERY_CHANNEL_SAME_DEVICE) {
    body.mfa_channel = BODY_MFA_CHANNEL_NONE;
  }
  if (isNumber(isDelegate)) {
    body.delegate = isDelegate;
  }
  if (country) {
    body.country = country;
  }
  if (isFormerColleague) {
    body.former = 1;
    body.delivery_channel = 4;
    body.invite = 0;
  }

  return put({
    url: `/agreements/${agreement}/participants/${id}`,
    body,
  }, {
    token,
  })
    .then((response) => response.json())
    .then((participant) => participant.agreement)
    .then(camelizeAgreement)
    .then(normalizeAgreement);
};

type ToggleNotificationsGuest = ({
  agreementId: number,
  participantId: number,
  notifications: boolean,
  token: string,
}) => Promise<Object>;

export const toggleNotificationsGuest: ToggleNotificationsGuest = ({
  agreementId,
  participantId,
  notifications,
  token,
}) => put({
  url: `/agreements/${agreementId}/participants/${participantId}`,
  body: {
    notifications,
  },
}, {
  token,
})
  .then((response) => response.json())
  .then((participant) => participant.agreement)
  .then(camelizeAgreement)
  .then(normalizeAgreement);

type RemoveParticipant = ({
  id: number,
  token?: string,
}) => Promise<{}>;

export const removeParticipant: RemoveParticipant = ({ agreement, id, token }) => post({
  url: `/agreements/${agreement}/participants/${id}/disable`,
}, {
  token,
})
  .then((response) => response.json())
  .then((participant) => participant.agreement)
  .then(camelizeAgreement)
  .then(normalizeAgreement);

type UpdateParty = (
  updatePartyParams: AddParticipantParams,
) => Promise<Object>;

export const updateParty: UpdateParty = ({
  agreement,
  id,
  companyName,
  country,
  companyRegNumber,
  saveToAddressBook,
  token,
}) => {
  const body = {};
  if (agreement) {
    body.agreement = agreement;
  }
  if (companyName) {
    body.name = companyName;
  }
  if (country) {
    body.country = country;
  }

  body.orgnr = companyRegNumber;

  if (saveToAddressBook) {
    body.save_to_address_book = saveToAddressBook;
  }

  return put({
    url: `/agreements/${agreement}/parties/${id}`,
    body,
  }, {
    token,
  })
    .then((response) => response.json())
    .then((party) => party.agreement)
    .then(camelizeAgreement)
    .then(normalizeAgreement);
};

type RemoveParty = ({
  id: number,
  token?: string,
}) => Promise<{}>;

export const removeParty: RemoveParty = ({ agreement, id, token }) => post({
  url: `/agreements/${agreement}/parties/${id}/disable`,
}, {
  token,
})
  .then((response) => response.json())
  .then((party) => party.agreement)
  .then(camelizeAgreement)
  .then(normalizeAgreement);

type PostMessage = ({
  id: number,
  recipients: Array<number>,
  isPrivate: boolean,
  message: string,
  guestToken: string,
  parentId: number,
  messageScope: string,
  mentionedParticipantIds: Array<number>,
  amplitudeScope?: 'contract view - popover' | 'collaboration menu',
}) => Promise<Agreement>;

export const postMessage: PostMessage = ({
  id,
  recipients,
  isPrivate,
  message,
  guestToken,
  messageScope,
  parentId,
  mentionedParticipantIds,
  amplitudeScope,
}) => {
  const body = {
    body: message,
    justPosted: true,
    to_participant_ids: recipients,
    agreement: id,
    private: isPrivate ? 1 : 0,
    comment_scope: messageScope,
    mentioned_participant_ids: mentionedParticipantIds,
  };
  if (parentId) {
    body.parent_id = parentId;
  }

  return (
    post({
      url: `/agreements/${id}/messages/`,
      body,
    }, {
      token: guestToken,
      amplitudeScope,
    })
      .then((response) => response.json())
      .then(camelizeAgreement)
  );
};

export const trackAgreement = (agreement: Oneflow.Agreement, guestToken: string) => {
  const myParticipant = getAgreementMyParticipant(agreement);

  if (
    isTemplate(agreement)
    || isDraft(agreement)
    || isEmpty(myParticipant)
    || guestToken === PREVIEW
  ) {
    return null;
  }

  return post({
    url: `/agreements/${agreement.id}/track`,
  }, {
    token: guestToken,
  })
    .then(camelizeAgreement);
};
