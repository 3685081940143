import Svg from 'components/svg';
import { uniqueId } from 'lodash';

const Fragment = () => {
  const id = uniqueId('clip0_12820_88511');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M11.4287 8.57227L8.00014 5.14369L4.57157 8.57227" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 15.4294L8 5.14373" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 0.572266L4 0.572266" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="16" height="16" transform="translate(16 16) rotate(180)" />
        </clipPath>
      </defs>
    </>
  );
};

const Publish = Svg(Fragment, {
  viewBox: '0 0 16 16',
  fill: 'none',
});

Publish.displayName = 'Publish';

export default Publish;
