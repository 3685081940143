// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { FormValidator } from './index';

type PasswordConfirmationValidator = (message: MessageTranslator) => FormValidator;

const passwordConfirmation: PasswordConfirmationValidator = (message) => (values) => {
  const errors = {};

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = message({
      id: "The password confirmation doesn't match",
      comment: 'Showed when the two password fields are not a match when changing the password',
    });
  }

  return errors;
};

export default passwordConfirmation;
