// @flow

import pick from 'lodash/pick';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { decamelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import {
  camelize,
  get,
  getMultiple,
  post,
  put,
  remove,
  hasAnyEntity,
  extractResponseBodyAsJSON,
} from 'oneflow-client/core';
import { accountSchema, workspaceSchema } from 'oneflow-client/workspaces';

const groupSchema = new schema.Entity('groups', {
  account: accountSchema,
});

const positionSchema = new schema.Entity('positions', {
  account: accountSchema,
  collections: [workspaceSchema],
});

groupSchema.define({
  positions: [positionSchema],
});

const userSchema = new schema.Entity('users', {
  positions: [positionSchema],
});

positionSchema.define({
  user: userSchema,
});

export {
  positionSchema,
  groupSchema,
};

export type NormalizedPositions = {
  entities: {
    collections: {
      [number]: Workspace,
    },
    accounts: {
      [number]: Account,
    },
    positions: {
      [number]: Position,
    },
    groups: {
      [number]: Group,
    },
  },
  result: number | Array<number>,
  count: number,
}

type PositionNormalizer = (any) => NormalizedPositions;

export const normalizePositions: PositionNormalizer = (positions) => ({
  ...normalize(positions.collection, [positionSchema]),
  count: positions.count,
});

const normalizePosition: PositionNormalizer = (positionData) => ({
  ...normalize(positionData, positionSchema),
  count: 1,
});

type GetPositions = ({
  params?: {},
  pagination?: {},
  defaultSort?: string,
  sort?: Array<string>,
  normalizeOutput?: boolean,
}) => Promise<NormalizedPositions>;

const defaultPositionsSorting = { attr: 'fullname', direction: 'asc' };

export const getPositions: GetPositions = ({
  params,
  pagination,
  defaultSort = 'fullname',
  sort,
  normalizeOutput = true,
}) => {
  let composedSortValues = [defaultSort];

  if (Array.isArray(sort) && sort.length > 0) {
    const isDefaultSortKeyIncluded = sort.some((attr) => attr.includes(defaultSort));
    if (!isDefaultSortKeyIncluded) {
      composedSortValues = [...sort, defaultSort];
    } else {
      composedSortValues = sort;
    }
  }
  const encodedSortValues = composedSortValues.map((attribute) => encodeURIComponent(attribute));

  const request = {
    url: '/positions/',
    params: decamelizeKeys(params, { separator: '_' }),
    pagination,
    sort: encodedSortValues,
  };

  const positionsPromise = getMultiple(request)
    .then(extractResponseBodyAsJSON);

  if (!normalizeOutput) {
    return positionsPromise;
  }

  return positionsPromise.then(normalizePositions);
};

type RunExport = (Query) => Promise<NormalizedPositions>;
export const runExport: RunExport = ({
  params,
  pagination,
}: Query) => (
  getMultiple({
    url: '/positions/',
    params: {
      ...params,
      export: 1,
    },
    pagination,
    sorting: defaultPositionsSorting,
  })
    .then(extractResponseBodyAsJSON)
);

export const hasAnyPosition = hasAnyEntity({ url: '/positions/' });

type GetPosition = ({
  id: number,
}) => Promise<NormalizedPositions>;

export const getPosition: GetPosition = ({ id }) => (
  get({ url: `/positions/${id}` })
    .then(extractResponseBodyAsJSON)
    .then(normalizePosition)
);

type GetMeAsJSON = () => Promise<{}>;

export const getMeAsJSON: GetMeAsJSON = () => (
  get(
    { url: '/positions/me' },
    { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
  ).then((response) => response.json())
);

type GetMeData = {} => Promise<NormalizedPositions>;

export const getMeData: GetMeData = async (responseJSON) => (
  normalizePosition(camelize(responseJSON))
);

type GetMe = () => Promise<NormalizedPositions>;

export const getMe: GetMe = () => (
  getMeAsJSON()
    .then(getMeData)
);

type CreatePosition = ({
  fullname: string,
  email: string,
  workspaceIds: Array<number>,
  workspaceRoleId?: number,
  accountRoleId?: number,
  groupIds: Array<number>,
  language: EnabledLanguages,
  userRole: UserRoleType,
}) => Promise<NormalizedPositions>;

export const createPosition: CreatePosition = ({
  fullname,
  email,
  workspaceIds,
  workspaceRoleId,
  accountRoleId,
  groupIds,
  language,
  userRole,
}) => (
  post({
    url: '/positions/',
    body: decamelizeKeys({
      fullname,
      email,
      collectionIds: workspaceIds,
      workspaceRoleId,
      accountRoleId,
      groupIds,
      language,
      invite: 1,
      userRole,
    }, {
      separator: '_',
    }),
  }).then(extractResponseBodyAsJSON)
    .then(normalizePosition)
);

const POSITION_ALLOWED_ATTRIBUTES = [
  'active',
  'fullname',
  'phoneNumber',
  'title',
  'signature',
  'email',
  'language',
  'password',
  'passwordVerification',
  'userRole',
];
type UpdatePosition = ({
  id: number,
  active?: 0 | 1,
  fullname?: string,
  email?: string,
  phoneNumber?: string,
  title?: string,
  signature?: string,
  language?: string,
  password?: string,
  passwordVerification?: string,
  userRole?: string,
}) => Promise<NormalizedPositions>;

export const updatePosition: UpdatePosition = ({
  id,
  ...optionalAttributes
}) => (
  put({
    url: `/positions/${id}`,
    body: decamelizeKeys(
      pick(optionalAttributes, POSITION_ALLOWED_ATTRIBUTES),
      { separator: '_' },
    ),
  })
    .then(extractResponseBodyAsJSON)
    .then(normalizePosition)
);

type ResendInvitation = ({
  id: number,
}) => Promise<{}>;

export const resendUserInvitation: ResendInvitation = ({ id }) => (
  post({
    url: `/positions/${id}/resend`,
    body: {},
  })
    .then(extractResponseBodyAsJSON)
);

const POSITION_SETTINGS_ALLOWED_ATTRIBUTES = [
  'positionNotificationsEmailAgreementCancel',
  'positionNotificationsEmailAgreementClose',
  'positionNotificationsEmailAgreementExpired',
  'positionNotificationsEmailAgreementMessage',
  'positionNotificationsEmailAgreementPublish',
  'positionNotificationsEmailAgreementRevived',
  'positionNotificationsEmailAgreementUpdate',
  'positionNotificationsEmailLifecycleEnd',
  'positionNotificationsEmailLifecycleNewPeriod',
  'positionNotificationsEmailLifecycleStart',
  'positionNotificationsEmailLifecycleTerminated',
  'positionNotificationsEmailParticipantEmailFeedback',
  'positionNotificationsEmailParticipantFirstVisit',
  'positionNotificationsEmailParticipantSign',
  'positionNotificationsEmailPartiesUpdate',
  'positionNotificationsEmailReminderExpired',
  'positionNotificationsEmailReminderLifecycles',
  'positionNotificationsRecieveAccountContractWins',
];
type UpdatePositionSettings = ({
  id: number,
  positionNotificationsEmailAgreementCancel: '1' | '0',
  positionNotificationsEmailAgreementClose: '1' | '0',
  positionNotificationsEmailAgreementExpired: '1' | '0',
  positionNotificationsEmailAgreementMessage: '1' | '0',
  positionNotificationsEmailAgreementPublish: '1' | '0',
  positionNotificationsEmailAgreementRevived: '1' | '0',
  positionNotificationsEmailAgreementUpdate: '1' | '0',
  positionNotificationsEmailLifecycleEnd: '1' | '0',
  positionNotificationsEmailLifecycleNewPeriod: '1' | '0',
  positionNotificationsEmailLifecycleStart: '1' | '0',
  positionNotificationsEmailLifecycleTerminated: '1' | '0',
  positionNotificationsEmailParticipantEmailFeedback: '1' | '0',
  positionNotificationsEmailParticipantFirstVisit: '1' | '0',
  positionNotificationsEmailParticipantSign: '1' | '0',
  positionNotificationsEmailPartiesUpdate: '1' | '0',
  positionNotificationsEmailReminderExpired: '1' | '0',
  positionNotificationsEmailReminderLifecycles: '1' | '0',
  positionNotificationsRecieveAccountContractWins: '1' | '0',
}) => Promise<{}>;

export const updatePositionSettings: UpdatePositionSettings = ({ id, ...optionalAttributes }) => (
  put({
    url: `/positions/${id}/settings`,
    body: decamelizeKeys(
      pick(optionalAttributes, POSITION_SETTINGS_ALLOWED_ATTRIBUTES),
      { separator: '-' },
    ),
  })
    .then(extractResponseBodyAsJSON)
);

type RevokeTrustedDevices = ({
  id: number,
}) => Promise<{}>;

export const revokeTrustedDevices: RevokeTrustedDevices = ({ id }) => (
  remove({
    url: `/positions/${id}/mfa/trusted_devices/`,
  })
    .then(extractResponseBodyAsJSON)
);

export type FetchColleaguesParams = {
  q?: string,
  active?: 0 | 1,
  invited: 0 | 1,
  limit?: number,
  offset?: number,
};

export type FetchColleagues = (
  params: FetchColleaguesParams,
) => Promise<any>;

export const fetchColleagues: FetchColleagues = ({
  active,
  invited,
  q,
  limit,
  offset = 0,
}: FetchColleaguesParams) => {
  let url = `/positions/?invited=${invited}`;
  if (isNumber(active)) {
    url = `${url}&active=${active}`;
  }
  if (isNumber(limit)) {
    url = `${url}&limit=${limit}&offset=${offset}`;
  }
  if (isString(q)) {
    url = `${url}&q=${q}`;
  }

  return get({
    url,
  }).then(extractResponseBodyAsJSON);
};
