import { normalize, schema } from 'normalizr';

import { roleSchema } from 'oneflow-client/roles';

export const bindingSchema = new schema.Entity('bindings', {
  role: roleSchema,
});

export const normalizeBinding = (binding) => ({
  ...normalize(binding, bindingSchema),
  count: 1,
});
