// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  required?: boolean,
  autoFocus?: boolean,
};

export const MessageTemplateSubject = ({
  message,
  required,
  autoFocus,
}: Props) => {
  let label = message({ id: 'Subject', comment: 'Subject for a message template' });
  if (!required) {
    label = message({ id: 'Subject (optional)', comment: 'Subject for a message template that is optional' });
  }

  return (
    <Field
      name="subject"
      label={label}
      placeholder={message({ id: 'Enter subject', comment: 'The placeholder of the subject for a message template' })}
      component={TextField}
      maxLength={150}
      required={required}
      autoFocus={autoFocus}
    />
  );
};

MessageTemplateSubject.defaultProps = {
  required: false,
  autoFocus: undefined,
};

export default localize<Props>(MessageTemplateSubject);
