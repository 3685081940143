// @flow

import React from 'react';
import { Message, localize, type MessageTranslator } from '@oneflowab/pomes';

import {
  PublicPasswordField,
  PublicPasswordConfirmationField,
} from 'components/fields';

type Props = {
  message: MessageTranslator,
};

export const ResetPasswordWithTokenFieldsComponent = ({ message }: Props) => (
  <>
    <p>
      <Message
        id="You will need to log in again after confirming your new password."
        comment="Help text on the reset password page."
      />
    </p>
    <PublicPasswordField
      autoFocus
      placeholder={message({
        id: 'New password',
        comment: 'New password placeholder on the reset password with token page.',
      })}
      hideRequired
    />
    <PublicPasswordConfirmationField
      hideRequired
    />
  </>
);

export default localize<Props>(ResetPasswordWithTokenFieldsComponent);
