// @flow

import React from 'react';

import Svg from 'components/svg';

const AIAssist = Svg((
  <>
    <path d="M3.61009 17.75H0.398438L4.56996 5.38636H8.5483L12.7198 17.75H9.50817L6.6044 8.50142H6.50781L3.61009 17.75ZM3.18146 12.8842H9.89453V15.1541H3.18146V12.8842ZM17.0392 5.38636V17.75H14.051V5.38636H17.0392Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.9429 5.91429L36.85 3L35.7571 5.91429L33.7 6.6L35.7571 7.28571L36.85 10.2L37.9429 7.28571L40 6.6L37.9429 5.91429ZM36.1429 16.7143L35.05 13.8L33.9571 16.7143L31.9 17.4L33.9571 18.0857L35.05 21L36.1429 18.0857L38.2 17.4L36.1429 16.7143ZM29.6135 9.73784L27.85 4.8L26.0865 9.73784L22 11.1L26.0865 12.4622L27.85 17.4L29.6135 12.4622L33.7 11.1L29.6135 9.73784Z" fill="currentColor" />
    {' '}
  </>
), {
  viewBox: '0 0 40 22',
  fill: 'none',
});

AIAssist.displayName = 'AIAssist';

export default AIAssist;
