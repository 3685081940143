import React from 'react';
import clsx from 'clsx';

import style from './block-header.module.scss';

type Props = {
  title: React.ReactNode,
  subheader?: React.ReactNode,
  optionalParagraph?: React.ReactNode,
  headerClassName?: string
};

const Header = (props: Props) => {
  const {
    title,
    subheader = null,
    optionalParagraph = null,
    headerClassName = '',
  } = props;

  const headerClasses = clsx(style.Header, {
    [headerClassName]: headerClassName,
    [style.Header]: !headerClassName,
  });

  return (
    <div className={headerClasses}>
      <h2>
        {title}
      </h2>
      {subheader}
      <p>
        {optionalParagraph}
      </p>
    </div>
  );
};

export default Header;
