import Svg from 'components/svg';

const Minus = Svg((
  <>
    <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.5 7h13" strokeWidth="1" />
  </>
), { viewBox: '0 0 14 14', fill: 'none' });

Minus.displayName = 'Minus';

export default Minus;
