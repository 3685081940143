// @flow

import React from 'react';

import Svg from 'components/svg';

const AddTable = Svg((
  <>
    <path d="M21 20L21 4L19 4L19 20L21 20Z" fill="currentColor" />
    <path d="M5 20L5 4L3 4L3 20L5 20Z" fill="currentColor" />
    <path d="M13 20L13 4L11 4L11 20L13 20Z" fill="currentColor" />
    <path d="M5 8L19 8L19 4L5 4L5 8Z" fill="currentColor" />
    <path d="M5 20L19 20L19 18L5 18L5 20Z" fill="currentColor" />
    <path d="M5 14L19 14L19 12L5 12L5 14Z" fill="currentColor" />
  </>
), { viewBox: '0 0 24 24' });

AddTable.displayName = 'AddTable';

export default AddTable;
