// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  usedExternalKeys: Array<string>,
};

export class DataFieldExternalKey extends React.PureComponent<Props> {
  isExternalKeyUsed = ({ params }: any) => {
    const { usedExternalKeys } = this.props;

    return usedExternalKeys.includes(params.externalKey);
  };

  render() {
    const { message } = this.props;

    return (
      <Field
        name="externalKey"
        label={message({
          id: 'External key',
          comment: 'Label for the external key field for a data field.',
        })}
        placeholder={message({
          id: 'Enter external key',
          comment: 'External key placeholder for a data field.',
        })}
        component={TextField}
        maxLength={100}
        pattern={{
          regexp: /^[a-zA-Z0-9-_]+$/,
          text: message({
            id: 'The external key is not valid. Only A-Z, a-z, 0-9, - and _ are allowed.',
            comment: 'Validation error for not matching pattern for a data field.',
          }),
        }}
        unique={{
          text: message({
            id: 'A data field with this external key already exists. Try another one.',
            comment: 'Validation error for conflicting external key for a data field.',
          }),
          param: 'externalKey',
          request: this.isExternalKeyUsed,
        }}
        required
      />
    );
  }
}

export default localize<Props>(DataFieldExternalKey);
