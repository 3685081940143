// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  autoFocus?: boolean,
  parse?: Function,
  message: MessageTranslator,
};

export const DataFieldDescription = ({ message, autoFocus, parse }: Props) => (
  <Field
    name="description"
    label={message({
      id: 'Description',
      comment: 'Label for the description field for a data field.',
    })}
    placeholder={message({
      id: 'Enter description',
      comment: 'Description placeholder for a data field.',
    })}
    component={TextField}
    maxLength={300}
    autoFocus={autoFocus}
    parse={parse}
  />
);

DataFieldDescription.defaultProps = {
  autoFocus: false,
  parse: undefined,
};

export default localize<Props>(DataFieldDescription);
