import Svg from 'components/svg';

const CommentsResolvedEmpty = Svg((
  <>
    <mask
      id="mask0_9351_22349"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="200"
    >
      <path d="M209.881 0H0V200H209.881V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_9351_22349)">
      <g filter="url(#filter0_d_9351_22349)">
        <path
          d="M25.3105 24.4309C25.3105 22.1823 27.1334 20.3594 29.3821 20.3594H182.066C184.315 20.3594 186.138 22.1823 186.138 24.4309V80.924C186.138 83.1725 184.315 84.9955 182.066 84.9955H29.3821C27.1334 84.9955 25.3105 83.1725 25.3105 80.924V24.4309Z"
          fill="white"
        />
        <mask
          id="mask1_9351_22349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="25"
          y="20"
          width="162"
          height="65"
        >
          <path
            d="M25.3105 24.427C25.3105 22.1784 27.1334 20.3555 29.3821 20.3555H182.066C184.315 20.3555 186.138 22.1784 186.138 24.427V80.9201C186.138 83.1686 184.315 84.9916 182.066 84.9916H29.3821C27.1334 84.9916 25.3105 83.1686 25.3105 80.9201V24.427Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_9351_22349)">
          <path
            d="M29.3822 84.9916C26.0092 84.9916 23.2749 82.257 23.2749 78.8843V26.4628C23.2749 23.0898 26.0092 20.3555 29.3822 20.3555C28.2579 20.3555 27.3464 22.1784 27.3464 24.427V80.9201C27.3464 83.1686 28.2579 84.9916 29.3822 84.9916Z"
            fill="#99B0B7"
          />
        </g>
        <path d="M163.235 51.9141H96.0544V57.5125H163.235V51.9141Z" fill="#D6DFE2" />
        <path d="M91.9828 51.9141H67.0444V57.5125H91.9828V51.9141Z" fill="#D6DFE2" />
        <path d="M62.9727 51.9141H38.0344V57.5125H62.9727V51.9141Z" fill="#D6DFE2" />
        <path
          d="M52.7937 36.1342C52.7937 31.9179 49.3758 28.5 45.1596 28.5C40.9433 28.5 37.5254 31.9179 37.5254 36.1342C37.5254 40.3504 40.9433 43.7684 45.1596 43.7684C49.3758 43.7684 52.7937 40.3504 52.7937 36.1342Z"
          fill="white"
        />
        <path
          d="M52.7937 36.1342C52.7937 31.9179 49.3758 28.5 45.1596 28.5C40.9433 28.5 37.5254 31.9179 37.5254 36.1342C37.5254 40.3504 40.9433 43.7684 45.1596 43.7684C49.3758 43.7684 52.7937 40.3504 52.7937 36.1342Z"
          stroke="#99B0B7"
        />
        <path
          d="M42.7542 33.6595C42.7542 34.2975 43.0077 34.9094 43.4588 35.3605C43.9099 35.8116 44.5218 36.0651 45.1597 36.0651C45.7978 36.0651 46.4096 35.8116 46.8608 35.3605C47.3119 34.9094 47.5653 34.2975 47.5653 33.6595C47.5653 33.0215 47.3119 32.4096 46.8608 31.9585C46.4096 31.5074 45.7978 31.2539 45.1597 31.2539C44.5218 31.2539 43.9099 31.5074 43.4588 31.9585C43.0077 32.4096 42.7542 33.0215 42.7542 33.6595Z"
          stroke="#99B0B7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.2231 41.0184C41.2231 39.9745 41.6378 38.9732 42.3761 38.2349C43.1143 37.4967 44.1156 37.082 45.1595 37.082C46.2035 37.082 47.2048 37.4967 47.943 38.2349C48.6812 38.9732 49.0959 39.9745 49.0959 41.0184"
          stroke="#99B0B7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M107.251 30.5391H56.3567V36.1375H107.251V30.5391Z" fill="#D6DFE2" />
        <path d="M90.965 38.6797H56.3567V41.7334H90.965V38.6797Z" fill="#D6DFE2" />
        <mask
          id="mask2_9351_22349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="170"
          y="33"
          width="8"
          height="8"
        >
          <path d="M170.5 39.6252V33.5H176.625V39.6252H170.5Z" fill="white" stroke="white" />
        </mask>
        <g mask="url(#mask2_9351_22349)">
          <path
            d="M170.509 37.2874L171.793 38.9288C171.836 38.984 171.891 39.0288 171.954 39.06C172.017 39.0912 172.087 39.1079 172.157 39.1089C172.227 39.11 172.297 39.0953 172.361 39.066C172.425 39.0367 172.481 38.9935 172.526 38.9396L176.616 34.0195"
            stroke="#99B0B7"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <mask
          id="mask3_9351_22349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="154"
          y="33"
          width="8"
          height="8"
        >
          <path d="M161.125 33H154V40.1252H161.125V33Z" fill="white" />
        </mask>
        <g mask="url(#mask3_9351_22349)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154.763 33.3047L157.562 36.1039L160.362 33.3047L160.829 33.7726L158.03 36.5718L160.829 39.371L160.362 39.8388L157.562 37.0396L154.763 39.8388L154.296 39.371L157.095 36.5718L154.296 33.7726L154.763 33.3047Z"
            fill="#99B0B7"
            stroke="#99B0B7"
            strokeWidth="0.3"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M38.0344 67.6959C38.0344 66.5717 38.9458 65.6602 40.0702 65.6602H63.9908C65.1151 65.6602 66.0266 66.5717 66.0266 67.6959V72.7854C66.0266 73.9097 65.1151 74.8212 63.9908 74.8212H40.0702C38.9458 74.8212 38.0344 73.9097 38.0344 72.7854V67.6959Z"
          fill="#D6DFE2"
        />
      </g>
      <g filter="url(#filter1_d_9351_22349)">
        <path
          d="M25.3105 100.263C25.3105 98.0145 27.1334 96.1914 29.3821 96.1914H182.066C184.315 96.1914 186.138 98.0145 186.138 100.263V158.792C186.138 161.04 184.315 162.863 182.066 162.863H29.3821C27.1334 162.863 25.3105 161.04 25.3105 158.792V100.263Z"
          fill="white"
        />
        <mask
          id="mask4_9351_22349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="25"
          y="96"
          width="162"
          height="67"
        >
          <path
            d="M25.3105 100.255C25.3105 98.0066 27.1334 96.1836 29.3821 96.1836H182.066C184.315 96.1836 186.138 98.0066 186.138 100.255V158.784C186.138 161.032 184.315 162.856 182.066 162.856H29.3821C27.1334 162.856 25.3105 161.032 25.3105 158.784V100.255Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_9351_22349)">
          <path
            d="M29.3822 162.859C26.0092 162.859 23.2749 160.125 23.2749 156.752V102.295C23.2749 98.9221 26.0092 96.1875 29.3822 96.1875C28.2579 96.1875 27.3464 98.0105 27.3464 100.259V158.788C27.3464 161.036 28.2579 162.859 29.3822 162.859Z"
            fill="#99B0B7"
          />
        </g>
        <path
          d="M52.7937 111.97C52.7937 107.754 49.3758 104.336 45.1596 104.336C40.9433 104.336 37.5254 107.754 37.5254 111.97C37.5254 116.186 40.9433 119.604 45.1596 119.604C49.3758 119.604 52.7937 116.186 52.7937 111.97Z"
          fill="white"
        />
        <path
          d="M52.7937 111.97C52.7937 107.754 49.3758 104.336 45.1596 104.336C40.9433 104.336 37.5254 107.754 37.5254 111.97C37.5254 116.186 40.9433 119.604 45.1596 119.604C49.3758 119.604 52.7937 116.186 52.7937 111.97Z"
          stroke="#99B0B7"
        />
        <path
          d="M42.7542 109.492C42.7542 110.129 43.0077 110.742 43.4588 111.193C43.9099 111.644 44.5218 111.897 45.1597 111.897C45.7978 111.897 46.4096 111.644 46.8608 111.193C47.3119 110.742 47.5653 110.129 47.5653 109.492C47.5653 108.854 47.3119 108.242 46.8608 107.791C46.4096 107.339 45.7978 107.086 45.1597 107.086C44.5218 107.086 43.9099 107.339 43.4588 107.791C43.0077 108.242 42.7542 108.854 42.7542 109.492Z"
          stroke="#99B0B7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.2231 116.855C41.2231 115.811 41.6378 114.809 42.3761 114.071C43.1143 113.333 44.1156 112.918 45.1595 112.918C46.2035 112.918 47.2048 113.333 47.943 114.071C48.6812 114.809 49.0959 115.811 49.0959 116.855"
          stroke="#99B0B7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M118.448 106.371H56.3567V111.969H118.448V106.371Z" fill="#D6DFE2" />
        <path d="M90.965 114.516H56.3567V117.569H90.965V114.516Z" fill="#D6DFE2" />
        <mask
          id="mask5_9351_22349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="168"
          y="108"
          width="8"
          height="8"
        >
          <path d="M175.45 108.406H168.325V115.531H175.45V108.406Z" fill="white" />
        </mask>
        <g mask="url(#mask5_9351_22349)">
          <path
            d="M168.834 112.694L170.118 114.335C170.161 114.39 170.216 114.435 170.279 114.466C170.342 114.497 170.411 114.514 170.482 114.515C170.552 114.516 170.622 114.501 170.686 114.472C170.749 114.443 170.806 114.4 170.851 114.346L174.941 109.426"
            stroke="#99B0B7"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <path d="M149.493 127.746H38.0344V133.344H149.493V127.746Z" fill="#D6DFE2" />
        <path
          d="M38.0344 145.559C38.0344 144.435 38.9458 143.523 40.0702 143.523H63.9908C65.1151 143.523 66.0266 144.435 66.0266 145.559V150.649C66.0266 151.773 65.1151 152.684 63.9908 152.684H40.0702C38.9458 152.684 38.0344 151.773 38.0344 150.649V145.559Z"
          fill="#D6DFE2"
        />
      </g>
      <path
        d="M31.2343 187.566L29.8486 188.952L32.117 191.221L33.5028 189.835L31.2343 187.566Z"
        fill="#99B0B7"
      />
      <path
        d="M36.7901 193.121L35.4043 194.507L37.6727 196.775L39.0585 195.389L36.7901 193.121Z"
        fill="#99B0B7"
      />
      <path
        d="M37.6675 187.562L35.3977 189.833L36.7826 191.217L39.0524 188.948L37.6675 187.562Z"
        fill="#99B0B7"
      />
      <path
        d="M32.1084 193.121L29.8386 195.391L31.2235 196.776L33.4933 194.506L32.1084 193.121Z"
        fill="#99B0B7"
      />
      <path
        d="M202.371 18.2936L202.37 21.5026L204.329 21.502L204.33 18.293L202.371 18.2936Z"
        fill="#99B0B7"
      />
      <path
        d="M202.362 26.1647L202.361 29.3736L204.32 29.3731L204.321 26.1641L202.362 26.1647Z"
        fill="#99B0B7"
      />
      <path
        d="M205.692 22.8525V24.8117L208.901 24.8107L208.902 22.8516L205.692 22.8525Z"
        fill="#99B0B7"
      />
      <path d="M197.821 22.8486V24.8078L201.03 24.8068V22.8477L197.821 22.8486Z" fill="#99B0B7" />
      <path d="M205.888 90.5312L205.887 92.502H207.857L207.858 90.5312H205.888Z" fill="#99B0B7" />
      <path d="M201.339 95.0909V97.061L203.31 97.0605V95.0898L201.339 95.0909Z" fill="#99B0B7" />
      <path
        d="M201.355 85.981L201.354 87.9516L203.324 87.9511L203.325 85.9805L201.355 85.981Z"
        fill="#99B0B7"
      />
      <path
        d="M196.798 90.5435L196.797 92.5136H198.768L198.769 90.543L196.798 90.5435Z"
        fill="#99B0B7"
      />
      <path
        d="M195.645 9.64062L194.252 11.0344L195.645 12.4273L197.038 11.0335L195.645 9.64062Z"
        fill="#99B0B7"
      />
      <path
        d="M189.22 9.65234L187.826 11.046L189.219 12.4389L190.613 11.0452L189.22 9.65234Z"
        fill="#99B0B7"
      />
      <path
        d="M195.655 3.21875L194.261 4.61248L195.654 6.00537L197.048 4.61164L195.655 3.21875Z"
        fill="#99B0B7"
      />
      <path
        d="M189.222 3.22266L187.828 4.61639L189.221 6.00927L190.615 4.61554L189.222 3.22266Z"
        fill="#99B0B7"
      />
      <path
        d="M11.5018 189.34L11.5012 191.311L13.4716 191.31L13.4722 189.34H11.5018Z"
        fill="#99B0B7"
      />
      <path d="M6.97264 193.898L6.97168 195.869H8.94211L8.94308 193.898H6.97264Z" fill="#99B0B7" />
      <path
        d="M6.98822 184.79L6.9873 186.76H8.95774L8.95866 184.789L6.98822 184.79Z"
        fill="#99B0B7"
      />
      <path d="M2.43184 189.352L2.43091 191.322H4.40134L4.40228 189.352H2.43184Z" fill="#99B0B7" />
      <path
        d="M11.0265 192L9.63281 193.393L11.0257 194.786L12.4194 193.393L11.0265 192Z"
        fill="#99B0B7"
      />
      <path
        d="M4.60882 192.02L3.21509 193.414L4.60798 194.806L6.00172 193.413L4.60882 192.02Z"
        fill="#99B0B7"
      />
      <path
        d="M11.0441 185.586L9.65039 186.98L11.0433 188.373L12.437 186.979L11.0441 185.586Z"
        fill="#99B0B7"
      />
      <path
        d="M4.61126 185.586L3.21753 186.98L4.61042 188.373L6.00416 186.979L4.61126 185.586Z"
        fill="#99B0B7"
      />
      <path
        d="M3.74056 164.837L3.73999 166.796L6.90878 166.795L4.67083 169.033L6.05578 170.418L8.29367 168.18L8.29275 171.349L10.2513 171.348L10.2523 168.18L12.4888 170.416L13.8746 169.03L11.638 166.794L14.8068 166.793L14.8074 164.834L11.6386 164.835L13.8766 162.597L12.4916 161.212L10.2537 163.45L10.2547 160.281L8.29606 160.282L8.29515 163.451L6.05853 161.214L4.67278 162.6L6.90939 164.836L3.74056 164.837ZM6.9412 164.853L8.30309 163.491L10.2298 163.49L11.5909 164.851L11.5903 166.778L10.2284 168.14H8.30166L6.94064 166.779L6.9412 164.853Z"
        fill="#99B0B7"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_9351_22349"
        x="6.11055"
        y="1.15547"
        width="199.227"
        height="103.041"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22349" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22349"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_9351_22349"
        x="6.11055"
        y="76.9875"
        width="199.227"
        height="105.076"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22349" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22349"
          result="shape"
        />
      </filter>
    </defs>
  </>
), {
  fill: 'none',
  viewBox: '0 0 210 200',
});

CommentsResolvedEmpty.displayName = 'CommentsResolvedEmpty';

export { CommentsResolvedEmpty };
