import { create } from 'zustand';

interface DrawerState {
  drawerStates: Record<string, boolean>;
  openDrawer: (id: string) => void;
  closeDrawer: (id: string) => void;
  getDrawerState: (id: string) => boolean;
}

export const useDrawerStore = create<DrawerState>((set, get) => ({
  drawerStates: {},
  openDrawer: (id: string) => {
    set((state) => ({
      drawerStates: { ...state.drawerStates, [id]: true },
    }));
  },
  closeDrawer: (id: string) => {
    set((state) => ({
      drawerStates: { ...state.drawerStates, [id]: false },
    }));
  },
  getDrawerState: (id: string) => Boolean(get().drawerStates[id]),
}));
