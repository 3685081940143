// @flow

import React from 'react';

import Svg from 'components/svg';

const MessageParticipants = Svg((
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.57319 1.64998V10.3362L1.9912 12.0822L4.6124 11.4269L12.3501 11.4269V1.64997L2.57319 1.64998ZM13.6501 0.349976V12.7269L4.77244 12.7269L1.15776 13.6306C0.927605 13.6881 0.684383 13.6157 0.523192 13.4416C0.362 13.2675 0.308446 13.0195 0.383467 12.7944L1.27319 10.1253V0.349994L13.6501 0.349976Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16165 3.06919V7.23842H6.76165V3.06919H8.16165Z"
      fill="currentColor"
    />
    <path
      d="M7.00011 9.30766C7.00011 9.43006 7.04874 9.54746 7.13529 9.63401C7.22185 9.72057 7.33924 9.76919 7.46165 9.76919C7.58406 9.76919 7.70145 9.72057 7.78801 9.63401C7.87456 9.54746 7.92319 9.43006 7.92319 9.30766C7.92319 9.18525 7.87456 9.06785 7.78801 8.9813C7.70145 8.89474 7.58406 8.84612 7.46165 8.84612C7.33924 8.84612 7.22185 8.89474 7.13529 8.9813C7.04874 9.06785 7.00011 9.18525 7.00011 9.30766Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.71103 8.55703C6.91011 8.35796 7.18011 8.24612 7.46165 8.24612C7.74319 8.24612 8.01319 8.35796 8.21227 8.55703C8.41135 8.75611 8.52319 9.02612 8.52319 9.30766C8.52319 9.58919 8.41135 9.8592 8.21227 10.0583C8.01319 10.2574 7.74319 10.3692 7.46165 10.3692C7.18011 10.3692 6.91011 10.2574 6.71103 10.0583C6.51195 9.8592 6.40011 9.58919 6.40011 9.30766C6.40011 9.02612 6.51195 8.75611 6.71103 8.55703ZM7.46165 9.44612C7.49837 9.44612 7.53359 9.43153 7.55956 9.40556C7.58552 9.3796 7.60011 9.34438 7.60011 9.30766C7.60011 9.27093 7.58552 9.23572 7.55956 9.20975C7.53359 9.18378 7.49837 9.16919 7.46165 9.16919C7.42493 9.16919 7.38971 9.18378 7.36374 9.20975C7.33778 9.23572 7.32319 9.27093 7.32319 9.30766C7.32319 9.34438 7.33778 9.3796 7.36374 9.40556C7.38971 9.43153 7.42493 9.44612 7.46165 9.44612Z"
      fill="currentColor"
    />

  </>
), { viewBox: '0 0 14 14', fill: 'none' });

MessageParticipants.displayName = 'MessageParticipants';

export default MessageParticipants;
