// @flow

import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { loginSuccess } from 'public/reducers/login';
import { setCurrentPositionId, getCurrentPositionIdSelector } from 'reducers/app';
import positionMfaReducer from 'reducers/entities/position-mfa';

import type { Props, MfaResponse } from './login-form';
import { LoginForm } from './login-form';

type StateProps = {|
  mfaResponse: MfaResponse,
  requestMfaState: CreateState,
|};

type DispatchProps = {|
  loginSuccess: () => void,
  setPositionMfa: MfaResponse => void,
  requestMfaCode: number => void,
  resetRequestMfaCode: number => void,
|};

type MapStateToProps = (state: State) => StateProps;

export const mapStateToProps: MapStateToProps = (state) => {
  const currentPositionId = getCurrentPositionIdSelector(state);

  return {
    mfaResponse: positionMfaReducer.getPositionMfaSelector(state, {
      id: currentPositionId,
    }),
    requestMfaState: positionMfaReducer.getRequestPositionMfaLoginCodeSelector(state, {
      id: currentPositionId,
    }),
  };
};

type MapDispatchToProps = (dispatch: Dispatch<*>) => DispatchProps;

export const mapDispatchToProps: MapDispatchToProps = (dispatch) => ({
  loginSuccess: () => {
    dispatch(loginSuccess());
  },
  setPositionMfa: (mfaResponse) => {
    dispatch(setCurrentPositionId(mfaResponse.positionId));
    dispatch(positionMfaReducer.setPositionMfas({
      [mfaResponse.positionId]: mfaResponse,
    }));
  },
  requestMfaCode: (id) => {
    dispatch(positionMfaReducer.requestPositionMfaLoginCode({ id }));
  },
  resetRequestMfaCode: (id) => {
    dispatch(positionMfaReducer.requestPositionMfaLoginCodeReset({ id }));
  },
});

const connectedLoginForm = connect<Props, {||}, StateProps, DispatchProps, State, Dispatch<*>>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);

export { connectedLoginForm as LoginForm };
