/* eslint-disable no-console */
import {
  DEFAULT_ROUNDING_PRECISION,
  roundBy,
} from 'utils/math';
import {
  isDiscountAbsolute,
  isDiscountPercentage,
} from 'agreement/boxes/product';
import type {
  DiscountType,
  PriceRoundingMethod,
  Product,
  ProductTableBox,
  ProductValue,
} from 'data-validators/entity-schemas/document-box/product-table';

import {
  PRICE_ROUNDING_METHOD,
  DEFAULT_QUANTITY_PRECISION,
} from 'components/contract-boxes/product-table-box/constants';

type PriceOptions = {
  isDiscounted?: boolean,
  pricePrecision: number,
  priceRoundingMethod: PriceRoundingMethod,
  quantityPrecision: number,
};

function isValidRoundingMethod(method: string) {
  return method === PRICE_ROUNDING_METHOD.LEGACY || method === PRICE_ROUNDING_METHOD.BANKERS;
}

export function getDiscount(
  productValue: ProductValue,
  priceTypeKey: string,
  isDiscounted: boolean,
  priceRoundingMethod: PriceRoundingMethod,
  pricePrecision: number = DEFAULT_ROUNDING_PRECISION,
): number | null {
  // Validate productValue and columnKey
  if (!productValue || typeof productValue !== 'object') {
    console.warn('Invalid productValue: expected an object');
    return null;
  }

  if (typeof priceTypeKey !== 'string') {
    console.warn('Invalid columnKey: expected a string');
    return null;
  }

  if (!isValidRoundingMethod(priceRoundingMethod)) {
    console.warn(`Invalid price rounding method: ${priceRoundingMethod}`);
    return null;
  }

  const roundedProductValue = roundBy(
    priceRoundingMethod,
    productValue[priceTypeKey as keyof ProductValue],
    pricePrecision,
  );

  if (!isDiscounted) return roundedProductValue || 0;

  const discountAmountKey = `${priceTypeKey}_discount_amount`;
  const discountAmount = productValue[discountAmountKey as keyof ProductValue];

  if (discountAmount === null || discountAmount === undefined) {
    return roundedProductValue;
  }

  if (typeof discountAmount !== 'number' || Number.isNaN(discountAmount)) {
    console.warn(`Invalid discount amount for ${priceTypeKey}`);
    return null;
  }

  const productPriceValue = roundedProductValue || 0;
  if (typeof productPriceValue !== 'number' || Number.isNaN(productPriceValue)) {
    console.warn(`Invalid product price for ${priceTypeKey}`);
    return null;
  }

  const discountTypeKey = `${priceTypeKey}_discount_type`;
  const discountType = productValue[discountTypeKey as keyof ProductValue];

  if (!discountAmount) {
    return productPriceValue;
  }

  let discountedAmount: number = productPriceValue;

  if (isDiscountPercentage(discountType as DiscountType)) {
    discountedAmount *= 1 - discountAmount;
  } else if (isDiscountAbsolute(discountType as DiscountType)) {
    discountedAmount -= discountAmount;
  }

  discountedAmount = roundBy(priceRoundingMethod, discountedAmount, pricePrecision);
  return discountedAmount;
}

export const getNumericalValue = (value: string | number): number => {
  const valueNumber = Number(value);
  if (Number.isNaN(valueNumber)) {
    throw new Error(`Cannot convert value to number: ${value}`);
  }

  return valueNumber;
};

const defaultPriceOptions: PriceOptions = {
  isDiscounted: false,
  priceRoundingMethod: PRICE_ROUNDING_METHOD.LEGACY,
  pricePrecision: DEFAULT_ROUNDING_PRECISION,
  quantityPrecision: DEFAULT_QUANTITY_PRECISION,
};

export const calculateTotalPrice = (
  productBox: ProductTableBox,
  priceTypeKey: string,
  {
    isDiscounted = false,
    priceRoundingMethod,
    pricePrecision,
    quantityPrecision,
  }: PriceOptions = defaultPriceOptions,
): number | null => {
  if (
    !productBox
    || !productBox.content
    || !productBox.content.data
    || !productBox.config
    || !productBox.config.columns
  ) {
    console.error('Invalid product box structure');
    return 0;
  }

  if (typeof priceTypeKey !== 'string') {
    console.error('Price type key must be a string');
    return 0;
  }

  const {
    content: { data },
    config,
  } = productBox;

  const calculateCount = (product: Product) => {
    let count = product.value.count || 0;
    const enabledCountColumn = config.columns.find((col) => (
      col.key === 'count' && col.enabled
    ));

    if (config.treatHiddenCountAsOne && !enabledCountColumn) {
      return 1;
    }

    const column = config.columns.find((col) => col.key === priceTypeKey);
    const isPriceColumn = column && (column.key === 'price_1' || column.key === 'price_2');

    if (isPriceColumn && column.fixedAmount) {
      count = Math.min(count, 1);
    }

    return roundBy(
      priceRoundingMethod,
      count,
      quantityPrecision || DEFAULT_QUANTITY_PRECISION,
    );
  };

  const newSum = data.reduce((memo, product) => {
    if (!product || !product.value) {
      console.error('Invalid product structure in product box');
      return 0;
    }

    if (product?._removed) {
      return memo;
    }

    const discountedAmount = getDiscount(
      product.value,
      priceTypeKey,
      isDiscounted,
      priceRoundingMethod,
      pricePrecision,
    );

    const price = (discountedAmount || 0) * calculateCount(product);
    return memo + getNumericalValue(price);
  }, 0);

  return !Number.isNaN(newSum) ? roundBy(priceRoundingMethod, newSum, pricePrecision) : null;
};

export const updateTotalPrices = (
  productBox: ProductTableBox,
  priceRoundingMethod: PriceRoundingMethod = PRICE_ROUNDING_METHOD.BANKERS,
  pricePrecision: number,
  quantityPrecision: number,
): { [key: string]: number | null } => {
  if (!isValidRoundingMethod(priceRoundingMethod)) {
    throw new Error(`Invalid rounding method: ${priceRoundingMethod}`);
  }

  const totals: { [key: string]: number | null } = {};

  ['price_1', 'price_2'].forEach((priceType) => {
    let grossPrice;
    let discountedPrice;
    let roundedGrossPrice;
    let roundedDiscountedPrice;

    try {
      grossPrice = calculateTotalPrice(
        productBox,
        priceType,
        {
          priceRoundingMethod,
          pricePrecision,
          quantityPrecision,
        },
      );
      roundedGrossPrice = roundBy(
        priceRoundingMethod,
        grossPrice,
        pricePrecision,
      );
      discountedPrice = calculateTotalPrice(
        productBox,
        priceType,
        {
          isDiscounted: true,
          priceRoundingMethod,
          pricePrecision,
          quantityPrecision,
        },
      );
      roundedDiscountedPrice = roundBy(
        priceRoundingMethod,
        discountedPrice,
        pricePrecision,
      );
    } catch (error) {
      console.error(`Error calculating prices for ${priceType}: ${(error as Error).message}`);
      roundedGrossPrice = null;
      roundedDiscountedPrice = null;
    }

    totals[priceType] = roundedGrossPrice;
    totals[`${priceType}_discounted`] = roundedDiscountedPrice;
  });

  return totals;
};
