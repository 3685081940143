import Svg from 'components/svg';

const ChatEmpty = Svg((
  <>
    <g clipPath="url(#clip0_9351_22496)">
      <mask
        id="mask0_9351_22496"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="244"
        height="169"
      >
        <path d="M244 0H0V169H244V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_9351_22496)">
        <g filter="url(#filter0_d_9351_22496)">
          <path
            d="M168.261 19.7734H21.6808C19.522 19.7734 17.772 21.5235 17.772 23.6822V76.451C17.772 78.6097 19.522 80.3598 21.6808 80.3598H168.261C170.419 80.3598 172.169 78.6097 172.169 76.451V23.6822C172.169 21.5235 170.419 19.7734 168.261 19.7734Z"
            fill="white"
          />
          <path
            d="M39.2703 35.8954C39.2703 31.8477 35.989 28.5664 31.9413 28.5664C27.8936 28.5664 24.6123 31.8477 24.6123 35.8954C24.6123 39.9431 27.8936 43.2244 31.9413 43.2244C35.989 43.2244 39.2703 39.9431 39.2703 35.8954Z"
            fill="white"
          />
          <path
            d="M39.2703 35.8954C39.2703 31.8477 35.989 28.5664 31.9413 28.5664C27.8936 28.5664 24.6123 31.8477 24.6123 35.8954C24.6123 39.9431 27.8936 43.2244 31.9413 43.2244C35.989 43.2244 39.2703 39.9431 39.2703 35.8954Z"
            stroke="#8EC4E3"
            strokeWidth="1.3"
          />
          <path
            d="M29.6321 33.5164C29.6321 34.129 29.8754 34.7163 30.3085 35.1494C30.7416 35.5825 31.329 35.8259 31.9415 35.8259C32.5539 35.8259 33.1413 35.5825 33.5745 35.1494C34.0075 34.7163 34.2508 34.129 34.2508 33.5164C34.2508 32.9039 34.0075 32.3165 33.5745 31.8834C33.1413 31.4504 32.5539 31.207 31.9415 31.207C31.329 31.207 30.7416 31.4504 30.3085 31.8834C29.8754 32.3165 29.6321 32.9039 29.6321 33.5164Z"
            stroke="#8EC4E3"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.1624 40.5876C28.1624 39.5854 28.5605 38.6241 29.2692 37.9154C29.9779 37.2067 30.9392 36.8086 31.9414 36.8086C32.9436 36.8086 33.9049 37.2067 34.6136 37.9154C35.3223 38.6241 35.7204 39.5854 35.7204 40.5876"
            stroke="#8EC4E3"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M91.5506 30.5234H42.6907V35.898H91.5506V30.5234Z" fill="#D6DFE2" />
          <path d="M75.9154 38.3398H42.6907V41.2714H75.9154V38.3398Z" fill="#D6DFE2" />
          <path
            d="M57.3484 48.6016H30.964C27.7259 48.6016 25.1008 51.2266 25.1008 54.4648C25.1008 57.7029 27.7259 60.3279 30.964 60.3279H57.3484C60.5865 60.3279 63.2116 57.7029 63.2116 54.4648C63.2116 51.2266 60.5865 48.6016 57.3484 48.6016Z"
            fill="#E6EBED"
          />
          <path d="M59.3029 52.5117H29.0098V56.4205H59.3029V52.5117Z" fill="#D6DFE2" />
          <path d="M150.182 66.1875H25.1008V71.5621H150.182V66.1875Z" fill="#D6DFE2" />
        </g>
        <g filter="url(#filter1_d_9351_22496)">
          <path
            d="M217.609 89.6406H71.0292C68.8704 89.6406 67.1204 91.3907 67.1204 93.5494V146.318C67.1204 148.477 68.8704 150.227 71.0292 150.227H217.609C219.768 150.227 221.518 148.477 221.518 146.318V93.5494C221.518 91.3907 219.768 89.6406 217.609 89.6406Z"
            fill="white"
          />
          <path
            d="M88.6187 105.766C88.6187 101.719 85.3374 98.4375 81.2897 98.4375C77.242 98.4375 73.9607 101.719 73.9607 105.766C73.9607 109.814 77.242 113.095 81.2897 113.095C85.3374 113.095 88.6187 109.814 88.6187 105.766Z"
            fill="white"
          />
          <path
            d="M88.6187 105.766C88.6187 101.719 85.3374 98.4375 81.2897 98.4375C77.242 98.4375 73.9607 101.719 73.9607 105.766C73.9607 109.814 77.242 113.095 81.2897 113.095C85.3374 113.095 88.6187 109.814 88.6187 105.766Z"
            stroke="#E0A8CC"
            strokeWidth="1.3"
          />
          <path
            d="M78.9802 103.384C78.9802 103.996 79.2236 104.584 79.657 105.017C80.0899 105.45 80.6772 105.693 81.2899 105.693C81.9026 105.693 82.4899 105.45 82.9228 105.017C83.3561 104.584 83.5995 103.996 83.5995 103.384C83.5995 102.771 83.3561 102.184 82.9228 101.751C82.4899 101.318 81.9026 101.074 81.2899 101.074C80.6772 101.074 80.0899 101.318 79.657 101.751C79.2236 102.184 78.9802 102.771 78.9802 103.384Z"
            stroke="#E0A8CC"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M77.511 110.451C77.511 109.449 77.9087 108.488 78.6177 107.779C79.3262 107.07 80.2877 106.672 81.2898 106.672C82.292 106.672 83.2535 107.07 83.962 107.779C84.6709 108.488 85.0687 109.449 85.0687 110.451"
            stroke="#E0A8CC"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M155.557 100.391H92.0391V105.765H155.557V100.391Z" fill="#D6DFE2" />
          <path d="M125.264 108.207H92.0391V111.139H125.264V108.207Z" fill="#D6DFE2" />
          <path
            d="M106.697 118.469H80.3127C77.0745 118.469 74.4495 121.094 74.4495 124.332C74.4495 127.57 77.0745 130.195 80.3127 130.195H106.697C109.935 130.195 112.56 127.57 112.56 124.332C112.56 121.094 109.935 118.469 106.697 118.469Z"
            fill="#E6EBED"
          />
          <path d="M108.651 122.375H78.3582V126.284H108.651V122.375Z" fill="#D6DFE2" />
          <path
            d="M162.397 118.469H122.332C119.094 118.469 116.469 121.094 116.469 124.332C116.469 127.57 119.094 130.195 122.332 130.195H162.397C165.635 130.195 168.261 127.57 168.261 124.332C168.261 121.094 165.635 118.469 162.397 118.469Z"
            fill="#E6EBED"
          />
          <path d="M164.352 122.375H120.378V126.284H164.352V122.375Z" fill="#D6DFE2" />
          <path d="M181.453 136.055H74.4495V141.429H181.453V136.055Z" fill="#D6DFE2" />
        </g>
        <path
          d="M48.5833 114.23L47.2529 115.561L49.4307 117.739L50.7611 116.408L48.5833 114.23Z"
          fill="#99B0B7"
        />
        <path
          d="M53.9168 119.566L52.5864 120.897L54.7642 123.075L56.0946 121.744L53.9168 119.566Z"
          fill="#99B0B7"
        />
        <path
          d="M54.7591 114.227L52.5801 116.405L53.9097 117.735L56.0887 115.556L54.7591 114.227Z"
          fill="#99B0B7"
        />
        <path
          d="M49.4225 119.566L47.2434 121.746L48.573 123.075L50.752 120.896L49.4225 119.566Z"
          fill="#99B0B7"
        />
        <path
          d="M196.139 76.2583L196.138 78.1502L198.03 78.1497L198.031 76.2578L196.139 76.2583Z"
          fill="#99B0B7"
        />
        <path d="M191.772 80.6372V82.5291L193.664 82.5281V80.6367L191.772 80.6372Z" fill="#99B0B7" />
        <path d="M191.787 71.8906L191.786 73.7825H193.678V71.8906H191.787Z" fill="#99B0B7" />
        <path
          d="M187.413 76.2734L187.412 78.1653L189.303 78.1648L189.304 76.2734H187.413Z"
          fill="#99B0B7"
        />
        <path
          d="M187.609 59.2036L187.608 61.095L189.5 61.0945V59.2031L187.609 59.2036Z"
          fill="#99B0B7"
        />
        <path
          d="M183.261 63.5786L183.26 65.47H185.151L185.152 63.5781L183.261 63.5786Z"
          fill="#99B0B7"
        />
        <path
          d="M183.276 54.8323L183.275 56.7239L185.167 56.7237L185.168 54.832L183.276 54.8323Z"
          fill="#99B0B7"
        />
        <path d="M178.901 59.2148V61.1067L180.792 61.1062L180.793 59.2148H178.901Z" fill="#99B0B7" />
        <path
          d="M187.152 61.7578L185.814 63.0956L187.152 64.4329L188.489 63.0946L187.152 61.7578Z"
          fill="#99B0B7"
        />
        <path
          d="M180.992 61.7773L179.654 63.1151L180.991 64.4524L182.329 63.1142L180.992 61.7773Z"
          fill="#99B0B7"
        />
        <path
          d="M187.169 55.6016L185.832 56.9396L187.169 58.2768L188.507 56.9388L187.169 55.6016Z"
          fill="#99B0B7"
        />
        <path
          d="M180.994 55.6016L179.656 56.9396L180.993 58.2768L182.331 56.9388L180.994 55.6016Z"
          fill="#99B0B7"
        />
        <path
          d="M190.488 46.3701V48.2504L193.53 48.2494L191.381 50.3979L192.711 51.7275L194.859 49.579V52.6211L196.739 52.6205L196.74 49.5784L198.887 51.7256L200.217 50.3952L198.07 48.2481L201.112 48.2471L201.113 46.3669L198.071 46.3678L200.219 44.2193L198.889 42.8897L196.741 45.0382L196.742 41.9961L194.861 41.9967V45.0388L192.714 42.8916L191.383 44.222L193.53 46.3692L190.488 46.3701ZM193.561 46.3844L194.868 45.077L196.718 45.0764L198.025 46.3831L198.024 48.2328L196.717 49.5402L194.867 49.5408L193.56 48.2341L193.561 46.3844Z"
          fill="#99B0B7"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_9351_22496"
        x="-1.42803"
        y="0.573437"
        width="192.797"
        height="98.9859"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22496" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22496"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_9351_22496"
        x="47.9204"
        y="70.4406"
        width="192.798"
        height="98.9859"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9.6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.227451 0 0 0 0 0.298039 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9351_22496" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9351_22496"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_9351_22496">
        <rect width="244" height="169" fill="white" />
      </clipPath>
    </defs>
  </>
), {
  fill: 'none',
  viewBox: '0 0 244 169',
});

ChatEmpty.displayName = 'ChatEmpty';

export { ChatEmpty };
