// @flow

import React from 'react';

import Svg from 'components/svg';

const MenuIcon = Svg((
  <>
    <line x1="1.8" y1="4.2" x2="22.2" y2="4.2" stroke="white" strokeWidth="1.6" strokeLinecap="square" />
    <line x1="1.8" y1="19.9139" x2="22.2" y2="19.9139" stroke="white" strokeWidth="1.6" strokeLinecap="square" />
    <line x1="1.8" y1="12.0574" x2="22.2" y2="12.0574" stroke="white" strokeWidth="1.6" strokeLinecap="square" />
  </>
), { fill: 'none', viewBox: '0 0 24 24' });

MenuIcon.displayName = 'Menu';

export default MenuIcon;
