// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_20032');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M4.06782 5.71687L7.73931 5.10194M4.43658 7.919L8.10808 7.30503M4.80535 10.1221L7.00843 9.75332M1.802 3.4539L9.14499 2.22595L10.7417 11.7711L3.39872 13L1.802 3.4539Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
        />
        <path
          d="M5.5 0.5H12V9"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const Draft = Svg(Fragment, { viewBox: '0 0 14 14', fill: 'none' });

Draft.displayName = 'Draft';

export default Draft;
