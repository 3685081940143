// @flow

import React from 'react';

import Svg from 'components/svg';

const SecurityUnlock = Svg((
  <g>
    <path d="M8.25,24C7.009,24,6,22.991,6,21.75v-10.5C6,10.009,7.009,9,8.25,9h2.25V6c0-2.481-2.019-4.5-4.5-4.5S1.5,3.519,1.5,6v3.75c0,0.414-0.336,0.75-0.75,0.75S0,10.164,0,9.75V6c0-3.308,2.692-6,6-6s6,2.692,6,6v3h9.75C22.991,9,24,10.009,24,11.25v10.5c0,1.241-1.009,2.25-2.25,2.25H8.25z M8.25,10.5c-0.414,0-0.75,0.336-0.75,0.75v10.5c0,0.414,0.336,0.75,0.75,0.75h13.5c0.414,0,0.75-0.336,0.75-0.75v-10.5c0-0.414-0.336-0.75-0.75-0.75H8.25z" />
    <path d="M15,18.75c-0.414,0-0.75-0.336-0.75-0.75v-3c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v3C15.75,18.414,15.414,18.75,15,18.75z" />
  </g>
));

SecurityUnlock.displayName = 'SecurityUnlock';

export default SecurityUnlock;
