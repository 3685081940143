// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

const numberPattern = /^\d+$/;
const getNumberDecimalsPattern = (decimals) => new RegExp(`^\\d+(\\.\\d{0,${decimals}})?$`);

type NumberValidator = ({
  message: MessageTranslator,
  field: string,
  text?: string,
  decimals?: number,
}) => Validator;

// NOTE: meant to be used only in number input
const numberValidator: NumberValidator = ({
  message, field, text, decimals,
}) => (value: string) => {
  if (value === undefined || numberPattern.test(value)) {
    return undefined;
  }

  if (!decimals) {
    return text || message({
      id: '{field} is not a valid number',
      values: {
        field,
      },
      comment: 'Validation message for number validator.',
    });
  }

  if (getNumberDecimalsPattern(decimals).test(value)) {
    return undefined;
  }

  return text || message({
    id: 'Not allowed to have more than {count} decimal',
    pluralId: 'Not allowed to have more than {count} decimals',
    pluralCondition: 'count',
    values: {
      count: decimals,
    },
    comment: 'Validation message for number of decimals validator.',
  });
};

export default numberValidator;
