/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import * as ReactIs from 'react-is';
import clsx from 'clsx';

import { Link } from 'react-router-dom';
import { amplitudeLogEvent } from 'client-analytics/amplitude';
import isString from 'lodash/isString';

import type { IconProps } from 'types/svg-icon-props';
import { composeRefs } from 'hooks/compose-refs';

import style from './button.module.scss';

export type ButtonColors = 'red' | 'thunder' | 'peace' | 'yellow' | 'salmon-pink' | 'forest-green' | 'transparent' | 'grey' | 'outlined-red';
export type ButtonKinds = 'round' | 'link' | 'primary' | 'secondary' | 'secondary-lite' | 'special' | 'sign' | 'linkInline' | 'linkSeparate' | 'wide' | 'approve' | 'awaitsApproval' | 'save' | 'send' | 'cancel' | 'document-sign' | 'ghost' | 'publish' | 'unpublish' | 'add-participant';
type ButtonTypes = 'button' | 'submit' | 'reset';

export type Props = {
  onClick?: (event: Event) => void,
  children?: ReactNode,
  disabled?: boolean,
  selected?: boolean,
  icon?: any,
  color?: ButtonColors, // deprecate
  kind?: ButtonKinds,
  type?: ButtonTypes,
  outline?: boolean, // deprecate
  secondary?: boolean, // deprecate
  danger?: boolean, // deprecate
  customClass?: string,
  hasIconRight?: boolean,
  href?: string,
  external?: boolean,
  underlineLink?: boolean,
  download?: boolean,
  buttonRef?: any,
  triggerRef?: any,
  trackable?: string | any,
  // We have accedentally started using className to totally override the button styles
  // Consider using customClass or Build one-off special buttons using this component
  className?: string, // @deprecated
  buttonId?: string,
  target?: string,
  rel?: string,
  onMouseOver?: () => void,
  onMouseDown?: (e: MouseEvent) => void,
} & HTMLProps<'button'>;
class Button extends Component<Props> {
  static defaultProps: Props = {
    onClick: null,
    disabled: false,
    selected: false,
    color: undefined,
    kind: undefined,
    type: 'button',
    icon: undefined,
    children: null,
    outline: undefined,
    secondary: undefined,
    danger: false,
    customClass: undefined,
    hasIconRight: undefined,
    href: undefined,
    external: undefined,
    underlineLink: undefined,
    trackable: undefined,
    download: false,
    buttonId: undefined,
  };

  getRef() {
    if (!this.props.buttonRef && !this.props.triggerRef) {
      return {};
    }

    // This will open up the possibility of
    // composing mutliple radix components (eg., tooltip composes popover)
    return {
      ref: composeRefs(this.props.buttonRef, this.props.triggerRef),
    };
  }

  getOnClick() {
    const { onClick, trackable } = this.props;

    if (!onClick && !trackable) {
      return undefined;
    }

    if (!trackable) {
      return onClick;
    }

    return (e: Event) => {
      if (onClick) {
        onClick(e);
      }

      setTimeout(() => {
        if (isString(trackable)) {
          amplitudeLogEvent(trackable);
        } else {
          amplitudeLogEvent(trackable.name, trackable.props, trackable.groups);
        }
      }, 0);
    };
  }

  renderIcon() {
    const { icon } = this.props;

    if (!icon) {
      return null;
    }

    if (ReactIs.isElement(icon)) {
      const iconElement: ReactNode = icon;

      return (
        <span className={style.icon}>
          {iconElement}
        </span>
      );
    }

    const IconComponent: React.ComponentType<IconProps> = icon;

    const { children } = this.props;
    const hasLabel = (children !== null);

    return (
      <span className={style.icon}>
        <IconComponent height={hasLabel ? '14px' : icon.height} />
      </span>
    );
  }

  renderLabel() {
    const { children, buttonId } = this.props;

    if (!children) {
      return null;
    }

    return (
      <span className={style.label} id={buttonId}>
        {children}
      </span>
    );
  }

  // Keep this as a last prop or at least after all restProps
  getClassName() {
    const {
      disabled,
      selected,
      color,
      kind,
      outline,
      secondary,
      danger,
      customClass,
      hasIconRight,
      underlineLink,
    } = this.props;

    let buttonClasses = clsx(style.Button, customClass, {
      [style.peace]: color === 'peace',
      [style.red]: color === 'red',
      [style.thunder]: color === 'thunder',
      [style.yellow]: color === 'yellow',
      [style.red]: color === 'red',
      [style.salmonPink]: color === 'salmon-pink',
      [style.transparent]: color === 'transparent',
      [style.forestGreen]: color === 'forest-green',
      [style.outline]: outline,
      [style.grey]: color === 'grey',
      [style.outlinedRed]: color === 'outlined-red',
      [style.secondary]: secondary,
      [style.round]: kind === 'round',
      [style.link]: kind === 'link',
      [style.linkInline]: kind === 'linkInline',
      [style.linkSeparate]: kind === 'linkSeparate',
      [style.underline]: underlineLink,
      [style.iconRight]: hasIconRight,
      [style.disabled]: disabled,
      [style.selected]: selected,
      [style.danger]: danger,
      [style.Primary]: kind === 'primary',
      [style.Secondary]: kind === 'secondary',
      [style.SecondaryLite]: kind === 'secondary-lite',
      [style.Special]: kind === 'special',
      [style.Sign]: kind === 'sign',
      [style.Wide]: kind === 'wide',
      [style.NewButtonDefaults]: kind === 'primary' || kind === 'secondary' || kind === 'special' || kind === 'ghost',
      [style.Approve]: kind === 'approve',
      [style.AwaitsApproval]: kind === 'awaitsApproval',
      [style.Save]: kind === 'save',
      [style.Send]: kind === 'send',
      [style.Cancel]: kind === 'cancel',
      [style.DocumentSign]: kind === 'document-sign',
      [style.Publish]: kind === 'publish',
      [style.Unpublish]: kind === 'unpublish',
      [style.Ghost]: kind === 'ghost',
      [style.AddParticipant]: kind === 'add-participant',
    });

    // We were previously doing the same implicitly
    // className={buttonClasses}
    // ...restProps where restProps could have className
    if (this.props?.className) {
      buttonClasses = this.props?.className;
    }

    return {
      className: buttonClasses,
    };
  }

  render() {
    const {
      icon,
      disabled,
      selected,
      children,
      color,
      kind,
      outline,
      secondary,
      danger,
      type,
      customClass,
      hasIconRight,
      href,
      external,
      underlineLink,
      buttonRef,
      triggerRef,
      onClick,
      download,
      buttonId,
      ...restProps
    } = this.props;

    if (download && href) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={href}
          disabled={disabled}
          {...this.getClassName()}
          onClick={this.getOnClick()}
        >
          {this.renderIcon()}
          {this.renderLabel()}
        </a>
      );
    }

    if (external && href) {
      return (
        <a
          href={href}
          disabled={disabled}
          onClick={this.getOnClick()}
          {...this.getRef()}
          {...restProps}
          {...this.getClassName()}
        >
          {this.renderIcon()}
          {this.renderLabel()}
        </a>
      );
    }

    if (href) {
      return (
        <Link
          to={href}
          disabled={disabled}
          onClick={this.getOnClick()}
          ref={triggerRef}
          {...restProps}
          {...this.getClassName()}
        >
          {this.renderIcon()}
          {this.renderLabel()}
        </Link>
      );
    }

    if (disabled) {
      return (
        <div className={style.DisabledButtonWrapper}>
          <button
            disabled={disabled}
            type={type}
            onClick={this.getOnClick()}
            {...this.getRef()}
            {...restProps}
            {...this.getClassName()}
          >
            {this.renderIcon()}
            {this.renderLabel()}
          </button>
        </div>
      );
    }

    return (
      <button
        disabled={disabled}
        type={type}
        onClick={this.getOnClick()}
        {...this.getRef()}
        {...restProps}
        {...this.getClassName()}
      >
        {this.renderIcon()}
        {this.renderLabel()}
      </button>
    );
  }
}

export default Button;
