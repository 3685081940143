// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_636_8798');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path d="M8.25 4.49951V8.99951H6.75V4.49951H8.25Z" fill="currentColor" />
        <path d="M6.375 10.8745C6.375 11.1729 6.49353 11.459 6.70451 11.67C6.91548 11.881 7.20163 11.9995 7.5 11.9995C7.79837 11.9995 8.08452 11.881 8.29549 11.67C8.50647 11.459 8.625 11.1729 8.625 10.8745C8.625 10.5761 8.50647 10.29 8.29549 10.079C8.08452 9.86804 7.79837 9.74951 7.5 9.74951C7.20163 9.74951 6.91548 9.86804 6.70451 10.079C6.49353 10.29 6.375 10.5761 6.375 10.8745Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.50002 -0.00195312C7.11717 -0.00195312 6.74137 0.10109 6.41207 0.296366C6.08372 0.491075 5.81365 0.770325 5.63003 1.10496L0.378331 10.5316L0.376427 10.5351C0.12408 10.9937 -0.00440007 11.5101 0.00365242 12.0335C0.0117049 12.5569 0.156011 13.0691 0.422347 13.5197C0.688683 13.9703 1.06785 14.3437 1.52248 14.6032C1.9771 14.8626 2.49149 14.9991 3.01492 14.9991H11.982C12.506 14.9992 13.021 14.8626 13.4761 14.603C13.9313 14.3433 14.3109 13.9695 14.5776 13.5184C14.8442 13.0673 14.9887 12.5545 14.9968 12.0306C15.0049 11.5066 14.8762 10.9896 14.6236 10.5305L9.36998 1.10492C9.18636 0.770303 8.9163 0.491067 8.58796 0.296366C8.25866 0.10109 7.88287 -0.00195312 7.50002 -0.00195312ZM3.01502 13.4991C2.75229 13.4991 2.4941 13.4306 2.26591 13.3004C2.03769 13.1701 1.84735 12.9827 1.71366 12.7565C1.57996 12.5303 1.50752 12.2731 1.50347 12.0104C1.49944 11.7483 1.56364 11.4896 1.68976 11.2597L1.6906 11.2582L6.9417 1.83265L6.9446 1.82741C6.9991 1.72771 7.07942 1.64453 7.17715 1.58658C7.27488 1.52863 7.3864 1.49805 7.50002 1.49805C7.61363 1.49805 7.72515 1.52863 7.82288 1.58658C7.92061 1.64453 8.00094 1.72771 8.05544 1.82741L8.05841 1.83279L13.3094 11.2537L13.3103 11.2553C13.4367 11.4856 13.501 11.7448 13.497 12.0075C13.4929 12.2708 13.4203 12.5284 13.2863 12.7551C13.1523 12.9818 12.9616 13.1696 12.7328 13.3001C12.5041 13.4305 12.2454 13.4992 11.9821 13.4991H3.01502Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const TerminateSnackbar = Svg(Fragment, { viewBox: '0 0 15 15' });

export default TerminateSnackbar;
