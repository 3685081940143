import Svg from 'components/svg';

const ContentTab = Svg((
  <>
    <rect x="2.75" y="10.75" width="9.5" height="10.5" rx="1.25" stroke="currentColor" strokeWidth="1.5" />
    <rect x="2.75" y="2.75" width="9.5" height="4.5" rx="1.25" stroke="currentColor" strokeWidth="1.5" />
    <rect x="15.75" y="2.75" width="5.5" height="9.5" rx="1.25" stroke="currentColor" strokeWidth="1.5" />
    <path d="M18.5 15.75V20.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M21 18.25L16 18.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </>

), { viewBox: '0 0 24 24', fill: 'none' });

ContentTab.displayName = 'ContentTab';

export default ContentTab;
