/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import Message from 'components/message';
import { localize } from '@oneflowab/pomes';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getCountryListAsOptions } from 'components/countries';

import Field from 'components/field';
import SelectField from 'components/select-field';
import Checkbox from 'components/checkbox';
import TermsLink from 'components/terms-link';
import {
  PublicPasswordFieldSignUp,
} from 'components/fields';

import style from './signup-settings-details.module.scss';

const SignupSettingsDetails = () => {
  const lang = useSelector((state) => getCurrentLanguageSelector(state));

  return (
    <>
      <Field
        name="country"
        component={SelectField}
        label={(
          <Message
            id="Country"
            comment="Used in required validation of the country field on the third step of the signup flow  page."
          />
        )}
        placeholder={(
          <Message
            id="Select country"
            comment="Country placeholder on the third step of the signup flow page."
          />
        )}
        options={getCountryListAsOptions(lang)}
        clearable
        searchable
        required
      />
      <PublicPasswordFieldSignUp />
      <div className={style.Checkbox}>
        <Field
          name="hasAgreedToTerms"
          component={Checkbox}
          type="checkbox"
          label={<TermsLink />}
        />
      </div>
    </>
  );
};

export default localize(SignupSettingsDetails);
