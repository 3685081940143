// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837_20005');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          d="M1.83805 12.2822L5.72073 16.1582M16.6986 1.30114C16.4412 1.04487 16.1357 0.842125 15.7995 0.704628C15.4633 0.567131 15.1032 0.497604 14.7401 0.500063C14.3769 0.502522 14.0178 0.576918 13.6835 0.718953C13.3492 0.860989 13.0464 1.06785 12.7925 1.32758L1.83729 12.283L0.5 17.5L5.71695 16.1619L16.6722 5.20655C16.932 4.95279 17.1389 4.65004 17.281 4.31582C17.4231 3.98159 17.4975 3.62252 17.4999 3.25936C17.5024 2.8962 17.4328 2.53615 17.2953 2.20003C17.1578 1.86392 16.955 1.5584 16.6986 1.30114V1.30114ZM12.4797 1.64114L16.3586 5.5201L12.4797 1.64114Z"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="18" height="18" fill="none" />
        </clipPath>
      </defs>
    </>
  );
};

const Pencil = Svg(Fragment, { viewBox: '0 0 18 18', fill: 'none' });

Pencil.displayName = 'Pencil';

export default Pencil;
