// @flow

import React from 'react';

import Svg from 'components/svg';

const ArrowBack = Svg((
  <>
    <path
      d="M13.7778 8.00005L7.77783 8.00005H1.77783M1.77783 8.00005L6.02502 3.77783M1.77783 8.00005L6.02502 12.2223"
      stroke="#013A4C"
      strokeWidth="1.3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </>), {
  viewBox: '0 0 16 16',
});

ArrowBack.displayName = 'ArrowBack';

export default ArrowBack;
