// @flow

import React from 'react';

import Svg from 'components/svg';

const AddRowUnder = Svg((
  <>
    <rect x="5" y="10" width="4" height="14" transform="rotate(-90 5 10)" stroke="currentColor" strokeWidth="2" />
    <rect x="9" y="15" width="6" height="2" fill="currentColor" />
    <rect x="11" y="19" width="6" height="2" transform="rotate(-90 11 19)" fill="currentColor" />
  </>
), {
  viewBox: '0 0 24 24',
  fill: 'none',
});

AddRowUnder.displayName = 'AddRowUnder';

export default AddRowUnder;
