// @flow

import { camelize, decamelize } from 'humps';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isRegExp from 'lodash/isRegExp';
import get from 'lodash/get';

const processKeys = (convert, objectKey, object, options) => {
  const excludedKeys = get(options, 'excludedKeys', []);
  if (!isObject(object)
    || isDate(object)
    || isRegExp(object)
    || isBoolean(object)
    || isFunction(object)
    || excludedKeys.includes(objectKey)) {
    return object;
  }

  if (isArray(object)) {
    return object.map((value, key) => processKeys(convert, key, value, options));
  }

  const output = {};
  Object.entries(object).forEach(([key, value]) => {
    output[convert(key, options)] = processKeys(convert, key, value, options);
  });
  return output;
};

const processor = (convert, options) => {
  const callback = options && 'process' in options ? options.process : options;

  if (!isFunction(callback)) {
    return convert;
  }

  return (string, processorOptions) => callback(string, convert, processorOptions);
};

export const camelizeKeys = (object: Object, options: any): any => (
  processKeys(processor(camelize, options), null, object, options)
);

export const decamelizeKeys = (object: Object, options: any): any => (
  processKeys(processor(decamelize, options), null, object, options)
);

export const excludedAttributes = [
  '_id',
  'colCount',
  'price_1',
  'price_2',
  'price_1_discounted',
  'price_2_discounted',
  'price_1_discount_amount',
  'price_1_discount_type',
  'price_2_discount_amount',
  'price_2_discount_type',
  'count_type',
  '_removed',
  'description_nodes',
  'annotationType',
];

export const excludedKeys = [
  'nodes',
  'price_1',
  'price_2',
  'price_1_discounted',
  'price_2_discounted',
  'price_1_discount_amount',
  'price_1_discount_type',
  'price_2_discount_amount',
  'price_2_discount_type',
  'count_type',
  '_removed',
  'description_nodes',
  'annotationType',
];
