// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

import Button from 'components/button';

import Container from '../container';
import Logo from '../logo';

import style from './error-page.module.scss';

export const reload = () => window.location.reload(true);

const ErrorPage = () => (
  <Container>
    <div className={style.Error}>
      <Logo />
      <div className={style.Message}>
        <Message
          id="An error occurred while loading."
          comment="When app fails during the loading process this message will show up"
        />
      </div>
      <div className={style.Message}>
        <Message
          id="If the problem persists, please contact {email}"
          values={{
            email: <a href="mailto:support@oneflow.com">support@oneflow.com</a>,
          }}
          comment="The error message for when some error has happened during the loading process."
        />
        <Button
          kind="link"
          color="peace"
          customClass={style.ReloadButton}
          onClick={reload}
        >
          <Message
            id="Please reload the page and try again."
            comment="When app fails during the loading process this message will show up"
          />
        </Button>
      </div>
    </div>
  </Container>
);

export default ErrorPage;
