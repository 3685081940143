// @flow

import React from 'react';

import Svg from 'components/svg';

const RecordScreen = Svg((
  <>
    <g clipPath="url(#clip0_1057_5305)">
      <path
        d="M3.33333 11.6667H8.66667M7.66667 11.6667H4.33333L5 9.33333H7L7.66667 11.6667ZM0.333333 0.333333H11.6667V9.33333H0.333333V0.333333Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="square"
      />
      <path
        d="M4.33333 6.6179V3.3821C4.33334 3.31447 4.35235 3.24806 4.38844 3.18964C4.42453 3.13122 4.4764 3.08289 4.53876 3.04958C4.60111 3.01626 4.67171 2.99917 4.74335 3.00003C4.81499 3.0009 4.88511 3.01969 4.94653 3.05449L7.8031 4.67214C7.86315 4.70607 7.91284 4.7541 7.94735 4.81157C7.98186 4.86903 8 4.93395 8 5C8 5.06605 7.98186 5.13097 7.94735 5.18843C7.91284 5.2459 7.86315 5.29393 7.8031 5.32786L4.94653 6.94551C4.88511 6.98031 4.81499 6.9991 4.74335 6.99997C4.67171 7.00083 4.60111 6.98374 4.53876 6.95042C4.4764 6.91711 4.42453 6.86878 4.38844 6.81036C4.35235 6.75194 4.33334 6.68553 4.33333 6.6179V6.6179Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="square"
      />
    </g>
  </>
), { viewBox: '0 0 12 12', fill: 'none' });

RecordScreen.displayName = 'ControlRecord';

export default RecordScreen;
