// @flow

import * as React from 'react';

import { composeRefs } from 'hooks/compose-refs';

type Options = {
  viewBox?: string,
  fill?: string,
};

type SvgDecorator = (
  fragment: React.Node | Function, options?: Options
) => React.ComponentType<IconProps>;

const SvgHOC: SvgDecorator = (fragment, options = {}) => (
  class SVG extends React.PureComponent<IconProps> {
    static defaultProps = {
      height: '16px',
      width: null,
      className: null,
      responsive: undefined,
      iconRef: undefined,
      triggerRef: undefined,
    };

    getHeight() {
      if (this.props.responsive) {
        return '1em';
      }

      return this.props.height;
    }

    getWidth() {
      if (this.props.responsive) {
        return '1em';
      }

      return this.props.width || this.props.height;
    }

    getFragement() {
      if (typeof fragment === 'function') {
        return fragment(this.props);
      }

      return fragment;
    }

    render() {
      const {
        iconRef,
        triggerRef,
        responsive,
        pathColor,
        'aria-label': ariaLabel,
        ...restProps
      } = this.props;

      return (
        <svg
          ref={composeRefs(iconRef, triggerRef)}
          fill={options.fill || 'currentColor'}
          className={this.props.className}
          aria-label={ariaLabel}
          {...restProps}
          height={this.getHeight()}
          width={this.getWidth()}
          viewBox={options.viewBox || '0 0 24 24'}
          xmlns="http://www.w3.org/2000/svg"
        >
          {this.getFragement()}
        </svg>
      );
    }
  }
);

export default SvgHOC;
