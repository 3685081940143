// @flow
import type { MessageTranslator } from '@oneflowab/pomes';

import type { Validator } from './index';

// https://github.com/marshmallow-code/marshmallow/blob/572c1557e9e0cdcb90ef92b7ac6fb179cfe61aa4/marshmallow/validate.py#L37
// https://github.com/marshmallow-code/marshmallow/blob/572c1557e9e0cdcb90ef92b7ac6fb179cfe61aa4/tests/test_validate.py#L11

// Protocol:        (?:[a-z0-9\.\-\+]*):\/\/
// Auth (not used): (?:[^:@]+?:[^:@]*?@|)
// Domain:          (?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})
// IPV4:            \d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}
// IPV6 (not used): \[[A-F0-9]*:[A-F0-9:]+\])
// Port:            (?::\d+)?
// Extra stuff:     (?:\/?|[/?]\S+)

const urlPattern = /^(?:[a-z0-9.\-+]*):\/\/((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[/?]\S+)$/i;
const supportedProtocols = ['http', 'https'];

export const isUrlValid = (value: string, protocols: string[]): boolean => {
  if (value === undefined) {
    return undefined;
  }

  return (
    value.includes('://')
    && protocols.includes(value.split('://')[0])
    && urlPattern.test(value)
  );
};

type UrlValidator = ({
  message: MessageTranslator,
  field: string,
  httpsOnly?: boolean,
}) => Validator;

const urlValidator: UrlValidator = ({ message, field, httpsOnly }) => (value: string) => {
  if (value === undefined) {
    return undefined;
  }

  const protocols = httpsOnly ? ['https'] : supportedProtocols;
  if (isUrlValid(value, protocols)) {
    return undefined;
  }

  return message({
    id: '{field} is not a valid URL',
    values: {
      field,
    },
    comment: 'Validation message for URL validator.',
  });
};

export default urlValidator;
