// @flow
/* eslint-disable no-continue */

import { isEmpty, partition, mapKeys } from 'lodash';

import { hasNoPosition, hasDeliveryChannel } from 'agreement/participant';
import { isDraft, isTemplate } from 'agreement';
import {
  INLINE_COMMENT_MESSAGE,
  MAX_VISIBLE_MESSAGES,
  SUGGESTION_MESSAGE,
} from 'comments/constants';
import hasDeliveryChannelSameDevice from 'agreement/participant/has-delivery-channel-same-device';
import isDisabled from 'agreement/participant/is-disabled';
import isOrganizer from 'agreement/participant/is-organizer';
import sessionStorage from 'utils/session-storage';

import { checkAcl } from 'components/acl';

export const saveMessageToSessionStorage = (agreementId, parentId, value) => {
  sessionStorage.setItem(`agreement:${agreementId}-reply-for-${parentId}`, value);
};

export const resetMessageInSessionStorage = (agreementId, parentId) => {
  sessionStorage.removeItem(`agreement:${agreementId}-reply-for-${parentId}`);
};

export const getMessageFromSessionStorage = (agreementId, parentId) => (
  sessionStorage.getItem(`agreement:${agreementId}-reply-for-${parentId}`)
);

export const saveChatMessageToSessionStorage = (agreementId, value) => {
  sessionStorage.setItem(`agreement:${agreementId}-chat-message`, value);
};

export const resetChatMessageInSessionStorage = (agreementId) => {
  sessionStorage.removeItem(`agreement:${agreementId}-chat-message`);
};

export const getChatMessageFromSessionStorage = (agreementId) => (
  sessionStorage.getItem(`agreement:${agreementId}-chat-message`)
);

export const getMessageIdFromSessionStorage = () => (
  sessionStorage.getItem('url-message-id')
);

export const resetMessageIdInSessionStorage = () => {
  const messageId = getMessageIdFromSessionStorage();

  sessionStorage.removeItem('url-message-id');

  const newUrl = window.location.pathname.replace(`/m/${messageId}`, '');
  window.history.replaceState(null, '', newUrl);
};

export type Receiver = {
  label: string,
  partyName: string,
  isPrivate: boolean,
  id: number
}
/**
 * Sorts receivers based on
 * 1. id 0 and 1 are "all participants" and "my company" and shall always be sorted first
 *    (they never appear in the same list, so no need to sort between them)
 * 2. label
*/
export const sortReceivers = (receiver1: Receiver, receiver2: Receiver) => {
  if (receiver1.id < 2) {
    return -1;
  } if (receiver2.id < 2) {
    return 1;
  } if (receiver2.label > receiver1.label) {
    return -1;
  }
  if (receiver2.label < receiver1.label) {
    return 1;
  }
  return 0;
};

export const filterParticipants = (agreement, participants, isMyParty) => {
  const isOtherSideOrganizer = (participant) => !isMyParty && isOrganizer(participant);
  const isFormerColleague = (participant) => isMyParty && hasNoPosition(participant);
  const isDraftAndMySide = isDraft(agreement) && isMyParty;

  const filteredParticipants = participants.filter(
    (participant) => (participant.isPublished || isDraftAndMySide)
      && !hasDeliveryChannelSameDevice(participant)
      && !isOtherSideOrganizer(participant)
      // Do not show participants with delivery channel None in comments except former colleagues
      && !(!hasDeliveryChannel(participant) && !(isFormerColleague(participant)))
      && !isDisabled(participant)
      && !(!isMyParty && agreement.private),
  );

  return filteredParticipants.map((participant) => ({
    label: participant.fullname,
    partyName: participant.partyName,
    isPrivate: isMyParty,
    id: participant.id,
  }));
};

export const isAnnotation = (message) => message?.isResolvable;

export const isComment = (message) => (
  isAnnotation(message) && message?.type === INLINE_COMMENT_MESSAGE
);

export const isSuggestion = (message) => (
  isAnnotation(message) && message?.type === SUGGESTION_MESSAGE
);

export const isCommentResolved = (message) => message.config?.status === 'resolved';

export const isOrphanComment = (message) => message?.type === INLINE_COMMENT_MESSAGE && message.config?.status === 'deleted';

export const isSuggestionAccepted = (suggestion) => suggestion.status === 'accepted';

export const isSuggestionRejected = (suggestion) => suggestion.status === 'rejected';

export const isSuggestionDeleted = (suggestion) => suggestion.status === 'deleted';

export const isSuggestionResolved = (suggestion) => isSuggestionAccepted(suggestion)
  || isSuggestionRejected(suggestion)
  || isSuggestionDeleted(suggestion);

export const isAnnotationResolved = (annotation) => (
  isCommentResolved(annotation) || isSuggestionResolved(annotation)
);

export const isParentResolved = (message, resolvedAnnotations) => {
  const parentId = message.parent?.id;
  return resolvedAnnotations.some((m) => m.id === parentId);
};

export const isChatAvailable = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:message:create')
);
export const isCommentsAvailable = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:comment:create')
);
export const isSuggestionsAvailable = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:suggestion:create')
);

export const isCollaborationEnabled = (agreement, myParticipant) => (
  Boolean(agreement.config?.collaboration) && !isTemplate(agreement) && !isEmpty(myParticipant)
);
export const isChatEnabled = (agreement: Agreement) => (
  Boolean(agreement.config?.comments) && isChatAvailable(agreement)
);
export const isCommentsEnabled = (agreement: Agreement) => (
  Boolean(agreement.config?.inlineComments) && isCommentsAvailable(agreement)
);
export const isSuggestionsEnabled = (agreement: Agreement) => (
  Boolean(agreement.config?.suggestions) && isSuggestionsAvailable(agreement)
);

export const canEditCollaborationConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:collaboration')
);
export const canEditChatConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:comments')
);
export const canViewCommentsConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:inline_comments:view')
);
export const canEditCommentsConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:inline_comments:edit')
);
export const canEditCounterpartCommentsResolveConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:counterpart_comments_resolve')
);
export const canViewSuggestionsConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:suggestions:view')
);
export const canEditSuggestionsConfig = (agreement: Agreement) => (
  checkAcl(agreement.acl, 'agreement:update:config:suggestions:edit')
);

export const groupMessagesByParentId = (messages) => {
  const [replies, parentMessages] = partition(
    messages,
    (item) => Number.isInteger(item?.parent?.id),
  );

  const updatedParentMessages = parentMessages.map((parentMessage) => {
    const parentMessageCopy = { ...parentMessage };

    const parentReplies = replies.filter((reply) => reply.parent.id === parentMessage.id);
    if (parentReplies.length > 0) {
      parentMessageCopy.replies = [...parentReplies]; // Create a new array containing the replies
    }

    return parentMessageCopy;
  });

  return updatedParentMessages;
};

export const getMessagesData = (state, allMessages) => {
  if (isEmpty(allMessages)) {
    return {
      mappedMessages: {},
      allMessages: [],
      visibleChatMessages: [],
      visibleAnnotations: [],
      annotations: [],
      chatMessages: [],
    };
  }

  const sortedMessages = [...allMessages].sort((a, b) => new Date(a.created) - new Date(b.created));
  const groupedMessages = groupMessagesByParentId(sortedMessages);
  const mappedMessages = mapKeys(
    groupedMessages, (message) => message.id,
  );

  const [annotations, chatMessages] = partition(
    groupedMessages,
    (message) => isAnnotation(message),
  );

  const [comments, suggestions] = partition(
    annotations,
    (message) => !isSuggestion(message),
  );

  const annotationsWithoutOrphans = annotations.filter(
    (annotation) => !isOrphanComment(annotation),
  );

  const [resolvedAnnotations, activeAnnotations] = partition(
    annotationsWithoutOrphans,
    (annotation) => (
      isAnnotationResolved(annotation)
    ),
  );

  const [activeSuggestions, activeComments] = partition(
    activeAnnotations,
    (comment) => isSuggestion(comment),
  );

  const trimMessages = (messages) => {
    if (messages.length > MAX_VISIBLE_MESSAGES) {
      return messages.slice(
        messages.length - MAX_VISIBLE_MESSAGES,
        messages.length,
      );
    }
    return messages;
  };

  const visibleChatMessages = trimMessages(chatMessages);
  const visibleAnnotations = trimMessages(annotations);
  const visibleActiveAnnotations = trimMessages(activeAnnotations);
  const visibleResolvedAnnotations = trimMessages(resolvedAnnotations);

  return {
    mappedMessages,

    allMessages,
    chatMessages,
    annotations,
    comments,
    suggestions,

    activeAnnotations,
    activeComments,
    activeSuggestions,
    resolvedAnnotations,

    visibleChatMessages,
    visibleAnnotations,
    visibleActiveAnnotations,
    visibleResolvedAnnotations,
  };
};
