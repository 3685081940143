// @flow
import punycode from 'punycode';
import type { MessageTranslator } from '@oneflowab/pomes';

// eslint-disable-next-line no-control-regex
const userRegex = /(^[-!#$%&'*+/=?^`{}|~\w]+(\.[-!#$%&'*+/=?^`{}|~\w]+)*$|^"([\u0001-\u0010\u0013\u0014\u0016-\u0037!#-\\[\]-\u0177]|\\[\u0001-\u0011\u0013\u0014\u0016-\u0177])*"$)/iu;
const domainRegex = /(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$|^\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/iu;

const marshmallowEmailValidator = {
  isValid(value) {
    if (value.indexOf('@') === -1) {
      return false;
    }

    const [userPart, domainPart] = value.split('@');

    if (!userRegex.test(userPart)) {
      return false;
    }

    if (!domainRegex.test(domainPart)) {
      if (domainRegex.test(punycode.toASCII(domainPart))) {
        return true;
      }

      return false;
    }

    return true;
  },
};

// Postmark email pattern
const postmarkPattern = /^[a-z0-9\\.!#\\$%&'`\\*\\+\-\\/=\\^_\\{\\}\\|~]+@((\.)?[a-zA-Z0-9\\-])+$/iu;

export const isValidEmail = (value: string) => {
  if (!value) {
    return true;
  }

  const trimmedValue = value.trim();

  return (
    marshmallowEmailValidator.isValid(trimmedValue) && postmarkPattern.test(trimmedValue)
  );
};

type Props = {
  message: MessageTranslator,
};

const email = ({ message }: Props) => (value: string) => {
  if (!isValidEmail(value)) {
    return message({
      id: 'Email address is invalid',
      comment: 'A validation message for the email field',
    });
  }

  return undefined;
};

export default email;
