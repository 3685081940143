const getAppTitle = (isEnvironment) => {
  if (isEnvironment('development')) {
    return 'Devflow';
  }

  if (isEnvironment('staging')) {
    return 'Stageflow';
  }

  if (isEnvironment('test')) {
    return 'Testflow';
  }

  return 'Oneflow';
};

export default getAppTitle;
