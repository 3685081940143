// @flow

import React from 'react';

import Svg from 'components/svg';

const Info = Svg((
  <>
    <path
      d="M6.6 5.4V9H5.4V5.4H6.6Z"
      fill="currentColor"
    />
    <path
      d="M5.3636 4.5364C5.19482 4.36761 5.1 4.1387 5.1 3.9C5.1 3.66131 5.19482 3.43239 5.3636 3.2636C5.53239 3.09482 5.76131 3 6 3C6.23869 3 6.46761 3.09482 6.6364 3.2636C6.80518 3.43239 6.9 3.66131 6.9 3.9C6.9 4.1387 6.80518 4.36761 6.6364 4.5364C6.46761 4.70518 6.23869 4.8 6 4.8C5.76131 4.8 5.53239 4.70518 5.3636 4.5364Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736ZM6 1.2C4.72696 1.2 3.50606 1.70571 2.60589 2.60589C1.70571 3.50606 1.2 4.72696 1.2 6C1.2 7.27304 1.70571 8.49394 2.60589 9.39411C3.50606 10.2943 4.72696 10.8 6 10.8C7.27304 10.8 8.49394 10.2943 9.39411 9.39411C10.2943 8.49394 10.8 7.27304 10.8 6C10.8 4.72696 10.2943 3.50606 9.39411 2.60589C8.49394 1.70571 7.27304 1.2 6 1.2Z"
      fill="currentColor"
    />
  </>
), { viewBox: '0 0 12 12', fill: 'none' });

Info.displayName = 'Info';

export default Info;
