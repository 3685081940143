// @flow

import { connect } from 'react-redux';

import logCompanyInfo from 'logging/company-info-logger';

import {
  getLoadingStateSelector,
  getErrorStateSelector,
} from 'reducers/app';
import { getLocationSelector } from 'reducers/router';

import PublicPages from './public';

logCompanyInfo();

export const mapStateToProps = (state: State) => ({
  isLoading: getLoadingStateSelector(state),
  hasError: getErrorStateSelector(state),
  location: getLocationSelector(state),
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PublicPages);
