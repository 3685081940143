// @flow

import React from 'react';
import { localize } from '@oneflowab/pomes';
import type { MessageTranslator } from '@oneflowab/pomes';
import get from 'lodash/get';
import type { FormRenderProps } from 'react-final-form';

import { passwordConfirmation } from 'forms/validators';
import { getForgotPassword, postChangePassword } from 'oneflow-client/forgot-password';
import PublicForm from 'hocs/public-form';

import ResetPasswordWithTokenFields from './reset-password-with-token-fields';

const onSubmitSuccess = () => new Promise(() => {
  window.location.replace('/login?code=b21');
});

type Props = {
  token?: string,
  onInvalidToken: Function,
  message: MessageTranslator,
};

type State = {
  isValidToken: boolean,
  errorCode?: number,
};

export const isSubmitDisabled = (formProps: FormRenderProps) => {
  const {
    hasSubmitErrors,
    dirtySinceLastSubmit,
    validating,
    submitting,
    pristine,
    invalid,
    hasValidationErrors,
  } = formProps;

  if (hasSubmitErrors && dirtySinceLastSubmit && !hasValidationErrors) {
    return false;
  }

  return !!(validating || submitting || pristine || invalid);
};

export class ResetPasswordWithTokenComponent extends React.Component<Props, State> {
  static defaultProps = {
    token: '',
  };

  state = {
    isValidToken: false,
    errorCode: undefined,
  }

  componentDidMount() {
    this.onInit();
  }

  onSubmitFailure = (error: Object) => {
    const errorCode = get(error, 'body.api_error_code');
    this.setState({ errorCode });
  }

  onSubmit = (values: Object) => {
    const { token } = this.props;

    return postChangePassword({
      token,
      password: values.password,
    }).then(onSubmitSuccess)
      .catch(this.onSubmitFailure);
  }

  onInitSuccess = () => {
    this.setState({
      isValidToken: true,
    });
  }

  onInitFailure = () => {
    const { onInvalidToken } = this.props;
    onInvalidToken();
  }

  onInit = () => {
    const { token } = this.props;

    return getForgotPassword({
      token: token || '',
    }).then(this.onInitSuccess)
      .catch(this.onInitFailure);
  }

  shouldRenderForm() {
    return this.state.isValidToken;
  }

  renderFields = () => <ResetPasswordWithTokenFields />;

  render() {
    const { message } = this.props;

    if (!this.shouldRenderForm()) {
      return null;
    }

    return (
      <PublicForm
        title={message({
          id: 'Reset password',
          comment: 'Reset password with token page header.',
        })}
        errorCode={this.state.errorCode}
        onSubmit={this.onSubmit}
        validate={passwordConfirmation(message)}
        submitText={message({
          id: 'Confirm',
          comment: 'Submit button for the reset password with token page.',
        })}
        isSubmitDisabled={isSubmitDisabled}
      >
        {this.renderFields}
      </PublicForm>
    );
  }
}

export default localize<Props>(ResetPasswordWithTokenComponent);
