import Svg from 'components/svg';

const EyeOutline = Svg((
  <>
    <path d="M16.6823 6.06202C16.8868 6.31928 17 6.6535 17 7.00003C17 7.34655 16.8868 7.68077 16.6823 7.93803C15.3876 9.52003 12.4404 12.6 9 12.6C5.55961 12.6 2.61247 9.52003 1.3177 7.93803C1.1132 7.68077 1 7.34655 1 7.00003C1 6.6535 1.1132 6.31928 1.3177 6.06202C2.61247 4.48002 5.55961 1.40002 9 1.40002C12.4404 1.40002 15.3876 4.48002 16.6823 6.06202Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99962 9.24019C10.3591 9.24019 11.4612 8.23731 11.4612 7.00019C11.4612 5.76307 10.3591 4.76019 8.99962 4.76019C7.64015 4.76019 6.53809 5.76307 6.53809 7.00019C6.53809 8.23731 7.64015 9.24019 8.99962 9.24019Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </>
), { viewBox: '0 0 18 14', fill: 'none' });

EyeOutline.displayName = 'EyeOutline';

export default EyeOutline;
