// @flow

import React from 'react';
import { uniqueId } from 'lodash';

import Svg from 'components/svg';

const Fragment = () => {
  const id = uniqueId('clip0_2837:19976');
  return (
    <>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.14989 1.15018L1.14998 12.8501L8.50387 12.8501L8.50384 9.35009H9.80384L9.80387 14.1501L-0.150024 14.1501L-0.150146 -0.149724L0.499832 -0.149776C2.23499 -0.149916 6.75003 -0.149925 9.15388 -0.149874L9.80387 -0.14986L9.80384 4.65009H8.50384L8.50387 1.15011C6.30153 1.15008 2.98957 1.15009 1.14989 1.15018ZM11.5757 4.58101L13.4686 6.54955C13.7105 6.80118 13.7105 7.19897 13.4686 7.4506L11.5757 9.41914L10.6387 8.5181L11.4733 7.65008H5.61925V6.35008H11.4733L10.6387 5.48206L11.5757 4.58101Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const Logout = Svg(Fragment, { viewBox: '0 0 14 14', fill: 'none' });

Logout.displayName = 'Logout';

export default Logout;
