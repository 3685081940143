// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  message: MessageTranslator,
  parse?: Function,
};

export const DataFieldPlaceholder = ({ message, parse }: Props) => (
  <Field
    name="placeholder"
    label={message({
      id: 'Placeholder',
      comment: 'Label for the placeholder field for a data field.',
    })}
    placeholder={message({
      id: 'Enter placeholder',
      comment: 'Placeholder placeholder for a data field.',
    })}
    component={TextField}
    maxLength={100}
    parse={parse}
  />
);

DataFieldPlaceholder.defaultProps = {
  parse: undefined,
};

export default localize<Props>(DataFieldPlaceholder);
