// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';
import Field from 'components/field';
import SelectField from 'components/select-field';
import { getMessageTemplateTypesAsOptions } from 'components/message-template-type';

type Props = {
  message: MessageTranslator,
};

export const MessageTemplateType = ({ message }: Props) => (
  <Field
    name="type"
    label={message({
      id: 'Type',
      comment: 'The field label in add message template page',
    })}
    placeholder={message({
      id: 'Select type',
      comment: 'The field placeholder in add message template page',
    })}
    component={SelectField}
    options={getMessageTemplateTypesAsOptions(message)}
    clearable={false}
    searchable={false}
    required
  />
);

export default localize<Props>(MessageTemplateType);
