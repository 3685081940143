// @flow

import React from 'react';

import Svg from 'components/svg';

const Link = Svg((
  <>
    <path d="M5.8335 7.58348C6.08401 7.91838 6.40362 8.1955 6.77065 8.39602C7.13768 8.59655 7.54354 8.71579 7.96071 8.74567C8.37788 8.77555 8.79659 8.71536 9.18845 8.56918C9.58031 8.42301 9.93615 8.19427 10.2318 7.89848L11.9818 6.14848C12.5131 5.59839 12.8071 4.86163 12.8005 4.09689C12.7938 3.33215 12.4871 2.60061 11.9463 2.05984C11.4055 1.51906 10.674 1.21232 9.90925 1.20568C9.14451 1.19903 8.40775 1.49301 7.85766 2.02431L6.85433 3.02181" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M8.16678 6.41672C7.91627 6.08181 7.59666 5.80469 7.22963 5.60417C6.8626 5.40364 6.45674 5.2844 6.03957 5.25452C5.6224 5.22465 5.20369 5.28484 4.81183 5.43101C4.41997 5.57719 4.06413 5.80593 3.76845 6.10172L2.01845 7.85172C1.48716 8.40181 1.19317 9.13856 1.19982 9.9033C1.20646 10.668 1.51321 11.3996 2.05398 11.9404C2.59475 12.4811 3.32629 12.7879 4.09103 12.7945C4.85577 12.8012 5.59253 12.5072 6.14262 11.9759L7.14012 10.9784" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </>
), { viewBox: '0 0 14 14' });

Link.displayName = 'Link';

export default Link;
