// @flow
import React from 'react';
import { Message } from '@oneflowab/pomes';

import {
  DELIVERY_CHANNEL_EMAIL,
  DELIVERY_CHANNEL_SMS,
  DELIVERY_CHANNEL_EMAIL_AND_SMS,
  DELIVERY_CHANNEL_SAME_DEVICE,
  DELIVERY_CHANNEL_NONE,
} from 'agreement/participant/constants';

export const deliveryChannelsOrder = [
  DELIVERY_CHANNEL_EMAIL, DELIVERY_CHANNEL_SMS,
  DELIVERY_CHANNEL_EMAIL_AND_SMS,
  DELIVERY_CHANNEL_SAME_DEVICE,
  DELIVERY_CHANNEL_NONE,
];

export const deliveryChannels = {
  [DELIVERY_CHANNEL_EMAIL]: {
    label: <Message id="Email" comment="Delivery channel type" />,
    value: DELIVERY_CHANNEL_EMAIL,
  },
  [DELIVERY_CHANNEL_SMS]: {
    label: <Message id="SMS" comment="Delivery channel type" />,
    value: DELIVERY_CHANNEL_SMS,
  },
  [DELIVERY_CHANNEL_EMAIL_AND_SMS]: {
    label: <Message id="Email and SMS" comment="Delivery channel type" />,
    value: DELIVERY_CHANNEL_EMAIL_AND_SMS,
  },
  [DELIVERY_CHANNEL_SAME_DEVICE]: {
    label: <Message id="Same device" comment="Delivery channel type" />,
    value: DELIVERY_CHANNEL_SAME_DEVICE,
  },
  [DELIVERY_CHANNEL_NONE]: {
    label: <Message id="Not delivered" comment="Delivery channel type" />,
    value: DELIVERY_CHANNEL_NONE,
  },
};

export const getDeliveryChannel = (
  deliveryChannel?: any,
) => (
  deliveryChannels[deliveryChannel]
);
