import {
  ACTION_STATUS_NOT_STARTED,
  ACTION_STATUS_RUNNING,
  ACTION_STATUS_SUCCEEDED,
} from 'agreement/actions/constants';
import * as participantConstants from 'agreement/participant/constants';
import isSignatory from 'agreement/participant/is-signatory';
import isExternalApprover from 'agreement/participant/is-external-approver';

export const getPendingStateApproverParticipants = (agreement: Oneflow.Agreement) => {
  if (!agreement?.pendingStateFlow || !agreement.parties) {
    return null;
  }

  const allParticipants = agreement.parties.flatMap((party) => party.participants);

  const participantApprovers = allParticipants.filter(
    (participant) => participant.roles.some((roleObj) => roleObj.role === 'PENDING_STATE_APPROVER'),
  );

  return participantApprovers;
};

export const getPendingStateApprovers = (agreement: Oneflow.Agreement) => {
  // NOTE: This outputs action groups, not participants!!

  if (!agreement?.pendingStateFlow || !agreement.parties) {
    return null;
  }

  const approvalBlocks = agreement.pendingStateFlow.childNodes.filter((node) => node.key === 'BLOCK_PENDING_STATE');

  const pendingStateActionGroups = approvalBlocks.map(
    (block) => block.childNodes.filter((node) => node.key === 'ACTION_GROUP_PENDING_STATE'),
  ).flat();

  const participantApprovers = getPendingStateApproverParticipants(agreement);
  const approverIds = participantApprovers?.map((participant) => participant.id);
  const filteredActionGroups = pendingStateActionGroups.filter(
    (group) => approverIds && approverIds.indexOf(group.config.actor.id) > -1,
  );

  if (filteredActionGroups === null || filteredActionGroups.length === 0) {
    return null;
  }

  return filteredActionGroups;
};

export const hasPendingStateApprovers = (agreement: Oneflow.Agreement) => {
  const approvers = getPendingStateApprovers(agreement);

  if (!approvers) {
    return false;
  }

  return Boolean(approvers.length);
};

export const getApprovedPendingStateApprovers = (agreement: Oneflow.Agreement) => {
  const externalApprovers = getPendingStateApproverParticipants(agreement);

  if (!externalApprovers) {
    return [];
  }

  return externalApprovers.filter((approver) => {
    const hasApproved = approver.actions?.some((action) => action.status === 'SUCCEEDED') ?? false;
    return hasApproved;
  });
};

export const hasAnyPendingApproverApproved = (agreement: Oneflow.Agreement): boolean => {
  const approvers = getPendingStateApprovers(agreement);

  if (!hasPendingStateApprovers(agreement)) {
    return false;
  }

  if (!approvers || approvers.length === 0) {
    return false;
  }

  const anyApproved = approvers.some(
    (approver) => approver.status === ACTION_STATUS_SUCCEEDED,
  );

  return anyApproved;
};

export const getUndecidedPendingStateApprovers = (agreement: Oneflow.Agreement) => {
  const approvers = getPendingStateApproverParticipants(agreement);

  if (!approvers) {
    return null;
  }

  const notDecidedApprovers = approvers
    .filter((approver) => approver.actions[0].status === 'NOT_STARTED' || approver.actions[0].status === 'RUNNING');

  return notDecidedApprovers;
};

export const hasPendingStateParticipantApproved = (
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
) => {
  if (!hasPendingStateApprovers(agreement)) {
    return false;
  }

  const pendingStateApprovers = getPendingStateApprovers(agreement);

  const hasApproved = pendingStateApprovers.some(
    (actionGroup) => actionGroup.config.actor.id === participant.id
      && actionGroup.status === ACTION_STATUS_SUCCEEDED,
  );

  return hasApproved;
};

export const getRunningSignOrderBlockIndex = (agreement) => {
  if (!agreement.config?.signOrder) {
    return null;
  }

  const runningBlock = agreement.pendingStateFlow.childNodes.find(
    (node) => node.key === 'BLOCK_PENDING_STATE' && node.status === ACTION_STATUS_RUNNING,
  );

  return runningBlock.config.blockIndex;
};

export const getLastBlockIndex = (agreement) => {
  if (agreement.pendingStateFlow === null) {
    return 1;
  }
  const blocks = agreement.pendingStateFlow.childNodes?.filter(
    (node) => node.key === 'BLOCK_PENDING_STATE',
  );

  if (!blocks || blocks.length === 0) {
    return 1;
  }

  const blockIndexes = blocks.map((block) => block.config?.blockIndex || 1);
  const highestBlockIndex = Math.max(...blockIndexes);

  return highestBlockIndex;
};

export const maxAvailableBlockIndex = (agreement) => {
  if (!agreement.config?.signOrder || !agreement.pendingStateFlow) {
    return false;
  }
  const blocks = agreement.pendingStateFlow.childNodes?.filter(
    (node) => node.key === 'BLOCK_PENDING_STATE',
  );

  const participantBlocks = blocks.map(
    (block) => block.childNodes,
  ).flat();

  const lastBlockIndex = getLastBlockIndex(agreement);

  return Math.max(lastBlockIndex, participantBlocks.length);
};

export const getNewParticipantBlockIndex = (agreement) => {
  if (agreement.pendingStateFlow === null) {
    return 1;
  }
  const lastBlockIndex = getLastBlockIndex(agreement);

  return lastBlockIndex + 1;
};

export const getParticipantSignOrderBlockIndex = (agreement, participantId) => {
  if (!agreement.config?.signOrder || !participantId) {
    return null;
  }
  if (agreement.pendingStateFlow === null) {
    return 1;
  }
  const blocks = agreement.pendingStateFlow.childNodes.filter((node) => node.key === 'BLOCK_PENDING_STATE').flat();

  const blockWithParticipant = blocks.find(
    (block) => block.childNodes.some((node) => node.config.actor.id === participantId),
  );

  return blockWithParticipant ? blockWithParticipant.config.blockIndex
    : getNewParticipantBlockIndex(agreement);
};

export const canEditSignOrderBlockIndex = (agreement, participantId) => {
  if (!agreement.config?.signOrder || !participantId) {
    return null;
  }

  const notStartedBlocks = agreement.pendingStateFlow?.childNodes.filter(
    (node) => node.key === 'BLOCK_PENDING_STATE'
      && node.status === ACTION_STATUS_NOT_STARTED,
  ).flat();

  const blockHasParticipant = notStartedBlocks.find(
    (block) => block.childNodes.some((node) => node.config.actor.id === participantId),
  );

  return blockHasParticipant;
};

// eslint-disable-next-line no-shadow
enum DocumentParticipantRole {
  signatory,
  approver,
  other,
}

type DocumentParticipant = {
  id: string;
  role: DocumentParticipantRole;
  blockIndex: number;
};

const getDocumentParticipants = (agreement: Oneflow.Agreement) => {
  const participants = agreement.parties.flatMap((party) => party.participants);

  const documentParticipants = participants.map((participant) => {
    if (participant.roles.length === 0) {
      return null;
    }

    const roleString = participant.roles[0].role;
    const { blockIndex } = participant.roles[0];

    let role: DocumentParticipantRole;
    if (roleString === 'PENDING_STATE_SIGNATORY') {
      role = DocumentParticipantRole.signatory;
    } else if (roleString === 'PENDING_STATE_APPROVER') {
      role = DocumentParticipantRole.approver;
    } else {
      role = DocumentParticipantRole.other;
    }

    const documentParticipant = {
      id: participant.id,
      role,
      blockIndex,
    };

    return documentParticipant;
  })
    .filter((participant) => participant !== null);

  return documentParticipants;
};

const coveredApproversCount = (participants: [DocumentParticipant]) => {
  const sortedParticipants = participants.sort((a, b) => a.blockIndex - b.blockIndex);
  const sortedSignatories = sortedParticipants
    .filter((p) => p.role === DocumentParticipantRole.signatory);

  if (sortedSignatories.length === 0) {
    return 0;
  }

  const highestSignatoryBlockIndex = sortedSignatories[sortedSignatories.length - 1].blockIndex;

  const coveredApprovers = sortedParticipants
    .filter((p) => p.role === DocumentParticipantRole.approver
      && p.blockIndex <= highestSignatoryBlockIndex);

  return coveredApprovers.length;
};

export const hasSignatoriesInDocument = (
  agreement: Oneflow.Agreement,
) => {
  const participants = agreement.parties.flatMap((party) => party.participants);

  return participants.some((participant) => participant.roles
    .some((participantRole) => participantRole.role === 'PENDING_STATE_SIGNATORY'));
};

export const getLastSignatory = (
  agreement: Oneflow.Agreement,
) => {
  const participants = agreement.parties.flatMap((party) => party.participants);

  const signatories = participants
    .filter((participant) => participant.roles
      .some((participantRole) => participantRole.role === 'PENDING_STATE_SIGNATORY'));

  const signatoriesBlockIndexes = signatories.map((signatory) => signatory.roles[0].blockIndex);
  const highestBlockIndex = Math.max(...signatoriesBlockIndexes);

  const signatoryWithHighestBlockIndex = signatories
    .find((signatory) => signatory.roles[0].blockIndex === highestBlockIndex);

  return signatoryWithHighestBlockIndex;
};

export const hasNoExternalApproverAfterSignatory = (
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
) => {
  if (!agreement.config?.signOrder
    || !hasPendingStateApprovers(agreement)
    || !isExternalApprover(participant)
    || !hasSignatoriesInDocument(agreement)) {
    return true;
  }

  const documentParticipants = getDocumentParticipants(agreement);
  const participantIndex = documentParticipants.findIndex((p) => p.id === participant.id);
  const participantBlockIndex = documentParticipants[participantIndex].blockIndex;
  const signatoriesWithHigherBlockIndex = documentParticipants
    .filter((p) => p.role === DocumentParticipantRole.signatory
      && p.blockIndex > participantBlockIndex);

  const signatoriesWitEqualBlockIndex = documentParticipants
    .filter((p) => p.role === DocumentParticipantRole.signatory
        && p.blockIndex === participantBlockIndex);

  const lastSignatory = getLastSignatory(agreement);
  const hasLastSignatorySigned = lastSignatory?.signatureInfo?.hasSigned;

  if (signatoriesWitEqualBlockIndex && hasLastSignatorySigned) {
    return false;
  }

  return signatoriesWithHigherBlockIndex.length > 0 || signatoriesWitEqualBlockIndex.length > 0;
};

export const anApproverIsWithoutSignatoryAfter = (
  agreement: Oneflow.Agreement,
) => {
  if (!agreement.config?.signOrder || !hasPendingStateApprovers(agreement)) {
    return false;
  }

  const participants = getDocumentParticipants(agreement);
  const coveredApprovers = coveredApproversCount(participants);
  const allApprovers = getPendingStateApprovers(agreement);

  return coveredApprovers < allApprovers.length;
};

export const changeImpliesApproverIsMissingSignatory = (
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
  newSignatoryIndex: number,
) => {
  if (!agreement.config?.signOrder) {
    return false;
  }

  const documentParticipants = getDocumentParticipants(agreement);
  const coveredApproversCountBeforeChange = coveredApproversCount(documentParticipants);

  const participantIndex = documentParticipants.findIndex((p) => p.id === participant.id);
  documentParticipants[participantIndex].blockIndex = newSignatoryIndex;

  const coveredApproversCountAfterChange = coveredApproversCount(documentParticipants);

  return coveredApproversCountAfterChange < coveredApproversCountBeforeChange;
};

export const shouldShowRemoveSignatoryWarning = (
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
) => {
  if (!isSignatory(participant)
    || !agreement.config?.signOrder) {
    return false;
  }

  const documentParticipants = getDocumentParticipants(agreement);
  const coveredApproversCountBeforeChange = coveredApproversCount(documentParticipants);

  const participantIndex = documentParticipants.findIndex((p) => p.id === participant.id);
  documentParticipants.splice(participantIndex, 1);

  const hasSignatoriesAfterChange = documentParticipants
    .some((p) => p.role === DocumentParticipantRole.signatory);

  if (!hasSignatoriesAfterChange) {
    return false;
  }

  const coveredApproversCountAfterChange = coveredApproversCount(documentParticipants);

  return coveredApproversCountAfterChange < coveredApproversCountBeforeChange;
};

export const shouldShowRoleEditWarningModal = (
  agreement: Oneflow.Agreement,
  participant: Oneflow.Participant,
  newRole: number,
) => {
  if (!isSignatory(participant)
    || !agreement.config?.signOrder
    || newRole === participantConstants.TYPE_IS_SIGNATORY) {
    return false;
  }

  const documentParticipants = getDocumentParticipants(agreement);
  const coveredApproversCountBeforeChange = coveredApproversCount(documentParticipants);

  const participantIndex = documentParticipants.findIndex((p) => p.id === participant.id);

  let role = DocumentParticipantRole.other;
  if (newRole === participantConstants.TYPE_IS_SIGNATORY) {
    role = DocumentParticipantRole.signatory;
  } else if (newRole === participantConstants.TYPE_IS_EXTERNAL_APPROVER) {
    role = DocumentParticipantRole.approver;
  }

  documentParticipants[participantIndex].role = role;

  const hasSignatoriesAfterChange = documentParticipants
    .some((p) => p.role === DocumentParticipantRole.signatory);

  if (!hasSignatoriesAfterChange) {
    return false;
  }

  const coveredApproversCountAfterChange = coveredApproversCount(documentParticipants);

  return coveredApproversCountAfterChange < coveredApproversCountBeforeChange;
};

export const getParticipantType = (participant: Oneflow.Participant) => {
  if (participant.roles.length === 0) {
    return participant.type;
  }

  if (participant.roles[0].role === 'PENDING_STATE_APPROVER') {
    return 1001;
  }

  return participant.type;
};

export const getPendingStateParticipants = (agreement: Oneflow.Agreement) => {
  const participants = agreement.parties.flatMap(
    (party) => party.participants?.map((participant) => ({
      role: participant.roles[0]?.role,
      participantId: participant.id,
      block: participant.roles[0]?.blockIndex,
    })),
  );

  return participants;
};
