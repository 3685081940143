// @flow

import * as agreementConstants from './constants';
import { hasPendingStateApprovers } from './pending-state-flow';

type AgreementHelper = (agreement: Agreement) => boolean;
type StateHelper = (state: number) => boolean;

const statusMapper = {
  [agreementConstants.STATE_DRAFT]: 'draft',
  [agreementConstants.STATE_PENDING]: 'pending',
  [agreementConstants.STATE_OVERDUE]: 'overdue',
  [agreementConstants.STATE_TEMPLATE]: 'template',
  [agreementConstants.STATE_SIGNED]: 'signed',
  [agreementConstants.STATE_SIGN_IN_PROGRESS]: 'sign_in_progress',
  [agreementConstants.STATE_DECLINED]: 'declined',
  [agreementConstants.STATE_DECLINE_IN_PROGRESS]: 'decline_in_progess',
};

/* state */
export const isStateDraft: StateHelper = (state) => (
  state === agreementConstants.STATE_DRAFT
);
export const isStatePending: StateHelper = (state) => (
  state === agreementConstants.STATE_PENDING
);
export const isStateOverdue: StateHelper = (state) => (
  state === agreementConstants.STATE_OVERDUE
);
export const isStateTemplate: StateHelper = (state) => (
  state === agreementConstants.STATE_TEMPLATE
);
export const isStateAnySigned: StateHelper = (state) => (
  [agreementConstants.STATE_SIGNED, agreementConstants.STATE_SIGN_IN_PROGRESS].includes(state)
);
export const isStateAnyDeclined: StateHelper = (state) => (
  [agreementConstants.STATE_DECLINED, agreementConstants.STATE_DECLINE_IN_PROGRESS].includes(state)
);

export const getStatusForState = (state) => statusMapper[state];
/* state from agreement */
export const isDraft: AgreementHelper = (agreement) => (
  isStateDraft(agreement.state)
);
export const isPending: AgreementHelper = (agreement) => (
  isStatePending(agreement.state)
);
export const isOverdue: AgreementHelper = (agreement) => (
  isStateOverdue(agreement.state)
);
export const isSigned: AgreementHelper = (agreement) => (
  agreement.state === agreementConstants.STATE_SIGNED
);
export const isDeclined: AgreementHelper = (agreement) => (
  agreement.state === agreementConstants.STATE_DECLINED
);
export const isClosed: AgreementHelper = (agreement) => (
  isSigned(agreement) || isDeclined(agreement)
);

export const getStatus = (agreement) => getStatusForState(agreement.state);

export const isCanceled: AgreementHelper = (agreement) => (
  Boolean(agreement.cancelTimestamp)
);

export const isConcluded: AgreementHelper = (agreement) => (
  isSigned(agreement) || isDeclined(agreement)
);
export const isTemplate: AgreementHelper = (agreement) => (
  isStateTemplate(agreement.state)
);
export const isSigningInProgress: AgreementHelper = (agreement) => (
  agreement.state === agreementConstants.STATE_SIGN_IN_PROGRESS
);
export const isDecliningInProgress: AgreementHelper = (agreement) => (
  agreement.state === agreementConstants.STATE_DECLINE_IN_PROGRESS
);

export const isInProgress: AgreementHelper = (agreement) => (
  isSigningInProgress(agreement) || isDecliningInProgress(agreement)
);

export const isPublished: AgreementHelper = (agreement) => (
  !isDraft(agreement) && !isTemplate(agreement)
);

export const isBlank: AgreementHelper = (agreement) => (
  Object.hasOwn(agreement.config, 'blank')
);

/* state groups */
export const isAnySignedState: AgreementHelper = (agreement) => (
  isStateAnySigned(agreement.state)
);
export const isAnyDeclinedState: AgreementHelper = (agreement) => (
  isStateAnyDeclined(agreement.state)
);

/* pending flow states */
export const isPendingApprovalFlowSucceeded: AgreementHelper = (agreement) => (
  hasPendingStateApprovers(agreement) && agreement.pendingStateFlow.status === 'SUCCEEDED'
);

/* approval flow draft states */
export const hasDraftApprovalFlow: AgreementHelper = (agreement) => (
  agreement?.draftApprovalFlow
);

export const isDraftApprovalFlowNotStarted: AgreementHelper = (agreement) => (
  hasDraftApprovalFlow(agreement) && agreement.draftApprovalFlow.status === 'NOT_STARTED'
);

export const isDraftApprovalFlowRunning: AgreementHelper = (agreement) => (
  hasDraftApprovalFlow(agreement) && agreement.draftApprovalFlow.status === 'RUNNING'
);

export const isDraftApprovalFlowSucceeded: AgreementHelper = (agreement) => (
  hasDraftApprovalFlow(agreement) && agreement.draftApprovalFlow.status === 'SUCCEEDED'
);
