// @flow

import React from 'react';
import { localize, type MessageTranslator } from '@oneflowab/pomes';

import Field from 'components/field';
import TextField from 'components/text-field';

type Props = {
  isInDocumentPage?: boolean,
  message: MessageTranslator,
  parse?: Function,
};

export const DataFieldValue = ({ isInDocumentPage, message, parse }: Props) => (
  <Field
    name="value"
    label={message({
      id: 'Value',
      comment: 'Label for the value field for a data field.',
    })}
    placeholder={message({
      id: 'Enter value',
      comment: 'Value placeholder for a data field.',
    })}
    maxLength={isInDocumentPage ? undefined : 1024}
    component={TextField}
    parse={parse}
  />
);

DataFieldValue.defaultProps = {
  parse: undefined,
};

export default localize<Props>(DataFieldValue);
