// @flow

import React from 'react';
import { Message } from '@oneflowab/pomes';

// a = error, b = info, use random numbers
export const getLoginMessages = () => ({
  b21: {
    headerText: (
      <Message
        id="Password changed"
        comment="Info box header when password is successfully changed."
      />
    ),
    bodyText: (
      <Message
        id="Please use your new password to log in."
        comment="Info box body when password is successfully changed."
      />
    ),
    hasInfoStyle: true,
  },
});

export const getSSOMessages = () => ({
  extensionDisabled: {
    headerText: (
      <Message
        id="Extension disabled"
        comment="Error header for SSO login."
      />
    ),
    bodyText: (
      <Message
        id="Could not login via Single Sign-On, please contact your system administrator."
        comment="Error body text for SSO login."
      />
    ),
  },
  loginDisabled: {
    headerText: (
      <Message
        id="Login disabled"
        comment="Error header for SSO login."
      />
    ),
    bodyText: (
      <Message
        id="Could not login via Single Sign-On, please contact your system administrator."
        comment="Error body text for SSO login."
      />
    ),
  },
  ssoInvalid: {
    headerText: (
      <Message
        id="SSO invalid"
        comment="Error header for SSO login."
      />
    ),
    bodyText: (
      <Message
        id="Could not login via Single Sign-On, please contact your system administrator."
        comment="Error body text for SSO login."
      />
    ),
  },
  runtimeProblems: {
    headerText: 'Runtime error', // Not translated on purpose
    bodyText: (
      <Message
        id="Could not login via Single Sign-On, please try again. If the problem persists, please contact your system administrator."
        comment="Error body text for SSO login."
      />
    ),
  },
});
