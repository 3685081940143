// @flow

import React from 'react';

import Svg from 'components/svg';

const Mobile = Svg((
  <path d="M0 14V2C0 0.895431 0.895431 0 2 0H4H6H8C9.10457 0 10 0.895431 10 2V14C10 15.1046 9.10457 16 8 16H2C0.89543 16 0 15.1046 0 14ZM3 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H8C8.55228 15 9 14.5523 9 14V2C9 1.44772 8.55228 1 8 1H7C7 1.55228 6.55228 2 6 2H4C3.44772 2 3 1.55228 3 1ZM2.70605 13.2351C2.70605 12.959 2.92991 12.7351 3.20605 12.7351H6.73541C7.01156 12.7351 7.23541 12.959 7.23541 13.2351C7.23541 13.5112 7.01156 13.7351 6.73541 13.7351H3.20605C2.92991 13.7351 2.70605 13.5112 2.70605 13.2351Z" fill="currentColor" />
), {
  viewBox: '0 0 10 16',
});

Mobile.displayName = 'Mobile';

export default Mobile;
