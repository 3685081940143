// @flow

import React from 'react';

import Svg from 'components/svg';

const Replay = Svg((
  <path d="M5 1L2.25 3L5 5M1.75 8.25C1.75 9.28835 2.05791 10.3034 2.63478 11.1667C3.21166 12.0301 4.0316 12.703 4.99091 13.1004C5.95022 13.4977 7.00582 13.6017 8.02422 13.3991C9.04262 13.1966 9.97809 12.6965 10.7123 11.9623C11.4465 11.2281 11.9466 10.2926 12.1491 9.27422C12.3517 8.25582 12.2477 7.20022 11.8504 6.24091C11.453 5.2816 10.7801 4.46166 9.91674 3.88478C9.05339 3.30791 8.03835 3 7 3H3" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
), { fill: 'none', viewBox: '0 0 14 14' });

Replay.displayName = 'Replay';

export default Replay;
