// @flow

import React from 'react';

import Svg from 'components/svg';

const BulletedList = Svg((
  <>
    <path d="M9 6H20V8H9V6Z" fill="currentColor" />
    <path d="M9 11H20V13H9V11Z" fill="currentColor" />
    <path d="M9 16H20V18H9V16Z" fill="currentColor" />
    <circle r="1.5" transform="matrix(1 0 0 -1 5.5 17)" fill="currentColor" />
    <circle r="1.5" transform="matrix(1 0 0 -1 5.5 12)" fill="currentColor" />
    <circle r="1.5" transform="matrix(1 0 0 -1 5.5 7)" fill="currentColor" />
  </>
));

BulletedList.displayName = 'BulletedList';

export default BulletedList;
