// @flow

import React from 'react';

import Svg from 'components/svg';

const HideTableBorders = Svg((
  <>
    <rect x="18.5459" y="12.6696" width="1.44941" height="1.45455" transform="rotate(-90 18.5459 12.6696)" fill="currentColor" />
    <rect x="18.5454" y="16.7544" width="2.41569" height="1.45455" transform="rotate(-90 18.5454 16.7544)" fill="currentColor" />
    <rect x="4" y="16.754" width="2.41569" height="1.45455" transform="rotate(-90 4 16.754)" fill="currentColor" />
    <rect x="11.2998" y="16.7536" width="2.41569" height="1.45455" transform="rotate(-90 11.2998 16.7536)" fill="currentColor" />
    <rect x="7.20117" y="11.2197" width="2.42424" height="1.44941" fill="currentColor" />
    <rect x="7.20117" y="4.00073" width="2.42424" height="1.44941" fill="currentColor" />
    <rect x="7.20117" y="18.5502" width="2.42424" height="1.44941" fill="currentColor" />
    <rect x="18.5454" y="9.60547" width="2.41569" height="1.45455" transform="rotate(-90 18.5454 9.60547)" fill="currentColor" />
    <rect x="4" y="9.60547" width="2.41569" height="1.45455" transform="rotate(-90 4 9.60547)" fill="currentColor" />
    <rect x="11.2998" y="9.6051" width="2.41569" height="1.45455" transform="rotate(-90 11.2998 9.6051)" fill="currentColor" />
    <rect x="14.375" y="11.2197" width="2.42424" height="1.44941" fill="currentColor" />
    <rect x="14.375" y="4.00073" width="2.42424" height="1.44941" fill="currentColor" />
    <rect x="14.375" y="18.55" width="2.42424" height="1.44941" fill="currentColor" />
    <rect x="4" y="12.6688" width="1.44941" height="1.45455" transform="rotate(-90 4 12.6688)" fill="currentColor" />
    <rect x="11.2998" y="12.6685" width="1.44941" height="1.45455" transform="rotate(-90 11.2998 12.6685)" fill="currentColor" />
    <rect x="4" y="5.45067" width="1.44941" height="1.45455" transform="rotate(-90 4 5.45067)" fill="currentColor" />
    <rect x="4" y="20" width="1.44941" height="1.45455" transform="rotate(-90 4 20)" fill="currentColor" />
    <rect x="11.2988" y="5.44971" width="1.44941" height="1.45455" transform="rotate(-90 11.2988 5.44971)" fill="currentColor" />
    <rect x="11.2988" y="19.9984" width="1.44941" height="1.45455" transform="rotate(-90 11.2988 19.9984)" fill="currentColor" />
    <rect x="18.5449" y="5.44971" width="1.44941" height="1.45455" transform="rotate(-90 18.5449 5.44971)" fill="currentColor" />
    <rect x="18.5449" y="19.9984" width="1.44941" height="1.45455" transform="rotate(-90 18.5449 19.9984)" fill="currentColor" />
  </>
), {
  fill: 'none',
  viewBox: '0 0 24 24',
});

HideTableBorders.displayName = 'HideTableBorders';

export default HideTableBorders;
