import React from 'react';

import Svg from 'components/svg';

export const AuditTrail = Svg(
  <>
    <path d="M17.727 21.4325C20.3253 21.4325 22.4316 19.3262 22.4316 16.728C22.4316 14.1297 20.3253 12.0234 17.727 12.0234C15.1288 12.0234 13.0225 14.1297 13.0225 16.728C13.0225 19.3262 15.1288 21.4325 17.727 21.4325Z" stroke="currentColor" strokeWidth="1.50284" strokeMiterlimit="10" />
    <path d="M17.7275 13.9834V16.7277H20.4719" stroke="currentColor" strokeWidth="1.50284" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.53418 3.65909C2.53418 3.64026 2.54944 3.625 2.56827 3.625H4.9541V19.375H2.56827C2.54944 19.375 2.53418 19.3597 2.53418 19.3409V3.65909ZM2.56827 2.125H5.7041H18.2501C19.0973 2.125 19.7842 2.81184 19.7842 3.65909V9.70218C19.3029 9.56149 18.801 9.46893 18.2842 9.43005V3.65909C18.2842 3.64026 18.2689 3.625 18.2501 3.625H6.4541V19.375H10.9028C11.1098 19.9081 11.3774 20.4109 11.6972 20.875H5.7041H2.56827C1.72102 20.875 1.03418 20.1882 1.03418 19.3409V3.65909C1.03418 2.81184 1.72102 2.125 2.56827 2.125ZM13.5041 10.75C12.9108 11.17 12.3828 11.676 11.9382 12.25H8.84082C8.42661 12.25 8.09082 11.9142 8.09082 11.5C8.09082 11.0858 8.42661 10.75 8.84082 10.75H13.5041ZM8.09082 8.36353C8.09082 7.94931 8.42661 7.61353 8.84082 7.61353H15.1135C15.5278 7.61353 15.8635 7.94931 15.8635 8.36353C15.8635 8.77774 15.5278 9.11353 15.1135 9.11353H8.84082C8.42661 9.11353 8.09082 8.77774 8.09082 8.36353Z" fill="currentColor" />
  </>,
  { viewBox: '0 0 24 24', fill: 'none' },
);

AuditTrail.displayName = 'AuditTrail';

export default AuditTrail;
