import Svg from 'components/svg';

const Mobile2 = Svg((
  <>
    <path d="M13.4375 0.8125H2.9375C2.10908 0.8125 1.4375 1.48407 1.4375 2.3125V18.8125C1.4375 19.6409 2.10908 20.3125 2.9375 20.3125H13.4375C14.2659 20.3125 14.9375 19.6409 14.9375 18.8125V2.3125C14.9375 1.48407 14.2659 0.8125 13.4375 0.8125Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.4375 16.5625H8.9375" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </>
), { viewBox: '0 0 16 22', fill: 'none' });

Mobile2.displayName = 'Mobile2';

export default Mobile2;
